/** @jsxImportSource @emotion/react */

import { useEffect, useRef } from 'react';
import { Currency } from 'utils/currency';

export type CardBrand =
  | ''
  | 'American Express'
  | 'MasterCard'
  | 'Visa'
  | 'Diners Club'
  | 'Discover'
  | 'UnionPay'
  | 'Maestro'
  | 'Elo'
  | 'Mir'
  | 'Hiper'
  | 'Hipercard';

export type CardPaymentSource = {
  cardPaymentSourceId?: string;
  last4: string;
  cardBin: string;
  brand?: CardBrand;
  funding?: 'credit' | 'debit' | 'chargecard' | 'prepaid' | 'deferreddebit' | 'unknown';
  expMonth: number;
  expYear: number;
  country: string;
};

export type PaymentSource = {
  cardPaymentSource?: CardPaymentSource;
};

export type PaymentData = {
  amount: number;
  currency: Currency;
  payType: 'PayInFull' | 'PayPlan';
  paymentMethodType: 'Card' | 'NetworkToken' | 'DirectDebit' | 'PayTo';
  paymentSource?: PaymentSource;
};

export type CheckoutProps = {
  amount: number;
  currency: Currency;
  publicKey: string;
  customerToken?: string;
  hidePayLaterOption?: boolean;
  onPaymentToken(paymentTokenId: string, paymentData: PaymentData): void;
};

export function Checkout({
  amount,
  currency,
  publicKey,
  customerToken,
  hidePayLaterOption,
  onPaymentToken,
}: CheckoutProps) {
  const checkoutRef = useRef();

  useEffect(() => {
    if (checkoutRef.current) return;

    const AprilCheckout = (window as any).AprilCheckout.createCheckout();
    checkoutRef.current = AprilCheckout;

    AprilCheckout.init({
      publicKey,
      customerToken,
      hidePayLaterOption: true,
      paymentToken: onPaymentToken,
    });

    AprilCheckout.render({
      elementId: 'april-cont',
      currency,
      amount,
      showPayNow: true,
    });
  }, [amount, currency, customerToken, hidePayLaterOption, onPaymentToken, publicKey]);

  return <div id="april-cont" />;
}
