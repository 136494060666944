import { getTransactionDetail } from 'api/platform/transactions';
import { PlatformDashboardTransactionsObj, PlatformTransactionDetailObj } from 'api/platform/transactions.types';
import PlatformLayout from 'layouts/platform/PlatformLayout';
import qs from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/platform/hooks';
import { getPlatformBaseUrl } from 'utils/getBaseUrl';
import getErrorMessage from 'utils/getErrorMessage';

import DashboardRoutesList from '../../constants';
import PaymentRoutesList from '../constants';
import { ITransactionFilterValuesObj, TransactionFilterParentObj } from '../transactions/types';
import TxnDetails from './TxnDetails';

export interface Props {
  transactionList: Array<PlatformDashboardTransactionsObj>;
  fetchListLoader: boolean;
  totalRows: number;
  handlePerRowsChange: (newPerPage: number, page: number) => void;
  handlePageChange: (page: number) => void;
  limit: number;
  toggleFilter: (val: boolean) => void;
  filterRecordHandler: (obj: ITransactionFilterValuesObj) => void;
  filterValuesObj: TransactionFilterParentObj;
  activePage: number;
  handleSort: (selectedColumn: TableColumn<PlatformDashboardTransactionsObj>, sortDirection: SortOrder) => void;
  defaultExportOpened?: boolean;
}

function TrasactionDetailPage() {
  const navigate = useNavigate();
  const { payoutId } = qs.parse(window.location.search);

  // detail section variables
  const [showDetailLoader, setShowDetailLoader] = useState<boolean>(false);
  const [txnDetailObj, setTxnDetailObj] = useState<PlatformTransactionDetailObj | null>(null);
  const [detailErrorMsg, setDetailErrorMsg] = useState<string>('');

  const { platformName, marketplaceId, apiBaseUri } = useAppSelector((state) => ({
    platformName: state.config.platformName,
    marketplaceId: state.config.marketplaceId,
    apiBaseUri: state.config.apiBaseUri,
  }));

  const detailSectionBackClickhandler = () => {
    navigate(
      `${getPlatformBaseUrl(platformName)}/dashboard/${DashboardRoutesList.PAYMENT_ROUTE}/${
        PaymentRoutesList.PAYMENT_TRANSACTIONS
      }`,
    );
  };

  const detailClickHandler = useCallback(async () => {
    try {
      if (!payoutId) {
        return;
      }

      setShowDetailLoader(true);

      const response = await getTransactionDetail(apiBaseUri, marketplaceId, payoutId as string);
      setTxnDetailObj(response);
    } catch (error) {
      setDetailErrorMsg(getErrorMessage(error));
    } finally {
      setShowDetailLoader(false);
    }
  }, [apiBaseUri, marketplaceId, payoutId]);

  useEffect(() => {
    if (payoutId) {
      detailClickHandler();
    }
  }, [payoutId, detailClickHandler]);

  return (
    <PlatformLayout activeTab="payment" title="Transaction Detail">
      <TxnDetails
        onBackButtonClick={() => detailSectionBackClickhandler()}
        showDetailLoader={showDetailLoader}
        txnDetailObj={txnDetailObj}
        detailErrorMsg={detailErrorMsg}
      />
    </PlatformLayout>
  );
}

export default TrasactionDetailPage;
