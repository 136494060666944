import { IAddressObj, SettingPlatformBusinessRegistrationResponse } from 'api/platform/setting.types';
import * as s from 'assets/styles/Platforms.styles';
import { FormikProps } from 'formik';
import SettingsSidebar from 'layouts/platform/SettingsSidebar';
import { RefObject } from 'react';

import { Button, Heading, Icon, LabelledValue, Text } from '@limepayments/cosmic';

import BusinessProfileEditModal from './partials/BusinessProfileEditModal';
import { SettingBusinessProfilePayload } from './types';

export interface Props {
  businessProfileDetail: SettingBusinessProfilePayload;
  onSubmitClick: (values: SettingBusinessProfilePayload, formObj: { resetForm: () => void }) => void;
  formRef: RefObject<FormikProps<SettingBusinessProfilePayload>>;
  errorMsg: string;
  editRequestLoading: boolean;
  editModalOpen: boolean;
  setEditModalOpen: (val: boolean) => void;
  platformCountry: string;
  platformRegistationDetail: SettingPlatformBusinessRegistrationResponse;
}

function BusinessProfile({
  businessProfileDetail,
  onSubmitClick,
  formRef,
  errorMsg,
  editModalOpen,
  editRequestLoading,
  setEditModalOpen,
  platformCountry,
  platformRegistationDetail,
}: Props) {
  const getAddress = (address: IAddressObj) => {
    let returnData = ``;

    if (address.unitNumber) {
      returnData += `${address.unitNumber}, `;
    }

    if (address.streetNumber) {
      returnData += `${address.streetNumber} `;
    }

    returnData += `${address.streetName}, ${address.suburb}, ${address.streetType} `;
    returnData += address.city ? `${address.city}` : `${address.state}`;
    returnData += ` - ${address.postalCode}, ${address.country}`;

    return returnData;
  };
  return (
    <>
      <div className="content-wrap-inner with-gutter">
        <div className="lp-w-full lp-flex">
          <div className="payment-sidebar">
            <div className="payment-sidebar-inner">
              <SettingsSidebar activeTab="business-profile" />
            </div>
          </div>

          <div className="payment-content-area">
            <div className="lp-w-full lp-flex lp-justify-between sm-lp-flex-col mb-32">
              <Heading alignment="left" tagName="h2" variant="xl" className="page-title mb-0">
                Business profile
              </Heading>
            </div>

            <s.MerchantsData className="bt-1">
              <div className="lp-w-full lp-flex-row mb-32">
                <div className="lp-w-full lp-flex lp-align-center lp-flex-row mb-0 sm-lp-align-start">
                  <div className="lp-w-full lp-flex lp-flex-column">
                    <Text alignment="left" tagName="p" variant="body-1" isEmphasised className="text-dark-50 mb-0">
                      Business profile
                    </Text>
                  </div>
                  <div className="ml-auto">
                    <Button
                      size="small"
                      onClick={() => setEditModalOpen(!editModalOpen)}
                      variant="reversed"
                      className="no-min-width w-70"
                      testId="business-profile-edit-button"
                    >
                      Edit
                      <Icon name="Edit" />
                    </Button>
                  </div>
                </div>
                <div className="lp-w-full lp-flex lp-align-center lp-flex-row mb-32 sm-lp-align-start">
                  <Text variant="caption" className="text-dark-50 mb-0 mt-4">
                    Contact details for ongoing engagements.
                  </Text>
                </div>
              </div>

              <div className="lp-w-full mb-32">
                <LabelledValue
                  label="First name"
                  value={businessProfileDetail.contactPerson.firstName ?? '-'}
                  variant="vertical"
                  testId="setting-business-profile-first-name"
                />
              </div>
              <div className="lp-w-full mb-32">
                <LabelledValue
                  label="Last name"
                  value={businessProfileDetail.contactPerson.lastName ?? '-'}
                  variant="vertical"
                  testId="setting-business-profile-last-name"
                />
              </div>
              <div className="lp-w-full mb-32">
                <LabelledValue
                  label="Job title"
                  value={businessProfileDetail.contactPerson.title ?? '-'}
                  variant="vertical"
                />
              </div>
              <div className="lp-w-full mb-32">
                <LabelledValue
                  label="Contact email address"
                  value={businessProfileDetail.contactEmail ?? '-'}
                  variant="vertical"
                  testId="setting-business-profile-email"
                />
              </div>
              <div className="lp-w-full mb-32">
                <LabelledValue
                  label="Contact phone number"
                  value={businessProfileDetail.phoneNo ?? '-'}
                  variant="vertical"
                />
              </div>
              <div className="lp-w-full mb-0">
                <LabelledValue
                  label="Business address"
                  value={`${getAddress(businessProfileDetail.address)}`}
                  variant="vertical"
                  testId="setting-business-profile-address"
                />
              </div>
            </s.MerchantsData>

            <s.MerchantsData className="bt-1 mt-32">
              <div className="lp-w-full lp-flex-row mb-32">
                <div className="lp-w-full lp-flex lp-align-center lp-flex-row mb-0 sm-lp-align-start">
                  <div className="lp-w-full lp-flex lp-flex-column">
                    <Text alignment="left" tagName="p" variant="body-1" isEmphasised className="text-dark-50 mb-0">
                      Business registration details
                    </Text>
                  </div>
                </div>
                <div className="lp-w-full lp-flex lp-align-center lp-flex-row mb-32 sm-lp-align-start">
                  <Text variant="caption" className="text-dark-50 mb-0 mt-4">
                    The official details of your business.
                  </Text>
                </div>
              </div>

              <div className="lp-w-full mb-32">
                <LabelledValue
                  label="Business registration number"
                  value={platformRegistationDetail.taxId}
                  variant="vertical"
                />
              </div>
              <div className="lp-w-full mb-32">
                <LabelledValue
                  label="Legal entity name"
                  value={platformRegistationDetail.businessName}
                  variant="vertical"
                />
              </div>
              <div className="lp-w-full mb-32">
                <LabelledValue
                  label="Business type"
                  value={
                    platformRegistationDetail.businessType.charAt(0).toUpperCase() +
                    platformRegistationDetail.businessType.slice(1)
                  }
                  variant="vertical"
                />
              </div>
              <div className="lp-w-full mb-0">
                <LabelledValue
                  label="Business industry"
                  value={platformRegistationDetail.industry.split(/(?=[A-Z])/).join(' ')}
                  variant="vertical"
                />
              </div>
            </s.MerchantsData>
          </div>
        </div>
      </div>

      <BusinessProfileEditModal
        isOpen={editModalOpen}
        modalToggler={setEditModalOpen}
        initialValues={JSON.parse(JSON.stringify(businessProfileDetail))}
        onSubmitClick={onSubmitClick}
        formRef={formRef}
        errorMsg={errorMsg}
        editRequestLoading={editRequestLoading}
        platformCountry={platformCountry}
      />
    </>
  );
}

export default BusinessProfile;
