import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/merchant/hooks';
import { getMerchantBaseUrl } from 'utils/getBaseUrl';

import OnboardingRoutesList from '../../../constants';
import PublicStep05View from './PublicStep05View';

interface Props {
  isSoleTrader: boolean;
}

function PublicSection({ isSoleTrader }: Props) {
  const navigate = useNavigate();
  const { merchantName } = useAppSelector((state) => ({
    merchantName: state.config.merchantName,
  }));

  return (
    <PublicStep05View
      onPreviousClick={() => {
        navigate(`${getMerchantBaseUrl(merchantName)}/onboarding/${OnboardingRoutesList.ONBOARDING_STEP04_PAGE}`);
      }}
      isSoleTrader={isSoleTrader}
    />
  );
}

export default PublicSection;
