import { PlatformMerchantObj } from 'api/platform/merchant.types';
import * as s from 'assets/styles/Platforms.styles';
import { DateTime } from 'luxon';
import { TableStyleSettings, TableThemeSettings } from 'pages/platform/constants';
import DashboardRoutesList from 'pages/platform/dashboard/constants';
import DataTable, { SortOrder, TableColumn, createTheme } from 'react-data-table-component';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/platform/hooks';
import { setOpenFilterDropdown } from 'redux/platform/slice/merchantSlice';
import { AppDispatch } from 'redux/platform/store';
import AUFlag, { NZFlag } from 'utils/flagUtils';
import { getPlatformBaseUrl } from 'utils/getBaseUrl';
import { capitalizeFirstLowercaseRest } from 'utils/stringUtils';

import { Spinner } from '@limepayments/cosmic';

import MerchantRoutesList, { MerchantStatusObj } from '../../constants';
import DataTableMerchantRow from './DataTableMerchantRow';
import EmptyMerchantListComponent from './EmptyMerchantListComponent';
import FilterEmptyMerchantListComponent from './FilterEmptyMerchantListComponent';

const EmptyMessageObj = {
  Active: {
    title: 'No active merchants to display',
    body: 'Merchants will appear here when they have been verified and enabled to process digital payments.',
  },
  Suspended: {
    title: 'No suspended merchants to display',
    body: 'Merchants will appear here when their digital payments capability has been disabled.',
  },
  Pending: {
    title: 'No pending merchants to display',
    body: 'Merchants will appear here when they have been added but haven’t had their business verified.',
  },
};

// createTheme creates a new theme named solarized that overrides the build in dark theme
createTheme('polarized', TableThemeSettings, 'dark');

interface Props {
  merchantList: Array<PlatformMerchantObj>;
  fetchListLoader: boolean;
  activeStatusTab: string;
  totalRows: number;
  handlePerRowsChange: (newPerPage: number, page: number) => void;
  handlePageChange: (page: number) => void;
  limit: number;
  isFilterRequest: boolean;
  activePage: number;
  handleSort: (selectedColumn: TableColumn<PlatformMerchantObj>, sortDirection: SortOrder) => void;
}

interface DataTableProps extends Props {
  dispatch: AppDispatch;
  navigate: NavigateFunction;
  platformName: string;
}

function MerchantsDataTable(props: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { platformName } = useAppSelector((state) => ({
    platformName: state.config.platformName,
  }));

  return (
    <s.MerchantTable className="merchant-table">
      <DataTable
        {...getDataTableProps({ ...props, platformName: platformName, dispatch: dispatch, navigate: navigate })}
        defaultSortAsc={false}
        defaultSortFieldId={4}
        pointerOnHover={true}
      />
    </s.MerchantTable>
  );
}

export function MerchantsPendingDataTable(props: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { platformName } = useAppSelector((state) => ({
    platformName: state.config.platformName,
  }));
  return (
    <s.MerchantTable className="merchant-table">
      <DataTable
        {...getDataTableProps({ ...props, platformName: platformName, dispatch: dispatch, navigate: navigate })}
        defaultSortAsc={false}
        defaultSortFieldId={4}
        pointerOnHover={true}
      />
    </s.MerchantTable>
  );
}

const getDataTableProps = ({
  merchantList,
  activeStatusTab,
  fetchListLoader,
  isFilterRequest,
  totalRows,
  handleSort,
  handlePerRowsChange,
  handlePageChange,
  limit,
  activePage,
  dispatch,
  navigate,
  platformName,
}: DataTableProps) => {
  const columns = [
    {
      name: 'Merchant',
      cell: (row: PlatformMerchantObj) => (
        <DataTableMerchantRow
          merchantId={row.merchantId}
          businessName={
            row.businessProfile.brandName ? row.businessProfile.brandName : row.businessProfile.businessName
          }
        />
      ),
      // sortable: true,
      // sortField: 'businessProfile.businessName',
    },
    {
      name: 'Business type',
      selector: (row: PlatformMerchantObj) =>
        row.businessProfile.businessType.charAt(0).toUpperCase() + row.businessProfile.businessType.slice(1),
      // sortable: true,
      // sortField: 'businessProfile.businessType',
    },
    {
      name: ' ',
      cell: (row: PlatformMerchantObj) => (row.businessProfile.address.country === 'AU' ? <AUFlag /> : <NZFlag />),
    },
    {
      name:
        activeStatusTab === MerchantStatusObj.Pending
          ? 'Date Created'
          : 'Date '.concat(capitalizeFirstLowercaseRest(activeStatusTab)),
      selector: (row: PlatformMerchantObj) =>
        DateTime.fromISO(MerchantStatusObj.Pending ? row.createdAt : row.stateUpdatedAt).toFormat('dd LLL yyyy'),
      sortable: true,
      sortField: activeStatusTab === MerchantStatusObj.Pending ? 'createdAt' : 'stateUpdatedAt',
    },
  ];

  return {
    pagination: true,
    responsive: true,
    columns: columns,
    data: merchantList,
    customStyles: TableStyleSettings,
    theme: 'polarized',
    className: '',
    progressPending: fetchListLoader,
    progressComponent: (
      <div className="transaction-empty">
        <div className="transaction-empty-inner">
          <Spinner variant="simple" isVisible label="Loading..." />
        </div>
      </div>
    ),
    noDataComponent:
      isFilterRequest && merchantList.length < 1 ? (
        <FilterEmptyMerchantListComponent toggleFilter={() => dispatch(setOpenFilterDropdown(true))} />
      ) : (
        <EmptyMerchantListComponent
          title={EmptyMessageObj[activeStatusTab as keyof typeof MerchantStatusObj].title}
          body={EmptyMessageObj[activeStatusTab as keyof typeof MerchantStatusObj].body}
        />
      ),
    paginationServer: true,
    paginationTotalRows: totalRows,
    paginationRowsPerPageOptions: [20, 40, 60, 80, 100],
    onChangeRowsPerPage: handlePerRowsChange,
    onChangePage: handlePageChange,
    paginationPerPage: limit,
    paginationDefaultPage: activePage,
    onRowClicked: (row: { merchantId: string }) =>
      navigate(
        `${getPlatformBaseUrl(platformName)}/dashboard/${DashboardRoutesList.MERCHANT_ROUTE}/${
          MerchantRoutesList.MERCHANT_DETAIL
        }/${row.merchantId}`,
      ),
    onSort: handleSort,
    sortServer: true,
    persistTableHead: true,
  };
};

export default MerchantsDataTable;
