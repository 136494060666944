import { Formik } from 'formik';
import * as Yup from 'yup';

import { Button, Heading, Icon, Illustration, Message, Text, TextField } from '@limepayments/cosmic';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Please enter a valid email address').required('Email address is required'),
});

type FormikFormValues = {
  email: string;
};

interface Props {
  onSubmitClick: (values: FormikFormValues, formObj: { resetForm: () => void }) => void;
  onPreviousClick: () => void;
  initialValues: FormikFormValues;
  requestLoader: boolean;
  errorMsg: string;
}

function ForgotPasswordView({ onSubmitClick, onPreviousClick, initialValues, requestLoader, errorMsg }: Props) {
  return (
    <>
      <div className="lp-w-full mb-32">
        <div className="size-136 mx-auto mb-32">
          <Illustration name="Data" size="large" />
        </div>
        <Heading testId="forgot-password-page-title" alignment="center" tagName="h1" variant="md" className="mb-12">
          Reset password
        </Heading>
        <Text tagName="p" variant="body-3" alignment="center">
          Enter the email linked to your account and we'll send you an email to reset your password.
        </Text>
        {errorMsg.length > 0 && (
          <Message type="inline" children={<span className="text-wrap">{errorMsg}</span>} variant={'error'} />
        )}
      </div>

      <Formik<FormikFormValues>
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values, formObj: { resetForm: () => void }) => {
          onSubmitClick(values, formObj);
        }}
        validationSchema={validationSchema}
      >
        {({ handleChange, values, handleSubmit, errors, touched, handleBlur }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <div className="lp-w-full mb-32">
              <div className="lp-w-full mb-8">
                <TextField
                  fullWidth
                  inputMode="email"
                  type="email"
                  label="Email"
                  autoComplete="off"
                  testId="email"
                  id="email"
                  name="email"
                  startAdornment="Filled-Email"
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={errors.email && touched.email ? errors.email : ''}
                />
              </div>
            </div>
            <Button
              type="submit"
              size="medium"
              variant="primary"
              className={`lp-w-full mb-8`}
              isLoading={requestLoader}
              testId="forgot-password-submit-button"
            >
              Send instructions
            </Button>
          </form>
        )}
      </Formik>

      <Button onClick={onPreviousClick} size="medium" variant="ghost" className="lp-w-full lp-no-transition">
        <Icon name="ArrowLeft" className="mr-8" />
        Log in
      </Button>
    </>
  );
}

export default ForgotPasswordView;
