import { handleResponse } from 'api/utils';
import {
  CreateMerchantCompanyOwnerPayload,
  CreateMerchantPartnershipOwnerPayload,
  CreateMerchantPersonPayloadApi,
  CreateMerchantTrustOwnerPayload,
} from 'pages/merchant/onboarding/step-05/types';
import getFetchOptions from 'utils/getFetchOptions';

import {
  CreateMerchantCompanyOwnerResponse,
  CreateMerchantPartnershipOwnerResponse,
  CreateMerchantPersonResponse,
  CreateMerchantTrustOwnerResponse,
  GetListMerchantCompanyOwnersObj,
  GetListMerchantPartnershipOwnersObj,
  GetListMerchantPersonObj,
  GetListMerchantTrustOwnersObj,
  GetMerchantCompanyOwnerResponse,
  GetMerchantPartnershipOwnerResponse,
  GetMerchantTrustOwnerResponse,
} from './step-05.types';

// Sole traders enpoint start here
export const getListMerchantPerson = async (
  host: string,
  merchantId: string,
): Promise<Array<GetListMerchantPersonObj>> => {
  const options = await getFetchOptions();
  return await fetch(`${host}/onboarding/${merchantId}/person`, options).then(handleResponse);
};

export const createMerchantPerson = async (
  host: string,
  merchantId: string,
  payload: CreateMerchantPersonPayloadApi,
): Promise<CreateMerchantPersonResponse> => {
  const options = await getFetchOptions('POST', { payload });
  return await fetch(`${host}/onboarding/${merchantId}/person`, options).then(handleResponse);
};

export const getMerchantPerson = async (
  host: string,
  merchantId: string,
  personId: string,
): Promise<GetListMerchantPersonObj> => {
  const options = await getFetchOptions();
  return await fetch(`${host}/onboarding/${merchantId}/person/${personId}`, options).then(handleResponse);
};

export const updateMerchantPerson = async (
  host: string,
  merchantId: string,
  personId: string,
  payload: CreateMerchantPersonPayloadApi,
): Promise<void> => {
  const options = await getFetchOptions('POST', { payload });
  return await fetch(`${host}/onboarding/${merchantId}/person/${personId}`, options).then(handleResponse);
};

export const deleteMerchantPerson = async (host: string, merchantId: string, personId: string): Promise<void> => {
  const options = await getFetchOptions('DELETE');
  return await fetch(`${host}/onboarding/${merchantId}/person/${personId}`, options).then(handleResponse);
};

// Sole traders enpoint ends here

// Partnerships enpoint start here
export const getListMerchantPartnershipOwners = async (
  host: string,
  merchantId: string,
): Promise<Array<GetListMerchantPartnershipOwnersObj>> => {
  const options = await getFetchOptions();
  return await fetch(`${host}/onboarding/${merchantId}/partnership-owner`, options).then(handleResponse);
};

export const createMerchantPartnershipOwner = async (
  host: string,
  merchantId: string,
  payload: CreateMerchantPartnershipOwnerPayload,
): Promise<CreateMerchantPartnershipOwnerResponse> => {
  const options = await getFetchOptions('POST', { payload });
  return await fetch(`${host}/onboarding/${merchantId}/partnership-owner`, options).then(handleResponse);
};

export const getMerchantPartnershipOwner = async (
  host: string,
  merchantId: string,
  merchantEntityOwnerId: string,
): Promise<GetMerchantPartnershipOwnerResponse> => {
  const options = await getFetchOptions();
  return await fetch(`${host}/onboarding/${merchantId}/partnership-owner/${merchantEntityOwnerId}`, options).then(
    handleResponse,
  );
};

export const updateMerchantPartnershipOwner = async (
  host: string,
  merchantId: string,
  merchantEntityOwnerId: string,
  payload: CreateMerchantPartnershipOwnerPayload,
): Promise<void> => {
  const options = await getFetchOptions('POST', { payload });
  return await fetch(`${host}/onboarding/${merchantId}/partnership-owner/${merchantEntityOwnerId}`, options).then(
    handleResponse,
  );
};

export const deleteMerchantPartnershipOwner = async (
  host: string,
  merchantId: string,
  merchantEntityOwnerId: string,
): Promise<void> => {
  const options = await getFetchOptions('DELETE');
  return await fetch(`${host}/onboarding/${merchantId}/partnership-owner/${merchantEntityOwnerId}`, options).then(
    handleResponse,
  );
};

// Partnerships enpoint ends here

// Trusts enpoint start here
export const getListMerchantTrustOwners = async (
  host: string,
  merchantId: string,
): Promise<Array<GetListMerchantTrustOwnersObj>> => {
  const options = await getFetchOptions();
  return await fetch(`${host}/onboarding/${merchantId}/trust-owner`, options).then(handleResponse);
};

export const createMerchantTrustOwner = async (
  host: string,
  merchantId: string,
  payload: CreateMerchantTrustOwnerPayload,
): Promise<CreateMerchantTrustOwnerResponse> => {
  const options = await getFetchOptions('POST', { payload });
  return await fetch(`${host}/onboarding/${merchantId}/trust-owner`, options).then(handleResponse);
};

export const getMerchantTrustOwner = async (
  host: string,
  merchantId: string,
  merchantEntityOwnerId: string,
): Promise<GetMerchantTrustOwnerResponse> => {
  const options = await getFetchOptions();
  return await fetch(`${host}/onboarding/${merchantId}/trust-owner/${merchantEntityOwnerId}`, options).then(
    handleResponse,
  );
};

export const updateMerchantTrustOwner = async (
  host: string,
  merchantId: string,
  merchantEntityOwnerId: string,
  payload: CreateMerchantTrustOwnerPayload,
): Promise<void> => {
  const options = await getFetchOptions('POST', { payload });
  return await fetch(`${host}/onboarding/${merchantId}/trust-owner/${merchantEntityOwnerId}`, options).then(
    handleResponse,
  );
};

export const deleteMerchantTrustOwner = async (
  host: string,
  merchantId: string,
  merchantEntityOwnerId: string,
): Promise<void> => {
  const options = await getFetchOptions('DELETE');
  return await fetch(`${host}/onboarding/${merchantId}/trust-owner/${merchantEntityOwnerId}`, options).then(
    handleResponse,
  );
};

// Trusts enpoint ends here

// Companies enpoint start here
export const getListMerchantCompanyOwners = async (
  host: string,
  merchantId: string,
): Promise<Array<GetListMerchantCompanyOwnersObj>> => {
  const options = await getFetchOptions();
  return await fetch(`${host}/onboarding/${merchantId}/company-owner`, options).then(handleResponse);
};

export const createMerchantCompanyOwner = async (
  host: string,
  merchantId: string,
  payload: CreateMerchantCompanyOwnerPayload,
): Promise<CreateMerchantCompanyOwnerResponse> => {
  const options = await getFetchOptions('POST', { payload });
  return await fetch(`${host}/onboarding/${merchantId}/company-owner`, options).then(handleResponse);
};

export const getMerchantCompanyOwner = async (
  host: string,
  merchantId: string,
  merchantEntityOwnerId: string,
): Promise<GetMerchantCompanyOwnerResponse> => {
  const options = await getFetchOptions();
  return await fetch(`${host}/onboarding/${merchantId}/company-owner/${merchantEntityOwnerId}`, options).then(
    handleResponse,
  );
};

export const updateMerchantCompanyOwner = async (
  host: string,
  merchantId: string,
  merchantEntityOwnerId: string,
  payload: CreateMerchantCompanyOwnerPayload,
): Promise<void> => {
  const options = await getFetchOptions('POST', { payload });
  return await fetch(`${host}/onboarding/${merchantId}/company-owner/${merchantEntityOwnerId}`, options).then(
    handleResponse,
  );
};

export const deleteMerchantCompanyOwner = async (
  host: string,
  merchantId: string,
  merchantEntityOwnerId: string,
): Promise<void> => {
  const options = await getFetchOptions('DELETE');
  return await fetch(`${host}/onboarding/${merchantId}/company-owner/${merchantEntityOwnerId}`, options).then(
    handleResponse,
  );
};

// Companies enpoint ends here
