import { PayoutDetailAPIResponseType } from 'api/merchant/dashboard/payouts.types';
import * as s from 'assets/styles/Platforms.styles';
import { Currency, toCurrency } from 'utils/currency';

import { Heading } from '@limepayments/cosmic';

function SummaryTable({
  summaryLineItems,
  total,
  currency,
}: {
  summaryLineItems: NonNullable<PayoutDetailAPIResponseType['summaryLineItems']>;
  total: number;
  currency: Currency;
}) {
  return (
    <>
      <s.MerchantsData>
        <Heading alignment="left" tagName="h2" variant="xxs" className="mb-16">
          Summary
        </Heading>
        <div className="lp-half lp-flex two-column-block">
          <s.PayoutSummaryTable>
            <table className="summary-table table">
              <thead>
                <tr>
                  <th data-column-id="1"></th>
                  <th data-column-id="2">Count</th>
                  <th data-column-id="3">Total</th>
                </tr>
              </thead>
              <tbody>
                {summaryLineItems.map((item) => {
                  return (
                    <tr>
                      {/* TODO: entryType labels */}
                      <td data-column-id="1">{item.entryType}</td>
                      <td data-column-id="2">{item.count ?? '-'}</td>
                      <td data-column-id="3">
                        {item.totalAmount < 0
                          ? `(${toCurrency(item.totalAmount, currency).replace('-', '')} ${currency})`
                          : `${toCurrency(item.totalAmount, currency)} ${currency}`}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <th data-column-id="1">Total</th>
                  <th data-column-id="2"></th>
                  <th data-column-id="3">
                    {toCurrency(total, currency)} {currency}
                  </th>
                </tr>
              </tfoot>
            </table>
          </s.PayoutSummaryTable>
        </div>
      </s.MerchantsData>
    </>
  );
}

export default SummaryTable;
