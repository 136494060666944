import * as s from 'assets/styles/Platforms.styles';

import { Button, Heading, Illustration, Modal, Text } from '@limepayments/cosmic';

interface Props {
  isOpen: boolean;
  modalToggler: (isOpen: boolean) => void;
}

function CancelPaymentModal({ isOpen, modalToggler }: Props) {
  return (
    <Modal isOpen={isOpen} className="modal-small update-pass-modal">
      <s.Wrapper className="">
        <div className="modal-main p-24">
          <div className="modal-header">
            <div className="content-main bb-1">
              <Heading alignment="center" tagName="h2" variant="xs" className="mt-12 mb-24 lp-justify-center lp-flex">
                Cancel payment
              </Heading>
            </div>
          </div>
          <div className="modal-body mb-16">
            <div className="mx-auto illustration-90 mt-12">
              <Illustration name="Email" size="medium" />
            </div>
            <Text alignment="center" tagName="p" variant="body-3" className="lp-neutral-600">
              Are you sure you want to cancel this payment of <strong>200.00</strong> AUD for{' '}
              <strong>Dwight Hill</strong>?
            </Text>
          </div>
          <div className="modal-footer lp-flex lp-justify-end sm-mt-24">
            <Button onClick={() => modalToggler(false)} size="medium" variant="ghost" className="no-min-width w-70">
              Cancel
            </Button>
            <Button type="submit" size="medium" variant="destructive" className="no-min-width">
              Cancel
            </Button>
          </div>
        </div>
      </s.Wrapper>
    </Modal>
  );
}

export default CancelPaymentModal;
