import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IOnboard {
  saveAndFinishLater: number;
  hasCompletedSteps: boolean;
  currentActiveStep: number;
}

const initialState: IOnboard = {
  saveAndFinishLater: 0,
  hasCompletedSteps: false,
  currentActiveStep: 0,
};

export const merchantOnboardSlice = createSlice({
  name: 'merchantOnboard',
  initialState,
  reducers: {
    setSaveAndFinishLater: (state, action: PayloadAction<number>) => {
      state.saveAndFinishLater = action.payload;
    },
    setHasCompletedSteps: (state, action: PayloadAction<boolean>) => {
      state.hasCompletedSteps = action.payload;
    },
    setCurrentActiveStep: (state, action: PayloadAction<number>) => {
      state.currentActiveStep = action.payload;
    },
  },
});

export const { setSaveAndFinishLater, setCurrentActiveStep, setHasCompletedSteps } = merchantOnboardSlice.actions;
export default merchantOnboardSlice.reducer;
