import { handleResponse } from 'api/utils';
import getFetchOptions from 'utils/getFetchOptions';

import { GetMerchantFileResponse, UploadMerchantFileResponse } from './file.types';

export const getMerchantFile = async (
  host: string,
  merchantId: string,
  fileId: string,
): Promise<GetMerchantFileResponse> => {
  const options = await getFetchOptions();
  return await fetch(`${host}/onboarding/files/merchants/${merchantId}/${fileId}`, options).then(handleResponse);
};

export const uploadOnboardingMerchantFile = async (
  host: string,
  merchantId: string,
  payload: FormData,
): Promise<UploadMerchantFileResponse> => {
  const options = await getFetchOptions('POST', { payload });
  return await fetch(`${host}/onboarding/files/merchants/${merchantId}`, options).then(handleResponse);
};
