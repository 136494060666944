import { createPlatformMerchant } from 'api/platform/merchant';
import { CreatePlatformMerchantPayload } from 'api/platform/merchant.types';
import * as s from 'assets/styles/Onboarding.styles';
import { Formik, FormikProps } from 'formik';
import { useState } from 'react';
import { useEffect, useRef } from 'react';
import { useAppSelector } from 'redux/platform/hooks';
import AUFlag, { NZFlag } from 'utils/flagUtils';
import getErrorMessage from 'utils/getErrorMessage';
import * as Yup from 'yup';

import { Announcement, Button, Heading, Icon, Message, Modal, Select, Text, TextField } from '@limepayments/cosmic';

import AddMerchantSuccessView from './AddMerchantSuccessView';

const validationSchema = Yup.object().shape({
  contactEmail: Yup.string().email('Please enter valid email').required('This field is required'),
  businessName: Yup.string().required('This field is required'),
  taxCountry: Yup.string().required('This field is required'),
  slug: Yup.string()
    .required('This field is required')
    .min(4, 'Dashboard URL path should not be less than 4 characters')
    .max(255, 'Dashboard URL path should not be greater than 255 characters')
    .matches(/^[a-z0-9]+$/, 'Only lowercase alphanumeric characters are allowed'),
});

interface Props {
  isOpen: boolean;
  modalToggle: (val: boolean) => void;
}

function AddMerchantModal({ isOpen, modalToggle }: Props) {
  const [showSuccessSection, setShowSuccessSection] = useState<boolean>(false);
  const [createdMerchantName, setCreatedMerchantName] = useState<string>('');
  const [initialValues] = useState<CreatePlatformMerchantPayload>({
    slug: '',
    contactEmail: '',
    businessName: '',
    taxCountry: '',
  });
  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const formRef = useRef<FormikProps<CreatePlatformMerchantPayload>>(null);
  const errorContainerRef = useRef<HTMLDivElement>(null);

  const { marketplaceId, apiBaseUri } = useAppSelector((state) => ({
    marketplaceId: state.config.marketplaceId,
    apiBaseUri: state.config.apiBaseUri,
  }));

  const submitHandler = async (values: CreatePlatformMerchantPayload, formObj: { resetForm: () => void }) => {
    try {
      setIsRequestLoading(true);
      setErrorMessage('');

      const postData = { ...values };

      await createPlatformMerchant(apiBaseUri, marketplaceId, postData);

      formObj.resetForm();
      setCreatedMerchantName(postData.businessName);
      setShowSuccessSection(true);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setIsRequestLoading(false);
    }
  };

  useEffect(() => {
    if (errorMessage.length > 0 && errorContainerRef && errorContainerRef.current) {
      errorContainerRef.current.scrollIntoView();
    }
  }, [errorMessage]);

  useEffect(() => {
    setErrorMessage('');
    setShowSuccessSection(false);
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} className="modal-medium hAuto">
      <s.Wrapper>
        {!showSuccessSection && (
          <div className="modal-main">
            <div className="modal-header">
              <div className="content-main">
                <Heading alignment="center" tagName="h2" variant="xxs" className="mb-24 lp-justify-center lp-flex">
                  Add a new merchant
                </Heading>
                <s.Separator>
                  <hr />
                </s.Separator>
              </div>
            </div>
            <Formik<CreatePlatformMerchantPayload>
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={(values, formObj: { resetForm: () => void }) => {
                submitHandler(values, formObj);
              }}
              validationSchema={validationSchema}
              innerRef={formRef}
            >
              {({ values, handleSubmit, errors, touched, setFieldValue, handleChange, handleBlur }) => (
                <form autoComplete="off" onSubmit={handleSubmit}>
                  <div className="modal-content">
                    <div className="lp-w-full" style={{ display: 'block' }}>
                      {errorMessage.length > 0 && (
                        <div className="mb-24" ref={errorContainerRef}>
                          <Message
                            type="inline"
                            children={<span className="text-wrap">{errorMessage}</span>}
                            variant={'error'}
                          />
                        </div>
                      )}
                      <div className="lp-w-full mb-24">
                        <Text alignment="left" variant="body-2" isEmphasised>
                          Merchant account details
                        </Text>
                        <Text tagName="span" variant="caption" className="text-greyish lp-ls5">
                          Provide the merchant's details to be used for their new account.
                        </Text>
                      </div>
                      <div className="lp-w-full mb-24">
                        <div className="custom-select">
                          <div className="country-block">{values.taxCountry === 'NZ' ? <NZFlag /> : <AUFlag />}</div>
                          <Select
                            label="Tax country"
                            onChange={(value) => setFieldValue('taxCountry', value)}
                            value={values.taxCountry}
                            options={[
                              {
                                text: 'Australia',
                                value: 'AU',
                              },
                              {
                                text: 'New Zealand',
                                value: 'NZ',
                              },
                            ]}
                            errorMessage={errors.taxCountry && touched.taxCountry ? errors.taxCountry : ''}
                            testId="tax-country"
                          />
                        </div>
                      </div>
                      <div className="lp-w-full mb-24">
                        <TextField
                          fullWidth
                          label="Email"
                          inputMode="email"
                          type="email"
                          id="contactEmail"
                          name="contactEmail"
                          testId="contactEmail"
                          onBlur={handleBlur}
                          value={values.contactEmail}
                          onChange={handleChange}
                          error={errors.contactEmail && touched.contactEmail ? errors.contactEmail : ''}
                          helperText="The email the merchant will use to activate their account."
                        />
                      </div>
                      <div className="lp-w-full mb-24">
                        <TextField
                          fullWidth
                          label="Trading name"
                          type="text"
                          id="businessName"
                          name="businessName"
                          testId="businessName"
                          onBlur={handleBlur}
                          value={values.businessName}
                          onChange={handleChange}
                          error={errors.businessName && touched.businessName ? errors.businessName : ''}
                          helperText="The brand or customer facing name of the business."
                        />
                      </div>

                      <div className="lp-w-full mb-24">
                        <Text alignment="left" variant="body-2" isEmphasised>
                          Dashboard URL path
                        </Text>
                        <Text tagName="span" variant="caption" className="text-greyish lp-ls5">
                          A unique URL path for merchants and customers to access their April dashboards, e.g.
                          meetapril.com/
                          <strong>example</strong>. Ensure the correct URL path is provided as this cannot be changed.
                        </Text>
                      </div>

                      <div className="lp-w-full mb-24">
                        <TextField
                          fullWidth
                          label="Dashboard URL path"
                          type="text"
                          id="slug"
                          name="slug"
                          testId="slug"
                          onBlur={handleBlur}
                          value={values.slug}
                          onChange={handleChange}
                          error={errors.slug && touched.slug ? errors.slug : ''}
                          helperText="4-255 lowercase and alphanumeric characters."
                          endAdornment={
                            errors.slug && touched.slug ? '' : <Icon className="text-primary" name="Tick" />
                          }
                        />
                      </div>

                      <s.WeakAnnouncement>
                        <Announcement variant="weak" hasDismissal={false}>
                          <Text alignment="left" variant="body-3" isEmphasised className="mb-4">
                            Important
                          </Text>
                          Ensure the above details are correct before continuing. Submitting this form will send the
                          merchant an email asking them to provide their business details for verification and to
                          complete account set-up.
                        </Announcement>
                      </s.WeakAnnouncement>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <s.MultiColumnRespBlock className="lp-w-full lp-justify-end">
                      <Button
                        onClick={() => modalToggle(!isOpen)}
                        type="button"
                        size="medium"
                        variant="ghost"
                        className="no-min-width"
                        disabled={isRequestLoading}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        size="medium"
                        variant="primary"
                        className="no-min-width"
                        disabled={isRequestLoading}
                        isLoading={isRequestLoading}
                      >
                        Submit
                      </Button>
                    </s.MultiColumnRespBlock>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        )}

        {showSuccessSection && (
          <AddMerchantSuccessView createdMerchantName={createdMerchantName} modalToggle={modalToggle} />
        )}
      </s.Wrapper>
    </Modal>
  );
}

export default AddMerchantModal;
