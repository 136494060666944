import ErrorPage from 'pages/common/error';
import { Route, Routes } from 'react-router-dom';

import DashboardRoutesList from '../constants';
import RoutesList from './constants';
import PayoutDetailPage from './details';
import PayoutLanding from './listing';
import PayoutBreakdownPage from './payout-breakdown-listing';

const PayoutRoutes = () => {
  return (
    <Routes>
      <Route path={RoutesList.PAYOUT_LANDING} caseSensitive={true} element={<PayoutLanding />} />
      <Route path={RoutesList.PAYOUT_DETAILS} caseSensitive={true} element={<PayoutDetailPage />} />
      <Route
        path={`${DashboardRoutesList.PAYOUT_BREAKDOWN_ROUTE}/${RoutesList.PAYOUT_BREAKDOWN}`}
        caseSensitive={true}
        element={<PayoutBreakdownPage />}
      />
      <Route
        path="*"
        element={<ErrorPage bodyTitle="Page Not Found" bodyText="The page you are trying to access doesn't exists" />}
      />
    </Routes>
  );
};

export default PayoutRoutes;
