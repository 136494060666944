declare global {
  interface Window {
    zE?: (action: string, event: string, parameters?: any) => undefined;
    zESettings: any;
  }
}

export const loadZendeskScript = async (): Promise<void> =>
  new Promise((resolve, reject) => {
    const key = 'b5157348-5a92-4116-85b0-7b9e810ea0ec';
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.type = 'text/javascript';
    script.onload = () => resolve();
    script.onerror = () => reject('Failed to load zendesk script');
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${key}`;
    document.head.appendChild(script);
  });

export const clearZendeskWidget = () => {
  try {
    window.zE?.('messenger', 'logoutUser');
  } catch (error) {
    console.warn(error);
  }
};

export const hideZendeskWidget = () => {
  try {
    window.zE?.('messenger', 'hide');
  } catch (error) {
    console.warn(error);
  }
};

export const hideOnCloseZendeskWidget = () => {
  try {
    window.zE?.('messenger:on', 'close', function () {
      hideZendeskWidget();
    });
  } catch (error) {
    console.warn(error);
  }
};

export const showZendeskWidget = () => {
  try {
    window.zE?.('messenger', 'show');
    window.zE?.('messenger', 'open');
  } catch (error) {
    console.warn(error);
  }
};

export const identifyZendeskUser = (name: string, email: string, organization: string) => {
  // TODO: https://developer.zendesk.com/api-reference/widget-messaging/web/authentication/#login
  // try {
  //   window.zE?.('messenger', 'loginUser', (callback: (token: string) => void) => {
  //     callback('token');
  //   });
  // } catch (error) {
  //   console.warn(error);
  // }
};

export const configZendeskWidget = () => {
  // TODO: remove
  // window.zE?.('webWidget', 'updateSettings', {
  //   webWidget: {
  //     launcher: {
  //       chatLabel: {
  //         '*': 'Live Chat',
  //       },
  //     },
  //     position: { horizontal: 'right' },
  //     offset: {
  //       horizontal: '10px',
  //       vertical: '10px',
  //       mobile: {
  //         horizontal: '0',
  //         vertical: '10px',
  //       },
  //     },
  //     color: {
  //       theme: '#793DE4',
  //     },
  //   },
  // });
};
