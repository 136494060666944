import { getMerchantBusinessRegistration } from 'api/merchant/onbording/step-01';
import MerchantLayout from 'layouts/merchant/MerchantLayout';
import ErrorComponent from 'pages/common/error/ErrorComponent';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/merchant/hooks';
import { setCurrentActiveStep } from 'redux/merchant/slice/merchantOnboardSlice';
import { getMerchantBaseUrl } from 'utils/getBaseUrl';
import getErrorMessage from 'utils/getErrorMessage';

import { Spinner } from '@limepayments/cosmic';

import OnboardingRoutesList from '../constants';
import PrivateSection from './company/private';
import PublicSection from './company/public';

function OnboardingStep05Page() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [fetchDetailLoader, setFetchDetailLoader] = useState<boolean>(true);
  const [businessType, setBusinessType] = useState<string>('');
  const [showPrivateSection, setShowPrivateSection] = useState<boolean>(false);
  const [companyType, setCompanyType] = useState<string>('');
  const [requiredErrorMessage, setRequiredErrorMessage] = useState<string>('');
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);

  const { merchantName, merchantId, apiBaseUri } = useAppSelector((state) => ({
    merchantName: state.config.merchantName,
    merchantId: state.config.merchantId,
    apiBaseUri: state.config.apiBaseUri,
  }));

  useEffect(() => {
    const fetchBusinessDetails = async () => {
      try {
        setFetchDetailLoader(true);

        const response = await getMerchantBusinessRegistration(apiBaseUri, merchantId);
        setBusinessType(response.businessType);
        setCompanyType(response.companyType);
      } catch (error) {
        setRequiredErrorMessage(getErrorMessage(error));
      } finally {
        setFetchDetailLoader(false);
      }
    };

    setRequiredErrorMessage('');
    fetchBusinessDetails();
  }, [apiBaseUri, merchantName, merchantId]);

  useEffect(() => {
    if (!fetchDetailLoader) {
      setIsPageLoading(false);
      dispatch(setCurrentActiveStep(6));

      if (businessType === 'company' && companyType === 'Private') {
        setShowPrivateSection(true);
      }

      if ((businessType === 'company' && companyType === 'Public') || businessType === 'soletrader') {
        setShowPrivateSection(false);
      }

      if (businessType !== 'company' && businessType !== 'soletrader') {
        navigate(`${getMerchantBaseUrl(merchantName)}/onboarding/${OnboardingRoutesList.ONBOARDING_STEP06_PAGE}`);
      }
    }
  }, [setIsPageLoading, fetchDetailLoader, dispatch, businessType, companyType, navigate, merchantName]);

  return (
    <MerchantLayout activeStepNumber={5} title="Onboarding - Verify Your Business Details">
      {!isPageLoading && requiredErrorMessage.length ? <ErrorComponent bodyText={requiredErrorMessage} /> : null}

      {isPageLoading && (
        <div className="spinner-wrapper">
          <Spinner variant="simple" isVisible label="Loading..." />
        </div>
      )}

      {!isPageLoading && !requiredErrorMessage.length && (
        <Fragment>
          {showPrivateSection && <PrivateSection businessType={businessType} />}
          {!showPrivateSection && <PublicSection isSoleTrader={businessType === 'soletrader'} />}
        </Fragment>
      )}
    </MerchantLayout>
  );
}

export default OnboardingStep05Page;
