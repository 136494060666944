import { sendResetPasswordEmail } from 'api/auth/platform-identity';
import RoutesList from 'config/constants';
import AuthLayout from 'layouts/auth/AuthLayout';
import ForgotPasswordConfirmationView from 'pages/auth/forgot-password/ForgotPasswordConfirmationView';
import ResetPasswordView from 'pages/auth/forgot-password/ForgotPasswordView';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/platform/hooks';
import { setLoginPending } from 'redux/platform/slice/configSlice';
import { getPlatformBaseUrl } from 'utils/getBaseUrl';
import getErrorMessage from 'utils/getErrorMessage';

import { Spinner } from '@limepayments/cosmic';

import AuthRouteList from '../constants';

type FormikFormValues = {
  email: string;
};

function ResetPasswordPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { platformName, apiBaseUri, marketplaceId } = useAppSelector((state) => ({
    platformName: state.config.platformName,
    apiBaseUri: state.config.apiBaseUri,
    marketplaceId: state.config.marketplaceId,
  }));
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [showForgotPasswordConfirmSection, setShowForgotPasswordConfirmSection] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<FormikFormValues>({
    email: '',
  });
  const [forgotPasswordRequestLoader, setForgotPasswordRequestLoader] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  useEffect(() => {
    setLoginPending(true);
  }, [dispatch]);

  useEffect(() => {
    if (marketplaceId) {
      setIsPageLoading(false);
    }
  }, [setIsPageLoading, marketplaceId]);

  const submitHandler = async (values: FormikFormValues, formObj: { resetForm: () => void }) => {
    try {
      setForgotPasswordRequestLoader(true);
      setErrorMessage('');

      await sendResetPasswordEmail(apiBaseUri, {
        marketplaceId: marketplaceId,
        emailAddress: values.email,
      });

      setInitialValues(initialValues);
      formObj.resetForm();

      setEmail(values.email);
      setShowForgotPasswordConfirmSection(true);
    } catch (err) {
      setErrorMessage(getErrorMessage(err));
    } finally {
      setForgotPasswordRequestLoader(false);
    }
  };

  return (
    <AuthLayout title="Reset password">
      <Fragment>
        {isPageLoading && (
          <div className="spinner-wrapper">
            <Spinner variant="simple" isVisible />
          </div>
        )}
        {!isPageLoading && (
          <Fragment>
            {!showForgotPasswordConfirmSection && (
              <ResetPasswordView
                onSubmitClick={submitHandler}
                onPreviousClick={() => {
                  navigate(`${getPlatformBaseUrl(platformName)}/${RoutesList.AUTH_ROUTE}/${AuthRouteList.LOGIN_PAGE}`);
                }}
                initialValues={initialValues}
                requestLoader={forgotPasswordRequestLoader}
                errorMsg={errorMessage}
              />
            )}

            {showForgotPasswordConfirmSection && (
              <ForgotPasswordConfirmationView
                onSubmitClick={() => setShowForgotPasswordConfirmSection(false)}
                email={email}
              />
            )}
          </Fragment>
        )}
      </Fragment>
    </AuthLayout>
  );
}

export default ResetPasswordPage;
