import { Heading, Icon, Text } from '@limepayments/cosmic';

interface Props {
  title: string;
  body: string;
}

function EmptyMerchantListComponent({ title, body }: Props) {
  return (
    <div className="transaction-empty">
      <div className="transaction-empty-inner">
        <Icon name="Business" />
        <Heading alignment="center" tagName="h2" variant="xs" className="mt-12 mb-8">
          {title}
        </Heading>
        <Text alignment="center" tagName="p" variant="body-2" className="text-dark-50 mt-0">
          {body}
        </Text>
      </div>
    </div>
  );
}

export default EmptyMerchantListComponent;
