import { useState } from 'react';
import { Popover } from 'react-tiny-popover';

import { Button, Icon } from '@limepayments/cosmic';

// Import Static UIs
import TableActionsDropdowns from './TableActionsDropdowns';

const PopoverComponent = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  return (
    <Popover
      isOpen={isPopoverOpen}
      align={'end'}
      positions={['bottom', 'top', 'left', 'right']} // preferred positions by priority
      reposition={false}
      content={
        <div className="t-action-dropdown w-204">
          <TableActionsDropdowns />
        </div>
      }
    >
      <div className="table-actions" onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
        <Button className="lp-w-full" size="small" variant="reversed">
          <Icon name="Ellipse" className="m-0" />
        </Button>
      </div>
    </Popover>
  );
};

export default PopoverComponent;
