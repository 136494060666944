import * as s from 'assets/styles/Onboarding.styles';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/merchant/hooks';
import { getMerchantBaseUrl } from 'utils/getBaseUrl';

import { Button, Heading, Icon, Text } from '@limepayments/cosmic';

import OnboardingRoutesList from '../../constants';
import { UpdateMerchantCustomerFacingDetailPayload } from '../../step-02/types';

interface Props {
  customerFacingDetail: UpdateMerchantCustomerFacingDetailPayload;
}

const CustomerFacingDetails = ({ customerFacingDetail }: Props) => {
  const navigate = useNavigate();

  const { merchantName } = useAppSelector((state) => ({
    merchantName: state.config.merchantName,
  }));

  return (
    <>
      <div className="lp-w-full lp-equal-width mb-24">
        <s.MultiColumnRespBlock className="content-header mb-24">
          <Heading alignment="left" tagName="h4" variant="sm">
            Customer facing details
          </Heading>
          <Button
            onClick={() => {
              navigate(`${getMerchantBaseUrl(merchantName)}/onboarding/${OnboardingRoutesList.ONBOARDING_STEP02_PAGE}`);
            }}
            size="medium"
            variant="ghost"
            className="no-min-width lp-justify-end"
            testId="customer-facing-detail-edit-button"
          >
            Edit
            <Icon name="Edit" />
          </Button>
        </s.MultiColumnRespBlock>
        <div className="content">
          <s.MultiColumnRespBlock className="mb-24" data-testid="trading-name-div">
            <Text tagName="p" variant="body-3" testId="trading-name-text">
              Trading name
            </Text>
            <Text tagName="p" variant="body-3" testId="trading-name-value" isEmphasised>
              {customerFacingDetail.brandName}
            </Text>
          </s.MultiColumnRespBlock>
          <s.MultiColumnRespBlock className="mb-24" data-testid="card-stat-div">
            <Text tagName="p" variant="body-3" testId="card-stat-text">
              Card statement name
            </Text>
            <Text tagName="p" variant="body-3" testId="card-stat-value" isEmphasised>
              {customerFacingDetail.cardStatementName}
            </Text>
          </s.MultiColumnRespBlock>
          <s.MultiColumnRespBlock className="mb-24" data-testid="sms-sender-div">
            <Text tagName="p" variant="body-3" testId="sms-sender-text">
              SMS sender name
            </Text>
            <Text tagName="p" variant="body-3" testId="sms-sender-value" isEmphasised>
              {customerFacingDetail.smsSenderName}
            </Text>
          </s.MultiColumnRespBlock>
          <s.MultiColumnRespBlock className="mb-24" data-testid="business-web-div">
            <Text tagName="p" variant="body-3" testId="business-web-text">
              Business website
            </Text>
            <Text tagName="p" variant="body-3" testId="business-web-value" isEmphasised>
              {customerFacingDetail.website}
            </Text>
          </s.MultiColumnRespBlock>
        </div>
      </div>
      <s.Separator>
        <hr />
      </s.Separator>
    </>
  );
};

export default CustomerFacingDetails;
