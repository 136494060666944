import 'react-phone-number-input/style.css';

import * as s from 'assets/styles/Onboarding.styles';
import { AustralianStates, StreetTypes } from 'config/constants';
import { ErrorMessage, Formik, FormikProps } from 'formik';
import { Fragment } from 'react';
import { RefObject, useEffect, useRef } from 'react';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import scrollToComponent from 'react-scroll-to-component';
import * as Yup from 'yup';

import { Button, Heading, Icon, Link, Message, Select, Text, TextField } from '@limepayments/cosmic';

import { UpdateMerchantBusinessContactPayload } from './types';

const validationSchema = Yup.object().shape({
  contactPerson: Yup.object().shape({
    firstName: Yup.string().trim().required('Please enter a first name'),
    lastName: Yup.string().trim().required('Please enter a last name'),
    title: Yup.string().trim().required('Please enter a job title'),
  }),
  contactEmail: Yup.string()
    .trim()
    .email('Please enter a valid email address')
    .required('Please enter a valid email address'),
  phoneNo: Yup.string()
    .trim()
    .required('Please enter a contact phone number')
    .test({
      name: 'phone-no-validation',
      test: function () {
        const { phoneNo } = this.parent;
        if (phoneNo && !isPossiblePhoneNumber(phoneNo))
          return this.createError({
            message: `Please enter a valid phone number`,
            path: `phoneNo`,
          });
        return true;
      },
    }),
  businessAddress: Yup.string().when('isManualEnterAddress', {
    is: false,
    then: Yup.string().trim().required('Please enter address'),
  }),
  address: Yup.object().when('isManualEnterAddress', {
    is: true,
    then: Yup.object().shape({
      streetNumber: Yup.string().trim().required('Please enter a street number'),
      streetName: Yup.string().trim().required('Please enter a street name'),
      streetType: Yup.string().trim().required('Please select a street type'),
      suburb: Yup.string().trim().required('Please enter a suburb'),
      city: Yup.string()
        .when('country', {
          is: 'NZ',
          then: Yup.string().nullable().trim().required('Please enter a city'),
        })
        .nullable(),
      state: Yup.string()
        .when('country', {
          is: 'AU',
          then: Yup.string().nullable().trim().required('Please select a state'),
        })
        .nullable(),
      postalCode: Yup.string()
        .trim()
        .required('Please enter a postal code')
        .min(4, 'Please enter between 4-10 characters')
        .max(10, 'Please enter between 4-10 characters')
        .matches(/^[0-9]+$/, 'Please enter only numeric characters'),
      country: Yup.string().required('Please enter a country'),
    }),
  }),
});

interface Props {
  onSubmitClick: (values: UpdateMerchantBusinessContactPayload, formObj: { resetForm: () => void }) => void;
  onPreviousClick: () => void;
  initialValues: UpdateMerchantBusinessContactPayload;
  requestLoader: boolean;
  errorMsg: string;
  formRef: RefObject<FormikProps<UpdateMerchantBusinessContactPayload>>;
  merchantTaxCountry: string;
  saveAndFinishLater: () => void;
  saveAndReview: boolean;
}

function Step03View({
  onSubmitClick,
  onPreviousClick,
  initialValues,
  requestLoader,
  errorMsg,
  formRef,
  merchantTaxCountry,
  saveAndFinishLater,
  saveAndReview,
}: Props) {
  const errorContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (errorMsg.length > 0 && errorContainerRef && errorContainerRef.current) {
      scrollToComponent(errorContainerRef.current, { duration: 500 });
    }
  }, [errorMsg]);

  return (
    <>
      <div className="content-wrap-inner">
        <header className="content-header">
          <div className="lp-w-full mb-24">
            <div className="lp-w-full mb-16">
              <Button
                size="small"
                variant="ghost"
                isNeutral
                className="back-button"
                type="button"
                onClick={onPreviousClick}
              >
                <Icon name="ArrowLeft" className="back-arrow" />
                Back
              </Button>
            </div>
            <Heading alignment="left" tagName="h2" variant="md" className="mb-24">
              Verify your business details
            </Heading>
            <Heading alignment="left" tagName="h4" variant="sm">
              Business contact details
            </Heading>
            {errorMsg.length > 0 && (
              <div className="mt-24 mb-24" ref={errorContainerRef}>
                <Message type="inline" children={<span className="text-wrap">{errorMsg}</span>} variant={'error'} />
              </div>
            )}
            <Text variant="body-3">Provide the business contact details for ongoing engagements.</Text>
          </div>
        </header>

        <Formik<UpdateMerchantBusinessContactPayload>
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={(values, formObj: { resetForm: () => void }) => {
            onSubmitClick(values, formObj);
          }}
          validationSchema={validationSchema}
          innerRef={formRef}
        >
          {({ values, handleSubmit, errors, touched, setFieldValue, handleBlur, handleChange }) => (
            <form autoComplete="off" onSubmit={handleSubmit} className="content-wrap-form">
              <div className="content-main">
                <div className="lp-w-full mb-0">
                  <ul className="two-col lp-flex">
                    <li className="mb-12">
                      <TextField
                        fullWidth
                        label="First name"
                        inputMode="text"
                        autoComplete="off"
                        id="contactPerson.firstName"
                        name="contactPerson.firstName"
                        onBlur={handleBlur}
                        value={values.contactPerson.firstName}
                        onChange={handleChange}
                        error={
                          errors.contactPerson &&
                          errors.contactPerson.firstName &&
                          touched.contactPerson &&
                          touched.contactPerson.firstName
                            ? errors.contactPerson.firstName
                            : ''
                        }
                      />
                    </li>
                    <li className="mb-12">
                      <TextField
                        fullWidth
                        label="Last name"
                        inputMode="text"
                        autoComplete="off"
                        id="contactPerson.lastName"
                        name="contactPerson.lastName"
                        onBlur={handleBlur}
                        value={values.contactPerson.lastName}
                        onChange={handleChange}
                        error={
                          errors.contactPerson &&
                          errors.contactPerson.lastName &&
                          touched.contactPerson &&
                          touched.contactPerson.lastName
                            ? errors.contactPerson.lastName
                            : ''
                        }
                      />
                    </li>
                  </ul>
                </div>
                <div className="lp-w-full mb-12">
                  <ul className="two-col lp-flex">
                    <li className="mb-12">
                      <TextField
                        fullWidth
                        label="Job title"
                        inputMode="text"
                        autoComplete="off"
                        id="contactPerson.title"
                        name="contactPerson.title"
                        onBlur={handleBlur}
                        value={values.contactPerson.title}
                        onChange={handleChange}
                        error={
                          errors.contactPerson &&
                          errors.contactPerson.title &&
                          touched.contactPerson &&
                          touched.contactPerson.title
                            ? errors.contactPerson.title
                            : ''
                        }
                      />
                    </li>
                  </ul>
                </div>
                <div className="lp-w-full mb-12">
                  <TextField
                    fullWidth
                    label="Contact email address"
                    inputMode="email"
                    type="email"
                    autoComplete="off"
                    id="contactEmail"
                    name="contactEmail"
                    onBlur={handleBlur}
                    value={values.contactEmail}
                    onChange={handleChange}
                    error={errors.contactEmail && touched.contactEmail ? errors.contactEmail : ''}
                  />
                </div>
                <div className={`lp-w-full mb-12${errors.phoneNo && touched.phoneNo ? ` has-error` : ``}`}>
                  <div className={`form-floating`}>
                    <PhoneInput
                      defaultCountry={merchantTaxCountry === 'NZ' ? 'NZ' : 'AU'}
                      country={merchantTaxCountry === 'NZ' ? 'NZ' : 'AU'}
                      value={values.phoneNo}
                      onChange={(e) => {
                        setFieldValue('phoneNo', e);
                      }}
                      onBlur={handleBlur}
                      id="phoneNo"
                      name="phoneNo"
                      className={`${values.phoneNo ? `is-filled` : `is-filled`}`}
                      countries={merchantTaxCountry === 'NZ' ? ['NZ'] : ['AU']}
                      international={true}
                      withCountryCallingCode={true}
                    />
                    <label htmlFor="phoneNo">Contact phone number</label>
                  </div>
                  <ErrorMessage name="phoneNo" component="span" className="Mui-error" />
                </div>

                {!values.isManualEnterAddress && (
                  <Fragment>
                    <div className="lp-w-full mb-12">
                      <TextField
                        fullWidth
                        label="Business address"
                        inputMode="text"
                        autoComplete="off"
                        id="businessAddress"
                        name="businessAddress"
                        onBlur={handleBlur}
                        value={values.businessAddress}
                        onChange={handleChange}
                        error={errors.businessAddress && touched.businessAddress ? errors.businessAddress : ''}
                      />
                      <Text alignment="left" variant="caption">
                        You can use a home address if the business doesn’t have a business address.
                      </Text>
                    </div>
                    <div className="mt-24">
                      <Link onClick={() => setFieldValue('isManualEnterAddress', true)} size="medium">
                        Manually enter address
                      </Link>
                    </div>
                  </Fragment>
                )}

                {values.isManualEnterAddress && (
                  <Fragment>
                    <header className="content-header mt-24 d-none">
                      <div className="lp-w-full mb-24">
                        <Heading alignment="left" tagName="h4" variant="sm">
                          Business address
                        </Heading>
                        <Text variant="body-3">
                          You can use a home address if the business doesn’t have a business address.
                        </Text>
                      </div>
                    </header>

                    <div className="lp-w-full mb-12 d-none">
                      <ul className="two-col lp-flex lp-flex-wrap lp-flex-column">
                        <li
                          className={`mb-12${
                            errors.address && errors.address.country && touched.address && touched.address.country
                              ? ` has-error`
                              : ``
                          }`}
                        >
                          <Select
                            label="Country"
                            testId="step03Country"
                            onChange={(value) => setFieldValue('address.country', value)}
                            value={values.address.country}
                            options={[
                              merchantTaxCountry === 'NZ'
                                ? {
                                    text: 'New Zealand',
                                    value: 'NZ',
                                  }
                                : {
                                    text: 'Australia',
                                    value: 'AU',
                                  },
                            ]}
                            errorMessage={
                              errors.address && errors.address.country && touched.address && touched.address.country
                                ? errors.address.country
                                : ''
                            }
                          />
                        </li>
                        <li className="mb-12">
                          <TextField
                            fullWidth
                            label="Unit number (optional)"
                            inputMode="text"
                            autoComplete="off"
                            id="address.unitNumber"
                            name="address.unitNumber"
                            onBlur={handleBlur}
                            value={values.address.unitNumber}
                            onChange={handleChange}
                            error={
                              errors.address &&
                              errors.address.unitNumber &&
                              touched.address &&
                              touched.address.unitNumber
                                ? errors.address.unitNumber
                                : ''
                            }
                          />
                        </li>
                        <li className="mb-12">
                          <TextField
                            fullWidth
                            label="Street number"
                            inputMode="text"
                            autoComplete="off"
                            id="address.streetNumber"
                            name="address.streetNumber"
                            onBlur={handleBlur}
                            value={values.address.streetNumber}
                            onChange={handleChange}
                            error={
                              errors.address &&
                              errors.address.streetNumber &&
                              touched.address &&
                              touched.address.streetNumber
                                ? errors.address.streetNumber
                                : ''
                            }
                          />
                        </li>
                        <li className="mb-12">
                          <TextField
                            fullWidth
                            label="Street name"
                            inputMode="text"
                            autoComplete="off"
                            id="address.streetName"
                            name="address.streetName"
                            onBlur={handleBlur}
                            value={values.address.streetName}
                            onChange={handleChange}
                            error={
                              errors.address &&
                              errors.address.streetName &&
                              touched.address &&
                              touched.address.streetName
                                ? errors.address.streetName
                                : ''
                            }
                          />
                        </li>
                        <li
                          className={`mb-12 ${
                            errors.address && errors.address.streetType && touched.address && touched.address.streetType
                              ? ` has-error`
                              : ``
                          }`}
                        >
                          <Select
                            label="Street type"
                            testId="step03StreetType"
                            onChange={(value) => setFieldValue('address.streetType', value)}
                            value={values.address.streetType}
                            options={StreetTypes}
                            errorMessage={
                              errors.address &&
                              errors.address.streetType &&
                              touched.address &&
                              touched.address.streetType
                                ? errors.address.streetType
                                : ''
                            }
                          />
                        </li>
                        <li className="mb-12">
                          <TextField
                            fullWidth
                            label="Suburb"
                            inputMode="text"
                            autoComplete="off"
                            id="address.suburb"
                            name="address.suburb"
                            onBlur={handleBlur}
                            value={values.address.suburb}
                            onChange={handleChange}
                            error={
                              errors.address && errors.address.suburb && touched.address && touched.address.suburb
                                ? errors.address.suburb
                                : ''
                            }
                          />
                        </li>
                        {values.address.country === 'NZ' && (
                          <li
                            className={`mb-12 ${
                              errors.address && errors.address.city && touched.address && touched.address.city
                                ? ` has-error`
                                : ``
                            }`}
                          >
                            <TextField
                              fullWidth
                              label="City"
                              inputMode="text"
                              autoComplete="off"
                              id="address.city"
                              name="address.city"
                              onBlur={handleBlur}
                              value={values.address.city ?? ''}
                              onChange={handleChange}
                              error={
                                errors.address && errors.address.city && touched.address && touched.address.city
                                  ? errors.address.city
                                  : ''
                              }
                            />
                          </li>
                        )}

                        {values.address.country === 'AU' && (
                          <li
                            className={`mb-12 ${
                              errors.address && errors.address.state && touched.address && touched.address.state
                                ? ` has-error`
                                : ``
                            }`}
                          >
                            <Select
                              label="State"
                              testId="step03State"
                              onChange={(value) => setFieldValue('address.state', value)}
                              value={values.address.state ?? ''}
                              options={AustralianStates}
                              errorMessage={
                                errors.address && errors.address.state && touched.address && touched.address.state
                                  ? errors.address.state
                                  : ''
                              }
                            />
                          </li>
                        )}
                        <li className="mb-12">
                          <TextField
                            fullWidth
                            label="Postcode"
                            inputMode="text"
                            autoComplete="off"
                            id="address.postalCode"
                            name="address.postalCode"
                            onBlur={handleBlur}
                            value={values.address.postalCode}
                            onChange={handleChange}
                            error={
                              errors.address &&
                              errors.address.postalCode &&
                              touched.address &&
                              touched.address.postalCode
                                ? errors.address.postalCode
                                : ''
                            }
                          />
                        </li>
                      </ul>
                    </div>
                  </Fragment>
                )}
              </div>
              <s.Cta className="lp-flex lp-justify-end lp-items-center sm:flex-col">
                <Button
                  size="medium"
                  variant="primary"
                  className="no-min-width lp-full"
                  isLoading={requestLoader}
                  disabled={requestLoader}
                  onClick={() => setFieldValue('isSaveFinishLater', false)}
                >
                  Save and {saveAndReview ? `review` : `continue`}
                  <Icon name="ArrowRight" />
                </Button>
                <div className="mobile-sidebar-footer">
                  <Button size="medium" onClick={saveAndFinishLater} variant="ghost" className="lp-w-full">
                    <Icon name="Save" />
                    Save and finish later
                  </Button>
                </div>
              </s.Cta>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default Step03View;
