import ErrorPage from 'pages/common/error';
import { Route, Routes } from 'react-router-dom';

import { PayInvoicePage } from './PayInvoicePage';

export const InvoiceRoutesList = {
  PAY_INVOICE_PAGE: 'invoice/:invoiceId/order/:orderId/pay',
};

export const InvoiceRoutes = () => {
  return (
    <Routes>
      <Route path={InvoiceRoutesList.PAY_INVOICE_PAGE} caseSensitive={true} element={<PayInvoicePage />} />
      <Route
        path="*"
        element={<ErrorPage bodyTitle="Page Not Found" bodyText="The page you are trying to access doesn't exists" />}
      />
    </Routes>
  );
};
