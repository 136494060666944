import ErrorPage from 'pages/common/error';
import { Route, Routes } from 'react-router-dom';

import RoutesList from './constants';
import MerchantDetailPage from './details';
import MerchantListing from './listing';

const MerchantRoutes = () => {
  return (
    <Routes>
      <Route path={RoutesList.MERCHANT_LANDING} caseSensitive={true} element={<MerchantListing />} />
      <Route path={`${RoutesList.MERCHANT_DETAIL}/:merchantId`} caseSensitive={true} element={<MerchantDetailPage />} />
      <Route
        path="*"
        element={<ErrorPage bodyTitle="Page Not Found" bodyText="The page you are trying to access doesn't exists" />}
      />
    </Routes>
  );
};

export default MerchantRoutes;
