import DashboardRoutesList from 'pages/platform/dashboard/constants';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/platform/hooks';
import { getPlatformBaseUrl } from 'utils/getBaseUrl';

import MerchantRoutesList from '../../constants';

interface Props {
  merchantId: string;
  businessName: string;
}

const DataTableMerchantRow = ({ merchantId, businessName }: Props) => {
  const navigate = useNavigate();

  const { platformName } = useAppSelector((state) => ({
    platformName: state.config.platformName,
  }));

  return (
    <button
      className="sm:text-left"
      onClick={() =>
        navigate(
          `${getPlatformBaseUrl(platformName)}/dashboard/${DashboardRoutesList.MERCHANT_ROUTE}/${
            MerchantRoutesList.MERCHANT_DETAIL
          }/${merchantId}`,
        )
      }
    >
      {businessName}
    </button>
  );
};

export default DataTableMerchantRow;
