import { handleResponse } from 'api/utils';
import { UpdateMerchantCustomerFacingDetailPayload } from 'pages/merchant/onboarding/step-02/types';
import getFetchOptions from 'utils/getFetchOptions';

import { GetMerchantCustomerFacingDetailResponse } from './step-02.types';

export const getMerchantCustomerFacingDetail = async (
  host: string,
  merchantId: string,
): Promise<GetMerchantCustomerFacingDetailResponse> => {
  const options = await getFetchOptions();
  return await fetch(`${host}/onboarding/${merchantId}/business/customer-facing-details`, options).then(handleResponse);
};

export const updateMerchantCustomerFacingDetail = async (
  host: string,
  merchantId: string,
  payload: UpdateMerchantCustomerFacingDetailPayload,
): Promise<void> => {
  const options = await getFetchOptions('POST', { payload });
  return await fetch(`${host}/onboarding/${merchantId}/business/customer-facing-details`, options).then(handleResponse);
};
