import {
  getInitialFilterState,
  getInitialListingPageState,
} from 'pages/platform/dashboard/merchant/listing/initialStates';
import { IFilterObj, IListingPagePropsObj } from 'pages/platform/dashboard/merchant/listing/types';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IConfig {
  openFilterDropdown: boolean;
  merchantFilterObj: IFilterObj;
  listingPageProps: IListingPagePropsObj;
}

const initialState: IConfig = {
  openFilterDropdown: false,
  merchantFilterObj: getInitialFilterState(),
  listingPageProps: getInitialListingPageState(),
};

export const merchantSlice = createSlice({
  name: 'merchantConfig',
  initialState,
  reducers: {
    setOpenFilterDropdown: (state, action: PayloadAction<boolean>) => {
      state.openFilterDropdown = action.payload;
    },
    setMerchantFilterObj: (state, action: PayloadAction<IFilterObj>) => {
      state.merchantFilterObj = action.payload;
    },
    setListingPagePropsObj: (state, action: PayloadAction<IListingPagePropsObj>) => {
      state.listingPageProps = action.payload;
    },
  },
});

export const { setOpenFilterDropdown, setMerchantFilterObj, setListingPagePropsObj } = merchantSlice.actions;

export default merchantSlice.reducer;
