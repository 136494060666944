import {
  getListMerchantCompanyOwners,
  getListMerchantPartnershipOwners,
  getListMerchantPerson,
  getListMerchantTrustOwners,
} from 'api/merchant/onbording/step-05';
import {
  GetListMerchantCompanyOwnersObj,
  GetListMerchantPartnershipOwnersObj,
  GetListMerchantPersonObj,
  GetListMerchantTrustOwnersObj,
} from 'api/merchant/onbording/step-05.types';
import AuthRoutesList from 'pages/merchant/auth/constants';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/merchant/hooks';
import { setSaveAndFinishLater } from 'redux/merchant/slice/merchantOnboardSlice';
import { getMerchantBaseUrl } from 'utils/getBaseUrl';
import getErrorMessage from 'utils/getErrorMessage';

import { Spinner } from '@limepayments/cosmic';

import OnboardingRoutesList from '../../../constants';
import Step05View from './Step05View';

interface Props {
  businessType: string;
}

function PrivateSection({ businessType }: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [requiredErrorMessage, setRequiredErrorMessage] = useState<string>('');
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [fetchMerchantOwnerListLoader, setFetchMerchantOwnerListLoader] = useState<boolean>(true);
  const [merchantPersonList, setMerchantPersonList] = useState<Array<GetListMerchantPersonObj>>([]);
  const [merchantCompanyOwnersList, setMerchantCompanyOwnersList] = useState<Array<GetListMerchantCompanyOwnersObj>>(
    [],
  );
  const [merchantPartnershipOwnersList, setMerchantPartnershipOwnersList] = useState<
    Array<GetListMerchantPartnershipOwnersObj>
  >([]);
  const [merchantTrustOwnersList, setMerchantTrustOwnersList] = useState<Array<GetListMerchantTrustOwnersObj>>([]);
  const [hasOwners, setHasOwners] = useState<string>('');
  const [reloadData, setReloadData] = useState<boolean>(true);
  const [isMounted, setIsMounted] = useState<boolean>(false);

  const { merchantName, merchantId, apiBaseUri, saveAndFinishLater } = useAppSelector((state) => ({
    merchantName: state.config.merchantName,
    merchantId: state.config.merchantId,
    apiBaseUri: state.config.apiBaseUri,
    saveAndFinishLater: state.onboarding.saveAndFinishLater,
  }));

  const filterMerchantList = async (id: string, section: string, removeAll?: boolean) => {
    if (removeAll) {
      await filterMerchantPersonList('', true);
      await filterMerchantTrustOwnersList('', true);
      await filterMerchantPartnershipOwnersList('', true);
      await filterMerchantCompanyOwnersList('', true);
      return;
    }

    switch (section) {
      case 'individual':
        await filterMerchantPersonList(id, removeAll);
        break;
      case 'trust':
        await filterMerchantTrustOwnersList(id, removeAll);
        break;
      case 'partnership':
        await filterMerchantPartnershipOwnersList(id, removeAll);
        break;
      case 'company':
      default:
        await filterMerchantCompanyOwnersList(id, removeAll);
        break;
    }
  };

  const filterMerchantPersonList = async (id: string, removeAll?: boolean) => {
    const filteredList = !removeAll
      ? merchantPersonList.filter((obj) => {
          return obj.personId !== id;
        })
      : [];

    setMerchantPersonList(filteredList);
  };

  const filterMerchantPartnershipOwnersList = async (id: string, removeAll?: boolean) => {
    const filteredList = !removeAll
      ? merchantPartnershipOwnersList.filter((obj) => {
          return obj.partnershipId !== id;
        })
      : [];

    setMerchantPartnershipOwnersList(filteredList);
  };

  const filterMerchantTrustOwnersList = async (id: string, removeAll?: boolean) => {
    const filteredList = !removeAll
      ? merchantTrustOwnersList.filter((obj) => {
          return obj.trustId !== id;
        })
      : [];

    setMerchantTrustOwnersList(filteredList);
  };

  const filterMerchantCompanyOwnersList = async (id: string, removeAll?: boolean) => {
    const filteredList = !removeAll
      ? merchantCompanyOwnersList.filter((obj) => {
          return obj.companyId !== id;
        })
      : [];

    setMerchantCompanyOwnersList(filteredList);
  };

  const refreshData = () => {
    setReloadData(true);
  };

  const fetchMerchantOwnerListHandler = useCallback(async () => {
    try {
      setIsPageLoading(true);
      setFetchMerchantOwnerListLoader(true);

      const response = await Promise.allSettled([
        await getListMerchantPerson(apiBaseUri, merchantId),
        await getListMerchantCompanyOwners(apiBaseUri, merchantId),
        await getListMerchantPartnershipOwners(apiBaseUri, merchantId),
        await getListMerchantTrustOwners(apiBaseUri, merchantId),
      ]);
      let hasOwner = '';

      if (response[0].status === 'fulfilled') {
        const personResponse = response[0] as PromiseFulfilledResult<GetListMerchantPersonObj[]>;
        setMerchantPersonList(personResponse.value);
        if (personResponse.value.length > 0) {
          const directorRecords = personResponse.value.filter((obj: GetListMerchantPersonObj) => {
            return obj.profile.roles.includes('Director');
          });

          hasOwner = directorRecords.length > 0 ? 'no' : 'yes';
        }
      }

      // COMPANIES
      if (response[1].status === 'fulfilled') {
        const companyResponse = response[1] as PromiseFulfilledResult<GetListMerchantCompanyOwnersObj[]>;
        setMerchantCompanyOwnersList(companyResponse.value);
        hasOwner = companyResponse.value.length > 0 ? 'yes' : hasOwner;
      }

      // Partnership
      if (response[2].status === 'fulfilled') {
        const partnershipResponse = response[2] as PromiseFulfilledResult<GetListMerchantPartnershipOwnersObj[]>;
        setMerchantPartnershipOwnersList(partnershipResponse.value);
        hasOwner = partnershipResponse.value.length > 0 ? 'yes' : hasOwner;
      }

      // TRUST
      if (response[3].status === 'fulfilled') {
        const trustResponse = response[3] as PromiseFulfilledResult<GetListMerchantTrustOwnersObj[]>;
        setMerchantTrustOwnersList(trustResponse.value);
        hasOwner = trustResponse.value.length > 0 ? 'yes' : hasOwner;
      }

      setReloadData(false);
      setHasOwners(hasOwner);
      if (!isMounted) {
        setIsMounted(true);
      }
    } catch (error) {
      setRequiredErrorMessage(getErrorMessage(error));
    } finally {
      setFetchMerchantOwnerListLoader(false);
    }
  }, [apiBaseUri, merchantId, isMounted]);

  useEffect(() => {
    if (!fetchMerchantOwnerListLoader) {
      setIsPageLoading(false);
    }
  }, [setIsPageLoading, fetchMerchantOwnerListLoader]);

  useEffect(() => {
    if (businessType && reloadData) {
      fetchMerchantOwnerListHandler();
    }
  }, [businessType, fetchMerchantOwnerListHandler, reloadData]);

  useEffect(() => {
    if (saveAndFinishLater) {
      navigate(`${getMerchantBaseUrl(merchantName)}/${AuthRoutesList.LOGOUT_PAGE}`);
    }
  }, [saveAndFinishLater, merchantName, navigate]);

  return (
    <>
      {isPageLoading && (
        <div className="spinner-wrapper">
          <Spinner variant="simple" isVisible label="Loading..." />
        </div>
      )}

      {!isPageLoading && (
        <Step05View
          onSubmitClick={() => {
            navigate(`${getMerchantBaseUrl(merchantName)}/onboarding/${OnboardingRoutesList.ONBOARDING_STEP06_PAGE}`);
          }}
          onPreviousClick={() => {
            navigate(`${getMerchantBaseUrl(merchantName)}/onboarding/${OnboardingRoutesList.ONBOARDING_STEP04_PAGE}`);
          }}
          setHasOwner={setHasOwners}
          hasOwner={hasOwners}
          merchantPersonList={merchantPersonList}
          merchantCompanyOwnersList={merchantCompanyOwnersList}
          merchantPartnershipOwnersList={merchantPartnershipOwnersList}
          merchantTrustOwnersList={merchantTrustOwnersList}
          refreshData={refreshData}
          requiredErrorMessage={requiredErrorMessage}
          filterMerchantList={filterMerchantList}
          isMounted={isMounted}
          saveAndFinishLater={() => {
            dispatch(setSaveAndFinishLater(Date.now()));
          }}
        />
      )}
    </>
  );
}

export default PrivateSection;
