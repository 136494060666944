import { firebaseApp } from './firebase';

export const login = async (tenantId: string, email: string, password: string) => {
  const firebaseAuth = firebaseApp.auth();
  firebaseAuth.tenantId = tenantId;
  await firebaseAuth.setPersistence('session');
  return firebaseAuth.signInWithEmailAndPassword(email, password).catch(function (error) {
    if (
      error.code === 'auth/user-not-found' ||
      error.code === 'auth/wrong-password' ||
      error.code === 'auth/user-disabled'
    ) {
      throw new Error('The login credentials attempted are incorrect, please try again');
    }
    throw error;
  });
};

export const logout = async () => {
  const firebaseAuth = firebaseApp.auth();
  return firebaseAuth.signOut();
};

export const loginWithToken = async (tenantId: string, token: string) => {
  const firebaseAuth = firebaseApp.auth();
  firebaseAuth.tenantId = tenantId;
  await firebaseAuth.setPersistence('session');
  return firebaseAuth.signInWithCustomToken(token);
};
