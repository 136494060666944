import DashboardRoutesList from 'pages/platform/dashboard/constants';
import MerchantRoutesList from 'pages/platform/dashboard/merchant/constants';
import PaymentRoutesList from 'pages/platform/dashboard/payment/constants';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/platform/hooks';
import { getPlatformBaseUrl } from 'utils/getBaseUrl';
import { showZendeskWidget } from 'utils/zendesk';

import { Icon, Text } from '@limepayments/cosmic';

import SettingsDropdown from './SettingsDropdown';
import ToggleSidebar from './ToggleSidebar';

interface Props {
  activeTab?: string;
}

function Header({ activeTab }: Props) {
  const navigate = useNavigate();
  const { platformName } = useAppSelector((state) => ({
    platformName: state.config.platformName,
  }));

  const [isSettingOpened, setIsSettingOpened] = useState(false);

  function toggleSetting() {
    setIsSettingOpened((wasSettingOpened) => !wasSettingOpened);
  }

  return (
    <div className="container container-sm">
      <div className="header-main">
        <div className="header-main-inner">
          <div className="header-logo">
            <div className="avatar puma">
              <Text alignment="left" tagName="p" variant="body-3" isEmphasised>
                {platformName.substring(0, 1).toUpperCase()}
              </Text>
            </div>
            <div className="header-logo-text">
              <Text alignment="left" tagName="p" variant="body-2" isEmphasised>
                {platformName.length > 10
                  ? `${platformName.substring(0, 4).toUpperCase()}...
                ${platformName.slice(-3).toUpperCase()}`
                  : platformName.toUpperCase()}
              </Text>
            </div>
          </div>
          <ul className="header-menu">
            <li
              className={`header-menu-items ${activeTab === `payment` ? `is-active` : ``}`}
              onClick={() =>
                navigate(
                  `${getPlatformBaseUrl(platformName)}/dashboard/${DashboardRoutesList.PAYMENT_ROUTE}/${
                    PaymentRoutesList.PAYMENT_LANDING
                  }`,
                )
              }
            >
              <Text alignment="center" tagName="p" variant="body-3" testId="payment-menu-link">
                Payments
              </Text>
            </li>
            <li
              className={`header-menu-items ${activeTab === `merchant` ? `is-active` : ``}`}
              onClick={() =>
                navigate(
                  `${getPlatformBaseUrl(platformName)}/dashboard/${DashboardRoutesList.MERCHANT_ROUTE}/${
                    MerchantRoutesList.MERCHANT_LANDING
                  }`,
                )
              }
            >
              <Text alignment="center" tagName="p" variant="body-3" testId="merchant-menu-link">
                Merchants
              </Text>
            </li>
          </ul>
        </div>
        <ul className="header-main-action">
          <li
            className={`header-menu-items p-0 ${activeTab === `help` ? `is-active` : ``}`}
            onClick={showZendeskWidget}
          >
            <span>
              <Icon name="Help" />
            </span>
          </li>
          {/* <li
            className={`header-menu-items p-0 ${activeTab === `settings` ? `is-active` : ``}`}
            onClick={() =>
              navigate(
                `${getPlatformBaseUrl(platformName)}/dashboard/${DashboardRoutesList.SETTINGS_ROUTE}/${
                  SettingRoutesList.SETTINGS_BRANDING
                }`,
              )
            }
          >
            <span>
              <Icon name="Settings" />
            </span>
          </li> */}
          <li
            className={`header-menu-items p-0 ${activeTab === `settings` ? `is-active` : ``}`}
            onClick={toggleSetting}
          >
            <span>
              <Icon name="Settings" testId="setting-menu-icon" />
            </span>
          </li>
        </ul>
        <ToggleSidebar activeTab={activeTab} />

        {/* Setting Dropdown */}
        {isSettingOpened && (
          <div className="setting-dropdown">
            <SettingsDropdown onCloseSettingClick={toggleSetting} />
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
