import { Heading, Illustration, Text } from '@limepayments/cosmic';

interface Props {
  onSubmitClick: () => void;
}

function AccountLinkInvalidView({ onSubmitClick }: Props) {
  return (
    <>
      <div className="size-136 mx-auto mb-32">
        <Illustration name="Time" size="large" />
      </div>
      <div className="lp-w-full mb-32">
        <Heading alignment="center" tagName="h1" variant="md" className="mb-12">
          Account activation link invalid
        </Heading>
        <Text tagName="p" variant="body-3" alignment="center" className="mb-0">
          Your token passed to activate account is not valid.
        </Text>
      </div>
    </>
  );
}

export default AccountLinkInvalidView;
