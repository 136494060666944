import { getLimepayApiHost } from 'utils/env';
import getFetchOptions from 'utils/getFetchOptions';

import { handleResponse } from '../utils';
import { GetMerchantConfigResponse, GetMerchantStatusResponse, GetPersonalSettingsResponse } from './merchant.types';

export const getMerchantConfig = async (slug: string): Promise<GetMerchantConfigResponse> =>
  fetch(`${await getLimepayApiHost()}/config/merchant?m=${slug}`).then(handleResponse);

export const getMerchantStatus = async (host: string, merchantId: string): Promise<GetMerchantStatusResponse> =>
  fetch(`${host}/merchants/${merchantId}/status`, await getFetchOptions()).then(handleResponse);

export const getPersonalSettings = async (host: string, merchantId: string): Promise<GetPersonalSettingsResponse> =>
  fetch(`${host}/merchants/${merchantId}/settings/personal`, await getFetchOptions()).then(handleResponse);
