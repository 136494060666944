import { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/merchant/hooks';
import { getMerchantBaseUrl } from 'utils/getBaseUrl';

import RoutesList, { OnboardingRoutesList } from './constants';
import IntroductionPage from './introduction';
import OnboardingStep01Page from './step-01';
import OnboardingStep02Page from './step-02';
import OnboardingStep03Page from './step-03';
import OnboardingStep04Page from './step-04';
import OnboardingStep05Page from './step-05';
import OnboardingStep06Page from './step-06';
import OnboardingStep07Page from './step-07';

const OnboardingRoutes = () => {
  const navigate = useNavigate();

  const { merchantName, currentActiveStep } = useAppSelector((state) => ({
    merchantName: state.config.merchantName,
    currentActiveStep: state.onboarding.currentActiveStep,
  }));

  useEffect(() => {
    if (currentActiveStep === 0) {
      navigate(`${getMerchantBaseUrl(merchantName)}/onboarding/${OnboardingRoutesList.ONBOARDING_PAGE}`);
      return;
    }
  }, [navigate, currentActiveStep, merchantName]);

  return (
    <Routes>
      <Route path={RoutesList.ONBOARDING_PAGE} caseSensitive={true} element={<IntroductionPage />} />
      <Route path={RoutesList.ONBOARDING_STEP01_PAGE} caseSensitive={true} element={<OnboardingStep01Page />} />
      <Route path={RoutesList.ONBOARDING_STEP02_PAGE} caseSensitive={true} element={<OnboardingStep02Page />} />
      <Route path={RoutesList.ONBOARDING_STEP03_PAGE} caseSensitive={true} element={<OnboardingStep03Page />} />
      <Route path={RoutesList.ONBOARDING_STEP04_PAGE} caseSensitive={true} element={<OnboardingStep04Page />} />
      <Route path={RoutesList.ONBOARDING_STEP05_PAGE} caseSensitive={true} element={<OnboardingStep05Page />} />
      <Route path={RoutesList.ONBOARDING_STEP06_PAGE} caseSensitive={true} element={<OnboardingStep06Page />} />
      <Route path={RoutesList.ONBOARDING_STEP07_PAGE} caseSensitive={true} element={<OnboardingStep07Page />} />
    </Routes>
  );
};

export default OnboardingRoutes;
