import * as s from 'assets/styles/Platforms.styles';
import { Fragment, useState } from 'react';

import { Button, Heading, Icon, Modal, Text } from '@limepayments/cosmic';

function DownloadModalComponent() {
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  return (
    <Fragment>
      <button className="download-button" onClick={() => setShowDownloadModal(true)}>
        <Icon name="Download" />
      </button>
      <Modal isOpen={showDownloadModal} className="modal-small update-pass-modal">
        <s.Wrapper className="">
          <div className="modal-main p-24">
            <div className="modal-header">
              <div className="content-main bb-1">
                <Heading alignment="center" tagName="h2" variant="xs" className="mt-12 mb-24 lp-justify-center lp-flex">
                  Download CSV
                </Heading>
              </div>
            </div>
            <div className="modal-body pt-24">
              <Text alignment="center" tagName="p" variant="body-3" className="lp-neutral-600">
                Download CSV of payout_YyflTg6UXTR6V6 of <strong>6,018.50</strong> AUD available on{' '}
                <strong>15 May 2022</strong>.
              </Text>
            </div>
            <div className="modal-footer lp-flex lp-justify-end sm-mt-24">
              <Button
                onClick={() => setShowDownloadModal(false)}
                size="medium"
                variant="ghost"
                className="no-min-width w-70"
              >
                Cancel
              </Button>
              <Button type="submit" size="medium" variant="primary" className="no-min-width">
                Download CSV
                <Icon name="Download" />
              </Button>
            </div>
          </div>
        </s.Wrapper>
      </Modal>
    </Fragment>
  );
}

export default DownloadModalComponent;
