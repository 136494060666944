import * as s from 'assets/styles/Onboarding.styles';

import { Button, Heading, Illustration, Text } from '@limepayments/cosmic';

interface Props {
  modalToggle: (val: boolean) => void;
  createdMerchantName: string;
}

function AddMerchantSuccessView({ modalToggle, createdMerchantName }: Props) {
  return (
    <s.Wrapper>
      <div className="modal-main">
        <div className="modal-header">
          <div className="content-main">
            <Heading alignment="center" tagName="h2" variant="xxs" className="mb-24 lp-justify-center lp-flex">
              Add a new merchant
            </Heading>
            <s.Separator>
              <hr />
            </s.Separator>
          </div>
        </div>
        <div className="modal-content">
          <div className="lp-w-full" style={{ display: 'block' }}>
            <s.Wrapper>
              <div className="lp-w-full mb-0">
                <div className="size-160">
                  <Illustration name="Success" size="medium" />
                </div>
              </div>
              <header className="content-header mt-24">
                <div className="lp-w-full mb-24">
                  <Heading alignment="left" tagName="h4" variant="xs" testId="addMerchantSuccessHeading">
                    Merchant account details submitted
                  </Heading>
                  <Text tagName="p" variant="body-3" testId="addMerchantSuccessText">
                    An email will be sent to <b>{createdMerchantName}</b> asking them to provide their business details
                    for verification and to complete account set-up.
                  </Text>
                </div>
              </header>
            </s.Wrapper>
          </div>
        </div>
        <div className="modal-footer">
          <s.MultiColumnRespBlock className="lp-w-full lp-justify-end">
            <Button
              onClick={() => modalToggle(false)}
              size="medium"
              variant="ghost"
              className="no-min-width"
              testId="addMerchantSuccessBtn"
            >
              Close
            </Button>
          </s.MultiColumnRespBlock>
        </div>
      </div>
    </s.Wrapper>
  );
}

export default AddMerchantSuccessView;
