export const MerchantRoutesList = {
  MERCHANT_LANDING: 'all',
  MERCHANT_DETAIL: 'detail',
};

export const MerchantStatusObj = {
  Active: 'Active',
  Pending: 'Pending',
  Suspended: 'Suspended',
};

export const ExportColumnsObj = {
  MerchantTradingName: 'Merchant Trading Name',
  MerchantId: 'Merchant Id',
  Status: 'Status',
  LegalEntityName: 'Legal Entity Name',
  BusinessRegistrationNumber: 'Business Registration Number',
  BusinessType: 'Business Type',
  Country: 'Country',
  DateAdded: 'Date Added',
  DateActive: 'Date Active',
  DateSuspended: 'Date Suspended',
  AccountEmail: 'Account Email',
  ContactEmailAddress: 'Contact Email Address',
  ContactPhoneNumber: 'Contact Phone Number',
  InformationNeeded: 'Information Needed',
};

export default MerchantRoutesList;
