import { createMerchantCompanyOwner, updateMerchantCompanyOwner } from 'api/merchant/onbording/step-05';
import * as s from 'assets/styles/Onboarding.styles';
import { Formik, FormikProps } from 'formik';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useAppSelector } from 'redux/merchant/hooks';
import getErrorMessage from 'utils/getErrorMessage';
import * as Yup from 'yup';

import { Button, Icon, Message, RadioGroup, Select, TextField } from '@limepayments/cosmic';

import { CreateMerchantCompanyOwnerPayload } from '../../../types';

const validationSchema = Yup.object().shape({
  companyType: Yup.string().trim().required('Please select a company type'),
  taxCountry: Yup.string().trim().required('Please select a country'),
  taxId: Yup.string().trim().required('Please enter a valid business registration number'),
  businessName: Yup.string().trim().required('Please enter a valid legal entity name'),
});

interface Props {
  isOpen: boolean;
  toggleModal: (val: boolean) => void;
  setCurrentStep: (val: number) => void;
  refreshData: () => void;
  updateItem: boolean;
  editObjValues: CreateMerchantCompanyOwnerPayload | null;
  selectedOwnerEditId: string;
}

function CompanySection({
  setCurrentStep,
  toggleModal,
  isOpen,
  refreshData,
  updateItem,
  editObjValues,
  selectedOwnerEditId,
}: Props) {
  const errorContainerRef = useRef<HTMLDivElement>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);
  const formRef = useRef<FormikProps<CreateMerchantCompanyOwnerPayload>>(null);
  const [initialValues, setInitialValues] = useState<CreateMerchantCompanyOwnerPayload>({
    companyType: '',
    taxCountry: '',
    taxId: '',
    businessName: '',
    updateItem: false,
  });

  const { merchantId, apiBaseUri } = useAppSelector((state) => ({
    merchantId: state.config.merchantId,
    apiBaseUri: state.config.apiBaseUri,
  }));

  const submitHandler = async (values: CreateMerchantCompanyOwnerPayload, formObj: { resetForm: () => void }) => {
    try {
      setIsRequestLoading(true);
      setErrorMessage('');
      const { ...postData } = { ...values };

      updateItem
        ? await updateMerchantCompanyOwner(apiBaseUri, merchantId, selectedOwnerEditId, postData)
        : await createMerchantCompanyOwner(apiBaseUri, merchantId, postData);
      formObj.resetForm();

      refreshData();
      toggleModal(false);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setIsRequestLoading(false);
    }
  };

  useEffect(() => {
    if (errorMessage.length > 0 && errorContainerRef && errorContainerRef.current) {
      errorContainerRef.current.scrollIntoView();
    }
  }, [errorMessage]);

  useEffect(() => {
    if (updateItem && editObjValues) {
      setInitialValues(editObjValues);
    }
  }, [updateItem, editObjValues]);

  return (
    <Fragment>
      <Formik<CreateMerchantCompanyOwnerPayload>
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values, formObj: { resetForm: () => void }) => {
          submitHandler(values, formObj);
        }}
        validationSchema={validationSchema}
        innerRef={formRef}
      >
        {({ values, handleSubmit, errors, touched, setFieldValue, handleChange, handleBlur }) => (
          <form autoComplete="off" onSubmit={handleSubmit} className="content-wrap-form">
            <div className="modal-content">
              <div className="lp-w-full">
                {errorMessage.length > 0 && (
                  <div className="mt-24 mb-24" ref={errorContainerRef}>
                    <Message
                      type="inline"
                      children={<span className="text-wrap">{errorMessage}</span>}
                      variant={'error'}
                    />
                  </div>
                )}

                <div className="lp-w-full mb-24">
                  <TextField
                    fullWidth
                    label="Business registration number"
                    inputMode="text"
                    autoComplete="off"
                    id="taxId"
                    name="taxId"
                    onBlur={handleBlur}
                    value={values.taxId}
                    onChange={handleChange}
                    error={errors.taxId && touched.taxId ? errors.taxId : ''}
                    helperText="The business’ or trustee’s ABN, ACN or NZBN."
                  />
                </div>

                <div className="lp-w-full mb-24">
                  <TextField
                    fullWidth
                    label="Legal entity name"
                    inputMode="text"
                    autoComplete="off"
                    id="businessName"
                    name="businessName"
                    onBlur={handleBlur}
                    value={values.businessName}
                    onChange={handleChange}
                    error={errors.businessName && touched.businessName ? errors.businessName : ''}
                    helperText="The entity name linked to the business registration number."
                  />
                </div>

                {((updateItem && values.taxCountry) || !updateItem) && (
                  <div className={`lp-w-full mb-24${errors.taxCountry && touched.taxCountry ? ` has-error` : ``}`}>
                    <Select
                      label="Country"
                      testId="step05CompanyCountry"
                      onChange={(value) => setFieldValue('taxCountry', value)}
                      value={values.taxCountry}
                      options={[
                        {
                          text: 'Australia',
                          value: 'AU',
                        },
                        {
                          text: 'New Zealand',
                          value: 'NZ',
                        },
                      ]}
                      errorMessage={errors.taxCountry && touched.taxCountry ? errors.taxCountry : ''}
                    />
                  </div>
                )}

                {((updateItem && values.companyType) || !updateItem) && (
                  <div className="lp-w-full lp-equal-width mb-24">
                    <div className="w-180">
                      <RadioGroup
                        defaultValue={values.companyType}
                        header="Company type"
                        name="companyType"
                        onChange={(value) => setFieldValue('companyType', value)}
                        orientation="vertical"
                        radios={[
                          {
                            text: 'Private',
                            value: 'Private',
                          },
                          {
                            text: 'Public',
                            value: 'Public',
                          },
                        ]}
                        errorMessage={errors.companyType && touched.companyType ? errors.companyType : ``}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="modal-footer">
              <s.MultiColumnRespBlock className="lp-w-full lp-justify-between">
                <Button
                  type="button"
                  onClick={() => toggleModal(!isOpen)}
                  size="medium"
                  variant="ghost"
                  className="no-min-width"
                  disabled={isRequestLoading}
                >
                  Cancel
                </Button>
                <div className="lp-flex">
                  {!updateItem && (
                    <Button
                      type="button"
                      onClick={() => setCurrentStep(1)}
                      size="medium"
                      variant="ghost"
                      className="no-min-width"
                      disabled={isRequestLoading}
                    >
                      <Icon name="ArrowLeft" className="ml-0" />
                      Back
                    </Button>
                  )}
                  <Button
                    type="submit"
                    size="medium"
                    variant="primary"
                    className="no-min-width"
                    disabled={isRequestLoading}
                    isLoading={isRequestLoading}
                  >
                    Save
                  </Button>
                </div>
              </s.MultiColumnRespBlock>
            </div>
          </form>
        )}
      </Formik>
    </Fragment>
  );
}

export default CompanySection;
