import { FunctionComponent, useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import getApplicationArea from 'utils/getApplicationArea';
import getBaseUrl from 'utils/getBaseUrl';
import getMerchantName from 'utils/getMerchantName';

interface PrivateRouteProps {
  isLoggedIn: boolean | null;
}

const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({ isLoggedIn }: PrivateRouteProps) => {
  const [merchantName] = useState(() => getMerchantName());
  const [applicationArea] = useState(() => getApplicationArea());
  const defaultRoute = `${getBaseUrl(merchantName, applicationArea)}/auth/login`;

  const { pathname } = useLocation();

  //react keeps the browser position when clicking on Link, this will make the browser to scroll to the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (!isLoggedIn) {
    return <Navigate to={`${defaultRoute}?redirectUrl=${pathname}`} replace={true} />;
  }

  return <Outlet />;
};

export default PrivateRoute;
