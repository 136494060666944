import { handleResponse } from 'api/utils';
import getFetchOptions from 'utils/getFetchOptions';

import {
  ExportPlatformPaymentsListPayload,
  PlatformDashboardPaymentsObj,
  PlatformPaymentDetailObj,
} from './payments.types';

export const getPaymentsList = async (
  host: string,
  marketplaceId: string,
  queryString: string,
): Promise<{ totalPages: number; totalRecords: number; items: Array<PlatformDashboardPaymentsObj> }> => {
  const options = await getFetchOptions();
  return await fetch(`${host}/platform/${marketplaceId}/dashboard/payments?${queryString}`, options).then(
    handleResponse,
  );
};

export const getPaymentDetail = async (
  host: string,
  marketplaceId: string,
  transactionId: string,
): Promise<PlatformPaymentDetailObj> => {
  const options = await getFetchOptions();
  return await fetch(`${host}/platform/${marketplaceId}/dashboard/payments/${transactionId}`, options).then(
    handleResponse,
  );
};

export const exportPlatformPaymentsList = async (
  host: string,
  marketplaceId: string,
  payload: ExportPlatformPaymentsListPayload,
): Promise<string> => {
  const options = await getFetchOptions('POST', { payload });
  return await fetch(`${host}/platform/${marketplaceId}/dashboard/payments/csv`, options).then(handleResponse);
};
