import { getMerchantOrderDetail } from 'api/merchant/dashboard/orders';
import { OrderAPIResponseType } from 'api/merchant/dashboard/orders.types';
import { getMerchantPaymentDetail } from 'api/merchant/dashboard/payments';
import { PaymentAPIResponseType } from 'api/merchant/dashboard/payments.types';
import MerchantDashboardLayout from 'layouts/merchant-dashboard/MerchantDashboardLayout';
import MerchantDefaultRoutes from 'pages/merchant/constants';
import qs from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/merchant/hooks';
import { getMerchantBaseUrl } from 'utils/getBaseUrl';
import getErrorMessage from 'utils/getErrorMessage';

import DashboardRoutesList from '../../constants';
import PaymentRoutesList from '../constants';
import PaymentDetails from './PaymentDetails';

function PaymentDetailPage() {
  const navigate = useNavigate();
  const { txnId } = qs.parse(window.location.search);

  // detail section variables
  const [showDetailLoader, setShowDetailLoader] = useState<boolean>(false);
  const [paymentDetailObj, setPaymentDetailObj] = useState<PaymentAPIResponseType | null>(null);
  const [orderDetailObj, setOrderDetailObj] = useState<OrderAPIResponseType | null>(null);
  const [detailErrorMsg, setDetailErrorMsg] = useState<string>('');

  const { merchantName } = useAppSelector((state) => ({
    merchantName: state.config.merchantName,
  }));

  const detailSectionBackClickhandler = () => {
    navigate(
      `${getMerchantBaseUrl(merchantName)}/${MerchantDefaultRoutes.DASHBOARD_ROUTE}/${
        DashboardRoutesList.PAYMENT_ROUTE
      }/${PaymentRoutesList.PAYMENT_LANDING}`,
    );
  };

  const detailClickHandler = useCallback(async () => {
    try {
      if (typeof txnId !== 'string') return;

      setShowDetailLoader(true);

      const paymentDetailObj = await getMerchantPaymentDetail(txnId);
      const orderDetailObj = await getMerchantOrderDetail(paymentDetailObj.purchaseDetails.orderId);

      setPaymentDetailObj(paymentDetailObj);
      setOrderDetailObj(orderDetailObj);
    } catch (error) {
      setDetailErrorMsg(getErrorMessage(error));
    } finally {
      setShowDetailLoader(false);
    }
  }, [txnId]);

  useEffect(() => {
    if (txnId) {
      detailClickHandler();
    }
  }, [txnId, detailClickHandler]);

  return (
    <MerchantDashboardLayout activeTab="payment" title="Payment Detail">
      <PaymentDetails
        onBackButtonClick={() => detailSectionBackClickhandler()}
        showDetailLoader={showDetailLoader}
        paymentDetailObj={paymentDetailObj}
        orderDetailObj={orderDetailObj}
        detailErrorMsg={detailErrorMsg}
      />
    </MerchantDashboardLayout>
  );
}

export default PaymentDetailPage;
