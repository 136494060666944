import {
  getSettingBrandingDetail,
  getSettingPayout,
  getSettingPayoutExternalAccount,
  getSettingPayoutSchedule,
} from 'api/platform/setting';
import { SettingPayoutExternalAccountResponse, SettingPayoutResponse } from 'api/platform/setting.types';
import PlatformLayout from 'layouts/platform/PlatformLayout';
import { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from 'redux/platform/hooks';
import getErrorMessage from 'utils/getErrorMessage';

import SettingsPayouts from './SettingsPayouts';
import { SettingBrandingDetailPayload } from './types';

function SettingsPayoutPage() {
  const [fetchBrandingDetailLoader, setFetchBrandingDetailLoader] = useState<boolean>(true);
  const [fetchSettingPayoutLoader, setFetchSettingPayoutLoader] = useState<boolean>(true);
  const [payoutScheduleLoader, setPayoutScheduleLoader] = useState<boolean>(true);
  const [payoutExternalAccountLoader, setPayoutExternalAccountLoader] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [settingPayoutDetail, setSettingPayoutDetail] = useState<SettingPayoutResponse>({
    notificationEmails: [],
    notificationsEnabled: false,
    settlementReportEnabled: false,
    email: '',
  });
  const [brandingDetail, setBrandingDetail] = useState<SettingBrandingDetailPayload>({
    branding: null,
    customerSettings: null,
    payoutSettings: {
      payoutDescription: '',
    },
  });
  const [payoutSchedule, setPayoutSchedule] = useState<string>('');
  const [payoutExternalAccount, setPayoutExternalAccount] = useState<Array<SettingPayoutExternalAccountResponse>>([]);

  const { payoutMerchantId, apiBaseUri, marketplaceId, platformCountry } = useAppSelector((state) => ({
    payoutMerchantId: state.setting.payoutMerchantId,
    apiBaseUri: state.config.apiBaseUri,
    marketplaceId: state.config.marketplaceId,
    platformCountry: state.config.platformCountry,
  }));

  const fetchSettingPayout = useCallback(async () => {
    try {
      setFetchSettingPayoutLoader(true);

      const response = await getSettingPayout(apiBaseUri, payoutMerchantId);
      setSettingPayoutDetail({ ...response, email: '' });
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setFetchSettingPayoutLoader(false);
    }
  }, [apiBaseUri, payoutMerchantId]);

  const fetchPayoutSchedule = useCallback(async () => {
    try {
      setPayoutScheduleLoader(true);

      const response = await getSettingPayoutSchedule(apiBaseUri, payoutMerchantId);
      setPayoutSchedule(response);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setPayoutScheduleLoader(false);
    }
  }, [apiBaseUri, payoutMerchantId]);

  const fetchPayoutExternalAccount = useCallback(async () => {
    try {
      setPayoutExternalAccountLoader(true);

      const response = await getSettingPayoutExternalAccount(apiBaseUri, payoutMerchantId);

      setPayoutExternalAccount(response.filter((bankDetail) => bankDetail.isDefaultForCurrency));
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setPayoutExternalAccountLoader(false);
    }
  }, [apiBaseUri, payoutMerchantId]);

  const fetchBrandingDetail = useCallback(async () => {
    try {
      setFetchBrandingDetailLoader(true);

      const response = await getSettingBrandingDetail(apiBaseUri, marketplaceId, platformCountry);

      const updateData = {
        branding: null,
        customerSettings: null,
        payoutSettings: response.payoutSettings
          ? response.payoutSettings
          : {
              payoutDescription: '',
            },
      };
      setBrandingDetail(updateData);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setFetchBrandingDetailLoader(false);
    }
  }, [apiBaseUri, marketplaceId, platformCountry]);

  useEffect(() => {
    if (!payoutMerchantId) {
      return;
    }

    fetchSettingPayout();
    fetchPayoutSchedule();
    fetchPayoutExternalAccount();
    fetchBrandingDetail();
  }, [
    apiBaseUri,
    payoutMerchantId,
    fetchPayoutExternalAccount,
    fetchPayoutSchedule,
    fetchSettingPayout,
    fetchBrandingDetail,
  ]);

  return (
    <PlatformLayout title="Business settings - Payouts" activeTab="settings">
      <SettingsPayouts
        payoutSchedule={payoutSchedule}
        payoutExternalAccount={payoutExternalAccount}
        settingPayoutDetail={settingPayoutDetail}
        refreshDataHandler={fetchPayoutSchedule}
        settingPayoutFetchHandler={fetchSettingPayout}
        fetchSettingPayoutLoader={fetchSettingPayoutLoader}
        payoutScheduleLoader={payoutScheduleLoader}
        payoutExternalAccountLoader={payoutExternalAccountLoader}
        brandingDetail={brandingDetail}
        fetchBrandingDetailLoader={fetchBrandingDetailLoader}
        fetchBrandingDetail={fetchBrandingDetail}
        errorMessage={errorMessage}
        bankDetailFetchHandler={fetchPayoutExternalAccount}
      />
    </PlatformLayout>
  );
}

export default SettingsPayoutPage;
