import { Currency, toCurrency } from 'utils/currency';

interface Props {
  amount: number;
  currency: Currency;
}

const DataTableTxnRow = ({ amount, currency }: Props) => {
  return (
    <div className={`${amount > 0 ? `` : `price`}`}>
      {amount > 0 ? toCurrency(amount, currency) : `(${toCurrency(amount, currency).replace('-', '')})`}
    </div>
  );
};

export default DataTableTxnRow;
