/** @jsxImportSource @emotion/react */

import { ReactComponent as Amex } from 'assets/images/amex.svg';
import { ReactComponent as Mastercard } from 'assets/images/mastercard.svg';
import { ReactComponent as Visa } from 'assets/images/visa.svg';

import { Icon, Text } from '@limepayments/cosmic';

import { CardBrand, CardPaymentSource } from './Checkout';

const CardBrandIcon = ({ brand }: { brand: CardBrand }) => (
  <span css={{ '& svg': { width: 'auto', height: 24, margin: 0 } }}>
    {brand === 'Visa' ? (
      <Visa />
    ) : brand === 'MasterCard' ? (
      <Mastercard />
    ) : brand === 'American Express' ? (
      <Amex />
    ) : (
      <Icon name="Card" />
    )}
  </span>
);

export const Card = ({ card }: { card: CardPaymentSource }) => (
  <div css={{ display: 'flex', alignItems: 'center', gap: 4 }}>
    <CardBrandIcon brand={card.brand ?? ''} />
    <Text tagName="span" variant="body-3">
      ••••
    </Text>
    <Text tagName="span" variant="body-3">
      {card.last4}
    </Text>
  </div>
);
