import * as Yup from 'yup';

export const paymentSourceModalValidationSchema = Yup.object().shape({
  domainNames: Yup.array()
    .of(
      Yup.string()
        .trim()
        .matches(
          /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#-]+)*(\/[\w#-]+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
          'Please enter a valid domain',
        ),
    )
    .min(1, 'Please enter atleast one domain'),
  domain: Yup.string()
    .trim()
    .matches(
      /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#-]+)*(\/[\w#-]+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
      'Please enter a valid domain',
    ),
});

export const paymentConfirmationModalValidationSchema = Yup.object().shape({
  orderPaidNotificationEmails: Yup.array()
    .of(Yup.string().email('Must be valid email addresses'))
    .min(1, 'Please enter atleast one email'),
  email: Yup.string().email('Please enter a valid email address'),
});
