import { LISTING_RECORDS_PER_PAGE } from 'pages/platform/constants';

export const getInitialFilterState = () => {
  return {
    dateValue: '',
    dateTypeCompareValue: '',
    dateTypeFilterValue: '',
    entryType: '',
    dateRangeValue: '',
    dateSingleValue: null,
    dateActiveChecked: false,
    entryTypeFilterChecked: false,
    merchantIdChecked: false,
    txnIdChecked: false,
    payoutIdChecked: false,
    amountChecked: false,
    merchantId: '',
    payoutId: '',
    txnId: '',
    amount: '',
    totalFiltersApplied: 0,
    saveTiggerAt: '',
  };
};

export const getInitialListingPageState = () => {
  return {
    activePage: 1,
    sortBy: '-createdAt',
    limit: LISTING_RECORDS_PER_PAGE,
  };
};
