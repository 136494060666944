import { updateSettingBrandingDetail } from 'api/platform/setting';
import * as s from 'assets/styles/Platforms.styles';
import { Formik, FormikProps } from 'formik';
import { useState } from 'react';
import { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useAppSelector } from 'redux/platform/hooks';
import getErrorMessage from 'utils/getErrorMessage';
import * as Yup from 'yup';

import { Button, Heading, Message, Modal, TextField } from '@limepayments/cosmic';

import { SettingBrandingDetailPayload } from '../types';

const validationSchema = Yup.object().shape({
  payoutSettings: Yup.object().shape({
    payoutDescription: Yup.string()
      .trim()
      .required('This field is required')
      .min(5, 'Must be at least 5 characters')
      .max(13, 'Must be at most 13 characters')
      .matches(/^[aA-zZ0-9]+$/, 'Please enter only alphanumeric characters'),
  }),
});

interface Props {
  isOpen: boolean;
  modalToggler: (val: boolean) => void;
  refreshDataHandler: () => void;
  brandingDetail: SettingBrandingDetailPayload;
}

function PayoutDescriptionModal({ isOpen, modalToggler, brandingDetail, refreshDataHandler }: Props) {
  const errorContainerRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<FormikProps<SettingBrandingDetailPayload>>(null);
  const [editRequestLoading, setEditRequestLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [initialValues, setInitialValues] = useState<SettingBrandingDetailPayload>({
    ...brandingDetail,
  });

  const { apiBaseUri, platformCountry, marketplaceId } = useAppSelector((state) => ({
    apiBaseUri: state.config.apiBaseUri,
    platformCountry: state.config.platformCountry,
    marketplaceId: state.config.marketplaceId,
  }));

  const submitHandler = async (values: SettingBrandingDetailPayload, formObj: { resetForm: () => void }) => {
    try {
      setEditRequestLoading(true);
      setErrorMsg('');

      await updateSettingBrandingDetail(apiBaseUri, marketplaceId, platformCountry, values);

      toast.success('Payout description updated successfully');
      formObj.resetForm();
      modalToggler(false);
      refreshDataHandler();
    } catch (error) {
      setErrorMsg(getErrorMessage(error));
    } finally {
      setEditRequestLoading(false);
    }
  };

  useEffect(() => {
    if (errorMsg.length > 0 && errorContainerRef && errorContainerRef.current) {
      errorContainerRef.current.scrollIntoView();
    }
  }, [errorMsg]);

  useEffect(() => {
    if (isOpen) {
      setInitialValues({
        ...brandingDetail,
      });
      setErrorMsg('');
    }
  }, [isOpen, brandingDetail]);

  return (
    <Modal isOpen={isOpen} className="modal-medium update-pass-modal">
      <s.Wrapper className="">
        <div className="modal-main p-24">
          <div className="modal-header">
            <div className="content-main bb-1">
              <Heading alignment="center" tagName="h2" variant="xs" className="mt-12 mb-24 lp-justify-center lp-flex">
                Payout description
              </Heading>
            </div>
          </div>

          <Formik<SettingBrandingDetailPayload>
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={(values, formObj: { resetForm: () => void }) => {
              submitHandler(values, formObj);
            }}
            validationSchema={validationSchema}
            innerRef={formRef}
          >
            {({ values, handleSubmit, errors, touched, handleChange, handleBlur }) => (
              <form autoComplete="off" onSubmit={handleSubmit} className="content-wrap-form">
                <div className="modal-content p-28 sm-p-0 sm-mt-24">
                  {errorMsg.length > 0 && (
                    <div className="mt-24 mb-24" ref={errorContainerRef}>
                      <Message
                        type="inline"
                        children={<span className="text-wrap">{errorMsg}</span>}
                        variant={'error'}
                      />
                    </div>
                  )}

                  <div className="lp-w-full mb-16 payout">
                    <TextField
                      fullWidth
                      autoComplete="off"
                      label="Payout description"
                      helperText="5-13 alphabetic characters only, this includes upper and lower cases."
                      id="payoutSettings.payoutDescription"
                      name="payoutSettings.payoutDescription"
                      value={values.payoutSettings.payoutDescription}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        errors.payoutSettings &&
                        errors.payoutSettings.payoutDescription &&
                        touched.payoutSettings &&
                        touched.payoutSettings.payoutDescription
                          ? errors.payoutSettings.payoutDescription
                          : ''
                      }
                    />
                  </div>
                </div>
                <div className="modal-footer lp-flex lp-justify-end sm-mt-24">
                  <Button
                    onClick={() => modalToggler(false)}
                    size="medium"
                    variant="ghost"
                    className="no-min-width w-70"
                    disabled={editRequestLoading}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size="medium"
                    variant="primary"
                    className="no-min-width"
                    disabled={editRequestLoading}
                    isLoading={editRequestLoading}
                  >
                    Save
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </s.Wrapper>
    </Modal>
  );
}

export default PayoutDescriptionModal;
