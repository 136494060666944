import { Fragment } from 'react';

import { Button, Heading, Icon, TextField } from '@limepayments/cosmic';

function OrderDetailSection() {
  return (
    <Fragment>
      <div className="two-col mb-16">
        <div className="lp-w-half white-bg">
          <TextField
            fullWidth
            containerId="standard"
            label="Order number"
            onBlur={function noRefCheck() {}}
            onChange={function noRefCheck() {}}
            onFocus={function noRefCheck() {}}
            onKeyDown={function noRefCheck() {}}
            onKeyPress={function noRefCheck() {}}
            onKeyUp={function noRefCheck() {}}
            onMouseDown={function noRefCheck() {}}
            onMouseLeave={function noRefCheck() {}}
            onMouseOver={function noRefCheck() {}}
            value="1234"
          />
        </div>
        <div className="lp-w-half white-bg">
          <TextField
            fullWidth
            containerId="standard"
            label="Order amount (AUD)"
            onBlur={function noRefCheck() {}}
            onChange={function noRefCheck() {}}
            onFocus={function noRefCheck() {}}
            onKeyDown={function noRefCheck() {}}
            onKeyPress={function noRefCheck() {}}
            onKeyUp={function noRefCheck() {}}
            onMouseDown={function noRefCheck() {}}
            onMouseLeave={function noRefCheck() {}}
            onMouseOver={function noRefCheck() {}}
            value="160.00"
            startAdornment="Filled-Dollar"
          />
        </div>
      </div>
      <div className="lp-w-full white-bg mb-16">
        <TextField
          fullWidth
          containerId="standard"
          label="Description (optional)"
          onBlur={function noRefCheck() {}}
          onChange={function noRefCheck() {}}
          onFocus={function noRefCheck() {}}
          onKeyDown={function noRefCheck() {}}
          onKeyPress={function noRefCheck() {}}
          onKeyUp={function noRefCheck() {}}
          onMouseDown={function noRefCheck() {}}
          onMouseLeave={function noRefCheck() {}}
          onMouseOver={function noRefCheck() {}}
          value="Plumbing 24 Sep 2022"
        />
      </div>
      <div className="lp-w-full white-bg mb-16">
        <TextField
          fullWidth
          containerId="standard"
          label="Customer name"
          onBlur={function noRefCheck() {}}
          onChange={function noRefCheck() {}}
          onFocus={function noRefCheck() {}}
          onKeyDown={function noRefCheck() {}}
          onKeyPress={function noRefCheck() {}}
          onKeyUp={function noRefCheck() {}}
          onMouseDown={function noRefCheck() {}}
          onMouseLeave={function noRefCheck() {}}
          onMouseOver={function noRefCheck() {}}
          value="Deb Amos"
        />
      </div>
      <div className="two-col mb-16">
        <div className="lp-w-half white-bg">
          <TextField
            fullWidth
            containerId="standard"
            label="Customer email"
            onBlur={function noRefCheck() {}}
            onChange={function noRefCheck() {}}
            onFocus={function noRefCheck() {}}
            onKeyDown={function noRefCheck() {}}
            onKeyPress={function noRefCheck() {}}
            onKeyUp={function noRefCheck() {}}
            onMouseDown={function noRefCheck() {}}
            onMouseLeave={function noRefCheck() {}}
            onMouseOver={function noRefCheck() {}}
            value="deb@gmail.com"
            startAdornment="Filled-Email"
          />
        </div>
        <div className="lp-w-half white-bg">
          <TextField
            fullWidth
            containerId="standard"
            label="Customer phone"
            onBlur={function noRefCheck() {}}
            onChange={function noRefCheck() {}}
            onFocus={function noRefCheck() {}}
            onKeyDown={function noRefCheck() {}}
            onKeyPress={function noRefCheck() {}}
            onKeyUp={function noRefCheck() {}}
            onMouseDown={function noRefCheck() {}}
            onMouseLeave={function noRefCheck() {}}
            onMouseOver={function noRefCheck() {}}
            value="0400000000"
            startAdornment="Filled-Phone"
          />
        </div>
      </div>

      <div className="separator"></div>

      <div className="lp-w-full">
        <div className="lp-flex lp-align-center">
          <Heading alignment="left" tagName="h1" variant="xxs" className="text-dark-50 mb-0">
            Item breakdown
          </Heading>
          <div className="ml-auto">
            <Button type="button" size="medium" className="no-min-width" variant="ghost">
              Edit
              <Icon name="Edit" className="ml-8 mr-0" />
            </Button>
          </div>
        </div>
        <div className="item-breakdown mt-0">
          <table className="lp-w-full table-summary">
            <thead>
              <tr>
                <th>Item</th>
                <th>Count</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Faucet</td>
                <td>1</td>
                <td>$160.00</td>
              </tr>
              <tr>
                <td>Grout</td>
                <td>5</td>
                <td>$80.00</td>
              </tr>
              <tr>
                <td>Paint</td>
                <td>10</td>
                <td>$300.00</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
}

export default OrderDetailSection;
