import { merchantPaymentRefund } from 'api/merchant/dashboard/payments';
import * as s from 'assets/styles/Platforms.styles';
import { Formik, FormikProps } from 'formik';
import { Fragment, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppSelector } from 'redux/merchant/hooks';
import { Currency, fromCurrency, toCurrency } from 'utils/currency';
import getErrorMessage from 'utils/getErrorMessage';
import * as Yup from 'yup';

import {
  Button,
  Heading,
  Illustration,
  Message,
  Modal,
  RadioGroup,
  Spinner,
  Stack,
  Text,
  TextField,
} from '@limepayments/cosmic';

import { RefundPaymentPayload } from '../types';

const getInitailValues = () => {
  return {
    refundType: '',
    partialRefundAmount: '',
  };
};

const validationSchema = Yup.object().shape({
  refundType: Yup.string().required('This field is required'),
  partialRefundAmount: Yup.string().when('refundType', {
    is: 'partial_refund',
    then: Yup.string().required('This field is required'),
  }),
});

interface Props {
  isOpen: boolean;
  modalToggler: (isOpen: boolean) => void;
  transactionAmount?: number;
  refundedAmount?: number;
  refundTransactionId: string;
  currency: Currency;
  customerName: string;
}

function RefundModalComponent({
  isOpen,
  modalToggler,
  refundedAmount,
  transactionAmount,
  refundTransactionId,
  currency,
  customerName,
}: Props) {
  const [activeStep, setActiveStep] = useState<number>(1);
  const [refundRequestLoader, setRefundRequestLoader] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<RefundPaymentPayload>(getInitailValues());
  const [errorMessage, setErrorMessage] = useState<string>('');
  const formRef = useRef<FormikProps<RefundPaymentPayload>>(null);
  const errorContainerRef = useRef<HTMLDivElement>(null);

  const { merchantId, apiBaseUri } = useAppSelector((state) => ({
    merchantId: state.config.merchantId,
    apiBaseUri: state.config.apiBaseUri,
  }));

  const getFinalRefundAmount = (refundType: string, refundAmount: string) => {
    let amount = 0;
    if (refundType === 'full_refund') {
      amount = (transactionAmount || 0) - (refundedAmount || 0);
    } else {
      amount = fromCurrency(refundAmount);
    }
    return amount;
  };

  const submitHandler = async (values: RefundPaymentPayload, formikObj: { resetForm: () => void }) => {
    try {
      if (activeStep === 1) {
        setActiveStep(2);
        return;
      }

      setRefundRequestLoader(true);
      setActiveStep(3);

      let amount = 0;
      if (values.refundType === 'full_refund') {
        amount = (transactionAmount || 0) - (refundedAmount || 0);
      } else {
        amount = fromCurrency(values.partialRefundAmount);
      }
      const postData = {
        transactionId: refundTransactionId,
        refundAmount: {
          amount,
          currency: currency,
        },
      };

      await merchantPaymentRefund(apiBaseUri, merchantId, postData);

      toast.success(`You have successfully refunded ${amount} ${currency} to ${customerName}.`);

      setInitialValues(values);
      formikObj.resetForm();
      setActiveStep(2);
    } catch (err: any) {
      toast.error(getErrorMessage(err));
    } finally {
      setRefundRequestLoader(false);
    }
  };

  useEffect(() => {
    if (errorMessage.length > 0 && errorContainerRef && errorContainerRef.current) {
      errorContainerRef.current.scrollIntoView();
    }
  }, [errorMessage]);

  useEffect(() => {
    setErrorMessage('');
    setActiveStep(1);
    setRefundRequestLoader(false);
    setInitialValues(getInitailValues());
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} className="modal-medium update-pass-modal">
      <s.Wrapper className="">
        <div className="modal-main p-24">
          <div className="modal-header">
            <div className="content-main bb-1">
              <Heading alignment="center" tagName="h2" variant="xxs" className="mt-12 mb-24 lp-justify-center lp-flex">
                Refund payment
              </Heading>
            </div>
          </div>
          <Formik<RefundPaymentPayload>
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={(values, formObj: { resetForm: () => void }) => {
              submitHandler(values, formObj);
            }}
            validationSchema={validationSchema}
            innerRef={formRef}
          >
            {({ values, handleSubmit, errors, touched, setFieldValue, handleChange, handleBlur }) => (
              <form autoComplete="off" onSubmit={handleSubmit}>
                <div className="modal-content p-28 sm-p-0 sm-mt-24">
                  <div className="">
                    {activeStep === 1 && (
                      <Fragment>
                        {/* Step 01 */}
                        <div className="lp-w-full lp-flex-row mb-16">
                          <div className="lp-w-full lp-flex lp-align-center lp-flex-row mb-0 sm-lp-align-start">
                            <div className="lp-w-full lp-flex lp-flex-column">
                              <Text
                                alignment="left"
                                tagName="p"
                                variant="body-2"
                                isEmphasised
                                className="text-dark-50 mb-0"
                              >
                                Refund {customerName}
                              </Text>
                            </div>
                          </div>
                          <div className="lp-w-full lp-flex lp-align-center lp-flex-row mb-0 sm-lp-align-start">
                            <Text variant="caption" className="text-dark-50 mb-0 mt-4">
                              Fully or partially refund payment of{' '}
                              <strong>
                                {toCurrency((transactionAmount || 0) - (refundedAmount || 0), currency)} {currency}.
                              </strong>
                            </Text>
                          </div>
                        </div>
                        <div className="lp-w-full lp-equal-width mb-24">
                          <div className="w-180">
                            <RadioGroup
                              defaultValue={values.refundType}
                              name="refundType"
                              onChange={(value) => setFieldValue('refundType', value)}
                              radios={[
                                {
                                  text: 'Full refund',
                                  value: 'full_refund',
                                },
                                {
                                  text: 'Partial refund',
                                  value: 'partial_refund',
                                },
                              ]}
                              errorMessage={errors.refundType && touched.refundType ? errors.refundType : ''}
                            />
                          </div>
                        </div>

                        {values.refundType === 'partial_refund' && (
                          <div className="lp-w-full lp-flex mb-24">
                            <div className="lp-equal-width">
                              <TextField
                                containerId="standard"
                                startAdornment="Filled-Dollar"
                                label="Refund amount"
                                id="partialRefundAmount"
                                name="partialRefundAmount"
                                value={values.partialRefundAmount}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  errors.partialRefundAmount && touched.partialRefundAmount
                                    ? errors.partialRefundAmount
                                    : ``
                                }
                              />
                              {/* The amount has to be lower than the original payment. */}
                            </div>
                            <div className="lp-equal-width">
                              <Text
                                alignment="left"
                                tagName="p"
                                variant="body-1"
                                isEmphasised
                                className="currency mb-0"
                              >
                                {currency}
                              </Text>
                            </div>
                          </div>
                        )}

                        <Message dismissalType="none" type="inline" variant="information">
                          <Text variant="caption">
                            It may take up to <strong>10 days</strong> for the refund to reach the customer.
                          </Text>
                        </Message>
                      </Fragment>
                    )}

                    {activeStep === 2 && (
                      <Fragment>
                        {/* Step 02 */}
                        <div className="lp-w-full lp-flex mb-24">
                          <div className="lp-w-full lp-flex lp-flex-column lp-align-center lp-justify-center">
                            <div className="illustration-90">
                              <Illustration name="Warning" size="medium" />
                            </div>
                            <div className="w-75">
                              <Text alignment="center" tagName="p" variant="body-3" className="text-dark-50 mb-0">
                                Are you sure you want to{' '}
                                {values.refundType === 'partial_refund' ? `partially` : `fully`} refund this payment of{' '}
                                <strong>
                                  {getFinalRefundAmount(values.refundType, values.partialRefundAmount)} {currency}
                                </strong>{' '}
                                for <strong>{customerName}</strong>?
                              </Text>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    )}

                    {activeStep === 3 && (
                      <Fragment>
                        {/* Step 03 */}
                        <div className="lp-w-full lp-flex lp-align-center lp-justify-center mb-0">
                          <div className="modal-body-loader">
                            <Stack spacing="md" className="mb-16">
                              <Spinner isVisible variant="simple" />
                            </Stack>
                            <Text alignment="center" tagName="p" variant="body-3" className="text-dark-50 mb-0">
                              Refunding{' '}
                              <strong>
                                {getFinalRefundAmount(values.refundType, values.partialRefundAmount)} {currency}
                              </strong>{' '}
                              to <strong>{customerName}</strong>...
                            </Text>
                          </div>
                        </div>
                      </Fragment>
                    )}
                  </div>
                </div>
                <div className="modal-footer lp-flex lp-justify-end sm-mt-24">
                  <Button
                    onClick={() => modalToggler(false)}
                    size="medium"
                    variant="ghost"
                    className="no-min-width"
                    disabled={refundRequestLoader ? true : false}
                  >
                    Cancel
                  </Button>
                  {activeStep === 1 && (
                    <Button type="submit" size="medium" variant="primary" className="no-min-width">
                      Refund
                    </Button>
                  )}

                  {activeStep !== 1 && (
                    <Button
                      type="submit"
                      size="medium"
                      variant="destructive"
                      className="no-min-width"
                      disabled={refundRequestLoader ? true : false}
                    >
                      Refund
                    </Button>
                  )}
                </div>
              </form>
            )}
          </Formik>
        </div>
      </s.Wrapper>
    </Modal>
  );
}

export default RefundModalComponent;
