/** @jsxImportSource @emotion/react */

import { StandardMerchant } from 'api/merchant/dashboard/merchants.types';
import * as s from 'assets/styles/Platforms.styles';
import { Formik, FormikProps } from 'formik';
import { RefObject, useEffect, useRef } from 'react';

import styled from '@emotion/styled';
import { Button, Heading, Icon, Modal, TextField } from '@limepayments/cosmic';

import { BrandingModalForm, toBrandingModalForm } from './types';
import { validationSchema } from './validation.schema';

interface Props {
  isOpen: boolean;
  modalToggler: (val: boolean) => void;
  merchantDetails: StandardMerchant;
  onSubmitClick: (values: BrandingModalForm, formObj: { resetForm: () => void }) => void;
  formRef: RefObject<FormikProps<BrandingModalForm>>;
  errorMsg: string;
  editRequestLoading: boolean;
}

const ColorExampleBox = styled.div((props) => ({
  width: 39,
  height: 39,
  border: '1px solid rgb(var(--lp-colors-neutral-200))',
  borderRadius: 4,
  backgroundColor: props.color ?? 'transparent',
}));

function EditBrandingModal({
  isOpen,
  merchantDetails,
  formRef,
  errorMsg,
  editRequestLoading,
  modalToggler,
  onSubmitClick,
}: Props) {
  const initialValues: BrandingModalForm = toBrandingModalForm(merchantDetails);

  const errorContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (errorMsg.length > 0 && errorContainerRef && errorContainerRef.current) {
      errorContainerRef.current.scrollIntoView();
    }
  }, [errorMsg]);

  return (
    <Modal isOpen={isOpen} className="modal-medium update-pass-modal">
      <s.Wrapper className="">
        <div className="modal-main p-24">
          <div className="modal-header">
            <div className="content-main bb-1">
              <Heading alignment="center" tagName="h2" variant="xs" className="mt-12 mb-16 lp-justify-center lp-flex">
                Branding details
              </Heading>
            </div>
          </div>
          <Formik<BrandingModalForm>
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={(values, formObj: { resetForm: () => void }) => {
              onSubmitClick(values, formObj);
            }}
            validationSchema={validationSchema}
            innerRef={formRef}
          >
            {({ values, handleSubmit, errors, touched, handleChange, handleBlur }) => (
              <form autoComplete="off" onSubmit={handleSubmit} className="content-wrap-form">
                <div className="modal-content p-28 sm-p-0 sm-mt-24">
                  <div className="lp-w-full is-row-disabled">
                    <div className="lp-w-full w-200  mb-4">
                      <TextField
                        id="tradingName"
                        name="tradingName"
                        containerId="standard"
                        label="Trading name"
                        value={values.tradingName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.tradingName && touched.tradingName ? errors.tradingName : ''}
                      />
                    </div>
                  </div>
                  <div className="lp-w-full w-200 mb-24">
                    <TextField
                      fullWidth
                      label="Logo URL"
                      testId="branding-edit-logo-url"
                      id="logoUrl"
                      name="logoUrl"
                      maxLength={2048}
                      value={values.logoUrl}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={errors.logoUrl && touched.logoUrl ? errors.logoUrl : ''}
                      endAdornment={
                        <span
                          data-balloon="Convert your logo to a URL by uploading it to an image hosting website.
For best result use a .jpg or .png image URL with dimensions 175x175 pixels."
                          data-balloon-pos="left"
                          data-balloon-length="large"
                          className="size-16"
                        >
                          <Icon className="text-primary" name="Info" />
                        </span>
                      }
                      helperText="Use a direct link ending with .jpg or .png"
                    />
                  </div>

                  <div className="lp-w-full w-200 mb-24">
                    <TextField
                      fullWidth
                      label="Favicon URL"
                      testId="branding-edit-favicon-url"
                      id="faviconUrl"
                      name="faviconUrl"
                      maxLength={2048}
                      value={values.faviconUrl}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={errors.faviconUrl && touched.faviconUrl ? errors.faviconUrl : ''}
                      endAdornment={
                        <span
                          data-balloon="Convert your favicon to a URL by uploading it to an image hosting website. 
For best result use a .ico or .png image URL with dimensions 32x32 or 16x16 pixels."
                          data-balloon-pos="left"
                          data-balloon-length="large"
                          className="size-16"
                        >
                          <Icon className="text-primary" name="Info" />
                        </span>
                      }
                      helperText="Use a direct link ending with .ico or .png"
                    />
                  </div>

                  <div className="lp-w-full w-200 mb-16">
                    <TextField
                      containerId="standard"
                      label="Logo alt text"
                      testId="branding-edit-logo-alt-text"
                      id="logoAltText"
                      name="logoAltText"
                      value={values.logoAltText}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={errors.logoAltText && touched.logoAltText ? errors.logoAltText : ''}
                    />
                  </div>

                  <div className="lp-w-full w-200 mb-24">
                    <TextField
                      fullWidth
                      label="Card statement name"
                      id="cardStatementName"
                      name="cardStatementName"
                      value={values.cardStatementName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={errors.cardStatementName && touched.cardStatementName ? errors.cardStatementName : ''}
                      endAdornment={
                        <span
                          data-balloon="The business name that appears on customer bank statements. A recognisable name is important so that customers remember where they purchased from and to avoid unintended disputes."
                          data-balloon-pos="left"
                          data-balloon-length="large"
                          className="size-16"
                        >
                          <Icon className="text-primary" name="Info" />
                        </span>
                      }
                      helperText="5-13 alphabetic characters only, this includes upper and lower cases."
                    />
                  </div>

                  <div className="lp-w-full w-200 mb-24">
                    <TextField
                      fullWidth
                      label="SMS sender name"
                      id="smsSenderName"
                      name="smsSenderName"
                      value={values.smsSenderName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={errors.smsSenderName && touched.smsSenderName ? errors.smsSenderName : ''}
                      endAdornment={
                        <span
                          data-balloon="The contact name that appears on SMS received by customers regarding their payment. A recognisable name is important so that customers remember where they purchased from and to avoid unintended disputes."
                          data-balloon-pos="left"
                          data-balloon-length="large"
                          className="size-16"
                        >
                          <Icon className="text-primary" name="Info" />
                        </span>
                      }
                      helperText="3-11 alphabetic or numeric characters, this includes lower or uppercase letters."
                    />
                  </div>

                  <div className="lp-w-full w-200 mb-16">
                    <TextField
                      containerId="standard"
                      label="Business website"
                      id="businessWebsite"
                      name="businessWebsite"
                      value={values.businessWebsite}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={errors.businessWebsite && touched.businessWebsite ? errors.businessWebsite : ''}
                    />
                  </div>

                  <div
                    className="lp-w-full w-200 mb-16"
                    css={{ display: 'flex', alignItems: 'center', gap: 'var(--lp-space-xxs)' }}
                  >
                    <div css={{ flex: 1 }}>
                      <TextField
                        fullWidth
                        label="Primary brand colour"
                        id="primaryBrandColor"
                        name="primaryBrandColor"
                        value={values.primaryBrandColor}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={errors.primaryBrandColor && touched.primaryBrandColor ? errors.primaryBrandColor : ''}
                        endAdornment={
                          <span
                            data-balloon="The brand colour that your customers see on your checkout. Brand colour must be entered as a Hex code."
                            data-balloon-pos="left"
                            data-balloon-length="large"
                            className="size-16"
                          >
                            <Icon className="text-primary" name="Info" />
                          </span>
                        }
                        helperText="Enter a hex code. 6 alphabetic or numeric characters."
                      />
                    </div>
                    <ColorExampleBox color={values.primaryBrandColor}></ColorExampleBox>
                  </div>
                </div>

                <div className="modal-footer lp-flex lp-justify-end sm-mt-24">
                  <Button
                    size="medium"
                    variant="ghost"
                    className="no-min-width w-70"
                    testId="branding-edit-cancel-button"
                    disabled={editRequestLoading}
                    onClick={() => modalToggler(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size="medium"
                    variant="primary"
                    className="no-min-width"
                    testId="branding-edit-submit-button"
                    disabled={editRequestLoading}
                    isLoading={editRequestLoading}
                  >
                    Save
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </s.Wrapper>
    </Modal>
  );
}

export default EditBrandingModal;
