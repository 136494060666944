import { login } from 'api/auth/identity';
import RoutesList from 'config/constants';
import AuthLayout from 'layouts/auth/AuthLayout';
import LoginView from 'pages/auth/login/LoginView';
import qs from 'query-string';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppSelector } from 'redux/merchant/hooks';
import compareTwoStrings from 'utils/compareTwoStrings';
import { getMerchantBaseUrl } from 'utils/getBaseUrl';
import getCurrentUser from 'utils/getCurrentUser';

import AuthRouteList from '../constants';

type FormikFormValues = {
  email: string;
  password: string;
};

function LoginPage() {
  const navigate = useNavigate();

  const { tenantId, merchantId, merchantName, branding } = useAppSelector((state) => ({
    tenantId: state.config.tenantId,
    merchantId: state.config.merchantId,
    merchantName: state.config.merchantName,
    branding: state.config.branding,
  }));

  const [initialValues, setInitialValues] = useState<FormikFormValues>({
    email: '',
    password: '',
  });
  const [loginRequestLoader, setLoginRequestLoader] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { logout } = qs.parse(window.location.search.replace('?', ''));

  const submitHandler = async (values: FormikFormValues, formObj: { resetForm: () => void }) => {
    setLoginRequestLoader(true);
    try {
      await login(tenantId, values.email, values.password);
      const currentUser = await getCurrentUser();
      if (!currentUser) {
        throw Error('Failed to retrieve user details from firebase');
      }

      const isMatched = compareTwoStrings(currentUser.claims.limepay.merchantId, merchantId);
      if (isMatched) {
        setInitialValues(initialValues);
        formObj.resetForm();
      } else {
        setErrorMessage(
          `You are attempting to access the ${merchantName} dashboard. Please check your account URL is correct and enter a valid email`,
        );
      }
    } catch (error: any) {
      setErrorMessage(error.message || 'Login failed!');
    } finally {
      setLoginRequestLoader(false);
    }
  };

  useEffect(() => {
    if (logout && parseInt(logout as string) === 1) {
      toast.success('You have successfully logged out of your account.');
    }
  }, [logout]);

  return (
    <AuthLayout title="Login">
      <LoginView
        onSubmitClick={submitHandler}
        onForgotPasswordClick={() => {
          navigate(
            `${getMerchantBaseUrl(merchantName)}/${RoutesList.AUTH_ROUTE}/${AuthRouteList.FORGOT_PASSWORD_PAGE}`,
          );
        }}
        initialValues={initialValues}
        loginRequestLoader={loginRequestLoader}
        errorMsg={errorMessage}
        logoUri={branding && branding.logoUri ? branding.logoUri : ''}
        name={
          branding && branding.marketplaceBusinessDisplayName
            ? branding.marketplaceBusinessDisplayName.substring(0, 2).toUpperCase()
            : merchantName.substring(0, 2).toUpperCase()
        }
      />
    </AuthLayout>
  );
}

export default LoginPage;
