import { sendResetPasswordEmail } from 'api/auth/merchant-identity';
import RoutesList from 'config/constants';
import AuthLayout from 'layouts/auth/AuthLayout';
import ForgotPasswordConfirmationView from 'pages/auth/forgot-password/ForgotPasswordConfirmationView';
import ResetPasswordView from 'pages/auth/forgot-password/ForgotPasswordView';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/merchant/hooks';
import { setLoginPending } from 'redux/merchant/slice/configSlice';
import { getMerchantBaseUrl } from 'utils/getBaseUrl';
import getErrorMessage from 'utils/getErrorMessage';

import { Spinner } from '@limepayments/cosmic';

import AuthRouteList from '../constants';

type FormikFormValues = {
  email: string;
};

function ResetPasswordPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { merchantName, merchantId, apiBaseUri } = useAppSelector((state) => ({
    merchantName: state.config.merchantName,
    merchantId: state.config.merchantId,
    apiBaseUri: state.config.apiBaseUri,
  }));
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [showForgotPasswordConfirmSection, setShowForgotPasswordConfirmSection] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<FormikFormValues>({
    email: '',
  });
  const [forgotPasswordRequestLoader, setForgotPasswordRequestLoader] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  useEffect(() => {
    setLoginPending(true);
  }, [dispatch]);

  useEffect(() => {
    if (merchantId) {
      setIsPageLoading(false);
    }
  }, [setIsPageLoading, merchantId]);

  const submitHandler = async (values: FormikFormValues, formObj: { resetForm: () => void }) => {
    try {
      setForgotPasswordRequestLoader(true);
      setErrorMessage('');

      await sendResetPasswordEmail(apiBaseUri, {
        slug: merchantName,
        userType: 'merchantStaff',
        emailAddress: values.email,
        dashboardReferrer: 'Platform',
      });

      setInitialValues(initialValues);
      formObj.resetForm();

      setEmail(values.email);
      setShowForgotPasswordConfirmSection(true);
    } catch (err) {
      setErrorMessage(getErrorMessage(err));
    } finally {
      setForgotPasswordRequestLoader(false);
    }
  };

  return (
    <AuthLayout title="Reset password">
      <Fragment>
        {isPageLoading && (
          <div className="spinner-wrapper">
            <Spinner variant="simple" isVisible />
          </div>
        )}
        {!isPageLoading && (
          <Fragment>
            {!showForgotPasswordConfirmSection && (
              <ResetPasswordView
                onSubmitClick={submitHandler}
                onPreviousClick={() => {
                  navigate(`${getMerchantBaseUrl(merchantName)}/${RoutesList.AUTH_ROUTE}/${AuthRouteList.LOGIN_PAGE}`);
                }}
                initialValues={initialValues}
                requestLoader={forgotPasswordRequestLoader}
                errorMsg={errorMessage}
              />
            )}

            {showForgotPasswordConfirmSection && (
              <ForgotPasswordConfirmationView
                onSubmitClick={() => setShowForgotPasswordConfirmSection(false)}
                email={email}
              />
            )}
          </Fragment>
        )}
      </Fragment>
    </AuthLayout>
  );
}

export default ResetPasswordPage;
