import { Button, Heading, Illustration, Text } from '@limepayments/cosmic';

interface Props {
  onSubmitClick: () => void;
}

function AccountSuccessView({ onSubmitClick }: Props) {
  return (
    <>
      <div className="size-136 mx-auto mb-32" data-testid="activateAccountImage">
        <Illustration name="Creating" size="large" />
      </div>
      <div className="lp-w-full mb-32">
        <Heading alignment="center" tagName="h1" variant="md" className="mb-12">
          Account activated
        </Heading>
        <Text tagName="p" variant="body-3" alignment="center" testId="activateAccBodyText">
          You have successfully activated your account, log in to proceed with providing your business details for
          verification.
        </Text>
      </div>
      <Button
        onClick={onSubmitClick}
        size="medium"
        variant="primary"
        className="lp-w-full"
        testId="activateAccLoginBtn"
      >
        Login
      </Button>
    </>
  );
}

export default AccountSuccessView;
