import { PayoutDetailAPIResponseType } from 'api/merchant/dashboard/payouts.types';
import * as s from 'assets/styles/Platforms.styles';

import { Heading, Text } from '@limepayments/cosmic';

function AccountDetails({
  accountDetails,
}: {
  accountDetails: NonNullable<PayoutDetailAPIResponseType['accountDetails']>;
}) {
  return (
    <>
      <s.MerchantsData>
        <Heading alignment="left" tagName="h2" variant="xxs" className="mb-16">
          Account details
        </Heading>
        <div className="lp-half lp-flex two-column-block">
          <ul className="two-column">
            {'AuBankAccountIdentifier' in accountDetails.identifier && (
              <>
                <li>
                  <Text tagName="p" variant="body-3" className="text-dark-50">
                    BSB
                  </Text>
                  <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                    {accountDetails.identifier.AuBankAccountIdentifier.bsb}
                  </Text>
                </li>
                <li>
                  <Text tagName="p" variant="body-3" className="text-dark-50">
                    Account number
                  </Text>
                  <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                    {accountDetails.identifier.AuBankAccountIdentifier.accountNumber}
                  </Text>
                </li>
              </>
            )}
            {'NzBankAccountIdentifier' in accountDetails.identifier && (
              <li>
                <Text tagName="p" variant="body-3" className="text-dark-50">
                  Account number
                </Text>
                <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                  {accountDetails.identifier.NzBankAccountIdentifier.accountNumber}
                </Text>
              </li>
            )}
            <li>
              <Text tagName="p" variant="body-3" className="text-dark-50">
                Bank
              </Text>
              <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                {accountDetails.bankName ?? '-'}
              </Text>
            </li>
          </ul>

          <ul className="two-column">
            <li>
              <Text tagName="p" variant="body-3" className="text-dark-50">
                Origin
              </Text>
              <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                {accountDetails.country}
              </Text>
            </li>
            <li>
              <Text tagName="p" variant="body-3" className="text-dark-50">
                Currency
              </Text>
              <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                {accountDetails.currency}
              </Text>
            </li>
            <li>
              <Text tagName="p" variant="body-3" className="text-dark-50">
                External account ID
              </Text>
              <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                {accountDetails.accountId}
              </Text>
            </li>
          </ul>
        </div>
      </s.MerchantsData>

      <s.MerchantsData>
        <Heading alignment="left" tagName="h2" variant="xxs" className="mb-16">
          Payout details
        </Heading>
        <div className="lp-half lp-flex two-column-block last">
          <ul className="two-column">
            <li>
              <Text tagName="p" variant="body-3" className="text-dark-50">
                Delivery method
              </Text>
              <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                Bank transfer
              </Text>
            </li>
          </ul>
        </div>
      </s.MerchantsData>
    </>
  );
}

export default AccountDetails;
