/** @jsxImportSource @emotion/react */

import { OrderAPIResponseType } from 'api/merchant/dashboard/orders.types';
import { useCallback, useMemo, useState } from 'react';
import { useAppSelector } from 'redux/merchant/hooks';
import { toCurrency } from 'utils/currency';

import styled from '@emotion/styled';
import { Button, Heading, Icon, Link, Message, Modal, Tag, Text } from '@limepayments/cosmic';

import { Card } from './Card';
import { CardPaymentSource, Checkout, CheckoutProps, PaymentData } from './Checkout';

const LabelHeading = styled.div({
  fontSize: 12,
  lineHeight: '1.25rem',
  color: 'rgb(var(--lp-colors-neutral-600))',
});

const LabelValue = styled.div({
  fontSize: 14,
  lineHeight: '1.25rem',
  color: 'rgb(var(--lp-colors-neutral-800))',
});

const Divider = styled.div({
  height: 1,
  background: 'rgb(var(--lp-colors-neutral-200))',
});

const Th = styled.th<{ isNumeric?: boolean }>(({ isNumeric }) => ({
  padding: 8,
  textAlign: isNumeric ? 'right' : 'left',
}));

const Td = styled.td<{ isNumeric?: boolean }>(({ isNumeric }) => ({
  padding: 8,
  textAlign: isNumeric ? 'right' : 'left',
  wordBreak: isNumeric ? 'normal' : 'break-all',
}));

export type PayInvoiceProps = {
  order: OrderAPIResponseType;
  paymentData: PaymentData | null;
  onPaymentToken: CheckoutProps['onPaymentToken'];
  onUpdateBillingPaymentSource(paymentSource: CardPaymentSource, callback: (error?: string) => void): void;
};

export function PayInvoice({ order, paymentData, onPaymentToken, onUpdateBillingPaymentSource }: PayInvoiceProps) {
  const [showDetails, setShowDetails] = useState(false);
  const [modal, setModal] = useState({
    isOpen: false,
    isLoading: false,
  });

  const { branding } = useAppSelector((state) => ({
    branding: state.config.branding,
  }));

  const marketplaceBusinessDisplayName = branding?.marketplaceBusinessDisplayName ?? 'April';
  const marketplaceSupportEmail = branding?.marketplaceSupportEmail ?? 'support@meetapril.com';

  const invoiceDetails = useMemo(() => {
    if (!('InvoiceOrderTypeDetails' in order.orderTypeDetails)) return null;

    return order.orderTypeDetails.InvoiceOrderTypeDetails;
  }, [order.orderTypeDetails]);

  const taxTotal = useMemo(
    () => invoiceDetails?.invoiceLineItems?.reduce((taxTotal, item) => taxTotal + item.taxAmount, 0) ?? 0,
    [invoiceDetails],
  );

  const cardPaymentSource = useMemo(() => {
    if (!paymentData?.paymentSource?.cardPaymentSource?.cardPaymentSourceId) return null;

    return paymentData.paymentSource.cardPaymentSource;
  }, [paymentData?.paymentSource?.cardPaymentSource]);

  const hasBillingPaymentSource = !!invoiceDetails?.customerConfig?.billingPaymentSourceId;

  const allowUpdateBillingPaymentSource = useMemo(() => {
    return (
      cardPaymentSource?.cardPaymentSourceId &&
      cardPaymentSource.cardPaymentSourceId !== invoiceDetails?.customerConfig?.billingPaymentSourceId &&
      invoiceDetails?.customerConfig?.customToken
    );
  }, [cardPaymentSource?.cardPaymentSourceId, invoiceDetails?.customerConfig]);

  const handleUpdateBillingPaymentSource = useCallback(() => {
    if (modal.isLoading || !cardPaymentSource?.cardPaymentSourceId) return;

    setModal({ ...modal, isLoading: true });
    onUpdateBillingPaymentSource(cardPaymentSource, (error) => {
      if (error) return setModal({ ...modal, isLoading: false });

      setModal({ ...modal, isOpen: false, isLoading: false });
    });
  }, [cardPaymentSource, modal, onUpdateBillingPaymentSource]);

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        width: '100%',
        maxWidth: 570,
        padding: 16,
        paddingTop: 50,
        margin: '0 auto',
        fontFamily: 'var(--lp-fonts-body-1)',
        color: 'rgb(var(--lp-colors-neutral-600))',
      }}
    >
      <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 8 }}>
        <Heading tagName="h2" variant="sm">
          Invoice #{order.referenceOrderId}
        </Heading>
        {order.status === 'paid' && <Tag variant="success" size="medium" label="Paid" />}
      </div>
      <div css={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Text css={{ fontWeight: 500 }} variant="body-3" isEmphasised>
          Invoice From: {marketplaceBusinessDisplayName}
        </Text>
        <Heading tagName="h3" variant="xs">
          Amount: {toCurrency(order.amount.minorCurrencyUnits, order.amount.currency)}
        </Heading>
      </div>
      <Divider />
      {!!paymentData && (
        <Message variant="success" type="inline" dismissalType="none">
          <Heading tagName="h3" variant="xxs">
            Payment completed successfully
          </Heading>
          <Text variant="body-3">
            This invoice has been successfully paid{!!cardPaymentSource && <> for using payment method</>}
          </Text>
          {!!cardPaymentSource && <Card card={cardPaymentSource} />}
        </Message>
      )}
      {(hasBillingPaymentSource || allowUpdateBillingPaymentSource) && (
        <div css={{ '&>div>div, &>div>div>div': { flex: 1 } }}>
          <Message variant="information" type="inline" dismissalType="none">
            <div css={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
              <div css={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <LabelHeading>Auto pay is offered for future invoices</LabelHeading>
                {!hasBillingPaymentSource && (
                  <LabelValue>Save time by automatically paying future invoices on their due date.</LabelValue>
                )}
                {hasBillingPaymentSource && (
                  <LabelValue>
                    <>Auto payments are set up for future invoices owed to {marketplaceBusinessDisplayName}.</>
                    {!allowUpdateBillingPaymentSource && (
                      <>
                        {' '}
                        To update your preferred payment method, either pay your invoice before the due date using the
                        new payment method or contact us at{' '}
                        <Link href={`mailto: ${marketplaceSupportEmail}`}>{marketplaceSupportEmail}</Link>.
                      </>
                    )}
                  </LabelValue>
                )}
              </div>
              {allowUpdateBillingPaymentSource && (
                <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <Button
                    css={{ '& svg': { marginRight: 0 } }}
                    variant="primary"
                    size="small"
                    onClick={() => setModal({ ...modal, isOpen: true })}
                  >
                    {hasBillingPaymentSource ? 'Update' : 'Setup'} Auto Payment <Icon name="ArrowRight" />
                  </Button>
                </div>
              )}
            </div>
          </Message>
          <Modal isOpen={modal.isOpen}>
            <div
              css={{
                maxWidth: 350,
                display: 'flex',
                flexDirection: 'column',
                gap: 16,
                padding: 24,
                fontFamily: 'var(--lp-fonts-body-1)',
                color: 'rgb(var(--lp-colors-neutral-600))',
              }}
            >
              <Heading css={{ textAlign: 'center' }} tagName="h3" variant="xxs">
                {hasBillingPaymentSource ? 'Update' : 'Setup'} Auto Payment
              </Heading>
              <Divider />
              <Text variant="body-3">
                You authorise {marketplaceBusinessDisplayName} to automatically charge previously used payment method,
              </Text>
              {!!cardPaymentSource && <Card card={cardPaymentSource} />}
              <Text variant="body-3">
                to pay future invoices on their due dates. For further information please read our{' '}
                <Link
                  css={{ color: 'rgb(var(--lp-colors-primary))', fontWeight: 500 }}
                  href="https://meetapril.com/direct-debit-terms/"
                  target="_blank"
                >
                  Terms of Use
                </Link>
                .
              </Text>
              <div css={{ display: 'flex', alignItems: 'center', gap: 16 }}>
                <Button variant="ghost" size="small" onClick={() => setModal({ ...modal, isOpen: false })}>
                  Cancel
                </Button>
                <Button
                  css={{ flex: 1 }}
                  variant="primary"
                  size="small"
                  isLoading={modal.isLoading}
                  onClick={handleUpdateBillingPaymentSource}
                >
                  Authorise Payments
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      )}
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
          padding: 24,
          border: '1px solid rgb(var(--lp-colors-neutral-200))',
          borderRadius: 4,
        }}
      >
        {!!order.description && (
          <>
            <LabelHeading>Description</LabelHeading>
            <LabelValue>{order.description}</LabelValue>
            <Divider />
          </>
        )}
        <LabelHeading>Customer Details</LabelHeading>
        <LabelValue>
          {order.customerName}
          <br />
          {order.customerEmailAddress}
          <br />
          {order.customerPhoneNumber}
        </LabelValue>
      </div>
      <div css={{ display: 'flex', alignItems: 'center', gap: 4 }}>
        <Link
          css={{ fontWeight: 500 }}
          onClick={(event) => {
            event.preventDefault();
            setShowDetails(!showDetails);
          }}
        >
          {showDetails ? 'Hide invoice details' : 'View invoice details'}
        </Link>
        <Icon name={showDetails ? 'ChevronUp' : 'ChevronDown'} />
      </div>
      {showDetails && (
        <div
          css={{
            padding: 16,
            border: '1px solid rgb(var(--lp-colors-neutral-200))',
            borderRadius: 4,
            overflowX: 'auto',
          }}
        >
          <table css={{ width: '100%', fontSize: 14 }}>
            <thead>
              <tr>
                <Th>Description</Th>
                <Th isNumeric>Count</Th>
                <Th isNumeric>Total</Th>
              </tr>
            </thead>
            <tbody>
              {invoiceDetails?.invoiceLineItems?.map((item, index) => {
                return (
                  <tr key={`invoiceLineItem${index}`}>
                    <Td>
                      {item.description}
                      {item.category && (
                        <>
                          <br />
                          <Text css={{ color: 'rgb(var(--lp-colors-neutral-500))' }} variant="caption">
                            {item.category}
                          </Text>
                        </>
                      )}
                    </Td>
                    <Td isNumeric>{item.count}</Td>
                    <Td isNumeric>{toCurrency(item.feeAmount, order.amount.currency)}</Td>
                  </tr>
                );
              })}
              {!!taxTotal && (
                <tr>
                  <Td colSpan={2}>Tax included</Td>
                  <Td isNumeric>{toCurrency(taxTotal, order.amount.currency)}</Td>
                </tr>
              )}
              <tr
                css={{
                  fontWeight: '700',
                }}
              >
                <Td colSpan={2}>Total</Td>
                <Td isNumeric>{toCurrency(order.amount.minorCurrencyUnits, order.amount.currency)}</Td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      {order.status === 'created' && !!invoiceDetails?.customerConfig && (
        <Checkout
          amount={order.amount.minorCurrencyUnits}
          currency={order.amount.currency}
          publicKey={invoiceDetails.customerConfig.merchantPublicKey}
          customerToken={invoiceDetails.customerConfig.customToken}
          hidePayLaterOption={invoiceDetails.customerConfig.hidePayLaterOption}
          onPaymentToken={onPaymentToken}
        />
      )}
    </div>
  );
}
