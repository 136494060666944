import { PlatformMerchantObj } from 'api/platform/merchant.types';
import * as s from 'assets/styles/Platforms.styles';
import { DateTime } from 'luxon';
import ErrorComponent from 'pages/common/error/ErrorComponent';
import { Fragment, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { capitalizeFirstLowercaseRest } from 'utils/stringUtils';

import { Button, Heading, Icon, Spinner, Tag, Text } from '@limepayments/cosmic';

import { MerchantStatusObj } from '../constants';

export interface Props {
  onBackButtonClick: () => void;
  merchantDetailErrorMsg: string;
  showMerchantDetailLoader: boolean;
  merchantDetailObj: PlatformMerchantObj | null;
}

function MerchantDetails({
  onBackButtonClick,
  merchantDetailErrorMsg,
  showMerchantDetailLoader,
  merchantDetailObj,
}: Props) {
  const backDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!showMerchantDetailLoader && merchantDetailObj && backDivRef.current) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [showMerchantDetailLoader, merchantDetailObj]);

  return (
    <div className="content-wrap-inner lp-full">
      <Helmet>
        <title>Merchant Detail</title>
      </Helmet>
      <div className="header-back mb-28" ref={backDivRef}>
        <Button onClick={onBackButtonClick} variant="ghost" size={'small'} testId="merchant-detail-back-button">
          <Icon name="ArrowLeft" className="mr-8" />
          Back
        </Button>
      </div>
      {!showMerchantDetailLoader && merchantDetailErrorMsg.length ? (
        <ErrorComponent bodyText={merchantDetailErrorMsg} />
      ) : null}

      {showMerchantDetailLoader && (
        <div className="spinner-wrapper">
          <Spinner variant="simple" isVisible label="Loading..." />
        </div>
      )}

      {!showMerchantDetailLoader && !merchantDetailErrorMsg.length && merchantDetailObj && (
        <Fragment>
          <div className="header-details">
            <div className="header-details-inner">
              <Heading
                alignment="left"
                tagName="h2"
                variant="sm"
                className="mb-0 mr-16"
                testId="merchant-detail-brand-name"
              >
                {merchantDetailObj.businessProfile.brandName
                  ? merchantDetailObj.businessProfile.brandName
                  : merchantDetailObj.businessProfile.businessName}
              </Heading>
              <Tag
                label={merchantDetailObj.status}
                variant={`${
                  merchantDetailObj.status === `Active`
                    ? 'success'
                    : merchantDetailObj.status === `Pending`
                    ? `positive`
                    : `negative`
                }`}
                size="small"
              />
            </div>
            <div className="lp-flex sm-flex-col sm-w-full">
              {/* TODO: do it later */}
              {/* <div className="stacked-data">
                <Text tagName="p" variant="caption" className="text-dark-50 m-0">
                  Volume - Last 7 days
                </Text>
                <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0" isEmphasised>
                  $43,500.00
                </Text>
              </div> */}
              <div className="stacked-data" data-testid="merchant-detail-heading-date-div">
                <Text
                  tagName="p"
                  variant="caption"
                  className="text-dark-50 m-0"
                  testId="merchant-detail-heading-date-heading"
                >
                  {merchantDetailObj.status === MerchantStatusObj.Pending
                    ? 'Created'
                    : 'Date '.concat(merchantDetailObj.status.toLowerCase())}
                </Text>
                <Text
                  tagName="p"
                  variant="body-3"
                  className="text-dark m-0 mt-0"
                  testId="merchant-detail-heading-date-value"
                  isEmphasised
                >
                  {DateTime.fromISO(
                    merchantDetailObj.status === MerchantStatusObj.Pending
                      ? merchantDetailObj.createdAt
                      : merchantDetailObj.stateUpdatedAt,
                  ).toFormat('dd LLL yyyy')}
                </Text>
              </div>
            </div>
          </div>

          <s.MerchantsData>
            <Heading alignment="left" tagName="h2" variant="xs" className="mb-32">
              Identity
            </Heading>
            <Heading alignment="left" tagName="h2" variant="xxs" className="mb-16">
              Account details
            </Heading>
            <div className="lp-half lp-flex two-column-block">
              <ul className="two-column">
                <li className="">
                  <Text tagName="p" variant="body-3" className="text-dark-50">
                    Merchant trading name
                  </Text>
                  <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                    {merchantDetailObj.businessProfile.brandName
                      ? merchantDetailObj.businessProfile.brandName
                      : merchantDetailObj.businessProfile.businessName}
                  </Text>
                </li>
                <li className="">
                  <Text tagName="p" variant="body-3" className="text-dark-50">
                    Merchant ID
                  </Text>
                  <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                    {merchantDetailObj.merchantId}
                  </Text>
                </li>
                <li className="">
                  <Text tagName="p" variant="body-3" className="text-dark-50">
                    Email
                  </Text>
                  <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                    {merchantDetailObj.businessProfile.contactEmail}
                  </Text>
                </li>
                <li className="">
                  <Text tagName="p" variant="body-3" className="text-dark-50">
                    Payout schedule
                  </Text>
                  <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                    {capitalizeFirstLowercaseRest(merchantDetailObj.payoutSchedule)}
                  </Text>
                </li>
              </ul>
            </div>
          </s.MerchantsData>

          <s.MerchantsData>
            <Heading alignment="left" tagName="h2" variant="xxs" className="mb-16">
              Business details
            </Heading>
            <div className="lp-half lp-flex two-column-block">
              <ul className="two-column">
                <li className="">
                  <Text tagName="p" variant="body-3" className="text-dark-50">
                    Legal entity name
                  </Text>
                  <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                    {merchantDetailObj.businessProfile.businessName}
                  </Text>
                </li>
                <li className="">
                  <Text tagName="p" variant="body-3" className="text-dark-50">
                    Business registration number
                  </Text>
                  <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                    {merchantDetailObj.businessProfile.taxId}
                  </Text>
                </li>
                <li className="">
                  <Text tagName="p" variant="body-3" className="text-dark-50">
                    Tax country
                  </Text>
                  <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                    {merchantDetailObj.businessProfile.address.country === `NZ` ? `New Zealand` : `Australia`}
                  </Text>
                </li>
                <li className="" data-testid="merchant-detail-business-type-listing">
                  <Text
                    tagName="p"
                    variant="body-3"
                    className="text-dark-50"
                    testId="merchant-detail-business-type-heading"
                  >
                    Business type
                  </Text>
                  <Text
                    tagName="p"
                    variant="body-3"
                    className="text-dark m-0 mt-0"
                    testId="merchant-detail-business-type-value"
                  >
                    {capitalizeFirstLowercaseRest(merchantDetailObj.businessProfile.businessType)}
                  </Text>
                </li>
              </ul>

              <ul className="two-column">
                <li className="">
                  <Text tagName="p" variant="body-3" className="text-dark-50">
                    Industry
                  </Text>
                  <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                    {merchantDetailObj.businessProfile.industry.split(/(?=[A-Z])/).join(' ')}
                  </Text>
                </li>
                <li className="">
                  <Text tagName="p" variant="body-3" className="text-dark-50">
                    Card statement name
                  </Text>
                  <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                    {merchantDetailObj.businessProfile.cardStatementName}
                  </Text>
                </li>
                <li className="">
                  <Text tagName="p" variant="body-3" className="text-dark-50">
                    SMS sender name
                  </Text>
                  <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                    {merchantDetailObj.businessProfile.smsSenderName}
                  </Text>
                </li>
                <li className="">
                  <Text tagName="p" variant="body-3" className="text-dark-50">
                    Business website
                  </Text>
                  <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                    {merchantDetailObj.businessProfile.website ? merchantDetailObj.businessProfile.website : 'None'}
                  </Text>
                </li>
              </ul>
            </div>
          </s.MerchantsData>

          <s.MerchantsData>
            <Heading alignment="left" tagName="h2" variant="xxs" className="mb-16">
              Contact details
            </Heading>
            <div className="lp-half lp-flex two-column-block">
              <ul className="two-column">
                <li className="">
                  <Text tagName="p" variant="body-3" className="text-dark-50">
                    Name
                  </Text>
                  <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                    {merchantDetailObj.businessProfile.contactPerson.firstName}{' '}
                    {merchantDetailObj.businessProfile.contactPerson.lastName}
                  </Text>
                </li>
                <li className="">
                  <Text tagName="p" variant="body-3" className="text-dark-50">
                    Job title
                  </Text>
                  <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                    {merchantDetailObj.businessProfile.contactPerson.title}
                  </Text>
                </li>
                <li className="">
                  <Text tagName="p" variant="body-3" className="text-dark-50">
                    Contact email address
                  </Text>
                  <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                    {merchantDetailObj.businessProfile.contactEmail}
                  </Text>
                </li>
              </ul>

              <ul className="two-column">
                <li className="">
                  <Text tagName="p" variant="body-3" className="text-dark-50">
                    Contact phone number
                  </Text>
                  <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                    {merchantDetailObj.businessProfile.phoneNo}
                  </Text>
                </li>
                <li className="">
                  <Text tagName="p" variant="body-3" className="text-dark-50">
                    Business address
                  </Text>
                  <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                    {`${merchantDetailObj.businessProfile.address.streetNumber} ${merchantDetailObj.businessProfile.address.streetName} ${merchantDetailObj.businessProfile.address.streetType}, ${merchantDetailObj.businessProfile.address.state} ${merchantDetailObj.businessProfile.address.country} ${merchantDetailObj.businessProfile.address.postalCode}`}
                  </Text>
                </li>
                <li className="">
                  <Text tagName="p" variant="body-3" className="text-dark-50">
                    Country
                  </Text>
                  <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                    {merchantDetailObj.businessProfile.address.country === 'NZ' ? `New Zealand` : `Australia`}
                  </Text>
                </li>
              </ul>
            </div>
          </s.MerchantsData>
        </Fragment>
      )}
    </div>
  );
}

export default MerchantDetails;
