import { getMerchantApiKeys } from 'api/merchant/dashboard/settings/api-keys';
import { IApiKeyObj } from 'api/merchant/dashboard/settings/api-keys.types';
import MerchantDashboardLayout from 'layouts/merchant-dashboard/MerchantDashboardLayout';
import { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from 'redux/merchant/hooks';
import getErrorMessage from 'utils/getErrorMessage';

import APIKeysView from './partials/APIKeysView';

function APIKeysPage() {
  const [fetchListLoader, setFetchListLoader] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [apiKeysObj, setApiKeysObj] = useState<IApiKeyObj | null>(null);
  const [publicTokenCopied, setPublicTokenCopied] = useState<boolean>(false);
  const [secrectTokenCopied, setSecretTokenCopied] = useState<boolean>(false);

  const { merchantId, apiBaseUri } = useAppSelector((state) => ({
    merchantId: state.config.merchantId,
    apiBaseUri: state.config.apiBaseUri,
  }));

  const fetchApiKeysList = useCallback(async () => {
    try {
      setFetchListLoader(true);
      setErrorMessage('');

      const response = await getMerchantApiKeys(apiBaseUri, merchantId);

      const apiKeysActiveList = response.filter((obj) => {
        return obj.isActive === true;
      });

      setApiKeysObj(apiKeysActiveList[0]);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setFetchListLoader(false);
    }
  }, [apiBaseUri, merchantId]);

  useEffect(() => {
    fetchApiKeysList();
  }, [fetchApiKeysList]);

  useEffect(() => {
    if (!fetchListLoader) {
      setIsPageLoading(false);
    }
  }, [setIsPageLoading, fetchListLoader]);

  const copyToClipBoard = (_text: string, isPublicButtonClicked: boolean) => {
    navigator.clipboard.writeText(_text);
    if (isPublicButtonClicked) {
      setPublicTokenCopied(true);
      setTimeout(() => {
        setPublicTokenCopied(false);
      }, 3000);
    } else {
      setSecretTokenCopied(true);
      setTimeout(() => {
        setSecretTokenCopied(false);
      }, 3000);
    }
  };

  return (
    <MerchantDashboardLayout activeTab="settings" title="API keys">
      <APIKeysView
        copyToClipBoard={copyToClipBoard}
        publicTokenCopied={publicTokenCopied}
        secrectTokenCopied={secrectTokenCopied}
        errorMessage={errorMessage}
        isPageLoading={isPageLoading}
        apiKeysObj={apiKeysObj}
      />
    </MerchantDashboardLayout>
  );
}

export default APIKeysPage;
