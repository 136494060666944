import * as s from 'assets/styles/Platforms.styles';
import AuthRoutesList from 'pages/platform/auth/constants';
import DashboardRoutesList from 'pages/platform/dashboard/constants';
import MerchantRoutesList from 'pages/platform/dashboard/merchant/constants';
import PaymentRoutesList from 'pages/platform/dashboard/payment/constants';
import SettingRoutesList from 'pages/platform/dashboard/settings/constants';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/platform/hooks';
import { getPlatformBaseUrl } from 'utils/getBaseUrl';
import { showZendeskWidget } from 'utils/zendesk';

import { Button, Heading, Icon } from '@limepayments/cosmic';

interface Props {
  activeTab?: string;
}

const ToggleSidebarComponent = ({ activeTab }: Props) => {
  const SETTINGS_MENU = 'settings';
  const PAYMENT_MENU = 'payment';
  const MERCHANT_MENU = 'merchant';

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isOpen, setIsopen] = useState<boolean>(false);
  const [isInnerOpen, setIsInneropen] = useState<boolean>(false);
  const [activeSection, setActiveSection] = useState<string>('');
  const [pathElements, setPathElements] = useState<Array<string>>([]);
  const { merchantEmail, platformName, payoutMerchantId } = useAppSelector((state) => ({
    merchantEmail: state.config.merchantEmail,
    platformName: state.config.platformName,
    payoutMerchantId: state.setting.payoutMerchantId,
  }));

  const toggleSidebar = (overlayClicked?: boolean) => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
    if (!isOpen) {
      const pathElements = pathname.split('/');
      setPathElements(pathElements);
      if (
        pathElements &&
        pathElements.length === 6 &&
        (pathElements[4] === SETTINGS_MENU || pathElements[4] === PAYMENT_MENU)
      ) {
        setActiveSection(pathElements[4]);
        setIsInneropen(true);
      }
    }

    if (overlayClicked) {
      setIsInneropen(false);
    }
  };

  const toggleInnerSidebar = (section: string) => {
    setActiveSection(section);
    isInnerOpen === true ? setIsInneropen(false) : setIsInneropen(true);
  };

  return (
    <>
      <div className="sidebar-menu-wrap">
        <s.HeaderHelp className="btn btn-primary" onClick={showZendeskWidget}>
          <Icon name="Help" />
        </s.HeaderHelp>
        <s.HeaderToggle className="btn btn-primary" onClick={() => toggleSidebar()}>
          <Icon name="Ellipse" />
        </s.HeaderToggle>
        <div className={`sidebar-menu ${isOpen === true ? 'is-active' : ''}`}>
          <div className="sidebar-menu-inner">
            <div className="sd-header">
              <button className="sd-close" onClick={() => toggleSidebar()}>
                <Icon name="Cross" />
              </button>
            </div>
            <div className="sd-body">
              <ul>
                <li>
                  <button
                    className={`sd-link ${activeTab === PAYMENT_MENU ? ` is-active` : ``}`}
                    onClick={() => toggleInnerSidebar(PAYMENT_MENU)}
                  >
                    <span>Payments</span>
                    <Icon name="ChevronRight" />
                  </button>
                </li>
                <li
                  onClick={() =>
                    navigate(
                      `${getPlatformBaseUrl(platformName)}/dashboard/${DashboardRoutesList.MERCHANT_ROUTE}/${
                        MerchantRoutesList.MERCHANT_LANDING
                      }`,
                    )
                  }
                >
                  <button className={`sd-link ${activeTab === MERCHANT_MENU ? ` is-active` : ``}`}>
                    <span>Merchants</span>
                  </button>
                </li>
                <li className="divider"></li>
                <li>
                  <button
                    className={`sd-link ${activeTab === SETTINGS_MENU ? ` is-active` : ``}`}
                    onClick={() => toggleInnerSidebar(SETTINGS_MENU)}
                  >
                    <span className="lp-flex lp-align-center">
                      <Icon name="Settings" className="mr-8" />
                      <span>Settings</span>
                    </span>
                    <Icon name="ChevronRight" />
                  </button>
                </li>
                {/* <li>
                  <a href={config.HELP_URL} target="_blank" rel="noreferrer" className="sd-link">
                    <span className="lp-flex lp-align-center">
                      <Icon name="Help" className="mr-8" />
                      <span>Help</span>
                    </span>
                  </a>
                </li> */}
              </ul>

              {/* Sub Child Menus */}
              <div className={`sd-submenu ${isInnerOpen === true ? 'navigation-active' : ''}`}>
                <div className="sidebar-menu-inner">
                  <div className="sd-header">
                    <Button size="small" onClick={() => toggleInnerSidebar('')} variant="ghost" className="sd-back">
                      <Icon name="ArrowLeft" />
                    </Button>
                    <span>{activeSection === PAYMENT_MENU ? `Payments` : `Settings`}</span>
                    <button type="button" className="sd-close2" onClick={() => toggleInnerSidebar('')}>
                      <Icon name="Cross" />
                    </button>
                  </div>

                  {activeSection === PAYMENT_MENU && (
                    <div className="sd-body">
                      <ul>
                        <li
                          onClick={() =>
                            navigate(
                              `${getPlatformBaseUrl(platformName)}/dashboard/${DashboardRoutesList.PAYMENT_ROUTE}/${
                                PaymentRoutesList.PAYMENT_LANDING
                              }`,
                            )
                          }
                        >
                          <button
                            className={`sd-link ${
                              pathElements[4] === PAYMENT_MENU && pathElements[5] === PaymentRoutesList.PAYMENT_LANDING
                                ? 'is-active'
                                : ''
                            }`}
                          >
                            Payments
                          </button>
                        </li>
                        <li
                          onClick={() =>
                            navigate(
                              `${getPlatformBaseUrl(platformName)}/dashboard/${DashboardRoutesList.PAYMENT_ROUTE}/${
                                PaymentRoutesList.PAYMENT_TRANSACTIONS
                              }`,
                            )
                          }
                        >
                          <button
                            className={`sd-link ${
                              pathElements[4] === PAYMENT_MENU &&
                              pathElements[5] === PaymentRoutesList.PAYMENT_TRANSACTIONS
                                ? 'is-active'
                                : ''
                            }`}
                          >
                            All transactions
                          </button>
                        </li>
                      </ul>
                    </div>
                  )}

                  {/* Settings Panel */}
                  {activeSection === SETTINGS_MENU && (
                    <div className="sd-body">
                      <Heading alignment="left" tagName="h2" variant="xs" className="submenu-title fw-medium mb-0">
                        My account
                      </Heading>
                      <ul className="mb-28">
                        <li
                          onClick={() =>
                            navigate(
                              `${getPlatformBaseUrl(platformName)}/dashboard/${DashboardRoutesList.SETTINGS_ROUTE}/${
                                SettingRoutesList.SETTINGS_PROFILE
                              }`,
                            )
                          }
                        >
                          <button
                            className={`sd-link ${
                              pathElements[4] === SETTINGS_MENU &&
                              pathElements[5] === SettingRoutesList.SETTINGS_PROFILE
                                ? 'is-active'
                                : ''
                            }`}
                          >
                            My profile
                          </button>
                        </li>
                      </ul>

                      <Heading alignment="left" tagName="h2" variant="xs" className="submenu-title fw-medium mb-0">
                        Settings
                      </Heading>
                      <ul>
                        <li
                          onClick={() =>
                            navigate(
                              `${getPlatformBaseUrl(platformName)}/dashboard/${DashboardRoutesList.SETTINGS_ROUTE}/${
                                SettingRoutesList.SETTINGS_BUSINESS_PROFILE
                              }`,
                            )
                          }
                        >
                          <button
                            className={`sd-link ${
                              pathElements[4] === SETTINGS_MENU &&
                              pathElements[5] === SettingRoutesList.SETTINGS_BUSINESS_PROFILE
                                ? 'is-active'
                                : ''
                            }`}
                          >
                            Business profile
                          </button>
                        </li>

                        {payoutMerchantId && (
                          <li
                            onClick={() =>
                              navigate(
                                `${getPlatformBaseUrl(platformName)}/dashboard/${DashboardRoutesList.SETTINGS_ROUTE}/${
                                  SettingRoutesList.SETTINGS_PAYOUTS
                                }`,
                              )
                            }
                          >
                            <button
                              className={`sd-link ${
                                pathElements[4] === SETTINGS_MENU &&
                                pathElements[5] === SettingRoutesList.SETTINGS_PAYOUTS
                                  ? 'is-active'
                                  : ''
                              }`}
                            >
                              Payouts
                            </button>
                          </li>
                        )}
                        <li
                          onClick={() =>
                            navigate(
                              `${getPlatformBaseUrl(platformName)}/dashboard/${DashboardRoutesList.SETTINGS_ROUTE}/${
                                SettingRoutesList.SETTINGS_BRANDING
                              }`,
                            )
                          }
                        >
                          <button
                            className={`sd-link ${
                              pathElements[4] === SETTINGS_MENU &&
                              pathElements[5] === SettingRoutesList.SETTINGS_BRANDING
                                ? 'is-active'
                                : ''
                            }`}
                          >
                            Branding
                          </button>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              {/* Sub Child Menus */}
            </div>

            <div className="sd-footer">
              <ul>
                <li className="is-active">{merchantEmail}</li>
                {/* TODO: do it later */}
                {/* <li className="fw-regular">Admin</li> */}
                <li className="mt-12">
                  <button
                    onClick={() => navigate(`${getPlatformBaseUrl(platformName)}/${AuthRoutesList.LOGOUT_PAGE}`)}
                    className="sd-link"
                  >
                    Log out
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          className={`sidebar-menu-overlay ${isOpen === true ? 'is-enabled' : ''}`}
          onClick={() => toggleSidebar(true)}
        ></div>
      </div>
    </>
  );
};

export default ToggleSidebarComponent;
