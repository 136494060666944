export interface Address {
  unitNumber?: string;
  streetNumber: string;
  streetName: string;
  streetType: string;
  suburb: string;
  city?: string;
  state?: string;
  postalCode: string;
  country: string;
}

type addressToStringType = (address?: Address) => string;

export const addressToString: addressToStringType = (address?: Address) => {
  let returnData = ``;

  if (address) {
    if (address.unitNumber) {
      returnData += `${address.unitNumber}, `;
    }

    if (address.streetNumber) {
      returnData += `${address.streetNumber}, `;
    }

    if (address.streetName) {
      returnData += `${address.streetName} ${address.streetType} `;
    }
    if (address.suburb) {
      returnData += `${address.suburb} `;
    }

    returnData += address.city ? `${address.city}` : `${address.state}`;
    returnData += ` - ${address.postalCode}`;
  }

  return returnData;
};
