import { getPayoutBankAccounts, getPayoutFrequency, getPayoutReports } from 'api/merchant/dashboard/settings/payouts';
import { AccountInterface, PayoutReportsAPIResponse } from 'api/merchant/dashboard/settings/payouts.types';
import MerchantDashboardLayout from 'layouts/merchant-dashboard/MerchantDashboardLayout';
import { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from 'redux/merchant/hooks';
import getErrorMessage from 'utils/getErrorMessage';

// Import Static UIs
import PayoutSettingView from './partials/PayoutView';

export const getInitialState = () => {
  return {
    notificationEmails: [],
    notificationsEnabled: false,
  };
};

export const getAccountInitialState = (): AccountInterface => {
  return {
    externalAccountId: '',
    accountNumberLast4: '',
    country: '',
    currency: 'AUD',
    isDefaultForCurrency: false,
  };
};

function PayoutSettingPage() {
  const [payoutReports, setPayoutReports] = useState<PayoutReportsAPIResponse>(getInitialState());
  const [payoutBankAccounts, setPayoutBankAccounts] = useState<AccountInterface>(getAccountInitialState());
  const [payoutFrequency, setPayoutFrequency] = useState<string>('daily');
  const [payoutReportsFetchLoader, setPayoutReportsFetchLoader] = useState<boolean>(true);
  const [payoutBankAccountsFetchLoader, setPayoutBankAccountsFetchLoader] = useState<boolean>(true);
  const [payoutFrequencyFetchLoader, setPayoutFrequencyFetchLoader] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [timestamp] = useState<number>(0);

  const { merchantId, apiBaseUri } = useAppSelector((state) => ({
    merchantId: state.config.merchantId,
    apiBaseUri: state.config.apiBaseUri,
  }));

  const fetchPayoutFrequency = useCallback(async () => {
    try {
      setPayoutFrequencyFetchLoader(true);

      const response = await getPayoutFrequency(apiBaseUri, merchantId);

      setPayoutFrequency(response);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setPayoutFrequencyFetchLoader(false);
    }
  }, [apiBaseUri, merchantId]);

  const fetchPayoutReports = useCallback(async () => {
    try {
      setPayoutReportsFetchLoader(true);

      const response = await getPayoutReports(apiBaseUri, merchantId);

      setPayoutReports(response);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setPayoutReportsFetchLoader(false);
    }
  }, [apiBaseUri, merchantId]);

  const fetchPayoutBankAccounts = useCallback(async () => {
    try {
      setPayoutBankAccountsFetchLoader(true);

      const response = await getPayoutBankAccounts(apiBaseUri, merchantId, timestamp);

      const activeBankArray = response.filter((obj) => {
        return obj.isDefaultForCurrency === true;
      });

      setPayoutBankAccounts(activeBankArray.length > 0 ? activeBankArray[0] : getAccountInitialState());
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setPayoutBankAccountsFetchLoader(false);
    }
  }, [apiBaseUri, merchantId, timestamp]);

  useEffect(() => {
    if (!merchantId) {
      return;
    }

    setErrorMessage('');
    fetchPayoutReports();
    fetchPayoutBankAccounts();
    fetchPayoutFrequency();
  }, [apiBaseUri, merchantId, fetchPayoutReports, fetchPayoutBankAccounts, fetchPayoutFrequency]);

  useEffect(() => {
    if (!payoutReportsFetchLoader && !payoutBankAccountsFetchLoader && !payoutFrequencyFetchLoader) {
      setIsPageLoading(false);
    }
  }, [payoutReportsFetchLoader, payoutBankAccountsFetchLoader, payoutFrequencyFetchLoader, setIsPageLoading]);

  return (
    <MerchantDashboardLayout activeTab="settings" title="Balances Settings">
      <PayoutSettingView
        payoutReports={payoutReports}
        payoutBankAccounts={payoutBankAccounts}
        payoutFrequency={payoutFrequency}
        isPageLoading={isPageLoading}
        errorMessage={errorMessage}
        payoutReportsFetchLoader={payoutReportsFetchLoader}
        payoutBankAccountsFetchLoader={payoutBankAccountsFetchLoader}
        payoutFrequencyFetchLoader={payoutFrequencyFetchLoader}
        fetchPayoutFrequency={fetchPayoutFrequency}
        fetchPayoutBankAccounts={fetchPayoutBankAccounts}
        fetchPayoutReports={fetchPayoutReports}
      />
    </MerchantDashboardLayout>
  );
}

export default PayoutSettingPage;
