import * as s from 'assets/styles/Onboarding.styles';
import { ausStateObj } from 'config/constants';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/merchant/hooks';
import { getMerchantBaseUrl } from 'utils/getBaseUrl';

import { Button, Heading, Icon, Text } from '@limepayments/cosmic';

import OnboardingRoutesList from '../../constants';
import { UpdateMerchantBusinessContactPayload } from '../../step-03/types';

interface Props {
  businessContact: UpdateMerchantBusinessContactPayload;
}

const BusinessContactDetails = ({ businessContact }: Props) => {
  const navigate = useNavigate();

  const { merchantName } = useAppSelector((state) => ({
    merchantName: state.config.merchantName,
  }));

  return (
    <>
      <div className="lp-w-full lp-equal-width mb-24">
        <s.MultiColumnRespBlock className="content-header mb-24">
          <Heading alignment="left" tagName="h4" variant="sm">
            Business contact details
          </Heading>
          <Button
            onClick={() => {
              navigate(`${getMerchantBaseUrl(merchantName)}/onboarding/${OnboardingRoutesList.ONBOARDING_STEP03_PAGE}`);
            }}
            size="medium"
            variant="ghost"
            className="no-min-width lp-justify-end"
            testId="business-contact-edit-button"
          >
            Edit
            <Icon name="Edit" />
          </Button>
        </s.MultiColumnRespBlock>
        <div className="content">
          <s.MultiColumnRespBlock className="mb-24" data-testid="first-name-div">
            <Text tagName="p" variant="body-3" testId="first-name-text">
              First name
            </Text>
            <Text tagName="p" variant="body-3" testId="first-name-value" isEmphasised>
              {businessContact.contactPerson.firstName}
            </Text>
          </s.MultiColumnRespBlock>
          <s.MultiColumnRespBlock className="mb-24" data-testid="last-name-div">
            <Text tagName="p" variant="body-3" testId="last-name-text">
              Last name
            </Text>
            <Text tagName="p" variant="body-3" testId="last-name-value" isEmphasised>
              {businessContact.contactPerson.lastName}
            </Text>
          </s.MultiColumnRespBlock>
          <s.MultiColumnRespBlock className="mb-24" data-testid="job-title-div">
            <Text tagName="p" variant="body-3" testId="job-title-text">
              Job title
            </Text>
            <Text tagName="p" variant="body-3" testId="job-title-value" isEmphasised>
              {businessContact.contactPerson.title}
            </Text>
          </s.MultiColumnRespBlock>
          <s.MultiColumnRespBlock className="mb-24" data-testid="con-email-div">
            <Text tagName="p" variant="body-3" testId="con-email-text">
              Contact email address
            </Text>
            <Text tagName="p" variant="body-3" testId="con-email-value" isEmphasised>
              {businessContact.contactEmail}
            </Text>
          </s.MultiColumnRespBlock>
          <s.MultiColumnRespBlock className="mb-24" data-testid="con-phone-div">
            <Text tagName="p" variant="body-3" testId="con-phone-text">
              Contact phone number
            </Text>
            <Text tagName="p" variant="body-3" testId="con-phone-value" isEmphasised>
              {businessContact.phoneNo}
            </Text>
          </s.MultiColumnRespBlock>
          <s.MultiColumnRespBlock className="mb-24" data-testid="country-div">
            <Text tagName="p" variant="body-3" testId="country-text">
              Country
            </Text>
            <Text tagName="p" variant="body-3" testId="country-value" isEmphasised>
              {businessContact.address.country === 'AU' ? `Australia` : `New Zealand`}
            </Text>
          </s.MultiColumnRespBlock>
          <s.MultiColumnRespBlock className="mb-24" data-testid="unit-num-div">
            <Text tagName="p" variant="body-3" testId="unit-num-text">
              Unit number
            </Text>
            <Text
              tagName="p"
              variant="body-3"
              testId="unit-num-value"
              isEmphasised
              className={!businessContact.address.unitNumber ? 'is-disabled' : ''}
            >
              {businessContact.address.unitNumber ? businessContact.address.unitNumber : `N/A`}
            </Text>
          </s.MultiColumnRespBlock>
          <s.MultiColumnRespBlock className="mb-24" data-testid="street-num-div">
            <Text tagName="p" variant="body-3" testId="street-num-text">
              Street number
            </Text>
            <Text tagName="p" variant="body-3" testId="street-num-value" isEmphasised>
              {businessContact.address.streetNumber}
            </Text>
          </s.MultiColumnRespBlock>
          <s.MultiColumnRespBlock className="mb-24" data-testid="street-name-div">
            <Text tagName="p" variant="body-3" testId="street-name-text">
              Street name
            </Text>
            <Text tagName="p" variant="body-3" testId="street-name-value" isEmphasised>
              {businessContact.address.streetName}
            </Text>
          </s.MultiColumnRespBlock>
          <s.MultiColumnRespBlock className="mb-24" data-testid="street-type-div">
            <Text tagName="p" variant="body-3" testId="street-type-text">
              Street type
            </Text>
            <Text tagName="p" variant="body-3" testId="street-type-value" isEmphasised>
              {businessContact.address.streetType}
            </Text>
          </s.MultiColumnRespBlock>
          <s.MultiColumnRespBlock className="mb-24" data-testid="suburb-div">
            <Text tagName="p" variant="body-3" testId="suburb-text">
              Suburb
            </Text>
            <Text tagName="p" variant="body-3" testId="suburb-value" isEmphasised>
              {businessContact.address.suburb}
            </Text>
          </s.MultiColumnRespBlock>
          {businessContact.address.country === `NZ` && (
            <s.MultiColumnRespBlock className="mb-24" data-testid="city-div">
              <Text tagName="p" variant="body-3" testId="city-text">
                City
              </Text>
              <Text tagName="p" variant="body-3" testId="city-value" isEmphasised>
                {businessContact.address.city}
              </Text>
            </s.MultiColumnRespBlock>
          )}

          {businessContact.address.country === `AU` && businessContact.address.state && (
            <s.MultiColumnRespBlock className="mb-24" data-testid="state-div">
              <Text tagName="p" variant="body-3" testId="state-text">
                State
              </Text>
              <Text tagName="p" variant="body-3" testId="state-value" isEmphasised>
                {ausStateObj[businessContact.address.state as keyof typeof ausStateObj]}
              </Text>
            </s.MultiColumnRespBlock>
          )}
          <s.MultiColumnRespBlock className="mb-24" data-testid="postcode-div">
            <Text tagName="p" variant="body-3" testId="postcode-text">
              Postcode
            </Text>
            <Text tagName="p" variant="body-3" testId="postcode-value" isEmphasised>
              {businessContact.address.postalCode}
            </Text>
          </s.MultiColumnRespBlock>
        </div>
      </div>
      <s.Separator>
        <hr />
      </s.Separator>
    </>
  );
};

export default BusinessContactDetails;
