import { DateTime } from 'luxon';
import DateRangeFilterComponent from 'pages/platform/dashboard/merchant/listing/partials/DateRangeFilterComponent';
import { Fragment, useCallback, useEffect, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { useAppDispatch, useAppSelector } from 'redux/platform/hooks';
import { setListingPagePropsObj, setTxnFilterObj } from 'redux/platform/slice/transactionSlice';
import { isValidObjectId } from 'utils/stringUtils';

import { Button, Checkbox, CheckboxGroup, Heading, Icon, Text } from '@limepayments/cosmic';

import { TxnFilterEntryTypeObj } from '../../constants';
import { getInitialFilterState } from '../initialStates';
import { ITxnFilterObj, ITxnFilterObjErrors } from '../types';

const getInitialErrorState = () => {
  return {
    dateValueError: '',
    dateTypeCompareError: '',
    dateTypeFilterError: '',
    entryTypeError: '',
    dateRangeError: '',
    dateSingleError: '',
    merchantIdError: '',
    payoutIdError: '',
    txnIdError: '',
    amountError: '',
  };
};

interface Props {
  modalToggler: () => void;
}

function FilterComponent({ modalToggler }: Props) {
  const dispatch = useAppDispatch();
  const { txnFilterObj, listingPageProps, apiBaseUri } = useAppSelector((state) => ({
    txnFilterObj: state.transaction.txnFilterObj,
    listingPageProps: state.transaction.listingPageProps,
    apiBaseUri: state.config.apiBaseUri,
  }));

  const [filterObj, setFilterObj] = useState<ITxnFilterObj>(txnFilterObj);
  const [filterObjError, setFilterObjError] = useState<ITxnFilterObjErrors>(getInitialErrorState());

  const filterChangeHandler = useCallback(
    (key: string, value: boolean | string | Date | Array<Date> | null | undefined | [Date | null, Date | null]) => {
      setFilterObj((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    },
    [],
  );

  const filterSaveHandler = async () => {
    const filterObjErrorRef = { ...getInitialErrorState() };
    let hasError = false;
    let totalFiltersApplied = 0;

    if (filterObj.dateActiveChecked) {
      if (!filterObj.dateTypeCompareValue) {
        filterObjErrorRef.dateTypeCompareError = 'This field is required';
        hasError = true;
      }

      if (filterObj.dateTypeCompareValue === 'is_in_the_last') {
        if (!filterObj.dateTypeFilterValue) {
          filterObjErrorRef.dateTypeFilterError = 'This field is required';
          hasError = true;
        }

        if (!filterObj.dateValue) {
          filterObjErrorRef.dateValueError = 'This field is required';
          hasError = true;
        }
      }

      if (filterObj.dateTypeCompareValue === 'is_between') {
        if (!filterObj.dateRangeValue) {
          filterObjErrorRef.dateRangeError = 'This field is required';
          hasError = true;
        }
      }

      if (
        filterObj.dateTypeCompareValue === 'is_equal_to' ||
        filterObj.dateTypeCompareValue === 'is_after' ||
        filterObj.dateTypeCompareValue === 'is_before'
      ) {
        if (!filterObj.dateSingleValue) {
          filterObjErrorRef.dateSingleError = 'This field is required';
          hasError = true;
        }
      }

      if (filterObj.dateTypeCompareValue === 'is_in_the_last' && !filterObjErrorRef.dateValueError) {
        if (filterObj.dateTypeFilterValue === 'days' && parseInt(filterObj.dateValue) > 90) {
          filterObjErrorRef.dateValueError = 'Maximum 90 days allowed';
          hasError = true;
        }
        if (filterObj.dateTypeFilterValue === 'month' && parseInt(filterObj.dateValue) > 3) {
          filterObjErrorRef.dateValueError = 'Maximum 3 months allowed';
          hasError = true;
        }
      }

      if (
        filterObj.dateTypeCompareValue === 'is_between' &&
        Array.isArray(filterObj.dateRangeValue) &&
        !filterObjErrorRef.dateValueError
      ) {
        const fromDateObj = new Date(filterObj.dateRangeValue[0]);
        const toDateObj = new Date(filterObj.dateRangeValue[1]);

        const fromDateLuxonObj = DateTime.fromISO(fromDateObj.toISOString()).toUTC();
        const toDateLuxonObj = DateTime.fromISO(toDateObj.toISOString()).toUTC();

        const diff = toDateLuxonObj.diff(fromDateLuxonObj, ['years', 'months', 'days', 'hours']);

        if (diff.months > 3 && diff.days > 0) {
          filterObjErrorRef.dateValueError = 'Maximum 90 days allowed';
          hasError = true;
        }
      }

      totalFiltersApplied++;
    }

    if (filterObj.amountChecked) {
      if (!filterObj.amount) {
        filterObjErrorRef.amountError = 'This field is required';
        hasError = true;
      }

      totalFiltersApplied++;
    }

    if (filterObj.txnIdChecked) {
      if (!filterObj.txnId) {
        filterObjErrorRef.txnIdError = 'This field is required';
        hasError = true;
      } else {
        if (!(await isValidObjectId(filterObj.txnId, 'transaction', apiBaseUri))) {
          filterObjErrorRef.txnIdError = 'Invalid Transaction ID';
          hasError = true;
        }
      }

      totalFiltersApplied++;
    }

    if (filterObj.entryTypeFilterChecked) {
      if (!filterObj.entryType) {
        filterObjErrorRef.entryTypeError = 'This field is required';
        hasError = true;
      }

      totalFiltersApplied++;
    }

    if (filterObj.merchantIdChecked) {
      if (!filterObj.merchantId) {
        filterObjErrorRef.merchantIdError = 'This field is required';
        hasError = true;
      } else {
        if (!(await isValidObjectId(filterObj.merchantId, 'merchant', apiBaseUri))) {
          filterObjErrorRef.merchantIdError = 'Invalid Merchant ID';
          hasError = true;
        }
      }
      totalFiltersApplied++;
    }

    if (filterObj.payoutIdChecked) {
      if (!filterObj.payoutId) {
        filterObjErrorRef.payoutIdError = 'This field is required';
        hasError = true;
      } else {
        if (!(await isValidObjectId(filterObj.payoutId, 'payout', apiBaseUri))) {
          filterObjErrorRef.payoutIdError = 'Invalid Payout ID';
          hasError = true;
        }
      }
      totalFiltersApplied++;
    }

    if (hasError) {
      setFilterObjError(filterObjErrorRef);
      return;
    }

    filterObj.totalFiltersApplied = totalFiltersApplied;
    filterObj.saveTiggerAt = DateTime.now().toISO();

    if (
      txnFilterObj.txnId !== filterObj.txnId ||
      txnFilterObj.amount !== filterObj.amount ||
      txnFilterObj.entryType !== filterObj.entryType ||
      txnFilterObj.merchantId !== filterObj.merchantId ||
      txnFilterObj.amount !== filterObj.amount ||
      txnFilterObj.dateSingleValue !== filterObj.dateSingleValue ||
      txnFilterObj.dateRangeValue !== filterObj.dateRangeValue ||
      txnFilterObj.dateValue !== filterObj.dateValue
    ) {
      dispatch(setTxnFilterObj(filterObj));
      dispatch(
        setListingPagePropsObj({
          ...listingPageProps,
          activePage: 1,
        }),
      );
    }
    modalToggler();
  };

  const clearFunction = () => {
    setFilterObj(getInitialFilterState());
  };

  const dateRangeChangeHandler = useCallback((value: string | Date | Array<Date>) => {
    setFilterObj((prevState) => ({
      ...prevState,
      dateRangeValue: value,
    }));
  }, []);

  const dateChangeHandler = useCallback((value: Date | null | undefined | [Date | null, Date | null]) => {
    setFilterObj((prevState) => ({
      ...prevState,
      dateSingleValue: value,
    }));
  }, []);

  useEffect(() => {
    if (!filterObj.entryTypeFilterChecked && filterObj.entryType) {
      filterChangeHandler('entryType', '');
    }
  }, [filterObj.entryTypeFilterChecked, filterObj.entryType, filterChangeHandler]);

  useEffect(() => {
    if (!filterObj.txnIdChecked && filterObj.txnId) {
      filterChangeHandler('txnId', '');
    }
  }, [filterObj.txnIdChecked, filterObj.txnId, filterChangeHandler]);

  useEffect(() => {
    if (!filterObj.payoutIdChecked && filterObj.payoutId) {
      filterChangeHandler('payoutId', '');
    }
  }, [filterObj.payoutIdChecked, filterObj.payoutId, filterChangeHandler]);

  useEffect(() => {
    if (!filterObj.merchantIdChecked && filterObj.merchantId) {
      filterChangeHandler('merchantId', '');
    }
  }, [filterObj.merchantIdChecked, filterObj.merchantId, filterChangeHandler]);

  useEffect(() => {
    if (!filterObj.dateActiveChecked) {
      filterChangeHandler('dateTypeCompareValue', '');
      filterChangeHandler('dateTypeFilterValue', '');
      filterChangeHandler('dateValue', '');
      dateRangeChangeHandler('');
      dateChangeHandler(null);
    }
  }, [filterObj.dateActiveChecked, dateRangeChangeHandler, dateChangeHandler, filterChangeHandler]);

  useEffect(() => {
    if (!filterObj.amountChecked && filterObj.amount) {
      filterChangeHandler('amount', '');
    }
  }, [filterObj.amountChecked, filterObj.amount, filterChangeHandler]);

  return (
    <ClickAwayListener onClickAway={() => modalToggler()}>
      <div className={`filter-dropdown ${filterObj.dateTypeCompareValue === `is_between` ? `w-449` : `w-328`}`}>
        <div className="lp-flex lp-flex-column">
          <div className="filter-header">
            <Heading alignment="left" tagName="h2" variant="xs" className="mb-0">
              Filters
            </Heading>
            <Button
              type="button"
              onClick={() => modalToggler()}
              size="small"
              variant="ghost"
              className="button-close m-0 p-0"
            >
              <Icon name="Cross" className="m-0 p-0" />
            </Button>
          </div>
          <div className="filter-body">
            <CheckboxGroup>
              <div className="checkbox-items">
                <Checkbox
                  id="dateActiveChecked"
                  testId="payment-date-check"
                  label="Date created"
                  variant="body-2"
                  checked={filterObj.dateActiveChecked}
                  onChange={() => filterChangeHandler('dateActiveChecked', !filterObj.dateActiveChecked)}
                />
                {filterObj.dateActiveChecked && (
                  <div className="checkbox-items-details">
                    <div className="lp-w-full mb-16">
                      <div className="selectdropdown">
                        <Icon name="ChevronDown" className="arrow-down" />
                        <select
                          className="form-control"
                          data-testid="transaction-filter-date-type"
                          value={filterObj.dateTypeCompareValue}
                          onChange={(e) => filterChangeHandler('dateTypeCompareValue', e.target.value)}
                        >
                          <option value="">please select</option>
                          <option value="is_in_the_last">is in the last</option>
                          <option value="is_equal_to">is equal to</option>
                          <option value="is_between">is between</option>
                          <option value="is_after">is after</option>
                          <option value="is_before">is before</option>
                        </select>
                      </div>
                      {filterObjError.dateTypeCompareError && (
                        <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                          {filterObjError.dateTypeCompareError}
                        </Text>
                      )}
                    </div>

                    {filterObj.dateTypeCompareValue ? (
                      <Fragment>
                        {filterObj.dateTypeCompareValue === 'is_in_the_last' && (
                          <Fragment>
                            <div className="lp-w-full lp-flex lp-align-center mb-0">
                              <div className="w-24">
                                <Icon name="Return" className="text-primary" />
                              </div>
                              <div className="w-40">
                                <input
                                  type="text"
                                  data-testid="transaction-filter-date-value"
                                  className="form-control"
                                  value={filterObj.dateValue}
                                  onChange={(e) =>
                                    filterChangeHandler('dateValue', e.target.value.replace(/[^0-9]/g, ''))
                                  }
                                />
                              </div>
                              <div className="selectdropdown">
                                <Icon name="ChevronDown" className="arrow-down" />
                                <select
                                  className="form-control"
                                  data-testid="transaction-filter-date-type-filter"
                                  value={filterObj.dateTypeFilterValue}
                                  onChange={(e) => filterChangeHandler('dateTypeFilterValue', e.target.value)}
                                >
                                  <option value="">please select</option>
                                  <option value="days">days</option>
                                  <option value="month">months</option>
                                </select>
                              </div>
                            </div>

                            {(filterObjError.dateValueError || filterObjError.dateTypeFilterError) && (
                              <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                                {filterObjError.dateValueError
                                  ? filterObjError.dateValueError
                                  : filterObjError.dateTypeFilterError}
                              </Text>
                            )}
                          </Fragment>
                        )}

                        {filterObj.dateTypeCompareValue !== 'is_in_the_last' && (
                          <Fragment>
                            <div className="lp-w-full lp-flex lp-align-center mb-0">
                              <div className="w-24">
                                <Icon name="Return" className="text-primary" />
                              </div>
                              <DateRangeFilterComponent
                                isSingleDateFeild={filterObj.dateTypeCompareValue !== 'is_between' ? true : false}
                                dateRangeChangeHandler={dateRangeChangeHandler}
                                dateChangeHandler={dateChangeHandler}
                                dateRangeValue={filterObj.dateRangeValue}
                                dateSingleValue={filterObj.dateSingleValue}
                              />
                            </div>

                            {(filterObjError.dateRangeError || filterObjError.dateSingleError) && (
                              <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                                {filterObjError.dateRangeError
                                  ? filterObjError.dateRangeError
                                  : filterObjError.dateSingleError}
                              </Text>
                            )}
                          </Fragment>
                        )}
                      </Fragment>
                    ) : null}
                  </div>
                )}
              </div>
              <div className="checkbox-items">
                <Checkbox
                  id="entryTypeFilterChecked"
                  testId="payment-entry-type-check"
                  label="Transaction Type"
                  variant="body-2"
                  checked={filterObj.entryTypeFilterChecked}
                  onChange={() => filterChangeHandler('entryTypeFilterChecked', !filterObj.entryTypeFilterChecked)}
                />
                {filterObj.entryTypeFilterChecked && (
                  <div className="checkbox-items-details">
                    <div className="lp-w-full mb-0">
                      <div className="selectdropdown">
                        <Icon name="ChevronDown" className="arrow-down" />
                        <select
                          className="form-control"
                          data-testid="transaction-filter-entry-type"
                          value={filterObj.entryType}
                          onChange={(e) => filterChangeHandler('entryType', e.target.value)}
                        >
                          <option value="">please select</option>
                          {Object.values(TxnFilterEntryTypeObj).map((obj, key) => (
                            <option value={obj} key={key}>
                              {obj.split(/(?=[A-Z])/).join(' ')}
                            </option>
                          ))}
                        </select>
                      </div>
                      {filterObjError.entryTypeError && (
                        <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                          {filterObjError.entryTypeError}
                        </Text>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className="checkbox-items">
                <Checkbox
                  id="amountChecked"
                  testId="payment-amount-check"
                  label="Amount"
                  variant="body-2"
                  checked={filterObj.amountChecked}
                  onChange={() => filterChangeHandler('amountChecked', !filterObj.amountChecked)}
                />
                {filterObj.amountChecked && (
                  <div className="checkbox-items-details">
                    <div className="lp-w-full filter-search position-relative mb-0">
                      <input
                        type="text"
                        data-testid="transaction-filter-amount"
                        className="form-control"
                        placeholder="Enter amount"
                        value={filterObj.amount}
                        onChange={(e) => filterChangeHandler('amount', e.target.value)}
                      />
                      {/* <Button
                        type="button"
                        onClick={() => filterSaveHandler()}
                        size="small"
                        variant="ghost"
                        className="button-search"
                      >
                        <Icon name="Search" className="m-0 p-0 text-primary" />
                      </Button> */}
                    </div>
                    {filterObjError.amountError && (
                      <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                        {filterObjError.amountError}
                      </Text>
                    )}
                  </div>
                )}
              </div>

              <div className="checkbox-items">
                <Checkbox
                  id="merchantIdChecked"
                  testId="payment-merchant-id-check"
                  label="Merchant ID"
                  variant="body-2"
                  checked={filterObj.merchantIdChecked}
                  onChange={() => filterChangeHandler('merchantIdChecked', !filterObj.merchantIdChecked)}
                />
                {filterObj.merchantIdChecked && (
                  <div className="checkbox-items-details">
                    <div className="lp-w-full filter-search position-relative mb-0">
                      <input
                        type="text"
                        data-testid="transaction-filter-merchant-id"
                        className="form-control"
                        placeholder="Enter Merchant ID"
                        value={filterObj.merchantId}
                        onChange={(e) => filterChangeHandler('merchantId', e.target.value)}
                      />
                      {/* <Button
                        type="button"
                        onClick={() => filterSaveHandler()}
                        size="small"
                        variant="ghost"
                        className="button-search"
                      >
                        <Icon name="Search" className="m-0 p-0 text-primary" />
                      </Button> */}
                    </div>
                    {filterObjError.merchantIdError && (
                      <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                        {filterObjError.merchantIdError}
                      </Text>
                    )}
                  </div>
                )}
              </div>

              <div className="checkbox-items">
                <Checkbox
                  id="payoutIdChecked"
                  testId="payment-payout-id-check"
                  label="Payout ID"
                  variant="body-2"
                  checked={filterObj.payoutIdChecked}
                  onChange={() => filterChangeHandler('payoutIdChecked', !filterObj.payoutIdChecked)}
                />
                {filterObj.payoutIdChecked && (
                  <div className="checkbox-items-details">
                    <div className="lp-w-full filter-search position-relative mb-0">
                      <input
                        type="text"
                        data-testid="transaction-filter-payout-id"
                        className="form-control"
                        placeholder="Enter Payout ID"
                        value={filterObj.payoutId}
                        onChange={(e) => filterChangeHandler('payoutId', e.target.value)}
                      />
                      {/* <Button
                        type="button"
                        onClick={() => filterSaveHandler()}
                        size="small"
                        variant="ghost"
                        className="button-search"
                      >
                        <Icon name="Search" className="m-0 p-0 text-primary" />
                      </Button> */}
                    </div>
                    {filterObjError.payoutIdError && (
                      <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                        {filterObjError.payoutIdError}
                      </Text>
                    )}
                  </div>
                )}
              </div>

              <div className="checkbox-items">
                <Checkbox
                  id="txnIdChecked"
                  testId="payment-txn-id-check"
                  label="Transaction ID"
                  variant="body-2"
                  checked={filterObj.txnIdChecked}
                  onChange={() => filterChangeHandler('txnIdChecked', !filterObj.txnIdChecked)}
                />
                {filterObj.txnIdChecked && (
                  <div className="checkbox-items-details">
                    <div className="lp-w-full filter-search position-relative mb-0">
                      <input
                        type="text"
                        data-testid="transaction-filter-txn-id"
                        className="form-control"
                        placeholder="Enter Transaction ID"
                        value={filterObj.txnId}
                        onChange={(e) => filterChangeHandler('txnId', e.target.value)}
                      />
                      {/* <Button
                        type="button"
                        onClick={() => filterSaveHandler()}
                        size="small"
                        variant="ghost"
                        className="button-search"
                      >
                        <Icon name="Search" className="m-0 p-0 text-primary" />
                      </Button> */}
                    </div>
                    {filterObjError.txnIdError && (
                      <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                        {filterObjError.txnIdError}
                      </Text>
                    )}
                  </div>
                )}
              </div>
            </CheckboxGroup>
          </div>
          <div className="filter-footer">
            <Button
              className="lp-w-full"
              onClick={() => clearFunction()}
              size="medium"
              variant="reversed"
              testId="transaction-filter-clear-button"
              disabled={
                filterObj.dateActiveChecked ||
                filterObj.txnIdChecked ||
                filterObj.merchantIdChecked ||
                filterObj.amountChecked ||
                filterObj.payoutIdChecked ||
                filterObj.entryTypeFilterChecked
                  ? false
                  : true
              }
            >
              Clear
            </Button>
            <Button
              className="lp-w-full"
              onClick={() => filterSaveHandler()}
              size="medium"
              variant="primary"
              testId="transaction-filter-save-button"
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </ClickAwayListener>
  );
}

export default FilterComponent;
