import * as s from 'assets/styles/Onboarding.styles';
import { useAppDispatch } from 'redux/merchant/hooks';
import { setSaveAndFinishLater } from 'redux/merchant/slice/merchantOnboardSlice';

import { Button, Icon, Stepper } from '@limepayments/cosmic';

export interface SidebarPros {
  activeStepNumber: number;
}

function Sidebar({ activeStepNumber }: SidebarPros) {
  const dispatch = useAppDispatch();
  return (
    <>
      <s.SidebarResponsive>
        <Stepper
          variant="minimal"
          activeStepNumber={activeStepNumber}
          steps={[
            'Business registration details',
            'Branding details',
            'Business contact details',
            'Business bank account details',
            'Business owners and directors',
            'Review and submit',
          ]}
        />
      </s.SidebarResponsive>
      <div className="sidebar-body">
        <div className="sidebar-body-content">
          <div className="sidebar-body-content-scroll">
            <Stepper
              variant="vertical"
              activeStepNumber={activeStepNumber}
              steps={[
                'Business registration details',
                'Branding details',
                'Business contact details',
                'Business bank account details',
                'Business owners and directors',
                'Review and submit',
              ]}
            />
          </div>
        </div>
      </div>
      <div className="sidebar-footer">
        <Button
          type="button"
          size="medium"
          onClick={() => {
            dispatch(setSaveAndFinishLater(Date.now()));
          }}
          variant="ghost"
          className="lp-w-full"
        >
          <Icon name="Save" />
          Save and finish later
        </Button>
      </div>
    </>
  );
}

export default Sidebar;
