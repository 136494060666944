import '@limepayments/cosmic/build/css/reset.css';
import '@limepayments/cosmic/build/css/styles.css';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';

import { ApplicationAreaOption } from 'config/constants';
import ErrorPage from 'pages/common/error';
import * as ReactDOMClient from 'react-dom/client';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import getApplicationArea from 'utils/getApplicationArea';

import MerchantApp from './MerchantApp';
import PlatformApp from './PlatformApp';
import MerchantStore from './redux/merchant/store';
import PlatformStore from './redux/platform/store';
import reportWebVitals from './reportWebVitals';

const applicationArea = getApplicationArea();

const container = document.getElementById('root') as HTMLElement;

ReactDOMClient.createRoot(container).render(
  applicationArea === ApplicationAreaOption.merchant ? (
    <Provider store={MerchantStore}>
      <MerchantApp />
      <ToastContainer />
    </Provider>
  ) : applicationArea === ApplicationAreaOption.platform ? (
    <Provider store={PlatformStore}>
      <PlatformApp />
      <ToastContainer />
    </Provider>
  ) : (
    <ErrorPage bodyTitle="Invalid Route" bodyText="The page you are trying to access doesn't exists" />
  ),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
