import { merchantPaymentCapture } from 'api/merchant/dashboard/payments';
import * as s from 'assets/styles/Platforms.styles';
import { Formik, FormikProps } from 'formik';
import { Fragment, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppSelector } from 'redux/merchant/hooks';
import { Currency, fromCurrency, toCurrency } from 'utils/currency';
import getErrorMessage from 'utils/getErrorMessage';
import * as Yup from 'yup';

import {
  Button,
  Heading,
  Illustration,
  Modal,
  RadioGroup,
  Spinner,
  Stack,
  Text,
  TextField,
} from '@limepayments/cosmic';

import { CapturePaymentPayload } from '../types';

const getInitailValues = () => {
  return {
    captureType: '',
    partialCaptureAmount: '',
  };
};

const validationSchema = Yup.object().shape({
  captureType: Yup.string().required('This field is required'),
  partialCaptureAmount: Yup.string().when('captureType', {
    is: 'partial_capture',
    then: Yup.string().required('This field is required'),
  }),
});

interface Props {
  isOpen: boolean;
  modalToggler: (isOpen: boolean) => void;
  transactionAmount?: number;
  capturedAmount?: number;
  transactionId: string;
  currency: Currency;
  customerName: string;
}

function CapturePaymentModalComponent({
  isOpen,
  modalToggler,
  capturedAmount,
  transactionAmount,
  transactionId,
  currency,
  customerName,
}: Props) {
  const [activeStep, setActiveStep] = useState<number>(1);
  const [captureRequestLoader, setCaptureRequestLoader] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<CapturePaymentPayload>(getInitailValues());
  const [errorMessage, setErrorMessage] = useState<string>('');
  const formRef = useRef<FormikProps<CapturePaymentPayload>>(null);
  const errorContainerRef = useRef<HTMLDivElement>(null);

  const { merchantId, apiBaseUri } = useAppSelector((state) => ({
    merchantId: state.config.merchantId,
    apiBaseUri: state.config.apiBaseUri,
  }));

  const getFinalCaptureAmount = (captureType: string, partialCaptureAmount: string) => {
    let amount = 0;
    if (captureType === 'full_capture') {
      amount = (transactionAmount || 0) - (capturedAmount || 0);
    } else {
      amount = fromCurrency(partialCaptureAmount);
    }
    return amount;
  };

  const submitHandler = async (values: CapturePaymentPayload, formikObj: { resetForm: () => void }) => {
    try {
      if (activeStep === 1) {
        setActiveStep(2);
        return;
      }

      setCaptureRequestLoader(true);
      setActiveStep(3);

      let amount = 0;
      if (values.captureType === 'full_capture') {
        amount = (transactionAmount || 0) - (capturedAmount || 0);
      } else {
        amount = fromCurrency(values.partialCaptureAmount);
      }

      const postData = {
        captureAmount: amount,
      };

      await merchantPaymentCapture(apiBaseUri, merchantId, transactionId, postData);

      toast.success(`You have successfully captured ${amount} ${currency} of ${customerName}’s payment.`);

      setInitialValues(values);
      formikObj.resetForm();
      setActiveStep(2);
    } catch (err: any) {
      toast.error(getErrorMessage(err));
    } finally {
      setCaptureRequestLoader(false);
    }
  };

  useEffect(() => {
    if (errorMessage.length > 0 && errorContainerRef && errorContainerRef.current) {
      errorContainerRef.current.scrollIntoView();
    }
  }, [errorMessage]);

  useEffect(() => {
    setErrorMessage('');
    setActiveStep(1);
    setCaptureRequestLoader(false);
    setInitialValues(getInitailValues());
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} className="modal-medium update-pass-modal capture-payment-modal">
      <s.Wrapper className="">
        <div className="modal-main p-24">
          <div className="modal-header">
            <div className="content-main bb-1">
              <Heading alignment="center" tagName="h2" variant="xxs" className="mt-12 mb-24 lp-justify-center lp-flex">
                Capture payment
              </Heading>
            </div>
          </div>
          <Formik<CapturePaymentPayload>
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={(values, formObj: { resetForm: () => void }) => {
              submitHandler(values, formObj);
            }}
            validationSchema={validationSchema}
            innerRef={formRef}
          >
            {({ values, handleSubmit, errors, touched, setFieldValue, handleChange, handleBlur }) => (
              <form autoComplete="off" onSubmit={handleSubmit}>
                <div className="modal-content p-28 sm-p-0 sm-mt-24">
                  <div className="">
                    {activeStep === 1 && (
                      <Fragment>
                        {/* Step 01 */}
                        <div className="lp-w-full lp-flex-row mb-16">
                          <div className="lp-w-full lp-flex lp-align-center lp-flex-row mb-0 sm-lp-align-start">
                            <div className="lp-w-full lp-flex lp-flex-column">
                              <Text
                                alignment="left"
                                tagName="p"
                                variant="body-2"
                                isEmphasised
                                className="text-dark-50 mb-0"
                              >
                                Capture payment authorised by {customerName}
                              </Text>
                            </div>
                          </div>
                          <div className="lp-w-full lp-flex lp-align-center lp-flex-row mb-0 sm-lp-align-start">
                            <Text variant="caption" className="text-dark-50 mb-0 mt-4">
                              Fully or partially capture payment of{' '}
                              <strong>
                                {toCurrency((transactionAmount || 0) - (capturedAmount || 0), currency)} {currency}.
                              </strong>
                            </Text>
                          </div>
                        </div>
                        <div className="lp-w-full lp-equal-width mb-24">
                          <div className="w-180">
                            <RadioGroup
                              defaultValue={values.captureType}
                              name="captureType"
                              onChange={(value) => setFieldValue('captureType', value)}
                              radios={[
                                {
                                  text: 'Full capture',
                                  value: 'full_capture',
                                },
                                {
                                  text: 'Partially capture',
                                  value: 'partial_capture',
                                },
                              ]}
                              errorMessage={errors.captureType && touched.captureType ? errors.captureType : ''}
                            />
                          </div>
                        </div>

                        {values.captureType === 'partial_capture' && (
                          <div className="lp-w-full lp-flex mb-24">
                            <div className="lp-equal-width">
                              <TextField
                                containerId="standard"
                                startAdornment="Filled-Dollar"
                                label="Capture amount"
                                id="partialCaptureAmount"
                                name="partialCaptureAmount"
                                value={values.partialCaptureAmount}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  errors.partialCaptureAmount && touched.partialCaptureAmount
                                    ? errors.partialCaptureAmount
                                    : ``
                                }
                              />
                              {/* The amount has to be lower than the original payment. */}
                            </div>
                            <div className="lp-equal-width">
                              <Text
                                alignment="left"
                                tagName="p"
                                variant="body-1"
                                isEmphasised
                                className="currency mb-0"
                              >
                                {currency}
                              </Text>
                            </div>
                          </div>
                        )}
                      </Fragment>
                    )}

                    {activeStep === 2 && (
                      <Fragment>
                        {/* Step 02 */}
                        <div className="lp-w-full lp-flex mb-24">
                          <div className="lp-w-full lp-flex lp-flex-column lp-align-center lp-justify-center">
                            <div className="illustration-90">
                              <Illustration name="Warning" size="medium" />
                            </div>
                            <div className="w-75">
                              <Text alignment="center" tagName="p" variant="body-3" className="text-dark-50 mb-0">
                                Are you sure you want to capture this payment of{' '}
                                <strong>
                                  {getFinalCaptureAmount(values.captureType, values.partialCaptureAmount)} {currency}
                                </strong>{' '}
                                for <strong>{customerName}</strong>?
                              </Text>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    )}

                    {activeStep === 3 && (
                      <Fragment>
                        {/* Step 03 */}
                        <div className="lp-w-full lp-flex lp-align-center lp-justify-center mb-0">
                          <div className="modal-body-loader">
                            <Stack spacing="md" className="mb-16">
                              <Spinner isVisible variant="simple" />
                            </Stack>
                            <Text alignment="center" tagName="p" variant="body-3" className="text-dark-50 mb-0">
                              Capturing{' '}
                              <strong>
                                {getFinalCaptureAmount(values.captureType, values.partialCaptureAmount)} {currency}
                              </strong>{' '}
                              for <strong>{customerName}</strong>...
                            </Text>
                          </div>
                        </div>
                      </Fragment>
                    )}
                  </div>
                </div>
                <div className="modal-footer lp-flex lp-justify-end sm-mt-24">
                  <Button
                    onClick={() => modalToggler(false)}
                    size="medium"
                    variant="ghost"
                    className="no-min-width"
                    disabled={captureRequestLoader ? true : false}
                  >
                    Cancel
                  </Button>
                  {activeStep === 1 && (
                    <Button type="submit" size="medium" variant="primary" className="no-min-width">
                      Capture
                    </Button>
                  )}

                  {activeStep !== 1 && (
                    <Button
                      type="submit"
                      size="medium"
                      variant="destructive"
                      className="no-min-width"
                      disabled={captureRequestLoader ? true : false}
                    >
                      Capture
                    </Button>
                  )}
                </div>
              </form>
            )}
          </Formik>
        </div>
      </s.Wrapper>
    </Modal>
  );
}

export default CapturePaymentModalComponent;
