import { TagProps } from '@limepayments/cosmic/build/Tag/Tag';

interface IPayoutStatusObj {
  [status: string]: {
    label: string;
    varient: TagProps['variant'];
    tooltipText: string;
  };
}

export const PayoutStatusObj: IPayoutStatusObj = {
  paid: {
    label: 'Paid',
    varient: 'success',
    tooltipText: 'This payout has been paid.',
  },
  pending: {
    label: 'Pending',
    varient: 'positive',
    tooltipText: 'This payout is still pending.',
  },
  in_transit: {
    label: 'In transit',
    varient: 'positive',
    tooltipText: 'This payout is in transit.',
  },
  canceled: {
    label: 'Canceled',
    varient: 'neutral',
    tooltipText: 'This payout has been canceled.',
  },
  failed: {
    label: 'Failed',
    varient: 'negative',
    tooltipText: 'This payout has failed.',
  },
  reversed: {
    label: 'Reversed',
    varient: 'neutral',
    tooltipText: 'This payout has been reversed.',
  },
  suspended: {
    label: 'Suspended',
    varient: 'warning',
    tooltipText: 'This payout has been suspended.',
  },
};

export const PayoutRoutesList = {
  PAYOUT_LANDING: 'all',
  PAYOUT_BREAKDOWN: 'all',
  PAYOUT_DETAILS: 'details',
};

export default PayoutRoutesList;
