import ErrorPage from 'pages/common/error';
import { Route, Routes } from 'react-router-dom';

import APIKeysPage from './api-keys';
import BrandingSettingPage from './branding';
import BusinessProfileSettingPage from './business';
import RoutesList from './constants';
// import FeeStructurePage from './fee-structure';
// import FraudPreventionPage from './fraud-prevention';
import ProfilePage from './my-profile';
import PayByLinkPage from './pay-by-link';
import PaymentSettingPage from './payments';
import PayoutSettingPage from './payouts';

const SettingRoutes = () => {
  return (
    <Routes>
      <Route path={RoutesList.SETTINGS_PROFILE} caseSensitive={true} element={<ProfilePage />} />
      <Route
        path={`${RoutesList.SETTINGS_BUSINESS_PROFILE}`}
        caseSensitive={true}
        element={<BusinessProfileSettingPage />}
      />
      <Route path={`${RoutesList.SETTINGS_BRANDING}`} caseSensitive={true} element={<BrandingSettingPage />} />
      <Route path={`${RoutesList.SETTINGS_PAYMENT}`} caseSensitive={true} element={<PaymentSettingPage />} />
      <Route path={`${RoutesList.SETTINGS_BALANCES}`} caseSensitive={true} element={<PayoutSettingPage />} />
      {/* <Route path={`${RoutesList.SETTINGS_FRAUD_PREVENTIONS}`} caseSensitive={true} element={<FraudPreventionPage />} />
      <Route path={`${RoutesList.SETTINGS_FEE_STRUCTURE}`} caseSensitive={true} element={<FeeStructurePage />} /> */}
      <Route path={`${RoutesList.SETTINGS_PAY_BY_LINK}`} caseSensitive={true} element={<PayByLinkPage />} />
      <Route path={`${RoutesList.SETTINGS_API_KEYS}`} caseSensitive={true} element={<APIKeysPage />} />
      <Route
        path="*"
        element={<ErrorPage bodyTitle="Page Not Found" bodyText="The page you are trying to access doesn't exists" />}
      />
    </Routes>
  );
};

export default SettingRoutes;
