import { GetMerchantFileResponse } from 'api/merchant/onbording/file.types';
import { SupportedIndustryType } from 'api/merchant/onbording/step-01.types';
import * as s from 'assets/styles/Onboarding.styles';
import { Formik, FormikProps } from 'formik';
import { Fragment, RefObject, useEffect, useRef } from 'react';
import scrollToComponent from 'react-scroll-to-component';
import validateAbn from 'utils/validateAbn';
import validateNzbn from 'utils/validateNzbn';
import * as Yup from 'yup';

import { Button, Heading, Icon, Message, RadioGroup, Select, Text, TextField } from '@limepayments/cosmic';

import { UpdateMerchantBusinessRegistrationPayload } from './types';

const validationSchema = Yup.object().shape({
  businessName: Yup.string().trim().required(`Please enter a valid legal entity name`),
  businessType: Yup.string().trim().required('Please select a business structure'),
  companyType: Yup.string().when('businessType', {
    is: 'company',
    then: Yup.string().required('Please select a company type'),
  }),
  taxId: Yup.string()
    .trim()
    .required('Please enter a valid business registration number')
    .test({
      name: 'validate-bn',
      test: function () {
        const { merchantTaxCountry, taxId } = this.parent;

        if (taxId) {
          if (merchantTaxCountry === 'AU' && !validateAbn(taxId))
            return this.createError({
              message: `Please enter a valid business registration number`,
              path: `taxId`,
            });
          if (merchantTaxCountry === 'NZ' && !validateNzbn(taxId))
            return this.createError({
              message: `Please enter a valid business registration number`,
              path: `taxId`,
            });
        }

        return true;
      },
    }),
  fileUpload: Yup.mixed().when('businessType', {
    is: (businessType: string) => businessType === 'partnership' || businessType === 'trust',
    then: Yup.mixed().when('updateItem', {
      is: !true,
      then: Yup.mixed()
        .required('Please select a file to continue')
        .test({
          message: 'Please provide a supported file type',
          test: (file, context) => {
            if (!file) {
              return true;
            }
            const fileExtension = file ? file.type.split('/')[1] : '';
            const isValid = ['jpeg', 'jpg', 'png', 'pdf'].includes(fileExtension.toLowerCase());
            return isValid;
          },
        })
        .test({
          message: `File too big, can't exceed 10MB limit`,
          test: (file) => {
            if (!file) {
              return true;
            }
            const isValid = file ? file.size < 10 * 1024 * 1024 : false;
            return isValid;
          },
        }),
    }),
  }),
  industry: Yup.string().nullable().trim().required('Please select an industry'),
});

interface Props {
  onSubmitClick: (values: UpdateMerchantBusinessRegistrationPayload, formObj: { resetForm: () => void }) => void;
  onPreviousClick: () => void;
  initialValues: UpdateMerchantBusinessRegistrationPayload;
  requestLoader: boolean;
  errorMsg: string;
  supportIndustryList: Array<SupportedIndustryType>;
  formRef: RefObject<FormikProps<UpdateMerchantBusinessRegistrationPayload>>;
  fileUploading: boolean;
  trustFileDetail: GetMerchantFileResponse | null;
  partnershipFileDetail: GetMerchantFileResponse | null;
  fileAmendementDetail: Array<GetMerchantFileResponse> | null;
  selectedFile: File | null;
  selectedAmendementFile: Array<File> | null;
  fileChangeHandler: (file: FileList | null) => void;
  agreementRemoveChangeHandler: (trustFileRemove: boolean) => void;
  amendementFileChangeHandler: (file: FileList | null) => void;
  amendementFileRemoveHandler: (index: number) => void;
  amendementUploadedFileRemoveHandler: (fileId: string) => void;
  saveAndFinishLater: () => void;
  saveAndReview: boolean;
}

function Step01View({
  onSubmitClick,
  onPreviousClick,
  initialValues,
  requestLoader,
  errorMsg,
  supportIndustryList,
  formRef,
  fileUploading,
  trustFileDetail,
  partnershipFileDetail,
  selectedFile,
  fileChangeHandler,
  saveAndFinishLater,
  saveAndReview,
  fileAmendementDetail,
  selectedAmendementFile,
  amendementFileChangeHandler,
  amendementFileRemoveHandler,
  amendementUploadedFileRemoveHandler,
  agreementRemoveChangeHandler,
}: Props) {
  const errorContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (errorMsg.length > 0 && errorContainerRef && errorContainerRef.current) {
      scrollToComponent(errorContainerRef.current, { duration: 500 });
    }
  }, [errorMsg]);

  return (
    <>
      <div className="content-wrap-inner">
        <header className="content-header">
          <div className="lp-w-full mb-24">
            <div className="lp-w-full mb-16">
              <Button
                size="small"
                variant="ghost"
                isNeutral
                className="back-button"
                type="button"
                onClick={onPreviousClick}
              >
                <Icon name="ArrowLeft" className="back-arrow" />
                Back
              </Button>
            </div>
            <Heading alignment="left" tagName="h2" variant="md" className="mb-24">
              Verify your business details
            </Heading>
            <Heading alignment="left" tagName="h4" variant="sm" className="mb-24">
              Business registration details
            </Heading>
            {errorMsg.length > 0 && (
              <div className="mt-24 mb-24" ref={errorContainerRef}>
                <Message type="inline" children={<span className="text-wrap">{errorMsg}</span>} variant={'error'} />
              </div>
            )}
            <Text tagName="p" variant="body-3">
              Provide the official details of your business.
            </Text>
          </div>
        </header>

        <Formik<UpdateMerchantBusinessRegistrationPayload>
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={(values, formObj: { resetForm: () => void }) => {
            onSubmitClick(values, formObj);
          }}
          validationSchema={validationSchema}
          innerRef={formRef}
        >
          {({ values, handleSubmit, errors, touched, setFieldValue, handleChange, handleBlur }) => (
            <form autoComplete="off" onSubmit={handleSubmit} className="content-wrap-form">
              <div className="content-main">
                <div className="lp-w-full mb-12">
                  <TextField
                    fullWidth
                    label="Business registration number"
                    inputMode="text"
                    autoComplete="off"
                    id="taxId"
                    name="taxId"
                    onBlur={handleBlur}
                    value={values.taxId}
                    onChange={handleChange}
                    error={errors.taxId && touched.taxId ? errors.taxId : ''}
                    helperText={
                      values.merchantTaxCountry === 'NZ'
                        ? "The business' or trustee's NZBN."
                        : "The business' or trustee's ABN or ACN."
                    }
                  />
                </div>
                <div className="lp-w-full mb-24">
                  <TextField
                    fullWidth
                    label="Legal entity name"
                    inputMode="text"
                    autoComplete="off"
                    id="businessName"
                    name="businessName"
                    onBlur={handleBlur}
                    value={values.businessName}
                    onChange={handleChange}
                    error={errors.businessName && touched.businessName ? errors.businessName : ''}
                    helperText="The entity name linked to the business registration number."
                  />
                </div>

                <div className="lp-w-full lp-equal-width mb-24">
                  <Text alignment="left" tagName="p" variant="body-1" isEmphasised className="mb-12">
                    Business structure
                  </Text>
                  <div className="lp-width-300">
                    <RadioGroup
                      name="businessType"
                      onChange={(value) => {
                        setFieldValue('businessType', value);
                        fileChangeHandler(null);
                        amendementFileChangeHandler(null);
                        setFieldValue('fileUpload', null);
                        setFieldValue('updateItem', value === initialValues.businessType ? true : false);
                      }}
                      defaultValue={values.businessType}
                      orientation="vertical"
                      radios={[
                        {
                          text: 'Sole trader (ex. Sean Fisher)',
                          value: 'soletrader',
                        },
                        {
                          text: 'Company (ex. April PTY LTD)',
                          value: 'company',
                        },
                        {
                          text: 'Partnership (ex. S Fisher & J Smith)',
                          value: 'partnership',
                        },
                        {
                          text: 'Trust (ex. Fisher Family Trust)',
                          value: 'trust',
                        },
                      ]}
                      errorMessage={errors.businessType && touched.businessType ? errors.businessType : ''}
                    />
                  </div>
                </div>

                {values.businessType === 'company' && (
                  <div className="lp-w-full lp-equal-width mb-24">
                    <Text alignment="left" tagName="p" variant="body-1" isEmphasised className="mb-12">
                      Company type
                    </Text>
                    <div className="lp-width-300">
                      <RadioGroup
                        name="companyType"
                        onChange={(value) => setFieldValue('companyType', value)}
                        defaultValue={values.companyType}
                        orientation="vertical"
                        radios={[
                          {
                            text: 'Private (ex. April PTY LTD)',
                            value: 'Private',
                          },
                          {
                            text: 'Public (ex. April LTD)',
                            value: 'Public',
                          },
                        ]}
                        errorMessage={errors.companyType && touched.companyType ? errors.companyType : ''}
                      />
                    </div>
                  </div>
                )}

                {(values.businessType === 'trust' || values.businessType === 'partnership') && (
                  <Fragment>
                    <div className="lp-w-full" style={{ display: 'block' }}>
                      <div className="lp-w-full mb-24">
                        <Text alignment="left" variant="body-2" isEmphasised>
                          {values.businessType === 'trust' ? `Original trust deed` : `Verification documents`}
                        </Text>
                        <Text tagName="span" variant="caption" className="text-greyish lp-ls5">
                          {values.businessType === 'trust'
                            ? values.merchantTaxCountry === 'NZ'
                              ? `Upload the original document, alternatively a certified true copy.`
                              : `Upload the original trust deed for verification, certified true copies are also accepted.`
                            : `Upload the original document, alternatively a certified true copy.`}{' '}
                          Files must be in jpeg, png or pdf format and a maximum of 10 MB each.
                        </Text>
                      </div>
                    </div>
                    <div className={`lp-w-full mb-24`}>
                      {values.businessType === 'partnership' && partnershipFileDetail ? (
                        <s.DocumentBlock className="lp-flex lp-justify-between lp-items-center sm:flex-col sm:align-start mb-24">
                          <Text alignment="left" variant="caption" isEmphasised className="text-primary">
                            Uploaded Partnership agreement
                          </Text>
                          <div className="upload-preview lp-flex">
                            <Icon name="Attachment" />
                            <div>
                              <Text alignment="left" variant="caption" isEmphasised className="text-primary mb-4">
                                {partnershipFileDetail.fileName}
                              </Text>
                              <Text alignment="left" variant="legal" className="m-0">
                                {partnershipFileDetail.fileSizeInKB}kb
                              </Text>
                            </div>

                            <button
                              className="upload-delete"
                              type="button"
                              onClick={() => {
                                agreementRemoveChangeHandler(values.businessType === 'trust' ? true : false);
                                setFieldValue('updateItem', false);
                              }}
                            >
                              <Icon name="Cross" label="Remove" />
                            </button>
                          </div>
                        </s.DocumentBlock>
                      ) : values.businessType === 'trust' && trustFileDetail ? (
                        <s.DocumentBlock className="lp-flex lp-justify-between lp-items-center sm:flex-row mb-24">
                          <Text alignment="left" variant="caption" isEmphasised className="text-primary">
                            Uploaded Trust deed
                          </Text>
                          <div className="upload-preview lp-flex">
                            <Icon name="Attachment" />
                            <div>
                              <Text alignment="left" variant="caption" isEmphasised className="text-primary mb-4">
                                {trustFileDetail.fileName}
                              </Text>
                              <Text alignment="left" variant="legal" className="m-0">
                                {trustFileDetail.fileSizeInKB}kb
                              </Text>
                            </div>

                            <button
                              className="upload-delete"
                              type="button"
                              onClick={() => {
                                agreementRemoveChangeHandler(true);
                                setFieldValue('updateItem', false);
                              }}
                            >
                              <Icon name="Cross" label="Remove" />
                            </button>
                          </div>
                        </s.DocumentBlock>
                      ) : (
                        <s.DocumentBlock className="lp-flex lp-justify-between lp-items-center sm:flex-col sm:align-start">
                          <Text alignment="left" variant="caption" isEmphasised className="text-primary">
                            {values.businessType === 'trust' ? 'Trust deed' : 'Partnership agreement'}
                          </Text>
                          {selectedFile && (
                            <div className="upload-preview lp-flex">
                              <Icon name="Attachment" />
                              <div>
                                <Text alignment="left" variant="caption" isEmphasised className="text-primary mb-4">
                                  {selectedFile.name}
                                </Text>
                                <Text alignment="left" variant="legal" className="m-0">
                                  {parseInt((selectedFile.size / 1024).toString())}kb
                                </Text>
                              </div>
                              <button
                                className="upload-delete"
                                type="button"
                                onClick={() => {
                                  fileChangeHandler(null);
                                  setFieldValue('fileUpload', null);
                                }}
                              >
                                <Icon name="Cross" label="Remove" />
                              </button>
                            </div>
                          )}

                          {!selectedFile && (
                            <span
                              onClick={function noRefCheck() {}}
                              data-testid="stepFirstDoc"
                              className="lp-flex lp-justify-center lp-items-center no-min-width btn-file"
                            >
                              Select file{' '}
                              <input
                                type="file"
                                data-testid="fileUpload"
                                accept="image/png, image/jpeg, image/jpg, .pdf"
                                id="fileUpload"
                                name="fileUpload"
                                onChange={(e) => {
                                  fileChangeHandler(e.target.files);
                                  setFieldValue('fileUpload', e.target.files ? e.target.files[0] : {});
                                }}
                              />
                            </span>
                          )}
                        </s.DocumentBlock>
                      )}

                      {errors.fileUpload && touched.fileUpload && (
                        <span className="Mui-error">
                          Please upload a{' '}
                          {values.businessType === 'partnership' ? `partnership agreement` : `trust deed`}
                        </span>
                      )}
                    </div>

                    {values.businessType === 'trust' && values.merchantTaxCountry === 'AU' && (
                      <div className={`lp-w-full mb-24`}>
                        <div className="lp-w-full" style={{ display: 'block' }}>
                          <div className="lp-w-full mb-24">
                            <Text alignment="left" variant="body-2" isEmphasised>
                              Original trust amendments
                            </Text>
                            <Text tagName="span" variant="caption" className="text-greyish lp-ls5">
                              Upload the original amendments for verification, certified true copies are also accepted.
                              Files must be in jpeg, png or pdf format and a maximum of 10 MB each.
                            </Text>
                          </div>
                        </div>
                        {fileAmendementDetail && fileAmendementDetail.length > 0 && (
                          <s.DocumentBlock className="lp-flex lp-items-start lp-flex-column noStyle">
                            {fileAmendementDetail.map((obj) => (
                              <div className="upload-preview lp-flex">
                                <Icon name="Attachment" />
                                <div className="doc-name">
                                  <Text alignment="left" variant="caption" isEmphasised className="text-primary mb-4">
                                    {obj.fileName}
                                  </Text>
                                  <Text alignment="left" variant="legal" className="m-0">
                                    {obj.fileSizeInKB}kb
                                  </Text>
                                </div>
                                <button
                                  className="upload-delete"
                                  type="button"
                                  onClick={() => {
                                    amendementUploadedFileRemoveHandler(obj.fileId);
                                  }}
                                >
                                  <Icon name="Cross" label="Remove" />
                                </button>
                              </div>
                            ))}
                          </s.DocumentBlock>
                        )}

                        {selectedAmendementFile && selectedAmendementFile.length > 0 && (
                          <s.DocumentBlock className="lp-flex lp-items-start lp-flex-column noStyle">
                            {selectedAmendementFile.map((fileObj, key) => (
                              <div className="upload-preview lp-flex" key={key}>
                                <div className="lp-flex">
                                  <Icon name="Attachment" className="mr-12" />
                                  <div className="doc-name">
                                    <Text alignment="left" variant="caption" isEmphasised className="text-primary mb-4">
                                      {fileObj.name}
                                    </Text>
                                    <Text alignment="left" variant="legal" className="m-0">
                                      {parseInt((fileObj.size / 1024).toString())}kb
                                    </Text>
                                  </div>
                                </div>
                                <button
                                  className="upload-delete"
                                  type="button"
                                  onClick={() => {
                                    amendementFileRemoveHandler(key);
                                  }}
                                >
                                  <Icon name="Cross" label="Remove" />
                                </button>
                              </div>
                            ))}
                          </s.DocumentBlock>
                        )}

                        <s.DocumentBlock className={`lp-flex noStyle`}>
                          <span
                            onClick={function noRefCheck() {}}
                            data-testid="stepFirstTrustAmendement"
                            className="lp-flex lp-justify-center lp-items-center no-min-width btn-file"
                          >
                            Select files{' '}
                            <input
                              type="file"
                              data-testid="fileUpload"
                              accept="image/png, image/jpeg, image/jpg, .pdf"
                              multiple
                              id="fileUpload"
                              name="fileUpload"
                              onChange={(e) => {
                                amendementFileChangeHandler(e.target.files);
                              }}
                            />
                          </span>
                        </s.DocumentBlock>
                      </div>
                    )}
                  </Fragment>
                )}

                {supportIndustryList.length > 0 && (
                  <div className={`lp-w-full mb-24${errors.industry && touched.industry ? ` has-error` : ``}`}>
                    <Select
                      label="Business industry"
                      testId="industry"
                      onChange={(value) => setFieldValue('industry', value)}
                      value={initialValues.industry}
                      helperText="The industry that most closely resembles the business."
                      options={supportIndustryList}
                      errorMessage={errors.industry && touched.industry ? errors.industry : ''}
                    />
                  </div>
                )}
              </div>

              <s.Cta className="lp-flex lp-justify-end lp-items-center sm:flex-col">
                <Button
                  type="submit"
                  size="medium"
                  variant="primary"
                  className="no-min-width lp-full"
                  isLoading={requestLoader || fileUploading}
                  onClick={() => setFieldValue('isSaveFinishLater', false)}
                >
                  Save and {saveAndReview ? `review` : `continue`}
                  <Icon name="ArrowRight" />
                </Button>
                <div className="mobile-sidebar-footer">
                  <Button size="medium" onClick={saveAndFinishLater} variant="ghost" className="lp-w-full">
                    <Icon name="Save" />
                    Save and finish later
                  </Button>
                </div>
              </s.Cta>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default Step01View;
