import { Heading, Icon, Text } from '@limepayments/cosmic';

function EmptyComponent() {
  return (
    <div className="transaction-empty">
      <div className="transaction-empty-inner">
        <Icon name="Wallet" />
        <Heading
          alignment="center"
          tagName="h2"
          variant="xs"
          className="mt-12 mb-8"
          testId="payment-filter-no-data-title"
        >
          No transactions to display
        </Heading>
        <Text
          alignment="center"
          tagName="p"
          variant="body-2"
          className="text-dark-50 mt-0 mb-16"
          testId="payment-filter-no-data-sub-title"
        >
          Transactions will appear here when they have been created.
        </Text>
      </div>
    </div>
  );
}

export default EmptyComponent;
