import { CustomField } from 'api/merchant/dashboard/merchants.types';

type RequiredOptional = 'required' | 'optional';
type VisibleHidden = 'visible' | 'hidden';

export const RadioValues = {
  REQUIRED: 'required' as RequiredOptional,
  OPTIONAL: 'optional' as RequiredOptional,
  VISIBLE: 'visible' as VisibleHidden,
  HIDDEN: 'hidden' as VisibleHidden,
};

export interface CustomFieldModalForm {
  key: string | null;
  label: string;
  required: RequiredOptional;
  visibleForCustomer: VisibleHidden;
}

type ToCustomFieldModalFormType = (customField: CustomField) => CustomFieldModalForm;
type ToCustomFieldType = (customFieldModalForm: CustomFieldModalForm) => CustomField;

export const toCustomFieldModalForm: ToCustomFieldModalFormType = (customField: CustomField) => ({
  key: customField.key,
  label: customField.label,
  required: customField.required ? RadioValues.REQUIRED : RadioValues.OPTIONAL,
  visibleForCustomer: customField.visibleForCustomer ? RadioValues.VISIBLE : RadioValues.HIDDEN,
});

export const toCustomField: ToCustomFieldType = (customFieldModalForm: CustomFieldModalForm) => ({
  key: customFieldModalForm.key || customFieldModalForm.label,
  label: customFieldModalForm.label,
  required: customFieldModalForm.required === RadioValues.REQUIRED,
  visibleForCustomer: customFieldModalForm.visibleForCustomer === RadioValues.VISIBLE,
});

export type CustomFielModalFormOnSubmit = (
  values: CustomFieldModalForm,
  customFieldAction: CustomFieldActions | null,
  formObj: { resetForm: () => void },
) => void;

export enum CustomFieldActions {
  ADD = 'add',
  EDIT = 'edit',
  DELETE = 'delete',
}

export const CustomFieldModalTitle = {
  [CustomFieldActions.ADD]: 'Add new field',
  [CustomFieldActions.EDIT]: 'Edit field',
  [CustomFieldActions.DELETE]: 'Delete field',
};

export const CustomFieldModalConfirmBtn = {
  [CustomFieldActions.ADD]: 'Save',
  [CustomFieldActions.EDIT]: 'Save changes',
  [CustomFieldActions.DELETE]: 'Confirm and delete',
};
