import { Formik } from 'formik';
import * as Yup from 'yup';

import { Button, Heading, Link, Message, PasswordField, TextField } from '@limepayments/cosmic';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Please enter a valid email address').required('Email address is required'),
  password: Yup.string().required('Password is required'),
});

type FormikFormValues = {
  email: string;
  password: string;
};

interface Props {
  onSubmitClick: (values: FormikFormValues, formObj: { resetForm: () => void }) => void;
  onForgotPasswordClick: () => void;
  initialValues: FormikFormValues;
  loginRequestLoader: boolean;
  errorMsg: string;
  logoUri: string;
  name: string;
}

function LoginView({
  onSubmitClick,
  onForgotPasswordClick,
  initialValues,
  loginRequestLoader,
  errorMsg,
  name,
  logoUri,
}: Props) {
  return (
    <>
      <div className="lp-w-full mb-32">
        <div className={`avatar ${!logoUri ? `hoppy` : ``}  mx-auto mb-32`}>
          {logoUri && (
            <div className="avatar-initial">
              <img src={logoUri} alt="Logo" />
            </div>
          )}

          {!logoUri && name && (
            <div className="avatar-initial">
              <Heading alignment="center" tagName="h1" variant="lg" className="m-0">
                {name}
              </Heading>
            </div>
          )}
        </div>
        <Heading testId="log-in-page-title" alignment="center" tagName="h1" variant="md" className="mb-12">
          Log in
        </Heading>
        {errorMsg.length > 0 && (
          <Message type="inline" children={<span className="text-wrap">{errorMsg}</span>} variant={'error'} />
        )}
      </div>
      <Formik<FormikFormValues>
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values, formObj: { resetForm: () => void }) => {
          onSubmitClick(values, formObj);
        }}
        validationSchema={validationSchema}
      >
        {({ values, handleSubmit, errors, touched, handleChange, handleBlur }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <div className="lp-w-full mb-32">
              <div className="lp-w-full mb-8">
                <TextField
                  fullWidth
                  inputMode="email"
                  type="email"
                  label="Email"
                  autoComplete="off"
                  testId="email"
                  id="email"
                  name="email"
                  onBlur={handleBlur}
                  value={values.email}
                  onChange={handleChange}
                  startAdornment="Filled-Email"
                  error={errors.email && touched.email ? errors.email : ''}
                />
              </div>
              <div className="lp-w-full lp-pass mb-0">
                <PasswordField
                  fullWidth
                  inputMode="text"
                  type="password"
                  label="Password"
                  autoComplete="off"
                  testId="password"
                  id="password"
                  name="password"
                  onBlur={handleBlur}
                  value={values.password}
                  onChange={handleChange}
                  startAdornment="Filled-Key"
                  error={errors.password && touched.password ? errors.password : ''}
                />
              </div>
              <div className="lp-w-full zover-2 mb-32">
                <Link onClick={onForgotPasswordClick} size="small" target="_self" className="fw-medium">
                  Forgot password?
                </Link>
              </div>
            </div>
            <Button
              type="submit"
              size="medium"
              variant="primary"
              className={`lp-w-full`}
              testId="loginButton"
              isLoading={loginRequestLoader}
            >
              Log in
            </Button>
          </form>
        )}
      </Formik>
    </>
  );
}

export default LoginView;
