import DashboardRoutesList from 'pages/platform/dashboard/constants';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/platform/hooks';
import { getPlatformBaseUrl } from 'utils/getBaseUrl';

import PaymentRoutesList from '../../constants';

interface Props {
  paymentTxnId: string;
  amount: string;
}

const DataTablePaymentRow = ({ paymentTxnId, amount }: Props) => {
  const navigate = useNavigate();

  const { platformName } = useAppSelector((state) => ({
    platformName: state.config.platformName,
  }));

  return (
    <div
      className=""
      onClick={() =>
        navigate(
          `${getPlatformBaseUrl(platformName)}/dashboard/${DashboardRoutesList.PAYMENT_ROUTE}/${
            PaymentRoutesList.PAYMENT_DETAILS
          }?txnId=${paymentTxnId}`,
        )
      }
    >
      <button className="sm:text-left">{amount}</button>
    </div>
  );
};

export default DataTablePaymentRow;
