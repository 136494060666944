import { Icon, Spinner } from '@limepayments/cosmic';

interface Props {
  onEditActionClick: (id: string, section: string) => void;
  onRemoveActionClick: (id: string, section: string, name: string) => void;
  id: string;
  section: string;
  editFetchLoader: boolean;
  allowDelete: boolean;
  name: string;
}

function ActionComponent({
  onEditActionClick,
  onRemoveActionClick,
  id,
  section,
  editFetchLoader,
  allowDelete,
  name,
}: Props) {
  return (
    <div className="lp-flex lp-flex-column">
      <ul className="action-list">
        <li className="action-list-items">
          <button
            type="button"
            onClick={() => onEditActionClick(id, section)}
            className="lp-w-full lp-flex lp-align-center"
          >
            {editFetchLoader && (
              <div className="m-0 p-0 size-18">
                <Spinner variant={'simple'} isVisible label="" />
              </div>
            )}
            {!editFetchLoader && <Icon name="Edit" className="m-0 p-0" />}

            <span>Edit</span>
          </button>
        </li>
        {allowDelete && (
          <li className="action-list-items">
            <button
              type="button"
              onClick={() => onRemoveActionClick(id, section, name)}
              className="lp-w-full lp-flex lp-align-center"
            >
              <Icon name="Cross" className="m-0 p-0" />
              <span>Remove</span>
            </button>
          </li>
        )}
      </ul>
    </div>
  );
}

export default ActionComponent;
