import { Fragment } from 'react';

import { Heading, Text } from '@limepayments/cosmic';

function ConfirmOrderSection() {
  return (
    <Fragment>
      <div>
        <div className="lp-w-full mb-16">
          <Text tagName="p" variant="body-2" className="text-dark-50 mt-0">
            Order amount
          </Text>
          <Heading alignment="left" tagName="h1" variant="lg">
            $540.00
          </Heading>
        </div>
        <div className="lp-w-full mb-16">
          <Text tagName="p" variant="caption" className="text-dark-50 mt-0">
            Description
          </Text>
          <Text tagName="p" variant="body-3" className="text-dark mt-0">
            Plumbing 24 Sep 2022
          </Text>
        </div>
        <div className="lp-w-full mb-16">
          <Text tagName="p" variant="caption" className="text-dark-50 mt-0">
            Customer details
          </Text>
          <Text tagName="p" variant="body-3" className="text-dark mt-0">
            Deb Amos
            <br />
            deb@gmail.com
            <br />
            0400000000
          </Text>
        </div>
        <div className="separator"></div>
        <div className="lp-w-full">
          <div className="lp-flex lp-align-center">
            <Heading alignment="left" tagName="h1" variant="xxs" className="text-dark-50 mb-0">
              Item breakdown
            </Heading>
          </div>
          <div className="item-breakdown mt-0">
            <table className="lp-w-full table-summary">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Count</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Faucet</td>
                  <td>1</td>
                  <td>$160.00</td>
                </tr>
                <tr>
                  <td>Grout</td>
                  <td>5</td>
                  <td>$80.00</td>
                </tr>
                <tr>
                  <td>Paint</td>
                  <td>10</td>
                  <td>$300.00</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Total (incl. GST)</th>
                  <th></th>
                  <th>$540.00</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ConfirmOrderSection;
