/** @jsxImportSource @emotion/react */

import { CustomField } from 'api/merchant/dashboard/merchants.types';
import ErrorComponent from 'pages/common/error/ErrorComponent';
import { Fragment } from 'react';

import { Button, Heading, Icon, Spinner, Text } from '@limepayments/cosmic';

import * as s from '../../../../../../assets/styles/Platforms.styles';
import SettingRoutesList from '../../constants';
// Import Static UIs
import SettingsSidebar from '../../partials/SettingsSidebar';
import CustomFieldModal from './CustomFieldModal';
import CustomFieldsDataTable from './CustomFieldsDataTable';
import { CustomFielModalFormOnSubmit, CustomFieldActions } from './types';

export interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  customFieldsList: Array<CustomField>;
  actionCustomField: CustomField | null;
  setActionCustomField: (value: CustomField | null) => void;
  customFieldAction: CustomFieldActions | null;
  setCustomFieldAction: (value: CustomFieldActions) => void;
  onSubmit: CustomFielModalFormOnSubmit;
  editDeleteHandler: (key: string, action: CustomFieldActions) => void;
  updateErrorMessage: string;
  setUpdateErrorMessage: (value: string) => void;
  isPageLoading: boolean;
  errorMessage: string;
  updateRequestLoading: boolean;
}

function PayByLinkView({
  isModalOpen,
  setIsModalOpen,
  customFieldsList,
  actionCustomField,
  setActionCustomField,
  customFieldAction,
  setCustomFieldAction,
  onSubmit,
  editDeleteHandler,
  updateErrorMessage,
  setUpdateErrorMessage,
  isPageLoading,
  errorMessage,
  updateRequestLoading,
}: Props) {
  return (
    <Fragment>
      <div className="content-wrap-inner with-gutter">
        <div className="lp-w-full lp-flex">
          <div className="payment-sidebar">
            <SettingsSidebar activeTab={SettingRoutesList.SETTINGS_PAY_BY_LINK} />
          </div>

          <div className="payment-content-area">
            <Fragment>
              <div className="lp-w-full lp-flex lp-justify-between sm-lp-flex-col mb-32">
                <Heading alignment="left" tagName="h2" variant="sm">
                  Pay by link
                </Heading>
              </div>

              <s.MerchantsData className="bt-1">
                <div css={{ display: 'flex', gap: 16 }} className="mb-32">
                  <div css={{ flex: 1 }}>
                    <Heading alignment="left" tagName="h2" variant="xs">
                      Configure fields
                    </Heading>
                    <Text variant="body-3" className="mt-4">
                      This enables you to add custom fields to your pay by link and save them so it requires your users
                      to input important information.
                    </Text>
                  </div>
                  <div>
                    <Button
                      size="small"
                      variant="reversed"
                      onClick={() => {
                        setActionCustomField(null);
                        setCustomFieldAction(CustomFieldActions.ADD);
                        setIsModalOpen(!isModalOpen);
                        setUpdateErrorMessage('');
                      }}
                    >
                      Add a new field
                      <Icon name="Plus" />
                    </Button>
                  </div>
                </div>
              </s.MerchantsData>

              <s.MerchantsData className="bt-1">
                {!isPageLoading && errorMessage.length ? <ErrorComponent bodyText={errorMessage} /> : null}

                {isPageLoading && (
                  <div className="spinner-wrapper">
                    <Spinner variant="simple" isVisible label="Loading..." />
                  </div>
                )}

                {!isPageLoading && !errorMessage.length && (
                  <CustomFieldsDataTable
                    customFieldsList={customFieldsList}
                    totalRows={customFieldsList.length}
                    editDeleteHandler={editDeleteHandler}
                  />
                )}
              </s.MerchantsData>
            </Fragment>
          </div>
        </div>
      </div>
      <CustomFieldModal
        isModalOpen={isModalOpen}
        modalToggler={setIsModalOpen}
        actionCustomField={actionCustomField}
        customFieldAction={customFieldAction}
        existingKeys={customFieldsList.map((obj) => obj.key)}
        onSubmitClick={onSubmit}
        updateRequestLoading={updateRequestLoading}
        updateErrorMessage={updateErrorMessage}
      />
    </Fragment>
  );
}

export default PayByLinkView;
