/** @jsxImportSource @emotion/react */

import { updateMerchant } from 'api/merchant/dashboard/merchants';
import {
  getMerchantOrderDetail,
  payMerchantOrder,
  resolveThreeDSAuthorisationRequired,
} from 'api/merchant/dashboard/orders';
import { OrderAPIResponseType, PayOrderRequest, PayOrderResponse } from 'api/merchant/dashboard/orders.types';
import ErrorPage from 'pages/common/error';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppSelector } from 'redux/merchant/hooks';
import getErrorMessage from 'utils/getErrorMessage';

import { Spinner } from '@limepayments/cosmic';

import { CheckoutProps, PaymentData } from './Checkout';
import { PayInvoice, PayInvoiceProps } from './PayInvoice';

export function PayInvoicePage() {
  const [order, setOrder] = useState<OrderAPIResponseType | null>(null);
  const [paymentData, setPaymentData] = useState<PaymentData | null>(null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const { merchantId } = useAppSelector((state) => ({
    merchantId: state.config.merchantId,
  }));

  const params = useParams<{ invoiceId: string; orderId: string }>();
  const [searchParams] = useSearchParams();

  const orderId = useMemo(() => params.orderId ?? '', [params.orderId]);
  const bearerToken = useMemo(() => searchParams.get('token') ?? '', [searchParams]);

  useEffect(() => {
    (async () => {
      setError('');
      setIsLoading(true);

      try {
        const order = await getMerchantOrderDetail(orderId, bearerToken);

        setOrder(order);
      } catch (error) {
        setError(getErrorMessage(error));
      } finally {
        setIsLoading(false);
      }
    })();
  }, [orderId, bearerToken]);

  const handlePaymentToken = useCallback<CheckoutProps['onPaymentToken']>(
    async (paymentTokenId, paymentData) => {
      setPaymentData(null);
      setIsLoading(true);

      try {
        const resolvePayOrder = async (payload: PayOrderRequest): Promise<PayOrderResponse> => {
          const response = await payMerchantOrder(orderId, payload, bearerToken);

          if ('ThreeDSAuthorisationRequired' in response) {
            const { ThreeDSAuthorisationRequired: threeDSResponse } = response;

            await resolveThreeDSAuthorisationRequired(threeDSResponse);

            return resolvePayOrder({
              Confirm3DSComplete: { paymentTokenId, threeDSResponse },
            });
          }

          return response;
        };

        const response = await resolvePayOrder({ PayByPaymentToken: { paymentTokenId } });

        if ('PayOrderComplete' in response) {
          const order = await getMerchantOrderDetail(orderId, bearerToken);
          setOrder(order);
          setPaymentData(paymentData);
          toast.success('Payment completed successfully');
          window.scrollTo(0, 0);
        }
      } catch (error) {
        toast.error(getErrorMessage(error));
      } finally {
        setIsLoading(false);
      }
    },
    [orderId, bearerToken],
  );

  const handleUpdateBillingPaymentSource = useCallback<PayInvoiceProps['onUpdateBillingPaymentSource']>(
    async (cardPaymentSource, callback) => {
      if (!cardPaymentSource.cardPaymentSourceId) return;

      const { cardPaymentSourceId: paymentSourceId, brand, last4 } = cardPaymentSource;

      try {
        await updateMerchant(merchantId, { SetMerchantBillingPaymentSource: { paymentSourceId } }, bearerToken);
        const order = await getMerchantOrderDetail(orderId, bearerToken);
        setOrder(order);
        toast.success(`Auto payment updated: ${brand ?? 'Card'} •••• ${last4}`);
        callback();
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        toast.error(errorMessage);
        callback(errorMessage);
      }
    },
    [bearerToken, merchantId, orderId],
  );

  return (
    <>
      {!!error && <ErrorPage bodyTitle="Error" bodyText={error} />}
      {!error && !!order && (
        <PayInvoice
          order={order}
          paymentData={paymentData}
          onPaymentToken={handlePaymentToken}
          onUpdateBillingPaymentSource={handleUpdateBillingPaymentSource}
        />
      )}
      {isLoading && (
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'fixed',
            width: '100%',
            height: '100%',
            background: 'rgba(255,255,255,0.8)',
            pointerEvents: 'none',
          }}
        >
          <Spinner variant="simple" isVisible label="Loading..." />
        </div>
      )}
    </>
  );
}
