import { Fragment } from 'react';

import { Illustration, Text } from '@limepayments/cosmic';

function PaymentRequestSection() {
  return (
    <Fragment>
      <div className="mx-auto illustration-115 mt-0 mb-24">
        <Illustration name="Email" size="medium" />
      </div>
      <Text alignment="center" tagName="p" variant="body-2" className="lp-neutral-600">
        Request for payment has been sent to
        <br /> <strong>deb@gmail.com.</strong>?
      </Text>
    </Fragment>
  );
}

export default PaymentRequestSection;
