import { DateTime } from 'luxon';
import {
  AmountFilter,
  AmountFilterValue,
  CustomerFilter,
  DateFilter,
  DateFilterValue,
  OrderFilter,
  TransactionFilter,
} from 'pages/common/filters';
import { useCallback, useEffect, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { useAppDispatch, useAppSelector } from 'redux/merchant/hooks';
import { setListingPagePropsObj, setPaymentFilterObj } from 'redux/merchant/slice/paymentSlice';

import { Button, Checkbox, CheckboxGroup, Heading, Icon, Text } from '@limepayments/cosmic';

import { PaymentFilterPaymentOptionObj, PaymentFilterStatusObj } from '../../constants';
import { getInitialFilterState } from '../initialStates';
import { IPaymentFilterObj, IPaymentFilterObjError } from '../types';
import { FilterDropdown } from './FilterComponent.styles';

const getInitialErrorState = (): IPaymentFilterObjError => {
  return {
    amountError: '',
    statusError: '',
    customerError: '',
    paymentOptionError: '',
    orderIdError: '',
    transactionIdError: '',
    createdOnError: '',
    availableOnError: '',
  };
};

interface Props {
  modalToggler: () => void;
}

// TODO: refector FilterComponent
function FilterComponent({ modalToggler }: Props) {
  const dispatch = useAppDispatch();
  const { paymentFilterObj, listingPageProps } = useAppSelector((state) => ({
    paymentFilterObj: state.payment.paymentFilterObj,
    listingPageProps: state.payment.listingPageProps,
  }));

  const [filterObj, setFilterObj] = useState<IPaymentFilterObj>(paymentFilterObj);
  const [filterObjError, setFilterObjError] = useState<IPaymentFilterObjError>(getInitialErrorState());

  const filterChangeHandler = useCallback(
    (key: string, value: string | boolean | AmountFilterValue | DateFilterValue | null) => {
      setFilterObj((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    },
    [],
  );

  // TODO: refector
  const filterSaveHandler = async () => {
    const filterObjErrorRef = { ...getInitialErrorState() };
    let hasError = false;
    let totalFiltersApplied = 0;

    if (filterObj.amountChecked) {
      if (!filterObj.amount) {
        filterObjErrorRef.amountError = 'This field is required';
        hasError = true;
      }

      totalFiltersApplied++;
    }

    if (filterObj.statusChecked) {
      if (!filterObj.status) {
        filterObjErrorRef.statusError = 'This field is required';
        hasError = true;
      }

      totalFiltersApplied++;
    }

    if (filterObj.customerChecked) {
      if (!filterObj.customer) {
        filterObjErrorRef.customerError = 'This field is required';
        hasError = true;
      }

      totalFiltersApplied++;
    }

    if (filterObj.paymentOptionChecked) {
      if (!filterObj.paymentOption) {
        filterObjErrorRef.paymentOptionError = 'This field is required';
        hasError = true;
      }

      totalFiltersApplied++;
    }

    if (filterObj.orderIdChecked) {
      if (!filterObj.orderId) {
        filterObjErrorRef.orderIdError = 'This field is required';
        hasError = true;
      }

      totalFiltersApplied++;
    }

    if (filterObj.transactionIdChecked) {
      if (!filterObj.transactionId) {
        filterObjErrorRef.transactionIdError = 'This field is required';
        hasError = true;
      }

      totalFiltersApplied++;
    }

    if (filterObj.createdOnChecked) {
      if (!filterObj.createdOn) {
        filterObjErrorRef.createdOnError = 'This field is required';
        hasError = true;
      }

      totalFiltersApplied++;
    }

    if (filterObj.availableOnChecked) {
      if (!filterObj.availableOn) {
        filterObjErrorRef.availableOnError = 'This field is required';
        hasError = true;
      }

      totalFiltersApplied++;
    }

    if (hasError) {
      setFilterObjError(filterObjErrorRef);
      return;
    }

    const saveTiggerAt = DateTime.now().toISO();

    if (
      paymentFilterObj.amount !== filterObj.amount ||
      paymentFilterObj.status !== filterObj.status ||
      paymentFilterObj.customer !== filterObj.customer ||
      paymentFilterObj.paymentOption !== filterObj.paymentOption ||
      paymentFilterObj.orderId !== filterObj.orderId ||
      paymentFilterObj.transactionId !== filterObj.transactionId ||
      paymentFilterObj.createdOn !== filterObj.createdOn ||
      paymentFilterObj.availableOn !== filterObj.availableOn
    ) {
      dispatch(setPaymentFilterObj({ ...filterObj, totalFiltersApplied, saveTiggerAt }));
      dispatch(
        setListingPagePropsObj({
          ...listingPageProps,
          activePage: 1,
        }),
      );
    }
    modalToggler();
  };

  const clearFunction = () => {
    setFilterObj(getInitialFilterState());
  };

  // TODO: refector effects
  useEffect(() => {
    if (!filterObj.amountChecked && filterObj.amount) {
      filterChangeHandler('amount', null);
    }
  }, [filterObj.amountChecked, filterObj.amount, filterChangeHandler]);

  useEffect(() => {
    if (!filterObj.statusChecked && filterObj.status) {
      filterChangeHandler('status', null);
    }
  }, [filterObj.statusChecked, filterObj.status, filterChangeHandler]);

  useEffect(() => {
    if (!filterObj.customerChecked && filterObj.customer) {
      filterChangeHandler('customer', null);
    }
  }, [filterObj.customerChecked, filterObj.customer, filterChangeHandler]);

  useEffect(() => {
    if (!filterObj.paymentOptionChecked && filterObj.paymentOption) {
      filterChangeHandler('paymentOption', null);
    }
  }, [filterObj.paymentOptionChecked, filterObj.paymentOption, filterChangeHandler]);

  useEffect(() => {
    if (!filterObj.orderIdChecked && filterObj.orderId) {
      filterChangeHandler('orderId', null);
    }
  }, [filterObj.orderIdChecked, filterObj.orderId, filterChangeHandler]);

  useEffect(() => {
    if (!filterObj.transactionIdChecked && filterObj.transactionId) {
      filterChangeHandler('transactionId', null);
    }
  }, [filterObj.transactionIdChecked, filterObj.transactionId, filterChangeHandler]);

  useEffect(() => {
    if (!filterObj.createdOnChecked && filterObj.createdOn) {
      filterChangeHandler('createdOn', null);
    }
  }, [filterObj.createdOnChecked, filterObj.createdOn, filterChangeHandler]);

  useEffect(() => {
    if (!filterObj.availableOnChecked && filterObj.availableOn) {
      filterChangeHandler('availableOn', null);
    }
  }, [filterObj.availableOnChecked, filterObj.availableOn, filterChangeHandler]);

  return (
    <ClickAwayListener onClickAway={() => modalToggler()}>
      <FilterDropdown className="w-328">
        <div className="lp-flex lp-flex-column">
          <div className="filter-header">
            <Heading alignment="left" tagName="h2" variant="xs" className="mb-0">
              Filters
            </Heading>
            <Button
              type="button"
              onClick={() => modalToggler()}
              size="small"
              variant="ghost"
              className="button-close m-0 p-0"
            >
              <Icon name="Cross" className="m-0 p-0" />
            </Button>
          </div>
          <div className="filter-body">
            <CheckboxGroup>
              <div className="checkbox-items">
                <Checkbox
                  id="amountChecked"
                  testId="payment-filter-amount-check"
                  label="Amount"
                  variant="body-2"
                  checked={filterObj.amountChecked}
                  onChange={() => filterChangeHandler('amountChecked', !filterObj.amountChecked)}
                />
                {filterObj.amountChecked && (
                  <div className="checkbox-items-details">
                    <AmountFilter
                      value={filterObj.amount}
                      onChange={(amount) => filterChangeHandler('amount', amount)}
                    />
                    {filterObjError.amountError && (
                      <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                        {filterObjError.amountError}
                      </Text>
                    )}
                  </div>
                )}
              </div>

              <div className="checkbox-items">
                <Checkbox
                  id="statusChecked"
                  testId="payment-filter-status-check"
                  label="Status"
                  variant="body-2"
                  checked={filterObj.statusChecked}
                  onChange={() => filterChangeHandler('statusChecked', !filterObj.statusChecked)}
                />
                {filterObj.statusChecked && (
                  <div className="checkbox-items-details">
                    <div className="lp-w-full mb-0">
                      <div className="selectdropdown">
                        <Icon name="ChevronDown" className="arrow-down" />
                        {/* TODO: multi-select */}
                        <select
                          className="form-control"
                          data-testid="payment-filter-status-select"
                          value={filterObj.status ?? ''}
                          onChange={(e) => filterChangeHandler('status', e.target.value || null)}
                        >
                          <option value="">please select</option>
                          {Object.values(PaymentFilterStatusObj).map((obj, key) => (
                            <option value={obj.value} key={key}>
                              {obj.label}
                            </option>
                          ))}
                        </select>
                        {filterObjError.statusError && (
                          <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                            {filterObjError.statusError}
                          </Text>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="checkbox-items">
                <Checkbox
                  id="customerChecked"
                  testId="payment-filter-customer-check"
                  label="Customer"
                  variant="body-2"
                  checked={filterObj.customerChecked}
                  onChange={() => filterChangeHandler('customerChecked', !filterObj.customerChecked)}
                />
                {filterObj.customerChecked && (
                  <div className="checkbox-items-details">
                    <CustomerFilter
                      value={filterObj.customer}
                      onChange={(customer) => setFilterObj({ ...filterObj, customer })}
                    />
                    {filterObjError.customerError && (
                      <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                        {filterObjError.customerError}
                      </Text>
                    )}
                  </div>
                )}
              </div>

              <div className="checkbox-items">
                <Checkbox
                  id="paymentOptionChecked"
                  testId="payment-filter-payment-option-check"
                  label="Payment option"
                  variant="body-2"
                  checked={filterObj.paymentOptionChecked}
                  onChange={() => filterChangeHandler('paymentOptionChecked', !filterObj.paymentOptionChecked)}
                />
                {filterObj.paymentOptionChecked && (
                  <div className="checkbox-items-details">
                    <div className="lp-w-full mb-0">
                      <div className="selectdropdown">
                        <Icon name="ChevronDown" className="arrow-down" />
                        <select
                          className="form-control"
                          data-testid="payment-filter-payment-option-select"
                          value={filterObj.paymentOption ?? ''}
                          onChange={(e) => filterChangeHandler('paymentOption', e.target.value || null)}
                        >
                          <option value="">please select</option>
                          {Object.values(PaymentFilterPaymentOptionObj).map((obj, key) => (
                            <option value={obj.value} key={key}>
                              {obj.label}
                            </option>
                          ))}
                        </select>
                        {filterObjError.paymentOptionError && (
                          <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                            {filterObjError.paymentOptionError}
                          </Text>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="checkbox-items">
                <Checkbox
                  id="orderIdChecked"
                  testId="payment-filter-internal-order-id-check"
                  label="Order ID"
                  variant="body-2"
                  checked={filterObj.orderIdChecked}
                  onChange={() => filterChangeHandler('orderIdChecked', !filterObj.orderIdChecked)}
                />
                {filterObj.orderIdChecked && (
                  <div className="checkbox-items-details">
                    <OrderFilter
                      value={filterObj.orderId}
                      onChange={(orderId) => setFilterObj({ ...filterObj, orderId })}
                    />
                    {filterObjError.orderIdError && (
                      <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                        {filterObjError.orderIdError}
                      </Text>
                    )}
                  </div>
                )}
              </div>

              <div className="checkbox-items">
                <Checkbox
                  id="transactionIdChecked"
                  testId="payment-filter-txn-id-check"
                  label="Transaction ID"
                  variant="body-2"
                  checked={filterObj.transactionIdChecked}
                  onChange={() => filterChangeHandler('transactionIdChecked', !filterObj.transactionIdChecked)}
                />
                {filterObj.transactionIdChecked && (
                  <div className="checkbox-items-details">
                    <TransactionFilter
                      value={filterObj.transactionId}
                      onChange={(transactionId) => setFilterObj({ ...filterObj, transactionId })}
                    />
                    {filterObjError.transactionIdError && (
                      <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                        {filterObjError.transactionIdError}
                      </Text>
                    )}
                  </div>
                )}
              </div>

              <div className="checkbox-items">
                <Checkbox
                  id="createdOn"
                  label="Created on"
                  variant="body-2"
                  checked={filterObj.createdOnChecked}
                  onChange={() => filterChangeHandler('createdOnChecked', !filterObj.createdOnChecked)}
                />
                {filterObj.createdOnChecked && (
                  <div className="checkbox-items-details">
                    <DateFilter
                      value={filterObj.createdOn}
                      onChange={(createdOn) => filterChangeHandler('createdOn', createdOn)}
                    />
                    {filterObjError.createdOnError && (
                      <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                        {filterObjError.createdOnError}
                      </Text>
                    )}
                  </div>
                )}
              </div>

              <div className="checkbox-items">
                <Checkbox
                  id="availableOn"
                  label="Available on"
                  variant="body-2"
                  checked={filterObj.availableOnChecked}
                  onChange={() => filterChangeHandler('availableOnChecked', !filterObj.availableOnChecked)}
                />
                {filterObj.availableOnChecked && (
                  <div className="checkbox-items-details">
                    <DateFilter
                      value={filterObj.availableOn}
                      onChange={(availableOn) => filterChangeHandler('availableOn', availableOn)}
                    />
                    {filterObjError.availableOnError && (
                      <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                        {filterObjError.availableOnError}
                      </Text>
                    )}
                  </div>
                )}
              </div>
            </CheckboxGroup>
          </div>
          <div className="filter-footer">
            <Button
              className="lp-w-full"
              onClick={() => clearFunction()}
              size="medium"
              variant="reversed"
              testId="payment-filter-clear-button"
            >
              Clear
            </Button>
            <Button
              className="lp-w-full"
              onClick={() => filterSaveHandler()}
              size="medium"
              variant="primary"
              testId="payment-filter-save-button"
            >
              Save
            </Button>
          </div>
        </div>
      </FilterDropdown>
    </ClickAwayListener>
  );
}

export default FilterComponent;
