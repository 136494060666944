import { Button, Heading, Illustration, Text } from '@limepayments/cosmic';

interface Props {
  onSubmitClick: () => void;
}

function ResetPasswordConfirmView({ onSubmitClick }: Props) {
  return (
    <>
      <div className="lp-w-full mb-32">
        <div className="size-136 mx-auto mb-32">
          <Illustration name="Success" size="large" />
        </div>
        <Heading alignment="center" tagName="h1" variant="md" className="mb-12">
          New password created
        </Heading>
        <Text testId="reset-password-confirm-text" tagName="p" variant="body-3" alignment="center">
          You have succesfully created a new password for your account.
        </Text>
      </div>
      <Button onClick={onSubmitClick} size="medium" variant="primary" className="lp-w-full mb-8">
        Login
      </Button>
    </>
  );
}

export default ResetPasswordConfirmView;
