import * as s from 'assets/styles/Onboarding.styles';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/merchant/hooks';
import { getMerchantBaseUrl } from 'utils/getBaseUrl';

import { Button, Heading, Icon, Text } from '@limepayments/cosmic';

import OnboardingRoutesList from '../../constants';

interface Props {
  hasOwner: boolean;
  businessType: string;
  companyType: string;
}

const BusinessRepresentatives = ({ hasOwner, businessType, companyType }: Props) => {
  const navigate = useNavigate();

  const { merchantName } = useAppSelector((state) => ({
    merchantName: state.config.merchantName,
  }));

  return (
    <>
      <div className="lp-w-full lp-equal-width mb-24">
        <s.MultiColumnRespBlock className="content-header mb-24">
          <Heading alignment="left" tagName="h4" variant="sm">
            Business owners and directors
          </Heading>
          <Button
            onClick={() => {
              navigate(`${getMerchantBaseUrl(merchantName)}/onboarding/${OnboardingRoutesList.ONBOARDING_STEP05_PAGE}`);
            }}
            size="medium"
            variant="ghost"
            className="no-min-width lp-justify-end"
            testId="business-representative-edit-button"
          >
            Edit
            <Icon name="Edit" />
          </Button>
        </s.MultiColumnRespBlock>
        <div className="content">
          {businessType !== 'soletrader' && companyType !== 'Public' && (
            <s.MultiColumnRespBlock className="mb-24" data-testid="has-owner-div">
              <Text tagName="p" variant="body-3" testId="has-owner-text">
                Has owners with 25% or more ownership
              </Text>
              <Text tagName="p" variant="body-3" testId="has-owner-value" isEmphasised>
                {hasOwner ? `Yes` : `No`}
              </Text>
            </s.MultiColumnRespBlock>
          )}
        </div>
      </div>
    </>
  );
};

export default BusinessRepresentatives;
