import { GetMerchantStatus } from 'api/merchant/onbording/introduction.types';
import qs from 'query-string';
import { FunctionComponent, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

interface Props {
  applicationArea: string;
  isLoggedIn: boolean | null;
  defaultRoute: string;
  merchantState?: GetMerchantStatus['state'];
}

const PublicRoute: FunctionComponent<Props> = ({ isLoggedIn, defaultRoute, applicationArea, merchantState }: Props) => {
  const { pathname } = useLocation();
  const { redirectUrl } = qs.parse(window.location.search);

  //react keeps the browser position when clicking on Link, this will make the browser to scroll to the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (isLoggedIn) {
    if ((applicationArea === 'merchant' && merchantState) || applicationArea === 'platform') {
      return <Navigate to={redirectUrl ? (redirectUrl as string) : defaultRoute} replace={true} />;
    }
  }

  return <Outlet />;
};

export default PublicRoute;
