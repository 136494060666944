import { Fragment } from 'react';
import { useState } from 'react';

import { Button, Heading, Icon, Select, Text, TextField } from '@limepayments/cosmic';

function ItemBreakdownAddNewItemsSection() {
  const [isOpened, setIsOpened] = useState(false);
  function toggleDetails() {
    setIsOpened((wasOpened: any) => !wasOpened);
  }
  return (
    <Fragment>
      <Text tagName="p" variant="body-2" className="lp-neutral-600 mt-0 mb-24">
        Create a breakdown of a customer's order by adding items
      </Text>
      <div className="lp-w-full">
        <Heading alignment="left" tagName="h1" variant="xxs" className="text-dark-50 mb-0">
          Item breakdown
        </Heading>
        <div className="item-breakdown">
          <table className="lp-w-full table-summary">
            <thead>
              <tr>
                <th>Item</th>
                <th>Count</th>
                <th>Total</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Faucet</td>
                <td>1</td>
                <td>$160.00</td>
                <td>
                  <div className="lp-edit-action">
                    <div className="lp-edit-action-holder">
                      <button className="edit-action-button" onClick={toggleDetails}>
                        <Icon name="Ellipse" />
                      </button>
                    </div>
                    {/* Begins Dropdown */}
                    {isOpened && (
                      <div className="action-dropdown">
                        <Button onClick={function noRefCheck() {}} variant="ghost" size={'small'}>
                          <Icon name="Edit" />
                          Edit
                        </Button>
                        <Button onClick={function noRefCheck() {}} variant="ghost" size={'small'}>
                          <Icon name="Cross" />
                          Remove
                        </Button>
                      </div>
                    )}
                    {/* //End Dropdown */}
                  </div>
                </td>
              </tr>
              <tr>
                <td>Grout</td>
                <td>5</td>
                <td>$80.00</td>
                <td>
                  <div className="lp-edit-action">
                    <div className="lp-edit-action-holder">
                      <button className="edit-action-button" onClick={toggleDetails}>
                        <Icon name="Ellipse" />
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Paint</td>
                <td>10</td>
                <td>$300.00</td>
                <td>
                  <div className="lp-edit-action">
                    <div className="lp-edit-action-holder">
                      <button className="edit-action-button" onClick={toggleDetails}>
                        <Icon name="Ellipse" />
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="add-new-item">
        <Text tagName="p" variant="body-2" className="lp-neutral-600 mt-0 mb-16" isEmphasised>
          New item
        </Text>
        <div className="two-col mb-16">
          <div className="lp-w-half white-bg">
            <TextField
              fullWidth
              containerId="standard"
              label="Item name"
              onBlur={function noRefCheck() {}}
              onChange={function noRefCheck() {}}
              onFocus={function noRefCheck() {}}
              onKeyDown={function noRefCheck() {}}
              onKeyPress={function noRefCheck() {}}
              onKeyUp={function noRefCheck() {}}
              onMouseDown={function noRefCheck() {}}
              onMouseLeave={function noRefCheck() {}}
              onMouseOver={function noRefCheck() {}}
              value="Faucet"
            />
          </div>
          <div className="lp-w-half white-bg">
            <TextField
              fullWidth
              containerId="standard"
              label="Item cost (AUD)"
              onBlur={function noRefCheck() {}}
              onChange={function noRefCheck() {}}
              onFocus={function noRefCheck() {}}
              onKeyDown={function noRefCheck() {}}
              onKeyPress={function noRefCheck() {}}
              onKeyUp={function noRefCheck() {}}
              onMouseDown={function noRefCheck() {}}
              onMouseLeave={function noRefCheck() {}}
              onMouseOver={function noRefCheck() {}}
              value="160.00"
              startAdornment="Filled-Dollar"
            />
          </div>
        </div>
        <div className="two-col">
          <div className="lp-w-half white-bg select-field">
            <Select
              label="Quantity"
              onChange={function noRefCheck() {}}
              options={[
                {
                  text: '1',
                  value: '1',
                },
                {
                  text: '2',
                  value: '2',
                },
              ]}
              testId="test-id"
              value="2"
            />
          </div>
          <div className="lp-w-half">
            <div className="lp-flex h-58px">
              <Button className="no-min-width w-70 mt-12" size="medium" variant="ghost">
                Remove
              </Button>
              <Button className="no-min-width w-70 mt-12" size="medium" variant="primary">
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Button className="no-min-width w-70 mt-12" size="medium" variant="reversed">
        Add new item
        <Icon name="Plus" className="ml-16" />
      </Button>
    </Fragment>
  );
}

export default ItemBreakdownAddNewItemsSection;
