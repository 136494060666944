import { Fragment } from 'react';

import { Button, Icon, Select, Text, TextField } from '@limepayments/cosmic';

function AddNewItemSection() {
  return (
    <Fragment>
      <Text tagName="p" variant="body-2" className="lp-neutral-600 mt-12 mb-24">
        Create a breakdown of a customer's order by adding items
      </Text>
      <div className="add-new-item">
        <Text tagName="p" variant="body-2" className="lp-neutral-600 mt-0 mb-16" isEmphasised>
          New item
        </Text>
        <div className="two-col mb-16">
          <div className="lp-w-half white-bg">
            <TextField
              fullWidth
              containerId="standard"
              label="Item name"
              onBlur={function noRefCheck() {}}
              onChange={function noRefCheck() {}}
              onFocus={function noRefCheck() {}}
              onKeyDown={function noRefCheck() {}}
              onKeyPress={function noRefCheck() {}}
              onKeyUp={function noRefCheck() {}}
              onMouseDown={function noRefCheck() {}}
              onMouseLeave={function noRefCheck() {}}
              onMouseOver={function noRefCheck() {}}
              value="Faucet"
            />
          </div>
          <div className="lp-w-half white-bg">
            <TextField
              fullWidth
              containerId="standard"
              label="Item cost (AUD)"
              onBlur={function noRefCheck() {}}
              onChange={function noRefCheck() {}}
              onFocus={function noRefCheck() {}}
              onKeyDown={function noRefCheck() {}}
              onKeyPress={function noRefCheck() {}}
              onKeyUp={function noRefCheck() {}}
              onMouseDown={function noRefCheck() {}}
              onMouseLeave={function noRefCheck() {}}
              onMouseOver={function noRefCheck() {}}
              value="160.00"
              startAdornment="Filled-Dollar"
            />
          </div>
        </div>
        <div className="two-col">
          <div className="lp-w-half white-bg select-field">
            <Select
              label="Quantity"
              onChange={function noRefCheck() {}}
              options={[
                {
                  text: '1',
                  value: '1',
                },
                {
                  text: '2',
                  value: '2',
                },
              ]}
              testId="test-id"
              value="2"
            />
          </div>
          <div className="lp-w-half">
            <div className="lp-flex h-58px">
              <Button className="no-min-width w-70 mt-12" size="medium" variant="ghost">
                Remove
              </Button>
              <Button className="no-min-width w-70 mt-12" size="medium" variant="primary">
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Button className="no-min-width w-70 mt-12" size="medium" variant="reversed">
        Add new item
        <Icon name="Plus" className="ml-16" />
      </Button>
    </Fragment>
  );
}

export default AddNewItemSection;
