import { handleResponse } from 'api/utils';
import getFetchOptions from 'utils/getFetchOptions';

import { CheckoutApplePayAPIResponse, PaymentConfirmationAPIResponse } from './payment.types';

export const getPaymentConfirmationDetail = async (
  host: string,
  merchantId: string,
): Promise<PaymentConfirmationAPIResponse> => {
  const options = await getFetchOptions();

  return await fetch(`${host}/merchants/${merchantId}/settings/order-notifications`, options).then(handleResponse);
};

export const updatePaymentConfirmation = async (
  host: string,
  merchantId: string,
  payload: PaymentConfirmationAPIResponse,
): Promise<PaymentConfirmationAPIResponse> => {
  const options = await getFetchOptions('POST', { payload });

  return await fetch(`${host}/merchants/${merchantId}/settings/order-notifications`, options).then(handleResponse);
};

export const getPaymentSourceDetail = async (
  host: string,
  merchantId: string,
): Promise<CheckoutApplePayAPIResponse> => {
  const options = await getFetchOptions();

  return await fetch(`${host}/payments/apple/web-merchant-details-jwt/${merchantId}`, options).then(handleResponse);
};

export const updatePaymentSourceDomains = async (
  host: string,
  merchantId: string,
  payload: Array<string>,
): Promise<void> => {
  const options = await getFetchOptions('POST', { payload });

  return await fetch(`${host}/payments/apple/web-merchant-details-jwt/${merchantId}`, options).then(handleResponse);
};

export const deletePaymentSourceDomains = async (
  host: string,
  merchantId: string,
  payload: Array<string>,
): Promise<void> => {
  const options = await getFetchOptions('DELETE', { payload });

  return await fetch(`${host}/payments/apple/web-merchant-details-jwt/${merchantId}`, options).then(handleResponse);
};
