import { createMerchantExternalBankAccount, getAuBankBranch, getNzBankBranch } from 'api/merchant/onbording/step-04';
import * as s from 'assets/styles/Platforms.styles';
import { Formik, FormikProps } from 'formik';
import { CreateMerchantExternalBankAccountPayload } from 'pages/merchant/onboarding/step-04/types';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppSelector } from 'redux/merchant/hooks';
import { CountryCurrency } from 'utils/currency';
import getErrorMessage from 'utils/getErrorMessage';
import * as Yup from 'yup';

import { Button, Heading, Icon, Message, Modal, Spinner, Text, TextField } from '@limepayments/cosmic';

const validationSchema = Yup.object().shape({
  accountName: Yup.string().trim().required('This field is required'),
  accountNumber: Yup.string()
    .trim()
    .required('This field is required')
    .matches(/^[0-9]+$/, 'Account number should only contain digits'),
  routingNumber: Yup.string()
    .trim()
    .when('country', {
      is: 'AU',
      then: Yup.string().required('This field is required'),
    }),
});

interface Props {
  isOpen: boolean;
  modalToggler: (val: boolean) => void;
  refreshDataHandler: () => void;
}

function BankDetailEditModal({ isOpen, modalToggler, refreshDataHandler }: Props) {
  const errorContainerRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<FormikProps<CreateMerchantExternalBankAccountPayload>>(null);
  const [bankDetailFetchLoader, setBankDetailFetchLoader] = useState<boolean>(false);
  const [bankNameErrorMsg, setBankNameErrorMsg] = useState<string>('');
  const [bankName, setBankName] = useState<string>('');
  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { merchantTaxCountry, apiBaseUri, merchantId } = useAppSelector((state) => ({
    merchantTaxCountry: state.config.merchantTaxCountry,
    apiBaseUri: state.config.apiBaseUri,
    merchantId: state.config.merchantId,
  }));

  const [initialValues] = useState<CreateMerchantExternalBankAccountPayload>({
    accountName: '',
    accountNumber: '',
    country: merchantTaxCountry,
    currency: CountryCurrency[merchantTaxCountry],
    routingNumber: '',
    isSaveFinishLater: false,
  });

  const fetchNzBankHandler = async (bankAccountNo: string) => {
    try {
      setBankNameErrorMsg('');
      setBankDetailFetchLoader(true);

      const nzAccountPrefix = bankAccountNo.length > 6 ? bankAccountNo.slice(0, 6) : bankAccountNo;

      const response = await getNzBankBranch(apiBaseUri, nzAccountPrefix);
      if (response.branch) {
        setBankName(response.branch.bankName);
      } else {
        setBankName('');
        setBankNameErrorMsg('Account number is invalid');
      }
    } catch (error) {
      setBankNameErrorMsg(getErrorMessage(error));
    } finally {
      setBankDetailFetchLoader(false);
    }
  };

  const fetchAuBankHandler = async (bsb: string) => {
    try {
      if (!bsb) {
        setBankName('');
        return;
      }

      setBankNameErrorMsg('');
      setBankDetailFetchLoader(true);

      const response = await getAuBankBranch(apiBaseUri, bsb);
      if (response.branch) {
        setBankName(response.branch.bankName);
      } else {
        setBankName('');
        setBankNameErrorMsg('BSB is invalid');
      }
    } catch (error) {
      setBankNameErrorMsg(getErrorMessage(error));
    } finally {
      setBankDetailFetchLoader(false);
    }
  };

  const submitHandler = async (
    values: CreateMerchantExternalBankAccountPayload,
    formObj: { resetForm: () => void },
  ) => {
    try {
      setIsRequestLoading(true);
      setErrorMessage('');

      const { isSaveFinishLater, ...postData } = { ...values };

      if (!bankName) {
        setErrorMessage('No valid bank found');
        return false;
      }

      await createMerchantExternalBankAccount(apiBaseUri, merchantId, postData);

      toast.success('Bank account detail updated successfully');
      formObj.resetForm();
      modalToggler(false);
      refreshDataHandler();
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setIsRequestLoading(false);
    }
  };

  useEffect(() => {
    if (errorMessage.length > 0 && errorContainerRef && errorContainerRef.current) {
      errorContainerRef.current.scrollIntoView();
    }
  }, [errorMessage]);

  useEffect(() => {
    if (isOpen) {
      setErrorMessage('');
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} className="modal-medium update-pass-modal">
      <s.Wrapper className="">
        <div className="modal-main p-24">
          <div className="modal-header">
            <div className="content-main bb-1">
              <Heading alignment="center" tagName="h2" variant="xs" className="mt-12 mb-24 lp-justify-center lp-flex">
                Update bank account
              </Heading>
            </div>
          </div>

          <Formik<CreateMerchantExternalBankAccountPayload>
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={(values, formObj: { resetForm: () => void }) => {
              submitHandler(values, formObj);
            }}
            validationSchema={validationSchema}
            innerRef={formRef}
          >
            {({ values, handleSubmit, errors, touched, handleBlur, handleChange }) => (
              <form autoComplete="off" onSubmit={handleSubmit} className="content-wrap-form">
                <div className="modal-content p-28 sm-p-0 sm-mt-24">
                  {errorMessage.length > 0 && (
                    <div className="mt-24 mb-24" ref={errorContainerRef}>
                      <Message
                        type="inline"
                        children={<span className="text-wrap">{errorMessage}</span>}
                        variant={'error'}
                      />
                    </div>
                  )}
                  {initialValues.country !== 'NZ' && (
                    <div className="lp-w-full mb-24">
                      <TextField
                        fullWidth
                        label="BSB"
                        inputMode="text"
                        autoComplete="off"
                        id="routingNumber"
                        name="routingNumber"
                        onBlur={(e) => {
                          handleBlur(e);
                          fetchAuBankHandler(e.target.value);
                        }}
                        value={values.routingNumber}
                        onChange={(e) => {
                          const value = e.target.value;
                          const newValue = value ? value.replace(/[^0-9]+/g, '') : '';
                          e.target.value = newValue;
                          handleChange(e);
                        }}
                        error={errors.routingNumber && touched.routingNumber ? errors.routingNumber : ''}
                      />

                      {(bankName || bankDetailFetchLoader || bankNameErrorMsg) && (
                        <div className="lp-w-full lp-flex lp-align-center pl-16 pr-16">
                          {bankDetailFetchLoader && <Spinner isVisible variant="simple" label="" />}
                          {!bankDetailFetchLoader && !bankNameErrorMsg && <Icon name="Tick" className="mr-4" />}
                          {bankName && (
                            <Text tagName="p" variant="caption" className="m-0">
                              {bankName}
                            </Text>
                          )}

                          {bankNameErrorMsg && (
                            <Text alignment="left" variant="caption" className="alert alert-danger">
                              {bankNameErrorMsg}
                            </Text>
                          )}
                        </div>
                      )}
                    </div>
                  )}

                  <div className="lp-w-full mb-24">
                    <TextField
                      fullWidth
                      label="Account number"
                      inputMode="text"
                      autoComplete="off"
                      id="accountNumber"
                      name="accountNumber"
                      onBlur={(e) => {
                        handleBlur(e);
                        if (initialValues.country === 'NZ' && e.target.value.length > 5) {
                          fetchNzBankHandler(e.target.value);
                        }
                      }}
                      onChange={(e) => {
                        const value = e.target.value;
                        const newValue = value ? value.replace(/[^0-9]+/g, '') : '';
                        e.target.value = newValue;
                        handleChange(e);
                      }}
                      value={values.accountNumber}
                      error={errors.accountNumber && touched.accountNumber ? errors.accountNumber : ''}
                    />

                    {initialValues.country === 'NZ' && (bankName || bankDetailFetchLoader || bankNameErrorMsg) && (
                      <div className="lp-flex lp-items-center custom-message">
                        {bankDetailFetchLoader && <Spinner isVisible variant="simple" label="" />}
                        {!bankDetailFetchLoader && !bankNameErrorMsg && <Icon name="Tick" className="mr-4" />}
                        {bankName && (
                          <Text tagName="p" variant="caption" className="m-0">
                            {bankName}
                          </Text>
                        )}

                        {bankNameErrorMsg && (
                          <Text alignment="left" variant="caption" className="alert alert-danger">
                            {bankNameErrorMsg}
                          </Text>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="lp-w-full mb-16">
                    <TextField
                      fullWidth
                      label="Account name"
                      inputMode="text"
                      autoComplete="off"
                      id="accountName"
                      name="accountName"
                      onBlur={handleBlur}
                      value={values.accountName}
                      onChange={handleChange}
                      error={errors.accountName && touched.accountName ? errors.accountName : ''}
                    />
                  </div>
                </div>
                <div className="modal-footer lp-flex lp-justify-end sm-mt-12">
                  <Button
                    onClick={() => modalToggler(false)}
                    type="button"
                    size="medium"
                    variant="ghost"
                    className="no-min-width w-70"
                    disabled={isRequestLoading}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size="medium"
                    variant="primary"
                    className="no-min-width"
                    disabled={isRequestLoading}
                    isLoading={isRequestLoading}
                  >
                    Update
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </s.Wrapper>
    </Modal>
  );
}

export default BankDetailEditModal;
