import { SettingPayoutExternalAccountResponse, SettingPayoutResponse } from 'api/platform/setting.types';
import * as s from 'assets/styles/Platforms.styles';
import SettingsSidebar from 'layouts/platform/SettingsSidebar';
import { Fragment, useState } from 'react';

import { Button, Heading, Icon, LabelledValue, Message, Spinner, Text } from '@limepayments/cosmic';

import BankDetailEditModal from './partials/BankDetailEditModal';
import PayoutDescriptionModal from './partials/PayoutDescriptionModal';
import PayoutReportModal from './partials/PayoutReportModal';
import PayoutScheduleModal from './partials/PayoutScheduleModal';
import { SettingBrandingDetailPayload } from './types';

export interface Props {
  payoutSchedule: string;
  payoutExternalAccount: Array<SettingPayoutExternalAccountResponse>;
  settingPayoutDetail: SettingPayoutResponse;
  refreshDataHandler: () => void;
  settingPayoutFetchHandler: () => void;
  fetchSettingPayoutLoader: boolean;
  payoutScheduleLoader: boolean;
  payoutExternalAccountLoader: boolean;
  errorMessage: string;
  fetchBrandingDetailLoader: boolean;
  brandingDetail: SettingBrandingDetailPayload;
  fetchBrandingDetail: () => void;
  bankDetailFetchHandler: () => void;
  payoutModalOpenProp?: boolean;
  bankEditModalOpenProp?: boolean;
  payoutReportModalOpenProp?: boolean;
  payoutDescriptionModalOpenProp?: boolean;
}

function BusinessProfile({
  payoutSchedule,
  payoutExternalAccount,
  settingPayoutDetail,
  refreshDataHandler,
  settingPayoutFetchHandler,
  fetchSettingPayoutLoader,
  payoutScheduleLoader,
  payoutExternalAccountLoader,
  errorMessage,
  brandingDetail,
  fetchBrandingDetailLoader,
  fetchBrandingDetail,
  bankDetailFetchHandler,
}: Props) {
  const [payoutModalOpen, setPayoutModalOpen] = useState<boolean>(false);
  const [bankEditModalOpen, setBankEditModalOpen] = useState<boolean>(false);
  const [payoutReportModalOpen, setPayoutReportModalOpen] = useState<boolean>(false);
  const [payoutDescriptionModalOpen, setPayoutDescriptionModalOpen] = useState<boolean>(false);

  return (
    <>
      <div className="content-wrap-inner with-gutter">
        <div className="lp-w-full lp-flex">
          <div className="payment-sidebar">
            <SettingsSidebar activeTab="payouts" />
          </div>

          <div className="payment-content-area">
            <div className="lp-w-full lp-flex lp-justify-between sm-lp-flex-col mb-32">
              <Heading alignment="left" tagName="h2" variant="xl" className="page-title mb-0">
                Payouts
              </Heading>
            </div>

            <s.MerchantsData className="bt-1">
              {errorMessage.length > 0 ? (
                <div className="mb-24">
                  <Message children={errorMessage} type={'inline'} variant={'error'} />
                </div>
              ) : null}
              <div className="lp-w-full lp-flex lp-align-center lp-flex-row mb-32 sm-lp-align-start">
                <Text alignment="left" tagName="p" variant="body-1" isEmphasised className="text-dark-50 mb-0">
                  Payout frequency
                  <Text variant="caption" className="mt-12">
                    Set the frequency to automatically receive payouts.
                  </Text>
                </Text>
                {!payoutScheduleLoader && (
                  <div className="ml-auto">
                    <Button
                      size="small"
                      onClick={() => setPayoutModalOpen(!payoutModalOpen)}
                      variant="reversed"
                      className="no-min-width w-70"
                    >
                      Edit
                      <Icon name="Edit" />
                    </Button>
                  </div>
                )}
              </div>

              {payoutScheduleLoader && (
                <div className="lp-w-full mb-0">
                  <Spinner variant="simple" isVisible label="Loading..." />
                </div>
              )}

              {!payoutScheduleLoader && (
                <div className="lp-w-full mb-0">
                  <LabelledValue
                    label="Frequency"
                    value={payoutSchedule.charAt(0).toUpperCase() + payoutSchedule.slice(1)}
                    variant="vertical"
                  />
                </div>
              )}
            </s.MerchantsData>

            <s.MerchantsData className="bt-0">
              <div className="lp-w-full lp-flex-row mb-32">
                <div className="lp-w-full lp-flex lp-align-center lp-flex-row mb-0 sm-lp-align-start">
                  <div className="lp-w-full lp-flex lp-flex-column">
                    <Text alignment="left" tagName="p" variant="body-1" isEmphasised className="text-dark-50 mb-0">
                      Bank account details
                    </Text>
                  </div>
                  {!payoutExternalAccountLoader && (
                    <div className="ml-auto">
                      <Button
                        size="small"
                        onClick={() => setBankEditModalOpen(!bankEditModalOpen)}
                        variant="reversed"
                        className="no-min-width w-70"
                      >
                        Update
                        <Icon name="Edit" />
                      </Button>
                    </div>
                  )}
                </div>
                <div className="lp-w-full lp-flex lp-align-center lp-flex-row mb-32 sm-lp-align-start">
                  <Text variant="caption" className="text-dark-50 mb-0 mt-4">
                    The bank account your payouts are deposited to.
                  </Text>
                </div>
              </div>

              {payoutExternalAccountLoader && (
                <div className="lp-w-full mb-0">
                  <Spinner variant="simple" isVisible label="Loading..." />
                </div>
              )}

              {!payoutExternalAccountLoader && (
                <div className="lp-w-full mb-0">
                  <LabelledValue
                    label="Bank account"
                    value={
                      payoutExternalAccount.length > 0 ? `*******${payoutExternalAccount[0].accountNumberLast4}` : ``
                    }
                    variant="vertical"
                  />
                </div>
              )}
            </s.MerchantsData>

            <s.MerchantsData className="bt-0">
              <div className="lp-w-full lp-flex-row mb-32">
                <div className="lp-w-full lp-flex lp-align-center lp-flex-row mb-0 sm-lp-align-start">
                  <div className="lp-w-full lp-flex lp-flex-column">
                    <Text alignment="left" tagName="p" variant="body-1" isEmphasised className="text-dark-50 mb-0">
                      Payout reports
                    </Text>
                  </div>

                  {!fetchSettingPayoutLoader && (
                    <div className="ml-auto">
                      <Button
                        size="small"
                        onClick={() => setPayoutReportModalOpen(!payoutReportModalOpen)}
                        variant="reversed"
                        className="no-min-width w-70"
                      >
                        Edit
                        <Icon name="Edit" />
                      </Button>
                    </div>
                  )}
                </div>
                <div className="lp-w-full lp-flex lp-align-center lp-flex-row mb-32 sm-lp-align-start">
                  <Text variant="caption" className="text-dark-50 mb-0 mt-4">
                    After each payout is processed, a payout report will be sent to nominated emails.
                  </Text>
                </div>
              </div>

              {fetchSettingPayoutLoader && (
                <div className="lp-w-full mb-0">
                  <Spinner variant="simple" isVisible label="Loading..." />
                </div>
              )}

              {!fetchSettingPayoutLoader && (
                <Fragment>
                  <div className="lp-w-full mb-32">
                    <LabelledValue
                      label="Receive reports via email"
                      value={settingPayoutDetail.notificationsEnabled ? `Enabled` : `Disabled`}
                      variant="vertical"
                    />
                  </div>
                  <div className="lp-w-full mb-0">
                    <LabelledValue label="Recipients" value="" variant="vertical" />
                    {settingPayoutDetail.notificationEmails.map((email, key) => (
                      <Text variant="body-3" className="mt-0" key={key}>
                        {email}
                      </Text>
                    ))}
                  </div>
                </Fragment>
              )}
            </s.MerchantsData>

            <s.MerchantsData className="bt-0">
              <div className="lp-w-full lp-flex-row mb-32">
                <div className="lp-w-full lp-flex lp-align-center lp-flex-row mb-0 sm-lp-align-start">
                  <div className="lp-w-full lp-flex lp-flex-column">
                    <Text alignment="left" tagName="p" variant="body-1" isEmphasised className="text-dark-50 mb-0">
                      Payout description
                    </Text>
                  </div>
                  {!fetchBrandingDetailLoader && (
                    <div className="ml-auto">
                      <Button
                        size="small"
                        onClick={() => setPayoutDescriptionModalOpen(!payoutReportModalOpen)}
                        variant="reversed"
                        className="no-min-width w-70"
                      >
                        Edit
                        <Icon name="Edit" />
                      </Button>
                    </div>
                  )}
                </div>
                <div className="lp-w-full lp-flex lp-align-center lp-flex-row mb-32 sm-lp-align-start">
                  <Text variant="caption" className="text-dark-50 mb-0 mt-4">
                    The description of bank statements in merchants payouts.
                  </Text>
                </div>
              </div>

              {fetchBrandingDetailLoader && (
                <div className="lp-w-full mb-0">
                  <Spinner variant="simple" isVisible label="Loading..." />
                </div>
              )}

              {!fetchBrandingDetailLoader && (
                <div className="lp-w-full mb-0">
                  <LabelledValue
                    label="Payout description"
                    value={brandingDetail.payoutSettings.payoutDescription}
                    variant="vertical"
                  />
                </div>
              )}
            </s.MerchantsData>
          </div>
        </div>
      </div>

      <PayoutScheduleModal
        isOpen={payoutModalOpen}
        modalToggler={setPayoutModalOpen}
        payoutValue={payoutSchedule}
        refreshDataHandler={refreshDataHandler}
      />

      <BankDetailEditModal
        isOpen={bankEditModalOpen}
        modalToggler={setBankEditModalOpen}
        refreshDataHandler={bankDetailFetchHandler}
      />

      <PayoutReportModal
        isOpen={payoutReportModalOpen}
        modalToggler={setPayoutReportModalOpen}
        settingPayoutDetail={JSON.parse(JSON.stringify(settingPayoutDetail))}
        refreshDataHandler={settingPayoutFetchHandler}
      />

      <PayoutDescriptionModal
        isOpen={payoutDescriptionModalOpen}
        modalToggler={setPayoutDescriptionModalOpen}
        brandingDetail={JSON.parse(JSON.stringify(brandingDetail))}
        refreshDataHandler={fetchBrandingDetail}
      />
    </>
  );
}

export default BusinessProfile;
