import * as s from 'assets/styles/Onboarding.styles';

import { Button, Heading, Icon, Illustration, Text } from '@limepayments/cosmic';

interface Props {
  onSubmitClick: () => void;
  merchantName?: string;
  merchantCountry: string;
}

function IntroductionView({ onSubmitClick, merchantName, merchantCountry }: Props) {
  return (
    <>
      <div className="content-wrap-inner">
        <header className="content-header">
          <Heading alignment="left" tagName="h2" variant="md" className="mb-24">
            Verify your business details
          </Heading>
          <Heading alignment="left" tagName="h4" variant="sm">
            Introduction
          </Heading>
          <Text tagName="p" variant="body-3">
            You can start processing digital payments when you complete this form and your business is verified by
            April.
          </Text>
          <Text tagName="p" variant="body-3">
            {merchantName ? `${merchantName}'s payment service provider, ` : null}April collect and store the
            information you provide for legal and compliance purposes in accordance with April's{' '}
            <a
              href="https://meetapril.com/privacy/"
              target="_blank"
              className="link-primary fw-medium"
              rel="noreferrer"
            >
              Group Privacy Policy
            </a>
            .
          </Text>
          <s.Separator>
            <hr />
          </s.Separator>
        </header>

        <div className="content-main">
          <div className="lp-w-full mb-32">
            <Heading alignment="left" tagName="h2" variant="sm">
              What to expect
            </Heading>
            <Text tagName="p" variant="body-3" className="mb-12">
              You will be required to provide the following information for your business.
            </Text>
            <ol className="ordered-lists">
              <li>
                <Text tagName="p" variant="body-3">
                  Business registration details
                </Text>
              </li>
              <li>
                <Text tagName="p" variant="body-3">
                  Branding details
                </Text>
              </li>
              <li>
                <Text tagName="p" variant="body-3">
                  Business contact details
                </Text>
              </li>
              <li>
                <Text tagName="p" variant="body-3">
                  Business bank account details
                </Text>
              </li>
              <li>
                <Text tagName="p" variant="body-3">
                  Business owners and directors
                </Text>
              </li>
            </ol>
            <Text tagName="p" variant="body-3" className="mb-12">
              You can save your progress at any point in the form and return to complete it later. All fields are
              mandatory unless marked otherwise.
            </Text>
          </div>

          <div className="lp-w-full lp-flex lp-items-center mb-32">
            <Illustration name="Time" size="small" />
            <Text tagName="p" variant="body-3" className="mb-0 pl-12">
              Approximately 10 minutes to complete.
            </Text>
          </div>
          <s.Separator>
            <hr />
          </s.Separator>

          <div className="lp-w-full mb-32">
            <Heading alignment="left" tagName="h3" variant="sm" className="mb-16">
              Details to have handy
            </Heading>
            <ol className="check-lists">
              <li>
                <Text tagName="p" variant="body-3" testId="introFirstPoint">
                  The business' or trustee’s tax ID ({merchantCountry === `NZ` ? `NZBN` : `e.g. ABN, ACN`}).
                </Text>
              </li>
              <li>
                <Text tagName="p" variant="body-3">
                  The legal entity name.
                </Text>
              </li>
              <li>
                <Text tagName="p" variant="body-3">
                  A certified copy of the partnership agreement if the business is a partnership or has a partnership
                  that owns 25% or more of the business.
                </Text>
              </li>
              <li>
                <Text tagName="p" variant="body-3">
                  A certified copy of the partnership agreement if the business is a partnership.
                </Text>
              </li>
              <li>
                <Text tagName="p" variant="body-3">
                  A certified copy of the trust deed if the business is a trust or has a trust that owns 25% or more of
                  the business.
                </Text>
              </li>
              <li>
                <Text tagName="p" variant="body-3">
                  Identification documents (e.g. drivers licence or passport) of all individuals who are beneficial
                  owners of the business, if applicable.
                </Text>
                <div className="check-lists-icon">
                  <span
                    data-balloon="A beneficial owner is someone who owns 25% or more of the business directly or indirectly."
                    data-balloon-pos="up"
                    data-balloon-mobile-pos="left"
                    data-balloon-length="large"
                    className="lp-flex lp-align-center lp-justify-center size-11"
                  >
                    <Icon name="Info" />
                  </span>
                </div>
              </li>
              <li>
                <Text tagName="p" variant="body-3">
                  Identification documents of all directors of the business, if applicable.
                </Text>
                <div className="check-lists-icon">
                  <span
                    data-balloon="A director is someone that is responsible for directing (and, in smaller companies, managing) the affairs of a company. A director is anyone who acts in this capacity - even if they have not been formally appointed."
                    data-balloon-pos="up"
                    data-balloon-mobile-pos="left"
                    data-balloon-length="large"
                    className="lp-flex lp-align-center lp-justify-center size-11"
                  >
                    <Icon name="Info" />
                  </span>
                </div>
              </li>
            </ol>
          </div>
          <s.Separator>
            <hr />
          </s.Separator>
        </div>

        <div className="lp-w-full mb-32">
          <Heading alignment="left" tagName="h3" variant="sm" className="mb-16">
            What happens next
          </Heading>
          <Text tagName="p" variant="body-2">
            Your details will be verified and we may contact you for more information. The timeframe for this process
            will depend on your business type and the information that you provide to us.
          </Text>
        </div>

        <div className="two-col sm:flex-col">
          <div className="lp-flex lp-items-center sm:lp-full sm:mb-24">
            <Illustration name="Calendar" size="small" />
            <div className="pl-12">
              <Heading alignment="left" tagName="h5" variant="xxs">
                Sole trader or Company
              </Heading>
              <Text tagName="p" variant="body-3">
                Up to 1 business day
              </Text>
            </div>
          </div>
          <div className="lp-flex lp-items-center sm:lp-full">
            <Illustration name="Calendar" size="small" />
            <div className="pl-12">
              <Heading alignment="left" tagName="h5" variant="xxs">
                Partnership or Trust
              </Heading>
              <Text tagName="p" variant="body-3">
                Up to 1-2 weeks
              </Text>
            </div>
          </div>
        </div>

        <s.Cta className="lp-flex lp-justify-end lp-items-center">
          <Button
            onClick={onSubmitClick}
            size="medium"
            variant="primary"
            className="no-min-width"
            testId="stepStartButton"
            // disabled
          >
            Start
          </Button>
        </s.Cta>
      </div>
    </>
  );
}

export default IntroductionView;
