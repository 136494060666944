import {
  GetListMerchantCompanyOwnersObj,
  GetListMerchantPartnershipOwnersObj,
  GetListMerchantPersonObj,
  GetListMerchantTrustOwnersObj,
} from 'api/merchant/onbording/step-05.types';
import * as s from 'assets/styles/Onboarding.styles';

import CompanyContent from './CompanyContent';
import IndividualContent from './IndividualContent';
import PartnershipContent from './PartnershipContent';
import TrustContent from './TrustContent';

interface Props {
  hasOwner: boolean;
  merchantPersonList: Array<GetListMerchantPersonObj>;
  merchantCompanyOwnersList: Array<GetListMerchantCompanyOwnersObj>;
  merchantPartnershipOwnersList: Array<GetListMerchantPartnershipOwnersObj>;
  merchantTrustOwnersList: Array<GetListMerchantTrustOwnersObj>;
  showTitle: boolean;
}

const BusinessRepresentativeDetails = ({
  hasOwner,
  merchantPersonList,
  merchantCompanyOwnersList,
  merchantPartnershipOwnersList,
  merchantTrustOwnersList,
  showTitle,
}: Props) => {
  return (
    <>
      {merchantPersonList.map((merchantPersonObj, key) => (
        <IndividualContent
          hasOwner={hasOwner}
          key={key}
          title={showTitle ? (hasOwner ? `Individual` : `Director ${key + 1}`) : ``}
          data={merchantPersonObj}
          showTitle={showTitle}
        />
      ))}

      {merchantTrustOwnersList.map((merchantTrustOwnerObj, key) => (
        <TrustContent key={key} title="Trust" data={merchantTrustOwnerObj} />
      ))}

      {merchantPartnershipOwnersList.map((merchantPartnershipOwnerObj, key) => (
        <PartnershipContent key={key} title="Partnership" data={merchantPartnershipOwnerObj} />
      ))}

      {merchantCompanyOwnersList.map((merchantCompanyOwnerObj, key) => (
        <CompanyContent key={key} title="Company" data={merchantCompanyOwnerObj} />
      ))}

      <s.Separator>
        <hr />
      </s.Separator>
    </>
  );
};

export default BusinessRepresentativeDetails;
