import * as s from 'assets/styles/Onboarding.styles';
import { Fragment } from 'react';

import { Button, Icon, RadioGroup } from '@limepayments/cosmic';

interface Props {
  isOpen: boolean;
  toggleModal: (val: boolean) => void;
  setCurrentStep: (val: number) => void;
  setSelectedOwnerType: (val: string) => void;
  selectedOwnerType: string;
}

function SelectOwnerSection({ setCurrentStep, toggleModal, isOpen, setSelectedOwnerType, selectedOwnerType }: Props) {
  return (
    <Fragment>
      <div className="modal-content">
        {/* Step 01 */}
        <div className="lp-w-full" style={{ display: 'block' }}>
          <div className="lp-w-full lp-equal-width mb-24">
            <div className="w-180">
              <RadioGroup
                defaultValue={selectedOwnerType}
                header="Owner type"
                data-testid="ownerType"
                description="Select the type of entity that owns 25% or more of the business."
                name="ownership"
                onChange={(value) => setSelectedOwnerType(value)}
                orientation="vertical"
                radios={[
                  {
                    text: 'Individual',
                    value: 'individual',
                  },
                  {
                    text: 'Company',
                    value: 'company',
                  },
                  {
                    text: 'Partnership',
                    value: 'partnership',
                  },
                  {
                    text: 'Trust',
                    value: 'trust',
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="modal-footer">
        <s.MultiColumnRespBlock className="lp-w-full lp-justify-between">
          <Button
            onClick={() => toggleModal(!isOpen)}
            size="medium"
            variant="ghost"
            testId="ownerTypeCancel"
            className="no-min-width"
          >
            Cancel
          </Button>
          <div className="lp-flex">
            <Button
              onClick={() => setCurrentStep(2)}
              size="medium"
              variant="primary"
              className="no-min-width"
              testId="ownerTypeSubmit"
              disabled={!selectedOwnerType ? true : false}
            >
              Next
              <Icon name="ArrowRight" />
            </Button>
          </div>
        </s.MultiColumnRespBlock>
      </div>
    </Fragment>
  );
}

export default SelectOwnerSection;
