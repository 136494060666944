import { getMerchant, getMerchantGeneralSettings, updateMerchant } from 'api/merchant/dashboard/merchants';
import { getStandardMerchantInitValues } from 'api/merchant/dashboard/merchants.init.values';
import { MerchantGeneralSettingsResponse, StandardMerchant } from 'api/merchant/dashboard/merchants.types';
import { FormikProps } from 'formik';
import MerchantDashboardLayout from 'layouts/merchant-dashboard/MerchantDashboardLayout';
import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppSelector } from 'redux/merchant/hooks';
import getErrorMessage from 'utils/getErrorMessage';

import BusinessProfileSettingView from './partials/BusinessProfileSettingView';
import { toUpdateMerchantBusinessProfileRequest } from './partials/types';
import { BusinessProfileModalForm } from './partials/types';

function BusinessProfileSettingPage() {
  const [merchantLoader, setMerchantLoader] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [updateErrorMsg, setUpdateErrorMsg] = useState<string>('');
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [editRequestLoading, setEditRequestLoading] = useState<boolean>(false);

  const [merchantResponse, setMerchantResponse] = useState<StandardMerchant>(() => getStandardMerchantInitValues());
  const [generalSettings, setGeneralSettings] = useState<MerchantGeneralSettingsResponse>({
    supportedCustomerTypes: [],
  });

  const formRef = useRef<FormikProps<BusinessProfileModalForm>>(null);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

  const { merchantId } = useAppSelector((state) => ({
    merchantId: state.config.merchantId,
  }));

  const submitHandler = async (values: BusinessProfileModalForm, formObj: { resetForm: () => void }) => {
    try {
      setEditRequestLoading(true);
      setUpdateErrorMsg('');

      const merchantBusinessProfile = toUpdateMerchantBusinessProfileRequest(values, merchantResponse);

      const response = await updateMerchant(merchantId, merchantBusinessProfile);
      if ('StandardMerchant' in response) {
        setMerchantResponse(response);
      } else {
        throw new Error('Unexpected error occurred');
      }

      toast.success('Business profile updated successfully');
      formObj.resetForm();
      setEditModalOpen(false);
    } catch (error) {
      setUpdateErrorMsg(getErrorMessage(error));
    } finally {
      setEditRequestLoading(false);
    }
  };

  const fetchMerchant = useCallback(async () => {
    try {
      setMerchantLoader(true);

      const [merchant, generalSettings] = await Promise.all([
        getMerchant(merchantId),
        getMerchantGeneralSettings(merchantId),
      ]);

      if ('StandardMerchant' in merchant) setMerchantResponse(merchant);
      setGeneralSettings(generalSettings);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setMerchantLoader(false);
    }
  }, [merchantId]);

  useEffect(() => {
    setErrorMessage('');
    fetchMerchant();
  }, [merchantId, fetchMerchant]);

  useEffect(() => {
    if (!merchantLoader) {
      setIsPageLoading(false);
    }
  }, [merchantLoader, setIsPageLoading]);

  return (
    <MerchantDashboardLayout activeTab="settings" title="Business Settings">
      <BusinessProfileSettingView
        errorMsg={updateErrorMsg}
        editRequestLoading={editRequestLoading}
        merchantDetails={merchantResponse}
        generalSettings={generalSettings}
        onSubmitClick={submitHandler}
        formRef={formRef}
        setEditModalOpen={setEditModalOpen}
        editModalOpen={editModalOpen}
        isPageLoading={isPageLoading}
        errorMessage={errorMessage}
        isManualEnterAddress={true}
      />
    </MerchantDashboardLayout>
  );
}

export default BusinessProfileSettingPage;
