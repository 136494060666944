import { AccountInterface, PayoutReportsAPIResponse } from 'api/merchant/dashboard/settings/payouts.types';
import ErrorComponent from 'pages/common/error/ErrorComponent';
import { Fragment, useState } from 'react';

import { Button, Heading, Icon, LabelledValue, Spinner, Text } from '@limepayments/cosmic';

import * as s from '../../../../../../assets/styles/Platforms.styles';
import SettingRoutesList from '../../constants';
// Import Static UIs
import SettingsSidebar from '../../partials/SettingsSidebar';
import BankDetailEditModal from './BankDetailEditModal';
import PayoutReportModal from './PayoutReportModal';
import PayoutScheduleModal from './PayoutScheduleModal';

export interface Props {
  payoutReports: PayoutReportsAPIResponse;
  payoutBankAccounts: AccountInterface;
  payoutFrequency: string;
  isPageLoading: boolean;
  payoutReportsFetchLoader: boolean;
  payoutBankAccountsFetchLoader: boolean;
  payoutFrequencyFetchLoader: boolean;
  errorMessage: string;
  fetchPayoutFrequency: () => void;
  fetchPayoutReports: () => void;
  fetchPayoutBankAccounts: () => void;
  reportModalOpen?: boolean;
  scheduleModalOpen?: boolean;
  bankModalOpen?: boolean;
}

function PayoutSettingView({
  payoutReports,
  payoutBankAccounts,
  payoutFrequency,
  isPageLoading,
  payoutReportsFetchLoader,
  payoutBankAccountsFetchLoader,
  payoutFrequencyFetchLoader,
  errorMessage,
  fetchPayoutFrequency,
  fetchPayoutReports,
  fetchPayoutBankAccounts,
  reportModalOpen,
  scheduleModalOpen,
  bankModalOpen,
}: Props) {
  const [payoutReportModalOpen, setPayoutReportModalOpen] = useState<boolean>(reportModalOpen ?? false);
  const [payoutScheduleModalOpen, setPayoutScheduleModalOpen] = useState<boolean>(scheduleModalOpen ?? false);
  const [bankDetailEditModalOpen, setBankDetailEditModalOpen] = useState<boolean>(bankModalOpen ?? false);

  return (
    <Fragment>
      <div className="content-wrap-inner with-gutter">
        <div className="lp-w-full lp-flex">
          <div className="payment-sidebar">
            <SettingsSidebar activeTab={SettingRoutesList.SETTINGS_BALANCES} />
          </div>

          <div className="payment-content-area">
            {!isPageLoading && errorMessage.length ? <ErrorComponent bodyText={errorMessage} /> : null}

            {isPageLoading && (
              <div className="spinner-wrapper">
                <Spinner variant="simple" isVisible label="Loading..." />
              </div>
            )}

            {!isPageLoading && !errorMessage.length && (
              <Fragment>
                <div className="lp-w-full lp-flex lp-justify-between sm-lp-flex-col mb-32">
                  <Heading alignment="left" tagName="h2" variant="sm">
                    Balances
                  </Heading>
                </div>

                <s.MerchantsData className="bt-1">
                  <div className="lp-w-full lp-flex lp-align-start lp-justify-between mb-32">
                    <div>
                      <Heading alignment="left" tagName="h2" variant="xs">
                        Payout frequency
                      </Heading>
                      <Text variant="body-3" className="mt-4">
                        Set the frequency to automatically receive payouts.
                      </Text>
                    </div>
                    <div className="ml-auto">
                      {!payoutFrequencyFetchLoader && (
                        <Button
                          size="small"
                          variant="reversed"
                          onClick={() => setPayoutScheduleModalOpen(!payoutScheduleModalOpen)}
                        >
                          Edit
                          <Icon name="Edit" />
                        </Button>
                      )}
                    </div>
                  </div>

                  {payoutFrequencyFetchLoader && (
                    <div className="spinner-wrapper">
                      <Spinner variant="simple" isVisible label="Loading..." />
                    </div>
                  )}

                  {!payoutFrequencyFetchLoader && (
                    <div className="lp-w-full mb-32">
                      <LabelledValue
                        label="Frequency"
                        value={payoutFrequency.charAt(0).toUpperCase() + payoutFrequency.slice(1)}
                        variant="vertical"
                      />
                    </div>
                  )}
                </s.MerchantsData>

                <s.MerchantsData className="bt-1">
                  <div className="lp-w-full lp-flex lp-align-start lp-justify-between mb-32">
                    <div>
                      <Heading alignment="left" tagName="h2" variant="xs">
                        Bank account details
                      </Heading>
                      <Text variant="body-3" className="mt-4">
                        The bank account your payouts are deposited to.
                      </Text>
                    </div>
                    <div className="ml-auto">
                      {!payoutBankAccountsFetchLoader && (
                        <Button
                          size="small"
                          variant="reversed"
                          onClick={() => setBankDetailEditModalOpen(!bankDetailEditModalOpen)}
                        >
                          Update
                          <Icon name="Edit" />
                        </Button>
                      )}
                    </div>
                  </div>

                  {payoutBankAccountsFetchLoader && (
                    <div className="spinner-wrapper">
                      <Spinner variant="simple" isVisible label="Loading..." />
                    </div>
                  )}

                  {!payoutBankAccountsFetchLoader && (
                    <div className="lp-w-full mb-32">
                      <LabelledValue
                        label="Bank account"
                        value={`*******${payoutBankAccounts.accountNumberLast4}`}
                        variant="vertical"
                      />
                    </div>
                  )}
                </s.MerchantsData>

                <s.MerchantsData className="bt-1">
                  <div className="lp-w-full lp-flex lp-align-start lp-justify-between mb-32">
                    <div>
                      <Heading alignment="left" tagName="h2" variant="xs">
                        Payout reports
                      </Heading>
                      <Text variant="body-3" className="mt-4">
                        After each payout is processed, a payout report will be sent to nominated emails.
                      </Text>
                    </div>
                    <div className="ml-auto">
                      {!payoutReportsFetchLoader && (
                        <Button
                          size="small"
                          variant="reversed"
                          onClick={() => setPayoutReportModalOpen(!payoutReportModalOpen)}
                        >
                          Edit
                          <Icon name="Edit" />
                        </Button>
                      )}
                    </div>
                  </div>

                  {payoutReportsFetchLoader && (
                    <div className="spinner-wrapper">
                      <Spinner variant="simple" isVisible label="Loading..." />
                    </div>
                  )}

                  {!payoutReportsFetchLoader && (
                    <Fragment>
                      <div className="lp-w-full mb-32">
                        <LabelledValue
                          label="Receive reports via email"
                          value={payoutReports.notificationsEnabled ? `Enabled` : `Disabled`}
                          variant="vertical"
                        />
                      </div>

                      <div className="lp-w-full mb-32">
                        {payoutReports.notificationEmails && payoutReports.notificationEmails.length > 0 && (
                          <Fragment>
                            <div className="lp-flex lp-align-center">
                              <Text variant="caption" className="text-dark-50 m-0">
                                Recipients
                              </Text>
                            </div>
                            {payoutReports.notificationEmails.map((obj, key) => (
                              <Text variant="body-3" className="mt-0" key={key}>
                                {obj}
                              </Text>
                            ))}
                          </Fragment>
                        )}
                      </div>
                    </Fragment>
                  )}
                </s.MerchantsData>

                <s.MerchantsData className="bt-1">
                  <div className="lp-w-full lp-flex lp-align-start lp-justify-between mb-32">
                    <div>
                      <Heading alignment="left" tagName="h2" variant="xs">
                        Settlements in payouts
                      </Heading>
                    </div>
                  </div>
                  <div className="lp-w-full mb-32">
                    <LabelledValue label="Settlement type" value="Net" variant="vertical" />
                  </div>
                </s.MerchantsData>
              </Fragment>
            )}
          </div>
        </div>
      </div>

      <PayoutScheduleModal
        isOpen={payoutScheduleModalOpen}
        modalToggler={setPayoutScheduleModalOpen}
        payoutValue={payoutFrequency}
        refreshDataHandler={fetchPayoutFrequency}
      />
      <PayoutReportModal
        modalToggler={setPayoutReportModalOpen}
        isOpen={payoutReportModalOpen}
        payoutReports={payoutReports}
        refreshDataHandler={fetchPayoutReports}
      />

      <BankDetailEditModal
        isOpen={bankDetailEditModalOpen}
        modalToggler={setBankDetailEditModalOpen}
        refreshDataHandler={fetchPayoutBankAccounts}
      />
    </Fragment>
  );
}

export default PayoutSettingView;
