import { logout } from 'api/auth/identity';
import RoutesList from 'config/constants';
import ErrorPage from 'pages/common/error';
import AuthRoutesList from 'pages/platform/auth/constants';
import { Fragment, useEffect, useState } from 'react';
import { useAppSelector } from 'redux/platform/hooks';
import { getPlatformBaseUrl } from 'utils/getBaseUrl';

import { Spinner } from '@limepayments/cosmic';

function LogoutPage() {
  const { platformName, isLoggedIn } = useAppSelector((state) => ({
    platformName: state.config.platformName,
    isLoggedIn: state.config.isLoggedIn,
  }));

  const [logoutRequestLoader, setLogoutRequestLoader] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    const logoutHandler = async () => {
      setLogoutRequestLoader(true);
      try {
        await logout();
        window.location.href = `${getPlatformBaseUrl(platformName)}/${RoutesList.AUTH_ROUTE}/${
          AuthRoutesList.LOGIN_PAGE
        }`;
      } catch (error: any) {
        setErrorMessage(error.message || 'Logout failed!');
      }
    };

    logoutHandler();
  }, [isLoggedIn, platformName]);

  return (
    <Fragment>
      {!logoutRequestLoader && errorMessage.length > 0 && <ErrorPage bodyTitle="Error" bodyText={errorMessage} />}

      {logoutRequestLoader && errorMessage.length === 0 && (
        <div className="spinner-wrapper">
          <Spinner variant="simple" isVisible label="Logging Out" />
        </div>
      )}
    </Fragment>
  );
}

export default LogoutPage;
