import { OrderAPIResponseType } from 'api/merchant/dashboard/orders.types';
import { DateTime } from 'luxon';
import { TableStyleSettings, TableThemeSettings } from 'pages/platform/constants';
import DataTable, { SortOrder, TableColumn, createTheme } from 'react-data-table-component';
import { useAppDispatch } from 'redux/merchant/hooks';
import { setOpenPaymentFilterDropdown } from 'redux/merchant/slice/paymentSlice';
import { toCurrency } from 'utils/currency';

import { Spinner } from '@limepayments/cosmic';

import * as s from '../../../../../../assets/styles/Platforms.styles';
import { VirtualTerminalStatusObj } from '../../constants';
import EmptyUnpaidRequestComponent from './EmptyUnpaidRequestComponent';
import PopoverComponent from './PopoverComponent';
import StatusTag from './StatusTagComponent';
import UnbaleToLoadComponent from './UnbaleToLoadComponent';
import UnpaidRequestComponent from './UnpaidRequestComponent';

const columns: TableColumn<OrderAPIResponseType>[] = [
  {
    name: '',
    cell: (row: OrderAPIResponseType) => {
      return <PopoverComponent />;
    },
  },
  {
    name: 'Amount',
    selector: (row: OrderAPIResponseType) => toCurrency(row.amount.minorCurrencyUnits, row.amount.currency),
    width: '100px',
    right: true,
  },
  {
    name: '',
    selector: (row: OrderAPIResponseType) => row.amount.currency,
    width: '70px',
    style: {
      color: 'rgb(var(--lp-colors-neutral-400))',
    },
  },
  {
    name: 'Order ID',
    selector: (row: OrderAPIResponseType) => row.referenceOrderId || row.orderId,
    minWidth: '230px',
  },
  {
    name: 'Customer',
    selector: (row: OrderAPIResponseType) => row.customerName ?? '',
  },
  {
    name: 'Description',
    selector: (row: OrderAPIResponseType) => row.description ?? '',
  },
  {
    name: 'Requested on',
    selector: (row: OrderAPIResponseType) => `${DateTime.fromISO(row.createdAt).toFormat('dd/LL/yyyy, HH:mm')}`,
    sortable: true,
    sortField: 'createdAt',
    minWidth: '150px',
  },
  {
    name: 'Status',
    cell: (row: OrderAPIResponseType) => {
      return (
        <StatusTag
          label={VirtualTerminalStatusObj[row.status as keyof typeof VirtualTerminalStatusObj].label}
          variant={VirtualTerminalStatusObj[row.status as keyof typeof VirtualTerminalStatusObj].varient}
          tooltipDirection="left"
          tooltipText={VirtualTerminalStatusObj[row.status as keyof typeof VirtualTerminalStatusObj].tooltipText}
        />
      );
    },
    maxWidth: '100px',
  },
  {
    name: '',
    cell: (row: OrderAPIResponseType) => {
      return <PopoverComponent />;
    },
  },
];

// createTheme creates a new theme named solarized that overrides the build in dark theme
createTheme('polarized', TableThemeSettings, 'dark');

interface Props {
  virtualTerminalList: Array<OrderAPIResponseType>;
  fetchListLoader: boolean;
  totalRows: number;
  handlePerRowsChange: (newPerPage: number, page: number) => void;
  handlePageChange: (page: number) => void;
  limit: number;
  activePage: number;
  isFilterRequest: boolean;
  handleSort: (selectedColumn: TableColumn<OrderAPIResponseType>, sortDirection: SortOrder) => void;
  isErrorRequest?: boolean;
  setCreateNewOrderModalOpen: (isOpen: boolean) => void;
}

function UnpaidRequestsDataTable({
  virtualTerminalList,
  totalRows,
  limit,
  fetchListLoader,
  handlePageChange,
  handlePerRowsChange,
  activePage,
  handleSort,
  isFilterRequest,
  isErrorRequest,
  setCreateNewOrderModalOpen,
}: Props) {
  const dispatch = useAppDispatch();

  return (
    <s.VirtualTable className="">
      <DataTable
        pagination
        responsive
        columns={columns}
        data={virtualTerminalList}
        customStyles={TableStyleSettings}
        theme="polarized"
        className=""
        progressPending={fetchListLoader}
        progressComponent={
          <div className="transaction-empty">
            <div className="transaction-empty-inner">
              <Spinner variant="simple" isVisible label="Loading..." />
            </div>
          </div>
        }
        noDataComponent={
          isErrorRequest ? (
            <UnbaleToLoadComponent />
          ) : isFilterRequest && virtualTerminalList.length < 1 ? (
            <EmptyUnpaidRequestComponent toggleFilter={() => dispatch(setOpenPaymentFilterDropdown(true))} />
          ) : (
            <UnpaidRequestComponent setCreateNewOrderModalOpen={setCreateNewOrderModalOpen} />
          )
        }
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        paginationPerPage={limit}
        paginationDefaultPage={activePage}
        onSort={handleSort}
        sortServer
        persistTableHead
        defaultSortFieldId={7}
        defaultSortAsc={false}
        paginationRowsPerPageOptions={[20, 40, 60, 80, 100]}
      />
    </s.VirtualTable>
  );
}

export default UnpaidRequestsDataTable;
