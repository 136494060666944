import { Fragment } from 'react';
import { useState } from 'react';

import { Button, Heading, Icon, Text } from '@limepayments/cosmic';

function ItemBreakdownSection() {
  const [isOpened, setIsOpened] = useState(false);
  function toggleDetails() {
    setIsOpened((wasOpened: any) => !wasOpened);
  }
  return (
    <Fragment>
      <Text tagName="p" variant="body-2" className="lp-neutral-600 mt-0 mb-24">
        Create a breakdown of a customer's order by adding items
      </Text>
      <div className="lp-w-full">
        <Heading alignment="left" tagName="h1" variant="xxs" className="text-dark-50 mb-0">
          Item breakdown
        </Heading>
        <div className="item-breakdown">
          <table className="lp-w-full table-summary">
            <thead>
              <tr>
                <th>Item</th>
                <th>Count</th>
                <th>Total</th>
                <th className="last-child"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Faucet</td>
                <td>1</td>
                <td>$160.00</td>
                <td className="last-child">
                  <div className="lp-edit-action">
                    <div className="lp-edit-action-holder">
                      <button className="edit-action-button" onClick={toggleDetails}>
                        <Icon name="Ellipse" />
                      </button>
                    </div>
                    {/* Begins Dropdown */}
                    {isOpened && (
                      <div className="action-dropdown">
                        <Button onClick={function noRefCheck() {}} variant="ghost" size={'small'}>
                          <Icon name="Edit" />
                          Edit
                        </Button>
                        <Button onClick={function noRefCheck() {}} variant="ghost" size={'small'}>
                          <Icon name="Cross" />
                          Remove
                        </Button>
                      </div>
                    )}
                    {/* //End Dropdown */}
                  </div>
                </td>
              </tr>
              <tr>
                <td>Grout</td>
                <td>5</td>
                <td>$80.00</td>
                <td className="last-child">
                  <div className="lp-edit-action">
                    <div className="lp-edit-action-holder">
                      <button className="edit-action-button" onClick={toggleDetails}>
                        <Icon name="Ellipse" />
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Paint</td>
                <td>10</td>
                <td>$300.00</td>
                <td className="last-child">
                  <div className="lp-edit-action">
                    <div className="lp-edit-action-holder">
                      <button className="edit-action-button" onClick={toggleDetails}>
                        <Icon name="Ellipse" />
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Button className="no-min-width w-70 mt-12" size="medium" variant="reversed">
          Add new item
          <Icon name="Plus" className="ml-16" />
        </Button>
      </div>
    </Fragment>
  );
}

export default ItemBreakdownSection;
