import { getPlatformMerchant } from 'api/platform/merchant';
import { PlatformMerchantObj } from 'api/platform/merchant.types';
import PlatformLayout from 'layouts/platform/PlatformLayout';
import { useCallback, useEffect, useState } from 'react';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/platform/hooks';
import { getPlatformBaseUrl } from 'utils/getBaseUrl';
import getErrorMessage from 'utils/getErrorMessage';

import DashboardRoutesList from '../../constants';
import MerchantRoutesList from '../constants';
import { IFilterComponentObj } from '../listing/types';
import MerchantDetails from './MerchantDetails';

export interface Props {
  merchantList: Array<PlatformMerchantObj>;
  activeStatusTab: string;
  statusTabChangeHandler: (val: string) => void;
  fetchListLoader: boolean;
  totalRows: number;
  handlePerRowsChange: (newPerPage: number, page: number) => void;
  handlePageChange: (page: number) => void;
  limit: number;
  exportOpen: boolean;
  filterPopupOpen: boolean;
  toggleFilter: (val: boolean) => void;
  toggleExport: () => void;
  filterRecordHandler: (obj: IFilterComponentObj) => void;
  isFilterRequest: boolean;
  startDate: string;
  endDate: string;
  activePage: number;
  handleSort: (selectedColumn: TableColumn<PlatformMerchantObj>, sortDirection: SortOrder) => void;
}

function MerchantDetailPage() {
  const navigate = useNavigate();
  const { merchantId } = useParams();

  // detail section variables
  const [showMerchantDetailLoader, setShowMerchantDetailLoader] = useState<boolean>(false);
  const [merchantDetailObj, setMerchantDetailObj] = useState<PlatformMerchantObj | null>(null);
  const [merchantDetailErrorMsg, setMerchantDetailErrorMsg] = useState<string>('');

  const { platformName, marketplaceId, apiBaseUri } = useAppSelector((state) => ({
    platformName: state.config.platformName,
    marketplaceId: state.config.marketplaceId,
    apiBaseUri: state.config.apiBaseUri,
  }));

  const detailSectionBackClickhandler = useCallback(() => {
    navigate(
      `${getPlatformBaseUrl(platformName)}/dashboard/${DashboardRoutesList.MERCHANT_ROUTE}/${
        MerchantRoutesList.MERCHANT_LANDING
      }`,
    );
  }, [navigate, platformName]);

  const fetchMerchantDetails = useCallback(async () => {
    try {
      setShowMerchantDetailLoader(true);

      const response = await getPlatformMerchant(apiBaseUri, marketplaceId, merchantId as string);
      setMerchantDetailObj(response);
    } catch (error) {
      setMerchantDetailErrorMsg(getErrorMessage(error));
    } finally {
      setShowMerchantDetailLoader(false);
    }
  }, [apiBaseUri, marketplaceId, merchantId]);

  useEffect(() => {
    if (merchantId) {
      fetchMerchantDetails();
    } else {
      detailSectionBackClickhandler();
    }
  }, [merchantId, fetchMerchantDetails, detailSectionBackClickhandler]);

  return (
    <PlatformLayout activeTab="merchant" title="Merchant Detail">
      <MerchantDetails
        onBackButtonClick={() => detailSectionBackClickhandler()}
        showMerchantDetailLoader={showMerchantDetailLoader}
        merchantDetailObj={merchantDetailObj}
        merchantDetailErrorMsg={merchantDetailErrorMsg}
      />
    </PlatformLayout>
  );
}

export default MerchantDetailPage;
