import * as s from 'assets/styles/Platforms.styles';
import { useEffect } from 'react';
import ClickAwayListener from 'react-click-away-listener';

import { Button, Heading, Icon, Message, Spinner, Stack, Text } from '@limepayments/cosmic';

import { IDownloadReport } from '../types';

interface Props {
  onCloseExportClick: () => void;
  downloadObj: IDownloadReport;
}

function DownloadReportComponent({ downloadObj, onCloseExportClick }: Props) {
  useEffect(() => {
    if (downloadObj.errorMsg.length > 0 || downloadObj.showLoader) {
      window.scrollTo({
        top: 250,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [downloadObj.errorMsg, downloadObj.showLoader]);

  return (
    <ClickAwayListener onClickAway={() => onCloseExportClick()}>
      <div className="filter-dropdown w-328">
        <div className="lp-flex lp-flex-column">
          <div className="filter-header mb-0">
            <Heading alignment="left" tagName="h2" variant="xs" className="mb-0">
              Download Report
            </Heading>
            <Button
              type="button"
              onClick={() => onCloseExportClick()}
              size="small"
              variant="ghost"
              className="button-close m-0 p-0"
            >
              <Icon name="Cross" className="m-0 p-0" />
            </Button>
          </div>
          <div className="filter-body">
            {/* listing and error state */}
            {!downloadObj.errorMsg && !downloadObj.showSuccessDiv && (
              <div className="lp-w-full">
                {downloadObj.errorMsg.length > 0 && (
                  <div className="lp-w-full mb-0">
                    <Message variant="error" children={<span>{downloadObj.errorMsg}</span>} type={'inline'} />
                  </div>
                )}
              </div>
            )}

            {/* loading state */}
            {downloadObj.showLoader && !downloadObj.showSuccessDiv && (
              <s.MerchantLoading className="m-loading">
                <Stack spacing="md">
                  <Spinner isVisible variant="simple" label="Preparing export" />
                </Stack>
              </s.MerchantLoading>
            )}

            {/* success state */}
            {!downloadObj.showLoader && downloadObj.showSuccessDiv && (
              <s.MerchantLoading className="m-loading">
                <div className="success mb-8">
                  <Icon name="Tick" />
                </div>
                <Text alignment="center" tagName="p" variant="body-3" className="max-w320 mb-12">
                  Download ready
                </Text>
                <Text alignment="center" tagName="p" variant="body-3" className="max-w320 mt-0">
                  <a
                    href={`data:text/csv;charset=utf-8,${escape(
                      downloadObj.downloadDataCSV ? downloadObj.downloadDataCSV : '',
                    )}`}
                    download="transaction-detail.csv"
                    className="fw-medium lp-link-primary"
                  >
                    click here to download
                  </a>
                </Text>
              </s.MerchantLoading>
            )}
          </div>
          <div className="filter-footer">
            <Button className="lp-w-full" onClick={() => onCloseExportClick()} size="medium" variant="reversed">
              Close
            </Button>
          </div>
        </div>
      </div>
    </ClickAwayListener>
  );
}

export default DownloadReportComponent;
