import { getAuth } from 'firebase/auth';

const getCurrentUserToken = async (): Promise<string> => {
  const currentUser = getAuth().currentUser;

  if (!currentUser) throw Error();

  const { token } = await currentUser.getIdTokenResult();
  return token;
};

export default getCurrentUserToken;
