import * as s from 'assets/styles/Onboarding.styles';
import { ReactNode } from 'react';
import { Helmet } from 'react-helmet';

// import Logo from '../../assets/images/logo.svg';

interface Props {
  title?: string;
  children: ReactNode;
}

function AuthLayout({ title, children }: Props) {
  return (
    <>
      {title && (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      )}
      <s.Wrapper data-bg="grey">
        <s.Main className="no-sidebar">
          <s.BoxContentWrap>{children}</s.BoxContentWrap>
          {/* <s.AppLogo>
            <img src={Logo} alt="April" className="img-fluid" />
          </s.AppLogo> */}
        </s.Main>
      </s.Wrapper>
    </>
  );
}

export default AuthLayout;
