import * as s from 'assets/styles/Onboarding.styles';
import { Helmet } from 'react-helmet';

import { Heading, Text } from '@limepayments/cosmic';

// import Logo from '../../assets/images/logo.svg';

interface Props {
  title?: string;
  bodyTitle: string;
  bodyText: string;
}

function ErrorLayout({ title, bodyTitle, bodyText }: Props) {
  return (
    <>
      {title && (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      )}
      <s.Wrapper data-bg="grey">
        <s.Main className="no-sidebar">
          <s.BoxContentWrap>
            <div className="lp-w-full mb-32">
              <Heading alignment="center" tagName="h4" variant="md" className="mb-12">
                {bodyTitle}
              </Heading>
              <Text alignment="center" tagName="p" className="mb-12">
                {bodyText}
              </Text>
            </div>
          </s.BoxContentWrap>
          {/* <s.AppLogo>
            <img src={Logo} alt="April" className="img-fluid" />
          </s.AppLogo> */}
        </s.Main>
      </s.Wrapper>
    </>
  );
}

export default ErrorLayout;
