import { getMerchantFile } from 'api/merchant/onbording/file';
import { GetMerchantFileResponse } from 'api/merchant/onbording/file.types';
import { GetListMerchantTrustOwnersObj } from 'api/merchant/onbording/step-05.types';
import * as s from 'assets/styles/Onboarding.styles';
import { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from 'redux/merchant/hooks';

import { Heading, Text } from '@limepayments/cosmic';

interface CardProps {
  title: string;
  data: GetListMerchantTrustOwnersObj;
}

const TrustContent = ({ title, data }: CardProps) => {
  const [firstFileDetail, setFirstFileDetail] = useState<GetMerchantFileResponse | null>(null);

  const { apiBaseUri, merchantId } = useAppSelector((state) => ({
    apiBaseUri: state.config.apiBaseUri,
    merchantId: state.config.merchantId,
  }));

  const fetchFileDetail = useCallback(
    async (fileId: string) => {
      try {
        const response = await getMerchantFile(apiBaseUri, merchantId, fileId);

        setFirstFileDetail(response);
      } catch (error) {}
    },
    [apiBaseUri, merchantId],
  );

  useEffect(() => {
    if (data.profile.trustDeedDocs.length > 0) {
      fetchFileDetail(data.profile.trustDeedDocs[data.profile.trustDeedDocs.length - 1]);
    }
  }, [data.profile.trustDeedDocs, fetchFileDetail]);

  return (
    <div className="content">
      <header className="content-header mb-24">
        <Heading alignment="left" tagName="h5" variant="xxs">
          {title}
        </Heading>
      </header>
      <s.MultiColumnRespBlock className="mb-24" data-testid={`${data.trustId}-trust-name-div`}>
        <Text tagName="p" variant="body-3" testId={`${data.trustId}-trust-name-text`}>
          Trust name
        </Text>
        <Text tagName="p" variant="body-3" testId={`${data.trustId}-trust-name-value`}>
          <strong>{data.profile.businessName}</strong>
        </Text>
      </s.MultiColumnRespBlock>
      <s.MultiColumnRespBlock className="mb-24" data-testid={`${data.trustId}-doc-div`}>
        <Text tagName="p" variant="body-3" testId={`${data.trustId}-doc-text`}>
          Verification documents
        </Text>
        <Text tagName="p" variant="body-3" testId={`${data.trustId}-doc-value`}>
          <strong>{firstFileDetail ? firstFileDetail.fileName : 'N/A'}</strong>
        </Text>
      </s.MultiColumnRespBlock>
    </div>
  );
};

export default TrustContent;
