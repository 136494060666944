import { getTransactionsList } from 'api/platform/transactions';
import { PlatformDashboardTransactionsObj } from 'api/platform/transactions.types';
import PlatformLayout from 'layouts/platform/PlatformLayout';
import { DateTime } from 'luxon';
import ErrorComponent from 'pages/common/error/ErrorComponent';
import { MAX_FILTERING_DAYS } from 'pages/platform/constants';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { useAppDispatch, useAppSelector } from 'redux/platform/hooks';
import { setListingPagePropsObj, setOpenTransactionFilterDropdown } from 'redux/platform/slice/transactionSlice';
import getErrorMessage from 'utils/getErrorMessage';

import { Spinner } from '@limepayments/cosmic';

import TrasactionsList from './partials/TrasactionsList';

function AllTransactionsPage() {
  const dispatch = useAppDispatch();
  const [fetchListLoader, setFetchListLoader] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [transactionList, setTransactionList] = useState<Array<PlatformDashboardTransactionsObj>>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [filterPopupOpen, setFilterPopupOpen] = useState<boolean>(false);

  const { marketplaceId, apiBaseUri, openTransactionFilterDropdown, txnFilterObj, listingPageProps } = useAppSelector(
    (state) => ({
      marketplaceId: state.config.marketplaceId,
      apiBaseUri: state.config.apiBaseUri,
      openTransactionFilterDropdown: state.transaction.openTransactionFilterDropdown,
      txnFilterObj: state.transaction.txnFilterObj,
      listingPageProps: state.transaction.listingPageProps,
    }),
  );

  const [lastFilterSaveTriggerAt, setLastFilterSaveTriggerAt] = useState<string>(txnFilterObj.saveTiggerAt);

  const apiQueryString = useMemo(() => {
    let queryString = `page=${listingPageProps.activePage}&limit=${listingPageProps.limit}`;

    if (listingPageProps.sortBy) {
      queryString += `&sort=${listingPageProps.sortBy}`;
    }

    if (txnFilterObj.dateTypeCompareValue) {
      let fromDate = '';
      let toDate = '';

      if (txnFilterObj.dateTypeCompareValue === 'is_in_the_last') {
        if (txnFilterObj.dateTypeFilterValue === 'days') {
          fromDate = `${DateTime.utc()
            .minus({ days: parseInt(txnFilterObj.dateValue) })
            .startOf('day')
            .toISO()}`;
          toDate = `${DateTime.utc().endOf('day').toISO()}`;
        }

        if (txnFilterObj.dateTypeFilterValue === 'month') {
          fromDate = `${DateTime.utc()
            .minus({ months: parseInt(txnFilterObj.dateValue) })
            .startOf('day')
            .toISO()}`;
          toDate = `${DateTime.utc().endOf('day').toISO()}`;
        }
      }

      if (txnFilterObj.dateTypeCompareValue === 'is_equal_to' && txnFilterObj.dateSingleValue) {
        const fromDateObj = new Date(txnFilterObj.dateSingleValue as unknown as string);
        fromDate = `${DateTime.fromISO(fromDateObj.toISOString()).toUTC().startOf('day').toISO()}`;
        toDate = `${DateTime.fromISO(fromDateObj.toISOString()).toUTC().endOf('day').toISO()}`;
      }

      if (
        txnFilterObj.dateTypeCompareValue === 'is_between' &&
        txnFilterObj.dateRangeValue &&
        Array.isArray(txnFilterObj.dateRangeValue)
      ) {
        const fromDateObj = new Date(txnFilterObj.dateRangeValue[0]);
        const toDateObj = new Date(txnFilterObj.dateRangeValue[1]);

        fromDate = `${DateTime.fromISO(fromDateObj.toISOString()).toUTC().startOf('day').toISO()}`;
        toDate = `${DateTime.fromISO(toDateObj.toISOString()).toUTC().startOf('day').toISO()}`;
      }

      if (txnFilterObj.dateTypeCompareValue === 'is_after' && txnFilterObj.dateSingleValue) {
        const fromDateObj = new Date(txnFilterObj.dateSingleValue as unknown as string);

        fromDate = `${DateTime.fromISO(fromDateObj.toISOString()).toUTC().endOf('day').toISO()}`;
        toDate = `${DateTime.fromISO(fromDateObj.toISOString())
          .toUTC()
          .plus({ months: MAX_FILTERING_DAYS })
          .endOf('day')
          .toISO()}`;
      }

      if (txnFilterObj.dateTypeCompareValue === 'is_before' && txnFilterObj.dateSingleValue) {
        const fromDateObj = new Date(txnFilterObj.dateSingleValue as unknown as string);
        fromDate = `${DateTime.fromISO(fromDateObj.toISOString())
          .toUTC()
          .minus({ months: MAX_FILTERING_DAYS })
          .startOf('day')
          .toISO()}`;
        toDate = `${DateTime.fromISO(fromDateObj.toISOString()).toUTC().startOf('day').toISO()}`;
      }

      if (fromDate) {
        queryString += `&from=${encodeURIComponent(fromDate)}`;
      }

      if (toDate) {
        queryString += `&to=${encodeURIComponent(toDate)}`;
      }
    }

    if (txnFilterObj.merchantId) {
      queryString += `&merchantId=${txnFilterObj.merchantId}`;
    }

    if (txnFilterObj.payoutId) {
      queryString += `&payoutId=${txnFilterObj.payoutId}`;
    }

    if (txnFilterObj.entryType) {
      queryString += `&entryType=${txnFilterObj.entryType}`;
    }

    if (txnFilterObj.txnId) {
      queryString += `&transactionId=${txnFilterObj.txnId}`;
    }

    if (txnFilterObj.amount) {
      queryString += `&amount=${parseFloat(txnFilterObj.amount) * 100}`; // convert into cents
    }

    return queryString;
  }, [txnFilterObj, listingPageProps]);

  const handleSort = (selectedColumn: TableColumn<PlatformDashboardTransactionsObj>, sortDirection: SortOrder) => {
    dispatch(
      setListingPagePropsObj({
        ...listingPageProps,
        activePage: 1,
        sortBy: selectedColumn.sortField
          ? `${sortDirection === 'asc' ? `` : `-`}${selectedColumn.sortField}`
          : '-createdAt',
      }),
    );
  };

  const toggleFilter = useCallback(() => {
    setFilterPopupOpen((wasOpened) => !wasOpened);
  }, []);

  const fetchTransactionsList = useCallback(async () => {
    try {
      setFetchListLoader(true);
      setErrorMessage('');

      const response = await getTransactionsList(apiBaseUri, marketplaceId, apiQueryString);

      setTransactionList(response.items);
      setTotalRows(response.totalRecords);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setFetchListLoader(false);
    }
  }, [apiBaseUri, marketplaceId, apiQueryString]);

  const handlePageChange = (page: number) => {
    dispatch(
      setListingPagePropsObj({
        ...listingPageProps,
        activePage: page,
      }),
    );
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    dispatch(
      setListingPagePropsObj({
        ...listingPageProps,
        activePage: page,
        limit: newPerPage,
      }),
    );
  };

  useEffect(() => {
    if (lastFilterSaveTriggerAt !== txnFilterObj.saveTiggerAt) {
      setLastFilterSaveTriggerAt(txnFilterObj.saveTiggerAt);
    }
  }, [apiBaseUri, dispatch, lastFilterSaveTriggerAt, marketplaceId, txnFilterObj.saveTiggerAt]);

  useEffect(() => {
    fetchTransactionsList();
  }, [fetchTransactionsList]);

  useEffect(() => {
    if (!fetchListLoader) {
      setIsPageLoading(false);
    }
  }, [setIsPageLoading, fetchListLoader]);

  useEffect(() => {
    if (openTransactionFilterDropdown) {
      toggleFilter();
      setTimeout(() => {
        dispatch(setOpenTransactionFilterDropdown(false));
      }, 2000);
    }
  }, [openTransactionFilterDropdown, toggleFilter, dispatch]);

  return (
    <PlatformLayout activeTab="payment" title="All Transactions">
      {!isPageLoading && errorMessage.length ? <ErrorComponent bodyText={errorMessage} /> : null}

      {isPageLoading && (
        <div className="spinner-wrapper">
          <Spinner variant="simple" isVisible label="Loading..." />
        </div>
      )}

      {!isPageLoading && (
        <TrasactionsList
          transactionList={transactionList}
          fetchListLoader={fetchListLoader}
          totalRows={totalRows}
          handlePerRowsChange={handlePerRowsChange}
          handlePageChange={handlePageChange}
          limit={listingPageProps.limit}
          toggleFilter={toggleFilter}
          filterPopupOpen={filterPopupOpen}
          activePage={listingPageProps.activePage}
          handleSort={handleSort}
        />
      )}
    </PlatformLayout>
  );
}

export default AllTransactionsPage;
