import PublicRoute from 'PublicRoute';
import { firebaseApp } from 'api/auth/firebase';
import { logout } from 'api/auth/identity';
import { getPlatformConfig } from 'api/platform/platform';
import RoutesList from 'config/constants';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import ErrorPage from 'pages/common/error';
import AuthRoutes from 'pages/platform/auth/AuthRoutes';
import AuthRoutesList from 'pages/platform/auth/constants';
import LogoutPage from 'pages/platform/auth/logout';
import PlatformDefaultRoute from 'pages/platform/constants';
import DashboardRoutes from 'pages/platform/dashboard/DashboardRoutes';
import { Fragment, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/platform/hooks';
import { setConfig, setLoggedIn, setMerchantEmail, setPlatformName } from 'redux/platform/slice/configSlice';
import compareTwoStrings from 'utils/compareTwoStrings';
import { getPlatformBaseUrl } from 'utils/getBaseUrl';
import getCurrentUser from 'utils/getCurrentUser';
import getErrorMessage from 'utils/getErrorMessage';
import getMerchantName from 'utils/getMerchantName';
import {
  clearZendeskWidget,
  configZendeskWidget,
  hideOnCloseZendeskWidget,
  hideZendeskWidget,
  identifyZendeskUser,
  loadZendeskScript,
} from 'utils/zendesk';

import { Spinner } from '@limepayments/cosmic';

import PrivateRoute from './PrivateRoute';

function PlatformApp() {
  const [platformName] = useState(() => getMerchantName());
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);

  const dispatch = useAppDispatch();

  const { isLoggedIn, merchantEmail } = useAppSelector((state) => ({
    isLoggedIn: state.config.isLoggedIn,
    merchantEmail: state.config.merchantEmail,
  }));

  useEffect(() => {
    if (!platformName) {
      setErrorMsg('No platform slug found');
      return;
    }

    const initialize = async () => {
      try {
        const config = await getPlatformConfig(platformName);
        dispatch(setConfig(config));
        dispatch(setPlatformName(platformName));

        const fireBaseApp = firebaseApp.initializeApp({ apiKey: config.authApiKey, authDomain: config.authDomain });
        const firebaseAuth = getAuth(fireBaseApp);

        await loadZendeskScript().catch(console.error);
        configZendeskWidget();

        onAuthStateChanged(firebaseAuth, async () => {
          const currentUser = await getCurrentUser();
          if (!currentUser) {
            clearZendeskWidget();
            setIsPageLoading(false);
            return;
          }

          if (
            currentUser.claims.limepay.marketplaceId &&
            compareTwoStrings(config.marketplaceId, currentUser.claims.limepay.marketplaceId)
          ) {
            hideZendeskWidget();
            hideOnCloseZendeskWidget();
            dispatch(setLoggedIn(!!currentUser));
            dispatch(setMerchantEmail(currentUser.claims.email));
            setIsPageLoading(false);
          } else {
            await logout();

            if (!window.location.href.includes('/auth/login')) {
              window.location.href = `/${RoutesList.PLATFORM_ROUTE}/${platformName}/auth/login`;
            }
          }
        });
      } catch (error) {
        setErrorMsg(getErrorMessage(error));
        setIsPageLoading(false);
      }
    };

    initialize();
  }, [platformName, dispatch]);

  useEffect(() => {
    if (!merchantEmail || !platformName) {
      return;
    }
    const name = `${platformName}`;
    identifyZendeskUser(name, merchantEmail, platformName);
  }, [platformName, merchantEmail]);

  return (
    <Fragment>
      {!isPageLoading && errorMsg.length > 0 && <ErrorPage bodyTitle="Error" bodyText={errorMsg} />}

      {isPageLoading && errorMsg.length === 0 && (
        <div className="spinner-wrapper">
          <Spinner variant="simple" isVisible label="Loading..." />
        </div>
      )}

      {!isPageLoading && errorMsg.length === 0 && (
        <BrowserRouter>
          <Routes>
            {/* Auth Routes for NON Logged In Users */}
            <Route
              element={
                <PublicRoute
                  applicationArea="platform"
                  isLoggedIn={isLoggedIn}
                  defaultRoute={`${getPlatformBaseUrl(platformName)}/${PlatformDefaultRoute.LOGGEDIN_ROUTE}`}
                />
              }
            >
              <Route path={`/${RoutesList.PLATFORM_ROUTE}/:platformName/*`} element={<AuthRoutes />} />
              <Route
                path={`/${RoutesList.PLATFORM_ROUTE}/:platformName/${RoutesList.AUTH_ROUTE}/*`}
                element={<AuthRoutes />}
              />
            </Route>

            {/* Private Routes */}
            <Route element={<PrivateRoute isLoggedIn={isLoggedIn} />}>
              <Route
                path={`/${RoutesList.PLATFORM_ROUTE}/:platformName/${AuthRoutesList.LOGOUT_PAGE}`}
                caseSensitive={true}
                element={<LogoutPage />}
              />
              <Route path={`/${RoutesList.PLATFORM_ROUTE}/:platformName/dashboard/*`} element={<DashboardRoutes />} />
            </Route>

            {/* Fallback - Not Found */}
            <Route
              path={`*`}
              element={
                <ErrorPage bodyTitle="Page Not Found" bodyText="The page you are trying to access doesn't exists" />
              }
            />
          </Routes>
        </BrowserRouter>
      )}
    </Fragment>
  );
}

export default PlatformApp;
