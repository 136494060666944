import { updateSettingPayout } from 'api/platform/setting';
import { SettingPayoutResponse } from 'api/platform/setting.types';
import * as s from 'assets/styles/Platforms.styles';
import { ErrorMessage, Formik, FormikProps } from 'formik';
import { Fragment, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppSelector } from 'redux/platform/hooks';
import getErrorMessage from 'utils/getErrorMessage';
import * as Yup from 'yup';

import { Button, Heading, Icon, Message, Modal, Switch, Text, TextField } from '@limepayments/cosmic';

import { SettingPayoutPayload } from '../types';

const validationSchema = Yup.object().shape({
  notificationEmails: Yup.array()
    .of(Yup.string().email('Must be valid email addresses'))
    .min(1, 'Please enter atleast one email'),
  email: Yup.string().email('Please enter a valid email address'),
});

interface Props {
  isOpen: boolean;
  modalToggler: (val: boolean) => void;
  settingPayoutDetail: SettingPayoutResponse;
  refreshDataHandler: () => void;
}

function PayoutReportModal({ isOpen, modalToggler, settingPayoutDetail, refreshDataHandler }: Props) {
  const errorContainerRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<FormikProps<SettingPayoutPayload>>(null);
  const [editRequestLoading, setEditRequestLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [initialValues, setInitialValues] = useState<SettingPayoutResponse>({
    ...settingPayoutDetail,
  });

  const { apiBaseUri, payoutMerchantId } = useAppSelector((state) => ({
    apiBaseUri: state.config.apiBaseUri,
    payoutMerchantId: state.setting.payoutMerchantId,
  }));

  const submitHandler = async (values: SettingPayoutPayload, formObj: { resetForm: () => void }) => {
    try {
      const { email, ...postData } = values;

      setEditRequestLoading(true);
      setErrorMsg('');

      await updateSettingPayout(apiBaseUri, payoutMerchantId, postData);

      toast.success('Payout report updated successfully');

      formObj.resetForm();
      modalToggler(false);
      refreshDataHandler();
    } catch (error) {
      setErrorMsg(getErrorMessage(error));
    } finally {
      setEditRequestLoading(false);
    }
  };

  useEffect(() => {
    if (errorMsg.length > 0 && errorContainerRef && errorContainerRef.current) {
      errorContainerRef.current.scrollIntoView();
    }
  }, [errorMsg]);

  useEffect(() => {
    if (isOpen) {
      setInitialValues({
        ...settingPayoutDetail,
      });
      setErrorMsg('');
    }
  }, [isOpen, settingPayoutDetail]);

  return (
    <Modal isOpen={isOpen} className="modal-medium update-pass-modal">
      <s.Wrapper className="">
        <div className="modal-main p-24">
          <div className="modal-header">
            <div className="content-main bb-1">
              <Heading alignment="center" tagName="h2" variant="xs" className="mt-12 mb-24 lp-justify-center lp-flex">
                Payout reports
              </Heading>
            </div>
          </div>
          <Formik<SettingPayoutPayload>
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={(values, formObj: { resetForm: () => void }) => {
              submitHandler(values, formObj);
            }}
            validationSchema={validationSchema}
            innerRef={formRef}
          >
            {({ values, handleSubmit, errors, touched, setFieldValue, handleChange, handleBlur }) => (
              <form autoComplete="off" onSubmit={handleSubmit} className="content-wrap-form">
                <div className="modal-content p-28 sm-p-0 sm-mt-24">
                  {errorMsg.length > 0 && (
                    <div className="mt-24 mb-24" ref={errorContainerRef}>
                      <Message
                        type="inline"
                        children={<span className="text-wrap">{errorMsg}</span>}
                        variant={'error'}
                      />
                    </div>
                  )}

                  <div className="lp-w-full mb-32">
                    <div className="lp-w-full lp-flex lp-align-center lp-flex-row mb-12">
                      <Text alignment="left" tagName="p" variant="body-1" isEmphasised className="text-dark-50 mb-0">
                        Receive reports via email
                        <Text variant="caption" className="mt-4">
                          After each payout is processed, a payout report will be sent to nominated emails.
                        </Text>
                      </Text>
                    </div>
                    <div
                      className={`payouts-switch${
                        errors.notificationsEnabled && touched.notificationsEnabled ? ` has-error` : ``
                      }`}
                    >
                      <Switch
                        className="text-neutral-900"
                        id="notificationsEnabled"
                        label={values.notificationsEnabled ? `Enabled` : `Disabled`}
                        size="medium"
                        checked={values.notificationsEnabled ? true : false}
                        onChange={(value) => {
                          setFieldValue('notificationsEnabled', value);
                        }}
                      />
                      <ErrorMessage name="notificationsEnabled" component="span" className="Mui-error" />
                    </div>
                  </div>
                  {!values.notificationsEnabled && (
                    <div className="lp-w-full mb-32">
                      <Text alignment="left" tagName="p" variant="body-1" isEmphasised className="text-dark-50 mb-16">
                        Recipients
                      </Text>
                      {values.notificationEmails.map((email, key) => (
                        <Text key={key} variant="body-3" className="text-dark-25 mt-0">
                          {email}
                        </Text>
                      ))}
                    </div>
                  )}

                  {values.notificationsEnabled && (
                    <Fragment>
                      <div className="lp-w-full mb-32">
                        <Text alignment="left" tagName="p" variant="body-1" isEmphasised className="text-dark-50 mb-16">
                          Recipients
                        </Text>
                        <div className="lp-flex lp-tags lp-flex-wrap">
                          {values.notificationEmails.map((email, key) => (
                            <div className="lp-flex lp-align-center tag" key={key}>
                              <Text variant="body-3" isEmphasised className="text-dark mt-0">
                                {email}
                              </Text>
                              <button
                                type="button"
                                className="lp-flex lp-align-center lp-justify-center tag-remove"
                                onClick={() => {
                                  const emailArray = values.notificationEmails.filter((obj) => {
                                    return obj !== email;
                                  });

                                  setFieldValue('notificationEmails', emailArray);
                                }}
                              >
                                <Icon name="Cross" />
                              </button>
                            </div>
                          ))}
                        </div>
                        <ErrorMessage name="notificationEmails" component="span" className="Mui-error" />
                      </div>
                      <div className="lp-w-full lp-flex lp-justify-between mb-16">
                        <div className={`lp-w-full`}>
                          <TextField
                            fullWidth
                            label="Email"
                            inputMode="email"
                            type="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="email"
                            name="email"
                            error={errors.email && touched.email ? errors.email : ``}
                          />
                        </div>
                        <div className="lp-w-120 ml-auto">
                          <Button
                            type="button"
                            onClick={() => {
                              if (values.email) {
                                const notificationEmails = values.notificationEmails;
                                notificationEmails.push(values.email);
                                setFieldValue('notificationEmails', notificationEmails);
                                setFieldValue('email', '');
                              }
                            }}
                            size="medium"
                            variant="reversed"
                            className="no-min-width lp-w-full"
                            disabled={!values.email || (errors.email && touched.email) ? true : false}
                          >
                            <Icon name="Plus" />
                            Add
                          </Button>
                        </div>
                      </div>
                    </Fragment>
                  )}
                </div>
                <div className="modal-footer lp-flex lp-justify-end sm-mt-24">
                  <Button
                    type="button"
                    onClick={() => modalToggler(false)}
                    size="medium"
                    variant="ghost"
                    className="no-min-width w-70"
                    disabled={editRequestLoading}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size="medium"
                    variant="primary"
                    className="no-min-width"
                    disabled={editRequestLoading}
                    isLoading={editRequestLoading}
                  >
                    Update
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </s.Wrapper>
    </Modal>
  );
}

export default PayoutReportModal;
