import * as s from 'assets/styles/Onboarding.styles';
import { ReactNode } from 'react';
import { Helmet } from 'react-helmet';

import Sidebar from './Sidebar';

interface Props {
  title?: string;
  children: ReactNode;
  hideSideBar?: boolean;
  activeStepNumber?: number;
}

function MerchantLayout({ title, children, hideSideBar = false, activeStepNumber = 0 }: Props) {
  return (
    <>
      {title && (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      )}
      <s.Wrapper>
        <s.Sidebar>{!hideSideBar && <Sidebar activeStepNumber={activeStepNumber} />}</s.Sidebar>
        <s.Main>
          <s.ContentWrap>{children}</s.ContentWrap>
        </s.Main>
      </s.Wrapper>
    </>
  );
}

export default MerchantLayout;
