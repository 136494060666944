import { PlatformDashboardPaymentsObj } from 'api/platform/payments.types';
import * as s from 'assets/styles/Platforms.styles';
import { DateTime } from 'luxon';
import { TableStyleSettings, TableThemeSettings } from 'pages/platform/constants';
import DashboardRoutesList from 'pages/platform/dashboard/constants';
import DataTable, { SortOrder, TableColumn, createTheme } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/platform/hooks';
import { setOpenPaymentFilterDropdown } from 'redux/platform/slice/paymentSlice';
import { toCurrency } from 'utils/currency';
import { getPlatformBaseUrl } from 'utils/getBaseUrl';

import { Spinner } from '@limepayments/cosmic';

import PaymentRoutesList, { PaymentListStatusObj } from '../../constants';
import DataTablePaymentRow from './DataTablePaymentRow';
import EmptyPaymentListComponent from './EmptyPaymentListComponent';
import FilterEmptyPaymentListComponent from './FilterEmptyPaymentListComponent';
import StatusTag from './StatusTagComponent';

const columns = [
  {
    name: 'Amount',
    cell: (row: PlatformDashboardPaymentsObj) => (
      <DataTablePaymentRow amount={toCurrency(row.amount, row.currency)} paymentTxnId={row.txnId} />
    ),
    // sortable: true,
    // sortField: 'amount',
  },
  {
    name: ' ',
    selector: (row: PlatformDashboardPaymentsObj) => row.currency,
  },
  {
    name: 'Status',
    cell: (row: PlatformDashboardPaymentsObj) => (
      <StatusTag
        label={PaymentListStatusObj[row.status as keyof typeof PaymentListStatusObj].label}
        variant={PaymentListStatusObj[row.status as keyof typeof PaymentListStatusObj].varient}
        tooltipDirection="right"
        tooltipText={PaymentListStatusObj[row.status as keyof typeof PaymentListStatusObj].tooltipText}
        paymentTxnId={row.txnId}
      />
    ),
    // sortable: true,
    // sortField: 'status',
  },
  {
    name: 'Payment option',
    selector: (row: PlatformDashboardPaymentsObj) => `${row.payType.charAt(0).toUpperCase() + row.payType.slice(1)}`,
    // sortable: true,
    // sortField: 'payType',
  },
  {
    name: 'Merchant',
    selector: (row: PlatformDashboardPaymentsObj) => row.merchantName,
    // sortable: true,
    // sortField: 'merchantName',
  },
  {
    name: 'Order ID',
    selector: (row: PlatformDashboardPaymentsObj) => row.orderId,
    // sortable: true,
    // sortField: 'orderId',
  },
  {
    name: 'Transaction ID',
    selector: (row: PlatformDashboardPaymentsObj) => row.txnId,
    // sortable: true,
    // sortField: 'txnId',
  },
  {
    name: 'Date',
    selector: (row: PlatformDashboardPaymentsObj) => DateTime.fromISO(row.createdAt).toFormat('dd/LL/yyyy, HH:mm'),
    sortable: true,
    sortField: 'createdAt',
  },
];

// createTheme creates a new theme named solarized that overrides the build in dark theme
createTheme('polarized', TableThemeSettings, 'dark');

interface Props {
  paymentList: Array<PlatformDashboardPaymentsObj>;
  fetchListLoader: boolean;
  totalRows: number;
  handlePerRowsChange: (newPerPage: number, page: number) => void;
  handlePageChange: (page: number) => void;
  limit: number;
  activePage: number;
  handleSort: (selectedColumn: TableColumn<PlatformDashboardPaymentsObj>, sortDirection: SortOrder) => void;
}

function MerchantsDataTable({
  paymentList,
  totalRows,
  limit,
  fetchListLoader,
  handlePageChange,
  handlePerRowsChange,
  activePage,
  handleSort,
}: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { platformName } = useAppSelector((state) => ({
    platformName: state.config.platformName,
  }));

  return (
    <s.PaymentsTable>
      <DataTable
        pagination
        responsive
        columns={columns}
        data={paymentList}
        customStyles={TableStyleSettings}
        theme="polarized"
        className=""
        progressPending={fetchListLoader}
        progressComponent={
          <div className="transaction-empty">
            <div className="transaction-empty-inner">
              <Spinner variant="simple" isVisible label="Loading..." />
            </div>
          </div>
        }
        noDataComponent={
          paymentList.length < 1 ? (
            <FilterEmptyPaymentListComponent toggleFilter={() => dispatch(setOpenPaymentFilterDropdown(true))} />
          ) : (
            <EmptyPaymentListComponent
              title={'No payments to display'}
              body={'Payments will appear here when they have been initiated.'}
            />
          )
        }
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        paginationPerPage={limit}
        paginationDefaultPage={activePage}
        onRowClicked={(row) =>
          navigate(
            `${getPlatformBaseUrl(platformName)}/dashboard/${DashboardRoutesList.PAYMENT_ROUTE}/${
              PaymentRoutesList.PAYMENT_DETAILS
            }?txnId=${row.txnId}`,
          )
        }
        onSort={handleSort}
        sortServer
        persistTableHead
        defaultSortFieldId={8}
        defaultSortAsc={false}
        paginationRowsPerPageOptions={[20, 40, 60, 80, 100]}
        pointerOnHover={true}
      />
    </s.PaymentsTable>
  );
}

export default MerchantsDataTable;
