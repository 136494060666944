import { uploadOnboardingMerchantFile } from 'api/merchant/onbording/file';
import { GetMerchantFileResponse } from 'api/merchant/onbording/file.types';
import { createMerchantPerson, updateMerchantPerson } from 'api/merchant/onbording/step-05';
import { FormikProps } from 'formik';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useAppSelector } from 'redux/merchant/hooks';
import getErrorMessage from 'utils/getErrorMessage';

import { CreateMerchantPersonPayload, CreateMerchantPersonPayloadApi } from '../../../types';
import IndividualForm from './IndividualForm';

interface Props {
  isOpen: boolean;
  toggleModal: (val: boolean) => void;
  setCurrentStep: (val: number) => void;
  refreshData: () => void;
  directorSection?: boolean;
  updateItem: boolean;
  editObjValues: CreateMerchantPersonPayload | null;
  selectedOwnerEditId: string;
  fileFrontDetail: GetMerchantFileResponse | null;
  fileBackDetail: GetMerchantFileResponse | null;
}

function IndividualSection({
  setCurrentStep,
  toggleModal,
  isOpen,
  refreshData,
  directorSection,
  updateItem,
  editObjValues,
  selectedOwnerEditId,
  fileFrontDetail,
  fileBackDetail,
}: Props) {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);
  const [fileFrontUploading, setFileFrontUploading] = useState<boolean>(false);
  const [fileBackUploading, setFileBackUploading] = useState<boolean>(false);
  const [selectedFileBack, setSelectedFileBack] = useState<File | null>(null);
  const [selectedFileFront, setSelectedFileFront] = useState<File | null>(null);
  const formRef = useRef<FormikProps<CreateMerchantPersonPayload>>(null);
  const [initialValues, setInitialValues] = useState<CreateMerchantPersonPayload>({
    title: '',
    roles: [directorSection ? `Director` : 'Owner'],
    firstName: '',
    middleName: '',
    lastName: '',
    dob: '',
    address: {
      unitNumber: '',
      streetNumber: '',
      streetName: '',
      streetType: '',
      suburb: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
    },
    email: '',
    phone: '',
    passport: {
      country: '',
      passportNumber: '',
      expiryDate: '',
    },
    driverLicence: {
      country: '',
      driverLicenceNumber: '',
      documentNumber: '',
      versionNumber: '',
      state: '',
    },
    verificationDocs: {
      idType: '',
      docBackFileId: '',
      docFrontFileId: '',
    },
    businessAddress: '',
    isManualEnterAddress: true,
    updateItem: false,
    isSaveFinishLater: false,
  });

  const { merchantId, apiBaseUri, saveAndFinishLater } = useAppSelector((state) => ({
    merchantId: state.config.merchantId,
    apiBaseUri: state.config.apiBaseUri,
    saveAndFinishLater: state.onboarding.saveAndFinishLater,
  }));

  const fileUploadHandler = async (type: string) => {
    try {
      type === 'front' ? setFileFrontUploading(true) : setFileBackUploading(true);
      setErrorMessage('');

      const formData = new FormData();
      if (selectedFileFront && type === 'front') {
        formData.append('file', selectedFileFront, selectedFileFront.name);
        formData.append('docType', 'PersonVerificationDocumentFront');
      }

      if (selectedFileBack && type === 'back') {
        formData.append('file', selectedFileBack, selectedFileBack.name);
        formData.append('docType', 'PersonVerificationDocumentBack');
      }

      const response = await uploadOnboardingMerchantFile(apiBaseUri, merchantId, formData);
      return response.limeFileId;
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      type === 'front' ? setFileFrontUploading(false) : setFileBackUploading(false);
    }

    return '';
  };

  const fileFrontChangeHandler = (file: FileList | null) => {
    if (file && file.length > 0) {
      setSelectedFileFront(file[0]);
    } else {
      setSelectedFileFront(null);
    }
  };

  const fileBackChangeHandler = (file: FileList | null) => {
    if (file && file.length > 0) {
      setSelectedFileBack(file[0]);
    } else {
      setSelectedFileBack(null);
    }
  };

  const submitHandler = async (values: CreateMerchantPersonPayload, formObj: { resetForm: () => void }) => {
    try {
      setIsRequestLoading(true);
      setErrorMessage('');
      const { selectedFileFront, selectedFileBack, isManualEnterAddress, businessAddress, ...rest } = { ...values };
      const postData: CreateMerchantPersonPayloadApi = JSON.parse(JSON.stringify(rest));

      delete postData.updateItem;

      if (!selectedFileFront && !updateItem) {
        setErrorMessage(
          `${
            postData.verificationDocs.idType === 'DriverLicence'
              ? `Please select driving license front`
              : `Please select passport page`
          }`,
        );
        return;
      }

      if (!selectedFileBack && postData.verificationDocs.idType === 'DriverLicence' && !updateItem) {
        setErrorMessage('Please select driving license back');
        return;
      }

      if (selectedFileFront) {
        const fileId = await fileUploadHandler('front');
        if (!fileId) {
          return;
        }

        postData.verificationDocs.docFrontFileId = fileId;
      }

      if (selectedFileBack && postData.verificationDocs.idType === 'DriverLicence') {
        const fileId = await fileUploadHandler('back');
        if (!fileId) {
          return;
        }

        postData.verificationDocs.docBackFileId = fileId;
      }

      if (!postData.middleName) {
        delete postData.middleName;
      }

      const dobDate = values.dob.split('/');
      postData.dob = `${dobDate[2]}-${dobDate[1]}-${dobDate[0]}`;

      if (postData.verificationDocs.idType === 'Passport') {
        delete postData.driverLicence;
        delete postData.verificationDocs.docBackFileId;
      }

      if (postData.verificationDocs.idType === 'DriverLicence') {
        delete postData.passport;
      }

      if (postData.verificationDocs.idType === 'Passport' && postData.passport && values.passport) {
        const passportExpiryDate = values.passport.expiryDate.split('/');
        postData.passport.expiryDate = `${passportExpiryDate[2]}-${passportExpiryDate[1]}-${passportExpiryDate[0]}`;
      }

      if (!postData.address.city) {
        delete postData.address.city;
      }

      if (!postData.address.state) {
        delete postData.address.state;
      }

      if (postData.driverLicence && !postData.driverLicence.state) {
        delete postData.driverLicence.state;
      }

      if (postData.driverLicence && postData.driverLicence.country === 'AU') {
        delete postData.driverLicence.versionNumber;
      }

      if (postData.driverLicence && postData.driverLicence.country === 'NZ') {
        delete postData.driverLicence.documentNumber;
      }

      if (postData.verificationDocs.idType !== initialValues.verificationDocs.idType) {
        if (postData.verificationDocs.idType !== 'DriverLicence' && postData.verificationDocs.docBackFileId) {
          delete postData.verificationDocs.docBackFileId;
        }
      }

      updateItem
        ? await updateMerchantPerson(apiBaseUri, merchantId, selectedOwnerEditId, postData)
        : await createMerchantPerson(apiBaseUri, merchantId, postData);
      formObj.resetForm();

      refreshData();
      toggleModal(false);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setIsRequestLoading(false);
    }
  };

  useEffect(() => {
    if (updateItem && editObjValues) {
      setInitialValues(editObjValues);
    }
  }, [updateItem, editObjValues]);

  useEffect(() => {
    if (formRef && formRef.current && saveAndFinishLater) {
      formRef.current.setFieldValue('isSaveFinishLater', true);
      setTimeout(() => {
        if (formRef && formRef.current) {
          formRef.current.submitForm();
        }
      }, 500);
    }
  }, [saveAndFinishLater]);

  return (
    <Fragment>
      <IndividualForm
        isOpen={isOpen}
        toggleModal={toggleModal}
        setCurrentStep={setCurrentStep}
        errorMessage={errorMessage}
        initialValues={initialValues}
        submitHandler={submitHandler}
        formRef={formRef}
        selectedFileFront={selectedFileFront}
        selectedFileBack={selectedFileBack}
        fileFrontChangeHandler={fileFrontChangeHandler}
        fileBackChangeHandler={fileBackChangeHandler}
        isRequestLoading={isRequestLoading}
        fileFrontUploading={fileFrontUploading}
        fileBackUploading={fileBackUploading}
        updateItem={updateItem}
        fileFrontDetail={fileFrontDetail}
        fileBackDetail={fileBackDetail}
        saveAndFinishLater={() => {}}
        directorSection={directorSection}
      />
    </Fragment>
  );
}

export default IndividualSection;
