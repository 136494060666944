import * as s from 'assets/styles/Platforms.styles';
import { Formik, FormikProps } from 'formik';
import { RefObject, useEffect, useRef } from 'react';
import * as Yup from 'yup';

import { Button, Heading, Icon, Message, Modal, TextField } from '@limepayments/cosmic';

import { SettingBrandingDetailPayload } from '../types';

const validationSchema = Yup.object().shape({
  branding: Yup.object().shape({
    supportEmail: Yup.string().trim().email('Please enter valid email').required('This field is required'),
    businessDisplayName: Yup.string().trim().required('This field is required'),
    businessWebsite: Yup.string().trim().required('This field is required'),
    iconUri: Yup.string().trim().required('This field is required').url('Please enter valid url'),
    logoUri: Yup.string().trim().required('This field is required').url('Please enter valid url'),
    logoAltText: Yup.string().trim().required('This field is required'),
  }),
  customerSettings: Yup.object().shape({
    smsSenderName: Yup.string()
      .trim()
      .required('This field is required')
      .min(3, 'Must be at least 3 characters')
      .max(11, 'Must be at most 11 characters')
      .matches(/^[aA-zZ0-9]+$/, 'Please enter only alphanumeric characters'),
    cardStatementName: Yup.string()
      .trim()
      .required('This field is required')
      .min(5, 'Must be at least 5 characters')
      .max(13, 'Must be at most 13 characters')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field'),
  }),
  payoutSettings: Yup.object().shape({
    payoutDescription: Yup.string()
      .trim()
      .required('This field is required')
      .min(5, 'Must be at least 5 characters')
      .max(13, 'Must be at most 13 characters')
      .matches(/^[aA-zZ0-9]+$/, 'Please enter only alphanumeric characters'),
  }),
});

interface Props {
  isOpen: boolean;
  modalToggler: (val: boolean) => void;
  initialValues: SettingBrandingDetailPayload;
  onSubmitClick: (values: SettingBrandingDetailPayload, formObj: { resetForm: () => void }) => void;
  formRef: RefObject<FormikProps<SettingBrandingDetailPayload>>;
  errorMsg: string;
  editRequestLoading: boolean;
}

function BrandingEditModal({
  isOpen,
  initialValues,
  formRef,
  errorMsg,
  editRequestLoading,
  modalToggler,
  onSubmitClick,
}: Props) {
  const errorContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (errorMsg.length > 0 && errorContainerRef && errorContainerRef.current) {
      errorContainerRef.current.scrollIntoView();
    }
  }, [errorMsg]);

  return (
    <Modal isOpen={isOpen} className="modal-medium update-pass-modal">
      <s.Wrapper className="">
        <div className="modal-main p-24">
          <div className="modal-header">
            <div className="content-main bb-1">
              <Heading alignment="center" tagName="h2" variant="xs" className="mt-12 mb-24 lp-justify-center lp-flex">
                Branding details
              </Heading>
            </div>
          </div>

          <Formik<SettingBrandingDetailPayload>
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={(values, formObj: { resetForm: () => void }) => {
              onSubmitClick(values, formObj);
            }}
            validationSchema={validationSchema}
            innerRef={formRef}
          >
            {({ values, handleSubmit, errors, touched, handleChange, handleBlur }) => (
              <form autoComplete="off" onSubmit={handleSubmit} className="content-wrap-form">
                <div className="modal-content p-28 sm-p-0 sm-mt-24">
                  {errorMsg.length > 0 && (
                    <div className="mt-24 mb-24" ref={errorContainerRef}>
                      <Message
                        type="inline"
                        children={<span className="text-wrap">{errorMsg}</span>}
                        variant={'error'}
                      />
                    </div>
                  )}
                  <div className="lp-w-full mb-24 form-disabled">
                    <TextField fullWidth disabled label="Trading name" value="Laddr" containerId="standard" />
                  </div>

                  <div className="lp-w-full mb-24">
                    <TextField
                      fullWidth
                      label="Logo URL"
                      testId="branding-edit-logo-url"
                      endAdornment={
                        <span
                          data-balloon="For best result use image dimentions 175 pixels wide x 100 pixels high and convert your image to a link from a .jpg or .png format."
                          data-balloon-pos="left"
                          data-balloon-length="large"
                          className="size-16"
                        >
                          <Icon className="text-primary" name="Info" />
                        </span>
                      }
                      id="branding.logoUri"
                      name="branding.logoUri"
                      value={values.branding.logoUri}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={
                        errors.branding && errors.branding.logoUri && touched.branding && touched.branding.logoUri
                          ? errors.branding.logoUri
                          : ''
                      }
                      helperText="Use a direct link ending with .jpg or .png"
                    />
                  </div>

                  <div className="lp-w-full mb-24">
                    <TextField
                      fullWidth
                      label="Favicon URL"
                      testId="branding-edit-favicon-url"
                      endAdornment={
                        <span
                          data-balloon="For best result use image dimentions 175 pixels wide x 100 pixels high and convert your image to a link from a .jpg or .png format."
                          data-balloon-pos="left"
                          data-balloon-length="large"
                          className="size-16"
                        >
                          <Icon className="text-primary" name="Info" />
                        </span>
                      }
                      helperText="Use a direct link ending with .ico or .png"
                      id="branding.iconUri"
                      name="branding.iconUri"
                      value={values.branding.iconUri}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={
                        errors.branding && errors.branding.iconUri && touched.branding && touched.branding.iconUri
                          ? errors.branding.iconUri
                          : ''
                      }
                    />
                  </div>

                  <div className="lp-w-full mb-16">
                    <TextField
                      fullWidth
                      label="Logo alt text"
                      testId="branding-edit-logo-alt-text"
                      id="branding.logoAltText"
                      name="branding.logoAltText"
                      value={values.branding.logoAltText}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={
                        errors.branding &&
                        errors.branding.logoAltText &&
                        touched.branding &&
                        touched.branding.logoAltText
                          ? errors.branding.logoAltText
                          : ''
                      }
                    />
                  </div>

                  <div className="lp-w-full mb-16 payout">
                    <TextField
                      fullWidth
                      label="Payout description"
                      testId="branding-edit-payout-description"
                      helperText="5-13 alphabetic characters only, this includes upper and lower cases."
                      id="payoutSettings.payoutDescription"
                      name="payoutSettings.payoutDescription"
                      value={values.payoutSettings.payoutDescription}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={
                        errors.payoutSettings &&
                        errors.payoutSettings.payoutDescription &&
                        touched.payoutSettings &&
                        touched.payoutSettings.payoutDescription
                          ? errors.payoutSettings.payoutDescription
                          : ''
                      }
                    />
                  </div>

                  <div className="lp-w-full mb-24">
                    <TextField
                      fullWidth
                      label="Card statement name"
                      testId="branding-edit-card-statement-name"
                      endAdornment={
                        <span
                          data-balloon="The business name that appears on customer bank statements. A recognisable name is important so that customers remember where they purchased from and to avoid unintended disputes."
                          data-balloon-pos="left"
                          data-balloon-length="large"
                          className="size-16"
                        >
                          <Icon className="text-primary" name="Info" />
                        </span>
                      }
                      helperText="5-13 alphabetic characters only, this includes upper and lower cases."
                      id="customerSettings.cardStatementName"
                      name="customerSettings.cardStatementName"
                      value={values.customerSettings.cardStatementName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={
                        errors.customerSettings &&
                        errors.customerSettings.cardStatementName &&
                        touched.customerSettings &&
                        touched.customerSettings.cardStatementName
                          ? errors.customerSettings.cardStatementName
                          : ''
                      }
                    />
                  </div>

                  <div className="lp-w-full mb-24">
                    <TextField
                      fullWidth
                      label="SMS sender name"
                      testId="branding-edit-sms-sender"
                      endAdornment={
                        <span
                          data-balloon="The contact name that appears on SMS received by customers regarding their payment. A recognisable name is important so that customers remember where they purchased from and to avoid unintended disputes."
                          data-balloon-pos="left"
                          data-balloon-length="large"
                          className="size-16"
                        >
                          <Icon className="text-primary" name="Info" />
                        </span>
                      }
                      helperText="3-11 alphabetic or numeric characters, this includes lower or uppercase letters."
                      id="customerSettings.smsSenderName"
                      name="customerSettings.smsSenderName"
                      value={values.customerSettings.smsSenderName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={
                        errors.customerSettings &&
                        errors.customerSettings.smsSenderName &&
                        touched.customerSettings &&
                        touched.customerSettings.smsSenderName
                          ? errors.customerSettings.smsSenderName
                          : ''
                      }
                    />
                  </div>

                  <div className="lp-w-full mb-16">
                    <TextField
                      fullWidth
                      label="Business website"
                      testId="branding-edit-business-website"
                      id="branding.businessWebsite"
                      name="branding.businessWebsite"
                      value={values.branding.businessWebsite}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={
                        errors.branding &&
                        errors.branding.businessWebsite &&
                        touched.branding &&
                        touched.branding.businessWebsite
                          ? errors.branding.businessWebsite
                          : ''
                      }
                    />
                  </div>
                </div>

                <div className="modal-footer lp-flex lp-justify-end sm-mt-24">
                  <Button
                    onClick={() => modalToggler(false)}
                    size="medium"
                    variant="ghost"
                    className="no-min-width w-70"
                    testId="branding-edit-cancel-button"
                    disabled={editRequestLoading}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size="medium"
                    variant="primary"
                    className="no-min-width"
                    testId="branding-edit-submit-button"
                    disabled={editRequestLoading}
                    isLoading={editRequestLoading}
                  >
                    Save
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </s.Wrapper>
    </Modal>
  );
}

export default BrandingEditModal;
