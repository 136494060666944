import { GetMerchantExternalBankAccountObj } from 'api/merchant/onbording/step-04.types';
import * as s from 'assets/styles/Onboarding.styles';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/merchant/hooks';
import { getMerchantBaseUrl } from 'utils/getBaseUrl';

import { Button, Heading, Icon, Text } from '@limepayments/cosmic';

import OnboardingRoutesList from '../../constants';

interface Props {
  existingMerchantDetail: Array<GetMerchantExternalBankAccountObj>;
}

const BusinessBankAccount = ({ existingMerchantDetail }: Props) => {
  const navigate = useNavigate();

  const { merchantName } = useAppSelector((state) => ({
    merchantName: state.config.merchantName,
  }));

  return (
    <>
      <div className="lp-w-full lp-equal-width mb-24">
        <s.MultiColumnRespBlock className="content-header mb-24">
          <Heading alignment="left" tagName="h4" variant="sm">
            Business bank account details
          </Heading>
          <Button
            onClick={() => {
              navigate(`${getMerchantBaseUrl(merchantName)}/onboarding/${OnboardingRoutesList.ONBOARDING_STEP04_PAGE}`);
            }}
            size="medium"
            variant="ghost"
            className="no-min-width lp-justify-end"
            testId="business-bank-acc-edit-button"
          >
            Edit
            <Icon name="Edit" />
          </Button>
        </s.MultiColumnRespBlock>
        {existingMerchantDetail.map((obj, key) => (
          <div className="content" key={key}>
            <s.MultiColumnRespBlock className="mb-24" data-testid={`${key}-bank-name-div`}>
              <Text tagName="p" variant="body-3" testId={`${key}-bank-name-text`}>
                Bank name
              </Text>
              <Text tagName="p" variant="body-3" testId={`${key}-bank-name-value`} isEmphasised>
                {obj.bankName}
              </Text>
            </s.MultiColumnRespBlock>
            <s.MultiColumnRespBlock className="mb-24" data-testid="bank-name-div">
              <Text tagName="p" variant="body-3" testId="acc-num-text">
                Account number
              </Text>
              <Text tagName="p" variant="body-3" testId="acc-num-value" isEmphasised>
                **** **** **** {obj.accountNumberLast4}
              </Text>
            </s.MultiColumnRespBlock>
          </div>
        ))}
      </div>
      <s.Separator>
        <hr />
      </s.Separator>
    </>
  );
};

export default BusinessBankAccount;
