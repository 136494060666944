import { handleResponse } from 'api/utils';
import { DateTime } from 'luxon';
import { Currency } from 'utils/currency';
import { getAprilApiHost } from 'utils/env';
import getFetchOptions from 'utils/getFetchOptions';

import { SettlementAPIResponseType } from './settlement.types';

export const getMerchantSettlementList = async (queryString: string): Promise<SettlementAPIResponseType> => {
  const options = await getFetchOptions();

  // TODO
  const currency: Currency = 'AUD';
  const createdAfter = encodeURIComponent(`${DateTime.utc().minus({ days: 7 }).startOf('day').toISO()}`);
  const createdBefore = encodeURIComponent(`${DateTime.utc().endOf('day').toISO()}`);

  return await fetch(
    `${await getAprilApiHost()}/transactions/settlement?currency=${currency}&createdAfter=${createdAfter}&createdBefore=${createdBefore}&${queryString}`,
    options,
  ).then(handleResponse);
};
