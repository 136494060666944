import { GetMerchantExternalBankAccountObj } from 'api/merchant/onbording/step-04.types';
import * as s from 'assets/styles/Onboarding.styles';
import { Formik } from 'formik';

import { Button, Heading, Icon, Text, TextField } from '@limepayments/cosmic';

interface Props {
  onSubmitClick: () => void;
  onPreviousClick: () => void;
  initialValues: GetMerchantExternalBankAccountObj;
  requestLoader: boolean;
  addNewAccountHandler: () => void;
  saveAndFinishLater: () => void;
  saveAndReview: boolean;
}

function Step04DetailView({
  onSubmitClick,
  onPreviousClick,
  initialValues,
  requestLoader,
  addNewAccountHandler,
  saveAndFinishLater,
  saveAndReview,
}: Props) {
  return (
    <>
      <div className="content-wrap-inner">
        <header className="content-header">
          <div className="lp-w-full mb-24">
            <div className="lp-w-full mb-16">
              <Button
                size="small"
                variant="ghost"
                isNeutral
                className="back-button"
                type="button"
                onClick={onPreviousClick}
              >
                <Icon name="ArrowLeft" className="back-arrow" />
                Back
              </Button>
            </div>
            <Heading alignment="left" tagName="h2" variant="md" className="mb-24">
              Verify your business details
            </Heading>
            <Heading alignment="left" tagName="h4" variant="sm">
              Business bank account details
            </Heading>

            <Text variant="body-3">
              Provide {initialValues.country === 'NZ' ? `a New Zealand` : `an Australian`} bank account where payouts
              will be deposited into.
            </Text>
          </div>
        </header>

        <Formik<GetMerchantExternalBankAccountObj>
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={(values, formObj: { resetForm: () => void }) => {
            onSubmitClick();
          }}
        >
          {({ values, handleSubmit, errors, touched, setFieldValue, handleBlur, handleChange }) => (
            <form autoComplete="off" onSubmit={handleSubmit} className="content-wrap-form">
              <div className="content-main">
                <div className="lp-w-full mb-0">
                  <ul className="two-col lp-flex lp-flex-wrap lp-flex-column">
                    <li className="mb-12">
                      <div className="lp-w-full form-floating">
                        <TextField
                          label="Account number"
                          inputMode="text"
                          autoComplete="off"
                          id="accountNumber"
                          name="accountNumber"
                          onBlur={handleBlur}
                          readOnly
                          value={`******* ${values.accountNumberLast4}`}
                        />
                        {values.bankName && (
                          <div className="lp-flex lp-items-center custom-message">
                            <Icon name="Tick" className="back-arrow" />
                            <Text alignment="left" variant="caption">
                              {values.bankName}
                            </Text>
                          </div>
                        )}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <s.Cta className="lp-flex lp-justify-between lp-items-center sm:flex-col sm:align-start">
                <Button
                  type="submit"
                  size="medium"
                  variant="primary"
                  className="no-min-width lp-full"
                  onClick={addNewAccountHandler}
                >
                  Add new account
                </Button>
                <Button
                  type="submit"
                  size="medium"
                  variant="primary"
                  className="no-min-width lp-full"
                  disabled={requestLoader}
                  isLoading={requestLoader}
                >
                  Continue {saveAndReview ? `and review` : ``}
                  <Icon name="ArrowRight" />
                </Button>
                <div className="mobile-sidebar-footer">
                  <Button size="medium" onClick={saveAndFinishLater} variant="ghost" className="lp-w-full">
                    <Icon name="Save" />
                    Save and finish later
                  </Button>
                </div>
              </s.Cta>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default Step04DetailView;
