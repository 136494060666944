import { GetMerchantFileResponse } from 'api/merchant/onbording/file.types';
import * as s from 'assets/styles/Onboarding.styles';
import { Fragment, useEffect, useState } from 'react';

import { Heading, Modal } from '@limepayments/cosmic';

import {
  CreateMerchantCompanyOwnerPayload,
  CreateMerchantPartnershipOwnerPayload,
  CreateMerchantPersonPayload,
  CreateMerchantTrustOwnerPayload,
} from '../../../types';
import CompanySection from './CompanySection';
import IndividualSection from './IndividualSection';
import PartnershipSection from './PartnershipSection';
import SelectOwnerSection from './SelectOwnerSection';
import TrustSection from './TrustSection';

interface Props {
  isOpen: boolean;
  toggleModal: (isOpen: boolean) => void;
  modalType: string;
  refreshData: () => void;
  selectedOwnerEditId: string;
  merchantPersonEditObj: CreateMerchantPersonPayload | null;
  merchantTrustEditObj: CreateMerchantTrustOwnerPayload | null;
  merchantPartnershipEditObj: CreateMerchantPartnershipOwnerPayload | null;
  merchantCompanyEditObj: CreateMerchantCompanyOwnerPayload | null;
  fileFrontDetail: GetMerchantFileResponse | null;
  fileBackDetail: GetMerchantFileResponse | null;
  fileRemoveHandler: () => void;
}

function AddOwnerModal({
  isOpen,
  toggleModal,
  modalType,
  refreshData,
  selectedOwnerEditId,
  merchantPersonEditObj,
  merchantTrustEditObj,
  merchantCompanyEditObj,
  merchantPartnershipEditObj,
  fileFrontDetail,
  fileBackDetail,
  fileRemoveHandler,
}: Props) {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [selectedOwnerType, setSelectedOwnerType] = useState<string>('');

  const modalCancelToggle = () => {
    setCurrentStep(1);
    setSelectedOwnerType('');
    toggleModal(!isOpen);
  };

  useEffect(() => {
    if (modalType && modalType === 'director') {
      setCurrentStep(2);
      setSelectedOwnerType(modalType);
    } else {
      if (selectedOwnerEditId) {
        if (merchantPersonEditObj) {
          setSelectedOwnerType('individual');
        }

        if (merchantTrustEditObj) {
          setSelectedOwnerType('trust');
        }

        if (merchantPartnershipEditObj) {
          setSelectedOwnerType('partnership');
        }

        if (merchantCompanyEditObj) {
          setSelectedOwnerType('company');
        }
        setCurrentStep(2);
        return;
      }
      setCurrentStep(1);
    }
  }, [
    modalType,
    isOpen,
    selectedOwnerEditId,
    merchantPersonEditObj,
    merchantTrustEditObj,
    merchantCompanyEditObj,
    merchantPartnershipEditObj,
  ]);

  return (
    <Fragment>
      {/* Add Owner Modal */}
      <Modal isOpen={isOpen} className="modal-medium hAuto">
        <s.Wrapper>
          <div className="modal-main">
            <div className="modal-header">
              <div className="content-main">
                <Heading alignment="center" tagName="h2" variant="xxs" className="mb-24 lp-justify-center lp-flex">
                  {modalType === 'director' ? `Director` : `Owner`} details
                </Heading>
                <s.Separator>
                  <hr />
                </s.Separator>
              </div>
            </div>

            {currentStep === 1 && (
              <SelectOwnerSection
                setCurrentStep={setCurrentStep}
                isOpen={isOpen}
                toggleModal={modalCancelToggle}
                setSelectedOwnerType={setSelectedOwnerType}
                selectedOwnerType={selectedOwnerType}
              />
            )}

            {currentStep === 2 && (selectedOwnerType === 'individual' || selectedOwnerType === 'director') && (
              <IndividualSection
                refreshData={refreshData}
                setCurrentStep={setCurrentStep}
                isOpen={isOpen}
                toggleModal={modalCancelToggle}
                directorSection={modalType && modalType === 'director' ? true : false}
                updateItem={selectedOwnerEditId ? true : false}
                editObjValues={merchantPersonEditObj}
                selectedOwnerEditId={selectedOwnerEditId}
                fileFrontDetail={fileFrontDetail}
                fileBackDetail={fileBackDetail}
              />
            )}

            {currentStep === 2 && selectedOwnerType === 'company' && (
              <CompanySection
                refreshData={refreshData}
                setCurrentStep={setCurrentStep}
                isOpen={isOpen}
                toggleModal={modalCancelToggle}
                updateItem={selectedOwnerEditId ? true : false}
                editObjValues={merchantCompanyEditObj}
                selectedOwnerEditId={selectedOwnerEditId}
              />
            )}

            {currentStep === 2 && selectedOwnerType === 'trust' && (
              <TrustSection
                refreshData={refreshData}
                setCurrentStep={setCurrentStep}
                isOpen={isOpen}
                toggleModal={modalCancelToggle}
                updateItem={selectedOwnerEditId ? true : false}
                editObjValues={merchantTrustEditObj}
                selectedOwnerEditId={selectedOwnerEditId}
                fileFrontDetail={fileFrontDetail}
                fileRemoveHandler={fileRemoveHandler}
              />
            )}

            {currentStep === 2 && selectedOwnerType === 'partnership' && (
              <PartnershipSection
                refreshData={refreshData}
                setCurrentStep={setCurrentStep}
                isOpen={isOpen}
                toggleModal={modalCancelToggle}
                updateItem={selectedOwnerEditId ? true : false}
                editObjValues={merchantPartnershipEditObj}
                selectedOwnerEditId={selectedOwnerEditId}
                fileFrontDetail={fileFrontDetail}
                fileRemoveHandler={fileRemoveHandler}
              />
            )}
          </div>
        </s.Wrapper>
      </Modal>
    </Fragment>
  );
}

export default AddOwnerModal;
