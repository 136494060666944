import { LISTING_RECORDS_PER_PAGE } from 'pages/platform/constants';

import { MerchantStatusObj } from '../constants';

export const getInitialFilterState = () => {
  return {
    dateActiveChecked: false,
    dateValue: '',
    dateTypeCompareValue: '',
    dateTypeFilterValue: '',
    dateRangeValue: '',
    dateSingleValue: null,
    businessTypeChecked: false,
    businessType: '',
    merchantNameChecked: false,
    merchantName: '',
    totalFiltersApplied: 0,
    saveTiggerAt: '',
  };
};

export const getInitialListingPageState = () => {
  return {
    activePage: 1,
    activeStatusTab: MerchantStatusObj.Active,
    sortBy: '-stateUpdatedAt',
    limit: LISTING_RECORDS_PER_PAGE,
  };
};
