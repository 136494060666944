import { ApplicationAreaOption } from 'config/constants';

const getBaseUrl = (slug: string, applicationArea: string) => {
  let baseUrl = `/${
    applicationArea === ApplicationAreaOption.merchant
      ? ApplicationAreaOption.merchant
      : applicationArea === ApplicationAreaOption.merchant_dashboard
      ? ApplicationAreaOption.merchant_dashboard
      : ApplicationAreaOption.platform
  }/${slug}`;

  return baseUrl;
};

export const getMerchantBaseUrl = (merchantName: string) => {
  return getBaseUrl(merchantName, ApplicationAreaOption.merchant);
};

export const getPlatformBaseUrl = (platformName: string) => {
  return getBaseUrl(platformName, ApplicationAreaOption.platform);
};

export const getMerchantDashboardBaseUrl = (merchantName: string) => {
  return getBaseUrl(merchantName, ApplicationAreaOption.merchant_dashboard);
};

export default getBaseUrl;
