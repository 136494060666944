import DatePicker from 'react-date-picker';

import { Icon } from '@limepayments/cosmic';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

interface Props {
  isSingleDateFeild: boolean;
  dateRangeChangeHandler: (value: string | Date | Array<Date>) => void;
  dateChangeHandler: (value: Date | null | undefined | [Date | null, Date | null]) => void;
  dateRangeValue: string | Date | Array<Date>;
  dateSingleValue: Date | null | undefined | [Date | null, Date | null];
}

function DateRangeFilterComponent({
  isSingleDateFeild,
  dateRangeChangeHandler,
  dateChangeHandler,
  dateRangeValue,
  dateSingleValue,
}: Props) {
  return (
    <div className="w100-24">
      {!isSingleDateFeild && (
        <DateRangePicker
          value={dateRangeValue}
          onChange={dateRangeChangeHandler}
          isOpen={false}
          format="dd-MM-y"
          closeCalendar={false}
          calendarIcon={<Icon name="Calendar" className="m-0 p-0" />}
          dayPlaceholder="DD"
          monthPlaceholder="MM"
          yearPlaceholder="YYYY"
        />
      )}

      {isSingleDateFeild && (
        <DatePicker
          value={dateSingleValue}
          onChange={dateChangeHandler}
          isOpen={false}
          format="dd-MM-y"
          closeCalendar={false}
          calendarIcon={<Icon name="Calendar" className="m-0 p-0" />}
          dayPlaceholder="DD"
          monthPlaceholder="MM"
          yearPlaceholder="YYYY"
        />
      )}
    </div>
  );
}

export default DateRangeFilterComponent;
