import {
  getInitialFilterState,
  getInitialListingPageState,
} from 'pages/platform/dashboard/payment/transactions/initialStates';
import { IListingPagePropsObj, ITxnFilterObj } from 'pages/platform/dashboard/payment/transactions/types';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IConfig {
  openTransactionFilterDropdown: boolean;
  selectedTxnId: string | null;
  txnFilterObj: ITxnFilterObj;
  listingPageProps: IListingPagePropsObj;
}

const initialState: IConfig = {
  openTransactionFilterDropdown: false,
  selectedTxnId: null,
  txnFilterObj: getInitialFilterState(),
  listingPageProps: getInitialListingPageState(),
};

export const transactionSlice = createSlice({
  name: 'transactionConfig',
  initialState,
  reducers: {
    setOpenTransactionFilterDropdown: (state, action: PayloadAction<boolean>) => {
      state.openTransactionFilterDropdown = action.payload;
    },
    setSelectedTxnId: (state, action: PayloadAction<string | null>) => {
      state.selectedTxnId = action.payload;
    },
    setTxnFilterObj: (state, action: PayloadAction<ITxnFilterObj>) => {
      state.txnFilterObj = action.payload;
    },
    setListingPagePropsObj: (state, action: PayloadAction<IListingPagePropsObj>) => {
      state.listingPageProps = action.payload;
    },
  },
});

export const { setSelectedTxnId, setOpenTransactionFilterDropdown, setTxnFilterObj, setListingPagePropsObj } =
  transactionSlice.actions;

export default transactionSlice.reducer;
