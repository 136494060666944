import { getSettingBrandingDetail, updateSettingBrandingDetail } from 'api/platform/setting';
import { FormikProps } from 'formik';
import PlatformLayout from 'layouts/platform/PlatformLayout';
import ErrorComponent from 'pages/common/error/ErrorComponent';
import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppSelector } from 'redux/platform/hooks';
import getErrorMessage from 'utils/getErrorMessage';

import { Spinner } from '@limepayments/cosmic';

import BrandingView from './BrandingView';
import { SettingBrandingDetailPayload } from './types';

function SettingsBrandingPage() {
  const [fetchBrandingDetailLoader, setFetchBrandingDetailLoader] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [updateErrorMsg, setUpdateErrorMsg] = useState<string>('');
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [editRequestLoading, setEditRequestLoading] = useState<boolean>(false);
  const [brandingDetail, setBrandingDetail] = useState<SettingBrandingDetailPayload | null>(null);
  const formRef = useRef<FormikProps<SettingBrandingDetailPayload>>(null);
  const [brandEditModalOpen, setBrandEditModalOpen] = useState<boolean>(false);

  const { marketplaceId, apiBaseUri, platformCountry } = useAppSelector((state) => ({
    marketplaceId: state.config.marketplaceId,
    apiBaseUri: state.config.apiBaseUri,
    platformCountry: state.config.platformCountry,
  }));

  const submitHandler = async (values: SettingBrandingDetailPayload, formObj: { resetForm: () => void }) => {
    try {
      setEditRequestLoading(true);
      setUpdateErrorMsg('');

      const { ...postData } = { ...values };

      await updateSettingBrandingDetail(apiBaseUri, marketplaceId, platformCountry, postData);

      toast.success('Branding details updated successfully');
      formObj.resetForm();
      setBrandEditModalOpen(false);
      fetchBrandingDetail();
    } catch (error) {
      setUpdateErrorMsg(getErrorMessage(error));
    } finally {
      setEditRequestLoading(false);
    }
  };

  const fetchBrandingDetail = useCallback(async () => {
    try {
      setFetchBrandingDetailLoader(true);
      setIsPageLoading(true);

      const response = await getSettingBrandingDetail(apiBaseUri, marketplaceId, platformCountry);

      const updateData = {
        branding: response.branding
          ? response.branding
          : {
              supportEmail: '',
              businessDisplayName: '',
              businessWebsite: '',
              iconUri: '',
              logoUri: '',
              logoAltText: '',
            },
        customerSettings: response.customerSettings
          ? response.customerSettings
          : {
              smsSenderName: '',
              cardStatementName: '',
            },
        payoutSettings: response.payoutSettings
          ? response.payoutSettings
          : {
              payoutDescription: '',
            },
      };
      setBrandingDetail(updateData);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setFetchBrandingDetailLoader(false);
    }
  }, [apiBaseUri, marketplaceId, platformCountry]);

  useEffect(() => {
    if (!platformCountry) {
      return;
    }

    setErrorMessage('');
    fetchBrandingDetail();
  }, [apiBaseUri, marketplaceId, platformCountry, fetchBrandingDetail]);

  useEffect(() => {
    if (!fetchBrandingDetailLoader) {
      setIsPageLoading(false);
    }
  }, [fetchBrandingDetailLoader, setIsPageLoading]);

  return (
    <PlatformLayout title="Business settings - Branding" activeTab="settings">
      {!isPageLoading && errorMessage.length ? <ErrorComponent bodyText={errorMessage} /> : null}

      {isPageLoading && (
        <div className="spinner-wrapper">
          <Spinner variant="simple" isVisible label="Loading..." />
        </div>
      )}

      {!isPageLoading && !errorMessage.length && (
        <BrandingView
          errorMsg={updateErrorMsg}
          editRequestLoading={editRequestLoading}
          brandingDetail={brandingDetail}
          onSubmitClick={submitHandler}
          formRef={formRef}
          setBrandEditModalOpen={setBrandEditModalOpen}
          brandEditModalOpen={brandEditModalOpen}
        />
      )}
    </PlatformLayout>
  );
}

export default SettingsBrandingPage;
