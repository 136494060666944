import * as s from 'assets/styles/Platforms.styles';
import { AustralianStates, NewZealandRegions, StreetTypes } from 'config/constants';
import { ErrorMessage, Formik, FormikProps } from 'formik';
import { Fragment, RefObject, useEffect, useRef } from 'react';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import scrollToComponent from 'react-scroll-to-component';
import * as Yup from 'yup';

import { Button, Heading, Link, Message, Modal, Select, Text, TextField } from '@limepayments/cosmic';

import { SettingBusinessProfilePayload } from '../types';

const validationSchema = Yup.object().shape({
  contactPerson: Yup.object().shape({
    firstName: Yup.string().trim().required('This field is required'),
    lastName: Yup.string().trim().required('This field is required'),
    title: Yup.string().trim().required('This field is required'),
  }),
  contactEmail: Yup.string().trim().email('Please enter valid email address').required('This field is required'),
  phoneNo: Yup.string()
    .trim()
    .required('This field is required')
    .test({
      name: 'phone-no-validation',
      test: function () {
        const { phoneNo } = this.parent;
        if (phoneNo && !isPossiblePhoneNumber(phoneNo))
          return this.createError({
            message: `Phone Number is invalid`,
            path: `phoneNo`,
          });
        return true;
      },
    }),
  businessAddress: Yup.string().when('isManualEnterAddress', {
    is: false,
    then: Yup.string().trim().required('This field is required'),
  }),
  address: Yup.object().when('isManualEnterAddress', {
    is: true,
    then: Yup.object().shape({
      streetNumber: Yup.string().trim().required('This field is required'),
      streetName: Yup.string().trim().required('This field is required'),
      streetType: Yup.string().trim().required('This field is required'),
      suburb: Yup.string().trim().required('This field is required'),
      city: Yup.string()
        .when('country', {
          is: 'NZ',
          then: Yup.string().nullable().trim().required('This field is required'),
        })
        .nullable(),
      state: Yup.string()
        .when('country', {
          is: 'AU',
          then: Yup.string().nullable().trim().required('This field is required'),
        })
        .nullable(),
      postalCode: Yup.string()
        .trim()
        .required('This field is required')
        .matches(/^[0-9]+$/, 'Only digits are allowed for this field'),
      country: Yup.string().required('This field is required'),
    }),
  }),
});

interface Props {
  isOpen: boolean;
  modalToggler: (val: boolean) => void;
  initialValues: SettingBusinessProfilePayload;
  onSubmitClick: (values: SettingBusinessProfilePayload, formObj: { resetForm: () => void }) => void;
  formRef: RefObject<FormikProps<SettingBusinessProfilePayload>>;
  errorMsg: string;
  editRequestLoading: boolean;
  platformCountry: string;
}

function BusinessProfileEditModal({
  isOpen,
  initialValues,
  formRef,
  errorMsg,
  editRequestLoading,
  modalToggler,
  onSubmitClick,
  platformCountry,
}: Props) {
  const errorContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (errorMsg.length > 0 && errorContainerRef && errorContainerRef.current) {
      scrollToComponent(errorContainerRef.current, { duration: 500 });
    }
  }, [errorMsg]);

  return (
    <Modal isOpen={isOpen} className="modal-medium update-pass-modal">
      <s.Wrapper className="">
        <div className="modal-main p-24">
          <div className="modal-header">
            <div className="content-main bb-1">
              <Heading alignment="center" tagName="h2" variant="xs" className="mt-12 mb-24 lp-justify-center lp-flex">
                Business contact details
              </Heading>
            </div>
          </div>

          <Formik<SettingBusinessProfilePayload>
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={(values, formObj: { resetForm: () => void }) => {
              onSubmitClick(values, formObj);
            }}
            validationSchema={validationSchema}
            innerRef={formRef}
          >
            {({ values, handleSubmit, errors, touched, setFieldValue, handleChange, handleBlur }) => (
              <form autoComplete="off" onSubmit={handleSubmit} className="content-wrap-form">
                <div className="modal-content p-28 sm-p-0 sm-mt-24">
                  {errorMsg.length > 0 && (
                    <div className="mt-24 mb-24" ref={errorContainerRef}>
                      <Message
                        type="inline"
                        children={<span className="text-wrap">{errorMsg}</span>}
                        variant={'error'}
                      />
                    </div>
                  )}
                  <div className="lp-w-full mb-16">
                    <ul className="two-col lp-flex">
                      <li className="mb-0">
                        <TextField
                          fullWidth
                          label="First name"
                          inputMode="text"
                          testId="business-profile-edit-first-name"
                          autoComplete="off"
                          id="contactPerson.firstName"
                          name="contactPerson.firstName"
                          onBlur={handleBlur}
                          value={values.contactPerson.firstName}
                          onChange={handleChange}
                          error={
                            errors.contactPerson &&
                            errors.contactPerson.firstName &&
                            touched.contactPerson &&
                            touched.contactPerson.firstName
                              ? errors.contactPerson.firstName
                              : ''
                          }
                        />
                      </li>
                      <li className="mb-0">
                        <TextField
                          fullWidth
                          label="Last name"
                          inputMode="text"
                          autoComplete="off"
                          testId="business-profile-edit-last-name"
                          id="contactPerson.lastName"
                          name="contactPerson.lastName"
                          onBlur={handleBlur}
                          value={values.contactPerson.lastName}
                          onChange={handleChange}
                          error={
                            errors.contactPerson &&
                            errors.contactPerson.lastName &&
                            touched.contactPerson &&
                            touched.contactPerson.lastName
                              ? errors.contactPerson.lastName
                              : ''
                          }
                        />
                      </li>
                    </ul>
                  </div>

                  <div className="lp-w-full mb-16">
                    <TextField
                      fullWidth
                      label="Job title"
                      inputMode="text"
                      autoComplete="off"
                      id="contactPerson.title"
                      name="contactPerson.title"
                      onBlur={handleBlur}
                      value={values.contactPerson.title}
                      onChange={handleChange}
                      error={
                        errors.contactPerson &&
                        errors.contactPerson.title &&
                        touched.contactPerson &&
                        touched.contactPerson.title
                          ? errors.contactPerson.title
                          : ''
                      }
                    />
                  </div>

                  <div className="lp-w-full mb-16">
                    <TextField
                      fullWidth
                      label="Contact email address"
                      inputMode="email"
                      type="email"
                      autoComplete="off"
                      id="contactEmail"
                      name="contactEmail"
                      testId="business-profile-edit-email"
                      onBlur={handleBlur}
                      value={values.contactEmail}
                      onChange={handleChange}
                      error={errors.contactEmail && touched.contactEmail ? errors.contactEmail : ''}
                    />
                  </div>

                  <div className={`lp-w-full mb-16${errors.phoneNo && touched.phoneNo ? ` has-error` : ``}`}>
                    <div className={`form-floating no-pl`}>
                      <PhoneInput
                        defaultCountry="AU"
                        value={values.phoneNo}
                        onChange={(e) => {
                          setFieldValue('phoneNo', e);
                        }}
                        onBlur={handleBlur}
                        id="phoneNo"
                        name="phoneNo"
                        className={`${values.phoneNo ? `is-filled` : ``}`}
                        countries={['AU', 'NZ']}
                      />
                      <label htmlFor="floatingPhoneInput">Contact phone number</label>
                    </div>
                    <ErrorMessage name="phoneNo" component="span" className="Mui-error" />
                  </div>

                  {!values.isManualEnterAddress && (
                    <Fragment>
                      <div className="lp-w-full mb-16">
                        <TextField
                          fullWidth
                          label="Business address"
                          inputMode="text"
                          autoComplete="off"
                          id="businessAddress"
                          name="businessAddress"
                          onBlur={handleBlur}
                          value={values.businessAddress}
                          onChange={handleChange}
                          error={errors.businessAddress && touched.businessAddress ? errors.businessAddress : ''}
                          helperText="You can use a home address if the business doesn't have a business address."
                        />
                      </div>

                      <div className="lp-w-full mb-0">
                        <Link
                          onClick={() => setFieldValue('isManualEnterAddress', true)}
                          size="small"
                          className="fw-medium"
                        >
                          Manually enter address
                        </Link>
                      </div>
                    </Fragment>
                  )}

                  {values.isManualEnterAddress && (
                    <Fragment>
                      <header className="content-header mt-24">
                        <div className="lp-w-full mb-24">
                          <Heading alignment="left" tagName="h4" variant="sm">
                            Business address
                          </Heading>
                          <Text variant="body-3">
                            You can use a home address if the business doesn’t have a business address.
                          </Text>
                        </div>
                      </header>

                      <div className="lp-w-full mb-12">
                        <ul className="two-col lp-flex lp-flex-wrap lp-flex-column">
                          <li
                            className={`mb-12${
                              errors.address && errors.address.country && touched.address && touched.address.country
                                ? ` has-error`
                                : ``
                            }`}
                          >
                            <Select
                              label="Country"
                              onChange={(value) => setFieldValue('address.country', value)}
                              value={values.address.country}
                              options={[
                                platformCountry === 'NZ'
                                  ? {
                                      text: 'New Zealand',
                                      value: 'NZ',
                                    }
                                  : {
                                      text: 'Australia',
                                      value: 'AU',
                                    },
                              ]}
                              errorMessage={
                                errors.address && errors.address.country && touched.address && touched.address.country
                                  ? errors.address.country
                                  : ''
                              }
                            />
                          </li>
                          <li className="mb-12">
                            <TextField
                              fullWidth
                              label="Unit number (optional)"
                              inputMode="text"
                              autoComplete="off"
                              id="address.unitNumber"
                              name="address.unitNumber"
                              onBlur={handleBlur}
                              value={values.address.unitNumber}
                              onChange={handleChange}
                              error={
                                errors.address &&
                                errors.address.unitNumber &&
                                touched.address &&
                                touched.address.unitNumber
                                  ? errors.address.unitNumber
                                  : ''
                              }
                            />
                          </li>
                          <li className="mb-12">
                            <TextField
                              fullWidth
                              label="Street number"
                              inputMode="text"
                              autoComplete="off"
                              id="address.streetNumber"
                              name="address.streetNumber"
                              onBlur={handleBlur}
                              value={values.address.streetNumber}
                              onChange={handleChange}
                              error={
                                errors.address &&
                                errors.address.streetNumber &&
                                touched.address &&
                                touched.address.streetNumber
                                  ? errors.address.streetNumber
                                  : ''
                              }
                            />
                          </li>
                          <li className="mb-12">
                            <TextField
                              fullWidth
                              label="Street name"
                              inputMode="text"
                              autoComplete="off"
                              id="address.streetName"
                              name="address.streetName"
                              onBlur={handleBlur}
                              value={values.address.streetName}
                              onChange={handleChange}
                              error={
                                errors.address &&
                                errors.address.streetName &&
                                touched.address &&
                                touched.address.streetName
                                  ? errors.address.streetName
                                  : ''
                              }
                            />
                          </li>
                          <li
                            className={`mb-12 ${
                              errors.address &&
                              errors.address.streetType &&
                              touched.address &&
                              touched.address.streetType
                                ? ` has-error`
                                : ``
                            }`}
                          >
                            <Select
                              label="Street type"
                              onChange={(value) => setFieldValue('address.streetType', value)}
                              value={values.address.streetType}
                              options={StreetTypes}
                              errorMessage={
                                errors.address &&
                                errors.address.streetType &&
                                touched.address &&
                                touched.address.streetType
                                  ? errors.address.streetType
                                  : ''
                              }
                            />
                          </li>
                          <li className="mb-12">
                            <TextField
                              fullWidth
                              label="Suburb"
                              inputMode="text"
                              autoComplete="off"
                              id="address.suburb"
                              name="address.suburb"
                              onBlur={handleBlur}
                              value={values.address.suburb}
                              onChange={handleChange}
                              error={
                                errors.address && errors.address.suburb && touched.address && touched.address.suburb
                                  ? errors.address.suburb
                                  : ''
                              }
                            />
                          </li>
                          {values.address.country === 'NZ' && (
                            <li
                              className={`mb-12 ${
                                errors.address && errors.address.city && touched.address && touched.address.city
                                  ? ` has-error`
                                  : ``
                              }`}
                            >
                              <Select
                                label="City"
                                onChange={(value) => setFieldValue('address.city', value)}
                                value={values.address.city ?? ''}
                                options={NewZealandRegions}
                                errorMessage={
                                  errors.address && errors.address.city && touched.address && touched.address.city
                                    ? errors.address.city
                                    : ''
                                }
                              />
                            </li>
                          )}

                          {values.address.country === 'AU' && (
                            <li
                              className={`mb-12 ${
                                errors.address && errors.address.state && touched.address && touched.address.state
                                  ? ` has-error`
                                  : ``
                              }`}
                            >
                              <Select
                                label="State"
                                onChange={(value) => setFieldValue('address.state', value)}
                                value={values.address.state ?? ''}
                                options={AustralianStates}
                                errorMessage={
                                  errors.address && errors.address.state && touched.address && touched.address.state
                                    ? errors.address.state
                                    : ''
                                }
                              />
                            </li>
                          )}
                          <li className="mb-12">
                            <TextField
                              fullWidth
                              label="Postcode"
                              inputMode="text"
                              autoComplete="off"
                              id="address.postalCode"
                              name="address.postalCode"
                              onBlur={handleBlur}
                              value={values.address.postalCode}
                              onChange={handleChange}
                              error={
                                errors.address &&
                                errors.address.postalCode &&
                                touched.address &&
                                touched.address.postalCode
                                  ? errors.address.postalCode
                                  : ''
                              }
                            />
                          </li>
                        </ul>
                      </div>
                    </Fragment>
                  )}
                </div>
                <div className="modal-footer lp-flex lp-justify-end sm-mt-24">
                  <Button
                    onClick={() => modalToggler(false)}
                    size="medium"
                    variant="ghost"
                    className="no-min-width w-70"
                    testId="business-profile-edit-cancel-button"
                    disabled={editRequestLoading}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size="medium"
                    variant="primary"
                    className="no-min-width"
                    testId="business-profile-edit-save-button"
                    disabled={editRequestLoading}
                    isLoading={editRequestLoading}
                  >
                    Save
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </s.Wrapper>
    </Modal>
  );
}

export default BusinessProfileEditModal;
