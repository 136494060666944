import { getMerchantStatus } from 'api/merchant/onbording/introduction';
import { getMerchantBusinessRegistration } from 'api/merchant/onbording/step-01';
import { getListMerchantTrustOwners } from 'api/merchant/onbording/step-05';
import MerchantLayout from 'layouts/merchant/MerchantLayout';
import ErrorComponent from 'pages/common/error/ErrorComponent';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/merchant/hooks';
import { setCurrentActiveStep } from 'redux/merchant/slice/merchantOnboardSlice';
import { getMerchantBaseUrl } from 'utils/getBaseUrl';
import getErrorMessage from 'utils/getErrorMessage';

import { Spinner } from '@limepayments/cosmic';

import OnboardingRoutesList from '../constants';
import IntroductionView from './IntroductionView';

function IntroductionPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [errorMsg, setErrorMsg] = useState<string>('');

  const { merchantName, branding, merchantTaxCountry, apiBaseUri, merchantId } = useAppSelector((state) => ({
    merchantName: state.config.merchantName,
    branding: state.config.branding,
    merchantTaxCountry: state.config.merchantTaxCountry,
    apiBaseUri: state.config.apiBaseUri,
    merchantId: state.config.merchantId,
  }));

  useEffect(() => {
    if (!merchantId || !apiBaseUri || !merchantName) {
      return;
    }

    const fetchMerchantBusinessProfile = async () => {
      try {
        setIsPageLoading(true);
        setErrorMsg('');

        const response = await getMerchantStatus(apiBaseUri, merchantId);

        if (response.state === 'OnboardingReview') {
          // Review page redirect condition
          const merchantTrustOwnersList = await getListMerchantTrustOwners(apiBaseUri, merchantId);
          const response = await getMerchantBusinessRegistration(apiBaseUri, merchantId);

          navigate(`${getMerchantBaseUrl(merchantName)}/onboarding/${OnboardingRoutesList.ONBOARDING_STEP07_PAGE}`, {
            state: {
              businessType: response.businessType,
              hasTrustOwner: merchantTrustOwnersList.length ? true : false,
            },
          });
        }

        if (response.state !== 'Onboarding' && response.state !== 'OnboardingReview') {
          setErrorMsg('Not a valid merchant state');
        }
      } catch (err) {
        setErrorMsg(getErrorMessage(err));
      } finally {
        setIsPageLoading(false);
      }
    };

    fetchMerchantBusinessProfile();
  }, [apiBaseUri, merchantName, merchantId, navigate]);

  useEffect(() => {
    dispatch(setCurrentActiveStep(1));
  }, [dispatch]);

  return (
    <MerchantLayout hideSideBar={true} title="Onboarding Introduction">
      {!isPageLoading && errorMsg.length ? <ErrorComponent bodyText={errorMsg} /> : null}

      {isPageLoading && (
        <div className="spinner-wrapper">
          <Spinner variant="simple" isVisible label="Loading..." />
        </div>
      )}

      {!isPageLoading && !errorMsg.length && (
        <IntroductionView
          onSubmitClick={() => {
            navigate(`${getMerchantBaseUrl(merchantName)}/onboarding/${OnboardingRoutesList.ONBOARDING_STEP01_PAGE}`);
          }}
          merchantName={
            branding && branding.marketplaceBusinessDisplayName ? branding.marketplaceBusinessDisplayName : ''
          }
          merchantCountry={merchantTaxCountry}
        />
      )}
    </MerchantLayout>
  );
}

export default IntroductionPage;
