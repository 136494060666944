import { PaginatedResponse, handlePaginatedResponse, handleResponse } from 'api/utils';
import { getAprilApiHost, getLimepayApiHost } from 'utils/env';
import getFetchOptions from 'utils/getFetchOptions';

import { PayoutAPIResponseType, PayoutDetailAPIResponseType } from './payouts.types';
import { GetMerchantBalancesResponse } from './settlement.types';

export const getMerchantPayoutsList = async (
  queryString: string,
): Promise<PaginatedResponse<PayoutAPIResponseType[]>> => {
  const options = await getFetchOptions();
  return await fetch(`${await getAprilApiHost()}/transactions/payouts?${queryString}`, options).then(
    handlePaginatedResponse,
  );
};

export const getMerchantPayoutDetail = async (payoutId: string): Promise<PayoutDetailAPIResponseType> => {
  const options = await getFetchOptions();
  return await fetch(`${await getAprilApiHost()}/transactions/payouts/${payoutId}`, options).then(handleResponse);
};

export const getMerchantBalances = async (merchantId: string): Promise<GetMerchantBalancesResponse> => {
  const options = await getFetchOptions();
  return await fetch(`${await getLimepayApiHost()}/dashboard/merchant/${merchantId}/balances`, options).then(
    handleResponse,
  );
};
