import MerchantDefaultRoutes from 'pages/merchant/constants';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/merchant/hooks';
import { getMerchantBaseUrl } from 'utils/getBaseUrl';

import { Button } from '@limepayments/cosmic';

import DashboardRoutesList from '../../constants';
import PayoutRoutesList from '../constants';

interface Props {
  activeTab: string;
}

const PayoutSidebar = ({ activeTab }: Props) => {
  const navigate = useNavigate();
  const { merchantName } = useAppSelector((state) => ({
    merchantName: state.config.merchantName,
  }));

  return (
    <div className="payment-sidebar">
      <div className="payment-sidebar-inner">
        <Button
          size="small"
          variant="primary"
          className={`lp-w-full lp-button-transaction${
            activeTab === DashboardRoutesList.PAYOUT_ROUTE ? ` is-selected` : ``
          }`}
          onClick={() =>
            navigate(
              `${getMerchantBaseUrl(merchantName)}/${MerchantDefaultRoutes.DASHBOARD_ROUTE}/${
                DashboardRoutesList.PAYOUT_ROUTE
              }/${PayoutRoutesList.PAYOUT_LANDING}`,
            )
          }
        >
          Balances
        </Button>
        <Button
          size="small"
          variant="primary"
          className={`lp-w-full lp-button-transaction${
            activeTab === DashboardRoutesList.PAYOUT_BREAKDOWN_ROUTE ? ` is-selected` : ``
          }`}
          onClick={() =>
            navigate(
              `${getMerchantBaseUrl(merchantName)}/${MerchantDefaultRoutes.DASHBOARD_ROUTE}/${
                DashboardRoutesList.PAYOUT_ROUTE
              }/${DashboardRoutesList.PAYOUT_BREAKDOWN_ROUTE}/${PayoutRoutesList.PAYOUT_BREAKDOWN}`,
            )
          }
        >
          Payout breakdown
        </Button>
      </div>
    </div>
  );
};

export default PayoutSidebar;
