import { exportPlatformPaymentsList } from 'api/platform/payments';
import { ExportPlatformPaymentsListPayload, PlatformDashboardPaymentsObj } from 'api/platform/payments.types';
import PaymentSidebar from 'layouts/platform/PaymentSidebar';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { toast } from 'react-toastify';
import { useAppSelector } from 'redux/platform/hooks';
import getErrorMessage from 'utils/getErrorMessage';

import { Button, FlexContainer, Heading, Icon } from '@limepayments/cosmic';

import { PaymentExportColumnsObj } from '../../constants';
import { IExportFilterObj } from '../types';
import ExportComponent from './ExportComponent';
import FilterComponent from './FilterComponent';
import PaymentsDataTable from './PaymentsDataTable';

const getInitialExportState = () => {
  return {
    exportWithAppliedFilter: false,
    dateActiveRangeValue: '',
    exportDateRangeValue: '',
    exportColumnsSelectedList: [],
    exportDataLoader: false,
    exportDataCSV: null,
    exportSuccessfully: false,
    errorMessage: '',
    status: '',
  };
};

export interface Props {
  paymentList: Array<PlatformDashboardPaymentsObj>;
  fetchListLoader: boolean;
  totalRows: number;
  handlePerRowsChange: (newPerPage: number, page: number) => void;
  handlePageChange: (page: number) => void;
  limit: number;
  toggleFilter: () => void;
  filterPopupOpen: boolean;
  activePage: number;
  handleSort: (selectedColumn: TableColumn<PlatformDashboardPaymentsObj>, sortDirection: SortOrder) => void;
  defaultExportOpened?: boolean;
}

function PaymentsList({
  paymentList,
  totalRows,
  limit,
  fetchListLoader,
  handlePageChange,
  handlePerRowsChange,
  toggleFilter,
  filterPopupOpen,
  activePage,
  handleSort,
  defaultExportOpened,
}: Props) {
  const [isExportOpened, setIsExportOpened] = useState(defaultExportOpened ?? false);

  // Export variables
  const [exportFilterObj, setExportFilterObj] = useState<IExportFilterObj>(getInitialExportState());

  const { platformName, marketplaceId, apiBaseUri, paymentFilterObj } = useAppSelector((state) => ({
    platformName: state.config.platformName,
    marketplaceId: state.config.marketplaceId,
    apiBaseUri: state.config.apiBaseUri,
    paymentFilterObj: state.payment.paymentFilterObj,
  }));

  const getFilterApplyCount = () => {
    return paymentFilterObj.totalFiltersApplied;
  };

  const exportFilterChangeHandler = (key: string, value: string | boolean | Date | Date[]) => {
    setExportFilterObj((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const toggleExportHandler = (val: boolean, closeSection?: boolean) => {
    if (val) {
      resetExportValuesHandler();
    }

    if (closeSection) {
      toggleExport();
    }
  };

  const resetExportValuesHandler = () => {
    setExportFilterObj(getInitialExportState());
  };

  const exportSaveClickHandler = async () => {
    try {
      let from = '';
      let to = '';

      let postData: ExportPlatformPaymentsListPayload = {
        query: {},
        columns: [],
      };

      if (exportFilterObj.exportColumnsSelectedList.length < 1) {
        setExportFilterObj((prevState) => ({
          ...prevState,
          errorMessage: getErrorMessage('Please select atleast one column to export data'),
        }));
        return;
      }

      if (exportFilterObj.exportColumnsSelectedList.length > 0) {
        postData.columns = exportFilterObj.exportColumnsSelectedList;
      }

      if (exportFilterObj.exportWithAppliedFilter && paymentFilterObj.statusValue) {
        postData.query.status = paymentFilterObj.statusValue;
      }

      if (exportFilterObj.exportWithAppliedFilter && paymentFilterObj.merchantId) {
        postData.query.merchantId = paymentFilterObj.merchantId;
      }

      if (exportFilterObj.exportWithAppliedFilter && paymentFilterObj.internalOrderId) {
        postData.query.internalOrderId = paymentFilterObj.internalOrderId;
      }

      if (exportFilterObj.exportWithAppliedFilter && paymentFilterObj.txnId) {
        postData.query.transactionId = paymentFilterObj.txnId;
      }

      if (exportFilterObj.dateActiveRangeValue === 'today') {
        from = `${DateTime.utc().startOf('day').toISO()}`;
        to = `${DateTime.utc().endOf('day').toISO()}`;
      }

      if (exportFilterObj.dateActiveRangeValue === 'current_month') {
        from = `${DateTime.utc().startOf('month').startOf('day').toISO()}`;
        to = `${DateTime.utc().endOf('day').toISO()}`;
      }

      if (exportFilterObj.dateActiveRangeValue === 'one_week') {
        from = `${DateTime.utc().minus({ days: 7 }).startOf('day').toISO()}`;
        to = `${DateTime.utc().endOf('day').toISO()}`;
      }

      if (exportFilterObj.dateActiveRangeValue === 'one_month') {
        from = `${DateTime.utc().minus({ month: 1 }).startOf('month').startOf('day').toISO()}`;
        to = `${DateTime.utc().minus({ month: 1 }).endOf('month').endOf('day').toISO()}`;
      }

      if (exportFilterObj.dateActiveRangeValue === 'custom' && Array.isArray(exportFilterObj.exportDateRangeValue)) {
        const fromDateObj = new Date(exportFilterObj.exportDateRangeValue[0]);
        const toDateObj = new Date(exportFilterObj.exportDateRangeValue[1]);

        const fromDateLuxonObj = DateTime.fromISO(fromDateObj.toISOString()).toUTC();
        const toDateLuxonObj = DateTime.fromISO(toDateObj.toISOString()).toUTC();

        const diff = toDateLuxonObj.diff(fromDateLuxonObj, ['years', 'months', 'days', 'hours']);

        if (diff.months > 3 && diff.days > 0) {
          toast.error('Please select date range within 3 months');
          return;
        }

        from = `${DateTime.fromISO(fromDateObj.toISOString()).toUTC().startOf('day').toISO()}`;
        to = `${DateTime.fromISO(toDateObj.toISOString()).toUTC().startOf('day').toISO()}`;
      }

      if (from) {
        postData.query.from = from;
      }

      if (to) {
        postData.query.to = to;
      }

      setExportFilterObj((prevState) => ({
        ...prevState,
        exportDataLoader: true,
        errorMessage: '',
      }));

      const response = await exportPlatformPaymentsList(apiBaseUri, marketplaceId, postData);
      setExportFilterObj((prevState) => ({
        ...prevState,
        exportDataCSV: response,
        exportSuccessfully: true,
      }));
    } catch (error) {
      setExportFilterObj((prevState) => ({
        ...prevState,
        errorMessage: getErrorMessage(error),
      }));
    } finally {
      setExportFilterObj((prevState) => ({
        ...prevState,
        exportDataLoader: false,
      }));
    }
  };

  const exportColumnsSelectedListHandler = (val: string, isSelectAll?: boolean) => {
    let updatedList = [...exportFilterObj.exportColumnsSelectedList];

    if (!val && isSelectAll) {
      updatedList =
        exportFilterObj.exportColumnsSelectedList.length === Object.keys(PaymentExportColumnsObj).length
          ? []
          : Object.keys(PaymentExportColumnsObj).map((obj) => obj);
    }

    if (val && exportFilterObj.exportColumnsSelectedList.includes(val)) {
      updatedList = exportFilterObj.exportColumnsSelectedList.filter((obj) => {
        return obj !== val;
      });
    }

    if (val && !exportFilterObj.exportColumnsSelectedList.includes(val)) {
      updatedList.push(val);
    }

    setExportFilterObj((prevState) => ({
      ...prevState,
      exportColumnsSelectedList: updatedList,
    }));
  };

  const toggleExport = () => {
    setIsExportOpened((wasExportOpened) => !wasExportOpened);
  };

  return (
    <div className="content-wrap-inner">
      <div className="lp-w-full lp-flex">
        <div className="payment-sidebar">
          <PaymentSidebar platformName={platformName} activeSection="payment" />
        </div>
        <div className="payment-content-area">
          <div className="lp-w-full lp-flex lp-justify-between sm-lp-flex-col mb-28">
            <Heading
              alignment="left"
              tagName="h2"
              variant="xl"
              className="page-title mb-0"
              testId="payment-page-heading"
            >
              Payments
            </Heading>
            <div className="merchant-controls sm-mt-16">
              <FlexContainer classNames="lp-flex">
                <div className="lp-flex order-2 position-relative">
                  <Button
                    className="lp-w-full"
                    onClick={() => toggleFilter()}
                    size="small"
                    variant="reversed"
                    disabled={paymentList.length < 1 && getFilterApplyCount() === 0}
                    testId="payment-filter-button"
                  >
                    <Icon name="Filter" className="ml-0" />
                    Filter ({getFilterApplyCount()})
                  </Button>
                  <Button
                    className="lp-w-full"
                    onClick={toggleExport}
                    size="small"
                    variant="reversed"
                    disabled={paymentList.length < 1}
                    testId="payment-export-button"
                  >
                    <Icon name="ExternalLink" className="ml-0" />
                    Export
                  </Button>

                  {/* Filter Dropdown */}
                  {filterPopupOpen && <FilterComponent modalToggler={toggleFilter} />}

                  {/* Export Dropdown */}
                  {isExportOpened && (
                    <div className="filter-dropdown w-656">
                      <ExportComponent
                        onCloseExportClick={toggleExportHandler}
                        exportSaveClickHandler={exportSaveClickHandler}
                        exportFilterObj={exportFilterObj}
                        setExportColumnsSelectedList={exportColumnsSelectedListHandler}
                        exportFilterChangeHandler={exportFilterChangeHandler}
                        filterCount={getFilterApplyCount()}
                      />
                    </div>
                  )}
                </div>
              </FlexContainer>
            </div>
          </div>

          <PaymentsDataTable
            paymentList={paymentList}
            fetchListLoader={fetchListLoader}
            totalRows={totalRows}
            handlePerRowsChange={handlePerRowsChange}
            handlePageChange={handlePageChange}
            limit={limit}
            activePage={activePage}
            handleSort={handleSort}
          />
        </div>
      </div>
    </div>
  );
}

export default PaymentsList;
