import { Button, Heading, Illustration, Link, Text } from '@limepayments/cosmic';

interface Props {
  onSubmitClick: () => void;
  email: string;
}

function ResetPasswordLinkExpiredView({ onSubmitClick, email }: Props) {
  return (
    <>
      <div className="lp-w-full mb-32">
        <div className="size-136 mx-auto mb-32">
          <Illustration name="Time" size="large" />
        </div>
        <Heading alignment="center" tagName="h1" variant="md" className="mb-12">
          Password reset link expired
        </Heading>
        <Text testId="reset-password-link-exp-text" tagName="p" variant="body-3" alignment="center" className="mb-0">
          Your link to create a new password for <Link className="fw-medium">{email}</Link> has expired.
        </Text>
        <Text tagName="p" variant="body-3" alignment="center" className="mt-0">
          Please submit a new request to reset your password.
        </Text>
      </div>
      <Button onClick={onSubmitClick} size="medium" variant="primary" className="lp-w-full mb-8">
        Re-send instructions
      </Button>
    </>
  );
}

export default ResetPasswordLinkExpiredView;
