import { handleResponse } from 'api/utils';
import getFetchOptions from 'utils/getFetchOptions';

import {
  ExportPlatformTransactionsListPayload,
  PlatformDashboardTransactionsObj,
  PlatformTransactionDetailObj,
} from './transactions.types';

export const getTransactionsList = async (
  host: string,
  marketplaceId: string,
  queryString: string,
): Promise<{ totalPages: number; totalRecords: number; items: Array<PlatformDashboardTransactionsObj> }> => {
  const options = await getFetchOptions();
  return await fetch(`${host}/platform/${marketplaceId}/dashboard/journals?${queryString}`, options).then(
    handleResponse,
  );
};

export const getTransactionDetail = async (
  host: string,
  marketplaceId: string,
  payoutId: string,
): Promise<PlatformTransactionDetailObj> => {
  const options = await getFetchOptions();
  return await fetch(`${host}/platform/${marketplaceId}/dashboard/journals/payouts/${payoutId}`, options).then(
    handleResponse,
  );
};

export const exportPlatformTransactionsList = async (
  host: string,
  marketplaceId: string,
  payload: ExportPlatformTransactionsListPayload,
): Promise<string> => {
  const options = await getFetchOptions('POST', { payload });
  return await fetch(`${host}/platform/${marketplaceId}/dashboard/journals/csv`, options).then(handleResponse);
};

export const downloadPlatformTransactionDetail = async (
  host: string,
  merchantId: string,
  payoutId: string,
): Promise<string> => {
  const options = await getFetchOptions();
  return await fetch(`${host}/reports/merchants/${merchantId}/settlement/payout/csv/${payoutId}`, options).then(
    handleResponse,
  );
};
