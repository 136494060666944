import getFetchOptions from 'utils/getFetchOptions';

import { handleResponse } from '../utils';
import {
  ResetPasswordRequest,
  ResetPasswordResponse,
  SendResetPasswordEmailRequest,
  ValidateTokenResponse,
} from './platform-identity.types';

export const sendResetPasswordEmail = async (host: string, payload: SendResetPasswordEmailRequest): Promise<void> =>
  fetch(`${host}/authn/send-reset-marketplace-password-email`, await getFetchOptions('POST', { payload })).then(
    handleResponse,
  );

export const validateToken = async (host: string, limepayToken: string): Promise<ValidateTokenResponse> =>
  fetch(`${host}/authn/validate-marketplace-usertoken`, await getFetchOptions('POST', { limepayToken })).then(
    handleResponse,
  );

export const resetPassword = async (
  host: string,
  limepayToken: string,
  payload: ResetPasswordRequest,
): Promise<ResetPasswordResponse> =>
  fetch(`${host}/authn/reset-marketplace-user-password`, await getFetchOptions('POST', { payload, limepayToken })).then(
    handleResponse,
  );
