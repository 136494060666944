import DashboardRoutesList from 'pages/platform/dashboard/constants';
import PaymentRoutesList from 'pages/platform/dashboard/payment/constants';
import { useNavigate } from 'react-router-dom';
import { getPlatformBaseUrl } from 'utils/getBaseUrl';

import { Button } from '@limepayments/cosmic';

interface Props {
  platformName: string;
  activeSection: string;
}

function PaymentSidebar({ platformName, activeSection }: Props) {
  const navigate = useNavigate();

  return (
    <div className="payment-sidebar-inner">
      <Button
        size="small"
        onClick={() =>
          navigate(
            `${getPlatformBaseUrl(platformName)}/dashboard/${DashboardRoutesList.PAYMENT_ROUTE}/${
              PaymentRoutesList.PAYMENT_LANDING
            }`,
          )
        }
        variant="primary"
        className={`lp-w-full lp-button-transaction ${activeSection === `payment` ? ` is-selected` : ``}`}
        testId="sidebar-payment-menu-item"
      >
        Payments
      </Button>
      <Button
        size="small"
        onClick={() =>
          navigate(
            `${getPlatformBaseUrl(platformName)}/dashboard/${DashboardRoutesList.PAYMENT_ROUTE}/${
              PaymentRoutesList.PAYMENT_TRANSACTIONS
            }`,
          )
        }
        variant="primary"
        className={`lp-w-full lp-button-transaction ${activeSection === `transaction` ? ` is-selected` : ``}`}
        testId="sidebar-transaction-menu-item"
      >
        All transactions
      </Button>
    </div>
  );
}

export default PaymentSidebar;
