import { Button, Heading, Illustration, Text } from '@limepayments/cosmic';

interface Props {
  onSubmitClick: () => void;
}

function ResetPasswordInvalidTokenView({ onSubmitClick }: Props) {
  return (
    <>
      <div className="lp-w-full mb-32">
        <div className="size-136 mx-auto mb-32">
          <Illustration name="Time" size="large" />
        </div>
        <Heading alignment="center" tagName="h1" variant="md" className="mb-12">
          Invalid Password reset token
        </Heading>
        <Text tagName="p" variant="body-3" alignment="center" className="mb-0">
          Your token passed to reset the password is not valid.
        </Text>
        <Text tagName="p" variant="body-3" alignment="center" className="mt-0">
          Please submit a new request to reset your password.
        </Text>
      </div>
      <Button onClick={onSubmitClick} size="medium" variant="primary" className="lp-w-full mb-8">
        Re-send instructions
      </Button>
    </>
  );
}

export default ResetPasswordInvalidTokenView;
