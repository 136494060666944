import * as s from 'assets/styles/Platforms.styles';
import { Formik, FormikProps } from 'formik';
import { RefObject, useEffect, useRef } from 'react';
import * as Yup from 'yup';

import { Button, Heading, Message, Modal, PasswordField } from '@limepayments/cosmic';

import { SettingPasswordUpdatePayload } from '../types';

const validationSchema = Yup.object().shape({
  password: Yup.string().trim().required('Please enter your password'),
  newPassword: Yup.string()
    // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/, 'Must contain one upper and lower case character and a digit')
    .required('Please enter a password')
    .min(8, 'Password must be 8 or more characters'),
  confirmNewPassword: Yup.string()
    .required('Please confirm your password')
    .min(8, 'Password must be 8 or more characters')
    .oneOf([Yup.ref('newPassword'), null], 'Please re-confirm your password, passwords do not match'),
});

interface Props {
  isOpen: boolean;
  modalToggler: (val: boolean) => void;
  initialValues: SettingPasswordUpdatePayload;
  onSubmitClick: (values: SettingPasswordUpdatePayload, formObj: { resetForm: () => void }) => void;
  formRef: RefObject<FormikProps<SettingPasswordUpdatePayload>>;
  errorMsg: string;
  updateLoader: boolean;
}

function PasswordUpdateModal({
  isOpen,
  initialValues,
  formRef,
  errorMsg,
  updateLoader,
  modalToggler,
  onSubmitClick,
}: Props) {
  const errorContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (errorMsg.length > 0 && errorContainerRef && errorContainerRef.current) {
      errorContainerRef.current.scrollIntoView();
    }
  }, [errorMsg]);

  return (
    <Modal isOpen={isOpen} className="modal-medium update-pass-modal">
      <s.Wrapper className="">
        <div className="modal-main p-24">
          <div className="modal-header">
            <div className="content-main bb-1">
              <Heading alignment="center" tagName="h2" variant="xs" className="mt-12 mb-24 lp-justify-center lp-flex">
                Update password
              </Heading>
            </div>
          </div>
          <Formik<SettingPasswordUpdatePayload>
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={(values, formObj: { resetForm: () => void }) => {
              onSubmitClick(values, formObj);
            }}
            validationSchema={validationSchema}
            innerRef={formRef}
          >
            {({ values, handleSubmit, errors, touched, handleChange, handleBlur }) => (
              <form autoComplete="off" onSubmit={handleSubmit} className="content-wrap-form">
                <div className="modal-content p-28 sm-p-0 sm-mt-24">
                  {errorMsg.length > 0 && (
                    <div className="mt-24 mb-24" ref={errorContainerRef}>
                      <Message
                        type="inline"
                        children={<span className="text-wrap">{errorMsg}</span>}
                        variant={'error'}
                      />
                    </div>
                  )}
                  <div className="lp-w-full mb-8">
                    <PasswordField
                      fullWidth
                      inputMode="text"
                      type="password"
                      label="Old password"
                      autoComplete="off"
                      id="password"
                      name="password"
                      value={values.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={errors.password && touched.password ? errors.password : ''}
                    />
                  </div>
                  <div className="lp-w-full mb-0">
                    <div className="lp-w-full mb-8">
                      <PasswordField
                        fullWidth
                        inputMode="text"
                        type="password"
                        label="New password"
                        autoComplete="off"
                        id="newPassword"
                        name="newPassword"
                        value={values.newPassword}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={errors.newPassword && touched.newPassword ? errors.newPassword : ''}
                      />
                    </div>
                    <div className="lp-w-full mb-0">
                      <PasswordField
                        fullWidth
                        inputMode="text"
                        type="password"
                        label="Confirm new password"
                        autoComplete="off"
                        id="confirmNewPassword"
                        name="confirmNewPassword"
                        value={values.confirmNewPassword}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={errors.confirmNewPassword && touched.confirmNewPassword ? errors.confirmNewPassword : ''}
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer lp-flex lp-justify-end sm-mt-24">
                  <Button
                    onClick={() => modalToggler(false)}
                    size="medium"
                    variant="ghost"
                    className="no-min-width w-70"
                    disabled={updateLoader}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size="medium"
                    variant="primary"
                    className="no-min-width"
                    disabled={updateLoader}
                    isLoading={updateLoader}
                  >
                    Save
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </s.Wrapper>
    </Modal>
  );
}

export default PasswordUpdateModal;
