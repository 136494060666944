import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';

import { Button, Checkbox, CheckboxGroup, Heading, Link, Message, PasswordField, Text } from '@limepayments/cosmic';

const validationSchema = Yup.object().shape({
  newPassword: Yup.string()
    // .min(8, 'New Password must have at least 8 characters')
    // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/, 'Must contain one upper and lower case character and a digit')
    .required('Please enter your password'),
  confirmNewPassword: Yup.string()
    .required('Please confirm your password')
    .oneOf([Yup.ref('newPassword'), null], 'Please re-confirm your password, passwords do not match'),
  termsAgreed: Yup.boolean()
    .required('This is a required field')
    .oneOf([true], 'The terms and conditions must be accepted.'),
});

type FormikFormValues = {
  newPassword: string;
  confirmNewPassword: string;
  termsAgreed: boolean;
};

interface Props {
  onSubmitClick: (values: FormikFormValues, formObj: { resetForm: () => void }) => void;
  email: string;
  errorMsg: string;
  initialValues: FormikFormValues;
  passwordUpdateRequestLoader: boolean;
}

function ActivationAccountView({ onSubmitClick, email, errorMsg, initialValues, passwordUpdateRequestLoader }: Props) {
  return (
    <>
      <div className="lp-w-full mb-32">
        <Heading alignment="center" tagName="h1" variant="md" className="mb-12">
          Activate your account
        </Heading>
        <Text tagName="p" variant="body-3" alignment="center" testId="activateAccountText">
          Create a password to activate your account for{' '}
          <Link onClick={function noRefCheck() {}} target="_self" className="fw-medium">
            {email}
          </Link>
          .
        </Text>
        {errorMsg.length > 0 && (
          <Message type="inline" children={<span className="text-wrap">{errorMsg}</span>} variant={'error'} />
        )}
      </div>
      <Formik<FormikFormValues>
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values, formObj: { resetForm: () => void }) => {
          onSubmitClick(values, formObj);
        }}
        validationSchema={validationSchema}
      >
        {({ values, handleSubmit, errors, touched, handleChange, handleBlur, setFieldValue }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <div className="lp-w-full mb-32">
              <div className="lp-w-full mb-8">
                <PasswordField
                  fullWidth
                  inputMode="text"
                  type="password"
                  label="New password"
                  autoComplete="off"
                  startAdornment="Filled-Key"
                  testId="newPassword"
                  id="newPassword"
                  name="newPassword"
                  onBlur={handleBlur}
                  value={values.newPassword}
                  onChange={handleChange}
                  error={errors.newPassword && touched.newPassword ? errors.newPassword : ''}
                />
              </div>
              <div className="lp-w-full mb-0">
                <PasswordField
                  fullWidth
                  inputMode="text"
                  type="password"
                  label="Confirm password"
                  autoComplete="off"
                  startAdornment="Filled-Key"
                  testId="confirmNewPassword"
                  id="confirmNewPassword"
                  name="confirmNewPassword"
                  onBlur={handleBlur}
                  value={values.confirmNewPassword}
                  onChange={handleChange}
                  error={errors.confirmNewPassword && touched.confirmNewPassword ? errors.confirmNewPassword : ''}
                />
              </div>
            </div>
            <div
              className={`lp-w-full mb-32 agree-checkbox${
                errors.termsAgreed && touched.termsAgreed ? ` has-error` : ``
              }`}
            >
              <CheckboxGroup>
                <Checkbox
                  id="termsAgreed"
                  label={
                    <p data-testid="activate-account-terms-text">
                      I've read and agree to April's{' '}
                      <Link size="small" href="https://meetapril.com/merchant-terms/" target="_blank">
                        Terms of Use
                      </Link>{' '}
                      and{' '}
                      <Link size="small" href="https://meetapril.com/privacy/" target="_blank">
                        Group Privacy Policy
                      </Link>
                      .
                    </p>
                  }
                  variant="body-3"
                  checked={values.termsAgreed}
                  onChange={() => setFieldValue('termsAgreed', !values.termsAgreed)}
                  testId="termsAgreed"
                />
              </CheckboxGroup>
              <ErrorMessage name="termsAgreed" component="span" className="Mui-error" />
            </div>
            <Button
              type="submit"
              size="medium"
              testId="passwordUpdateButton"
              variant="primary"
              className={`lp-w-full ${passwordUpdateRequestLoader ? `is-loading` : ``}`}
            >
              Create password
            </Button>
          </form>
        )}
      </Formik>
    </>
  );
}

export default ActivationAccountView;
