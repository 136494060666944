import { Formik } from 'formik';
import * as Yup from 'yup';

import { Button, Heading, Illustration, Message, PasswordField } from '@limepayments/cosmic';

const validationSchema = Yup.object().shape({
  newPassword: Yup.string()
    // .min(8, 'New Password must have at least 8 characters')
    // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/, 'Must contain one upper and lower case character and a digit')
    .required('This is a required field'),
  confirmNewPassword: Yup.string()
    .required('This is a required field')
    .oneOf([Yup.ref('newPassword'), null], "Password and Confirm password don't match"),
});

type FormikFormValues = {
  newPassword: string;
  confirmNewPassword: string;
};

interface Props {
  onSubmitClick: (values: FormikFormValues, formObj: { resetForm: () => void }) => void;
  initialValues: FormikFormValues;
  requestLoader: boolean;
  errorMsg: string;
}

function ResetPasswordView({ onSubmitClick, initialValues, requestLoader, errorMsg }: Props) {
  return (
    <>
      <div className="lp-w-full mb-32">
        <div className="size-136 mx-auto mb-32">
          <Illustration name="Data" size="large" />
        </div>
        <Heading testId="reset-password-page-title" alignment="center" tagName="h1" variant="md" className="mb-12">
          Create a new password
        </Heading>
        {errorMsg.length > 0 && (
          <Message type="inline" children={<span className="text-wrap">{errorMsg}</span>} variant={'error'} />
        )}
      </div>
      <Formik<FormikFormValues>
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values, formObj: { resetForm: () => void }) => {
          onSubmitClick(values, formObj);
        }}
        validationSchema={validationSchema}
      >
        {({ values, handleSubmit, errors, touched, handleChange, handleBlur }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <div className="lp-w-full mb-32">
              <div className="lp-w-full mb-8">
                <PasswordField
                  fullWidth
                  inputMode="text"
                  type="password"
                  label="New password"
                  autoComplete="off"
                  startAdornment="Filled-Key"
                  testId="newPassword"
                  id="newPassword"
                  name="newPassword"
                  onBlur={handleBlur}
                  value={values.newPassword}
                  onChange={handleChange}
                  error={errors.newPassword && touched.newPassword ? errors.newPassword : ''}
                />
              </div>
              <div className="lp-w-full mb-0">
                <PasswordField
                  fullWidth
                  inputMode="text"
                  type="password"
                  label="Confirm password"
                  autoComplete="off"
                  startAdornment="Filled-Key"
                  testId="confirmNewPassword"
                  id="confirmNewPassword"
                  name="confirmNewPassword"
                  onBlur={handleBlur}
                  value={values.confirmNewPassword}
                  onChange={handleChange}
                  error={errors.confirmNewPassword && touched.confirmNewPassword ? errors.confirmNewPassword : ''}
                />
              </div>
            </div>
            <Button
              testId="reset-password-submit-button"
              type="submit"
              size="medium"
              variant="primary"
              className={`lp-w-full`}
              isLoading={requestLoader}
            >
              Create password
            </Button>
          </form>
        )}
      </Formik>
    </>
  );
}

export default ResetPasswordView;
