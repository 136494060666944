import getCurrentUserToken from './getCurrentUserToken';

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

export interface GetFetchOptions<P = unknown> extends RequestInit {
  headers?: Record<string, string>;
  payload?: P;
  bearerToken?: string;
  limepayToken?: string;
}

const getFetchOptions = async <P = unknown>(
  method: HttpMethod | 'PATCH' = 'GET',
  options?: GetFetchOptions<P>,
): Promise<RequestInit> => {
  const { headers = {}, payload, bearerToken, limepayToken, ...fetchOptions } = options || {};

  if (payload instanceof FormData) {
    fetchOptions['body'] = payload;
  } else if (typeof payload !== 'undefined') {
    headers['Content-Type'] = 'application/json';
    fetchOptions['body'] = JSON.stringify(payload);
  }

  try {
    const token = bearerToken || (await getCurrentUserToken());
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
  } catch (error) {}

  if (limepayToken) {
    headers['Limepay-Token'] = limepayToken;
  }

  return {
    ...fetchOptions,
    method,
    headers,
  };
};

export default getFetchOptions;
