import AUSquare from 'assets/images/icons/AU-Square-24.svg';
import NZFlagIcon from 'assets/images/icons/NZ-Flag-24.svg';

const AUFlag = () => {
  return <img src={AUSquare} width="24" height="24" className="" alt="AU" />;
};

export const NZFlag = () => {
  return <img src={NZFlagIcon} width="24" className="" alt="NZ" />;
};

export default AUFlag;
