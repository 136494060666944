import { useState } from 'react';
import { Popover } from 'react-tiny-popover';

import { Button, Icon } from '@limepayments/cosmic';

// Import Static UIs
import TableActionsDropdowns from './TableActionsDropdowns';
import { CustomFieldActions } from './types';

interface Props {
  onEditDelete: (key: string, action: CustomFieldActions) => void;
  customFieldKey: string;
}

const PopoverComponent = ({ onEditDelete, customFieldKey }: Props) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  return (
    <Popover
      isOpen={isPopoverOpen}
      align={'end'}
      positions={['bottom', 'top', 'left', 'right']}
      reposition={false}
      onClickOutside={() => setIsPopoverOpen(false)}
      content={
        <div className="t-action-dropdown w-204">
          <TableActionsDropdowns
            onClickDelete={() => {
              onEditDelete(customFieldKey, CustomFieldActions.DELETE);
              setIsPopoverOpen(!isPopoverOpen);
            }}
            onClickEdit={() => {
              onEditDelete(customFieldKey, CustomFieldActions.EDIT);
              setIsPopoverOpen(!isPopoverOpen);
            }}
          />
        </div>
      }
    >
      <div className="table-actions" onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
        <Button className="lp-w-full" size="small" variant="reversed">
          <Icon name="Ellipse" className="m-0" />
        </Button>
      </div>
    </Popover>
  );
};

export default PopoverComponent;
