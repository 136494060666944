import { getMerchantPayoutDetail } from 'api/merchant/dashboard/payouts';
import { PayoutDetailAPIResponseType } from 'api/merchant/dashboard/payouts.types';
import MerchantDashboardLayout from 'layouts/merchant-dashboard/MerchantDashboardLayout';
import MerchantDefaultRoutes from 'pages/merchant/constants';
import qs from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/merchant/hooks';
import { getMerchantBaseUrl } from 'utils/getBaseUrl';
import getErrorMessage from 'utils/getErrorMessage';

import { DashboardRoutesList } from '../../constants';
import { PayoutRoutesList } from '../constants';
import PayoutDetails from './PayoutDetails';

function PayoutDetailPage() {
  const navigate = useNavigate();
  const { payoutId } = qs.parse(window.location.search);

  // detail section variables
  const [showDetailLoader, setShowDetailLoader] = useState<boolean>(false); // TODO
  const [payoutDetailObj, setPayoutDetailObj] = useState<PayoutDetailAPIResponseType | null>(null);
  const [detailErrorMsg, setDetailErrorMsg] = useState<string>('');

  const { merchantName } = useAppSelector((state) => ({
    merchantName: state.config.merchantName,
  }));

  const detailSectionBackClickhandler = () => {
    navigate(
      `${getMerchantBaseUrl(merchantName)}/${MerchantDefaultRoutes.DASHBOARD_ROUTE}/${
        DashboardRoutesList.PAYOUT_ROUTE
      }/${PayoutRoutesList.PAYOUT_LANDING}`,
    );
  };

  const detailClickHandler = useCallback(async () => {
    try {
      if (typeof payoutId !== 'string') return;

      setShowDetailLoader(true);

      const response = await getMerchantPayoutDetail(payoutId);

      setPayoutDetailObj(response);
    } catch (error) {
      setDetailErrorMsg(getErrorMessage(error));
    } finally {
      setShowDetailLoader(false);
    }
  }, [payoutId]);

  useEffect(() => {
    if (payoutId) {
      detailClickHandler();
    }
  }, [payoutId, detailClickHandler]);

  return (
    <MerchantDashboardLayout activeTab="payout" title="Payout Detail">
      <PayoutDetails
        onBackButtonClick={() => detailSectionBackClickhandler()}
        showDetailLoader={showDetailLoader}
        payoutDetailObj={payoutDetailObj}
        detailErrorMsg={detailErrorMsg}
      />
    </MerchantDashboardLayout>
  );
}

export default PayoutDetailPage;
