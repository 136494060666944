export const OnboardingRoutesList = {
  ONBOARDING_PAGE: '',
  ONBOARDING_STEP01_PAGE: 'step-01',
  ONBOARDING_STEP02_PAGE: 'step-02',
  ONBOARDING_STEP03_PAGE: 'step-03',
  ONBOARDING_STEP04_PAGE: 'step-04',
  ONBOARDING_STEP05_PAGE: 'step-05',
  ONBOARDING_STEP05_SECOND_PAGE: 'step-05_2',
  ONBOARDING_STEP06_PAGE: 'step-06',
  ONBOARDING_STEP07_PAGE: 'step-07',
  ONBOARDING_STEP07_SECOND_PAGE: 'step-07_2',
  ONBOARDING_STEP08_PAGE: 'step-08',
};

export default OnboardingRoutesList;
