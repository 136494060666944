export const SettingRoutesList = {
  SETTINGS_BRANDING: 'branding',
  SETTINGS_BUSINESS_PROFILE: 'business-profile',
  SETTINGS_BALANCES: 'balances',
  SETTINGS_PROFILE: 'my-profile',
  SETTINGS_FEE_STRUCTURE: 'fee-structure',
  SETTINGS_PAY_BY_LINK: 'pay-by-link',
  SETTINGS_API_KEYS: 'api-keys',
  SETTINGS_FRAUD_PREVENTIONS: 'fraud-preventions',
  SETTINGS_PAYMENT: 'payments',
};

export const APPLE_DOMAIN_ASSOCIATION_FILE_URL =
  'https://static.au.meetapril.com/.well-known/apple-developer-merchantid-domain-association';

export default SettingRoutesList;
