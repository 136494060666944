import { DateTime } from 'luxon';
import { Fragment, useCallback, useEffect, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { useAppDispatch, useAppSelector } from 'redux/platform/hooks';
import { setListingPagePropsObj, setMerchantFilterObj } from 'redux/platform/slice/merchantSlice';

import { Button, Checkbox, CheckboxGroup, Heading, Icon, Text } from '@limepayments/cosmic';

import { MerchantStatusObj } from '../../constants';
import { getInitialFilterState } from '../initialStates';
import { IFilterObj, IFilterObjError } from '../types';
import DateRangeFilterComponent from './DateRangeFilterComponent';

const getInitialErrorState = () => {
  return {
    dateValueError: '',
    dateTypeCompareError: '',
    dateTypeFilterError: '',
    dateRangeError: '',
    dateSingleError: '',
    businessTypeError: '',
    businessNameError: '',
  };
};

interface Props {
  activeStatusTab: string;
  modalToggler: () => void;
}

function FilterComponent({ activeStatusTab, modalToggler }: Props) {
  const dispatch = useAppDispatch();
  const { merchantFilterObj, listingPageProps } = useAppSelector((state) => ({
    merchantFilterObj: state.merchant.merchantFilterObj,
    listingPageProps: state.merchant.listingPageProps,
  }));

  const [filterObj, setFilterObj] = useState<IFilterObj>(merchantFilterObj);
  const [filterObjError, setFilterObjError] = useState<IFilterObjError>(getInitialErrorState());

  const filterChangeHandler = useCallback(
    (key: string, value: boolean | string | Date | Array<Date> | null | undefined | [Date | null, Date | null]) => {
      setFilterObj((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    },
    [],
  );

  const filterSaveHandler = () => {
    const filterObjErrorRef = { ...getInitialErrorState() };
    let hasError = false;
    let totalFiltersApplied = 0;

    if (filterObj.dateActiveChecked) {
      if (!filterObj.dateTypeCompareValue) {
        filterObjErrorRef.dateTypeCompareError = 'This field is required';
        hasError = true;
      }

      if (filterObj.dateTypeCompareValue === 'is_in_the_last') {
        if (!filterObj.dateTypeFilterValue) {
          filterObjErrorRef.dateTypeFilterError = 'This field is required';
          hasError = true;
        }

        if (!filterObj.dateValue) {
          filterObjErrorRef.dateValueError = 'This field is required';
          hasError = true;
        }
      }

      if (filterObj.dateTypeCompareValue === 'is_between') {
        if (!filterObj.dateRangeValue) {
          filterObjErrorRef.dateRangeError = 'This field is required';
          hasError = true;
        }
      }

      if (
        filterObj.dateTypeCompareValue === 'is_equal_to' ||
        filterObj.dateTypeCompareValue === 'is_after' ||
        filterObj.dateTypeCompareValue === 'is_before'
      ) {
        if (!filterObj.dateSingleValue) {
          filterObjErrorRef.dateSingleError = 'This field is required';
          hasError = true;
        }
      }

      if (filterObj.dateTypeCompareValue === 'is_in_the_last' && !filterObjErrorRef.dateValueError) {
        if (filterObj.dateTypeFilterValue === 'days' && parseInt(filterObj.dateValue) > 180) {
          filterObjErrorRef.dateValueError = 'Maximum 180 days allowed';
          hasError = true;
        }

        if (filterObj.dateTypeFilterValue === 'month' && parseInt(filterObj.dateValue) > 6) {
          filterObjErrorRef.dateValueError = 'Maximum 6 months allowed';
          hasError = true;
        }
      }

      if (
        filterObj.dateTypeCompareValue === 'is_between' &&
        Array.isArray(filterObj.dateRangeValue) &&
        !filterObjErrorRef.dateValueError
      ) {
        const fromDateObj = new Date(filterObj.dateRangeValue[0]);
        const toDateObj = new Date(filterObj.dateRangeValue[1]);

        const fromDateLuxonObj = DateTime.fromISO(fromDateObj.toISOString()).toUTC();
        const toDateLuxonObj = DateTime.fromISO(toDateObj.toISOString()).toUTC();

        const diff = toDateLuxonObj.diff(fromDateLuxonObj, ['years', 'months', 'days', 'hours']);

        if (diff.months > 6 && diff.days > 0) {
          filterObjErrorRef.dateValueError = 'Maximum 180 days allowed';
          hasError = true;
        }
      }

      totalFiltersApplied++;
    }

    if (filterObj.businessTypeChecked) {
      if (!filterObj.businessType) {
        filterObjErrorRef.businessTypeError = 'This field is required';
        hasError = true;
      }
      totalFiltersApplied++;
    }

    if (filterObj.merchantNameChecked) {
      if (!filterObj.merchantName) {
        filterObjErrorRef.businessNameError = 'This field is required';
        hasError = true;
      }
      totalFiltersApplied++;
    }

    if (hasError) {
      setFilterObjError(filterObjErrorRef);
      return;
    }

    filterObj.totalFiltersApplied = totalFiltersApplied;
    filterObj.saveTiggerAt = DateTime.now().toISO();

    if (
      merchantFilterObj.businessType !== filterObj.businessType ||
      merchantFilterObj.merchantName !== filterObj.merchantName ||
      merchantFilterObj.dateSingleValue !== filterObj.dateSingleValue ||
      merchantFilterObj.dateRangeValue !== filterObj.dateRangeValue ||
      merchantFilterObj.dateValue !== filterObj.dateValue
    ) {
      dispatch(setMerchantFilterObj(filterObj));
      dispatch(
        setListingPagePropsObj({
          ...listingPageProps,
          activePage: 1,
        }),
      );
    }
    modalToggler();
  };

  const clearFunction = () => {
    setFilterObj(getInitialFilterState());
  };

  const dateRangeChangeHandler = useCallback((value: string | Date | Array<Date>) => {
    setFilterObj((prevState) => ({
      ...prevState,
      dateRangeValue: value,
    }));
  }, []);

  const dateChangeHandler = useCallback((value: Date | null | undefined | [Date | null, Date | null]) => {
    setFilterObj((prevState) => ({
      ...prevState,
      dateSingleValue: value,
    }));
  }, []);

  useEffect(() => {
    if (!filterObj.businessTypeChecked && filterObj.businessType) {
      filterChangeHandler('businessType', '');
    }
  }, [filterObj.businessTypeChecked, filterObj.businessType, filterChangeHandler]);

  useEffect(() => {
    if (!filterObj.merchantNameChecked && filterObj.merchantName) {
      filterChangeHandler('merchantName', '');
    }
  }, [filterObj.merchantNameChecked, filterObj.merchantName, filterChangeHandler]);

  useEffect(() => {
    if (!filterObj.dateActiveChecked) {
      filterChangeHandler('dateTypeCompareValue', '');
      filterChangeHandler('dateTypeFilterValue', '');
      filterChangeHandler('dateValue', '');
      dateRangeChangeHandler('');
      dateChangeHandler(null);
    }
  }, [filterObj.dateActiveChecked, filterChangeHandler, dateRangeChangeHandler, dateChangeHandler]);

  return (
    <ClickAwayListener onClickAway={() => modalToggler()}>
      <div className={`filter-dropdown ${filterObj.dateTypeCompareValue === `is_between` ? `w-449` : `w-328`}`}>
        <div className="lp-flex lp-flex-column">
          <div className="filter-header">
            <Heading alignment="left" tagName="h2" variant="xs" className="mb-0">
              Filters
            </Heading>
            <Button
              type="button"
              onClick={() => modalToggler()}
              size="small"
              variant="ghost"
              className="button-close m-0 p-0"
            >
              <Icon name="Cross" className="m-0 p-0" />
            </Button>
          </div>
          <div className="filter-body">
            <CheckboxGroup>
              <div className="checkbox-items">
                <Checkbox
                  id="dateActiveChecked"
                  testId="merchant-filter-date-check"
                  label={`Date ${
                    activeStatusTab === MerchantStatusObj.Pending ? 'created' : activeStatusTab.toLowerCase()
                  }`}
                  variant="body-2"
                  checked={filterObj.dateActiveChecked}
                  onChange={() => filterChangeHandler('dateActiveChecked', !filterObj.dateActiveChecked)}
                />
                {filterObj.dateActiveChecked && (
                  <div className="checkbox-items-details">
                    <div className="lp-w-full mb-16">
                      <div className="selectdropdown">
                        <Icon name="ChevronDown" className="arrow-down" />
                        <select
                          className="form-control"
                          value={filterObj.dateTypeCompareValue}
                          onChange={(e) => filterChangeHandler('dateTypeCompareValue', e.target.value)}
                        >
                          <option value="">please select</option>
                          <option value="is_in_the_last">is in the last</option>
                          <option value="is_equal_to">is equal to</option>
                          <option value="is_between">is between</option>
                          <option value="is_after">is after</option>
                          <option value="is_before">is before</option>
                        </select>
                      </div>
                      {filterObjError.dateTypeCompareError && (
                        <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                          {filterObjError.dateTypeCompareError}
                        </Text>
                      )}
                    </div>

                    {filterObj.dateTypeCompareValue ? (
                      <Fragment>
                        {filterObj.dateTypeCompareValue === 'is_in_the_last' && (
                          <Fragment>
                            <div className="lp-w-full lp-flex lp-align-center mb-0">
                              <div className="w-24">
                                <Icon name="Return" className="text-primary" />
                              </div>
                              <div className="w-40">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={filterObj.dateValue}
                                  onChange={(e) =>
                                    filterChangeHandler('dateValue', e.target.value.replace(/[^0-9]/g, ''))
                                  }
                                />
                              </div>
                              <div className="selectdropdown">
                                <Icon name="ChevronDown" className="arrow-down" />
                                <select
                                  className="form-control"
                                  value={filterObj.dateTypeFilterValue}
                                  onChange={(e) => filterChangeHandler('dateTypeFilterValue', e.target.value)}
                                >
                                  <option value="">please select</option>
                                  <option value="days">days</option>
                                  <option value="month">months</option>
                                </select>
                              </div>
                            </div>

                            {(filterObjError.dateValueError || filterObjError.dateTypeFilterError) && (
                              <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                                {filterObjError.dateValueError
                                  ? filterObjError.dateValueError
                                  : filterObjError.dateTypeFilterError}
                              </Text>
                            )}
                          </Fragment>
                        )}

                        {filterObj.dateTypeCompareValue !== 'is_in_the_last' && (
                          <Fragment>
                            <div className="lp-w-full lp-flex lp-align-center mb-0">
                              <div className="w-24">
                                <Icon name="Return" className="text-primary" />
                              </div>
                              <DateRangeFilterComponent
                                isSingleDateFeild={filterObj.dateTypeCompareValue !== 'is_between' ? true : false}
                                dateRangeChangeHandler={dateRangeChangeHandler}
                                dateChangeHandler={dateChangeHandler}
                                dateRangeValue={filterObj.dateRangeValue}
                                dateSingleValue={filterObj.dateSingleValue}
                              />
                            </div>

                            {(filterObjError.dateRangeError || filterObjError.dateSingleError) && (
                              <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                                {filterObjError.dateRangeError
                                  ? filterObjError.dateRangeError
                                  : filterObjError.dateSingleError}
                              </Text>
                            )}
                          </Fragment>
                        )}
                      </Fragment>
                    ) : null}
                  </div>
                )}
              </div>
              <div className="checkbox-items">
                <Checkbox
                  id="businessTypeChecked"
                  testId="merchant-filter-business-check"
                  label="Business type"
                  variant="body-2"
                  checked={filterObj.businessTypeChecked}
                  onChange={() => filterChangeHandler('businessTypeChecked', !filterObj.businessTypeChecked)}
                />
                {filterObj.businessTypeChecked && (
                  <div className="checkbox-items-details">
                    <div className="lp-w-full mb-0">
                      <div className="selectdropdown">
                        <Icon name="ChevronDown" className="arrow-down" />
                        <select
                          className="form-control"
                          value={filterObj.businessType}
                          onChange={(e) => filterChangeHandler('businessType', e.target.value)}
                        >
                          <option value="">please select</option>
                          <option value="soletrader">Sole trader</option>
                          <option value="company">Company</option>
                          <option value="partnership">Partnership</option>
                          <option value="trust">Trust</option>
                        </select>
                      </div>
                      {filterObjError.businessTypeError && (
                        <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                          {filterObjError.businessTypeError}
                        </Text>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="checkbox-items">
                <Checkbox
                  id="merchantNameChecked"
                  testId="merchant-filter-merchant-name-check"
                  label="Merchant name"
                  variant="body-2"
                  checked={filterObj.merchantNameChecked}
                  onChange={() => filterChangeHandler('merchantNameChecked', !filterObj.merchantNameChecked)}
                />
                {filterObj.merchantNameChecked && (
                  <div className="checkbox-items-details">
                    <div className="lp-w-full filter-search position-relative mb-0">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select merchant"
                        value={filterObj.merchantName}
                        onChange={(e) => filterChangeHandler('merchantName', e.target.value)}
                      />
                      {/* <Button
                        type="button"
                        onClick={() => filterSaveHandler()}
                        size="small"
                        variant="ghost"
                        className="button-search"
                      >
                        <Icon name="Search" className="m-0 p-0 text-primary" />
                      </Button> */}
                    </div>
                    {filterObjError.businessNameError && (
                      <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                        {filterObjError.businessNameError}
                      </Text>
                    )}
                  </div>
                )}
              </div>
            </CheckboxGroup>
          </div>
          <div className="filter-footer">
            <Button
              className="lp-w-full"
              onClick={() => clearFunction()}
              size="medium"
              variant="reversed"
              disabled={
                filterObj.dateActiveChecked || filterObj.businessTypeChecked || filterObj.merchantNameChecked
                  ? false
                  : true
              }
            >
              Clear
            </Button>
            <Button
              className="lp-w-full"
              onClick={() => filterSaveHandler()}
              size="medium"
              variant="primary"
              testId="merchant-filter-save-button"
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </ClickAwayListener>
  );
}

export default FilterComponent;
