import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IConfig {
  payoutMerchantId: string;
}

const initialState: IConfig = {
  payoutMerchantId: '',
};

export const settingSlice = createSlice({
  name: 'settingConfig',
  initialState,
  reducers: {
    setPayoutMerchantId: (state, action: PayloadAction<string>) => {
      state.payoutMerchantId = action.payload;
    },
  },
});

export const { setPayoutMerchantId } = settingSlice.actions;

export default settingSlice.reducer;
