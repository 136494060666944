import { getMerchantFile, uploadOnboardingMerchantFile } from 'api/merchant/onbording/file';
import { GetMerchantFileResponse } from 'api/merchant/onbording/file.types';
import { getListMerchantPerson, updateMerchantPerson } from 'api/merchant/onbording/step-05';
import { FormikProps } from 'formik';
import ErrorPage from 'pages/common/error';
import OnboardingRoutesList from 'pages/merchant/onboarding/constants';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/merchant/hooks';
import { setCurrentActiveStep, setSaveAndFinishLater } from 'redux/merchant/slice/merchantOnboardSlice';
import { getMerchantBaseUrl } from 'utils/getBaseUrl';
import getErrorMessage from 'utils/getErrorMessage';

import { Button, Heading, Icon, Spinner, Text } from '@limepayments/cosmic';

import { CreateMerchantPersonPayload, CreateMerchantPersonPayloadApi } from '../../types';
import IndividualForm from '../private/partials/IndividualForm';

interface Props {
  onPreviousClick: () => void;
  isSoleTrader: boolean;
}

function PublicStep05View({ onPreviousClick, isSoleTrader }: Props) {
  const navigation = useNavigate();
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [fetchDataErrorMessage, setFetchDataErrorMessage] = useState<string>('');
  const [selectedOwnerEditId, setSelectedOwnerEditId] = useState<string>('');
  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);
  const [ownerListLoader, setOwnerListLoader] = useState<boolean>(true);
  const [updateItem, setUpdateItem] = useState<boolean>(false);
  const [fileFrontUploading, setFileFrontUploading] = useState<boolean>(false);
  const [fileBackUploading, setFileBackUploading] = useState<boolean>(false);
  const [selectedFileBack, setSelectedFileBack] = useState<File | null>(null);
  const [selectedFileFront, setSelectedFileFront] = useState<File | null>(null);
  const [fileFrontDetail, setFileFrontDetail] = useState<GetMerchantFileResponse | null>(null);
  const [fileBackDetail, setFileBackDetail] = useState<GetMerchantFileResponse | null>(null);
  const [fetchFileFrontLoader, setFetchFileFrontLoader] = useState<boolean>(false);
  const [fetchFileBackLoader, setFetchFileBackLoader] = useState<boolean>(false);
  const formRef = useRef<FormikProps<CreateMerchantPersonPayload>>(null);
  const [initialValues, setInitialValues] = useState<CreateMerchantPersonPayload>({
    title: '',
    roles: [isSoleTrader ? 'Owner' : 'Director'],
    firstName: '',
    middleName: '',
    lastName: '',
    dob: '',
    address: {
      unitNumber: '',
      streetNumber: '',
      streetName: '',
      streetType: '',
      suburb: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
    },
    email: '',
    phone: '',
    passport: {
      country: '',
      passportNumber: '',
      expiryDate: '',
    },
    driverLicence: {
      country: '',
      driverLicenceNumber: '',
      documentNumber: '',
      state: '',
    },
    verificationDocs: {
      idType: '',
      docBackFileId: '',
      docFrontFileId: '',
    },
    businessAddress: '',
    isManualEnterAddress: true,
    updateItem: false,
  });
  const [isVerified, setIsVerified] = useState<boolean>(false);

  const { merchantId, apiBaseUri, merchantName, saveAndFinishLater, hasCompletedSteps } = useAppSelector((state) => ({
    merchantId: state.config.merchantId,
    apiBaseUri: state.config.apiBaseUri,
    merchantName: state.config.merchantName,
    saveAndFinishLater: state.onboarding.saveAndFinishLater,
    hasCompletedSteps: state.onboarding.hasCompletedSteps,
  }));

  const fileUploadHandler = async (type: string) => {
    try {
      type === 'front' ? setFileFrontUploading(true) : setFileBackUploading(true);
      setErrorMessage('');

      const formData = new FormData();
      if (selectedFileFront && type === 'front') {
        formData.append('file', selectedFileFront, selectedFileFront.name);
        formData.append('docType', 'PersonVerificationDocumentFront');
      }

      if (selectedFileBack && type === 'back') {
        formData.append('file', selectedFileBack, selectedFileBack.name);
        formData.append('docType', 'PersonVerificationDocumentBack');
      }

      const response = await uploadOnboardingMerchantFile(apiBaseUri, merchantId, formData);
      return response.limeFileId;
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      type === 'front' ? setFileFrontUploading(false) : setFileBackUploading(false);
    }

    return '';
  };

  const fileFrontChangeHandler = (file: FileList | null) => {
    if (file && file.length > 0) {
      setSelectedFileFront(file[0]);
    } else {
      setSelectedFileFront(null);
    }
  };

  const fileBackChangeHandler = (file: FileList | null) => {
    if (file && file.length > 0) {
      setSelectedFileBack(file[0]);
    } else {
      setSelectedFileBack(null);
    }
  };

  const submitHandler = async (values: CreateMerchantPersonPayload, formObj: { resetForm: () => void }) => {
    try {
      const { selectedFileFront, selectedFileBack, isManualEnterAddress, businessAddress, ...rest } = { ...values };
      const postData: CreateMerchantPersonPayloadApi = JSON.parse(JSON.stringify(rest));

      if (!selectedFileFront && !updateItem) {
        setErrorMessage(
          `${
            postData.verificationDocs.idType === 'DriverLicence'
              ? `Please select driving license front`
              : `Please select passport page`
          }`,
        );
        return;
      }

      if (!selectedFileBack && postData.verificationDocs.idType === 'DriverLicence' && !updateItem) {
        setErrorMessage('Please select driving license back');
        return;
      }

      if (selectedFileFront) {
        const fileId = await fileUploadHandler('front');
        if (!fileId) {
          return;
        }

        postData.verificationDocs.docFrontFileId = fileId;
      }

      if (selectedFileBack && postData.verificationDocs.idType === 'DriverLicence') {
        const fileId = await fileUploadHandler('back');
        if (!fileId) {
          return;
        }

        postData.verificationDocs.docBackFileId = fileId;
      }

      if (!postData.middleName) {
        delete postData.middleName;
      }

      const dobDate = values.dob.split('/');
      postData.dob = `${dobDate[2]}-${dobDate[1]}-${dobDate[0]}`;

      if (postData.verificationDocs.idType === 'Passport') {
        delete postData.driverLicence;
        delete postData.verificationDocs.docBackFileId;
      }

      if (postData.verificationDocs.idType === 'DriverLicence') {
        delete postData.passport;
      }

      if (postData.verificationDocs.idType === 'Passport' && postData.passport && values.passport) {
        const passportExpiryDate = values.passport.expiryDate.split('/');
        postData.passport.expiryDate = `${passportExpiryDate[2]}-${passportExpiryDate[1]}-${passportExpiryDate[0]}`;
      }

      if (!postData.address.city) {
        delete postData.address.city;
      }

      if (!postData.address.state) {
        delete postData.address.state;
      }

      setIsRequestLoading(true);
      setErrorMessage('');

      await updateMerchantPerson(apiBaseUri, merchantId, selectedOwnerEditId, postData);

      formObj.resetForm();
      navigation(`${getMerchantBaseUrl(merchantName)}/onboarding/${OnboardingRoutesList.ONBOARDING_STEP06_PAGE}`);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setIsRequestLoading(false);
    }
  };

  const fetchFileDetailHandler = useCallback(
    async (fileId: string, type: string) => {
      try {
        type === 'front' ? setFetchFileFrontLoader(true) : setFetchFileBackLoader(true);
        const response = await getMerchantFile(apiBaseUri, merchantId, fileId);

        type === 'front' ? setFileFrontDetail(response) : setFileBackDetail(response);
      } catch (error) {
        setErrorMessage(getErrorMessage(error));
      } finally {
        type === 'front' ? setFetchFileFrontLoader(false) : setFetchFileBackLoader(false);
      }
    },
    [apiBaseUri, merchantId],
  );

  useEffect(() => {
    const fetchOwnerListHandler = async () => {
      try {
        setOwnerListLoader(true);

        const response = await getListMerchantPerson(apiBaseUri, merchantId);

        // Individual or Public company type
        if (response.length > 0) {
          const merchantResponseEditObj = {
            title: response[0].profile.title,
            roles: response[0].profile.roles,
            firstName: response[0].profile.firstName,
            middleName: response[0].profile.middleName,
            lastName: response[0].profile.lastName,
            dob: getFormattedDate(response[0].profile.dob),
            address: response[0].profile.address,
            email: response[0].profile.email,
            phone: response[0].profile.phone,
            passport: {
              country: response[0].profile.passport ? response[0].profile.passport.country : '',
              passportNumber: response[0].profile.passport ? response[0].profile.passport.passportNumber : '',
              expiryDate: response[0].profile.passport ? getFormattedDate(response[0].profile.passport.expiryDate) : '',
            },
            driverLicence: response[0].profile.driverLicence
              ? response[0].profile.driverLicence
              : {
                  country: '',
                  driverLicenceNumber: '',
                  documentNumber: '',
                  state: '',
                },
            verificationDocs: response[0].profile.verificationDocs,
            businessAddress: '',
            isManualEnterAddress: true,
            selectedFileFront: null,
            selectedFileBack: null,
            updateItem: true,
          };
          if (response[0].verificationResult.status === 'Verified') {
            setIsVerified(true);
          }
          await fetchFileDetailHandler(response[0].profile.verificationDocs.docFrontFileId, 'front');
          if (response[0].profile.verificationDocs.docBackFileId) {
            await fetchFileDetailHandler(response[0].profile.verificationDocs.docBackFileId, 'back');
          }
          setInitialValues(merchantResponseEditObj);
          setUpdateItem(true);
          setSelectedOwnerEditId(response[0].personId);
          dispatch(setCurrentActiveStep(6));
        }
      } catch (error) {
        setFetchDataErrorMessage(getErrorMessage(error));
      } finally {
        setOwnerListLoader(false);
      }
    };

    const getFormattedDate = (value: string) => {
      const dateValueArray = value.split('-');

      return `${dateValueArray[1]}/${dateValueArray[2]}/${dateValueArray[0]}`;
    };

    setFetchDataErrorMessage('');
    fetchOwnerListHandler();
  }, [apiBaseUri, merchantId, fetchFileDetailHandler, dispatch]);

  useEffect(() => {
    if (formRef && formRef.current && saveAndFinishLater) {
      formRef.current.setFieldValue('isSaveFinishLater', true);
      setTimeout(() => {
        if (formRef && formRef.current) {
          formRef.current.submitForm();
        }
      }, 500);
    }
  }, [saveAndFinishLater]);

  return (
    <div className="content-wrap-inner">
      {!ownerListLoader && !fetchFileFrontLoader && !fetchFileBackLoader && fetchDataErrorMessage.length ? (
        <ErrorPage bodyText={errorMessage} />
      ) : null}

      {(ownerListLoader || fetchFileFrontLoader || fetchFileBackLoader) && (
        <div className="spinner-wrapper">
          <Spinner variant="simple" isVisible label="Loading..." />
        </div>
      )}

      {!ownerListLoader && !fetchFileFrontLoader && !fetchFileBackLoader && !fetchDataErrorMessage.length && (
        <Fragment>
          <header className="content-header">
            <div className="lp-w-full mb-24">
              <div className="lp-w-full mb-16">
                <Button
                  size="small"
                  variant="ghost"
                  isNeutral
                  className="back-button"
                  type="button"
                  onClick={onPreviousClick}
                >
                  <Icon name="ArrowLeft" className="back-arrow" />
                  Back
                </Button>
              </div>
              <Heading alignment="left" tagName="h2" variant="md" className="mb-24">
                Verify your business details
              </Heading>
              <Heading alignment="left" tagName="h4" variant="sm" className="mb-8">
                Business owners and directors
              </Heading>
              <Text alignment="left" variant="body-3" className="mb-24">
                Provide the personal details of one director of the business.
              </Text>
            </div>
          </header>

          <IndividualForm
            isOpen={false}
            toggleModal={(val: boolean) => {}}
            setCurrentStep={(val: number) => {}}
            errorMessage={errorMessage}
            initialValues={initialValues}
            submitHandler={(values: CreateMerchantPersonPayload, formObj: { resetForm: () => void }) =>
              !isVerified
                ? submitHandler(values, formObj)
                : navigation(
                    `${getMerchantBaseUrl(merchantName)}/onboarding/${OnboardingRoutesList.ONBOARDING_STEP06_PAGE}`,
                  )
            }
            formRef={formRef}
            selectedFileFront={selectedFileFront}
            selectedFileBack={selectedFileBack}
            fileFrontChangeHandler={fileFrontChangeHandler}
            fileBackChangeHandler={fileBackChangeHandler}
            isRequestLoading={isRequestLoading}
            fileFrontUploading={fileFrontUploading}
            fileBackUploading={fileBackUploading}
            isPublicSection={true}
            updateItem={updateItem}
            fileFrontDetail={fileFrontDetail}
            fileBackDetail={fileBackDetail}
            isVerified={isVerified}
            saveAndFinishLater={() => {
              dispatch(setSaveAndFinishLater(Date.now()));
            }}
            saveAndReview={hasCompletedSteps}
          />
        </Fragment>
      )}
    </div>
  );
}

export default PublicStep05View;
