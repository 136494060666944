import DashboardRoutesList from 'pages/platform/dashboard/constants';
import SettingRoutesList from 'pages/platform/dashboard/settings/constants';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/platform/hooks';
import { getPlatformBaseUrl } from 'utils/getBaseUrl';

import { Button, Heading } from '@limepayments/cosmic';

interface Props {
  activeTab?: string;
}

function SettingsSidebar({ activeTab }: Props) {
  const navigate = useNavigate();
  const { platformName, payoutMerchantId } = useAppSelector((state) => ({
    platformName: state.config.platformName,
    payoutMerchantId: state.setting.payoutMerchantId,
  }));

  return (
    <div className="payment-sidebar-inner">
      <div className="lp-w-full mb-32">
        <Heading alignment="left" tagName="h2" variant="xs" className="sidebar-title mb-0">
          My account
        </Heading>
        <Button
          size="small"
          onClick={() =>
            navigate(
              `${getPlatformBaseUrl(platformName)}/dashboard/${DashboardRoutesList.SETTINGS_ROUTE}/${
                SettingRoutesList.SETTINGS_PROFILE
              }`,
            )
          }
          variant="primary"
          className={`lp-w-full lp-button-transaction ${activeTab === `profile` ? `is-selected` : ``}`}
        >
          My profile
        </Button>
      </div>

      <div className="lp-w-full mb-32">
        <Heading alignment="left" tagName="h2" variant="xs" className="sidebar-title mb-0">
          Business settings
        </Heading>
        <Button
          size="small"
          onClick={() =>
            navigate(
              `${getPlatformBaseUrl(platformName)}/dashboard/${DashboardRoutesList.SETTINGS_ROUTE}/${
                SettingRoutesList.SETTINGS_BUSINESS_PROFILE
              }`,
            )
          }
          variant="primary"
          className={`lp-w-full lp-button-transaction ${activeTab === `business-profile` ? `is-selected` : ``}`}
          testId="setting-business-profile-menu-button"
        >
          Business profile
        </Button>
        {payoutMerchantId && (
          <Button
            size="small"
            onClick={() =>
              navigate(
                `${getPlatformBaseUrl(platformName)}/dashboard/${DashboardRoutesList.SETTINGS_ROUTE}/${
                  SettingRoutesList.SETTINGS_PAYOUTS
                }`,
              )
            }
            variant="primary"
            className={`lp-w-full lp-button-transaction ${activeTab === `payouts` ? `is-selected` : ``}`}
          >
            Payouts
          </Button>
        )}
        <Button
          size="small"
          onClick={() =>
            navigate(
              `${getPlatformBaseUrl(platformName)}/dashboard/${DashboardRoutesList.SETTINGS_ROUTE}/${
                SettingRoutesList.SETTINGS_BRANDING
              }`,
            )
          }
          variant="primary"
          className={`lp-w-full lp-button-transaction ${activeTab === `branding` ? `is-selected` : ``}`}
          testId="setting-branding-menu-button"
        >
          Branding
        </Button>
      </div>
    </div>
  );
}

export default SettingsSidebar;
