import { LISTING_RECORDS_PER_PAGE } from 'pages/platform/constants';

export const getInitialFilterState = () => {
  return {
    dateActiveChecked: false,
    dateValue: '',
    dateTypeCompareValue: '',
    dateTypeFilterValue: '',
    statusValue: '',
    dateRangeValue: '',
    dateSingleValue: null,
    statusFilterChecked: false,
    merchantIdChecked: false,
    merchantId: '',
    orderIdChecked: false,
    orderId: '',
    txnIdChecked: false,
    txnId: '',
    amountIdChecked: false,
    amount: '',
    internalOrderIdChecked: false,
    internalOrderId: '',
    totalFiltersApplied: 0,
    saveTiggerAt: '',
  };
};

export const getInitialListingPageState = () => {
  return {
    activePage: 1,
    sortBy: '-createdAt',
    limit: LISTING_RECORDS_PER_PAGE,
  };
};
