import * as s from 'assets/styles/Platforms.styles';
import { FormikProps } from 'formik';
import SettingsSidebar from 'layouts/platform/SettingsSidebar';
import { RefObject } from 'react';

import { Button, Heading, Icon, LabelledValue, Text } from '@limepayments/cosmic';

import BrandingEditModal from './partials/BrandingEditModal';
import { SettingBrandingDetailPayload } from './types';

export interface Props {
  brandingDetail: SettingBrandingDetailPayload | null;
  onSubmitClick: (values: SettingBrandingDetailPayload, formObj: { resetForm: () => void }) => void;
  formRef: RefObject<FormikProps<SettingBrandingDetailPayload>>;
  errorMsg: string;
  editRequestLoading: boolean;
  brandEditModalOpen: boolean;
  setBrandEditModalOpen: (val: boolean) => void;
}

function BrandingView({
  brandingDetail,
  brandEditModalOpen,
  onSubmitClick,
  setBrandEditModalOpen,
  formRef,
  errorMsg,
  editRequestLoading,
}: Props) {
  return (
    <>
      <div className="content-wrap-inner with-gutter">
        <div className="lp-w-full lp-flex">
          <div className="payment-sidebar">
            <SettingsSidebar activeTab="branding" />
          </div>

          <div className="payment-content-area">
            <div className="lp-w-full lp-flex lp-justify-between sm-lp-flex-col mb-32">
              <Heading alignment="left" tagName="h2" variant="xl" className="page-title mb-0">
                Branding
              </Heading>
            </div>

            <s.MerchantsData className="bt-1">
              <div className="lp-w-full lp-flex lp-align-start lp-flex-row mb-32 sm-lp-align-start bb-1 pb-32 sm-lp-flex-col">
                <div className="lp-col-lt sm-lp-col-lt sm-pl-0 sm-pr-0">
                  <div className="lp-w-full mb-32">
                    <Text alignment="left" tagName="p" variant="body-1" isEmphasised className="text-dark-50 mb-0">
                      Merchant facing
                      <Text variant="body-3" className="mt-12">
                        Branding merchants will see as they interact or transact with your business.
                      </Text>
                    </Text>
                  </div>
                  <div className="lp-w-full lp-flex lp-align-start lp-flex-row sm-lp-flex-col mb-0">
                    <div className="avatar avatar-100">
                      {brandingDetail && brandingDetail.branding.logoUri ? (
                        <img src={brandingDetail.branding.logoUri} alt="Logo" />
                      ) : (
                        <p>LOGO HERE</p>
                      )}
                    </div>
                    <div className="branding-details">
                      <div className="lp-w-full mb-24">
                        <LabelledValue
                          label="Trading name"
                          value={
                            brandingDetail?.branding.businessDisplayName
                              ? brandingDetail.branding.businessDisplayName
                              : '-'
                          }
                          variant="vertical"
                        />
                      </div>
                      <div className="lp-w-full mb-0">
                        <div className="lp-flex lp-flex-column">
                          <div className="lp-flex lp-align-center text-caption">
                            Support email
                            <span
                              data-balloon="The support email your merchants are directed to for help with the April product."
                              data-balloon-pos="up"
                              data-balloon-length="large"
                              className="lp-flex lp-align-center lp-justify-center size-11"
                            >
                              <Icon name="Info" />
                            </span>
                          </div>
                          <span className="text-body-3">
                            {brandingDetail ? brandingDetail.branding.supportEmail : '-'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lp-col-rt sm-lp-col-rt sm-pl-0 sm-pr-0">
                  <div className="lp-w-full mb-32">
                    <Text alignment="left" tagName="p" variant="body-1" isEmphasised className="text-dark-50 mb-0">
                      Customer facing
                      <Text variant="body-3" className="mt-12">
                        Branding your merchants' customers will see as they interact or transact with your business.
                      </Text>
                    </Text>
                  </div>
                  <div className="lp-w-full lp-flex lp-align-start lp-flex-row sm-lp-flex-col mb-0">
                    <div className="avatar avatar-100">
                      {brandingDetail && brandingDetail.branding.logoUri ? (
                        <img src={brandingDetail.branding.logoUri} alt="Logo" />
                      ) : (
                        <p>LOGO HERE</p>
                      )}
                    </div>
                    <div className="branding-details">
                      <div className="lp-w-full mb-24">
                        <LabelledValue
                          label="Trading name"
                          value={
                            brandingDetail?.branding.businessDisplayName
                              ? brandingDetail.branding.businessDisplayName
                              : '-'
                          }
                          variant="vertical"
                        />
                      </div>
                      <div className="lp-w-full mb-0">
                        <div className="lp-flex lp-flex-column">
                          <div className="lp-flex lp-align-center text-caption">
                            Support email
                            <span
                              data-balloon="The support email your merchants’ customers are directed to for help with the April product. "
                              data-balloon-pos="up"
                              data-balloon-length="large"
                              className="lp-flex lp-align-center lp-justify-center size-11"
                            >
                              <Icon name="Info" />
                            </span>
                          </div>
                          <span className="text-body-3">
                            {brandingDetail ? brandingDetail.branding.supportEmail : '-'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="lp-w-full lp-flex lp-align-center lp-flex-row mb-32 sm-lp-align-start">
                <Text alignment="left" tagName="p" variant="body-1" isEmphasised className="text-dark-50 mb-0">
                  Branding details
                  <Text variant="body-3" className="mt-12">
                    Branding details representing your business.
                  </Text>
                </Text>
                <div className="ml-auto">
                  <Button
                    size="small"
                    onClick={() => setBrandEditModalOpen(!brandEditModalOpen)}
                    variant="reversed"
                    className="no-min-width w-70"
                    testId="setting-branding-edit-button"
                  >
                    Edit
                    <Icon name="Edit" testId="setting-branding-edit-icon" />
                  </Button>
                </div>
              </div>

              <div className="lp-w-full mb-32">
                <LabelledValue
                  label="Trading name"
                  value={
                    brandingDetail?.branding.businessDisplayName ? brandingDetail.branding.businessDisplayName : '-'
                  }
                  variant="vertical"
                  testId="setting-branding-trading-name"
                />
              </div>

              <div className="lp-w-full mb-32">
                <div className="lp-flex lp-flex-column">
                  <div className="lp-flex lp-align-center text-caption">
                    Logo URL
                    <span
                      data-balloon="Convert your logo to a URL by uploading it to an image hosting website. For best result use a .jpg or .png image URL with dimensions 175x175 pixels."
                      data-balloon-pos="up"
                      data-balloon-mobile-pos="right"
                      data-balloon-length="large"
                      className="lp-flex lp-align-center lp-justify-center size-11"
                    >
                      <Icon name="Info" />
                    </span>
                  </div>
                  <span className="text-body-3" data-testid="setting-branding-logo-url">
                    {brandingDetail ? brandingDetail.branding.logoUri : '-'}
                  </span>
                </div>
              </div>

              <div className="lp-w-full mb-32">
                <div className="lp-flex lp-flex-column">
                  <div className="lp-flex lp-align-center text-caption">
                    Favicon URL
                    <span
                      data-balloon="Convert your favicon to a URL by uploading it to an image hosting website. For best result use a .ico or .png image URL with dimensions 32x32 or 16x16 pixels. "
                      data-balloon-pos="up"
                      data-balloon-mobile-pos="right"
                      data-balloon-length="large"
                      className="lp-flex lp-align-center lp-justify-center size-11"
                    >
                      <Icon name="Info" />
                    </span>
                  </div>
                  <span className="text-body-3" data-testid="setting-branding-favicon-url">
                    {brandingDetail ? brandingDetail.branding.iconUri : '-'}
                  </span>
                </div>
              </div>

              <div className="lp-w-full mb-32">
                <LabelledValue
                  label="Logo alt text"
                  value={brandingDetail ? brandingDetail.branding.logoAltText : '-'}
                  variant="vertical"
                  testId="setting-branding-logo-alt-text"
                />
              </div>

              {/* <div className="lp-w-full mb-32">
                <div className="lp-flex lp-flex-column">
                  <div className="lp-flex lp-align-center text-caption">
                    Support email{' '}
                    <span
                      data-balloon="The support email your merchants are directed to for help with the April product."
                      data-balloon-pos="up"
                      data-balloon-length="large"
                      className="lp-flex lp-align-center lp-justify-center size-11"
                    >
                      <Icon name="Info" />
                    </span>
                  </div>
                  <span className="text-body-3">support@limepay.zendesk.com</span>
                </div>
              </div> */}

              <div className="lp-w-full mb-32">
                <div className="lp-flex lp-flex-column">
                  <div className="lp-flex lp-align-center text-caption">
                    Card statement name
                    <span
                      data-balloon="The business name that appears on customer bank statements. A recognisable name is important so that customers remember where they purchased from and to avoid unintended disputes."
                      data-balloon-pos="up"
                      data-balloon-length="large"
                      className="lp-flex lp-align-center lp-justify-center size-11"
                    >
                      <Icon name="Info" />
                    </span>
                  </div>
                  <span className="text-body-3" data-testid="setting-branding-card-statement-name">
                    {brandingDetail && brandingDetail.customerSettings
                      ? brandingDetail.customerSettings.cardStatementName
                      : '-'}
                  </span>
                </div>
              </div>

              <div className="lp-w-full mb-32">
                <div className="lp-flex lp-flex-column">
                  <div className="lp-flex lp-align-center text-caption">
                    SMS sender name
                    <span
                      data-balloon="The contact name that appears on SMS received by customers regarding their payment. A recognisable name is important so that customers remember where they purchased from and to avoid unintended disputes."
                      data-balloon-pos="up"
                      data-balloon-length="large"
                      className="lp-flex lp-align-center lp-justify-center size-11"
                    >
                      <Icon name="Info" />
                    </span>
                  </div>
                  <span className="text-body-3" data-testid="setting-branding-sms-sender-name">
                    {brandingDetail && brandingDetail.customerSettings
                      ? brandingDetail.customerSettings.smsSenderName
                      : '-'}
                  </span>
                </div>
              </div>

              <div className="lp-w-full mb-32">
                <div className="lp-flex lp-flex-column">
                  <div className="lp-flex lp-align-center text-caption">
                    Payout description
                    <span
                      data-balloon="The description of bank statements in merchants payouts."
                      data-balloon-pos="up"
                      data-balloon-length="large"
                      className="lp-flex lp-align-center lp-justify-center size-11"
                    >
                      <Icon name="Info" />
                    </span>
                  </div>
                  <span className="text-body-3" data-testid="setting-branding-payout-description">
                    {brandingDetail?.payoutSettings?.payoutDescription}
                  </span>
                </div>
              </div>

              <div className="lp-w-full mb-32">
                <div className="lp-flex lp-flex-column">
                  <div className="lp-flex lp-align-center text-caption">Business website</div>
                  {brandingDetail && brandingDetail.branding.businessWebsite ? (
                    <a
                      href={
                        brandingDetail.branding.businessWebsite.substring(0, 4) !== 'http'
                          ? 'http://' + brandingDetail.branding.businessWebsite
                          : brandingDetail.branding.businessWebsite
                      }
                      className="lp-link-primary fw-medium"
                      target="_blank"
                      rel="noreferrer"
                      data-testid="setting-branding-business-website"
                    >
                      {brandingDetail.branding.businessWebsite}
                    </a>
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            </s.MerchantsData>
          </div>
        </div>
      </div>

      {brandingDetail && (
        <BrandingEditModal
          isOpen={brandEditModalOpen}
          modalToggler={setBrandEditModalOpen}
          initialValues={JSON.parse(JSON.stringify(brandingDetail))}
          onSubmitClick={onSubmitClick}
          formRef={formRef}
          errorMsg={errorMsg}
          editRequestLoading={editRequestLoading}
        />
      )}
    </>
  );
}

export default BrandingView;
