import { handleResponse } from 'api/utils';
import getFetchOptions from 'utils/getFetchOptions';

import {
  CreatePlatformMerchantPayload,
  CreatePlatformMerchantResponse,
  ExportPlatformMerchantsListPayload,
  PlatformMerchantObj,
} from './merchant.types';

export const createPlatformMerchant = async (
  host: string,
  marketplaceId: string,
  payload: CreatePlatformMerchantPayload,
): Promise<CreatePlatformMerchantResponse> => {
  const options = await getFetchOptions('POST', { payload: { ...payload, dashboardReferrer: 'Platform' } });
  return await fetch(`${host}/platform/${marketplaceId}/dashboard/onboarding`, options).then(handleResponse);
};

export const getPlatformMerchantsList = async (
  host: string,
  marketplaceId: string,
  queryString: string,
): Promise<{ totalPages: number; totalRecords: number; items: Array<PlatformMerchantObj> }> => {
  const options = await getFetchOptions();
  return await fetch(`${host}/platform/${marketplaceId}/dashboard/merchants?${queryString}`, options).then(
    handleResponse,
  );
};

export const exportPlatformMerchantsList = async (
  host: string,
  marketplaceId: string,
  payload: ExportPlatformMerchantsListPayload,
): Promise<string> => {
  const options = await getFetchOptions('POST', { payload });
  return await fetch(`${host}/platform/${marketplaceId}/dashboard/merchants/csv`, options).then(handleResponse);
};

export const getPlatformMerchant = async (
  host: string,
  marketplaceId: string,
  merchantId: string,
): Promise<PlatformMerchantObj> => {
  const options = await getFetchOptions();
  return await fetch(`${host}/platform/${marketplaceId}/dashboard/merchants/${merchantId}`, options).then(
    handleResponse,
  );
};
