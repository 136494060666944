import { handleResponse } from 'api/utils';
import { SettingPayoutPayload } from 'pages/platform/dashboard/settings/payouts/types';
import { SettingPasswordUpdatePayload } from 'pages/platform/dashboard/settings/profile/types';
import getFetchOptions from 'utils/getFetchOptions';

import {
  SettingBrandingDetailResponse,
  SettingBusinessProfileResponse,
  SettingPayoutExternalAccountResponse,
  SettingPayoutResponse,
  SettingPlatformBusinessRegistrationResponse,
} from './setting.types';

export const getSettingCountry = async (host: string, marketplaceId: string): Promise<Array<string>> => {
  const options = await getFetchOptions();
  return await fetch(`${host}/platform/${marketplaceId}/dashboard/settings/country`, options).then(handleResponse);
};

export const getSettingBrandingDetail = async (
  host: string,
  marketplaceId: string,
  country: string,
): Promise<SettingBrandingDetailResponse> => {
  const options = await getFetchOptions();
  return await fetch(`${host}/platform/${marketplaceId}/dashboard/settings/${country}/branding`, options).then(
    handleResponse,
  );
};

export const updateSettingBrandingDetail = async (
  host: string,
  marketplaceId: string,
  country: string,
  payload: SettingBrandingDetailResponse,
): Promise<void> => {
  const options = await getFetchOptions('POST', { payload });
  return await fetch(`${host}/platform/${marketplaceId}/dashboard/settings/${country}/branding`, options).then(
    handleResponse,
  );
};

export const getSettingBusinessProfile = async (
  host: string,
  marketplaceId: string,
  country: string,
): Promise<SettingBusinessProfileResponse> => {
  const options = await getFetchOptions();
  return await fetch(`${host}/platform/${marketplaceId}/dashboard/settings/${country}/business-contact`, options).then(
    handleResponse,
  );
};

export const getSettingPlatformBusinessRegistration = async (
  host: string,
  marketplaceId: string,
  country: string,
): Promise<SettingPlatformBusinessRegistrationResponse> => {
  const options = await getFetchOptions();
  return await fetch(
    `${host}/platform/${marketplaceId}/dashboard/settings/${country}/business-registration`,
    options,
  ).then(handleResponse);
};

export const updateSettingBusinessProfile = async (
  host: string,
  marketplaceId: string,
  country: string,
  payload: SettingBusinessProfileResponse,
): Promise<void> => {
  const options = await getFetchOptions('POST', { payload });
  return await fetch(`${host}/platform/${marketplaceId}/dashboard/settings/${country}/business-contact`, options).then(
    handleResponse,
  );
};

export const updateSettingPassword = async (host: string, payload: SettingPasswordUpdatePayload): Promise<void> => {
  const options = await getFetchOptions('POST', { payload });
  return await fetch(`${host}/authn/change-marketplace-user-password`, options).then(handleResponse);
};

export const getPrimaryMerchantIds = async (
  host: string,
  marketplaceId: string,
  country: string,
): Promise<Array<string>> => {
  const options = await getFetchOptions();
  return await fetch(`${host}/platform/${marketplaceId}/dashboard/merchants/primary/${country}`, options).then(
    handleResponse,
  );
};

export const getSettingPayout = async (host: string, merchantId: string): Promise<SettingPayoutResponse> => {
  const options = await getFetchOptions();
  return await fetch(`${host}/merchants/${merchantId}/settings/payout`, options).then(handleResponse);
};

export const updateSettingPayout = async (
  host: string,
  merchantId: string,
  payload: SettingPayoutPayload,
): Promise<SettingPayoutResponse> => {
  const options = await getFetchOptions('POST', { payload });
  return await fetch(`${host}/merchants/${merchantId}/settings/payout`, options).then(handleResponse);
};

export const getSettingPayoutSchedule = async (host: string, merchantId: string): Promise<string> => {
  const options = await getFetchOptions();
  return await fetch(`${host}/merchants/${merchantId}/settings/payout-schedule`, options).then(handleResponse);
};

export const updateSettingPayoutSchedule = async (
  host: string,
  merchantId: string,
  scheduleType: string,
): Promise<string> => {
  const options = await getFetchOptions('POST', { payload: scheduleType });
  return await fetch(`${host}/merchants/${merchantId}/settings/payout-schedule`, options).then(handleResponse);
};

export const getSettingPayoutExternalAccount = async (
  host: string,
  merchantId: string,
): Promise<Array<SettingPayoutExternalAccountResponse>> => {
  const options = await getFetchOptions();
  return await fetch(`${host}/merchants/${merchantId}/external-bank-account/query`, options).then(handleResponse);
};

export const updateSettingPayoutDescription = async (
  host: string,
  merchantId: string,
  description: string,
): Promise<string> => {
  const options = await getFetchOptions('POST', { payload: description });
  return await fetch(`${host}/merchants/${merchantId}/settings/payout-schedule`, options).then(handleResponse);
};
