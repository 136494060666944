import { Fragment } from 'react';

import { Button, Icon, Text } from '@limepayments/cosmic';

function LandingSection() {
  return (
    <Fragment>
      <Text tagName="p" variant="body-2" className="lp-neutral-600 mt-12 mb-24">
        Create a breakdown of a customer's order by adding items
      </Text>
      <div className="build-order-content">
        <Button className="no-min-width w-70 mt-0" size="medium" variant="reversed">
          Add new item
          <Icon name="Plus" className="ml-16" />
        </Button>
        <Button className="no-min-width w-70 mt-12" size="medium" variant="ghost">
          Skip to order details
          <Icon name="ArrowRight" className="ml-16" />
        </Button>
      </div>
    </Fragment>
  );
}

export default LandingSection;
