import ErrorPage from 'pages/common/error';
import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/platform/hooks';
import { setListingPagePropsObj, setMerchantFilterObj } from 'redux/platform/slice/merchantSlice';
import {
  setPaymentFilterObj,
  setListingPagePropsObj as setPaymentListingPagePropsObj,
} from 'redux/platform/slice/paymentSlice';
import {
  setTxnFilterObj,
  setListingPagePropsObj as setTxnListingPagePropsObj,
} from 'redux/platform/slice/transactionSlice';

import DashboardRoutesList from './constants';
import MerchantRoutes from './merchant/MerchantRoutes';
import {
  getInitialFilterState as getInitialMerchantFilterState,
  getInitialListingPageState as getInitialMerchantListingPageState,
} from './merchant/listing/initialStates';
import PaymentRoutes from './payment/PaymentRoutes';
import {
  getInitialFilterState as getInitialPaymentFilterState,
  getInitialListingPageState as getInitialPaymentListingPageState,
} from './payment/listing/initialStates';
import {
  getInitialFilterState as getInitialTxnFilterState,
  getInitialListingPageState as getInitialTxnListingPageState,
} from './payment/transactions/initialStates';
import SettingsRoutes from './settings/SettingsRoutes';

const DashboardRoutes = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { merchantFilterObj, paymentFilterObj, txnFilterObj } = useAppSelector((state) => ({
    merchantFilterObj: state.merchant.merchantFilterObj,
    paymentFilterObj: state.payment.paymentFilterObj,
    txnFilterObj: state.transaction.txnFilterObj,
  }));

  useEffect(() => {
    if (!location.pathname.includes('/dashboard/merchant')) {
      if (merchantFilterObj.totalFiltersApplied > 0) {
        dispatch(setMerchantFilterObj(getInitialMerchantFilterState()));
      }
      dispatch(setListingPagePropsObj(getInitialMerchantListingPageState()));
    }
  }, [location, dispatch, merchantFilterObj.totalFiltersApplied]);

  useEffect(() => {
    if (!location.pathname.includes('/dashboard/payment')) {
      if (paymentFilterObj.totalFiltersApplied > 0) {
        dispatch(setPaymentFilterObj(getInitialPaymentFilterState()));
      }
      dispatch(setPaymentListingPagePropsObj(getInitialPaymentListingPageState()));
    }
  }, [location, dispatch, paymentFilterObj.totalFiltersApplied]);

  useEffect(() => {
    if (!location.pathname.includes('/dashboard/payment/all-transactions')) {
      if (txnFilterObj.totalFiltersApplied > 0) {
        dispatch(setTxnFilterObj(getInitialTxnFilterState()));
      }
      dispatch(setTxnListingPagePropsObj(getInitialTxnListingPageState()));
    }
  }, [location, dispatch, txnFilterObj.totalFiltersApplied]);

  return (
    <Routes>
      <Route path={`${DashboardRoutesList.MERCHANT_ROUTE}/*`} element={<MerchantRoutes />} />
      <Route path={`${DashboardRoutesList.PAYMENT_ROUTE}/*`} element={<PaymentRoutes />} />
      <Route path={`${DashboardRoutesList.SETTINGS_ROUTE}/*`} element={<SettingsRoutes />} />
      <Route
        path="*"
        element={<ErrorPage bodyTitle="Page Not Found" bodyText="The page you are trying to access doesn't exists" />}
      />
    </Routes>
  );
};

export default DashboardRoutes;
