import { GetMerchantFileResponse } from 'api/merchant/onbording/file.types';
import * as s from 'assets/styles/Onboarding.styles';
import { AustralianStates, StreetTypes } from 'config/constants';
import { ErrorMessage, Formik, FormikProps } from 'formik';
import { DateTime } from 'luxon';
import { Fragment, RefObject, useEffect, useRef } from 'react';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import { useAppSelector } from 'redux/merchant/hooks';
import * as Yup from 'yup';

import {
  Announcement,
  Button,
  DateField,
  Heading,
  Icon,
  Link,
  Message,
  RadioGroup,
  Select,
  Text,
  TextField,
} from '@limepayments/cosmic';

import { CreateMerchantPersonPayload } from '../../../types';

interface Props {
  isOpen: boolean;
  toggleModal: (val: boolean) => void;
  setCurrentStep: (val: number) => void;
  errorMessage: string;
  initialValues: CreateMerchantPersonPayload;
  submitHandler: (values: CreateMerchantPersonPayload, formObj: { resetForm: () => void }) => void;
  formRef: RefObject<FormikProps<CreateMerchantPersonPayload>>;
  selectedFileFront: File | null;
  selectedFileBack: File | null;
  fileFrontChangeHandler: (file: FileList | null) => void;
  fileBackChangeHandler: (file: FileList | null) => void;
  isRequestLoading: boolean;
  fileFrontUploading: boolean;
  fileBackUploading: boolean;
  isPublicSection?: boolean;
  updateItem?: boolean;
  isVerified?: boolean;
  fileFrontDetail: GetMerchantFileResponse | null;
  fileBackDetail: GetMerchantFileResponse | null;
  saveAndFinishLater: () => void;
  saveAndReview?: boolean;
  directorSection?: boolean;
}

const validationSchema = Yup.object().shape({
  title: Yup.string().trim().required('Please enter a job title'),
  firstName: Yup.string().trim().required('Please enter a legal first name'),
  middleName: Yup.string().nullable(),
  lastName: Yup.string().trim().required('Please enter a legal last name'),
  dob: Yup.string()
    .trim()
    .required('This field is required')
    .test({
      name: 'dob-validation',
      test: function () {
        const { dob } = this.parent;
        if (dob) {
          const dateArray = dob.split('/');
          if (!DateTime.fromISO(`${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`).isValid) {
            return this.createError({
              message: `Please enter a valid date`,
              path: `dob`,
            });
          }

          if (DateTime.fromISO(`${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`).isValid) {
            const maxDateRange = DateTime.now().minus({
              year: 18,
            });
            const fromDateObj = new Date(`${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`);
            const fromDateLuxonObj = DateTime.fromISO(fromDateObj.toISOString()).toUTC();

            if (fromDateLuxonObj.startOf('day') > maxDateRange.startOf('day')) {
              return this.createError({
                message: `Must be over 18`,
                path: `dob`,
              });
            }
          }
        }

        return true;
      },
    }),
  email: Yup.string().trim().email('Please enter a valid email address').required('Please enter a valid email address'),
  phone: Yup.string()
    .trim()
    .required('Please enter a contact phone number')
    .test({
      name: 'phone-no-validation',
      test: function () {
        const { phone } = this.parent;
        if (phone && !isPossiblePhoneNumber(phone))
          return this.createError({
            message: `Please enter a valid phone number`,
            path: `phone`,
          });
        return true;
      },
    }),
  businessAddress: Yup.string().when('isManualEnterAddress', {
    is: false,
    then: Yup.string().required('Please enter a address'),
  }),
  address: Yup.object().when('isManualEnterAddress', {
    is: true,
    then: Yup.object().shape({
      streetNumber: Yup.string().trim().required('Please enter a street number'),
      streetName: Yup.string().trim().required('Please enter a street name'),
      streetType: Yup.string().trim().required('Please select a street type'),
      suburb: Yup.string().trim().required('Please enter a suburb'),
      city: Yup.string()
        .when('country', {
          is: 'NZ',
          then: Yup.string().trim().nullable().required('Please enter a city'),
        })
        .nullable(),
      state: Yup.string()
        .when('country', {
          is: 'AU',
          then: Yup.string().trim().nullable().required('Please select a state'),
        })
        .nullable(),
      postalCode: Yup.string()
        .trim()
        .required('Please enter a postal code')
        .min(4, 'Please enter between 4-10 characters')
        .max(10, 'Please enter between 4-10 characters')
        .matches(/^[0-9]+$/, 'Please enter only numeric characters'),
      country: Yup.string().required('Please select a country'),
    }),
  }),
  verificationDocs: Yup.object().shape({
    idType: Yup.string().trim().required('Please select an ID type'),
  }),
  selectedFileFront: Yup.mixed().when('updateItem', {
    is: !true,
    then: Yup.mixed()
      .required('File is required')
      .test({
        message: 'Please provide a supported file type',
        test: (file, context) => {
          if (!file) {
            return true;
          }
          const fileExtension = file ? file.type.split('/')[1] : '';
          const isValid = ['jpeg', 'jpg', 'png', 'pdf'].includes(fileExtension.toLowerCase());
          return isValid;
        },
      })
      .test({
        message: `File too big, can't exceed 10MB limit`,
        test: (file) => {
          if (!file) {
            return true;
          }
          const isValid = file ? file.size <= 10 * 1024 * 1024 : false;
          return isValid;
        },
      }),
  }),
  selectedFileBack: Yup.mixed().when('updateItem', {
    is: !true,
    then: Yup.mixed().when('verificationDocs.idType', {
      is: 'DriverLicence',
      then: Yup.mixed()
        .required('Please upload the back of the Driver License')
        .test({
          message: 'Please provide a supported file type',
          test: (file, context) => {
            if (!file) {
              return true;
            }
            const fileExtension = file ? file.type.split('/')[1] : '';
            const isValid = ['jpeg', 'jpg', 'png', 'pdf'].includes(fileExtension.toLowerCase());
            return isValid;
          },
        })
        .test({
          message: `File too big, can't exceed 10MB limit`,
          test: (file) => {
            if (!file) {
              return true;
            }
            const isValid = file ? file.size <= 10 * 1024 * 1024 : false;
            return isValid;
          },
        }),
    }),
  }),
  driverLicence: Yup.object().when('verificationDocs.idType', {
    is: 'DriverLicence',
    then: Yup.object().shape({
      country: Yup.string().trim().required('Please select the country of the Drive License'),
      driverLicenceNumber: Yup.string().trim().required('Please enter a Drive License number'),
      state: Yup.string()
        .nullable()
        .trim()
        .test({
          name: 'test-state',
          test: function () {
            const { country, state } = this.parent;

            if (!state && country === 'AU') {
              return this.createError({
                message: `Please select the state of the Drive License`,
                path: `driverLicence.state`,
              });
            }

            return true;
          },
        }),
      documentNumber: Yup.string()
        .nullable()
        .trim()
        .matches(/^[aA-zZ0-9]+$/, 'Please enter only alphabetic characters')
        .test({
          name: 'test-documentnumber',
          test: function () {
            const { country, documentNumber } = this.parent;

            if (!documentNumber && country === 'AU') {
              return this.createError({
                message: `Please enter a Document Number`,
                path: `driverLicence.documentNumber`,
              });
            }

            if (documentNumber && documentNumber.length < 6) {
              return this.createError({
                message: `Must be at least 6 characters`,
                path: `driverLicence.documentNumber`,
              });
            }

            if (documentNumber && documentNumber.length > 10) {
              return this.createError({
                message: `Must be at most 10 characters`,
                path: `driverLicence.documentNumber`,
              });
            }

            return true;
          },
        }),
      versionNumber: Yup.string()
        .nullable()
        .trim()
        .matches(/^[aA-zZ0-9]+$/, 'Please enter only alphabetic characters')
        .test({
          name: 'test-versionnumber',
          test: function () {
            const { country, versionNumber } = this.parent;

            if (!versionNumber && country === 'NZ') {
              return this.createError({
                message: `Please enter a Version Number`,
                path: `driverLicence.versionNumber`,
              });
            }

            return true;
          },
        }),
    }),
  }),
  passport: Yup.object().when('verificationDocs.idType', {
    is: 'Passport',
    then: Yup.object().shape({
      country: Yup.string().trim().required('Please select a Passport country'),
      passportNumber: Yup.string().trim().required('Please select a Passport number'),
      expiryDate: Yup.string()
        .trim()
        .required('Please select a Passport expiry date')
        .test({
          name: 'passport-expiry-validation',
          test: function () {
            const { expiryDate } = this.parent;

            if (expiryDate) {
              const dateArray = expiryDate.split('/');
              if (!DateTime.fromISO(`${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`).isValid) {
                return this.createError({
                  message: `Please enter a valid date`,
                  path: `expiryDate`,
                });
              }

              if (DateTime.fromISO(`${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`).isValid) {
                const minDateRange = DateTime.now();
                const fromDateObj = new Date(`${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`);
                const fromDateLuxonObj = DateTime.fromISO(fromDateObj.toISOString());

                if (fromDateLuxonObj.startOf('day') < minDateRange.startOf('day')) {
                  return this.createError({
                    message: `Passport is expired`,
                    path: `passport.expiryDate`,
                  });
                }
              }
            }

            return true;
          },
        }),
    }),
  }),
});

function IndividualForm({
  setCurrentStep,
  toggleModal,
  isOpen,
  errorMessage,
  initialValues,
  submitHandler,
  formRef,
  selectedFileFront,
  selectedFileBack,
  fileFrontChangeHandler,
  fileBackChangeHandler,
  isRequestLoading,
  fileFrontUploading,
  fileBackUploading,
  isPublicSection,
  updateItem,
  fileFrontDetail,
  fileBackDetail,
  saveAndFinishLater,
  saveAndReview,
  directorSection,
}: Props) {
  const errorContainerRef = useRef<HTMLDivElement>(null);

  const { merchantTaxCountry } = useAppSelector((state) => ({
    merchantTaxCountry: state.config.merchantTaxCountry,
  }));

  useEffect(() => {
    if (errorMessage.length > 0 && errorContainerRef && errorContainerRef.current) {
      errorContainerRef.current.scrollIntoView();
    }
  }, [errorMessage]);

  return (
    <Formik<CreateMerchantPersonPayload>
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={(values, formObj: { resetForm: () => void }) => {
        submitHandler(values, formObj);
      }}
      validationSchema={validationSchema}
      innerRef={formRef}
    >
      {({ values, handleSubmit, errors, touched, setFieldValue, handleChange, handleBlur }) => (
        <form autoComplete="off" onSubmit={handleSubmit} className="content-wrap-form">
          <div className={`${!isPublicSection ? `modal-content` : ``}`}>
            <div className="lp-w-full">
              <div className="lp-w-full lp-equal-width mb-24">
                {errorMessage.length > 0 && (
                  <div className="mt-24 mb-24" ref={errorContainerRef}>
                    <Message
                      type="inline"
                      children={<span className="text-wrap">{errorMessage}</span>}
                      variant={'error'}
                    />
                  </div>
                )}
                <Heading alignment="left" tagName="h4" variant="xs" className="mb-24">
                  Personal details
                </Heading>
                <s.WeakAnnouncement>
                  <Announcement variant="weak" hasDismissal={false}>
                    Enter all details as they are stated on the government issued photo identification.
                  </Announcement>
                </s.WeakAnnouncement>
              </div>
              <div className="lp-w-full lp-equal-width mb-40">
                <div className="lp-w-full mb-24">
                  <TextField
                    fullWidth
                    label="Job title"
                    inputMode="text"
                    autoComplete="off"
                    id="title"
                    name="title"
                    onBlur={handleBlur}
                    value={values.title}
                    onChange={handleChange}
                    error={errors.title && touched.title ? errors.title : ''}
                  />
                </div>
                <div className="lp-w-full mb-24">
                  <TextField
                    fullWidth
                    label="Legal first name"
                    inputMode="text"
                    autoComplete="off"
                    id="firstName"
                    name="firstName"
                    onBlur={handleBlur}
                    value={values.firstName}
                    onChange={handleChange}
                    error={errors.firstName && touched.firstName ? errors.firstName : ''}
                  />
                </div>
                <div className="lp-w-full mb-24">
                  <TextField
                    fullWidth
                    label="Legal middle name (optional)"
                    inputMode="text"
                    autoComplete="off"
                    id="middleName"
                    name="middleName"
                    onBlur={handleBlur}
                    value={values.middleName}
                    onChange={handleChange}
                    error={errors.middleName && touched.middleName ? errors.middleName : ''}
                  />
                </div>
                <div className="lp-w-full mb-24">
                  <TextField
                    fullWidth
                    label="Legal last name"
                    inputMode="text"
                    autoComplete="off"
                    id="lastName"
                    name="lastName"
                    onBlur={handleBlur}
                    value={values.lastName}
                    onChange={handleChange}
                    error={errors.lastName && touched.lastName ? errors.lastName : ''}
                  />
                </div>
                <div className="lp-w-full mb-24">
                  <DateField
                    fullWidth
                    inputMode="numeric"
                    label="Date of birth"
                    maskPlaceholder="DD/MM/YYYY"
                    mask={[/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                    autoComplete="off"
                    id="dob"
                    name="dob"
                    onBlur={handleBlur}
                    value={values.dob}
                    onChange={handleChange}
                    error={errors.dob && touched.dob ? errors.dob : ''}
                  />
                </div>
                {!values.isManualEnterAddress && (
                  <div className="lp-w-full mb-24">
                    <TextField
                      fullWidth
                      label="Residential address"
                      inputMode="text"
                      autoComplete="off"
                      id="businessAddress"
                      name="businessAddress"
                      onBlur={handleBlur}
                      value={values.businessAddress}
                      onChange={handleChange}
                      error={errors.businessAddress && touched.businessAddress ? errors.businessAddress : ''}
                    />
                    <div className="lp-w-full zover-2 mb-0 mt-8">
                      <Link
                        onClick={() => setFieldValue('isManualEnterAddress', true)}
                        size="small"
                        target="_self"
                        className="link-underline fw-medium"
                      >
                        Manually enter address
                      </Link>
                    </div>
                  </div>
                )}

                {values.isManualEnterAddress && (
                  <Fragment>
                    <header className="content-header mt-24 d-none">
                      <div className="lp-w-full mb-24">
                        <Heading alignment="left" tagName="h4" variant="sm">
                          Business address
                        </Heading>
                        <Text variant="body-3">
                          You can use a home address if the business doesn’t have a business address.
                        </Text>
                      </div>
                    </header>

                    <div className="lp-w-full mb-12 d-none">
                      <ul className="two-col lp-flex lp-flex-wrap lp-flex-column">
                        {((updateItem && values.address.country) || !updateItem) && (
                          <li
                            className={`mb-12${
                              errors.address && errors.address.country && touched.address && touched.address.country
                                ? ` has-error`
                                : ``
                            }`}
                          >
                            <Select
                              label="Country"
                              testId="step05IndividualCountry"
                              onChange={(value) => setFieldValue('address.country', value)}
                              value={values.address.country}
                              options={[
                                merchantTaxCountry === 'NZ'
                                  ? {
                                      text: 'New Zealand',
                                      value: 'NZ',
                                    }
                                  : {
                                      text: 'Australia',
                                      value: 'AU',
                                    },
                              ]}
                              errorMessage={
                                errors.address && errors.address.country && touched.address && touched.address.country
                                  ? errors.address.country
                                  : ''
                              }
                            />
                          </li>
                        )}
                        <li className="mb-12">
                          <TextField
                            fullWidth
                            label="Unit number (optional)"
                            inputMode="text"
                            autoComplete="off"
                            id="address.unitNumber"
                            name="address.unitNumber"
                            onBlur={handleBlur}
                            value={values.address.unitNumber}
                            onChange={handleChange}
                            error={
                              errors.address &&
                              errors.address.unitNumber &&
                              touched.address &&
                              touched.address.unitNumber
                                ? errors.address.unitNumber
                                : ''
                            }
                          />
                        </li>
                        <li className="mb-12">
                          <TextField
                            fullWidth
                            label="Street number"
                            inputMode="text"
                            autoComplete="off"
                            id="address.streetNumber"
                            name="address.streetNumber"
                            onBlur={handleBlur}
                            value={values.address.streetNumber}
                            onChange={handleChange}
                            error={
                              errors.address &&
                              errors.address.streetNumber &&
                              touched.address &&
                              touched.address.streetNumber
                                ? errors.address.streetNumber
                                : ''
                            }
                          />
                        </li>
                        <li className="mb-12">
                          <TextField
                            fullWidth
                            label="Street name"
                            inputMode="text"
                            autoComplete="off"
                            id="address.streetName"
                            name="address.streetName"
                            onBlur={handleBlur}
                            value={values.address.streetName}
                            onChange={handleChange}
                            error={
                              errors.address &&
                              errors.address.streetName &&
                              touched.address &&
                              touched.address.streetName
                                ? errors.address.streetName
                                : ''
                            }
                          />
                        </li>
                        {((updateItem && values.address.streetType) || !updateItem) && (
                          <li
                            className={`mb-12 ${
                              errors.address &&
                              errors.address.streetType &&
                              touched.address &&
                              touched.address.streetType
                                ? ` has-error`
                                : ``
                            }`}
                          >
                            <Select
                              label="Street type"
                              testId="step05StreetType"
                              onChange={(value) => setFieldValue('address.streetType', value)}
                              value={values.address.streetType}
                              options={StreetTypes}
                              errorMessage={
                                errors.address &&
                                errors.address.streetType &&
                                touched.address &&
                                touched.address.streetType
                                  ? errors.address.streetType
                                  : ''
                              }
                            />
                          </li>
                        )}
                        <li className="mb-12">
                          <TextField
                            fullWidth
                            label="Suburb"
                            inputMode="text"
                            autoComplete="off"
                            id="address.suburb"
                            name="address.suburb"
                            onBlur={handleBlur}
                            value={values.address.suburb}
                            onChange={handleChange}
                            error={
                              errors.address && errors.address.suburb && touched.address && touched.address.suburb
                                ? errors.address.suburb
                                : ''
                            }
                          />
                        </li>
                        {values.address.country === 'NZ' && (
                          <li
                            className={`mb-12 ${
                              errors.address && errors.address.city && touched.address && touched.address.city
                                ? ` has-error`
                                : ``
                            }`}
                          >
                            {((updateItem && values.address.city) || !updateItem) && (
                              <TextField
                                fullWidth
                                label="City"
                                inputMode="text"
                                autoComplete="off"
                                id="address.city"
                                name="address.city"
                                onBlur={handleBlur}
                                value={values.address.city ?? ''}
                                onChange={handleChange}
                                error={
                                  errors.address && errors.address.city && touched.address && touched.address.city
                                    ? errors.address.city
                                    : ''
                                }
                              />
                            )}
                          </li>
                        )}

                        {values.address.country === 'AU' && (
                          <li
                            className={`mb-12 ${
                              errors.address && errors.address.state && touched.address && touched.address.state
                                ? ` has-error`
                                : ``
                            }`}
                          >
                            {((updateItem && values.address.state) || !updateItem) && (
                              <Select
                                label="State"
                                testId="step05State"
                                onChange={(value) => setFieldValue('address.state', value)}
                                value={values.address.state ?? ''}
                                options={AustralianStates}
                                errorMessage={
                                  errors.address && errors.address.state && touched.address && touched.address.state
                                    ? errors.address.state
                                    : ''
                                }
                              />
                            )}
                          </li>
                        )}
                        <li className="mb-12">
                          <TextField
                            fullWidth
                            label="Postcode"
                            inputMode="text"
                            autoComplete="off"
                            id="address.postalCode"
                            name="address.postalCode"
                            onBlur={handleBlur}
                            value={values.address.postalCode}
                            onChange={handleChange}
                            error={
                              errors.address &&
                              errors.address.postalCode &&
                              touched.address &&
                              touched.address.postalCode
                                ? errors.address.postalCode
                                : ''
                            }
                          />
                        </li>
                      </ul>
                    </div>
                  </Fragment>
                )}
              </div>

              <Heading alignment="left" tagName="h4" variant="xs" className="mb-24">
                Individual verification documents
              </Heading>
              <div className="lp-w-full mb-24">
                <Text alignment="left" variant="body-2" isEmphasised>
                  ID type
                </Text>
                <Text tagName="span" variant="caption" className="text-greyish lp-ls5">
                  Select the document to verify the identity of the representative.
                </Text>
                {((updateItem && values.verificationDocs.idType) || !updateItem) && (
                  <div className="lp-w-full mb-16">
                    <div
                      className={`w-260${
                        errors.verificationDocs &&
                        errors.verificationDocs.idType &&
                        touched.verificationDocs &&
                        touched.verificationDocs.idType
                          ? ` has-error`
                          : ``
                      }`}
                    >
                      <RadioGroup
                        defaultValue={values.verificationDocs.idType}
                        name="verificationDocs.idType"
                        onChange={(value) => {
                          setFieldValue('verificationDocs.idType', value);
                          fileBackChangeHandler(null);
                          fileFrontChangeHandler(null);
                          setFieldValue('selectedFileBack', null);
                          setFieldValue('selectedFileFront', null);
                        }}
                        orientation="vertical"
                        radios={[
                          {
                            text: 'Driver License (Current)',
                            value: 'DriverLicence',
                          },
                          {
                            text: 'Passport',
                            value: 'Passport',
                          },
                        ]}
                        errorMessage={
                          errors.verificationDocs &&
                          errors.verificationDocs.idType &&
                          touched.verificationDocs &&
                          touched.verificationDocs.idType
                            ? errors.verificationDocs.idType
                            : ``
                        }
                      />
                    </div>
                  </div>
                )}

                {values.verificationDocs.idType === `DriverLicence` && (
                  <Fragment>
                    <div
                      className={`lp-w-full mb-24${
                        errors.driverLicence &&
                        errors.driverLicence.country &&
                        touched.driverLicence &&
                        touched.driverLicence.country
                          ? ` has-error`
                          : ``
                      }`}
                    >
                      <Select
                        label="Country"
                        testId="step05DLCountry"
                        onChange={(value) => setFieldValue('driverLicence.country', value)}
                        value={values.driverLicence.country}
                        options={[
                          merchantTaxCountry === 'NZ'
                            ? {
                                text: 'New Zealand',
                                value: 'NZ',
                              }
                            : {
                                text: 'Australia',
                                value: 'AU',
                              },
                        ]}
                        errorMessage={
                          errors.driverLicence &&
                          errors.driverLicence.country &&
                          touched.driverLicence &&
                          touched.driverLicence.country
                            ? errors.driverLicence.country
                            : ``
                        }
                      />
                    </div>

                    {values.driverLicence.country === 'AU' && (
                      <div
                        className={`lp-w-full mb-24${
                          errors.driverLicence &&
                          errors.driverLicence.state &&
                          touched.driverLicence &&
                          touched.driverLicence.state
                            ? ` has-error`
                            : ``
                        }`}
                      >
                        <Select
                          label="State"
                          testId="step05DLState"
                          onChange={(value) => setFieldValue('driverLicence.state', value)}
                          value={values.driverLicence.state}
                          options={AustralianStates}
                          errorMessage={
                            errors.driverLicence &&
                            errors.driverLicence.state &&
                            touched.driverLicence &&
                            touched.driverLicence.state
                              ? errors.driverLicence.state
                              : ''
                          }
                        />
                      </div>
                    )}

                    <div className="lp-w-full mb-24">
                      <TextField
                        fullWidth
                        label="Driver License number"
                        inputMode="text"
                        autoComplete="off"
                        id="driverLicence.driverLicenceNumber"
                        name="driverLicence.driverLicenceNumber"
                        onBlur={handleBlur}
                        value={values.driverLicence.driverLicenceNumber}
                        onChange={handleChange}
                        error={
                          errors.driverLicence &&
                          errors.driverLicence.driverLicenceNumber &&
                          touched.driverLicence &&
                          touched.driverLicence.driverLicenceNumber
                            ? errors.driverLicence.driverLicenceNumber
                            : ''
                        }
                      />
                    </div>

                    {values.driverLicence.country === 'AU' && (
                      <div className="lp-w-full mb-24">
                        <TextField
                          fullWidth
                          label="Document number"
                          inputMode="text"
                          autoComplete="off"
                          id="driverLicence.documentNumber"
                          name="driverLicence.documentNumber"
                          onBlur={handleBlur}
                          value={values.driverLicence.documentNumber}
                          onChange={handleChange}
                          error={
                            errors.driverLicence &&
                            errors.driverLicence.documentNumber &&
                            touched.driverLicence &&
                            touched.driverLicence.documentNumber
                              ? errors.driverLicence.documentNumber
                              : ''
                          }
                        />
                      </div>
                    )}

                    {values.driverLicence.country === 'NZ' && (
                      <div className="lp-w-full mb-24">
                        <TextField
                          fullWidth
                          label="Version number"
                          inputMode="text"
                          autoComplete="off"
                          id="driverLicence.versionNumber"
                          name="driverLicence.versionNumber"
                          onBlur={handleBlur}
                          value={values.driverLicence.versionNumber}
                          onChange={handleChange}
                          error={
                            errors.driverLicence &&
                            errors.driverLicence.versionNumber &&
                            touched.driverLicence &&
                            touched.driverLicence.versionNumber
                              ? errors.driverLicence.versionNumber
                              : ''
                          }
                        />
                      </div>
                    )}
                  </Fragment>
                )}

                {values.verificationDocs.idType === `Passport` && (
                  <Fragment>
                    <div
                      className={`lp-w-full mb-24${
                        errors.passport && errors.passport.country && touched.passport && touched.passport.country
                          ? ` has-error`
                          : ``
                      }`}
                    >
                      <Select
                        label="Country"
                        testId="step05CountrySec"
                        onChange={(value) => setFieldValue('passport.country', value)}
                        value={values.passport.country}
                        options={[
                          merchantTaxCountry === 'NZ'
                            ? {
                                text: 'New Zealand',
                                value: 'NZ',
                              }
                            : {
                                text: 'Australia',
                                value: 'AU',
                              },
                        ]}
                        errorMessage={
                          errors.passport && errors.passport.country && touched.passport && touched.passport.country
                            ? errors.passport.country
                            : ``
                        }
                      />
                    </div>
                    <div className="lp-w-full mb-24">
                      <TextField
                        fullWidth
                        label="Passport number"
                        inputMode="text"
                        autoComplete="off"
                        id="passport.passportNumber"
                        name="passport.passportNumber"
                        onBlur={handleBlur}
                        value={values.passport.passportNumber}
                        onChange={handleChange}
                        error={
                          errors.passport &&
                          errors.passport.passportNumber &&
                          touched.passport &&
                          touched.passport.passportNumber
                            ? errors.passport.passportNumber
                            : ''
                        }
                      />
                    </div>

                    <div className="lp-w-full mb-24">
                      <DateField
                        fullWidth
                        inputMode="numeric"
                        maskPlaceholder="DD/MM/YYYY"
                        label="Date of expiry"
                        mask={[/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                        autoComplete="off"
                        id="passport.expiryDate"
                        name="passport.expiryDate"
                        onBlur={handleBlur}
                        value={values.passport.expiryDate}
                        onChange={handleChange}
                        error={
                          errors.passport &&
                          errors.passport.expiryDate &&
                          touched.passport &&
                          touched.passport.expiryDate
                            ? errors.passport.expiryDate
                            : ''
                        }
                      />
                    </div>
                  </Fragment>
                )}
              </div>

              {values.verificationDocs.idType && (
                <div className="lp-w-full mb-40">
                  <div className="lp-w-full mb-24">
                    <Text alignment="left" variant="body-2" isEmphasised>
                      Verification documents
                    </Text>
                    <Text tagName="span" variant="caption" className="text-greyish lp-ls5">
                      Upload documents in jpeg, png or pdf format, maximum 10 MB per file.
                    </Text>
                  </div>
                  <div className="lp-w-full mb-16">
                    {fileFrontDetail && values.verificationDocs.idType === initialValues.verificationDocs.idType && (
                      <s.DocumentBlock className="lp-flex lp-justify-between lp-items-center sm:flex-col sm:align-start mb-24">
                        <Text alignment="left" variant="caption" isEmphasised className="text-primary">
                          Uploaded{' '}
                          {values.verificationDocs.idType === 'Passport' ? `Passport page` : `Driving license front`}
                        </Text>
                        <div className="upload-preview lp-flex">
                          <Icon name="Attachment" />
                          <div>
                            <Text alignment="left" variant="caption" isEmphasised className="text-primary mb-4">
                              {fileFrontDetail.fileName}
                            </Text>
                            <Text alignment="left" variant="legal" className="m-0">
                              {fileFrontDetail.fileSizeInKB}kb
                            </Text>
                          </div>
                        </div>
                      </s.DocumentBlock>
                    )}

                    <s.DocumentBlock className="lp-flex lp-justify-between lp-items-center sm:flex-col sm:align-start mb-4">
                      <Text alignment="left" variant="caption" isEmphasised className="text-primary">
                        {values.verificationDocs.idType === `DriverLicence`
                          ? `Driver Licence front`
                          : `Passport information page`}
                      </Text>
                      {selectedFileFront && (
                        <div className="upload-preview lp-flex">
                          <Icon name="Attachment" />
                          <div>
                            <Text alignment="left" variant="caption" isEmphasised className="text-primary mb-4">
                              {selectedFileFront.name.substring(0, 5)}...{selectedFileFront.name.slice(-8)}
                            </Text>
                            <Text alignment="left" variant="legal" className="m-0">
                              {parseInt((selectedFileFront.size / 1024).toString())}kb
                            </Text>
                          </div>
                          <button
                            type="button"
                            className="upload-delete"
                            onClick={() => {
                              fileFrontChangeHandler(null);
                              setFieldValue('selectedFileFront', null);
                            }}
                          >
                            <Icon name="Cross" />
                          </button>
                        </div>
                      )}

                      {!selectedFileFront && (
                        <span className="lp-flex lp-justify-center lp-items-center no-min-width btn-file">
                          Select file{' '}
                          <input
                            type="file"
                            data-testid="selectedFileFront"
                            id="selectedFileFront"
                            name="selectedFileFront"
                            accept="image/png, image/jpeg, image/jpg, .pdf"
                            onChange={(e) => {
                              fileFrontChangeHandler(e.target.files);
                              setFieldValue('selectedFileFront', e.target.files ? e.target.files[0] : {});
                            }}
                          />
                        </span>
                      )}
                    </s.DocumentBlock>
                    {errors.selectedFileFront && formRef.current?.isSubmitting ? (
                      <span className="Mui-error">
                        {values.verificationDocs.idType === 'DriverLicence'
                          ? `Please upload the front of the Driver License`
                          : `Please upload the Passport information page`}
                      </span>
                    ) : null}

                    {fileBackDetail && values.verificationDocs.idType === 'DriverLicence' && (
                      <s.DocumentBlock className="lp-flex lp-justify-between lp-items-center sm:flex-col sm:align-start mb-24 mt-24">
                        <Text alignment="left" variant="caption" isEmphasised className="text-primary">
                          Uploaded Driver Licence back
                        </Text>
                        <div className="upload-preview lp-flex">
                          <Icon name="Attachment" />
                          <div>
                            <Text alignment="left" variant="caption" isEmphasised className="text-primary mb-4">
                              {fileBackDetail.fileName}
                            </Text>
                            <Text alignment="left" variant="legal" className="m-0">
                              {fileBackDetail.fileSizeInKB}kb
                            </Text>
                          </div>
                        </div>
                      </s.DocumentBlock>
                    )}
                    {values.verificationDocs.idType === `DriverLicence` && (
                      <Fragment>
                        <s.DocumentBlock className="lp-flex lp-justify-between lp-items-center sm:flex-col sm:align-start mb-4 mt-24">
                          <Text alignment="left" variant="caption" isEmphasised className="text-primary">
                            Driver Licence back
                          </Text>
                          {selectedFileBack && (
                            <div className="upload-preview lp-flex">
                              <Icon name="Attachment" />
                              <div>
                                <Text alignment="left" variant="caption" isEmphasised className="text-primary mb-4">
                                  {selectedFileBack.name.substring(0, 5)}...{selectedFileBack.name.slice(-8)}
                                </Text>
                                <Text alignment="left" variant="legal" className="m-0">
                                  {parseInt((selectedFileBack.size / 1024).toString())}kb
                                </Text>
                              </div>
                              <button
                                type="button"
                                className="upload-delete"
                                onClick={() => {
                                  fileBackChangeHandler(null);
                                  setFieldValue('selectedFileBack', null);
                                }}
                              >
                                <Icon name="Cross" />
                              </button>
                            </div>
                          )}

                          {!selectedFileBack && (
                            <span className="lp-flex lp-justify-center lp-items-center no-min-width btn-file">
                              Select file{' '}
                              <input
                                type="file"
                                data-testid="selectedFileBack"
                                id="selectedFileBack"
                                name="selectedFileBack"
                                accept="image/png, image/jpeg, image/jpg, .pdf"
                                onChange={(e) => {
                                  fileBackChangeHandler(e.target.files);
                                  setFieldValue('selectedFileBack', e.target.files ? e.target.files[0] : {});
                                }}
                              />
                            </span>
                          )}
                        </s.DocumentBlock>
                        {errors.selectedFileBack && formRef.current?.isSubmitting ? (
                          <span className="Mui-error">{errors.selectedFileBack}</span>
                        ) : null}
                      </Fragment>
                    )}
                  </div>
                </div>
              )}
              <Heading alignment="left" tagName="h4" variant="xs" className="mb-24">
                Contact details
              </Heading>
              <div className="lp-w-full mb-24">
                <TextField
                  fullWidth
                  label="Email address"
                  inputMode="email"
                  type="email"
                  autoComplete="off"
                  id="email"
                  name="email"
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={errors.email && touched.email ? errors.email : ''}
                />
              </div>
              <div className={`lp-w-full mb-12${errors.phone && touched.phone ? ` has-error` : ``}`}>
                <div className={`form-floating`}>
                  <PhoneInput
                    defaultCountry={merchantTaxCountry === 'NZ' ? 'NZ' : 'AU'}
                    country={merchantTaxCountry === 'NZ' ? 'NZ' : 'AU'}
                    value={values.phone}
                    onChange={(e) => {
                      setFieldValue('phone', e);
                    }}
                    onBlur={handleBlur}
                    id="phone"
                    name="phone"
                    className={`${values.phone ? `is-filled` : `is-filled`}`}
                    countries={merchantTaxCountry === 'NZ' ? ['NZ'] : ['AU']}
                    international={true}
                    withCountryCallingCode={true}
                  />
                  <label htmlFor="floatingPhoneInput">Contact phone number</label>
                </div>
                <ErrorMessage name="phone" component="span" className="Mui-error" />
              </div>
            </div>
          </div>

          {!isPublicSection && (
            <div className={`modal-footer`}>
              <s.MultiColumnRespBlock className="lp-w-full lp-justify-between">
                <Button
                  type="button"
                  onClick={() => toggleModal(!isOpen)}
                  size="medium"
                  variant="ghost"
                  className="no-min-width"
                  disabled={isRequestLoading || fileFrontUploading || fileBackUploading}
                >
                  Cancel
                </Button>
                <div className="lp-flex">
                  {!updateItem && !directorSection && (
                    <Button
                      type="button"
                      onClick={() => setCurrentStep(1)}
                      size="medium"
                      variant="ghost"
                      className="no-min-width"
                      disabled={isRequestLoading || fileFrontUploading || fileBackUploading}
                    >
                      <Icon name="ArrowLeft" className="ml-0" />
                      Back
                    </Button>
                  )}
                  <Button
                    type="submit"
                    size="medium"
                    variant="primary"
                    className="no-min-width"
                    isLoading={isRequestLoading || fileFrontUploading || fileBackUploading}
                    disabled={isRequestLoading || fileFrontUploading || fileBackUploading}
                  >
                    Save
                  </Button>
                </div>
              </s.MultiColumnRespBlock>
            </div>
          )}

          {isPublicSection && (
            <s.Cta className="lp-flex lp-justify-end lp-items-center sm:flex-col">
              <Button
                type="submit"
                size="medium"
                variant="primary"
                className="no-min-width lp-full"
                isLoading={isRequestLoading || fileFrontUploading || fileBackUploading}
                disabled={isRequestLoading || fileFrontUploading || fileBackUploading}
                onClick={() => setFieldValue('isSaveFinishLater', false)}
              >
                Save and {saveAndReview ? `review` : `continue`}
                <Icon name="ArrowRight" />
              </Button>

              <div className="mobile-sidebar-footer">
                <Button size="medium" onClick={saveAndFinishLater} variant="ghost" className="lp-w-full">
                  <Icon name="Save" />
                  Save and finish later
                </Button>
              </div>
            </s.Cta>
          )}
        </form>
      )}
    </Formik>
  );
}

export default IndividualForm;
