import { getPrimaryMerchantIds, getSettingCountry } from 'api/platform/setting';
import PlatformLayout from 'layouts/platform/PlatformLayout';
import ErrorPage from 'pages/common/error';
import { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/platform/hooks';
import { setPlatformCountry } from 'redux/platform/slice/configSlice';
import { setPayoutMerchantId } from 'redux/platform/slice/settingSlice';
import { getPlatformBaseUrl } from 'utils/getBaseUrl';
import getErrorMessage from 'utils/getErrorMessage';

import { Spinner } from '@limepayments/cosmic';

import DashboardRoutesList from '../constants';
import SettingsBranding from './branding';
import BusinessProfile from './business-profile';
import RoutesList, { SettingRoutesList } from './constants';
import SettingsPayouts from './payouts';
import SettingsProfile from './profile';

const SettingsRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [fetchCountryLoader, setFetchCountryLoader] = useState<boolean>(true);
  const [fetchMerchantIdLoader, setFetchMerchantIdLoader] = useState<boolean>(true);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);

  const { isLoggedIn, marketplaceId, apiBaseUri, platformCountry, payoutMerchantId, platformName } = useAppSelector(
    (state) => ({
      isLoggedIn: state.config.isLoggedIn,
      marketplaceId: state.config.marketplaceId,
      apiBaseUri: state.config.apiBaseUri,
      platformCountry: state.config.platformCountry,
      payoutMerchantId: state.setting.payoutMerchantId,
      platformName: state.config.platformName,
    }),
  );

  useEffect(() => {
    if (!apiBaseUri || !marketplaceId || !isLoggedIn) {
      return;
    }

    const fetchSettingCountry = async () => {
      try {
        setFetchCountryLoader(true);

        const response = await getSettingCountry(apiBaseUri, marketplaceId);
        dispatch(setPlatformCountry(response[0]));
      } catch (error) {
        setErrorMsg(getErrorMessage(error));
      } finally {
        setFetchCountryLoader(false);
      }
    };

    fetchSettingCountry();
  }, [apiBaseUri, marketplaceId, isLoggedIn, dispatch]);

  useEffect(() => {
    if (!apiBaseUri || !marketplaceId || !isLoggedIn || !platformCountry) {
      return;
    }

    const fetchPayoutMerchantId = async () => {
      try {
        setFetchMerchantIdLoader(true);
        const response = await getPrimaryMerchantIds(apiBaseUri, marketplaceId, platformCountry);
        if (response.length === 1) {
          dispatch(setPayoutMerchantId(response[0]));
        }
      } catch (error) {
        setErrorMsg(getErrorMessage(error));
      } finally {
        setFetchMerchantIdLoader(false);
      }
    };

    fetchPayoutMerchantId();
  }, [apiBaseUri, marketplaceId, isLoggedIn, platformCountry, dispatch]);

  useEffect(() => {
    if (
      location.pathname ===
        `${getPlatformBaseUrl(platformName)}/dashboard/${DashboardRoutesList.SETTINGS_ROUTE}/${
          SettingRoutesList.SETTINGS_PAYOUTS
        }` &&
      !payoutMerchantId &&
      !fetchMerchantIdLoader
    ) {
      navigate(
        `${getPlatformBaseUrl(platformName)}/dashboard/${DashboardRoutesList.SETTINGS_ROUTE}/${
          SettingRoutesList.SETTINGS_BUSINESS_PROFILE
        }`,
      );
    }
  }, [location, platformName, payoutMerchantId, fetchMerchantIdLoader, navigate]);

  useEffect(() => {
    if (!fetchCountryLoader && !fetchMerchantIdLoader) {
      setIsPageLoading(false);
    }
  }, [fetchCountryLoader, fetchMerchantIdLoader, setIsPageLoading]);

  return (
    <Fragment>
      {!isPageLoading && errorMsg.length > 0 && <ErrorPage bodyTitle="Error" bodyText={errorMsg} />}

      {isPageLoading && errorMsg.length === 0 && (
        <PlatformLayout title="Business settings" activeTab="settings">
          <div className="spinner-wrapper">
            <Spinner variant="simple" isVisible label="Loading..." />
          </div>
        </PlatformLayout>
      )}

      {!isPageLoading && errorMsg.length === 0 && (
        <Routes>
          <Route path={RoutesList.SETTINGS_BRANDING} caseSensitive={true} element={<SettingsBranding />} />
          <Route path={RoutesList.SETTINGS_PAYOUTS} caseSensitive={true} element={<SettingsPayouts />} />
          <Route path={RoutesList.SETTINGS_PROFILE} caseSensitive={true} element={<SettingsProfile />} />
          <Route path={RoutesList.SETTINGS_BUSINESS_PROFILE} caseSensitive={true} element={<BusinessProfile />} />
        </Routes>
      )}
    </Fragment>
  );
};

export default SettingsRoutes;
