import { Fragment } from 'react';

import { Message } from '@limepayments/cosmic';

interface Props {
  bodyTitle?: string;
  bodyText: string;
}

function ErrorComponent({ bodyTitle, bodyText }: Props) {
  bodyTitle = bodyTitle ?? 'Error';
  return (
    <Fragment>
      <div className="mt-24 mb-24">
        <Message children={bodyText} type={'inline'} variant="error" />
      </div>
    </Fragment>
  );
}

export default ErrorComponent;
