import { Heading, Icon, Text } from '@limepayments/cosmic';

interface Props {
  title?: string;
  body?: string;
}

function ErrorFetchTxnListComponent({ title, body }: Props) {
  return (
    <div className="transaction-empty">
      <div className="transaction-empty-inner">
        <Icon name="Platforms" />
        <Heading alignment="center" tagName="h2" variant="xs" className="mt-12 mb-8">
          {title ? title : 'Unable to load payments'}
        </Heading>
        <Text alignment="center" tagName="p" variant="body-2" className="text-dark-50 mt-0 mb-16">
          {body ? body : `Sorry, we're unable to load your payments at the moment, please try again later.`}
        </Text>
      </div>
    </div>
  );
}

export default ErrorFetchTxnListComponent;
