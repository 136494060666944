import { getPaymentConfirmationDetail, getPaymentSourceDetail } from 'api/merchant/dashboard/settings/payment';
import {
  CheckoutApplePayAPIResponse,
  PaymentConfirmationAPIResponse,
} from 'api/merchant/dashboard/settings/payment.types';
import MerchantDashboardLayout from 'layouts/merchant-dashboard/MerchantDashboardLayout';
import { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from 'redux/merchant/hooks';
import getErrorMessage from 'utils/getErrorMessage';

import PaymentSettingView from './partials/PaymentSettingView';

export const getInitialCheckoutInfo = (): CheckoutApplePayAPIResponse => ({
  domainNames: [],
  encryptTo: '',
  merchantUrl: '',
  partnerInternalMerchantIdentifier: '',
  partnerMerchantName: '',
});

export const getInitialPaymentConfirmation = (): PaymentConfirmationAPIResponse => ({
  orderPaidNotificationEmails: [],
  orderPaidNotificationsEnabled: false,
});

function PaymentSettingPage() {
  const [paymentConfirmationDetailFetchLoader, setPaymentConfirmationDetailFetchLoader] = useState<boolean>(true);
  const [paymentSourceDetailFetchLoader, setPaymentSourceDetailFetchLoader] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [paymentConfirmationObj, setPaymentConfirmationObj] = useState<PaymentConfirmationAPIResponse>(
    getInitialPaymentConfirmation(),
  );
  const [paymentSourceObj, setPaymentSourceObj] = useState<CheckoutApplePayAPIResponse>(getInitialCheckoutInfo());

  const [paymentConfirmationEditModalOpen, setPaymentConfirmationEditModalOpen] = useState<boolean>(false);
  const [paymentSourceEditModalOpen, setPaymentSourceEditModalOpen] = useState<boolean>(false);

  const { merchantId, apiBaseUri } = useAppSelector((state) => ({
    merchantId: state.config.merchantId,
    apiBaseUri: state.config.apiBaseUri,
  }));

  const fetchPaymentSourceDetail = useCallback(async () => {
    try {
      setPaymentSourceDetailFetchLoader(true);

      const response = await getPaymentSourceDetail(apiBaseUri, merchantId);

      setPaymentSourceObj(response);
    } catch (error) {
      setPaymentSourceObj(getInitialCheckoutInfo());
      // setErrorMessage(getErrorMessage(error));
    } finally {
      setPaymentSourceDetailFetchLoader(false);
    }
  }, [apiBaseUri, merchantId]);

  const fetchPaymentConfirmationDetail = useCallback(async () => {
    try {
      setPaymentConfirmationDetailFetchLoader(true);

      const response = await getPaymentConfirmationDetail(apiBaseUri, merchantId);

      setPaymentConfirmationObj(response);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setPaymentConfirmationDetailFetchLoader(false);
    }
  }, [apiBaseUri, merchantId]);

  useEffect(() => {
    if (!merchantId) {
      return;
    }

    setErrorMessage('');
    fetchPaymentConfirmationDetail();
    fetchPaymentSourceDetail();
  }, [apiBaseUri, merchantId, fetchPaymentConfirmationDetail, fetchPaymentSourceDetail]);

  useEffect(() => {
    if (!paymentConfirmationDetailFetchLoader && !paymentSourceDetailFetchLoader) {
      setIsPageLoading(false);
    }
  }, [paymentConfirmationDetailFetchLoader, paymentSourceDetailFetchLoader, setIsPageLoading]);

  return (
    <MerchantDashboardLayout activeTab="settings" title="Payment Settings">
      <PaymentSettingView
        paymentConfirmationObj={paymentConfirmationObj}
        paymentSourceObj={paymentSourceObj}
        setPaymentConfirmationEditModalOpen={setPaymentConfirmationEditModalOpen}
        paymentConfirmationEditModalOpen={paymentConfirmationEditModalOpen}
        setPaymentSourceEditModalOpen={setPaymentSourceEditModalOpen}
        paymentSourceEditModalOpen={paymentSourceEditModalOpen}
        isPageLoading={isPageLoading}
        errorMessage={errorMessage}
        paymentConfirmationRefreshDataHandler={fetchPaymentConfirmationDetail}
        paymentConfirmationDetailFetchLoader={paymentConfirmationDetailFetchLoader}
        paymentSourceRefreshDataHandler={fetchPaymentSourceDetail}
        paymentSourceDetailFetchLoader={paymentSourceDetailFetchLoader}
      />
    </MerchantDashboardLayout>
  );
}

export default PaymentSettingPage;
