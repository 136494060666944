const compareTwoStrings = (a: string, b: string): boolean => {
  if (a.length !== b.length) {
    return false;
  }

  let matched: boolean = true;
  for (let i = 0; i < a.length; i++) {
    const xorResult = a[i].charCodeAt(0) ^ b[i].charCodeAt(0);
    if (xorResult !== 0) {
      matched = false;
      break;
    }
  }

  return matched;
};

export default compareTwoStrings;
