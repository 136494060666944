import { PlatformDashboardTransactionsObj } from 'api/platform/transactions.types';
import * as s from 'assets/styles/Platforms.styles';
import { DateTime } from 'luxon';
import { TableStyleSettings, TableThemeSettings } from 'pages/platform/constants';
import DashboardRoutesList from 'pages/platform/dashboard/constants';
import DataTable, { SortOrder, TableColumn, createTheme } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/platform/hooks';
import { setOpenTransactionFilterDropdown } from 'redux/platform/slice/transactionSlice';
import { getPlatformBaseUrl } from 'utils/getBaseUrl';

import { Spinner } from '@limepayments/cosmic';

import PaymentRoutesList from '../../constants';
import DataTableTxnRow from './DataTableTxnRow';
import EmptyTxnListComponent from './EmptyTxnListComponent';
import FilterEmptyTxnListComponent from './FilterEmptyTxnListComponent';
import StatusComponent from './StatusComponent';

const columns = [
  {
    name: 'Amount',
    cell: (row: PlatformDashboardTransactionsObj) =>
      row.payoutId ? (
        <DataTableTxnRow amount={row.amount} currency={row.currency} />
      ) : (
        <StatusComponent
          tooltipText={`This payout will be available on ${DateTime.fromISO(row.availableAt).toFormat('dd/LL/yyyy')}`}
          tooltipDirection={'right'}
        >
          <DataTableTxnRow amount={row.amount} currency={row.currency} />
        </StatusComponent>
      ),
    grow: 1,
  },
  {
    name: '',
    cell: (row: PlatformDashboardTransactionsObj, index: number) =>
      row.payoutId ? (
        row.currency
      ) : (
        <StatusComponent
          tooltipText={`This payout will be available on ${DateTime.fromISO(row.availableAt).toFormat('dd/LL/yyyy')}`}
          tooltipDirection={index === 0 ? 'down' : 'up'}
        >
          {row.currency}
        </StatusComponent>
      ),
  },
  {
    name: 'Transaction type',
    cell: (row: PlatformDashboardTransactionsObj, index: number) =>
      row.payoutId ? (
        row.entryType.split(/(?=[A-Z])/).join(' ')
      ) : (
        <StatusComponent
          tooltipText={`This payout will be available on ${DateTime.fromISO(row.availableAt).toFormat('dd/LL/yyyy')}`}
          tooltipDirection={index === 0 ? 'down' : 'up'}
        >
          {row.entryType.split(/(?=[A-Z])/).join(' ')}
        </StatusComponent>
      ),
  },
  {
    name: 'Merchant',
    cell: (row: PlatformDashboardTransactionsObj, index: number) =>
      row.payoutId ? (
        row.merchantName
      ) : (
        <StatusComponent
          tooltipText={`This payout will be available on ${DateTime.fromISO(row.availableAt).toFormat('dd/LL/yyyy')}`}
          tooltipDirection={index === 0 ? 'down' : 'up'}
        >
          {row.merchantName}
        </StatusComponent>
      ),
  },
  {
    name: 'Transaction ID',
    cell: (row: PlatformDashboardTransactionsObj, index: number) =>
      row.payoutId ? (
        row.txnId
      ) : (
        <StatusComponent
          tooltipText={`This payout will be available on ${DateTime.fromISO(row.availableAt).toFormat('dd/LL/yyyy')}`}
          tooltipDirection={index === 0 ? 'down' : 'up'}
        >
          {row.txnId}
        </StatusComponent>
      ),
  },
  {
    name: 'Created on',
    cell: (row: PlatformDashboardTransactionsObj, index: number) =>
      row.payoutId ? (
        DateTime.fromISO(row.createdAt).toFormat('dd/LL/yyyy, HH:mm')
      ) : (
        <StatusComponent
          tooltipText={`This payout will be available on ${DateTime.fromISO(row.availableAt).toFormat('dd/LL/yyyy')}`}
          tooltipDirection={index === 0 ? 'down' : 'up'}
        >
          {DateTime.fromISO(row.createdAt).toFormat('dd/LL/yyyy, HH:mm')}
        </StatusComponent>
      ),
    sortable: true,
    sortField: 'createdAt',
  },
  {
    name: 'Available on',
    cell: (row: PlatformDashboardTransactionsObj, index: number) =>
      row.payoutId ? (
        DateTime.fromISO(row.availableAt).toFormat('dd/LL/yyyy, HH:mm')
      ) : (
        <StatusComponent
          tooltipText={`This payout will be available on ${DateTime.fromISO(row.availableAt).toFormat('dd/LL/yyyy')}`}
          tooltipDirection={index === 0 ? 'down' : 'up'}
        >
          {DateTime.fromISO(row.availableAt).toFormat('dd/LL/yyyy, HH:mm')}
        </StatusComponent>
      ),
  },
];

// createTheme creates a new theme named solarized that overrides the build in dark theme
createTheme('polarized', TableThemeSettings, 'dark');

interface Props {
  transactionList: Array<PlatformDashboardTransactionsObj>;
  fetchListLoader: boolean;
  totalRows: number;
  handlePerRowsChange: (newPerPage: number, page: number) => void;
  handlePageChange: (page: number) => void;
  limit: number;
  activePage: number;
  handleSort: (selectedColumn: TableColumn<PlatformDashboardTransactionsObj>, sortDirection: SortOrder) => void;
}

function TransactionsDataTable({
  transactionList,
  totalRows,
  limit,
  fetchListLoader,
  handlePageChange,
  handlePerRowsChange,
  activePage,
  handleSort,
}: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { platformName } = useAppSelector((state) => ({
    platformName: state.config.platformName,
  }));

  return (
    <s.TransactionTable>
      <DataTable
        pagination
        responsive
        columns={columns}
        data={transactionList}
        customStyles={TableStyleSettings}
        theme="polarized"
        className=""
        progressPending={fetchListLoader}
        progressComponent={
          <div className="transaction-empty">
            <div className="transaction-empty-inner">
              <Spinner variant="simple" isVisible label="Loading..." />
            </div>
          </div>
        }
        noDataComponent={
          transactionList.length < 1 ? (
            <FilterEmptyTxnListComponent toggleFilter={() => dispatch(setOpenTransactionFilterDropdown(true))} />
          ) : (
            <EmptyTxnListComponent
              title={'No transactions to display'}
              body={'When transactions are initiated, they will appear here.'}
            />
          )
        }
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        paginationPerPage={limit}
        paginationDefaultPage={activePage}
        paginationRowsPerPageOptions={[20, 40, 60, 80, 100]}
        onRowClicked={(row) => {
          if (row.payoutId) {
            navigate(
              `${getPlatformBaseUrl(platformName)}/dashboard/${DashboardRoutesList.PAYMENT_ROUTE}/${
                PaymentRoutesList.PAYOUT_DETAILS
              }?payoutId=${row.payoutId}`,
            );
          }
        }}
        onSort={handleSort}
        conditionalRowStyles={[
          {
            when: (row: PlatformDashboardTransactionsObj) => !row.payoutId,
            style: {
              cursor: 'default',
            },
          },
        ]}
        sortServer
        persistTableHead
        defaultSortFieldId={6}
        defaultSortAsc={false}
        pointerOnHover={true}
      />
    </s.TransactionTable>
  );
}

export default TransactionsDataTable;
