import { Button, Heading, Illustration, Link, Text } from '@limepayments/cosmic';

interface Props {
  onSubmitClick: () => void;
  email: string;
  resentActivationLoader: boolean;
}

function AccountLinkExpiredView({ onSubmitClick, email, resentActivationLoader }: Props) {
  return (
    <>
      <div className="size-136 mx-auto mb-32">
        <Illustration name="Time" size="large" />
      </div>
      <div className="lp-w-full mb-32">
        <Heading alignment="center" tagName="h1" variant="md" className="mb-12">
          Account activation link expired
        </Heading>
        <Text tagName="p" variant="body-3" alignment="center" className="mb-0">
          The link to activate your account for <Link className="fw-medium">{email}</Link> has expired.
        </Text>
        <Text tagName="p" variant="body-3" alignment="center" className="mt-0">
          Please submit a new request to activate your account.
        </Text>
      </div>
      <Button
        onClick={onSubmitClick}
        size="medium"
        variant="primary"
        className="lp-w-full"
        isLoading={resentActivationLoader ? true : false}
      >
        Re-send activation link
      </Button>
    </>
  );
}

export default AccountLinkExpiredView;
