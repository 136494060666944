import ErrorPage from 'pages/common/error';
import { Route, Routes } from 'react-router-dom';

import RoutesList from './constants';
import VirtualTreminalPage from './lisitng';

const VirtualTerminalRoutes = () => {
  return (
    <Routes>
      <Route path={RoutesList.VIRTUAL_TERMINAL_LANDING} caseSensitive={true} element={<VirtualTreminalPage />} />
      <Route
        path="*"
        element={<ErrorPage bodyTitle="Page Not Found" bodyText="The page you are trying to access doesn't exists" />}
      />
    </Routes>
  );
};

export default VirtualTerminalRoutes;
