import * as s from 'assets/styles/Platforms.styles';
import { FormikProps } from 'formik';
import SettingsSidebar from 'layouts/platform/SettingsSidebar';
import { RefObject } from 'react';

import { Button, Heading, Icon, LabelledValue } from '@limepayments/cosmic';

import PasswordUpdateModal from './partials/PasswordUpdateModal';
import { SettingPasswordUpdatePayload } from './types';

export interface Props {
  merchantEmail: string;
  initialState: SettingPasswordUpdatePayload;
  onSubmitClick: (values: SettingPasswordUpdatePayload, formObj: { resetForm: () => void }) => void;
  formRef: RefObject<FormikProps<SettingPasswordUpdatePayload>>;
  errorMsg: string;
  updateLoader: boolean;
  setPasswordEditModalOpen: (val: boolean) => void;
  passwordEditModalOpen: boolean;
}

function SettingsProfile({
  merchantEmail,
  updateLoader,
  errorMsg,
  initialState,
  passwordEditModalOpen,
  setPasswordEditModalOpen,
  onSubmitClick,
  formRef,
}: Props) {
  return (
    <>
      <div className="content-wrap-inner with-gutter">
        <div className="lp-w-full lp-flex">
          <div className="payment-sidebar">
            <SettingsSidebar activeTab="profile" />
          </div>

          <div className="payment-content-area">
            <div className="lp-w-full lp-flex lp-justify-between sm-lp-flex-col mb-32">
              <Heading alignment="left" tagName="h2" variant="xl" className="page-title mb-0">
                My profile
              </Heading>
            </div>

            <s.MerchantsData className="bt-1">
              {/* <div className="lp-w-full mb-32">
                <LabelledValue label="Name" value="Sean Hunt- NOT POPULATED YET" variant="vertical" />
              </div> */}
              <div className="lp-w-full mb-0">
                <LabelledValue label="Email" value={merchantEmail} variant="vertical" />
              </div>
            </s.MerchantsData>

            <s.MerchantsData className="">
              <div className="lp-w-full lp-flex lp-align-center lp-flex-row mb-32 sm-lp-align-start">
                <LabelledValue label="Password" value="*****************" variant="vertical" />
                <div className="ml-auto">
                  <Button size="small" onClick={() => setPasswordEditModalOpen(true)} variant="reversed">
                    Update
                    <Icon name="Edit" />
                  </Button>
                </div>
              </div>
            </s.MerchantsData>
          </div>
        </div>
      </div>

      <PasswordUpdateModal
        isOpen={passwordEditModalOpen}
        modalToggler={setPasswordEditModalOpen}
        onSubmitClick={onSubmitClick}
        formRef={formRef}
        errorMsg={errorMsg}
        updateLoader={updateLoader}
        initialValues={initialState}
      />
    </>
  );
}

export default SettingsProfile;
