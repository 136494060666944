import { GetMerchantFileResponse } from 'api/merchant/onbording/file.types';
import * as s from 'assets/styles/Onboarding.styles';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/merchant/hooks';
import { getMerchantBaseUrl } from 'utils/getBaseUrl';

import { Button, Heading, Icon, Text } from '@limepayments/cosmic';

import OnboardingRoutesList from '../../constants';
import { UpdateMerchantBusinessRegistrationPayload } from '../../step-01/types';

interface Props {
  businessDetail: UpdateMerchantBusinessRegistrationPayload;
  businessFileDetail: GetMerchantFileResponse | null;
}

const OfficialBusinessDetails = ({ businessDetail, businessFileDetail }: Props) => {
  const navigate = useNavigate();

  const { merchantName } = useAppSelector((state) => ({
    merchantName: state.config.merchantName,
  }));

  return (
    <>
      <div className="lp-w-full lp-equal-width mb-24">
        <s.MultiColumnRespBlock className="content-header mb-24">
          <Heading alignment="left" tagName="h4" variant="sm">
            Official business details
          </Heading>
          <Button
            onClick={() => {
              navigate(`${getMerchantBaseUrl(merchantName)}/onboarding/${OnboardingRoutesList.ONBOARDING_STEP01_PAGE}`);
            }}
            size="medium"
            variant="ghost"
            className="no-min-width lp-justify-end"
            testId="official-business-detail-edit-button"
          >
            Edit
            <Icon name="Edit" />
          </Button>
        </s.MultiColumnRespBlock>
        <div className="content">
          <s.MultiColumnRespBlock className="mb-24" data-testid="business-reg-div">
            <Text tagName="p" variant="body-3" testId="business-reg-text">
              Business registration number
            </Text>
            <Text tagName="p" variant="body-3" testId="business-reg-value" isEmphasised>
              {businessDetail.taxId}
            </Text>
          </s.MultiColumnRespBlock>
          <s.MultiColumnRespBlock className="mb-24" data-testid="legal-entity-div">
            <Text tagName="p" variant="body-3" testId="legal-entity-text">
              Legal entity name
            </Text>
            <Text tagName="p" variant="body-3" testId="legal-entity-value" isEmphasised>
              {businessDetail.businessName}
            </Text>
          </s.MultiColumnRespBlock>
          <s.MultiColumnRespBlock className="mb-24" data-testid="business-structure-div">
            <Text tagName="p" variant="body-3" testId="business-structure-text">
              Business structure
            </Text>
            <Text tagName="p" variant="body-3" testId="business-structure-value" isEmphasised>
              {businessDetail.businessType.charAt(0).toUpperCase() + businessDetail.businessType.slice(1)}
            </Text>
          </s.MultiColumnRespBlock>
          {/*           
          {(businessDetail.businessType === 'trust' || businessDetail.businessType === 'partnership') &&
            businessFileDetail && (
              <s.MultiColumnRespBlock className="mb-24">
                <Text tagName="p" variant="body-3">
                  {businessDetail.businessType === 'trust' ? `Trust deed` : `Partnership agreement`}
                </Text>
                <Text tagName="p" variant="body-3">
                  <strong>{businessFileDetail.fileName}</strong>
                </Text>
              </s.MultiColumnRespBlock>
            )} */}
          <s.MultiColumnRespBlock className="mb-24" data-testid="business-industry-div">
            <Text tagName="p" variant="body-3" testId="business-structure-text">
              Business industry
            </Text>
            <Text tagName="p" variant="body-3" testId="business-structure-value" isEmphasised>
              {businessDetail.industry.split(/(?=[A-Z])/).join(' ')}
            </Text>
          </s.MultiColumnRespBlock>
        </div>
      </div>
      <s.Separator>
        <hr />
      </s.Separator>
    </>
  );
};

export default OfficialBusinessDetails;
