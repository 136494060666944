import { handleResponse } from 'api/utils';
import getFetchOptions from 'utils/getFetchOptions';

import { AccountInterface, PayoutReportsAPIResponse } from './payouts.types';

export const getPayoutFrequency = async (host: string, merchantId: string): Promise<string> => {
  const options = await getFetchOptions();

  return await fetch(`${host}/merchants/${merchantId}/settings/payout-schedule`, options).then(handleResponse);
};

export const updatePayoutSchedule = async (host: string, merchantId: string, payload: string): Promise<string> => {
  const options = await getFetchOptions('POST', { payload: payload });

  return await fetch(`${host}/merchants/${merchantId}/settings/payout-schedule`, options).then(handleResponse);
};

export const getPayoutReports = async (host: string, merchantId: string): Promise<PayoutReportsAPIResponse> => {
  const options = await getFetchOptions();

  return await fetch(`${host}/merchants/${merchantId}/settings/payout`, options).then(handleResponse);
};

export const updatePayoutReports = async (
  host: string,
  merchantId: string,
  payload: PayoutReportsAPIResponse,
): Promise<PayoutReportsAPIResponse> => {
  const options = await getFetchOptions('POST', { payload });

  return await fetch(`${host}/merchants/${merchantId}/settings/payout`, options).then(handleResponse);
};

export const getPayoutBankAccounts = async (
  host: string,
  merchantId: string,
  timestamp: number,
): Promise<Array<AccountInterface>> => {
  const options = await getFetchOptions();

  return await fetch(`${host}/merchants/${merchantId}/external-bank-account/query?t=${timestamp}`, options).then(
    handleResponse,
  );
};

// export const updatePayoutBankAccount = async (
//   host: string,
//   merchantId: string,
//   payload: SettingPayoutPayload,
// ): Promise<Array<AccountInterface>> => {
//   const options = await getFetchOptions('POST', { payload });

//   return await fetch(`${host}/merchants/${merchantId}/external-bank-account/query`, options).then(handleResponse);
// };
