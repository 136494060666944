import { handleResponse } from 'api/utils';
import { UpdateMerchantBusinessContactPayload } from 'pages/merchant/onboarding/step-03/types';
import getFetchOptions from 'utils/getFetchOptions';

import { GetMerchantBusinessContactResponse } from './step-03.types';

export const getMerchantBusinessContact = async (
  host: string,
  merchantId: string,
): Promise<GetMerchantBusinessContactResponse> => {
  const options = await getFetchOptions();
  return await fetch(`${host}/onboarding/${merchantId}/business/contact`, options).then(handleResponse);
};

export const updateMerchantBusinessContact = async (
  host: string,
  merchantId: string,
  payload: UpdateMerchantBusinessContactPayload,
): Promise<void> => {
  const options = await getFetchOptions('POST', { payload });
  return await fetch(`${host}/onboarding/${merchantId}/business/contact`, options).then(handleResponse);
};
