import DashboardRoutesList from 'pages/platform/dashboard/constants';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/platform/hooks';
import { getPlatformBaseUrl } from 'utils/getBaseUrl';

import { Tag } from '@limepayments/cosmic';
import { TagProps } from '@limepayments/cosmic/build/Tag/Tag';

import PaymentRoutesList from '../../constants';

export interface StatusTagProps {
  label: string;
  variant: TagProps['variant'];
  tooltipText: string;
  tooltipDirection: any;
  paymentTxnId?: string;
}

function StatusTag({ label, variant, tooltipText, tooltipDirection, paymentTxnId }: StatusTagProps) {
  const navigate = useNavigate();

  const { platformName } = useAppSelector((state) => ({
    platformName: state.config.platformName,
  }));

  return (
    <div
      onClick={() =>
        paymentTxnId
          ? navigate(
              `${getPlatformBaseUrl(platformName)}/dashboard/${DashboardRoutesList.PAYMENT_ROUTE}/${
                PaymentRoutesList.PAYMENT_LANDING
              }`,
            )
          : null
      }
      className="lp-flex"
      data-balloon={tooltipText}
      data-balloon-pos={tooltipDirection}
      data-balloon-mobile-pos="left"
      data-balloon-length="large"
      data-balloon-mobile-length="medium"
      data-testid="status-tag-parent"
    >
      <Tag label={label} size="small" variant={variant} testId="status-tag-value" />
    </div>
  );
}

export default StatusTag;
