import MerchantDefaultRoutes from 'pages/merchant/constants';
import AuthRoutesList from 'pages/platform/auth/constants';
import DashboardRoutesList from 'pages/platform/dashboard/constants';
import SettingRoutesList from 'pages/platform/dashboard/settings/constants';
import ClickAwayListener from 'react-click-away-listener';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/merchant/hooks';
import { getMerchantBaseUrl } from 'utils/getBaseUrl';

import { Button } from '@limepayments/cosmic';

interface Props {
  onCloseSettingClick: (val: boolean) => void;
}

function SettingsDropdown({ onCloseSettingClick }: Props) {
  const navigate = useNavigate();
  const { merchantName } = useAppSelector((state) => ({
    merchantName: state.config.merchantName,
  }));

  return (
    <ClickAwayListener onClickAway={() => onCloseSettingClick(false)}>
      <div className="lp-flex lp-flex-column">
        {/* <div className="setting-dropdown-header">
          <p className="lp-flex lp-justify-start lp-items-center lp-font-button fw-medium lp-w-full btnText mb-8">
            Simon Cusack
          </p>
          <Text alignment="left" tagName="span" variant="caption" className="userRole">
            Admin
          </Text>
        </div> */}
        <div className="setting-dropdown-body">
          <ul>
            <li>
              <Button
                size="medium"
                variant="ghost"
                className="lp-w-full btnText"
                onClick={() =>
                  navigate(
                    `${getMerchantBaseUrl(merchantName)}/${MerchantDefaultRoutes.DASHBOARD_ROUTE}/${
                      DashboardRoutesList.SETTINGS_ROUTE
                    }/${SettingRoutesList.SETTINGS_PROFILE}`,
                  )
                }
                testId="setting-menu-link"
              >
                Settings
              </Button>
            </li>
            <li>
              <Button
                onClick={() => navigate(`${getMerchantBaseUrl(merchantName)}/${AuthRoutesList.LOGOUT_PAGE}`)}
                size="medium"
                variant="ghost"
                className="lp-w-full btnText"
              >
                Logout
              </Button>
            </li>
          </ul>
        </div>
      </div>
    </ClickAwayListener>
  );
}

export default SettingsDropdown;
