import { handleResponse } from 'api/utils';
import { CreateMerchantExternalBankAccountPayload } from 'pages/merchant/onboarding/step-04/types';
import getFetchOptions from 'utils/getFetchOptions';

import { GetAuBankBranchResponse, GetMerchantExternalBankAccountObj, GetNzBankBranchResponse } from './step-04.types';

export const getMerchantExternalBankAccount = async (
  host: string,
  merchantId: string,
): Promise<Array<GetMerchantExternalBankAccountObj>> => {
  const options = await getFetchOptions();
  return await fetch(`${host}/merchants/${merchantId}/external-bank-account/query`, options).then(handleResponse);
};

export const createMerchantExternalBankAccount = async (
  host: string,
  merchantId: string,
  payload: CreateMerchantExternalBankAccountPayload,
): Promise<string> => {
  const options = await getFetchOptions('POST', { payload });
  return await fetch(`${host}/merchants/${merchantId}/external-bank-account`, options).then(handleResponse);
};

export const getAuBankBranch = async (host: string, BSB: string): Promise<GetAuBankBranchResponse> => {
  const options = await getFetchOptions();
  return await fetch(`${host}/onboarding/banks/au/${BSB}`, options).then(handleResponse);
};

export const getNzBankBranch = async (host: string, nzAccountPrefix: string): Promise<GetNzBankBranchResponse> => {
  const options = await getFetchOptions();
  return await fetch(`${host}/onboarding/banks/nz/${nzAccountPrefix}`, options).then(handleResponse);
};
