import { PayoutAPIResponseType } from 'api/merchant/dashboard/payouts.types';
import { DateTime } from 'luxon';
import { MerchantDefaultRoutes } from 'pages/merchant/constants';
import { DashboardRoutesList } from 'pages/merchant/dashboard/constants';
import { PayoutRoutesList, PayoutStatusObj } from 'pages/merchant/dashboard/payout/constants';
import { TableStyleSettings, TableThemeSettings } from 'pages/platform/constants';
import DataTable, { SortOrder, TableColumn, createTheme } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/merchant/hooks';
import { setOpenPaymentFilterDropdown } from 'redux/merchant/slice/paymentSlice';
import { toCurrency } from 'utils/currency';
import { getMerchantBaseUrl } from 'utils/getBaseUrl';

import { Spinner } from '@limepayments/cosmic';

import * as s from '../../../../../../assets/styles/Platforms.styles';
import DownloadModalComponent from './DownloadModalComponent';
import EmptyComponent from './EmptyComponent';
import EmptyPayoutFilterComponent from './EmptyPayoutFilterComponent';
import ErrorComponent from './ErrorComponent';
import StatusTag from './StatusTagComponent';

const columns: TableColumn<PayoutAPIResponseType>[] = [
  {
    name: 'Amount',
    selector: (row: PayoutAPIResponseType) => toCurrency(row.amount, row.currency),
    width: '100px',
    right: true,
  },
  {
    name: '',
    selector: (row: PayoutAPIResponseType) => row.currency,
    width: '70px',
    style: {
      color: 'rgb(var(--lp-colors-neutral-400))',
    },
  },
  {
    name: 'Payout ID',
    selector: (row: PayoutAPIResponseType) => row.payoutId,
    minWidth: '250px',
  },
  {
    name: 'Created on',
    selector: (row: PayoutAPIResponseType) => `${DateTime.fromISO(row.createdAt).toFormat('dd/LL/yyyy, HH:mm')}`,
    sortable: true,
    sortField: 'createdAt',
    minWidth: '170px',
  },
  {
    name: 'Available on',
    selector: (row: PayoutAPIResponseType) =>
      row.settlementDate ? `${DateTime.fromISO(row.settlementDate).toFormat('dd/LL/yyyy, HH:mm')}` : '',
    minWidth: '170px',
  },
  {
    name: 'Status',
    cell: (row: PayoutAPIResponseType) => {
      return (
        <StatusTag
          label={PayoutStatusObj[row.status as keyof typeof PayoutStatusObj].label}
          variant={PayoutStatusObj[row.status as keyof typeof PayoutStatusObj].varient}
          tooltipDirection="left"
          tooltipText={PayoutStatusObj[row.status as keyof typeof PayoutStatusObj].tooltipText}
        />
      );
    },
    width: '110px',
  },
  {
    name: '',
    cell: (row: PayoutAPIResponseType) => {
      return <DownloadModalComponent />;
    },
    width: '50px',
  },
];

// createTheme creates a new theme named solarized that overrides the build in dark theme
createTheme('polarized', TableThemeSettings, 'dark');

interface Props {
  payoutList: Array<PayoutAPIResponseType>;
  fetchListLoader: boolean;
  totalRows: number;
  handlePerRowsChange: (newPerPage: number, page: number) => void;
  handlePageChange: (page: number) => void;
  limit: number;
  activePage: number;
  isFilterRequest: boolean;
  handleSort: (selectedColumn: TableColumn<PayoutAPIResponseType>, sortDirection: SortOrder) => void;
  isErrorRequest?: boolean;
}

function PayoutDataTable({
  payoutList,
  totalRows,
  limit,
  fetchListLoader,
  handlePageChange,
  handlePerRowsChange,
  activePage,
  handleSort,
  isFilterRequest,
  isErrorRequest,
}: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { merchantName } = useAppSelector((state) => ({
    merchantName: state.config.merchantName,
  }));

  return (
    <s.PayoutsTable>
      <DataTable
        pagination
        responsive
        columns={columns}
        data={payoutList}
        customStyles={TableStyleSettings}
        theme="polarized"
        className=""
        progressPending={fetchListLoader}
        progressComponent={
          <div className="transaction-empty">
            <div className="transaction-empty-inner">
              <Spinner variant="simple" isVisible label="Loading..." />
            </div>
          </div>
        }
        noDataComponent={
          isErrorRequest ? (
            <ErrorComponent />
          ) : isFilterRequest && payoutList.length < 1 ? (
            <EmptyPayoutFilterComponent toggleFilter={() => dispatch(setOpenPaymentFilterDropdown(true))} />
          ) : (
            <EmptyComponent />
          )
        }
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        paginationPerPage={limit}
        paginationDefaultPage={activePage}
        onRowClicked={(row) =>
          navigate(
            `${getMerchantBaseUrl(merchantName)}/${MerchantDefaultRoutes.DASHBOARD_ROUTE}/${
              DashboardRoutesList.PAYOUT_ROUTE
            }/${PayoutRoutesList.PAYOUT_DETAILS}?payoutId=${row.payoutId}`,
          )
        }
        onSort={handleSort}
        sortServer
        persistTableHead
        defaultSortFieldId={4}
        defaultSortAsc={false}
        paginationRowsPerPageOptions={[20, 40, 60, 80, 100]}
        pointerOnHover={true}
      />
    </s.PayoutsTable>
  );
}

export default PayoutDataTable;
