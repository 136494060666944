import { resetPassword, sendResetPasswordEmail, validateToken } from 'api/auth/merchant-identity';
import RoutesList from 'config/constants';
import AuthLayout from 'layouts/auth/AuthLayout';
import AccountCheckEmailSendView from 'pages/auth/account-activate/AccountCheckEmailSendView';
import AccountLinkExpiredView from 'pages/auth/account-activate/AccountLinkExpiredView';
import AccountLinkInvalidView from 'pages/auth/account-activate/AccountLinkInvalidView';
import AccountSuccessView from 'pages/auth/account-activate/AccountSuccessView';
import ActivationAccountView from 'pages/auth/account-activate/ActivationAccountView';
import ErrorPage from 'pages/common/error';
import qs from 'query-string';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/merchant/hooks';
import { getMerchantBaseUrl } from 'utils/getBaseUrl';
import getErrorMessage from 'utils/getErrorMessage';

import { Spinner } from '@limepayments/cosmic';

import AuthRoutesList from '../constants';

type FormikFormValues = {
  newPassword: string;
  confirmNewPassword: string;
  termsAgreed: boolean;
};

function ActivateAccountPage() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [tokenErrorMessage, setTokenErrorMessage] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showConfirmSection, setShowConfirmSection] = useState<boolean>(false);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [expiredToken, setExpiredToken] = useState<boolean>(false);
  const [invalidToken, setInvalidToken] = useState<boolean>(false);
  const [isValidateTokenLoading, setIsValidateTokenLoading] = useState<boolean>(false);
  const [showAccountEmailSentSection, setShowAccountEmailSentSection] = useState<boolean>(false);
  const [resentActivationLoader, setResentActivationLoader] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<FormikFormValues>({
    newPassword: '',
    confirmNewPassword: '',
    termsAgreed: false,
  });

  const { apiBaseUri, merchantId, merchantName } = useAppSelector((state) => ({
    apiBaseUri: state.config.apiBaseUri,
    merchantId: state.config.merchantId,
    merchantName: state.config.merchantName,
  }));

  const { ref } = qs.parse(window.location.search);

  useEffect(() => {
    if (merchantId) {
      setIsPageLoading(false);
    }
  }, [setIsPageLoading, merchantId]);

  useEffect(() => {
    if (!ref || !apiBaseUri || !merchantName) {
      setTokenErrorMessage('Not all required parameters are set');
      return;
    }

    setTokenErrorMessage('');
    setIsValidateTokenLoading(true);
    validateToken(apiBaseUri, ref as string)
      .then(({ isExpired, emailAddress }) => {
        setErrorMessage('');
        setEmail(emailAddress);

        if (isExpired) {
          setExpiredToken(true);
        }
      })
      .catch((e) => {
        setInvalidToken(true);
      })
      .finally(() => {
        setIsValidateTokenLoading(false);
      });
  }, [apiBaseUri, merchantName, ref]);

  const submitHandler = async (values: FormikFormValues, formObj: { resetForm: () => void }) => {
    try {
      setErrorMessage('');
      setIsLoading(true);

      await resetPassword(apiBaseUri, ref as string, { password: values.newPassword });

      setInitialValues(initialValues);
      formObj.resetForm();

      setShowConfirmSection(true);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  const resendActivateLinkHandler = async () => {
    try {
      setErrorMessage('');
      setResentActivationLoader(true);

      await sendResetPasswordEmail(apiBaseUri, {
        slug: merchantName,
        userType: 'merchantStaff',
        emailAddress: email,
        dashboardReferrer: 'Platform',
      });

      setShowAccountEmailSentSection(true);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setResentActivationLoader(false);
    }
  };

  return (
    <Fragment>
      {!isPageLoading && !isValidateTokenLoading && (
        <Fragment>{tokenErrorMessage.length > 0 && <ErrorPage bodyText={tokenErrorMessage} />}</Fragment>
      )}

      {(isPageLoading || isValidateTokenLoading) && (
        <div className="spinner-wrapper">
          <Spinner variant="simple" isVisible />
        </div>
      )}

      {(isPageLoading || isValidateTokenLoading || !tokenErrorMessage.length) && (
        <AuthLayout title="Activate account">
          <Fragment>
            {invalidToken && <AccountLinkInvalidView onSubmitClick={() => resendActivateLinkHandler()} />}

            {expiredToken && !showAccountEmailSentSection && (
              <AccountLinkExpiredView
                onSubmitClick={() => resendActivateLinkHandler()}
                email={email}
                resentActivationLoader={resentActivationLoader}
              />
            )}

            {!isPageLoading && !isValidateTokenLoading && !tokenErrorMessage.length && !invalidToken && !expiredToken && (
              <Fragment>
                {!showConfirmSection && !showAccountEmailSentSection && (
                  <ActivationAccountView
                    onSubmitClick={submitHandler}
                    initialValues={initialValues}
                    passwordUpdateRequestLoader={isLoading}
                    errorMsg={errorMessage}
                    email={email}
                  />
                )}

                {showConfirmSection && !showAccountEmailSentSection && (
                  <AccountSuccessView
                    onSubmitClick={() =>
                      navigate(
                        `${getMerchantBaseUrl(merchantName)}/${RoutesList.AUTH_ROUTE}/${AuthRoutesList.LOGIN_PAGE}`,
                      )
                    }
                  />
                )}
              </Fragment>
            )}

            {!isPageLoading &&
              !isValidateTokenLoading &&
              !tokenErrorMessage.length &&
              !invalidToken &&
              showAccountEmailSentSection &&
              !showConfirmSection && <AccountCheckEmailSendView email={email} />}
          </Fragment>
        </AuthLayout>
      )}
    </Fragment>
  );
}

export default ActivateAccountPage;
