import { getMerchantFile, uploadOnboardingMerchantFile } from 'api/merchant/onbording/file';
import { GetMerchantFileResponse } from 'api/merchant/onbording/file.types';
import {
  getMerchantBusinessRegistration,
  getSupportedIndustry,
  updateMerchantBusinessRegistration,
} from 'api/merchant/onbording/step-01';
import { SupportedIndustryType } from 'api/merchant/onbording/step-01.types';
import { FormikProps } from 'formik';
import MerchantLayout from 'layouts/merchant/MerchantLayout';
import ErrorComponent from 'pages/common/error/ErrorComponent';
import AuthRoutesList from 'pages/merchant/auth/constants';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/merchant/hooks';
import { setCurrentActiveStep, setSaveAndFinishLater } from 'redux/merchant/slice/merchantOnboardSlice';
import { getMerchantBaseUrl } from 'utils/getBaseUrl';
import getErrorMessage from 'utils/getErrorMessage';

import { Spinner } from '@limepayments/cosmic';

import OnboardingRoutesList from '../constants';
import Step01View from './Step01View';
import { UpdateMerchantBusinessRegistrationPayload } from './types';

function OnboardingStep01Page() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const formRef = useRef<FormikProps<UpdateMerchantBusinessRegistrationPayload>>(null);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [requiredErrorMessage, setRequiredErrorMessage] = useState<string>('');
  const [fetchDetailLoader, setFetchDetailLoader] = useState<boolean>(true);
  const [fetchFileDetailLoader, setFetchFileDetailLoader] = useState<boolean>(false);
  const [fileUploading, setFileUploading] = useState<boolean>(false);
  const [fetchSupportIndustryLoader, setFetchSupportIndustryLoader] = useState<boolean>(true);
  const [supportIndustryList, setSupportIndustryList] = useState<Array<SupportedIndustryType>>([]);
  const [trustFileDetail, setTrustFileDetail] = useState<GetMerchantFileResponse | null>(null);
  const [partnershipFileDetail, setPartnershipFileDetail] = useState<GetMerchantFileResponse | null>(null);
  const [fileAmendementDetail, setFileAmendementDetail] = useState<Array<GetMerchantFileResponse> | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedAmendementFile, setSelectedAmendementFile] = useState<Array<File> | null>(null);
  const [initialValues, setInitialValues] = useState<UpdateMerchantBusinessRegistrationPayload>({
    businessName: '',
    businessType: '',
    companyType: '',
    industry: '',
    taxId: '',
    verificationDocs: {
      companyBackFileId: '',
      companyFrontFileId: '',
      companyTaxIdFileId: '',
      partnershipAgreementFileId: '',
      trustDeedFileId: '',
    },
    isSaveFinishLater: false,
    fileUpload: null,
    merchantTaxCountry: '',
    updateItem: false,
  });

  const { merchantId, merchantName, saveAndFinishLater, apiBaseUri, merchantTaxCountry, hasCompletedSteps } =
    useAppSelector((state) => ({
      merchantId: state.config.merchantId,
      merchantName: state.config.merchantName,
      saveAndFinishLater: state.onboarding.saveAndFinishLater,
      apiBaseUri: state.config.apiBaseUri,
      merchantTaxCountry: state.config.merchantTaxCountry,
      hasCompletedSteps: state.onboarding.hasCompletedSteps,
    }));

  const agreementRemoveChangeHandler = (trustFileRemove: boolean) => {
    trustFileRemove ? setTrustFileDetail(null) : setPartnershipFileDetail(null);
  };

  const fileChangeHandler = (file: FileList | null) => {
    if (file && file.length > 0) {
      setSelectedFile(file[0]);
    } else {
      setSelectedFile(null);
    }
  };

  const amendementFileChangeHandler = (file: FileList | null) => {
    if (file && file.length > 0) {
      const filesArray = selectedAmendementFile ? [...selectedAmendementFile] : [];
      for (let i = 0; i < file.length; i++) {
        filesArray.push(file[i]);
      }
      setSelectedAmendementFile(filesArray);
    } else {
      setSelectedAmendementFile(null);
    }
  };

  const amendementFileRemoveHandler = (index: number) => {
    if (selectedAmendementFile && selectedAmendementFile.length > 0) {
      const filterList = selectedAmendementFile.filter((obj, key) => {
        return key !== index;
      });
      setSelectedAmendementFile(filterList);
    }
  };

  const amendementUploadedFileRemoveHandler = (fileId: string) => {
    if (fileAmendementDetail && fileAmendementDetail.length > 0) {
      const filterList = fileAmendementDetail.filter((obj) => {
        return obj.fileId !== fileId;
      });

      setFileAmendementDetail(filterList);
    }
  };

  const fileUploadHandler = async (businessType: string) => {
    try {
      setFileUploading(true);
      setErrorMessage('');

      const formData = new FormData();
      if (selectedFile) {
        formData.append('file', selectedFile, selectedFile.name);
        formData.append('category', 'MerchantOnboarding');
        formData.append('docType', businessType === 'partnership' ? `PartnershipAgreement` : `TrustDeed`);
      }

      const response = await uploadOnboardingMerchantFile(apiBaseUri, merchantId, formData);
      return response.limeFileId;
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setFileUploading(false);
    }

    return '';
  };

  const fileAmendementUploadHandler = async () => {
    try {
      setFileUploading(true);
      setErrorMessage('');

      let returnData = [];

      if (selectedAmendementFile && selectedAmendementFile.length) {
        for (let i = 0; i < selectedAmendementFile.length; i++) {
          const formData = new FormData();
          formData.append('file', selectedAmendementFile[i], selectedAmendementFile[i].name);
          formData.append('category', 'MerchantOnboarding');
          formData.append('docType', `TrustDeedAmendment`);

          const response = await uploadOnboardingMerchantFile(apiBaseUri, merchantId, formData);
          returnData.push(response.limeFileId);
        }
      }

      return returnData;
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setFileUploading(false);
    }

    return [];
  };

  const submitHandler = async (
    values: UpdateMerchantBusinessRegistrationPayload,
    formObj: { resetForm: () => void },
  ) => {
    try {
      setIsRequestLoading(true);
      setErrorMessage('');

      const { isSaveFinishLater, updateItem, merchantTaxCountry, fileUpload, ...postData } = { ...values };

      const oldFileIds = fileAmendementDetail
        ? fileAmendementDetail.map((obj) => {
            return obj.fileId;
          })
        : [];

      postData.verificationDocs.trustDeedAmendmentFileIds = oldFileIds.length > 0 ? oldFileIds : null;

      if (
        selectedAmendementFile &&
        selectedAmendementFile.length > 0 &&
        values.businessType === 'trust' &&
        merchantTaxCountry === 'AU'
      ) {
        const fileIds = await fileAmendementUploadHandler();

        postData.verificationDocs.trustDeedAmendmentFileIds = postData.verificationDocs.trustDeedAmendmentFileIds
          ? [...oldFileIds, ...fileIds]
          : fileIds;
      }

      if (selectedFile && (values.businessType === 'partnership' || values.businessType === 'trust')) {
        const fileId = await fileUploadHandler(values.businessType);

        if (!fileId) {
          return;
        }

        if (postData.businessType === 'partnership') {
          postData.verificationDocs.partnershipAgreementFileId = fileId;
        } else if (postData.businessType === 'trust') {
          postData.verificationDocs.trustDeedFileId = fileId;
        }
      }

      if (postData.businessType !== initialValues.businessType) {
        if (values.businessType !== 'partnership') {
          postData.verificationDocs.partnershipAgreementFileId = null;
        }

        if (values.businessType !== 'trust') {
          postData.verificationDocs.trustDeedFileId = null;
          postData.verificationDocs.trustDeedAmendmentFileIds = null;
        }
      }

      await updateMerchantBusinessRegistration(apiBaseUri, merchantId, postData);

      formObj.resetForm();

      let redirectUrl = isSaveFinishLater
        ? `${getMerchantBaseUrl(merchantName)}/${AuthRoutesList.LOGOUT_PAGE}`
        : `${getMerchantBaseUrl(merchantName)}/onboarding/${OnboardingRoutesList.ONBOARDING_STEP02_PAGE}`;

      if (hasCompletedSteps) {
        redirectUrl = `${getMerchantBaseUrl(merchantName)}/onboarding/${OnboardingRoutesList.ONBOARDING_STEP06_PAGE}`;
      }

      navigate(redirectUrl);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setIsRequestLoading(false);
    }
  };

  const fetchFileDetailHandler = useCallback(
    async (fileIds: Array<string>, trustDeedAmendRequest: boolean, trustDeedRequest?: boolean) => {
      try {
        let data = [];
        setFetchFileDetailLoader(true);
        for (let i = 0; i < fileIds.length; i++) {
          const response = await getMerchantFile(apiBaseUri, merchantId, fileIds[i]);
          data.push(response);
        }

        trustDeedAmendRequest
          ? setFileAmendementDetail(data)
          : trustDeedRequest
          ? setTrustFileDetail(data[0])
          : setPartnershipFileDetail(data[0]);
      } catch (error) {
        setRequiredErrorMessage(getErrorMessage(error));
      } finally {
        setFetchFileDetailLoader(false);
      }
    },
    [apiBaseUri, merchantId],
  );

  useEffect(() => {
    if (formRef && formRef.current && saveAndFinishLater) {
      formRef.current.setFieldValue('isSaveFinishLater', true);
      setTimeout(() => {
        if (formRef && formRef.current) {
          formRef.current.submitForm();
        }
      }, 500);
    }
  }, [saveAndFinishLater]);

  useEffect(() => {
    const fetchBusinessDetails = async () => {
      try {
        setFetchDetailLoader(true);

        const response = await getMerchantBusinessRegistration(apiBaseUri, merchantId);
        let updateItem = false;

        if (response.businessType === 'partnership' && response.verificationDocs.partnershipAgreementFileId) {
          updateItem = true;
          await fetchFileDetailHandler([response.verificationDocs.partnershipAgreementFileId], false, false);
        }
        if (response.businessType === 'trust' && response.verificationDocs.trustDeedFileId) {
          updateItem = true;
          await fetchFileDetailHandler([response.verificationDocs.trustDeedFileId], false, true);

          if (
            response.verificationDocs.trustDeedAmendmentFileIds &&
            response.verificationDocs.trustDeedAmendmentFileIds.length > 0
          ) {
            await fetchFileDetailHandler(response.verificationDocs.trustDeedAmendmentFileIds, true, false);
          }
        }

        setInitialValues({ isSaveFinishLater: false, merchantTaxCountry, fileUpload: null, ...response, updateItem });
      } catch (error) {
        setRequiredErrorMessage(getErrorMessage(error));
      } finally {
        setFetchDetailLoader(false);
      }
    };

    const fetchSupportIndustryOptions = async () => {
      try {
        setFetchSupportIndustryLoader(true);

        const response = await getSupportedIndustry(apiBaseUri, merchantId);
        const supportIndustryArray = response.map((obj) => {
          return {
            text: obj.description,
            value: obj.name,
          };
        });

        setSupportIndustryList(supportIndustryArray);
      } catch (error) {
        setRequiredErrorMessage(getErrorMessage(error));
      } finally {
        setFetchSupportIndustryLoader(false);
      }
    };

    setRequiredErrorMessage('');
    fetchSupportIndustryOptions();
    fetchBusinessDetails();
  }, [apiBaseUri, merchantName, merchantId, fetchFileDetailHandler, merchantTaxCountry]);

  useEffect(() => {
    if (!fetchDetailLoader && !fetchSupportIndustryLoader && !fetchFileDetailLoader) {
      setIsPageLoading(false);
      dispatch(setCurrentActiveStep(2));
    }
  }, [setIsPageLoading, fetchDetailLoader, fetchSupportIndustryLoader, fetchFileDetailLoader, dispatch]);

  return (
    <MerchantLayout hideSideBar={false} activeStepNumber={1} title="Onboarding - Business Registration Details">
      {!isPageLoading && requiredErrorMessage.length ? <ErrorComponent bodyText={requiredErrorMessage} /> : null}

      {isPageLoading && (
        <div className="spinner-wrapper">
          <Spinner variant="simple" isVisible label="Loading..." />
        </div>
      )}

      {!isPageLoading && !requiredErrorMessage.length && (
        <Step01View
          onSubmitClick={submitHandler}
          onPreviousClick={() => {
            navigate(`${getMerchantBaseUrl(merchantName)}/onboarding/${OnboardingRoutesList.ONBOARDING_PAGE}`);
          }}
          initialValues={initialValues}
          requestLoader={isRequestLoading}
          errorMsg={errorMessage}
          supportIndustryList={supportIndustryList}
          formRef={formRef}
          fileUploading={fileUploading}
          trustFileDetail={trustFileDetail}
          partnershipFileDetail={partnershipFileDetail}
          selectedFile={selectedFile}
          fileChangeHandler={fileChangeHandler}
          saveAndFinishLater={() => {
            dispatch(setSaveAndFinishLater(Date.now()));
          }}
          saveAndReview={hasCompletedSteps}
          fileAmendementDetail={fileAmendementDetail}
          selectedAmendementFile={selectedAmendementFile}
          amendementFileChangeHandler={amendementFileChangeHandler}
          amendementFileRemoveHandler={amendementFileRemoveHandler}
          amendementUploadedFileRemoveHandler={amendementUploadedFileRemoveHandler}
          agreementRemoveChangeHandler={agreementRemoveChangeHandler}
        />
      )}
    </MerchantLayout>
  );
}

export default OnboardingStep01Page;
