import * as s from 'assets/styles/Onboarding.styles';

import { Button, Heading, Illustration, Message, Modal, Text } from '@limepayments/cosmic';

interface Props {
  modalToggler: (cancelClick?: boolean) => void;
  removeOwnerHandler: () => void;
  isOpen: boolean;
  removeOwnerLoader: boolean;
  errorMessage: string;
}

function RemoveAllModal({ isOpen, modalToggler, removeOwnerHandler, removeOwnerLoader, errorMessage }: Props) {
  return (
    <Modal isOpen={isOpen} className="modal-small hAuto">
      <s.Wrapper>
        <div className="modal-main">
          <div className="modal-header">
            <div className="content-main">
              <Heading alignment="center" tagName="h2" variant="xxs" className="mb-24 lp-justify-center lp-flex">
                Are you sure?
              </Heading>
              <s.Separator>
                <hr />
              </s.Separator>
            </div>
          </div>
          <div className="modal-content mb-0">
            <div className="lp-w-full mb-24">
              <div className="lp-flex lp-align-center lp-justify-center mb-8">
                <Illustration name="Notifications" size="medium" />
              </div>
              <Text alignment="center" tagName="p" variant="body-3">
                By continuing you will be{' '}
                <strong>removing all the details you've entered for the business owners or directors</strong>.
              </Text>

              {errorMessage && (
                <div className="mt-24 mb-24">
                  <Message
                    type="inline"
                    children={<span className="text-wrap">{errorMessage}</span>}
                    variant={'error'}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <s.MultiColumnRespBlock className="lp-w-full lp-justify-end">
              <Button
                onClick={() => modalToggler(true)}
                size="medium"
                variant="ghost"
                className="no-min-width"
                disabled={removeOwnerLoader}
              >
                Cancel
              </Button>
              <Button
                onClick={() => removeOwnerHandler()}
                size="medium"
                variant="destructive"
                className="no-min-width"
                disabled={removeOwnerLoader}
                isLoading={removeOwnerLoader}
              >
                Remove
              </Button>
            </s.MultiColumnRespBlock>
          </div>
        </div>
      </s.Wrapper>
    </Modal>
  );
}

export default RemoveAllModal;
