import { resetPassword, validateToken } from 'api/auth/merchant-identity';
import RoutesList from 'config/constants';
import AuthLayout from 'layouts/auth/AuthLayout';
import ResetPasswordConfirmView from 'pages/auth/reset-password/ResetPasswordConfirmView';
import ResetPasswordInvalidTokenView from 'pages/auth/reset-password/ResetPasswordInvalidTokenView';
import ResetPasswordLinkExpiredView from 'pages/auth/reset-password/ResetPasswordLinkExpiredView';
import ResetPasswordView from 'pages/auth/reset-password/ResetPasswordView';
import ErrorPage from 'pages/common/error';
import qs from 'query-string';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/merchant/hooks';
import { getMerchantBaseUrl } from 'utils/getBaseUrl';

import { Spinner } from '@limepayments/cosmic';

import AuthRoutesList from '../constants';

type FormikFormValues = {
  newPassword: string;
  confirmNewPassword: string;
};

function CreateNewPasswordPage() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [tokenErrorMessage, setTokenErrorMessage] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showResetPasswordConfirmSection, setShowResetPasswordConfirmSection] = useState<boolean>(false);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [expiredToken, setExpiredToken] = useState<boolean>(false);
  const [invalidToken, setInvalidToken] = useState<boolean>(false);
  const [isValidateTokenLoading, setIsValidateTokenLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<FormikFormValues>({
    newPassword: '',
    confirmNewPassword: '',
  });

  const { apiBaseUri, merchantId, merchantName } = useAppSelector((state) => ({
    apiBaseUri: state.config.apiBaseUri,
    merchantId: state.config.merchantId,
    merchantName: state.config.merchantName,
  }));

  const { ref } = qs.parse(window.location.search);

  useEffect(() => {
    if (merchantId) {
      setIsPageLoading(false);
    }
  }, [setIsPageLoading, merchantId]);

  useEffect(() => {
    if (!ref || !apiBaseUri || !merchantName) {
      setTokenErrorMessage('Not all required parameters are set');
      return;
    }

    setTokenErrorMessage('');
    setIsValidateTokenLoading(true);
    validateToken(apiBaseUri, ref as string)
      .then(({ isExpired, emailAddress }) => {
        setErrorMessage('');
        setEmail(emailAddress);

        if (isExpired) {
          setExpiredToken(true);
        }
      })
      .catch((e) => {
        setInvalidToken(true);
      })
      .finally(() => {
        setIsValidateTokenLoading(false);
      });
  }, [apiBaseUri, merchantName, ref]);

  const submitHandler = async (values: FormikFormValues, formObj: { resetForm: () => void }) => {
    try {
      setErrorMessage('');
      setIsLoading(true);

      await resetPassword(apiBaseUri, ref as string, { password: values.newPassword });

      setInitialValues(initialValues);
      formObj.resetForm();

      setShowResetPasswordConfirmSection(true);
    } catch (err: any) {
      setErrorMessage(err.message || 'Failed to reset password');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      {!isPageLoading && !isValidateTokenLoading && (
        <Fragment>{tokenErrorMessage.length > 0 && <ErrorPage bodyText={tokenErrorMessage} />}</Fragment>
      )}

      {(isPageLoading || isValidateTokenLoading) && (
        <div className="spinner-wrapper">
          <Spinner variant="simple" isVisible />
        </div>
      )}

      {(isPageLoading || isValidateTokenLoading || !tokenErrorMessage.length) && (
        <AuthLayout title="Create new password">
          <Fragment>
            {invalidToken && (
              <ResetPasswordInvalidTokenView
                onSubmitClick={() => {
                  navigate(
                    `${getMerchantBaseUrl(merchantName)}/${RoutesList.AUTH_ROUTE}/${
                      AuthRoutesList.FORGOT_PASSWORD_PAGE
                    }`,
                  );
                }}
              />
            )}

            {expiredToken && (
              <ResetPasswordLinkExpiredView
                onSubmitClick={() => {
                  navigate(
                    `${getMerchantBaseUrl(merchantName)}/${RoutesList.AUTH_ROUTE}/${
                      AuthRoutesList.FORGOT_PASSWORD_PAGE
                    }`,
                  );
                }}
                email={email}
              />
            )}

            {!isPageLoading && !isValidateTokenLoading && !tokenErrorMessage.length && !invalidToken && !expiredToken && (
              <Fragment>
                {!showResetPasswordConfirmSection && (
                  <ResetPasswordView
                    onSubmitClick={submitHandler}
                    initialValues={initialValues}
                    requestLoader={isLoading}
                    errorMsg={errorMessage}
                  />
                )}

                {showResetPasswordConfirmSection && (
                  <ResetPasswordConfirmView
                    onSubmitClick={() =>
                      navigate(
                        `${getMerchantBaseUrl(merchantName)}/${RoutesList.AUTH_ROUTE}/${AuthRoutesList.LOGIN_PAGE}`,
                      )
                    }
                  />
                )}
              </Fragment>
            )}
          </Fragment>
        </AuthLayout>
      )}
    </Fragment>
  );
}

export default CreateNewPasswordPage;
