import * as s from 'assets/styles/Platforms.styles';
import { Fragment, useEffect, useState } from 'react';

import { Button, Heading, Icon, Modal } from '@limepayments/cosmic';

import AddNewItemSection from './order-partials/AddNewItemSection';
import ConfirmOrderSection from './order-partials/ConfirmOrderSection';
import ItemBreakdownAddNewItemsSection from './order-partials/ItemBreakdownAddNewItemsSection';
import ItemBreakdownSection from './order-partials/ItemBreakdownSection';
import LandingSection from './order-partials/LandingSection';
import OrderDetailSection from './order-partials/OrderDetailSection';
import PaymentRequestSection from './order-partials/PaymentRequestSection';

interface Props {
  isOpen: boolean;
  modalToggler: (isOpen: boolean) => void;
}

function CreateNewOrderModal({ isOpen, modalToggler }: Props) {
  const [activeStep, setActiveStep] = useState<number>(1);

  const getModalTitle = () => {
    let title = `Build your order`;

    if (activeStep === 5) {
      title = `Order details`;
    }

    if (activeStep === 6) {
      title = `Confirm order #1234`;
    }

    if (activeStep === 7) {
      title = `Payment request has been created`;
    }

    return title;
  };

  useEffect(() => {
    if (!isOpen) {
      setActiveStep(1);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} className="modal-medium update-pass-modal">
      <s.Wrapper className="">
        <div className="modal-main p-24">
          <div className="modal-header">
            <div className="content-main bb-1">
              <Heading alignment="center" tagName="h2" variant="xs" className="mt-12 mb-24 lp-justify-center lp-flex">
                {getModalTitle()}
              </Heading>
            </div>
          </div>
          <div className="modal-body h90vh p-28 sm-p-0 sm-mt-24">
            {activeStep === 1 && <LandingSection />}
            {activeStep === 2 && <AddNewItemSection />}
            {activeStep === 3 && <ItemBreakdownSection />}
            {activeStep === 4 && <ItemBreakdownAddNewItemsSection />}
            {activeStep === 5 && <OrderDetailSection />}
            {activeStep === 6 && <ConfirmOrderSection />}
            {activeStep === 7 && <PaymentRequestSection />}
          </div>
          <div className="modal-footer lp-flex lp-justify-end sm-mt-24">
            {activeStep < 6 && (
              <Fragment>
                <Button onClick={() => modalToggler(false)} size="medium" variant="ghost" className="no-min-width w-70">
                  Cancel
                </Button>
                <Button
                  type="button"
                  size="medium"
                  variant="primary"
                  className="no-min-width"
                  onClick={() => setActiveStep(activeStep + 1)}
                >
                  Next
                </Button>
              </Fragment>
            )}

            {activeStep === 6 && (
              <div className="lp-flex lp-align-center sm:flex-col sm:justify-end sm:align-end">
                <div className="lp-flex lp-align-center sm:mb-12">
                  <Button
                    onClick={() => modalToggler(false)}
                    size="medium"
                    variant="ghost"
                    className="no-min-width w-70"
                  >
                    Edit
                  </Button>
                  <Button
                    type="button"
                    size="medium"
                    variant="primary"
                    className="no-min-width"
                    onClick={() => setActiveStep(activeStep + 1)}
                  >
                    <Icon name="Filled-Email" className="ml-0 mr-12" />
                    Send to customer
                  </Button>
                </div>
                <Button
                  type="button"
                  size="medium"
                  variant="primary"
                  className="no-min-width"
                  onClick={() => setActiveStep(activeStep + 1)}
                >
                  <Icon name="Filled-CC" className="ml-0 mr-12" />
                  Process now
                </Button>
              </div>
            )}

            {activeStep === 7 && (
              <Fragment>
                <Button
                  type="button"
                  size="medium"
                  className="no-min-width"
                  variant="ghost"
                  onClick={() => modalToggler(false)}
                >
                  <Icon name="Filled-CC" className="ml-0 mr-12" />
                  Process now
                </Button>
                <Button onClick={() => modalToggler(false)} size="medium" variant="primary" className="no-min-width">
                  <Icon name="Cross" className="ml-0 mr-12" />
                  Close
                </Button>
              </Fragment>
            )}
          </div>
        </div>
      </s.Wrapper>
    </Modal>
  );
}

export default CreateNewOrderModal;
