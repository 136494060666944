import { getMerchantFile } from 'api/merchant/onbording/file';
import { GetMerchantFileResponse } from 'api/merchant/onbording/file.types';
import {
  deleteMerchantCompanyOwner,
  deleteMerchantPartnershipOwner,
  deleteMerchantPerson,
  deleteMerchantTrustOwner,
  getMerchantCompanyOwner,
  getMerchantPartnershipOwner,
  getMerchantPerson,
  getMerchantTrustOwner,
} from 'api/merchant/onbording/step-05';
import {
  GetListMerchantCompanyOwnersObj,
  GetListMerchantPartnershipOwnersObj,
  GetListMerchantPersonObj,
  GetListMerchantTrustOwnersObj,
} from 'api/merchant/onbording/step-05.types';
import * as s from 'assets/styles/Onboarding.styles';
import { Fragment, useCallback, useEffect, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { useAppSelector } from 'redux/merchant/hooks';
import getErrorMessage from 'utils/getErrorMessage';

import { Announcement, Button, Heading, Icon, LabelledValue, Message, RadioGroup, Text } from '@limepayments/cosmic';

import {
  CreateMerchantCompanyOwnerPayload,
  CreateMerchantPartnershipOwnerPayload,
  CreateMerchantPersonPayload,
  CreateMerchantTrustOwnerPayload,
} from '../../types';
import ActionComponent from './partials/ActionComponent';
import AddOwnerModal from './partials/AddOwnerModal';
import RemoveAllModal from './partials/RemoveAllModal';
import RemoveModal from './partials/RemoveModal';

interface Props {
  onSubmitClick: () => void;
  onPreviousClick: () => void;
  setHasOwner: (val: string) => void;
  hasOwner: string;
  merchantPersonList: Array<GetListMerchantPersonObj>;
  merchantCompanyOwnersList: Array<GetListMerchantCompanyOwnersObj>;
  merchantPartnershipOwnersList: Array<GetListMerchantPartnershipOwnersObj>;
  merchantTrustOwnersList: Array<GetListMerchantTrustOwnersObj>;
  refreshData: () => void;
  requiredErrorMessage: string;
  filterMerchantList: (id: string, section: string, removeAll?: boolean) => void;
  isMounted: boolean;
  saveAndFinishLater: () => void;
}

function Step05View({
  onSubmitClick,
  onPreviousClick,
  setHasOwner,
  hasOwner,
  merchantPersonList,
  merchantCompanyOwnersList,
  merchantPartnershipOwnersList,
  merchantTrustOwnersList,
  refreshData,
  requiredErrorMessage,
  filterMerchantList,
  saveAndFinishLater,
}: Props) {
  const [selectedOwnerDeleteId, setSelectedOwnerDeleteId] = useState<string>('');
  const [selectedOwnerEditId, setSelectedOwnerEditId] = useState<string>('');
  const [selectedOwnerSection, setSelectedOwnerSection] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [merchantPersonEditObj, setMerchantPersonEditObj] = useState<CreateMerchantPersonPayload | null>(null);
  const [merchantTrustEditObj, setMerchantTrustEditObj] = useState<CreateMerchantTrustOwnerPayload | null>(null);
  const [merchantPartnershipEditObj, setMerchantPartnershipEditObj] =
    useState<CreateMerchantPartnershipOwnerPayload | null>(null);
  const [merchantCompanyEditObj, setMerchantCompanyEditObj] = useState<CreateMerchantCompanyOwnerPayload | null>(null);
  const [fetchEditOwnerDetailLoader, setFetchEditOwnerDetailLoader] = useState<boolean>(false);

  const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false);
  const [removeOwnerLoader, setRemoveOwnerLoader] = useState<boolean>(false);
  const [removeAllModalOpen, setRemoveAllModalOpen] = useState<boolean>(false);
  const [removeAllOwnerLoader, setRemoveAllOwnerLoader] = useState<boolean>(false);
  const [modalAddOwnerOpen, setmodalAddOwnerOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>('owner');
  const [ownerName, setOwnerName] = useState<string>('');
  const [menuClickedSection, setMenuClickedSection] = useState<string>('');
  const [activeMenuItemId, setActiveMenuItemId] = useState<string>('');
  const [fileFrontDetail, setFileFrontDetail] = useState<GetMerchantFileResponse | null>(null);
  const [fileBackDetail, setFileBackDetail] = useState<GetMerchantFileResponse | null>(null);
  const [fetchFileFrontLoader, setFetchFileFrontLoader] = useState<boolean>(false);
  const [fetchFileBackLoader, setFetchFileBackLoader] = useState<boolean>(false);

  const { merchantId, apiBaseUri, hasCompletedSteps } = useAppSelector((state) => ({
    merchantId: state.config.merchantId,
    apiBaseUri: state.config.apiBaseUri,
    hasCompletedSteps: state.onboarding.hasCompletedSteps,
  }));

  const fileRemoveHandler = () => {
    setFileFrontDetail(null);
  };

  const fetchFileDetailHandler = useCallback(
    async (fileId: string, type: string) => {
      try {
        type === 'front' ? setFetchFileFrontLoader(true) : setFetchFileBackLoader(true);
        const response = await getMerchantFile(apiBaseUri, merchantId, fileId);

        type === 'front' ? setFileFrontDetail(response) : setFileBackDetail(response);
      } catch (error) {
        setErrorMessage(getErrorMessage(error));
      } finally {
        type === 'front' ? setFetchFileFrontLoader(false) : setFetchFileBackLoader(false);
      }
    },
    [apiBaseUri, merchantId],
  );

  const addModalToggler = useCallback(
    (val: boolean) => {
      setmodalAddOwnerOpen(val);
      if (val === false && selectedOwnerEditId) {
        setMerchantPersonEditObj(null);
        setMerchantTrustEditObj(null);
        setMerchantPartnershipEditObj(null);
        setMerchantCompanyEditObj(null);
        setFileFrontDetail(null);
        setFileBackDetail(null);
        setSelectedOwnerEditId('');
      }
    },
    [selectedOwnerEditId],
  );

  const removeAllOwnerHandler = async () => {
    try {
      setRemoveAllOwnerLoader(true);
      setErrorMessage('');
      let deletePromiseResponse = [];

      // Individual delete list
      for (let i = 0; i < merchantPersonList.length; i++) {
        deletePromiseResponse.push(deleteMerchantPerson(apiBaseUri, merchantId, merchantPersonList[i].personId));
      }

      // Trust delete list
      for (let i = 0; i < merchantTrustOwnersList.length; i++) {
        deletePromiseResponse.push(
          deleteMerchantTrustOwner(apiBaseUri, merchantId, merchantTrustOwnersList[i].trustId),
        );
      }

      // Partnership delete list
      for (let i = 0; i < merchantPartnershipOwnersList.length; i++) {
        deletePromiseResponse.push(
          await deleteMerchantPartnershipOwner(apiBaseUri, merchantId, merchantPartnershipOwnersList[i].partnershipId),
        );
      }

      // Company delete list
      for (let i = 0; i < merchantCompanyOwnersList.length; i++) {
        deletePromiseResponse.push(
          deleteMerchantCompanyOwner(apiBaseUri, merchantId, merchantCompanyOwnersList[i].companyId),
        );
      }

      await Promise.allSettled(deletePromiseResponse);

      filterMerchantList(selectedOwnerDeleteId, selectedOwnerSection, true);

      modalRemoveAllHandler();
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setRemoveAllOwnerLoader(false);
    }
  };

  const removeOwnerHandler = async () => {
    try {
      setRemoveOwnerLoader(true);
      setErrorMessage('');

      switch (selectedOwnerSection) {
        case 'individual':
          await deleteMerchantPerson(apiBaseUri, merchantId, selectedOwnerDeleteId);
          break;
        case 'trust':
          await deleteMerchantTrustOwner(apiBaseUri, merchantId, selectedOwnerDeleteId);
          break;
        case 'partnership':
          await deleteMerchantPartnershipOwner(apiBaseUri, merchantId, selectedOwnerDeleteId);
          break;
        case 'company':
        default:
          await deleteMerchantCompanyOwner(apiBaseUri, merchantId, selectedOwnerDeleteId);
          break;
      }

      filterMerchantList(selectedOwnerDeleteId, selectedOwnerSection);

      setSelectedOwnerSection('');
      setSelectedOwnerDeleteId('');
      modalRemoveHandler('', '', '');
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setRemoveOwnerLoader(false);
    }
  };

  const toggleMenu = (section: string, id: string) => {
    setActiveMenuItemId(id);
    setMenuClickedSection(section);
  };

  const modalRemoveHandler = (id: string, section: string, name: string) => {
    setSelectedOwnerDeleteId(id);
    setSelectedOwnerSection(section);
    setOwnerName(name);
    setRemoveModalOpen(!removeModalOpen);
  };

  const modalRemoveAllHandler = (cancelClicked?: boolean) => {
    setRemoveAllModalOpen(!removeAllModalOpen);

    if (cancelClicked) {
      setHasOwner(hasOwner === 'no' ? 'yes' : 'no');
    }
  };

  const modalEditHandler = (id: string, section: string) => {
    setSelectedOwnerEditId(id);
    setSelectedOwnerSection(section);
  };

  const getFormattedDate = (value: string) => {
    const dateValueArray = value.split('-');

    return `${dateValueArray[2]}/${dateValueArray[1]}/${dateValueArray[0]}`;
  };

  const editOwnerHandler = useCallback(async () => {
    try {
      setFetchEditOwnerDetailLoader(true);
      setErrorMessage('');

      let frontFileId = '';
      let backFileId = '';

      switch (selectedOwnerSection) {
        case 'individual':
          const merchantPersonResponse = await getMerchantPerson(apiBaseUri, merchantId, selectedOwnerEditId);
          const merchantResponseEditObj = {
            title: merchantPersonResponse.profile.title,
            roles: merchantPersonResponse.profile.roles,
            firstName: merchantPersonResponse.profile.firstName,
            middleName: merchantPersonResponse.profile.middleName,
            lastName: merchantPersonResponse.profile.lastName,
            dob: getFormattedDate(merchantPersonResponse.profile.dob),
            address: merchantPersonResponse.profile.address,
            email: merchantPersonResponse.profile.email,
            phone: merchantPersonResponse.profile.phone,
            passport: {
              country: merchantPersonResponse.profile.passport ? merchantPersonResponse.profile.passport.country : '',
              passportNumber: merchantPersonResponse.profile.passport
                ? merchantPersonResponse.profile.passport.passportNumber
                : '',
              expiryDate: merchantPersonResponse.profile.passport
                ? getFormattedDate(merchantPersonResponse.profile.passport.expiryDate)
                : '',
            },
            driverLicence: merchantPersonResponse.profile.driverLicence
              ? merchantPersonResponse.profile.driverLicence
              : {
                  country: '',
                  driverLicenceNumber: '',
                  documentNumber: '',
                  versionNumber: '',
                  state: '',
                },
            verificationDocs: merchantPersonResponse.profile.verificationDocs,
            businessAddress: '',
            isManualEnterAddress: true,
            selectedFileFront: null,
            selectedFileBack: null,
            updateItem: true,
          };

          frontFileId = merchantPersonResponse.profile.verificationDocs.docFrontFileId;
          backFileId = merchantPersonResponse.profile.verificationDocs.docBackFileId;
          setMerchantPersonEditObj(merchantResponseEditObj);
          break;
        case 'trust':
          const merchantTrustOwnerResponse = await getMerchantTrustOwner(apiBaseUri, merchantId, selectedOwnerEditId);
          const merchantResponseTrustEditObj = {
            businessName: merchantTrustOwnerResponse.businessName,
            trustDeedDocs: merchantTrustOwnerResponse.trustDeedDocs,
            trustDeedAmendmentDocs: merchantTrustOwnerResponse.trustDeedAmendmentDocs,
            selectedFile: null,
            updateItem: true,
          };

          frontFileId = merchantTrustOwnerResponse.trustDeedDocs[merchantTrustOwnerResponse.trustDeedDocs.length - 1];
          setMerchantTrustEditObj(merchantResponseTrustEditObj);
          break;
        case 'partnership':
          const merchantPartnershipOwnerResponse = await getMerchantPartnershipOwner(
            apiBaseUri,
            merchantId,
            selectedOwnerEditId,
          );
          const merchantResponsePartnershipEditObj = {
            businessName: merchantPartnershipOwnerResponse.businessName,
            partnershipAgreementDocs: merchantPartnershipOwnerResponse.partnershipAgreementDocs,
            selectedFile: null,
            updateItem: true,
          };
          frontFileId =
            merchantPartnershipOwnerResponse.partnershipAgreementDocs[
              merchantPartnershipOwnerResponse.partnershipAgreementDocs.length - 1
            ];
          setMerchantPartnershipEditObj(merchantResponsePartnershipEditObj);
          break;
        case 'company':
        default:
          const merchantComapnyOwnerResponse = await getMerchantCompanyOwner(
            apiBaseUri,
            merchantId,
            selectedOwnerEditId,
          );
          const merchantResponseCompanyEditObj = {
            companyType: merchantComapnyOwnerResponse.companyType,
            taxCountry: merchantComapnyOwnerResponse.taxCountry,
            taxId: merchantComapnyOwnerResponse.taxId,
            businessName: merchantComapnyOwnerResponse.businessName,
            updateItem: true,
          };
          setMerchantCompanyEditObj(merchantResponseCompanyEditObj);
          break;
      }

      if (frontFileId) {
        await fetchFileDetailHandler(frontFileId, 'front');
      }

      if (backFileId) {
        await fetchFileDetailHandler(backFileId, 'back');
      }

      addModalToggler(true);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setFetchEditOwnerDetailLoader(true);
    }
  }, [selectedOwnerEditId, selectedOwnerSection, addModalToggler, apiBaseUri, merchantId, fetchFileDetailHandler]);

  useEffect(() => {
    if (selectedOwnerEditId) {
      editOwnerHandler();
    }
  }, [editOwnerHandler, selectedOwnerEditId]);

  useEffect(() => {
    setModalType(hasOwner === 'yes' ? `owner` : `director`);
  }, [hasOwner]);

  return (
    <>
      <div className="content-wrap-inner">
        <header className="content-header">
          <div className="lp-w-full mb-24">
            <div className="lp-w-full mb-16">
              <Button
                size="small"
                variant="ghost"
                isNeutral
                className="back-button"
                type="button"
                onClick={onPreviousClick}
              >
                <Icon name="ArrowLeft" className="back-arrow" />
                Back
              </Button>
            </div>
            <Heading alignment="left" tagName="h2" variant="md" className="mb-24">
              Verify your business details
            </Heading>
            <Heading alignment="left" tagName="h4" variant="sm" className="mb-24">
              Business owners and directors
            </Heading>
            <s.WeakAnnouncement>
              <Announcement variant="weak" hasDismissal={false}>
                Please make sure all information entered matches your official business registration.
              </Announcement>
            </s.WeakAnnouncement>
          </div>
        </header>

        <div className="content-main">
          <div className="lp-w-full lp-equal-width mb-24">
            <div className="w-260">
              <RadioGroup
                defaultValue={hasOwner}
                data-testid="ownerOrDirectorRadio"
                header="Are there any individuals or entities who own 25% or more of the business directly or indirectly?"
                description="Direct ownership could be through shareholdings and indirect ownership could be through another company."
                name="ownership"
                onChange={(value) => {
                  setHasOwner(value);

                  const totalListCount =
                    merchantCompanyOwnersList.length +
                    merchantPersonList.length +
                    merchantTrustOwnersList.length +
                    merchantPartnershipOwnersList.length;

                  if (hasOwner && totalListCount > 0) {
                    modalRemoveAllHandler();
                  }
                }}
                orientation="vertical"
                radios={[
                  {
                    text: 'Yes',
                    value: 'yes',
                  },
                  {
                    text: 'No',
                    value: 'no',
                  },
                ]}
              />
            </div>
          </div>

          <Fragment>
            {hasOwner && (
              <Fragment>
                <div className="lp-w-full mb-24">
                  <Text alignment="left" variant="body-2" isEmphasised>
                    Business {hasOwner === 'yes' ? `owners'` : `directors'`} details
                  </Text>
                  <Text tagName="span" variant="caption" className="text-greyish lp-ls5">
                    {hasOwner === 'yes'
                      ? `Provide the details of up to four individuals or entities who own 25% or more of the business.`
                      : `Provide the details of all directors of the business. A director is someone who is responsible for directing and/or managing the affairs of the business.`}
                  </Text>
                </div>

                {requiredErrorMessage.length > 0 && (
                  <div className="mt-24 mb-24">
                    <Message
                      type="inline"
                      children={<span className="text-wrap">{requiredErrorMessage}</span>}
                      variant={'error'}
                    />
                  </div>
                )}

                {(merchantPersonList.length > 0 ||
                  merchantCompanyOwnersList.length > 0 ||
                  merchantPartnershipOwnersList.length > 0 ||
                  merchantTrustOwnersList.length > 0) && (
                  <div className="lp-w-full mb-24">
                    <div className="lp-w-full lp-position-relative mb-0">
                      {merchantPersonList.map((merchantPersonObj, key) => (
                        <div key={key} className="lp-autofocus lp-position-relative mb-4">
                          <LabelledValue
                            size="large"
                            label={modalType === 'director' ? merchantPersonObj.profile.title : `Individual`}
                            value={`${merchantPersonObj.profile.firstName} ${merchantPersonObj.profile.lastName}`}
                            variant="vertical"
                          />
                          <div
                            className="lp-field-action"
                            onClick={() => toggleMenu('merchantPersonMenu', merchantPersonObj.personId)}
                          >
                            <Icon name="Ellipse" />
                          </div>

                          {menuClickedSection === 'merchantPersonMenu' &&
                            activeMenuItemId === merchantPersonObj.personId && (
                              <ClickAwayListener onClickAway={() => toggleMenu('', '')}>
                                <div className="action-dropdown">
                                  <ActionComponent
                                    id={merchantPersonObj.personId}
                                    section="individual"
                                    onEditActionClick={modalEditHandler}
                                    onRemoveActionClick={modalRemoveHandler}
                                    editFetchLoader={
                                      (fetchEditOwnerDetailLoader &&
                                        selectedOwnerEditId === merchantPersonObj.personId) ||
                                      fetchFileBackLoader ||
                                      fetchFileFrontLoader
                                    }
                                    allowDelete={merchantPersonObj.isDeletable}
                                    name={`${merchantPersonObj.profile.firstName} ${merchantPersonObj.profile.lastName}`}
                                  />
                                </div>
                              </ClickAwayListener>
                            )}
                        </div>
                      ))}

                      {merchantTrustOwnersList.map((merchantTrustObj, key) => (
                        <div key={key} className="lp-autofocus lp-position-relative mb-4">
                          <LabelledValue
                            size="large"
                            label="Trust"
                            value={`${merchantTrustObj.profile.businessName}`}
                            variant="vertical"
                          />
                          <div
                            className="lp-field-action"
                            onClick={() => toggleMenu('merchantTrustOwnersMenu', merchantTrustObj.trustId)}
                          >
                            <Icon name="Ellipse" />
                          </div>

                          {menuClickedSection === 'merchantTrustOwnersMenu' &&
                            activeMenuItemId === merchantTrustObj.trustId && (
                              <ClickAwayListener onClickAway={() => toggleMenu('', '')}>
                                <div className="action-dropdown">
                                  <ActionComponent
                                    id={merchantTrustObj.trustId}
                                    section="trust"
                                    onEditActionClick={modalEditHandler}
                                    onRemoveActionClick={modalRemoveHandler}
                                    editFetchLoader={
                                      (fetchEditOwnerDetailLoader &&
                                        selectedOwnerEditId === merchantTrustObj.trustId) ||
                                      fetchFileBackLoader ||
                                      fetchFileFrontLoader
                                    }
                                    allowDelete={true}
                                    name={`${merchantTrustObj.profile.businessName}`}
                                  />
                                </div>
                              </ClickAwayListener>
                            )}
                        </div>
                      ))}

                      {merchantPartnershipOwnersList.map((merchantPartnerObj, key) => (
                        <div key={key} className="lp-autofocus lp-position-relative mb-4">
                          <LabelledValue
                            size="large"
                            label="Partnership"
                            value={`${merchantPartnerObj.profile.businessName}`}
                            variant="vertical"
                          />
                          <div
                            className="lp-field-action"
                            onClick={() =>
                              toggleMenu('merchantPartnershipOwnersMenu', merchantPartnerObj.partnershipId)
                            }
                          >
                            <Icon name="Ellipse" />
                          </div>

                          {menuClickedSection === 'merchantPartnershipOwnersMenu' &&
                            activeMenuItemId === merchantPartnerObj.partnershipId && (
                              <ClickAwayListener onClickAway={() => toggleMenu('', '')}>
                                <div className="action-dropdown">
                                  <ActionComponent
                                    id={merchantPartnerObj.partnershipId}
                                    section="partnership"
                                    onEditActionClick={modalEditHandler}
                                    onRemoveActionClick={modalRemoveHandler}
                                    editFetchLoader={
                                      (fetchEditOwnerDetailLoader &&
                                        selectedOwnerEditId === merchantPartnerObj.partnershipId) ||
                                      fetchFileBackLoader ||
                                      fetchFileFrontLoader
                                    }
                                    allowDelete={true}
                                    name={`${merchantPartnerObj.profile.businessName}`}
                                  />
                                </div>
                              </ClickAwayListener>
                            )}
                        </div>
                      ))}

                      {merchantCompanyOwnersList.map((merchantPersonObj, key) => (
                        <div key={key} className="lp-autofocus lp-position-relative last-child mb-0">
                          <LabelledValue
                            size="large"
                            label="Company"
                            value={`${merchantPersonObj.profile.businessName}`}
                            variant="vertical"
                          />
                          <div
                            className="lp-field-action"
                            onClick={() => toggleMenu('merchantCompanyOwnersMenu', merchantPersonObj.companyId)}
                          >
                            <Icon name="Ellipse" />
                          </div>

                          {menuClickedSection === 'merchantCompanyOwnersMenu' &&
                            activeMenuItemId === merchantPersonObj.companyId && (
                              <ClickAwayListener onClickAway={() => toggleMenu('', '')}>
                                <div className="action-dropdown">
                                  <ActionComponent
                                    id={merchantPersonObj.companyId}
                                    section="company"
                                    onEditActionClick={modalEditHandler}
                                    onRemoveActionClick={modalRemoveHandler}
                                    editFetchLoader={
                                      (fetchEditOwnerDetailLoader &&
                                        selectedOwnerEditId === merchantPersonObj.companyId) ||
                                      fetchFileBackLoader ||
                                      fetchFileFrontLoader
                                    }
                                    allowDelete={true}
                                    name={`${merchantPersonObj.profile.businessName}`}
                                  />
                                </div>
                              </ClickAwayListener>
                            )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                <div className="lp-w-full mb-24">
                  <Button
                    onClick={() => addModalToggler(!modalAddOwnerOpen)}
                    size="medium"
                    variant="reversed"
                    className="no-min-width"
                    testId="addOwnerDirector"
                    disabled={
                      modalType !== 'director' &&
                      merchantPersonList.length +
                        merchantCompanyOwnersList.length +
                        merchantPartnershipOwnersList.length +
                        merchantTrustOwnersList.length >
                        3
                    }
                  >
                    <Icon name="Plus" className="ml-0 mr-8" />
                    {hasOwner === 'yes' ? `Add owner` : `Add director`}
                  </Button>
                </div>
              </Fragment>
            )}
          </Fragment>
        </div>

        <s.Cta className="lp-flex lp-justify-end lp-items-center sm:flex-col">
          <Button
            onClick={onSubmitClick}
            size="medium"
            variant="primary"
            className="no-min-width lp-full"
            disabled={
              merchantPersonList.length > 0 ||
              merchantCompanyOwnersList.length > 0 ||
              merchantPartnershipOwnersList.length > 0 ||
              merchantTrustOwnersList.length > 0
                ? false
                : true
            }
          >
            Save and {hasCompletedSteps ? `review` : `continue`}
            <Icon name="ArrowRight" />
          </Button>

          <div className="mobile-sidebar-footer">
            <Button size="medium" onClick={saveAndFinishLater} variant="ghost" className="lp-w-full">
              <Icon name="Save" />
              Save and finish later
            </Button>
          </div>
        </s.Cta>
      </div>

      {/* Remove All Owner Modal */}
      <RemoveAllModal
        isOpen={removeAllModalOpen}
        modalToggler={modalRemoveAllHandler}
        removeOwnerHandler={removeAllOwnerHandler}
        removeOwnerLoader={removeAllOwnerLoader}
        errorMessage={errorMessage}
      />

      {/* Remove Owner Modal */}
      <RemoveModal
        isOpen={removeModalOpen}
        modalToggler={modalRemoveHandler}
        removeOwnerHandler={removeOwnerHandler}
        removeOwnerLoader={removeOwnerLoader}
        errorMessage={errorMessage}
        ownerName={ownerName}
      />

      {/* Add Owner Modal */}
      <AddOwnerModal
        refreshData={refreshData}
        isOpen={modalAddOwnerOpen}
        toggleModal={addModalToggler}
        modalType={modalType}
        selectedOwnerEditId={selectedOwnerEditId}
        merchantPersonEditObj={merchantPersonEditObj}
        merchantTrustEditObj={merchantTrustEditObj}
        merchantPartnershipEditObj={merchantPartnershipEditObj}
        merchantCompanyEditObj={merchantCompanyEditObj}
        fileFrontDetail={fileFrontDetail}
        fileBackDetail={fileBackDetail}
        fileRemoveHandler={fileRemoveHandler}
      />
    </>
  );
}

export default Step05View;
