import { handleResponse } from 'api/utils';
import getFetchOptions from 'utils/getFetchOptions';

import { IApiKeyObj } from './api-keys.types';

export const getMerchantApiKeys = async (host: string, merchantId: string): Promise<Array<IApiKeyObj>> => {
  const options = await getFetchOptions();

  return await fetch(`${host}/dashboard/merchant/${merchantId}/tokens`, options).then(handleResponse);
};
