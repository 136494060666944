import { ApiError, ErrorResponse } from 'api/utils';

export function isApiError(error: unknown): error is ApiError {
  return error instanceof ApiError;
}

const getErrorMessage = (error: any, getApiErrorMessage?: (error: ErrorResponse) => string): string => {
  const isText = (text: any): boolean => typeof text === 'string' && !!text.length;
  if (isText(error)) return error;
  if (isApiError(error)) return getApiErrorMessage?.(error.payload) ?? error.message;
  if (isText(error?.message)) return error.message;
  return 'Unknown error';
};

export default getErrorMessage;
