import { getPlatformMerchantsList } from 'api/platform/merchant';
import { PlatformMerchantObj } from 'api/platform/merchant.types';
import PlatformLayout from 'layouts/platform/PlatformLayout';
import { DateTime } from 'luxon';
import ErrorComponent from 'pages/common/error/ErrorComponent';
import { MAX_FILTERING_DAYS_MERCHANT } from 'pages/platform/constants';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { useAppDispatch, useAppSelector } from 'redux/platform/hooks';
import {
  setListingPagePropsObj,
  setMerchantFilterObj,
  setOpenFilterDropdown,
} from 'redux/platform/slice/merchantSlice';
import getErrorMessage from 'utils/getErrorMessage';

import { Spinner } from '@limepayments/cosmic';

import { MerchantStatusObj } from '../constants';
import { getInitialFilterState } from './initialStates';
import MerchantLists from './partials/MerchantLists';

function MerchantPage() {
  const dispatch = useAppDispatch();

  const [fetchListLoader, setFetchListLoader] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [merchantList, setMerchantList] = useState<Array<PlatformMerchantObj>>([]);
  const [filterPopupOpen, setFilterPopupOpen] = useState<boolean>(false);
  const [exportOpen, setExportOpen] = useState<boolean>(false);
  const { marketplaceId, apiBaseUri, openFilterDropdown, merchantFilterObj, listingPageProps } = useAppSelector(
    (state) => ({
      marketplaceId: state.config.marketplaceId,
      apiBaseUri: state.config.apiBaseUri,
      openFilterDropdown: state.merchant.openFilterDropdown,
      merchantFilterObj: state.merchant.merchantFilterObj,
      listingPageProps: state.merchant.listingPageProps,
    }),
  );
  const [lastFilterSaveTriggerAt, setLastFilterSaveTriggerAt] = useState<string>(merchantFilterObj.saveTiggerAt);
  const [totalRows, setTotalRows] = useState<number>(0);

  // const [lastPagePropsUpdatesAt, setLastPagePropsUpdatesAt] = useState<string>('');

  const apiQueryString = useMemo(() => {
    let queryString = `page=${listingPageProps.activePage}&limit=${listingPageProps.limit}`;

    if (listingPageProps.sortBy) {
      queryString += `&sort=${listingPageProps.sortBy}`;
    }

    if (listingPageProps.activeStatusTab) {
      queryString += `&status=${listingPageProps.activeStatusTab}`;
    }

    if (merchantFilterObj.dateTypeCompareValue) {
      let fromDate = '';
      let toDate = '';

      if (merchantFilterObj.dateTypeCompareValue === 'is_in_the_last') {
        if (merchantFilterObj.dateTypeFilterValue === 'days') {
          fromDate = `${DateTime.utc()
            .minus({ days: parseInt(merchantFilterObj.dateValue) })
            .startOf('day')
            .toISO()}`;
          toDate = `${DateTime.utc().endOf('day').toISO()}`;
        }

        if (merchantFilterObj.dateTypeFilterValue === 'month') {
          fromDate = `${DateTime.utc()
            .minus({ months: parseInt(merchantFilterObj.dateValue) })
            .startOf('day')
            .toISO()}`;
          toDate = `${DateTime.utc().endOf('day').toISO()}`;
        }
      }

      if (merchantFilterObj.dateTypeCompareValue === 'is_equal_to' && merchantFilterObj.dateSingleValue) {
        const fromDateObj = new Date(merchantFilterObj.dateSingleValue as unknown as string);
        fromDate = `${DateTime.fromISO(fromDateObj.toISOString()).toUTC().startOf('day').toISO()}`;
        toDate = `${DateTime.fromISO(fromDateObj.toISOString()).toUTC().endOf('day').toISO()}`;
      }

      if (
        merchantFilterObj.dateTypeCompareValue === 'is_between' &&
        merchantFilterObj.dateRangeValue &&
        Array.isArray(merchantFilterObj.dateRangeValue)
      ) {
        const fromDateObj = new Date(merchantFilterObj.dateRangeValue[0]);
        const toDateObj = new Date(merchantFilterObj.dateRangeValue[1]);

        fromDate = `${DateTime.fromISO(fromDateObj.toISOString()).toUTC().startOf('day').toISO()}`;
        toDate = `${DateTime.fromISO(toDateObj.toISOString()).toUTC().startOf('day').toISO()}`;
      }

      if (merchantFilterObj.dateTypeCompareValue === 'is_after' && merchantFilterObj.dateSingleValue) {
        const fromDateObj = new Date(merchantFilterObj.dateSingleValue as unknown as string);

        fromDate = `${DateTime.fromISO(fromDateObj.toISOString()).toUTC().endOf('day').toISO()}`;
        toDate = `${DateTime.fromISO(fromDateObj.toISOString())
          .toUTC()
          .plus({ months: MAX_FILTERING_DAYS_MERCHANT })
          .endOf('day')
          .toISO()}`;
      }

      if (merchantFilterObj.dateTypeCompareValue === 'is_before' && merchantFilterObj.dateSingleValue) {
        const fromDateObj = new Date(merchantFilterObj.dateSingleValue as unknown as string);
        fromDate = `${DateTime.fromISO(fromDateObj.toISOString())
          .toUTC()
          .minus({ months: MAX_FILTERING_DAYS_MERCHANT })
          .startOf('day')
          .toISO()}`;
        toDate = `${DateTime.fromISO(fromDateObj.toISOString()).toUTC().startOf('day').toISO()}`;
      }

      if (fromDate) {
        queryString += `&from=${encodeURIComponent(fromDate)}`;
      }

      if (toDate) {
        queryString += `&to=${encodeURIComponent(toDate)}`;
      }
    }

    if (merchantFilterObj.businessType) {
      queryString += `&businessType=${merchantFilterObj.businessType}`;
    }

    if (merchantFilterObj.merchantName) {
      queryString += `&businessName=${merchantFilterObj.merchantName}`;
    }

    return queryString;
  }, [merchantFilterObj, listingPageProps]);

  const toggleFilter = useCallback(() => {
    setFilterPopupOpen((wasOpened) => !wasOpened);
  }, []);

  const toggleExport = () => {
    setExportOpen((wasExportOpened) => !wasExportOpened);
  };

  const fetchMerchantList = useCallback(async () => {
    try {
      setErrorMessage('');
      setFetchListLoader(true);

      const response = await getPlatformMerchantsList(apiBaseUri, marketplaceId, apiQueryString);

      setMerchantList(response.items);
      setTotalRows(response.totalRecords);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setFetchListLoader(false);
    }
  }, [apiQueryString, apiBaseUri, marketplaceId]);

  const handlePageChange = (page: number) => {
    dispatch(
      setListingPagePropsObj({
        ...listingPageProps,
        activePage: page,
      }),
    );
  };

  const handleSort = (selectedColumn: TableColumn<PlatformMerchantObj>, sortDirection: SortOrder) => {
    dispatch(
      setListingPagePropsObj({
        ...listingPageProps,
        activePage: 1,
        sortBy: selectedColumn.sortField
          ? `${sortDirection === 'asc' ? `` : `-`}${selectedColumn.sortField}`
          : '-createdAt',
      }),
    );
  };

  const statusTabChangeHandler = (val: string) => {
    dispatch(setMerchantFilterObj(getInitialFilterState()));

    dispatch(
      setListingPagePropsObj({
        ...listingPageProps,
        activePage: 1,
        sortBy: val === MerchantStatusObj.Pending ? `-createdAt` : `-stateUpdatedAt`,
        activeStatusTab: val,
      }),
    );
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    dispatch(
      setListingPagePropsObj({
        ...listingPageProps,
        activePage: page,
        limit: newPerPage,
      }),
    );
  };

  useEffect(() => {
    if (lastFilterSaveTriggerAt !== merchantFilterObj.saveTiggerAt) {
      setLastFilterSaveTriggerAt(merchantFilterObj.saveTiggerAt);
    }
  }, [apiBaseUri, dispatch, lastFilterSaveTriggerAt, marketplaceId, merchantFilterObj.saveTiggerAt]);

  useEffect(() => {
    fetchMerchantList();
  }, [fetchMerchantList]);

  useEffect(() => {
    if (!fetchListLoader) {
      setIsPageLoading(false);
    }
  }, [setIsPageLoading, fetchListLoader]);

  useEffect(() => {
    if (openFilterDropdown) {
      toggleFilter();
      setTimeout(() => {
        dispatch(setOpenFilterDropdown(false));
      }, 2000);
    }
  }, [openFilterDropdown, toggleFilter, dispatch]);

  return (
    <PlatformLayout activeTab="merchant" title="Merchants Listing">
      {!isPageLoading && errorMessage.length ? <ErrorComponent bodyText={errorMessage} /> : null}

      {isPageLoading && (
        <div className="spinner-wrapper">
          <Spinner variant="simple" isVisible label="Loading..." />
        </div>
      )}

      {!isPageLoading && (
        <Fragment>
          <MerchantLists
            merchantList={merchantList}
            activeStatusTab={listingPageProps.activeStatusTab}
            statusTabChangeHandler={statusTabChangeHandler}
            fetchListLoader={fetchListLoader}
            totalRows={totalRows}
            handlePerRowsChange={handlePerRowsChange}
            handlePageChange={handlePageChange}
            limit={listingPageProps.limit}
            toggleFilter={toggleFilter}
            toggleExport={toggleExport}
            filterPopupOpen={filterPopupOpen}
            exportOpen={exportOpen}
            activePage={listingPageProps.activePage}
            handleSort={handleSort}
          />
        </Fragment>
      )}
    </PlatformLayout>
  );
}

export default MerchantPage;
