import ErrorPage from 'pages/common/error';
import { Route, Routes } from 'react-router-dom';

import RoutesList from './constants';
import PaymentDetailPage from './details';
import PaymentLanding from './listing';
import TrasactionDetailPage from './transaction-detail';
import Transactions from './transactions';

const PaymentRoutes = () => {
  return (
    <Routes>
      <Route path={RoutesList.PAYMENT_TRANSACTIONS} caseSensitive={true} element={<Transactions />} />
      <Route path={RoutesList.PAYMENT_LANDING} caseSensitive={true} element={<PaymentLanding />} />
      <Route path={`${RoutesList.PAYMENT_DETAILS}`} caseSensitive={true} element={<PaymentDetailPage />} />
      <Route path={`${RoutesList.PAYOUT_DETAILS}`} caseSensitive={true} element={<TrasactionDetailPage />} />
      <Route
        path="*"
        element={<ErrorPage bodyTitle="Page Not Found" bodyText="The page you are trying to access doesn't exists" />}
      />
    </Routes>
  );
};

export default PaymentRoutes;
