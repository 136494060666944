export interface StatusComponentProps {
  tooltipText: string;
  tooltipDirection: any;
  children: React.ReactNode;
}

function StatusComponent({ tooltipText, tooltipDirection, ...rest }: StatusComponentProps) {
  return (
    <div
      className="lp-flex"
      data-balloon={tooltipText}
      data-balloon-pos={tooltipDirection}
      data-balloon-length="large"
      style={{ cursor: 'default' }}
    >
      {rest.children}
    </div>
  );
}

export default StatusComponent;
