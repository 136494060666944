import * as s from 'assets/styles/Platforms.styles';
import { ReactNode } from 'react';
import { Helmet } from 'react-helmet';

import Header from './Header';

interface Props {
  title?: string;
  children: ReactNode;
  activeTab?: string;
}

function PlatformLayout({ title, children, activeTab }: Props) {
  return (
    <>
      {title && (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      )}
      <s.Wrapper>
        <s.Header>
          <Header activeTab={activeTab} />
        </s.Header>
        <s.Main>
          <div className="container container-body">
            <s.ContentWrap>{children}</s.ContentWrap>
          </div>
        </s.Main>
      </s.Wrapper>
    </>
  );
}

export default PlatformLayout;
