import { getMerchantFile, uploadOnboardingMerchantFile } from 'api/merchant/onbording/file';
import { GetMerchantFileResponse } from 'api/merchant/onbording/file.types';
import { createMerchantTrustOwner, updateMerchantTrustOwner } from 'api/merchant/onbording/step-05';
import * as s from 'assets/styles/Onboarding.styles';
import { ErrorMessage, Formik, FormikProps } from 'formik';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useAppSelector } from 'redux/merchant/hooks';
import getErrorMessage from 'utils/getErrorMessage';
import * as Yup from 'yup';

import { Button, Icon, Message, Spinner, Text, TextField } from '@limepayments/cosmic';

import { CreateMerchantTrustOwnerPayload } from '../../../types';

const validationSchema = Yup.object().shape({
  businessName: Yup.string().trim().required('Please enter legal entity name'),
  selectedFile: Yup.mixed().when('updateItem', {
    is: !true,
    then: Yup.mixed()
      .required('Please upload a trust deed')
      .test({
        message: 'Please provide a supported file type',
        test: (file, context) => {
          if (!file) {
            return true;
          }
          const fileExtension = file ? file.type.split('/')[1] : '';
          const isValid = ['jpeg', 'jpg', 'png', 'pdf'].includes(fileExtension.toLowerCase());
          return isValid;
        },
      })
      .test({
        message: `File too big, can't exceed 10MB limit`,
        test: (file) => {
          if (!file) {
            return true;
          }
          const isValid = file ? file.size < 10 * 1024 * 1024 : false;
          return isValid;
        },
      }),
  }),
});

interface Props {
  isOpen: boolean;
  toggleModal: (val: boolean) => void;
  setCurrentStep: (val: number) => void;
  refreshData: () => void;
  updateItem: boolean;
  editObjValues: CreateMerchantTrustOwnerPayload | null;
  selectedOwnerEditId: string;
  fileFrontDetail: GetMerchantFileResponse | null;
  fileRemoveHandler: () => void;
}

function TrustSection({
  setCurrentStep,
  toggleModal,
  isOpen,
  refreshData,
  updateItem,
  editObjValues,
  selectedOwnerEditId,
  fileFrontDetail,
  fileRemoveHandler,
}: Props) {
  const errorContainerRef = useRef<HTMLDivElement>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);
  const [fileUploading, setFileUploading] = useState<boolean>(false);
  const formRef = useRef<FormikProps<CreateMerchantTrustOwnerPayload>>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fetchFileDetailLoader, setFetchFileDetailLoader] = useState<boolean>(false);
  const [selectedAmendementFile, setSelectedAmendementFile] = useState<Array<File> | null>(null);
  const [fileAmendementDetail, setFileAmendementDetail] = useState<Array<GetMerchantFileResponse> | null>(null);
  const [initialValues, setInitialValues] = useState<CreateMerchantTrustOwnerPayload>({
    businessName: '',
    trustDeedDocs: [],
    trustDeedAmendmentDocs: null,
    selectedFile: null,
    updateItem: false,
  });

  const { merchantId, apiBaseUri, merchantTaxCountry } = useAppSelector((state) => ({
    merchantId: state.config.merchantId,
    apiBaseUri: state.config.apiBaseUri,
    merchantTaxCountry: state.config.merchantTaxCountry,
  }));

  const fileUploadHandler = async () => {
    try {
      setFileUploading(true);
      setErrorMessage('');

      const formData = new FormData();
      if (selectedFile) {
        formData.append('file', selectedFile, selectedFile.name);
        formData.append('docType', 'TrustDeed');
      }

      const response = await uploadOnboardingMerchantFile(apiBaseUri, merchantId, formData);
      return response.limeFileId;
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setFileUploading(false);
    }

    return '';
  };

  const fileChangeHandler = (file: FileList | null) => {
    if (file && file.length > 0) {
      setSelectedFile(file[0]);
    } else {
      setSelectedFile(null);
    }
  };

  const amendementFileChangeHandler = (file: FileList | null) => {
    if (file && file.length > 0) {
      const filesArray = [];
      for (let i = 0; i < file.length; i++) {
        filesArray.push(file[i]);
      }
      setSelectedAmendementFile(filesArray);
    } else {
      setSelectedFile(null);
    }
  };

  const amendementFileRemoveHandler = (index: number) => {
    if (selectedAmendementFile && selectedAmendementFile.length > 0) {
      const filterList = selectedAmendementFile.filter((obj, key) => {
        return key !== index;
      });
      setSelectedAmendementFile(filterList);
    }
  };

  const amendementUploadedFileRemoveHandler = (fileId: string) => {
    if (fileAmendementDetail && fileAmendementDetail.length > 0) {
      const filterList = fileAmendementDetail.filter((obj) => {
        return obj.fileId !== fileId;
      });
      setFileAmendementDetail(filterList);
    }
  };

  const fetchFileDetailHandler = useCallback(
    async (fileIds: Array<string>) => {
      try {
        let data = [];
        setFetchFileDetailLoader(true);
        for (let i = 0; i < fileIds.length; i++) {
          const response = await getMerchantFile(apiBaseUri, merchantId, fileIds[i]);
          data.push(response);
        }

        setFileAmendementDetail(data);
      } catch (error) {
        setErrorMessage(getErrorMessage(error));
      } finally {
        setFetchFileDetailLoader(false);
      }
    },
    [apiBaseUri, merchantId],
  );

  const fileAmendementUploadHandler = async () => {
    try {
      setFileUploading(true);
      setErrorMessage('');

      let returnData = [];

      if (selectedAmendementFile && selectedAmendementFile.length) {
        for (let i = 0; i < selectedAmendementFile.length; i++) {
          const formData = new FormData();
          formData.append('file', selectedAmendementFile[i], selectedAmendementFile[i].name);
          formData.append('category', 'MerchantOnboarding');
          formData.append('docType', `TrustDeedAmendment`);

          const response = await uploadOnboardingMerchantFile(apiBaseUri, merchantId, formData);
          returnData.push(response.limeFileId);
        }
      }

      return returnData;
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setFileUploading(false);
    }

    return [];
  };

  const submitHandler = async (values: CreateMerchantTrustOwnerPayload, formObj: { resetForm: () => void }) => {
    try {
      setIsRequestLoading(true);
      setErrorMessage('');
      const { selectedFile, ...postData } = { ...values };

      const oldFileIds = fileAmendementDetail
        ? fileAmendementDetail.map((obj) => {
            return obj.fileId;
          })
        : [];

      postData.trustDeedAmendmentDocs = oldFileIds.length > 0 ? oldFileIds : null;

      delete postData.updateItem;

      if (selectedFile) {
        const fileId = await fileUploadHandler();
        if (!fileId) {
          return;
        }

        postData.trustDeedDocs = [fileId];
      }

      if (selectedAmendementFile && selectedAmendementFile.length > 0 && merchantTaxCountry === 'AU') {
        const fileIds = await fileAmendementUploadHandler();

        postData.trustDeedAmendmentDocs = postData.trustDeedAmendmentDocs ? [...oldFileIds, ...fileIds] : fileIds;
      }

      updateItem
        ? await updateMerchantTrustOwner(apiBaseUri, merchantId, selectedOwnerEditId, postData)
        : await createMerchantTrustOwner(apiBaseUri, merchantId, postData);
      formObj.resetForm();

      refreshData();
      toggleModal(false);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setIsRequestLoading(false);
    }
  };

  useEffect(() => {
    if (errorMessage.length > 0 && errorContainerRef && errorContainerRef.current) {
      errorContainerRef.current.scrollIntoView();
    }
  }, [errorMessage]);

  useEffect(() => {
    if (updateItem && editObjValues) {
      setInitialValues(editObjValues);
      if (editObjValues.trustDeedAmendmentDocs && editObjValues.trustDeedAmendmentDocs.length > 0) {
        fetchFileDetailHandler(editObjValues.trustDeedAmendmentDocs);
      }
    }
  }, [updateItem, editObjValues, fetchFileDetailHandler]);

  return (
    <Fragment>
      {fetchFileDetailLoader && (
        <div className="spinner-wrapper">
          <Spinner variant="simple" isVisible label="Loading..." />
        </div>
      )}

      {!fetchFileDetailLoader && (
        <Formik<CreateMerchantTrustOwnerPayload>
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={(values, formObj: { resetForm: () => void }) => {
            submitHandler(values, formObj);
          }}
          validationSchema={validationSchema}
          innerRef={formRef}
        >
          {({ values, handleSubmit, errors, touched, setFieldValue, handleChange, handleBlur }) => (
            <form autoComplete="off" onSubmit={handleSubmit} className="content-wrap-form">
              <div className="modal-content">
                <div className="lp-w-full">
                  {errorMessage.length > 0 && (
                    <div className="mt-24 mb-24" ref={errorContainerRef}>
                      <Message
                        type="inline"
                        children={<span className="text-wrap">{errorMessage}</span>}
                        variant={'error'}
                      />
                    </div>
                  )}
                  <div className="lp-w-full mb-24">
                    <Text alignment="left" variant="body-2" isEmphasised>
                      Original trust deed
                    </Text>
                    <Text tagName="span" variant="caption" className="text-greyish lp-ls5">
                      Upload the orignal trust deed for verification, certified true copies are also accepted. Files
                      must be in jpeg, png or pdf format and a maximum of 10 MB each.
                    </Text>
                  </div>
                  <div className="lp-w-full mb-24">
                    {fileFrontDetail ? (
                      <s.DocumentBlock className="lp-flex lp-justify-between lp-items-center sm:flex-row mb-24">
                        <Text alignment="left" variant="caption" isEmphasised className="text-primary">
                          Uploaded Trust deed
                        </Text>
                        <div className="upload-preview lp-flex">
                          <Icon name="Attachment" />
                          <div>
                            <Text alignment="left" variant="caption" isEmphasised className="text-primary mb-4">
                              {fileFrontDetail.fileName}
                            </Text>
                            <Text alignment="left" variant="legal" className="m-0">
                              {fileFrontDetail.fileSizeInKB}kb
                            </Text>
                          </div>
                          <button
                            type="button"
                            className="upload-delete"
                            onClick={() => {
                              fileRemoveHandler();
                              setFieldValue('updateItem', false);
                            }}
                          >
                            <Icon name="Cross" />
                          </button>
                        </div>
                      </s.DocumentBlock>
                    ) : (
                      <s.DocumentBlock className="lp-flex lp-justify-between lp-items-center sm:flex-row mb-0">
                        <Text alignment="left" variant="caption" isEmphasised className="text-primary">
                          Trust deed
                        </Text>

                        {selectedFile && (
                          <div className="upload-preview lp-flex">
                            <Icon name="Attachment" />
                            <div>
                              <Text alignment="left" variant="caption" isEmphasised className="text-primary mb-4">
                                {selectedFile.name.substring(0, 5)}...{selectedFile.name.slice(-8)}
                              </Text>
                              <Text alignment="left" variant="legal" className="m-0">
                                {parseInt((selectedFile.size / 1024).toString())}kb
                              </Text>
                            </div>
                            <button
                              type="button"
                              className="upload-delete"
                              onClick={() => {
                                fileChangeHandler(null);
                                setFieldValue('selectedFile', null);
                              }}
                            >
                              <Icon name="Cross" />
                            </button>
                          </div>
                        )}

                        {!selectedFile && (
                          <span className="lp-flex lp-justify-center lp-items-center no-min-width btn-file">
                            Select file{' '}
                            <input
                              type="file"
                              data-testid="selectedFile"
                              id="selectedFile"
                              name="selectedFile"
                              accept="image/png, image/jpeg, image/jpg, .pdf"
                              onChange={(e) => {
                                fileChangeHandler(e.target.files);
                                setFieldValue('selectedFile', e.target.files ? e.target.files[0] : {});
                              }}
                            />
                          </span>
                        )}
                      </s.DocumentBlock>
                    )}

                    <ErrorMessage name="selectedFile" component="span" className="Mui-error" />
                  </div>

                  <div className={`lp-w-full mb-24`}>
                    <div className="lp-w-full" style={{ display: 'block' }}>
                      <div className="lp-w-full mb-24">
                        <Text alignment="left" variant="body-2" isEmphasised>
                          Original trust amendments
                        </Text>
                        <Text tagName="span" variant="caption" className="text-greyish lp-ls5">
                          Upload the original amendments for verification, certified true copies are also accepted.
                          Files must be in jpeg, png or pdf format and a maximum of 10 MB each.
                        </Text>
                      </div>
                    </div>
                    {fileAmendementDetail && fileAmendementDetail.length > 0 && (
                      <s.DocumentBlock className="lp-flex lp-items-start lp-flex-column noStyle">
                        {fileAmendementDetail.map((obj) => (
                          <div className="upload-preview lp-flex">
                            <Icon name="Attachment" />
                            <div className="doc-name">
                              <Text alignment="left" variant="caption" isEmphasised className="text-primary mb-4">
                                {obj.fileName}
                              </Text>
                              <Text alignment="left" variant="legal" className="m-0">
                                {obj.fileSizeInKB}kb
                              </Text>
                            </div>
                            <button
                              className="upload-delete"
                              type="button"
                              onClick={() => {
                                amendementUploadedFileRemoveHandler(obj.fileId);
                              }}
                            >
                              <Icon name="Cross" label="Remove" />
                            </button>
                          </div>
                        ))}
                      </s.DocumentBlock>
                    )}

                    {selectedAmendementFile && selectedAmendementFile.length > 0 && (
                      <s.DocumentBlock className="lp-flex lp-items-start lp-flex-column noStyle">
                        {selectedAmendementFile.map((fileObj, key) => (
                          <div className="upload-preview lp-flex" key={key}>
                            <div className="lp-flex">
                              <Icon name="Attachment" className="mr-12" />
                              <div className="doc-name">
                                <Text alignment="left" variant="caption" isEmphasised className="text-primary mb-4">
                                  {fileObj.name}
                                </Text>
                                <Text alignment="left" variant="legal" className="m-0">
                                  {parseInt((fileObj.size / 1024).toString())}kb
                                </Text>
                              </div>
                            </div>
                            <button
                              className="upload-delete"
                              type="button"
                              onClick={() => {
                                amendementFileRemoveHandler(key);
                              }}
                            >
                              <Icon name="Cross" label="Remove" />
                            </button>
                          </div>
                        ))}
                      </s.DocumentBlock>
                    )}

                    <s.DocumentBlock className={`lp-flex noStyle`}>
                      <span
                        onClick={function noRefCheck() {}}
                        className="lp-flex lp-justify-center lp-items-center no-min-width btn-file"
                      >
                        Select files{' '}
                        <input
                          type="file"
                          data-testid="fileUpload"
                          accept="image/png, image/jpeg, image/jpg, .pdf"
                          multiple
                          id="fileUpload"
                          name="fileUpload"
                          onChange={(e) => {
                            amendementFileChangeHandler(e.target.files);
                          }}
                        />
                      </span>
                    </s.DocumentBlock>
                  </div>

                  <div className="lp-w-full mb-24">
                    <TextField
                      fullWidth
                      label="Legal entity name"
                      inputMode="text"
                      autoComplete="off"
                      id="businessName"
                      name="businessName"
                      onBlur={handleBlur}
                      value={values.businessName}
                      onChange={handleChange}
                      error={errors.businessName && touched.businessName ? errors.businessName : ''}
                      helperText="The name as stated in the trust deed."
                    />
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <s.MultiColumnRespBlock className="lp-w-full lp-justify-between">
                  <Button
                    onClick={() => toggleModal(!isOpen)}
                    size="medium"
                    variant="ghost"
                    className="no-min-width"
                    disabled={isRequestLoading || fileUploading}
                  >
                    Cancel
                  </Button>
                  <div className="lp-flex">
                    {!updateItem && (
                      <Button
                        onClick={() => setCurrentStep(1)}
                        size="medium"
                        variant="ghost"
                        className="no-min-width"
                        disabled={isRequestLoading || fileUploading}
                      >
                        <Icon name="ArrowLeft" className="ml-0" />
                        Back
                      </Button>
                    )}
                    <Button
                      size="medium"
                      variant="primary"
                      className="no-min-width"
                      disabled={isRequestLoading || fileUploading}
                      isLoading={isRequestLoading || fileUploading}
                    >
                      Save
                    </Button>
                  </div>
                </s.MultiColumnRespBlock>
              </div>
            </form>
          )}
        </Formik>
      )}
    </Fragment>
  );
}

export default TrustSection;
