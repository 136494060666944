import { configureStore } from '@reduxjs/toolkit';

import configSlice from './slice/configSlice';
import merchantSlice from './slice/merchantSlice';
import paymentSlice from './slice/paymentSlice';
import settingSlice from './slice/settingSlice';
import transactionSlice from './slice/transactionSlice';

const store = configureStore({
  reducer: {
    config: configSlice,
    merchant: merchantSlice,
    payment: paymentSlice,
    setting: settingSlice,
    transaction: transactionSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
