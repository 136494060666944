import * as s from 'assets/styles/Onboarding.styles';

import { Button, Heading, Illustration, Link, Text } from '@limepayments/cosmic';

interface Props {
  onSubmitClick: () => void;
  businessType: string;
  hasTrustOwner: boolean;
  emailAddress: string | null;
}

const BusinessDetailsStep07 = ({ onSubmitClick, businessType, hasTrustOwner, emailAddress }: Props) => {
  return (
    <div className="content-wrap-inner">
      <header className="content-header">
        <div className="lp-w-full mb-24">
          <div className="lp-w-full mb-16"></div>
          <Heading alignment="left" tagName="h2" variant="md" className="mb-24">
            Verify your business details
          </Heading>
        </div>
      </header>
      <s.Wrapper>
        <Illustration name="Creating" size="large" />
        <header className="content-header">
          <div className="lp-w-full mb-24">
            <Heading alignment="left" tagName="h4" variant="xs" testId="submitMessageHeading">
              You've successfully submitted your business details to set up your account
            </Heading>
            <Text tagName="p" variant="body-3" testId="submitMessageBody">
              {businessType === 'trust' || businessType === 'partnership' || hasTrustOwner
                ? `Your details will be checked and April, our payment services provider, may contact you for more information within 2-3 business days. Once your details are verified, you will be notified and given access to your dashboard. Depending on the information you have provided, this process could take up to 1-2 weeks.`
                : `Your details will be checked and April, our payment services provider, may contact you for more information. Once your details are verified, you will be notified and given access to your dashboard. This could take up to one business day.`}
            </Text>
            <Text tagName="p" variant="body-3" testId="submitMessageBodySec">
              If you have any questions,{' '}
              {`you can reach out to ${emailAddress ? 'our support team' : 'the April support team'} at `}
              <Link
                href={`mailto: ${emailAddress ?? 'support@meetapril.com'}`}
                size="medium"
                target="_self"
                className="link-primary fw-medium"
              >
                {emailAddress ?? 'support@meetapril.com'}
              </Link>
              .
            </Text>
          </div>
        </header>
      </s.Wrapper>

      <s.Cta className="lp-flex justify-start lp-items-center">
        <Button onClick={onSubmitClick} size="medium" variant="primary" className="no-min-width">
          Logout
        </Button>
      </s.Cta>
    </div>
  );
};

export default BusinessDetailsStep07;
