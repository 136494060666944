import MerchantDefaultRoutes from 'pages/merchant/constants';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/merchant/hooks';
import { getMerchantBaseUrl } from 'utils/getBaseUrl';

import { Button, Heading } from '@limepayments/cosmic';

import DashboardRoutesList from '../../constants';
import SettingRoutesList from '../constants';

interface Props {
  activeTab?: string;
}

function SettingsSidebar({ activeTab }: Props) {
  const navigate = useNavigate();
  const { merchantName } = useAppSelector((state) => ({
    merchantName: state.config.merchantName,
  }));

  return (
    <div className="payment-sidebar-inner sticky-sidebar">
      <div className="lp-w-full mb-32">
        <Heading alignment="left" tagName="h2" variant="xs" className="sidebar-title mb-0">
          My account
        </Heading>
        <Button
          size="small"
          onClick={() =>
            navigate(
              `${getMerchantBaseUrl(merchantName)}/${MerchantDefaultRoutes.DASHBOARD_ROUTE}/${
                DashboardRoutesList.SETTINGS_ROUTE
              }/${SettingRoutesList.SETTINGS_PROFILE}`,
            )
          }
          variant="primary"
          className={`lp-w-full lp-button-transaction ${
            activeTab === SettingRoutesList.SETTINGS_PROFILE ? `is-selected` : ``
          }`}
        >
          My profile
        </Button>
      </div>

      <div className="lp-w-full mb-32">
        <Heading alignment="left" tagName="h2" variant="xs" className="sidebar-title mb-0">
          Business settings
        </Heading>
        <Button
          size="small"
          onClick={() =>
            navigate(
              `${getMerchantBaseUrl(merchantName)}/${MerchantDefaultRoutes.DASHBOARD_ROUTE}/${
                DashboardRoutesList.SETTINGS_ROUTE
              }/${SettingRoutesList.SETTINGS_BUSINESS_PROFILE}`,
            )
          }
          variant="primary"
          className={`lp-w-full lp-button-transaction ${
            activeTab === SettingRoutesList.SETTINGS_BUSINESS_PROFILE ? `is-selected` : ``
          }`}
        >
          Business profile
        </Button>
        <Button
          size="small"
          onClick={() =>
            navigate(
              `${getMerchantBaseUrl(merchantName)}/${MerchantDefaultRoutes.DASHBOARD_ROUTE}/${
                DashboardRoutesList.SETTINGS_ROUTE
              }/${SettingRoutesList.SETTINGS_BRANDING}`,
            )
          }
          variant="primary"
          className={`lp-w-full lp-button-transaction ${
            activeTab === SettingRoutesList.SETTINGS_BRANDING ? `is-selected` : ``
          }`}
        >
          Branding
        </Button>

        {/* <Button
          size="small"
          onClick={() =>
            navigate(
              `${getMerchantBaseUrl(merchantName)}/${MerchantDefaultRoutes.DASHBOARD_ROUTE}/${
                DashboardRoutesList.SETTINGS_ROUTE
              }/${SettingRoutesList.SETTINGS_FRAUD_PREVENTIONS}`,
            )
          }
          variant="primary"
          className={`lp-w-full lp-button-transaction ${
            activeTab === SettingRoutesList.SETTINGS_FRAUD_PREVENTIONS ? `is-selected` : ``
          }`}
        >
          Fraud prevention
        </Button> */}

        {/* <Button
          size="small"
          onClick={() =>
            navigate(
              `${getMerchantBaseUrl(merchantName)}/${MerchantDefaultRoutes.DASHBOARD_ROUTE}/${
                DashboardRoutesList.SETTINGS_ROUTE
              }/${SettingRoutesList.SETTINGS_FEE_STRUCTURE}`,
            )
          }
          variant="primary"
          className={`lp-w-full lp-button-transaction ${
            activeTab === SettingRoutesList.SETTINGS_FEE_STRUCTURE ? `is-selected` : ``
          }`}
        >
          Fee structure
        </Button> */}
      </div>

      <div className="lp-w-full mb-32">
        <Heading alignment="left" tagName="h2" variant="xs" className="sidebar-title mb-0">
          Product settings
        </Heading>
        <Button
          size="small"
          onClick={() =>
            navigate(
              `${getMerchantBaseUrl(merchantName)}/${MerchantDefaultRoutes.DASHBOARD_ROUTE}/${
                DashboardRoutesList.SETTINGS_ROUTE
              }/${SettingRoutesList.SETTINGS_PAYMENT}`,
            )
          }
          variant="primary"
          className={`lp-w-full lp-button-transaction ${
            activeTab === SettingRoutesList.SETTINGS_PAYMENT ? `is-selected` : ``
          }`}
        >
          Payments
        </Button>
        <Button
          size="small"
          onClick={() =>
            navigate(
              `${getMerchantBaseUrl(merchantName)}/${MerchantDefaultRoutes.DASHBOARD_ROUTE}/${
                DashboardRoutesList.SETTINGS_ROUTE
              }/${SettingRoutesList.SETTINGS_PAY_BY_LINK}`,
            )
          }
          variant="primary"
          className={`lp-w-full lp-button-transaction ${
            activeTab === SettingRoutesList.SETTINGS_PAY_BY_LINK ? `is-selected` : ``
          }`}
        >
          Pay by link
        </Button>
        <Button
          size="small"
          onClick={() =>
            navigate(
              `${getMerchantBaseUrl(merchantName)}/${MerchantDefaultRoutes.DASHBOARD_ROUTE}/${
                DashboardRoutesList.SETTINGS_ROUTE
              }/${SettingRoutesList.SETTINGS_BALANCES}`,
            )
          }
          variant="primary"
          className={`lp-w-full lp-button-transaction ${
            activeTab === SettingRoutesList.SETTINGS_BALANCES ? `is-selected` : ``
          }`}
        >
          Balances
        </Button>
        <Button
          size="small"
          onClick={() =>
            navigate(
              `${getMerchantBaseUrl(merchantName)}/${MerchantDefaultRoutes.DASHBOARD_ROUTE}/${
                DashboardRoutesList.SETTINGS_ROUTE
              }/${SettingRoutesList.SETTINGS_API_KEYS}`,
            )
          }
          variant="primary"
          className={`lp-w-full lp-button-transaction ${
            activeTab === SettingRoutesList.SETTINGS_API_KEYS ? `is-selected` : ``
          }`}
        >
          API keys
        </Button>
      </div>
    </div>
  );
}

export default SettingsSidebar;
