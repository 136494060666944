import { useState } from 'react';

import { Icon } from '@limepayments/cosmic';

import CancelPaymentModal from './CancelPaymentModal';
import ProcessPaymentModal from './ProcessPaymentModal';
import ResendToCustomerModal from './ResendToCustomerModal';

function TableActionsDropdowns() {
  const [showProcessModal, setShowProcessModal] = useState(false);
  const [showResendModal, setShowResendModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);

  return (
    <div className="lp-flex lp-flex-column">
      <div className="t-action-dropdown-body">
        <button className="lp-flex lp-align-center lp-w-full" onClick={() => setShowProcessModal(!showProcessModal)}>
          <Icon name="Card" className="mr-6" />
          Process payment now
        </button>
        <button className="lp-flex lp-align-center lp-w-full" onClick={() => setShowResendModal(!showResendModal)}>
          <Icon name="Filled-Email" className="mr-6" />
          Resend to customer
        </button>
        <button className="lp-flex lp-align-center lp-w-full" onClick={() => setShowCancelModal(!showCancelModal)}>
          <Icon name="Cross" className="mr-6" />
          Cancel payment
        </button>
      </div>

      {/* Modals UI(s) */}
      <ProcessPaymentModal modalToggler={setShowProcessModal} isOpen={showProcessModal} />
      <ResendToCustomerModal modalToggler={setShowResendModal} isOpen={showResendModal} />
      <CancelPaymentModal modalToggler={setShowCancelModal} isOpen={showCancelModal} />
    </div>
  );
}

export default TableActionsDropdowns;
