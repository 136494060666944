import {
  createMerchantExternalBankAccount,
  getAuBankBranch,
  getMerchantExternalBankAccount,
  getNzBankBranch,
} from 'api/merchant/onbording/step-04';
import { GetMerchantExternalBankAccountObj } from 'api/merchant/onbording/step-04.types';
import { FormikProps } from 'formik';
import MerchantLayout from 'layouts/merchant/MerchantLayout';
import ErrorComponent from 'pages/common/error/ErrorComponent';
import AuthRoutesList from 'pages/merchant/auth/constants';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/merchant/hooks';
import { setCurrentActiveStep, setSaveAndFinishLater } from 'redux/merchant/slice/merchantOnboardSlice';
import { CountryCurrency } from 'utils/currency';
import { getMerchantBaseUrl } from 'utils/getBaseUrl';
import getErrorMessage from 'utils/getErrorMessage';

import { Spinner } from '@limepayments/cosmic';

import OnboardingRoutesList from '../constants';
import Step04DetailView from './Step04DetailView';
import Step04FormView from './Step04FormView';
import { CreateMerchantExternalBankAccountPayload } from './types';

function OnboardingStep04Page() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const formRef = useRef<FormikProps<CreateMerchantExternalBankAccountPayload>>(null);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [bankNameErrorMsg, setBankNameErrorMsg] = useState<string>('');
  const [bankName, setBankName] = useState<string>('');
  const [requiredErrorMessage, setRequiredErrorMessage] = useState<string>('');
  const [fetchDetailLoader, setFetchDetailLoader] = useState<boolean>(true);
  const [bankDetailFetchLoader, setBankDetailFetchLoader] = useState<boolean>(false);

  const [existingMerchantDetail, setExistingMerchantDetail] = useState<Array<GetMerchantExternalBankAccountObj>>([]);
  const [showDetailView, setShowDetailView] = useState<boolean>(false);

  const { merchantId, merchantName, saveAndFinishLater, merchantTaxCountry, apiBaseUri, hasCompletedSteps } =
    useAppSelector((state) => ({
      merchantId: state.config.merchantId,
      merchantName: state.config.merchantName,
      saveAndFinishLater: state.onboarding.saveAndFinishLater,
      merchantTaxCountry: state.config.merchantTaxCountry,
      apiBaseUri: state.config.apiBaseUri,
      hasCompletedSteps: state.onboarding.hasCompletedSteps,
    }));

  const [initialValues] = useState<CreateMerchantExternalBankAccountPayload>({
    accountName: '',
    accountNumber: '',
    country: merchantTaxCountry,
    currency: CountryCurrency[merchantTaxCountry],
    routingNumber: '',
    isSaveFinishLater: false,
  });

  const fetchNzBankHandler = async (bankAccountNo: string) => {
    try {
      setBankNameErrorMsg('');
      setBankDetailFetchLoader(true);

      const nzAccountPrefix = bankAccountNo.length > 6 ? bankAccountNo.slice(0, 6) : bankAccountNo;

      const response = await getNzBankBranch(apiBaseUri, nzAccountPrefix);
      if (response.branch) {
        setBankName(response.branch.bankName);
      } else {
        setBankName('');
        setBankNameErrorMsg('Account number is invalid');
      }
    } catch (error) {
      setBankNameErrorMsg(getErrorMessage(error));
    } finally {
      setBankDetailFetchLoader(false);
    }
  };

  const fetchAuBankHandler = async (bsb: string) => {
    try {
      setBankNameErrorMsg('');
      setBankDetailFetchLoader(true);

      const response = await getAuBankBranch(apiBaseUri, bsb);
      if (response.branch) {
        setBankName(response.branch.bankName);
      } else {
        setBankName('');
        setBankNameErrorMsg('Please enter a BSB');
      }
    } catch (error) {
      setBankNameErrorMsg(getErrorMessage(error));
    } finally {
      setBankDetailFetchLoader(false);
    }
  };

  const submitHandler = async (
    values: CreateMerchantExternalBankAccountPayload,
    formObj: { resetForm: () => void },
  ) => {
    try {
      setIsRequestLoading(true);
      setErrorMessage('');

      const { isSaveFinishLater, ...postData } = { ...values };

      if (!bankName) {
        setErrorMessage('No valid bank found');
        return false;
      }

      await createMerchantExternalBankAccount(apiBaseUri, merchantId, postData);

      formObj.resetForm();

      let redirectUrl = isSaveFinishLater
        ? `${getMerchantBaseUrl(merchantName)}/${AuthRoutesList.LOGOUT_PAGE}`
        : `${getMerchantBaseUrl(merchantName)}/onboarding/${OnboardingRoutesList.ONBOARDING_STEP05_PAGE}`;

      if (hasCompletedSteps) {
        redirectUrl = `${getMerchantBaseUrl(merchantName)}/onboarding/${OnboardingRoutesList.ONBOARDING_STEP06_PAGE}`;
      }

      navigate(redirectUrl);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setIsRequestLoading(false);
    }
  };

  useEffect(() => {
    if (formRef && formRef.current && saveAndFinishLater && !showDetailView) {
      formRef.current.setFieldValue('isSaveFinishLater', true);
      setTimeout(() => {
        if (formRef && formRef.current) {
          formRef.current.submitForm();
        }
      }, 500);
    }

    if (saveAndFinishLater && showDetailView) {
      navigate(`${getMerchantBaseUrl(merchantName)}/${AuthRoutesList.LOGOUT_PAGE}`);
    }
  }, [saveAndFinishLater, showDetailView, merchantName, navigate]);

  useEffect(() => {
    const fetchMerchantExternalBankAccount = async () => {
      try {
        setRequiredErrorMessage('');
        setFetchDetailLoader(true);

        const response = await getMerchantExternalBankAccount(apiBaseUri, merchantId);

        if (response.length > 0) {
          setExistingMerchantDetail(response.filter((bankDetail) => bankDetail.isDefaultForCurrency));
          setShowDetailView(true);
        }
      } catch (error) {
        setRequiredErrorMessage(getErrorMessage(error));
      } finally {
        setFetchDetailLoader(false);
      }
    };

    fetchMerchantExternalBankAccount();
  }, [apiBaseUri, merchantName, dispatch, merchantId]);

  useEffect(() => {
    if (!fetchDetailLoader) {
      setIsPageLoading(false);
      dispatch(setCurrentActiveStep(5));
    }
  }, [setIsPageLoading, fetchDetailLoader, dispatch]);

  return (
    <MerchantLayout activeStepNumber={4} title="Onboarding - Business bank account details">
      {!isPageLoading && requiredErrorMessage.length ? <ErrorComponent bodyText={requiredErrorMessage} /> : null}

      {isPageLoading && (
        <div className="spinner-wrapper">
          <Spinner variant="simple" isVisible label="Loading..." />
        </div>
      )}

      {!isPageLoading && !requiredErrorMessage.length && (
        <Fragment>
          {showDetailView && (
            <Step04DetailView
              onSubmitClick={() => {
                navigate(
                  `${getMerchantBaseUrl(merchantName)}/onboarding/${
                    hasCompletedSteps
                      ? OnboardingRoutesList.ONBOARDING_STEP06_PAGE
                      : OnboardingRoutesList.ONBOARDING_STEP05_PAGE
                  }`,
                );
              }}
              onPreviousClick={() => {
                navigate(
                  `${getMerchantBaseUrl(merchantName)}/onboarding/${OnboardingRoutesList.ONBOARDING_STEP03_PAGE}`,
                );
              }}
              initialValues={existingMerchantDetail[0]}
              requestLoader={isRequestLoading}
              addNewAccountHandler={() => setShowDetailView(false)}
              saveAndFinishLater={() => {
                dispatch(setSaveAndFinishLater(Date.now()));
              }}
              saveAndReview={hasCompletedSteps}
            />
          )}

          {!showDetailView && (
            <Step04FormView
              onSubmitClick={submitHandler}
              onPreviousClick={() => {
                navigate(
                  `${getMerchantBaseUrl(merchantName)}/onboarding/${OnboardingRoutesList.ONBOARDING_STEP03_PAGE}`,
                );
              }}
              initialValues={initialValues}
              requestLoader={isRequestLoading}
              errorMsg={errorMessage}
              formRef={formRef}
              bankName={bankName}
              bankDetailFetchLoader={bankDetailFetchLoader}
              fetchAuBankHandler={fetchAuBankHandler}
              fetchNzBankHandler={fetchNzBankHandler}
              isShowCancelButton={existingMerchantDetail.length > 0}
              cancelNewAccountHandler={() => {
                setBankName('');
                setShowDetailView(true);
                setBankNameErrorMsg('');
                setErrorMessage('');
              }}
              bankNameErrorMsg={bankNameErrorMsg}
              saveAndFinishLater={() => {
                dispatch(setSaveAndFinishLater(Date.now()));
              }}
              saveAndReview={hasCompletedSteps}
            />
          )}
        </Fragment>
      )}
    </MerchantLayout>
  );
}

export default OnboardingStep04Page;
