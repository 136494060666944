import MerchantLayout from 'layouts/merchant/MerchantLayout';
import AuthRoutesList from 'pages/merchant/auth/constants';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/merchant/hooks';
import { getMerchantBaseUrl } from 'utils/getBaseUrl';

import BusinessDetailsStep07 from './BusinessDetailsStep07';

interface LocationState {
  businessType: string;
  hasTrustOwner: boolean;
}

function OnboardingStep08Page() {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as LocationState;

  const { merchantName, branding, saveAndFinishLater } = useAppSelector((state) => ({
    merchantName: state.config.merchantName,
    branding: state.config.branding,
    saveAndFinishLater: state.onboarding.saveAndFinishLater,
  }));

  useEffect(() => {
    if (saveAndFinishLater) {
      navigate(`${getMerchantBaseUrl(merchantName)}/${AuthRoutesList.LOGOUT_PAGE}`);
    }
  }, [saveAndFinishLater, merchantName, navigate]);

  return (
    <MerchantLayout activeStepNumber={8} hideSideBar={true}>
      <BusinessDetailsStep07
        onSubmitClick={() => {
          navigate(`${getMerchantBaseUrl(merchantName)}/${AuthRoutesList.LOGOUT_PAGE}`);
        }}
        businessType={locationState.businessType}
        hasTrustOwner={locationState.hasTrustOwner}
        emailAddress={branding && branding.marketplaceSupportEmail ? branding.marketplaceSupportEmail : null}
      />
    </MerchantLayout>
  );
}

export default OnboardingStep08Page;
