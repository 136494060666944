import { uploadOnboardingMerchantFile } from 'api/merchant/onbording/file';
import { GetMerchantFileResponse } from 'api/merchant/onbording/file.types';
import { createMerchantPartnershipOwner, updateMerchantPartnershipOwner } from 'api/merchant/onbording/step-05';
import * as s from 'assets/styles/Onboarding.styles';
import { ErrorMessage, Formik, FormikProps } from 'formik';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useAppSelector } from 'redux/merchant/hooks';
import getErrorMessage from 'utils/getErrorMessage';
import * as Yup from 'yup';

import { Button, Icon, Message, Text, TextField } from '@limepayments/cosmic';

import { CreateMerchantPartnershipOwnerPayload } from '../../../types';

const validationSchema = Yup.object().shape({
  businessName: Yup.string().trim().required('Please enter legal entity name'),
  selectedFile: Yup.mixed().when('updateItem', {
    is: !true,
    then: Yup.mixed()
      .required('Please upload a partnership agreement')
      .test({
        message: 'Please provide a supported file type',
        test: (file, context) => {
          if (!file) {
            return true;
          }
          const fileExtension = file ? file.type.split('/')[1] : '';
          const isValid = ['jpeg', 'jpg', 'png', 'pdf'].includes(fileExtension.toLowerCase());
          return isValid;
        },
      })
      .test({
        message: `File too big, can't exceed 10MB limit`,
        test: (file) => {
          if (!file) {
            return true;
          }
          const isValid = file ? file.size < 10 * 1024 * 1024 : false;
          return isValid;
        },
      }),
  }),
});

interface Props {
  isOpen: boolean;
  toggleModal: (val: boolean) => void;
  setCurrentStep: (val: number) => void;
  refreshData: () => void;
  updateItem: boolean;
  editObjValues: CreateMerchantPartnershipOwnerPayload | null;
  selectedOwnerEditId: string;
  fileFrontDetail: GetMerchantFileResponse | null;
  fileRemoveHandler: () => void;
}

function PartnershipSection({
  setCurrentStep,
  toggleModal,
  isOpen,
  refreshData,
  updateItem,
  editObjValues,
  selectedOwnerEditId,
  fileFrontDetail,
  fileRemoveHandler,
}: Props) {
  const errorContainerRef = useRef<HTMLDivElement>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);
  const [fileUploading, setFileUploading] = useState<boolean>(false);
  const formRef = useRef<FormikProps<CreateMerchantPartnershipOwnerPayload>>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [initialValues, setInitialValues] = useState<CreateMerchantPartnershipOwnerPayload>({
    businessName: '',
    partnershipAgreementDocs: [],
    selectedFile: null,
    updateItem: false,
  });

  const { merchantId, apiBaseUri } = useAppSelector((state) => ({
    merchantId: state.config.merchantId,
    apiBaseUri: state.config.apiBaseUri,
  }));

  const fileUploadHandler = async () => {
    try {
      setFileUploading(true);
      setErrorMessage('');

      const formData = new FormData();
      if (selectedFile) {
        formData.append('file', selectedFile, selectedFile.name);
        formData.append('docType', 'PartnershipAgreement');
      }

      const response = await uploadOnboardingMerchantFile(apiBaseUri, merchantId, formData);
      return response.limeFileId;
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setFileUploading(false);
    }

    return '';
  };

  const fileChangeHandler = (file: FileList | null) => {
    if (file && file.length > 0) {
      setSelectedFile(file[0]);
    } else {
      setSelectedFile(null);
    }
  };

  const submitHandler = async (values: CreateMerchantPartnershipOwnerPayload, formObj: { resetForm: () => void }) => {
    try {
      setIsRequestLoading(true);
      setErrorMessage('');
      const { selectedFile, ...postData } = { ...values };

      if (selectedFile) {
        const fileId = await fileUploadHandler();
        if (!fileId) {
          return;
        }

        postData.partnershipAgreementDocs = [fileId];
      }

      updateItem
        ? await updateMerchantPartnershipOwner(apiBaseUri, merchantId, selectedOwnerEditId, postData)
        : await createMerchantPartnershipOwner(apiBaseUri, merchantId, postData);
      formObj.resetForm();

      refreshData();
      toggleModal(false);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setIsRequestLoading(false);
    }
  };

  useEffect(() => {
    if (errorMessage.length > 0 && errorContainerRef && errorContainerRef.current) {
      errorContainerRef.current.scrollIntoView();
    }
  }, [errorMessage]);

  useEffect(() => {
    if (updateItem && editObjValues) {
      setInitialValues(editObjValues);
    }
  }, [updateItem, editObjValues]);

  return (
    <Fragment>
      <Formik<CreateMerchantPartnershipOwnerPayload>
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values, formObj: { resetForm: () => void }) => {
          submitHandler(values, formObj);
        }}
        validationSchema={validationSchema}
        innerRef={formRef}
      >
        {({ values, handleSubmit, errors, touched, setFieldValue, handleChange, handleBlur }) => (
          <form autoComplete="off" onSubmit={handleSubmit} className="content-wrap-form">
            <div className="modal-content">
              <div className="lp-w-full" style={{ display: 'block' }}>
                {errorMessage.length > 0 && (
                  <div className="mt-24 mb-24" ref={errorContainerRef}>
                    <Message
                      type="inline"
                      children={<span className="text-wrap">{errorMessage}</span>}
                      variant={'error'}
                    />
                  </div>
                )}

                <div className="lp-w-full mb-24">
                  <Text alignment="left" variant="body-2" isEmphasised>
                    Verification documents
                  </Text>
                  <Text tagName="span" variant="caption" className="text-greyish lp-ls5">
                    Upload the original document, alternatively a certified true copy. Files must be in jpeg, png or pdf
                    format and a maximum of 10 MB each.
                  </Text>
                </div>
              </div>

              <div className="lp-w-full mb-24">
                {fileFrontDetail ? (
                  <s.DocumentBlock className="lp-flex lp-justify-between lp-items-center sm:flex-row mb-24">
                    <Text alignment="left" variant="caption" isEmphasised className="text-primary">
                      Uploaded Partnership agreement
                    </Text>
                    <div className="upload-preview lp-flex">
                      <Icon name="Attachment" />
                      <div>
                        <Text alignment="left" variant="caption" isEmphasised className="text-primary mb-4">
                          {fileFrontDetail.fileName}
                        </Text>
                        <Text alignment="left" variant="legal" className="m-0">
                          {fileFrontDetail.fileSizeInKB}kb
                        </Text>
                      </div>
                      <button
                        type="button"
                        className="upload-delete"
                        onClick={() => {
                          fileRemoveHandler();
                          setFieldValue('updateItem', false);
                        }}
                      >
                        <Icon name="Cross" />
                      </button>
                    </div>
                  </s.DocumentBlock>
                ) : (
                  <s.DocumentBlock className="lp-flex lp-justify-between lp-items-center sm:flex-row mb-0">
                    <Text alignment="left" variant="caption" isEmphasised className="text-primary">
                      Partnership agreement
                    </Text>
                    {selectedFile ? (
                      <div className="upload-preview lp-flex">
                        <Icon name="Attachment" />
                        <div>
                          <Text alignment="left" variant="caption" isEmphasised className="text-primary mb-4">
                            {selectedFile.name.substring(0, 5)}...{selectedFile.name.slice(-8)}
                          </Text>
                          <Text alignment="left" variant="legal" className="m-0">
                            {parseInt((selectedFile.size / 1024).toString())}kb
                          </Text>
                        </div>
                        <button
                          type="button"
                          className="upload-delete"
                          onClick={() => {
                            fileChangeHandler(null);
                            setFieldValue('selectedFile', null);
                          }}
                        >
                          <Icon name="Cross" />
                        </button>
                      </div>
                    ) : (
                      <span className="lp-flex lp-justify-center lp-items-center no-min-width btn-file">
                        Select file{' '}
                        <input
                          type="file"
                          data-testid="selectedFile"
                          id="selectedFile"
                          name="selectedFile"
                          accept="image/png, image/jpeg, image/jpg, .pdf"
                          onChange={(e) => {
                            fileChangeHandler(e.target.files);
                            setFieldValue('selectedFile', e.target.files ? e.target.files[0] : {});
                          }}
                        />
                      </span>
                    )}
                  </s.DocumentBlock>
                )}

                <ErrorMessage name="selectedFile" component="span" className="Mui-error" />
              </div>

              <div className="lp-w-full mb-24">
                <TextField
                  fullWidth
                  label="Legal entity name"
                  inputMode="text"
                  autoComplete="off"
                  id="businessName"
                  name="businessName"
                  onBlur={handleBlur}
                  value={values.businessName}
                  onChange={handleChange}
                  error={errors.businessName && touched.businessName ? errors.businessName : ''}
                  helperText="The name as stated in the partnership agreement."
                />
              </div>
            </div>

            <div className="modal-footer">
              <s.MultiColumnRespBlock className="lp-w-full lp-justify-between">
                <Button
                  onClick={() => toggleModal(!isOpen)}
                  size="medium"
                  variant="ghost"
                  className="no-min-width"
                  disabled={isRequestLoading || fileUploading}
                >
                  Cancel
                </Button>
                <div className="lp-flex">
                  {!updateItem && (
                    <Button
                      onClick={() => setCurrentStep(1)}
                      size="medium"
                      variant="ghost"
                      className="no-min-width"
                      disabled={isRequestLoading || fileUploading}
                    >
                      <Icon name="ArrowLeft" className="ml-0" />
                      Back
                    </Button>
                  )}
                  <Button
                    size="medium"
                    variant="primary"
                    className="no-min-width"
                    disabled={isRequestLoading || fileUploading}
                    isLoading={isRequestLoading || fileUploading}
                  >
                    Save
                  </Button>
                </div>
              </s.MultiColumnRespBlock>
            </div>
          </form>
        )}
      </Formik>
    </Fragment>
  );
}

export default PartnershipSection;
