import { updateSettingPassword } from 'api/platform/setting';
import { FormikProps } from 'formik';
import PlatformLayout from 'layouts/platform/PlatformLayout';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppSelector } from 'redux/platform/hooks';
import getErrorMessage from 'utils/getErrorMessage';

import SettingsProfile from './SettingsProfile';
import { SettingPasswordUpdatePayload } from './types';

function ProfilePage() {
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [passwordEditModalOpen, setPasswordEditModalOpen] = useState(false);
  const [updateLoader, setUpdateLoader] = useState<boolean>(false);
  const [initialState] = useState<SettingPasswordUpdatePayload>({
    password: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  const formRef = useRef<FormikProps<SettingPasswordUpdatePayload>>(null);

  const { merchantEmail, apiBaseUri } = useAppSelector((state) => ({
    merchantEmail: state.config.merchantEmail,
    apiBaseUri: state.config.apiBaseUri,
  }));

  useEffect(() => {
    setErrorMsg('');
  }, [passwordEditModalOpen]);

  const submitHandler = async (values: SettingPasswordUpdatePayload, formObj: { resetForm: () => void }) => {
    try {
      setUpdateLoader(true);
      setErrorMsg('');

      const { confirmNewPassword, ...postData } = { ...values };

      await updateSettingPassword(apiBaseUri, postData);

      toast.success('Password updated successfully');
      formObj.resetForm();
      setPasswordEditModalOpen(false);
    } catch (error) {
      setErrorMsg(
        getErrorMessage(error, ({ errorCode, message }) =>
          errorCode === 'credentials_invalid' ? 'Incorrect Password' : message,
        ),
      );
    } finally {
      setUpdateLoader(false);
    }
  };

  return (
    <PlatformLayout title="My account - My profile" activeTab="settings">
      <SettingsProfile
        merchantEmail={merchantEmail}
        initialState={initialState}
        onSubmitClick={submitHandler}
        formRef={formRef}
        errorMsg={errorMsg}
        updateLoader={updateLoader}
        passwordEditModalOpen={passwordEditModalOpen}
        setPasswordEditModalOpen={setPasswordEditModalOpen}
      />
    </PlatformLayout>
  );
}

export default ProfilePage;
