import * as s from 'assets/styles/Platforms.styles';
// import { DateTime } from 'luxon';
import { useEffect } from 'react';
import ClickAwayListener from 'react-click-away-listener';

import { Button, Checkbox, Heading, Icon, Message, Spinner, Stack, Text } from '@limepayments/cosmic';

// import CustomDateRange from '../../../merchant/listing/partials/CustomDateRange';
import { PaymentExportColumnsObj } from '../../constants';
import { IExportFilterObj } from '../types';

interface Props {
  onCloseExportClick: (val: boolean, closeSection?: boolean) => void;
  exportSaveClickHandler: () => void;
  exportFilterObj: IExportFilterObj;
  setExportColumnsSelectedList: (val: string, isSelectAll?: boolean) => void;
  exportFilterChangeHandler: (key: string, value: string | boolean | Date | Date[]) => void;
  filterCount: number;
}

function ExportComponent({
  onCloseExportClick,
  exportFilterObj,
  setExportColumnsSelectedList,
  exportSaveClickHandler,
  exportFilterChangeHandler,
  filterCount,
}: Props) {
  useEffect(() => {
    if (exportFilterObj.errorMessage.length > 0 || exportFilterObj.exportDataLoader) {
      window.scrollTo({
        top: 250,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [exportFilterObj.errorMessage, exportFilterObj.exportDataLoader]);

  return (
    <ClickAwayListener onClickAway={() => onCloseExportClick(true, true)}>
      <div className="lp-flex lp-flex-column">
        <div className="filter-header">
          <Heading alignment="left" tagName="h2" variant="xs" className="mb-0">
            Export payments
          </Heading>
          <Button
            type="button"
            onClick={() => onCloseExportClick(true, true)}
            size="small"
            variant="ghost"
            className="button-close m-0 p-0"
          >
            <Icon name="Cross" className="m-0 p-0" />
          </Button>
        </div>
        <div className="filter-body">
          {/* listing and error state */}
          {!exportFilterObj.exportDataLoader && !exportFilterObj.exportSuccessfully && (
            <div className="lp-w-full">
              {exportFilterObj.errorMessage.length > 0 && (
                <div className="lp-w-full mb-24">
                  <Message variant="error" children={<span>{exportFilterObj.errorMessage}</span>} type={'inline'} />
                </div>
              )}
              {/* <div className="lp-w-full mb-24">
                <Switch
                  className="text-neutral-600"
                  id="someid"
                  onChange={(val) => exportFilterChangeHandler('exportWithAppliedFilter', val)}
                  checked={exportFilterObj.exportWithAppliedFilter}
                  label="Export with applied filters"
                  size="small"
                />
              </div> */}
              {/* <div className="lp-w-full mb-24">
                <RadioGroup
                  defaultValue={exportFilterObj.dateActiveRangeValue}
                  description=""
                  header="Date"
                  name="nationality"
                  onChange={(value) => exportFilterChangeHandler('dateActiveRangeValue', value)}
                  radios={[
                    {
                      text: `Today (${DateTime.local().toFormat('d LLL')})`,
                      value: 'today',
                    },
                    {
                      text: `Current month (${DateTime.local()
                        .startOf('month')
                        .toFormat('d LLL')} - ${DateTime.local().toFormat('d LLL')})`,
                      value: 'current_month',
                    },
                    {
                      text: `Last 7 days (${DateTime.local()
                        .minus({ days: 7 })
                        .toFormat('d LLL')} - ${DateTime.local().toFormat('d LLL')})`,
                      value: 'one_week',
                    },
                    {
                      text: `Last month (${DateTime.local()
                        .minus({ month: 1 })
                        .startOf('month')
                        .toFormat('d LLL')} - ${DateTime.local()
                        .minus({ month: 1 })
                        .endOf('month')
                        .toFormat('d LLL')})`,
                      value: 'one_month',
                    },
                    {
                      text: 'Custom',
                      value: 'custom',
                    },
                  ]}
                />
              </div>
              {exportFilterObj.dateActiveRangeValue === 'custom' && (
                <div className="lp-w-full mb-24">
                  <CustomDateRange
                    dateRangeChangeHandler={(value) => exportFilterChangeHandler('exportDateRangeValue', value)}
                    dateRangeValue={exportFilterObj.exportDateRangeValue}
                  />
                </div>
              )} */}

              {filterCount > 0 && (
                <div className="lp-w-full mb-24">
                  <Message
                    children={
                      <Text tagName="p" variant="body-3" isEmphasised>
                        {`The ${filterCount} ${
                          filterCount > 1 ? 'filters' : 'filter'
                        } applied will not be included in the export.`}
                      </Text>
                    }
                    type={'inline'}
                    variant={'information'}
                  />
                </div>
              )}

              <div className="lp-w-full mb-24">
                <Heading alignment="left" tagName="h2" variant="xxs" className="mb-24">
                  Columns
                </Heading>
                <Checkbox
                  id="testid"
                  label="Select all"
                  variant="body-2"
                  checked={
                    exportFilterObj.exportColumnsSelectedList.length === Object.keys(PaymentExportColumnsObj).length
                  }
                  onChange={(event) => setExportColumnsSelectedList('', true)}
                />
              </div>

              <div className="checkbox-column-list lp-flex">
                <div className="checkbox-columns">
                  {Object.keys(PaymentExportColumnsObj).map((objKey, key) => (
                    <Checkbox
                      key={key}
                      id={`testid${key}`}
                      label={PaymentExportColumnsObj[objKey as keyof typeof PaymentExportColumnsObj]}
                      variant="body-2"
                      checked={exportFilterObj.exportColumnsSelectedList.includes(objKey)}
                      onChange={() => setExportColumnsSelectedList(objKey)}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}

          {/* loading state */}
          {exportFilterObj.exportDataLoader && !exportFilterObj.exportSuccessfully && (
            <s.MerchantLoading>
              <Stack spacing="md">
                <Spinner isVisible variant="simple" label="Preparing export" />
              </Stack>
            </s.MerchantLoading>
          )}

          {/* success state */}
          {!exportFilterObj.exportDataLoader && exportFilterObj.exportSuccessfully && (
            <s.MerchantLoading>
              <div className="success mb-8">
                <Icon name="Tick" />
              </div>
              <Text alignment="center" tagName="p" variant="body-3" className="max-w320 mb-24">
                Export complete
              </Text>
              <Text alignment="center" tagName="p" variant="body-3" className="max-w320 mt-0">
                <a
                  href={`data:text/csv;charset=utf-8,${escape(
                    exportFilterObj.exportDataCSV ? exportFilterObj.exportDataCSV : '',
                  )}`}
                  download="payments.csv"
                  className="fw-medium lp-link-primary"
                >
                  click here to download
                </a>
              </Text>
            </s.MerchantLoading>
          )}
        </div>
        <div className="filter-footer">
          <Button
            className="lp-w-full"
            onClick={() => onCloseExportClick(true, exportFilterObj.exportSuccessfully ? true : false)}
            size="medium"
            variant="reversed"
          >
            {`${exportFilterObj.exportSuccessfully ? 'Close' : 'Clear'}`}
          </Button>
          {!exportFilterObj.exportSuccessfully && !exportFilterObj.exportDataLoader && (
            <Button className="lp-w-full" onClick={() => exportSaveClickHandler()} size="medium" variant="primary">
              Export
            </Button>
          )}
        </div>
      </div>
    </ClickAwayListener>
  );
}

export default ExportComponent;
