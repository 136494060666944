import { GetMerchantStatus } from 'api/merchant/onbording/introduction.types';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IMerchant {
  merchantStatus: GetMerchantStatus | null;
}

const initialState: IMerchant = {
  merchantStatus: null,
};

export const merchantSlice = createSlice({
  name: 'merchantSlice',
  initialState,
  reducers: {
    setMerchantStatus: (state, action: PayloadAction<GetMerchantStatus>) => {
      state.merchantStatus = action.payload;
    },
  },
});

export const { setMerchantStatus } = merchantSlice.actions;
export default merchantSlice.reducer;
