import * as s from 'assets/styles/Onboarding.styles';
import { Formik, FormikProps } from 'formik';
import { RefObject, useEffect, useRef } from 'react';
import scrollToComponent from 'react-scroll-to-component';
import * as Yup from 'yup';

import { Button, Heading, Icon, Message, Text, TextField } from '@limepayments/cosmic';

import { UpdateMerchantCustomerFacingDetailPayload } from './types';

const validationSchema = Yup.object().shape({
  brandName: Yup.string().trim().required("Please enter the business' trading name"),
  cardStatementName: Yup.string()
    .trim()
    .required('Please enter a card statement name')
    .min(5, 'Please enter between 5-13 characters')
    .max(13, 'Please enter between 5-13 characters')
    .matches(/^[aA-zZ\s]+$/, 'Please enter only alphabetic characters'),
  smsSenderName: Yup.string()
    .trim()
    .required('Please enter an SMS sender name')
    .min(3, 'Please enter between 3-11 characters')
    .max(11, 'Please enter between 3-11 characters')
    .matches(/^[aA-zZ0-9]+$/, 'Please enter only alphanumeric characters'),
  website: Yup.string()
    .required("Please enter the business' website")
    .trim()
    .matches(
      /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#-]+)*(\/[\w#-]+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
      'Please enter a valid website URL',
    ),
});

interface Props {
  onSubmitClick: (values: UpdateMerchantCustomerFacingDetailPayload, formObj: { resetForm: () => void }) => void;
  onPreviousClick: () => void;
  initialValues: UpdateMerchantCustomerFacingDetailPayload;
  requestLoader: boolean;
  errorMsg: string;
  formRef: RefObject<FormikProps<UpdateMerchantCustomerFacingDetailPayload>>;
  saveAndFinishLater: () => void;
  saveAndReview: boolean;
}

function Step02View({
  onSubmitClick,
  onPreviousClick,
  saveAndFinishLater,
  initialValues,
  requestLoader,
  errorMsg,
  formRef,
  saveAndReview,
}: Props) {
  const errorContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (errorMsg.length > 0 && errorContainerRef && errorContainerRef.current) {
      scrollToComponent(errorContainerRef.current, { duration: 500 });
    }
  }, [errorMsg]);

  return (
    <>
      <div className="content-wrap-inner">
        <header className="content-header">
          <div className="lp-w-full mb-24">
            <div className="lp-w-full mb-16">
              <Button
                size="small"
                variant="ghost"
                isNeutral
                className="back-button"
                type="button"
                onClick={onPreviousClick}
              >
                <Icon name="ArrowLeft" className="back-arrow" />
                Back
              </Button>
            </div>
            <Heading alignment="left" tagName="h2" variant="md" className="mb-24">
              Verify your business details
            </Heading>
            <Heading alignment="left" tagName="h4" variant="sm">
              Branding details
            </Heading>
            {errorMsg.length > 0 && (
              <div className="mt-24 mb-24" ref={errorContainerRef}>
                <Message type="inline" children={<span className="text-wrap">{errorMsg}</span>} variant={'error'} />
              </div>
            )}
            <Text variant="body-3">
              Provide the details customers will see as they interact or transact with your business.
            </Text>
          </div>
        </header>

        <Formik<UpdateMerchantCustomerFacingDetailPayload>
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={(values, formObj: { resetForm: () => void }) => {
            onSubmitClick(values, formObj);
          }}
          validationSchema={validationSchema}
          innerRef={formRef}
        >
          {({ values, handleSubmit, errors, touched, handleBlur, handleChange, setFieldValue }) => (
            <form autoComplete="off" onSubmit={handleSubmit} className="content-wrap-form">
              <div className="content-main">
                <div className="lp-w-full mb-12">
                  <TextField
                    fullWidth
                    label="Trading name"
                    inputMode="text"
                    autoComplete="off"
                    id="brandName"
                    name="brandName"
                    onBlur={handleBlur}
                    value={values.brandName}
                    onChange={handleChange}
                    error={errors.brandName && touched.brandName ? errors.brandName : ''}
                    helperText="The brand or customer facing name of the business."
                  />
                </div>
                <div className="lp-w-full mb-12">
                  <div
                    className={`lp-w-full icon-input ${
                      errors.cardStatementName && touched.cardStatementName ? 'icon-input-error' : ''
                    }`}
                  >
                    <TextField
                      fullWidth
                      label="Card statement name"
                      inputMode="text"
                      autoComplete="off"
                      id="cardStatementName"
                      name="cardStatementName"
                      onBlur={handleBlur}
                      value={values.cardStatementName}
                      onChange={handleChange}
                      error={errors.cardStatementName && touched.cardStatementName ? errors.cardStatementName : ''}
                      helperText="5-13 alphabetic characters only, this includes upper and lower cases."
                    />
                    <span
                      data-balloon="The business name that appears on customer bank statements. A recognisable name is important so that customers remember where they purchased from and to avoid unintended disputes."
                      data-balloon-pos="up"
                      data-balloon-mobile-pos="left"
                      data-balloon-length="large"
                      className="lp-flex lp-align-center lp-justify-center size-11 info-icon"
                    >
                      <Icon name="Info" />
                    </span>
                  </div>
                </div>
                <div className="lp-w-full mb-12">
                  <div
                    className={`lp-w-full icon-input ${
                      errors.smsSenderName && touched.smsSenderName ? 'icon-input-error' : ''
                    }`}
                  >
                    <TextField
                      fullWidth
                      label="SMS sender name"
                      inputMode="text"
                      autoComplete="off"
                      id="smsSenderName"
                      name="smsSenderName"
                      onBlur={handleBlur}
                      value={values.smsSenderName}
                      onChange={handleChange}
                      error={errors.smsSenderName && touched.smsSenderName ? errors.smsSenderName : ''}
                      helperText="3-11 characters, lower or uppercase letters or digits."
                    />
                    <span
                      data-balloon="The contact name that appears on SMS received by customers regarding their payment. A recognisable name is important so that customers remember where they purchased from and to avoid unintended disputes."
                      data-balloon-pos="up"
                      data-balloon-mobile-pos="left"
                      data-balloon-length="large"
                      className="lp-flex lp-align-center lp-justify-center size-11 info-icon"
                    >
                      <Icon name="Info" />
                    </span>
                  </div>
                </div>
                <div className="lp-w-full mb-12">
                  <TextField
                    fullWidth
                    label="Business website"
                    inputMode="text"
                    autoComplete="off"
                    id="website"
                    name="website"
                    onBlur={handleBlur}
                    value={values.website}
                    onChange={handleChange}
                    error={errors.website && touched.website ? errors.website : ''}
                    helperText="The business' website, app store link, social media profile, etc."
                  />
                </div>
              </div>

              <s.Cta className="lp-flex lp-justify-end lp-items-center sm:flex-col">
                <Button
                  type="submit"
                  size="medium"
                  variant="primary"
                  className="no-min-width lp-full"
                  isLoading={requestLoader}
                  disabled={requestLoader}
                  onClick={() => setFieldValue('isSaveFinishLater', false)}
                >
                  Save and {saveAndReview ? `review` : `continue`}
                  <Icon name="ArrowRight" />
                </Button>
                <div className="mobile-sidebar-footer">
                  <Button size="medium" onClick={saveAndFinishLater} variant="ghost" className="lp-w-full">
                    <Icon name="Save" />
                    Save and finish later
                  </Button>
                </div>
              </s.Cta>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default Step02View;
