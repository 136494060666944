import {
  getSettingBusinessProfile,
  getSettingPlatformBusinessRegistration,
  updateSettingBusinessProfile,
} from 'api/platform/setting';
import { SettingPlatformBusinessRegistrationResponse } from 'api/platform/setting.types';
import { FormikProps } from 'formik';
import PlatformLayout from 'layouts/platform/PlatformLayout';
import ErrorComponent from 'pages/common/error/ErrorComponent';
import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppSelector } from 'redux/platform/hooks';
import getErrorMessage from 'utils/getErrorMessage';

import { Spinner } from '@limepayments/cosmic';

import BusinessProfile from './BusinessProfile';
import { SettingBusinessProfilePayload } from './types';

function BusinessProfilePage() {
  const [fetchBusinessProfileDetailLoader, setFetchBusinessProfileDetailLoader] = useState<boolean>(true);
  const [platformRegistrationLoader, setPlatformRegistrationLoader] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [updateErrorMsg, setUpdateErrorMsg] = useState<string>('');
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [editRequestLoading, setEditRequestLoading] = useState<boolean>(false);
  const [businessProfileDetail, setBusinessProfileDetail] = useState<SettingBusinessProfilePayload>({
    contactPerson: {
      firstName: '',
      lastName: '',
      title: '',
    },
    contactEmail: '',
    supportEmail: '',
    phoneNo: '',
    address: {
      unitNumber: '',
      streetNumber: '',
      streetName: '',
      streetType: '',
      suburb: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
    },
    isManualEnterAddress: true,
    businessAddress: '',
  });
  const [platformRegistationDetail, setPlatformRegistationDetail] =
    useState<SettingPlatformBusinessRegistrationResponse>({
      taxId: '',
      industry: '',
      businessName: '',
      businessType: '',
    });
  const formRef = useRef<FormikProps<SettingBusinessProfilePayload>>(null);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

  const { marketplaceId, apiBaseUri, platformCountry } = useAppSelector((state) => ({
    marketplaceId: state.config.marketplaceId,
    apiBaseUri: state.config.apiBaseUri,
    platformCountry: state.config.platformCountry,
  }));

  const submitHandler = async (values: SettingBusinessProfilePayload, formObj: { resetForm: () => void }) => {
    try {
      setEditRequestLoading(true);
      setUpdateErrorMsg('');

      const { isManualEnterAddress, businessAddress, ...postData } = { ...values };

      await updateSettingBusinessProfile(apiBaseUri, marketplaceId, platformCountry, postData);

      toast.success('Business profile updated successfully');
      setBusinessProfileDetail(values);
      formObj.resetForm();
      setEditModalOpen(false);
    } catch (error) {
      setUpdateErrorMsg(getErrorMessage(error));
    } finally {
      setEditRequestLoading(false);
    }
  };

  const fetchBusinessProfileDetail = useCallback(async () => {
    try {
      setFetchBusinessProfileDetailLoader(true);

      const response = await getSettingBusinessProfile(apiBaseUri, marketplaceId, platformCountry);
      setBusinessProfileDetail({ ...response, isManualEnterAddress: true, businessAddress: '' });
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setFetchBusinessProfileDetailLoader(false);
    }
  }, [apiBaseUri, marketplaceId, platformCountry]);

  const fetchPlatformBusinessRegistration = useCallback(async () => {
    try {
      setPlatformRegistrationLoader(true);

      const response = await getSettingPlatformBusinessRegistration(apiBaseUri, marketplaceId, platformCountry);
      setPlatformRegistationDetail(response);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setPlatformRegistrationLoader(false);
    }
  }, [apiBaseUri, marketplaceId, platformCountry]);

  useEffect(() => {
    if (!platformCountry) {
      return;
    }

    setErrorMessage('');
    fetchBusinessProfileDetail();
    fetchPlatformBusinessRegistration();
  }, [apiBaseUri, marketplaceId, platformCountry, fetchBusinessProfileDetail, fetchPlatformBusinessRegistration]);

  useEffect(() => {
    if (!fetchBusinessProfileDetailLoader && !platformRegistrationLoader) {
      setIsPageLoading(false);
    }
  }, [fetchBusinessProfileDetailLoader, platformRegistrationLoader, setIsPageLoading]);

  return (
    <PlatformLayout title="Business settings - Business profile" activeTab="settings">
      {!isPageLoading && errorMessage.length ? <ErrorComponent bodyText={errorMessage} /> : null}

      {isPageLoading && (
        <div className="spinner-wrapper">
          <Spinner variant="simple" isVisible label="Loading..." />
        </div>
      )}

      {!isPageLoading && !errorMessage.length && (
        <BusinessProfile
          errorMsg={updateErrorMsg}
          editRequestLoading={editRequestLoading}
          businessProfileDetail={businessProfileDetail}
          onSubmitClick={submitHandler}
          formRef={formRef}
          setEditModalOpen={setEditModalOpen}
          editModalOpen={editModalOpen}
          platformCountry={platformCountry}
          platformRegistationDetail={platformRegistationDetail}
        />
      )}
    </PlatformLayout>
  );
}

export default BusinessProfilePage;
