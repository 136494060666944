import { Address, addressToString } from 'api/fragments';
import { StandardMerchant, UpdateMerchantBusinessProfileRequest } from 'api/merchant/dashboard/merchants.types';

export interface BusinessProfileModalForm {
  contactPerson: {
    jobTitle: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
  };
  businessAddress: Address;
  isManualEnterAddress?: boolean;
  businessAddressManual?: string;
}

type toUpdateMerchantBusinessProfileRequestType = (
  businessProfileModalFormData: BusinessProfileModalForm,
  standardMerchantResponse: StandardMerchant,
) => UpdateMerchantBusinessProfileRequest;
type toBusinessProfileModalFormType = (merchantResponse: StandardMerchant) => BusinessProfileModalForm;

export const toBusinessProfileModalForm: toBusinessProfileModalFormType = ({ StandardMerchant }) => {
  return {
    contactPerson: {
      jobTitle: StandardMerchant.businessProfile.contactPerson.title,
      firstName: StandardMerchant.businessProfile.contactPerson.givenName,
      lastName: StandardMerchant.businessProfile.contactPerson.familyName,
      phoneNumber: StandardMerchant.businessProfile.phoneNumber,
      email: StandardMerchant.businessProfile.contactEmailAddress,
    },
    businessAddress: StandardMerchant.businessProfile.address,
    businessAddressManual: addressToString(StandardMerchant?.businessProfile.address),
  };
};

export const toUpdateMerchantBusinessProfileRequest: toUpdateMerchantBusinessProfileRequestType = (
  { contactPerson, businessAddress },
  { StandardMerchant },
) => ({
  UpdateMerchantBusinessProfile: {
    timeZone: StandardMerchant.businessProfile.timeZone,
    businessName: StandardMerchant.businessProfile.businessName,
    businessType: StandardMerchant.businessProfile.businessType,
    companyType: StandardMerchant.businessProfile.companyType,
    website: StandardMerchant.businessProfile.website,
    supportSite: StandardMerchant.businessProfile.supportSite,
    industry: StandardMerchant.businessProfile.industry,
    taxId: StandardMerchant.businessProfile.taxId,
    contactPerson: {
      title: contactPerson.jobTitle,
      givenName: contactPerson.firstName,
      familyName: contactPerson.lastName,
    },
    contactEmailAddress: contactPerson.email,
    phoneNumber: contactPerson.phoneNumber,
    address: {
      unitNumber: businessAddress.unitNumber,
      streetNumber: businessAddress.streetNumber,
      streetName: businessAddress.streetName,
      streetType: businessAddress.streetType,
      suburb: businessAddress.suburb,
      city: businessAddress.country === 'NZ' ? businessAddress.city : undefined,
      state: businessAddress.country !== 'NZ' ? businessAddress.state : undefined,
      postalCode: businessAddress.postalCode,
      country: businessAddress.country,
    },
    supportEmailAddress: StandardMerchant.businessProfile.supportEmailAddress,
    brandName: StandardMerchant.businessProfile.brandName,
    cardStatementName: StandardMerchant.businessProfile.cardStatementName,
    smsSenderName: StandardMerchant.businessProfile.smsSenderName,
  },
});
