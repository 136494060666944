import * as s from 'assets/styles/Platforms.styles';
// import { DateTime } from 'luxon';
import { useEffect } from 'react';
import ClickAwayListener from 'react-click-away-listener';

import { Button, Checkbox, Heading, Icon, Message, Spinner, Stack, Text } from '@limepayments/cosmic';

import { ExportColumnsObj } from '../../constants';

// import CustomDateRange from './CustomDateRange';

interface Props {
  onCloseExportClick: (val: boolean, clearSection?: boolean) => void;
  exportSaveClickHandler: () => void;
  exportWithAppliedFilter: boolean;
  dateActiveRangeValue: string;
  exportDateRangeValue: string | Date | Array<Date>;
  exportColumnsSelectedList: Array<string>;
  setExportWithAppliedFilter: (exportWithAppliedFilter: boolean) => void;
  setDateActiveRangeValue: (val: string) => void;
  setExportDateRangeValue: (exportDateRangeValue: string | Date | Array<Date>) => void;
  setExportColumnsSelectedList: (val: string, isSelectAll?: boolean) => void;
  exportSuccessfully: boolean;
  exportDataLoader: boolean;
  exportDataCSV: string | null;
  errorMessage: string;
  activeStatusTab: string;
  filterCount: number;
}

function ExportComponent({
  onCloseExportClick,
  exportColumnsSelectedList,
  dateActiveRangeValue,
  exportDateRangeValue,
  setExportWithAppliedFilter,
  setDateActiveRangeValue,
  setExportDateRangeValue,
  setExportColumnsSelectedList,
  exportSaveClickHandler,
  exportSuccessfully,
  exportDataLoader,
  exportDataCSV,
  errorMessage,
  activeStatusTab,
  exportWithAppliedFilter,
  filterCount,
}: Props) {
  useEffect(() => {
    if (errorMessage.length > 0 || exportDataLoader) {
      window.scrollTo({
        top: 250,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [errorMessage, exportDataLoader]);

  return (
    <ClickAwayListener onClickAway={() => onCloseExportClick(true, true)}>
      <div className="lp-flex lp-flex-column">
        <div className="filter-header">
          <Heading alignment="left" tagName="h2" variant="xs" className="mb-0">
            Export merchants
          </Heading>
          <Button
            type="button"
            onClick={() => onCloseExportClick(true, true)}
            size="small"
            variant="ghost"
            className="button-close m-0 p-0"
          >
            <Icon name="Cross" className="m-0 p-0" />
          </Button>
        </div>
        <div className="filter-body">
          {/* listing and error state */}
          {!exportDataLoader && !exportSuccessfully && (
            <div className="lp-w-full">
              {errorMessage.length > 0 && (
                <div className="lp-w-full mb-24">
                  <Message variant="error" children={<span>{errorMessage}</span>} type={'inline'} />
                </div>
              )}
              {/* <div className="lp-w-full mb-24">
                <Switch
                  className="text-neutral-600"
                  id="someid"
                  onChange={(val) => setExportWithAppliedFilter(val)}
                  checked={exportWithAppliedFilter}
                  label="Export with applied filters"
                  size="small"
                />
              </div> */}
              {/* <div className="lp-w-full mb-24">
                <RadioGroup
                  defaultValue={dateActiveRangeValue}
                  description=""
                  header={`Date ${
                    activeStatusTab === MerchantStatusObj.Pending ? 'Created' : `${activeStatusTab.toLowerCase()}`
                  } range`}
                  name="dateoption"
                  onChange={(value) => setDateActiveRangeValue(value)}
                  radios={[
                    {
                      text: `Today (${DateTime.local().toFormat('d LLL')})`,
                      value: 'today',
                    },
                    {
                      text: `Current month (${DateTime.local()
                        .startOf('month')
                        .toFormat('d LLL')} - ${DateTime.local().toFormat('d LLL')})`,
                      value: 'current_month',
                    },
                    {
                      text: `Last 7 days (${DateTime.local()
                        .minus({ days: 7 })
                        .toFormat('d LLL')} - ${DateTime.local().toFormat('d LLL')})`,
                      value: 'one_week',
                    },
                    {
                      text: `Last month (${DateTime.local()
                        .minus({ month: 1 })
                        .startOf('month')
                        .toFormat('d LLL')} - ${DateTime.local()
                        .minus({ month: 1 })
                        .endOf('month')
                        .toFormat('d LLL')})`,
                      value: 'one_month',
                    },
                    {
                      text: 'Custom',
                      value: 'custom',
                    },
                  ]}
                />
              </div>
              {dateActiveRangeValue === 'custom' && (
                <div className="lp-w-full mb-24">
                  <CustomDateRange
                    dateRangeChangeHandler={setExportDateRangeValue}
                    dateRangeValue={exportDateRangeValue}
                  />
                </div>
              )} */}

              {filterCount > 0 && (
                <div className="lp-w-full mb-24">
                  <Message
                    children={
                      <Text tagName="p" variant="body-3" isEmphasised>
                        {`The ${filterCount} ${
                          filterCount > 1 ? 'filters' : 'filter'
                        } applied will not be included in the export.`}
                      </Text>
                    }
                    type={'inline'}
                    variant={'information'}
                  />
                </div>
              )}

              <div className="lp-w-full mb-24">
                <Heading alignment="left" tagName="h2" variant="xxs" className="mb-24">
                  Columns
                </Heading>
                <Checkbox
                  id="testid"
                  label="Select all"
                  variant="body-2"
                  checked={exportColumnsSelectedList.length === Object.keys(ExportColumnsObj).length}
                  onChange={(event) => setExportColumnsSelectedList('', true)}
                />
              </div>

              <div className="checkbox-column-list lp-flex">
                <div className="checkbox-columns">
                  {Object.keys(ExportColumnsObj).map((objKey, key) => (
                    <Checkbox
                      key={key}
                      id={`testid${key}`}
                      label={ExportColumnsObj[objKey as keyof typeof ExportColumnsObj]}
                      variant="body-2"
                      checked={exportColumnsSelectedList.includes(objKey)}
                      onChange={() => setExportColumnsSelectedList(objKey)}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}

          {/* loading state */}
          {exportDataLoader && !exportSuccessfully && (
            <s.MerchantLoading>
              <Stack spacing="md">
                <Spinner isVisible variant="simple" label="Preparing export" />
              </Stack>
            </s.MerchantLoading>
          )}

          {/* process state */}
          {/* <s.MerchantLoading>
          <Text alignment="center" tagName="p" variant="body-3">
            Exporting 1,298 objects. A few seconds remaining...
          </Text>
          <div className="export-loading mt-16 mb-24">
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                aria-valuenow={25}
                aria-valuemin={0}
                aria-valuemax={100}
                style={{ width: '75%' }}
              ></div>
            </div>
          </div>
          <Text alignment="center" tagName="p" variant="caption" className="max-w320">
            If you need to close this window, we'll send an email to {'{'}username@email.com{'}'} with a link to
            download when your export is ready.
          </Text>
        </s.MerchantLoading> */}

          {/* success state */}
          {!exportDataLoader && exportSuccessfully && (
            <s.MerchantLoading>
              <div className="success mb-8">
                <Icon name="Tick" />
              </div>
              <Text alignment="center" tagName="p" variant="body-3" className="max-w320 mb-24">
                Export complete
              </Text>
              <Text alignment="center" tagName="p" variant="body-3" className="max-w320 mt-0">
                <a
                  href={`data:text/csv;charset=utf-8,${escape(exportDataCSV ? exportDataCSV : '')}`}
                  download="merchants.csv"
                  className="fw-medium lp-link-primary"
                >
                  click here to download
                </a>
              </Text>
            </s.MerchantLoading>
          )}
        </div>
        <div className="filter-footer">
          <Button
            className="lp-w-full"
            onClick={() => onCloseExportClick(true, exportSuccessfully ? true : false)}
            size="medium"
            variant="reversed"
          >
            {`${exportSuccessfully ? 'Close' : 'Clear'}`}
          </Button>
          {!exportSuccessfully && !exportDataLoader && (
            <Button className="lp-w-full" onClick={() => exportSaveClickHandler()} size="medium" variant="primary">
              Export
            </Button>
          )}
        </div>
      </div>
    </ClickAwayListener>
  );
}

export default ExportComponent;
