import { getMerchantFile } from 'api/merchant/onbording/file';
import { GetMerchantFileResponse } from 'api/merchant/onbording/file.types';
import { GetListMerchantPersonObj } from 'api/merchant/onbording/step-05.types';
import * as s from 'assets/styles/Onboarding.styles';
import { ausStateObj } from 'config/constants';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useAppSelector } from 'redux/merchant/hooks';
import { getHumanReadableDate } from 'utils/dateUtils';

import { Heading, Text } from '@limepayments/cosmic';

interface CardProps {
  title: string;
  data: GetListMerchantPersonObj;
  hasOwner: boolean;
  showTitle: boolean;
}

const IndividualContent = ({ title, data, hasOwner, showTitle }: CardProps) => {
  const [firstFileDetail, setFirstFileDetail] = useState<GetMerchantFileResponse | null>(null);
  const [secondFileDetail, setSecondFileDetail] = useState<GetMerchantFileResponse | null>(null);

  const { apiBaseUri, merchantId } = useAppSelector((state) => ({
    apiBaseUri: state.config.apiBaseUri,
    merchantId: state.config.merchantId,
  }));

  const fetchFileDetail = useCallback(
    async (fileId: string, type: string) => {
      try {
        const response = await getMerchantFile(apiBaseUri, merchantId, fileId);

        type === 'front' ? setFirstFileDetail(response) : setSecondFileDetail(response);
      } catch (error) {}
    },
    [apiBaseUri, merchantId],
  );

  useEffect(() => {
    if (data.profile.verificationDocs.docFrontFileId) {
      fetchFileDetail(data.profile.verificationDocs.docFrontFileId, 'front');
    }
  }, [data.profile.verificationDocs.docFrontFileId, fetchFileDetail]);

  useEffect(() => {
    if (data.profile.verificationDocs.docBackFileId) {
      fetchFileDetail(data.profile.verificationDocs.docBackFileId, 'back');
    }
  }, [data.profile.verificationDocs.docBackFileId, fetchFileDetail]);

  return (
    <div className="content">
      <header className="content-header mb-24">
        <Heading alignment="left" tagName="h5" variant="xxs">
          {title}
        </Heading>
      </header>
      {(!hasOwner || !showTitle) && (
        <s.MultiColumnRespBlock className="mb-24" data-testid={`${data.personId}-job-title-div`}>
          <Text tagName="p" variant="body-3" testId={`${data.personId}-job-title-text`}>
            Job title
          </Text>
          <Text tagName="p" variant="body-3" testId={`${data.personId}-job-title-value`} isEmphasised>
            {data.profile.title}
          </Text>
        </s.MultiColumnRespBlock>
      )}
      <s.MultiColumnRespBlock className="mb-24" data-testid={`${data.personId}-first-name-div`}>
        <Text tagName="p" variant="body-3" testId={`${data.personId}-first-name-text`}>
          First name
        </Text>
        <Text tagName="p" variant="body-3" testId={`${data.personId}-first-name-value`} isEmphasised>
          {data.profile.firstName}
        </Text>
      </s.MultiColumnRespBlock>
      <s.MultiColumnRespBlock className="mb-24" data-testid={`${data.personId}-last-name-div`}>
        <Text tagName="p" variant="body-3" testId={`${data.personId}-last-name-text`}>
          Last name
        </Text>
        <Text tagName="p" variant="body-3" testId={`${data.personId}-last-name-text`} isEmphasised>
          {data.profile.lastName}
        </Text>
      </s.MultiColumnRespBlock>

      <s.MultiColumnRespBlock className="mb-24" data-testid={`${data.personId}-dob-div`}>
        <Text tagName="p" variant="body-3" testId={`${data.personId}-dob-text`}>
          Date of birth
        </Text>
        <Text tagName="p" variant="body-3" testId={`${data.personId}-dob-value`} isEmphasised>
          {getHumanReadableDate(data.profile.dob)}
        </Text>
      </s.MultiColumnRespBlock>
      <s.MultiColumnRespBlock className="mb-24" data-testid={`${data.personId}-country-div`}>
        <Text tagName="p" variant="body-3" testId={`${data.personId}-country-text`}>
          Country
        </Text>
        <Text tagName="p" variant="body-3" testId={`${data.personId}-country-value`} isEmphasised>
          {data.profile.address.country === `NZ` ? `New Zealand` : `Australia`}
        </Text>
      </s.MultiColumnRespBlock>
      <s.MultiColumnRespBlock className="mb-24" data-testid={`${data.personId}-unit-num-div`}>
        <Text tagName="p" variant="body-3" testId={`${data.personId}-unit-num-text`}>
          Unit number
        </Text>
        <Text
          tagName="p"
          variant="body-3"
          testId={`${data.personId}-unit-num-value`}
          isEmphasised
          className={!data.profile.address.unitNumber ? 'is-disabled' : ''}
        >
          {data.profile.address.unitNumber ? data.profile.address.unitNumber : `N/A`}
        </Text>
      </s.MultiColumnRespBlock>
      <s.MultiColumnRespBlock className="mb-24" data-testid={`${data.personId}-street-num-div`}>
        <Text tagName="p" variant="body-3" testId={`${data.personId}-street-num-text`}>
          Street number
        </Text>
        <Text tagName="p" variant="body-3" testId={`${data.personId}-street-num-value`} isEmphasised>
          {data.profile.address.streetNumber}
        </Text>
      </s.MultiColumnRespBlock>
      <s.MultiColumnRespBlock className="mb-24" data-testid={`${data.personId}-street-name-div`}>
        <Text tagName="p" variant="body-3" testId={`${data.personId}-street-name-text`}>
          Street name
        </Text>
        <Text tagName="p" variant="body-3" testId={`${data.personId}-street-name-value`} isEmphasised>
          {data.profile.address.streetName}
        </Text>
      </s.MultiColumnRespBlock>
      <s.MultiColumnRespBlock className="mb-24" data-testid={`${data.personId}-street-type-div`}>
        <Text tagName="p" variant="body-3" testId={`${data.personId}-street-type-text`}>
          Street type
        </Text>
        <Text tagName="p" variant="body-3" testId={`${data.personId}-street-type-value`} isEmphasised>
          {data.profile.address.streetType}
        </Text>
      </s.MultiColumnRespBlock>
      <s.MultiColumnRespBlock className="mb-24" data-testid={`${data.personId}-suburb-div`}>
        <Text tagName="p" variant="body-3" testId={`${data.personId}-suburb-text`}>
          Suburb
        </Text>
        <Text tagName="p" variant="body-3" testId={`${data.personId}-suburb-value`} isEmphasised>
          {data.profile.address.suburb}
        </Text>
      </s.MultiColumnRespBlock>
      {data.profile.address.country === `NZ` && (
        <s.MultiColumnRespBlock className="mb-24" data-testid={`${data.personId}-city-div`}>
          <Text tagName="p" variant="body-3" testId={`${data.personId}-city-text`}>
            City
          </Text>
          <Text tagName="p" variant="body-3" testId={`${data.personId}-city-value`} isEmphasised>
            {data.profile.address.city}
          </Text>
        </s.MultiColumnRespBlock>
      )}

      {data.profile.address.country === `AU` && (
        <s.MultiColumnRespBlock className="mb-24" data-testid={`${data.personId}-state-div`}>
          <Text tagName="p" variant="body-3" testId={`${data.personId}-state-text`}>
            State
          </Text>
          <Text tagName="p" variant="body-3" testId={`${data.personId}-state-value`} isEmphasised>
            {ausStateObj[data.profile.address.state as keyof typeof ausStateObj]}
          </Text>
        </s.MultiColumnRespBlock>
      )}
      <s.MultiColumnRespBlock className="mb-24" data-testid={`${data.personId}-postcode-div`}>
        <Text tagName="p" variant="body-3" testId={`${data.personId}-postcode-text`}>
          Postcode
        </Text>
        <Text tagName="p" variant="body-3" testId={`${data.personId}-postcode-value`} isEmphasised>
          {data.profile.address.postalCode}
        </Text>
      </s.MultiColumnRespBlock>
      <s.MultiColumnRespBlock className="mb-24" data-testid={`${data.personId}-id-type-div`}>
        <Text tagName="p" variant="body-3" testId={`${data.personId}-id-type-text`}>
          ID type
        </Text>
        <Text tagName="p" variant="body-3" testId={`${data.personId}-id-type-value`} isEmphasised>
          {data.profile.verificationDocs.idType === `Passport` ? `Passport` : `Driver License`}
        </Text>
      </s.MultiColumnRespBlock>
      <s.MultiColumnRespBlock className="mb-24" data-testid={`${data.personId}-doc-state-div`}>
        <Text tagName="p" variant="body-3" testId={`${data.personId}-doc-state-text`}>
          {data.profile.verificationDocs.idType === `Passport` ? `Passport country` : `Driver License state`}
        </Text>
        <Text tagName="p" variant="body-3" testId={`${data.personId}-doc-state-value`} isEmphasised>
          {data.profile.verificationDocs.idType === `Passport`
            ? data.profile.passport.country === `AU`
              ? `Australia`
              : `New Zealand`
            : ausStateObj[data.profile.driverLicence.state as keyof typeof ausStateObj]}
        </Text>
      </s.MultiColumnRespBlock>
      <s.MultiColumnRespBlock className="mb-24" data-testid={`${data.personId}-doc-number-div`}>
        <Text tagName="p" variant="body-3" testId={`${data.personId}-doc-number-text`}>
          {data.profile.verificationDocs.idType === `Passport` ? `Passport` : `Driver License`} number
        </Text>
        <Text tagName="p" variant="body-3" testId={`${data.personId}-doc-number-value`} isEmphasised>
          {data.profile.verificationDocs.idType === `Passport`
            ? data.profile.passport.passportNumber
            : data.profile.driverLicence.driverLicenceNumber}
        </Text>
      </s.MultiColumnRespBlock>
      {data.profile.verificationDocs.idType === `Passport` && (
        <s.MultiColumnRespBlock className="mb-24" data-testid={`${data.personId}-passport-exp-div`}>
          <Text tagName="p" variant="body-3" testId={`${data.personId}-passport-exp-text`}>
            Passport Date of expiry
          </Text>
          <Text tagName="p" variant="body-3" testId={`${data.personId}-passport-exp-value`} isEmphasised>
            {getHumanReadableDate(data.profile.passport.expiryDate)}
          </Text>
        </s.MultiColumnRespBlock>
      )}
      <s.MultiColumnRespBlock className="mb-24" data-testid={`${data.personId}-doc-1-div`}>
        <Text tagName="p" variant="body-3" testId={`${data.personId}-doc-1-text`}>
          {data.profile.verificationDocs.idType === `Passport` ? `Passport page` : `Driver License front`}
        </Text>
        <Text tagName="p" variant="body-3" testId={`${data.personId}-doc-1-value`} isEmphasised>
          {firstFileDetail ? firstFileDetail.fileName : 'N/A'}
        </Text>
      </s.MultiColumnRespBlock>
      {data.profile.verificationDocs.idType !== `Passport` && secondFileDetail && (
        <s.MultiColumnRespBlock className="mb-24" data-testid={`${data.personId}-doc-2-div`}>
          <Text tagName="p" variant="body-3" testId={`${data.personId}-doc-2-text`}>
            Driver License back
          </Text>
          <Text tagName="p" variant="body-3" testId={`${data.personId}-doc-2-value`} isEmphasised>
            {secondFileDetail.fileName}
          </Text>
        </s.MultiColumnRespBlock>
      )}

      {hasOwner && showTitle && (
        <Fragment>
          <s.MultiColumnRespBlock className="mb-24" data-testid={`${data.personId}owner-title-div`}>
            <Text tagName="p" variant="body-3" testId={`${data.personId}owner-title-text`}>
              Job title
            </Text>
            <Text tagName="p" variant="body-3" testId={`${data.personId}owner-title-value`} isEmphasised>
              {data.profile.title}
            </Text>
          </s.MultiColumnRespBlock>
          <s.MultiColumnRespBlock className="mb-24" data-testid={`${data.personId}-is-director-div`}>
            <Text tagName="p" variant="body-3" testId={`${data.personId}-is-director-text`}>
              Is a director
            </Text>
            <Text tagName="p" variant="body-3" testId={`${data.personId}-is-director-value`} isEmphasised>
              No
            </Text>
          </s.MultiColumnRespBlock>
        </Fragment>
      )}

      <s.MultiColumnRespBlock className="mb-24" data-testid={`${data.personId}-email-div`}>
        <Text tagName="p" variant="body-3" testId={`${data.personId}-email-text`}>
          Email address
        </Text>
        <Text tagName="p" variant="body-3" testId={`${data.personId}-email-value`} isEmphasised>
          {data.profile.email}
        </Text>
      </s.MultiColumnRespBlock>
      <s.MultiColumnRespBlock className="mb-24" data-testid={`${data.personId}-phone-div`}>
        <Text tagName="p" variant="body-3" testId={`${data.personId}-phone-text`}>
          Phone number
        </Text>
        <Text tagName="p" variant="body-3" testId={`${data.personId}-phone-value`} isEmphasised>
          {data.profile.phone}
        </Text>
      </s.MultiColumnRespBlock>
    </div>
  );
};

export default IndividualContent;
