import { validateContextualID } from 'api/platform/common';

export const capitalizeFirstLowercaseRest = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const isValidObjectId = async (stringToCheck: string, type: string, host: string) => {
  if (type === 'merchant' && !stringToCheck.includes('mcht_')) {
    return false;
  }

  if (type === 'transaction' && !stringToCheck.includes('tran_')) {
    return false;
  }

  if (type === 'payout' && !stringToCheck.includes('payout_')) {
    return false;
  }

  if (type === 'order' && !stringToCheck.includes('ordr_')) {
    return false;
  }

  try {
    await validateContextualID(host, stringToCheck);
    return true;
  } catch (err) {
    return false;
  }
};
