import { getPaymentsList } from 'api/platform/payments';
import { PlatformDashboardPaymentsObj } from 'api/platform/payments.types';
import PlatformLayout from 'layouts/platform/PlatformLayout';
import { DateTime } from 'luxon';
import ErrorComponent from 'pages/common/error/ErrorComponent';
import { MAX_FILTERING_DAYS } from 'pages/platform/constants';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { useAppDispatch, useAppSelector } from 'redux/platform/hooks';
import { setListingPagePropsObj, setOpenPaymentFilterDropdown } from 'redux/platform/slice/paymentSlice';
import getErrorMessage from 'utils/getErrorMessage';

import { Spinner } from '@limepayments/cosmic';

import PaymentsList from './partials/PaymentsList';

function PaymentPage() {
  const dispatch = useAppDispatch();
  const [fetchListLoader, setFetchListLoader] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [paymentList, setPaymentList] = useState<Array<PlatformDashboardPaymentsObj>>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [filterPopupOpen, setFilterPopupOpen] = useState<boolean>(false);

  const { marketplaceId, apiBaseUri, openPaymentFilterDropdown, paymentFilterObj, listingPageProps } = useAppSelector(
    (state) => ({
      marketplaceId: state.config.marketplaceId,
      apiBaseUri: state.config.apiBaseUri,
      openPaymentFilterDropdown: state.payment.openPaymentFilterDropdown,
      paymentFilterObj: state.payment.paymentFilterObj,
      listingPageProps: state.payment.listingPageProps,
    }),
  );

  const [lastFilterSaveTriggerAt, setLastFilterSaveTriggerAt] = useState<string>(paymentFilterObj.saveTiggerAt);

  const apiQueryString = useMemo(() => {
    let queryString = `page=${listingPageProps.activePage}&limit=${listingPageProps.limit}`;

    if (listingPageProps.sortBy) {
      queryString += `&sort=${listingPageProps.sortBy}`;
    }

    if (paymentFilterObj.dateTypeCompareValue) {
      let fromDate = '';
      let toDate = '';

      if (paymentFilterObj.dateTypeCompareValue === 'is_in_the_last') {
        if (paymentFilterObj.dateTypeFilterValue === 'days') {
          fromDate = `${DateTime.utc()
            .minus({ days: parseInt(paymentFilterObj.dateValue) })
            .startOf('day')
            .toISO()}`;
          toDate = `${DateTime.utc().endOf('day').toISO()}`;
        }

        if (paymentFilterObj.dateTypeFilterValue === 'month') {
          fromDate = `${DateTime.utc()
            .minus({ months: parseInt(paymentFilterObj.dateValue) })
            .startOf('day')
            .toISO()}`;
          toDate = `${DateTime.utc().endOf('day').toISO()}`;
        }
      }

      if (paymentFilterObj.dateTypeCompareValue === 'is_equal_to' && paymentFilterObj.dateSingleValue) {
        const fromDateObj = new Date(paymentFilterObj.dateSingleValue as unknown as string);
        fromDate = `${DateTime.fromISO(fromDateObj.toISOString()).toUTC().startOf('day').toISO()}`;
        toDate = `${DateTime.fromISO(fromDateObj.toISOString()).toUTC().endOf('day').toISO()}`;
      }

      if (
        paymentFilterObj.dateTypeCompareValue === 'is_between' &&
        paymentFilterObj.dateRangeValue &&
        Array.isArray(paymentFilterObj.dateRangeValue)
      ) {
        const fromDateObj = new Date(paymentFilterObj.dateRangeValue[0]);
        const toDateObj = new Date(paymentFilterObj.dateRangeValue[1]);

        fromDate = `${DateTime.fromISO(fromDateObj.toISOString()).toUTC().startOf('day').toISO()}`;
        toDate = `${DateTime.fromISO(toDateObj.toISOString()).toUTC().startOf('day').toISO()}`;
      }

      if (paymentFilterObj.dateTypeCompareValue === 'is_after' && paymentFilterObj.dateSingleValue) {
        const fromDateObj = new Date(paymentFilterObj.dateSingleValue as unknown as string);

        fromDate = `${DateTime.fromISO(fromDateObj.toISOString()).toUTC().endOf('day').toISO()}`;
        toDate = `${DateTime.fromISO(fromDateObj.toISOString())
          .toUTC()
          .plus({ months: MAX_FILTERING_DAYS })
          .endOf('day')
          .toISO()}`;
      }

      if (paymentFilterObj.dateTypeCompareValue === 'is_before' && paymentFilterObj.dateSingleValue) {
        const fromDateObj = new Date(paymentFilterObj.dateSingleValue as unknown as string);
        fromDate = `${DateTime.fromISO(fromDateObj.toISOString())
          .toUTC()
          .minus({ months: MAX_FILTERING_DAYS })
          .startOf('day')
          .toISO()}`;
        toDate = `${DateTime.fromISO(fromDateObj.toISOString()).toUTC().startOf('day').toISO()}`;
      }

      if (fromDate) {
        queryString += `&from=${encodeURIComponent(fromDate)}`;
      }

      if (toDate) {
        queryString += `&to=${encodeURIComponent(toDate)}`;
      }
    }

    if (paymentFilterObj.merchantId) {
      queryString += `&merchantId=${paymentFilterObj.merchantId}`;
    }

    if (paymentFilterObj.internalOrderId) {
      queryString += `&internalOrderId=${paymentFilterObj.internalOrderId}`;
    }

    if (paymentFilterObj.statusValue) {
      queryString += `&status=${paymentFilterObj.statusValue}`;
    }

    if (paymentFilterObj.txnId) {
      queryString += `&transactionId=${paymentFilterObj.txnId}`;
    }

    if (paymentFilterObj.amount) {
      queryString += `&amount=${parseFloat(paymentFilterObj.amount) * 100}`; // convert into cents
    }

    return queryString;
  }, [paymentFilterObj, listingPageProps]);

  const handleSort = (selectedColumn: TableColumn<PlatformDashboardPaymentsObj>, sortDirection: SortOrder) => {
    dispatch(
      setListingPagePropsObj({
        ...listingPageProps,
        activePage: 1,
        sortBy: selectedColumn.sortField
          ? `${sortDirection === 'asc' ? `` : `-`}${selectedColumn.sortField}`
          : '-createdAt',
      }),
    );
  };

  const toggleFilter = useCallback(() => {
    setFilterPopupOpen((wasOpened) => !wasOpened);
  }, []);

  const fetchPaymentsList = useCallback(async () => {
    try {
      setFetchListLoader(true);
      setErrorMessage('');

      const response = await getPaymentsList(apiBaseUri, marketplaceId, apiQueryString);

      setPaymentList(response.items);
      setTotalRows(response.totalRecords);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setFetchListLoader(false);
    }
  }, [apiBaseUri, marketplaceId, apiQueryString]);

  const handlePageChange = (page: number) => {
    dispatch(
      setListingPagePropsObj({
        ...listingPageProps,
        activePage: page,
      }),
    );
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    dispatch(
      setListingPagePropsObj({
        ...listingPageProps,
        activePage: page,
        limit: newPerPage,
      }),
    );
  };

  useEffect(() => {
    if (lastFilterSaveTriggerAt !== paymentFilterObj.saveTiggerAt) {
      setLastFilterSaveTriggerAt(paymentFilterObj.saveTiggerAt);
    }
  }, [apiBaseUri, dispatch, lastFilterSaveTriggerAt, marketplaceId, paymentFilterObj.saveTiggerAt]);

  useEffect(() => {
    fetchPaymentsList();
  }, [fetchPaymentsList]);

  useEffect(() => {
    if (!fetchListLoader) {
      setIsPageLoading(false);
    }
  }, [setIsPageLoading, fetchListLoader]);

  useEffect(() => {
    if (openPaymentFilterDropdown) {
      toggleFilter();
      setTimeout(() => {
        dispatch(setOpenPaymentFilterDropdown(false));
      }, 2000);
    }
  }, [openPaymentFilterDropdown, toggleFilter, dispatch]);

  return (
    <PlatformLayout activeTab="payment" title="Payments Listing">
      {!isPageLoading && errorMessage.length ? <ErrorComponent bodyText={errorMessage} /> : null}

      {isPageLoading && (
        <div className="spinner-wrapper">
          <Spinner variant="simple" isVisible label="Loading..." />
        </div>
      )}

      {!isPageLoading && (
        <PaymentsList
          paymentList={paymentList}
          fetchListLoader={fetchListLoader}
          totalRows={totalRows}
          handlePerRowsChange={handlePerRowsChange}
          handlePageChange={handlePageChange}
          limit={listingPageProps.limit}
          toggleFilter={toggleFilter}
          activePage={listingPageProps.activePage}
          handleSort={handleSort}
          filterPopupOpen={filterPopupOpen}
        />
      )}
    </PlatformLayout>
  );
}

export default PaymentPage;
