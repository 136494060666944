import { Heading, Illustration, Link, Text } from '@limepayments/cosmic';

interface Props {
  email: string;
}

function AccountCheckEmailSendView({ email }: Props) {
  return (
    <>
      <div className="size-136 mx-auto mb-32">
        <Illustration name="Email" size="large" />
      </div>
      <div className="lp-w-full mb-32">
        <Heading alignment="center" tagName="h1" variant="md" className="mb-12">
          Check your email
        </Heading>
        <Text tagName="p" variant="body-3" alignment="center">
          We've sent your account activation link to <Link className="fw-medium">{email}</Link>.
        </Text>
      </div>
      <Text tagName="p" variant="body-3" alignment="center">
        Didn't receive an email? Check your spam folder or{' '}
        <Link target="_self" size="small" className="fw-semibold">
          contact support
        </Link>
        .
      </Text>
    </>
  );
}

export default AccountCheckEmailSendView;
