import { GetListMerchantCompanyOwnersObj } from 'api/merchant/onbording/step-05.types';
import * as s from 'assets/styles/Onboarding.styles';

import { Heading, Text } from '@limepayments/cosmic';

interface CardProps {
  title: string;
  data: GetListMerchantCompanyOwnersObj;
}

const CompanyContent = ({ title, data }: CardProps) => {
  return (
    <div className="content">
      <header className="content-header mb-24">
        <Heading alignment="left" tagName="h5" variant="xxs">
          {title}
        </Heading>
      </header>
      <s.MultiColumnRespBlock className="mb-24" data-testid={`${data.profile}-business-regis-div`}>
        <Text tagName="p" variant="body-3" testId={`${data.profile}-business-regis-text`}>
          Business registration number
        </Text>
        <Text tagName="p" variant="body-3" testId={`${data.profile}-business-regis-value`} isEmphasised>
          {data.profile.taxId}
        </Text>
      </s.MultiColumnRespBlock>
      <s.MultiColumnRespBlock className="mb-24" data-testid={`${data.profile}-legal-entity-div`}>
        <Text tagName="p" variant="body-3" testId={`${data.profile}-legal-entity-text`}>
          Legal entity name
        </Text>
        <Text tagName="p" variant="body-3" testId={`${data.profile}-legal-entity-value`} isEmphasised>
          {data.profile.businessName}
        </Text>
      </s.MultiColumnRespBlock>

      <s.MultiColumnRespBlock className="mb-24" data-testid={`${data.profile}-cmpny-type-div`}>
        <Text tagName="p" variant="body-3" testId={`${data.profile}-cmpny-type-text`}>
          Company type
        </Text>
        <Text tagName="p" variant="body-3" testId={`${data.profile}-cmpny-type-value`} isEmphasised>
          {data.profile.companyType}
        </Text>
      </s.MultiColumnRespBlock>
    </div>
  );
};

export default CompanyContent;
