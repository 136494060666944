import { getMerchantFile } from 'api/merchant/onbording/file';
import { GetMerchantFileResponse } from 'api/merchant/onbording/file.types';
import { getMerchantBusinessRegistration } from 'api/merchant/onbording/step-01';
import { getMerchantCustomerFacingDetail } from 'api/merchant/onbording/step-02';
import { getMerchantBusinessContact } from 'api/merchant/onbording/step-03';
import { getMerchantExternalBankAccount } from 'api/merchant/onbording/step-04';
import { GetMerchantExternalBankAccountObj } from 'api/merchant/onbording/step-04.types';
import {
  getListMerchantCompanyOwners,
  getListMerchantPartnershipOwners,
  getListMerchantPerson,
  getListMerchantTrustOwners,
} from 'api/merchant/onbording/step-05';
import {
  GetListMerchantCompanyOwnersObj,
  GetListMerchantPartnershipOwnersObj,
  GetListMerchantPersonObj,
  GetListMerchantTrustOwnersObj,
} from 'api/merchant/onbording/step-05.types';
import { updateMerchantOnboardingReview } from 'api/merchant/onbording/step-06';
import MerchantLayout from 'layouts/merchant/MerchantLayout';
import ErrorComponent from 'pages/common/error/ErrorComponent';
import AuthRoutesList from 'pages/merchant/auth/constants';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/merchant/hooks';
import {
  setCurrentActiveStep,
  setHasCompletedSteps,
  setSaveAndFinishLater,
} from 'redux/merchant/slice/merchantOnboardSlice';
import { getMerchantBaseUrl } from 'utils/getBaseUrl';
import getErrorMessage from 'utils/getErrorMessage';

import { Spinner } from '@limepayments/cosmic';

import OnboardingRoutesList from '../constants';
import { UpdateMerchantBusinessRegistrationPayload } from '../step-01/types';
import { UpdateMerchantCustomerFacingDetailPayload } from '../step-02/types';
import { UpdateMerchantBusinessContactPayload } from '../step-03/types';
import BusinessDetailsStep06 from './BusinessDetailsStep06';

function OnboardingStep06Page() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [fetchBusinessDetailLoader, setFetchBusinessDetailLoader] = useState<boolean>(true);
  const [customerFacingDetailLoader, setCustomerFacingDetailLoader] = useState<boolean>(true);
  const [businessContactLoader, setBusinessContactLoader] = useState<boolean>(true);
  const [externalBankAccountLoader, setExternalBankAccountLoader] = useState<boolean>(true);
  const [ownerListLoader, setOwnerListLoader] = useState<boolean>(true);
  const [hasOwners, setHasOwners] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [businessDetail, setBusinessDetail] = useState<UpdateMerchantBusinessRegistrationPayload>({
    businessName: '',
    businessType: '',
    companyType: '',
    industry: '',
    taxId: '',
    verificationDocs: {
      companyBackFileId: '',
      companyFrontFileId: '',
      companyTaxIdFileId: '',
      partnershipAgreementFileId: '',
      trustDeedFileId: '',
    },
  });
  const [businessFileDetail, setBusinessFileDetail] = useState<GetMerchantFileResponse | null>(null);
  const [customerFacingDetail, setCustomerFacingDetail] = useState<UpdateMerchantCustomerFacingDetailPayload>({
    brandName: '',
    cardStatementName: '',
    smsSenderName: '',
    website: '',
    supportSite: '',
  });
  const [businessContact, setBusinessContact] = useState<UpdateMerchantBusinessContactPayload>({
    contactPerson: {
      firstName: '',
      lastName: '',
      title: '',
    },
    contactEmail: '',
    supportEmail: '',
    phoneNo: '',
    address: {
      unitNumber: '',
      streetNumber: '',
      streetName: '',
      streetType: '',
      suburb: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
    },
  });
  const [existingMerchantDetail, setExistingMerchantDetail] = useState<Array<GetMerchantExternalBankAccountObj>>([]);
  const [merchantPersonList, setMerchantPersonList] = useState<Array<GetListMerchantPersonObj>>([]);
  const [merchantCompanyOwnersList, setMerchantCompanyOwnersList] = useState<Array<GetListMerchantCompanyOwnersObj>>(
    [],
  );
  const [merchantPartnershipOwnersList, setMerchantPartnershipOwnersList] = useState<
    Array<GetListMerchantPartnershipOwnersObj>
  >([]);
  const [merchantTrustOwnersList, setMerchantTrustOwnersList] = useState<Array<GetListMerchantTrustOwnersObj>>([]);
  const [hideRepresentative, setHideRepresentative] = useState<boolean>(false);
  const [onboardingReviewLoader, setOnboardingReviewLoader] = useState<boolean>(false);
  const [reviewRequestError, setReviewRequestError] = useState<string>('');

  const { merchantName, apiBaseUri, merchantId, saveAndFinishLater, hasCompletedSteps } = useAppSelector((state) => ({
    merchantName: state.config.merchantName,
    apiBaseUri: state.config.apiBaseUri,
    merchantId: state.config.merchantId,
    saveAndFinishLater: state.onboarding.saveAndFinishLater,
    hasCompletedSteps: state.onboarding.hasCompletedSteps,
  }));

  const submitHandler = async () => {
    try {
      setOnboardingReviewLoader(true);
      setReviewRequestError('');

      await updateMerchantOnboardingReview(apiBaseUri, merchantId);

      navigate(`${getMerchantBaseUrl(merchantName)}/onboarding/${OnboardingRoutesList.ONBOARDING_STEP07_PAGE}`, {
        state: {
          businessType: businessDetail.businessType,
          hasTrustOwner: merchantTrustOwnersList.length ? true : false,
        },
      });
    } catch (err) {
      setReviewRequestError(getErrorMessage(err));
    } finally {
      setOnboardingReviewLoader(false);
    }
  };

  useEffect(() => {
    const fetchFileDetail = async (fileId: string) => {
      try {
        const response = await getMerchantFile(apiBaseUri, merchantId, fileId);
        return response;
      } catch (error) {
        setErrorMessage(getErrorMessage(error));
      }

      return false;
    };

    const fetchBusinessDetails = async () => {
      try {
        setFetchBusinessDetailLoader(true);

        const response = await getMerchantBusinessRegistration(apiBaseUri, merchantId);
        setBusinessDetail({ ...response });

        if (response.businessType !== 'trust' && response.businessType !== 'partnership') {
          fetchOwnerListHandler();
        } else {
          setOwnerListLoader(false);
          setHideRepresentative(true);
          const fileDetailResponse = await fetchFileDetail(
            response.verificationDocs.partnershipAgreementFileId
              ? response.verificationDocs.partnershipAgreementFileId
              : response.verificationDocs.trustDeedFileId
              ? response.verificationDocs.trustDeedFileId
              : '',
          );
          setBusinessFileDetail(fileDetailResponse ? fileDetailResponse : null);
        }
      } catch (error) {
        setErrorMessage(getErrorMessage(error));
      } finally {
        setFetchBusinessDetailLoader(false);
      }
    };

    const fetchCustomerFacingDetail = async () => {
      try {
        setCustomerFacingDetailLoader(true);

        const response = await getMerchantCustomerFacingDetail(apiBaseUri, merchantId);
        setCustomerFacingDetail({ ...response });
      } catch (error) {
        setErrorMessage(getErrorMessage(error));
      } finally {
        setCustomerFacingDetailLoader(false);
      }
    };

    const fetchBusinessContact = async () => {
      try {
        setBusinessContactLoader(true);

        const response = await getMerchantBusinessContact(apiBaseUri, merchantId);
        setBusinessContact({ ...response });
      } catch (error) {
        setErrorMessage(getErrorMessage(error));
      } finally {
        setBusinessContactLoader(false);
      }
    };

    const fetchExternalBankAccount = async () => {
      try {
        setExternalBankAccountLoader(true);

        const response = await getMerchantExternalBankAccount(apiBaseUri, merchantId);

        if (response.length > 0) {
          setExistingMerchantDetail(response.filter((bankDetail) => bankDetail.isDefaultForCurrency));
        }
      } catch (error) {
        setErrorMessage(getErrorMessage(error));
      } finally {
        setExternalBankAccountLoader(false);
      }
    };

    const fetchOwnerListHandler = async () => {
      try {
        setOwnerListLoader(true);

        const response = await Promise.allSettled([
          await getListMerchantPerson(apiBaseUri, merchantId),
          await getListMerchantCompanyOwners(apiBaseUri, merchantId),
          await getListMerchantPartnershipOwners(apiBaseUri, merchantId),
          await getListMerchantTrustOwners(apiBaseUri, merchantId),
        ]);

        // Individual or Director or Public company type
        if (response[0].status === 'fulfilled') {
          const personResponse = response[0] as PromiseFulfilledResult<GetListMerchantPersonObj[]>;
          setMerchantPersonList(personResponse.value);
          if (personResponse.value.length > 0) {
            const directorRecords = personResponse.value.filter((obj: GetListMerchantPersonObj) => {
              return obj.profile.roles.includes('Director');
            });

            setHasOwners(directorRecords.length > 0 ? false : true);
          }
        }

        // COMPANIES
        if (response[1].status === 'fulfilled') {
          const companyResponse = response[1] as PromiseFulfilledResult<GetListMerchantCompanyOwnersObj[]>;
          setMerchantCompanyOwnersList(companyResponse.value);
        }

        // Partnership
        if (response[2].status === 'fulfilled') {
          const partnershipResponse = response[2] as PromiseFulfilledResult<GetListMerchantPartnershipOwnersObj[]>;
          setMerchantPartnershipOwnersList(partnershipResponse.value);
        }

        // TRUST
        if (response[3].status === 'fulfilled') {
          const trustResponse = response[3] as PromiseFulfilledResult<GetListMerchantTrustOwnersObj[]>;
          setMerchantTrustOwnersList(trustResponse.value);
        }
      } catch (error) {
        setErrorMessage(getErrorMessage(error));
      } finally {
        setOwnerListLoader(false);
      }
    };

    setErrorMessage('');
    fetchBusinessDetails();
    fetchCustomerFacingDetail();
    fetchBusinessContact();
    fetchExternalBankAccount();
  }, [apiBaseUri, merchantName, merchantId]);

  useEffect(() => {
    if (
      !fetchBusinessDetailLoader &&
      !customerFacingDetailLoader &&
      !businessContactLoader &&
      !externalBankAccountLoader &&
      !ownerListLoader
    ) {
      setIsPageLoading(false);
      dispatch(setCurrentActiveStep(7));
    }
  }, [
    setIsPageLoading,
    fetchBusinessDetailLoader,
    customerFacingDetailLoader,
    businessContactLoader,
    externalBankAccountLoader,
    ownerListLoader,
    dispatch,
  ]);

  useEffect(() => {
    if (saveAndFinishLater && merchantName) {
      navigate(`${getMerchantBaseUrl(merchantName)}/${AuthRoutesList.LOGOUT_PAGE}`);
    }
  }, [saveAndFinishLater, merchantName, navigate]);

  useEffect(() => {
    if (!hasCompletedSteps) {
      dispatch(setHasCompletedSteps(true));
    }
  }, [hasCompletedSteps, dispatch]);

  return (
    <MerchantLayout activeStepNumber={6} title="Onboarding - Verify your business details">
      {!isPageLoading && errorMessage.length ? <ErrorComponent bodyText={errorMessage} /> : null}

      {isPageLoading && (
        <div className="spinner-wrapper">
          <Spinner variant="simple" isVisible label="Loading..." />
        </div>
      )}

      {!isPageLoading && !errorMessage.length && (
        <BusinessDetailsStep06
          onSubmitClick={() => submitHandler()}
          onPreviousClick={() => {
            navigate(`${getMerchantBaseUrl(merchantName)}/onboarding/${OnboardingRoutesList.ONBOARDING_STEP05_PAGE}`);
          }}
          businessDetail={businessDetail}
          customerFacingDetail={customerFacingDetail}
          businessContact={businessContact}
          existingMerchantDetail={existingMerchantDetail}
          merchantPersonList={merchantPersonList}
          merchantCompanyOwnersList={merchantCompanyOwnersList}
          merchantPartnershipOwnersList={merchantPartnershipOwnersList}
          merchantTrustOwnersList={merchantTrustOwnersList}
          hasOwner={hasOwners}
          hideRepresentative={hideRepresentative}
          businessFileDetail={businessFileDetail}
          saveAndFinishLater={() => {
            dispatch(setSaveAndFinishLater(Date.now()));
          }}
          onboardingReviewLoader={onboardingReviewLoader}
          reviewRequestError={reviewRequestError}
        />
      )}
    </MerchantLayout>
  );
}

export default OnboardingStep06Page;
