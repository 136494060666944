import { GetMerchantFileResponse } from 'api/merchant/onbording/file.types';
import { GetMerchantExternalBankAccountObj } from 'api/merchant/onbording/step-04.types';
import {
  GetListMerchantCompanyOwnersObj,
  GetListMerchantPartnershipOwnersObj,
  GetListMerchantPersonObj,
  GetListMerchantTrustOwnersObj,
} from 'api/merchant/onbording/step-05.types';
import * as s from 'assets/styles/Onboarding.styles';
import { Fragment, useEffect, useRef } from 'react';
import scrollToComponent from 'react-scroll-to-component';

import { Button, Heading, Icon, Message, Text } from '@limepayments/cosmic';

import { UpdateMerchantBusinessRegistrationPayload } from '../step-01/types';
import { UpdateMerchantCustomerFacingDetailPayload } from '../step-02/types';
import { UpdateMerchantBusinessContactPayload } from '../step-03/types';
import BusinessBankAccount from './_partials/BusinessBankAccount';
import BusinessContactDetails from './_partials/BusinessContactDetails';
import BusinessRepresentativeDetails from './_partials/BusinessRepresentativeDetails';
import BusinessRepresentatives from './_partials/BusinessRepresentatives';
import CustomerFacingDetails from './_partials/CustomerFacingDetails';
import OfficialBusinessDetails from './_partials/OfficialBusinessDetails';

interface Props {
  onSubmitClick: () => void;
  onPreviousClick: () => void;
  businessDetail: UpdateMerchantBusinessRegistrationPayload;
  customerFacingDetail: UpdateMerchantCustomerFacingDetailPayload;
  businessContact: UpdateMerchantBusinessContactPayload;
  existingMerchantDetail: Array<GetMerchantExternalBankAccountObj>;
  hasOwner: boolean;
  merchantPersonList: Array<GetListMerchantPersonObj>;
  merchantCompanyOwnersList: Array<GetListMerchantCompanyOwnersObj>;
  merchantPartnershipOwnersList: Array<GetListMerchantPartnershipOwnersObj>;
  merchantTrustOwnersList: Array<GetListMerchantTrustOwnersObj>;
  hideRepresentative: boolean;
  businessFileDetail: GetMerchantFileResponse | null;
  saveAndFinishLater: () => void;
  onboardingReviewLoader: boolean;
  reviewRequestError: string;
}

function BusinessDetailsStep06({
  onSubmitClick,
  onPreviousClick,
  businessDetail,
  customerFacingDetail,
  businessContact,
  existingMerchantDetail,
  hasOwner,
  merchantPersonList,
  merchantCompanyOwnersList,
  merchantPartnershipOwnersList,
  merchantTrustOwnersList,
  hideRepresentative,
  businessFileDetail,
  saveAndFinishLater,
  onboardingReviewLoader,
  reviewRequestError,
}: Props) {
  const errorContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (reviewRequestError.length > 0 && errorContainerRef && errorContainerRef.current) {
      scrollToComponent(errorContainerRef.current, { duration: 500 });
    }
  }, [reviewRequestError]);

  return (
    <div className="content-wrap-inner">
      <header className="content-header">
        <div className="lp-w-full mb-24">
          <div className="lp-w-full mb-16">
            <Button
              size="small"
              variant="ghost"
              isNeutral
              className="back-button"
              type="button"
              onClick={onPreviousClick}
            >
              <Icon name="ArrowLeft" className="back-arrow" />
              Back
            </Button>
          </div>
          <Heading alignment="left" tagName="h2" variant="md" className="mb-24">
            Verify your business details
          </Heading>
          {reviewRequestError.length > 0 ? (
            <div className="mt-24 mb-24" ref={errorContainerRef}>
              <Message
                type="inline"
                children={<span className="text-wrap">{reviewRequestError}</span>}
                variant={'error'}
              />
            </div>
          ) : null}
          <Heading alignment="left" tagName="h4" variant="sm" className="mb-24">
            Review and submit
          </Heading>
          <Text tagName="p" variant="body-3">
            Review the details completed in the form and submit your details.
          </Text>
        </div>
      </header>

      <div className="content-main">
        <s.Separator>
          <hr />
        </s.Separator>
        <OfficialBusinessDetails businessDetail={businessDetail} businessFileDetail={businessFileDetail} />
        <CustomerFacingDetails customerFacingDetail={customerFacingDetail} />
        <BusinessContactDetails businessContact={businessContact} />
        <BusinessBankAccount existingMerchantDetail={existingMerchantDetail} />
        {!hideRepresentative && (
          <Fragment>
            <BusinessRepresentatives
              hasOwner={hasOwner}
              businessType={businessDetail.businessType}
              companyType={businessDetail.companyType}
            />
            {merchantPersonList.length +
              merchantCompanyOwnersList.length +
              merchantPartnershipOwnersList.length +
              merchantTrustOwnersList.length >
              0 && (
              <BusinessRepresentativeDetails
                merchantPersonList={merchantPersonList}
                merchantCompanyOwnersList={merchantCompanyOwnersList}
                merchantPartnershipOwnersList={merchantPartnershipOwnersList}
                merchantTrustOwnersList={merchantTrustOwnersList}
                hasOwner={hasOwner}
                showTitle={businessDetail.businessType !== 'soletrader' && businessDetail.companyType !== 'Public'}
              />
            )}
          </Fragment>
        )}

        <Text tagName="p" variant="body-3" testId="terms-text">
          By submitting this form you are confirming that the details you are providing are correct.
        </Text>
      </div>

      <s.Cta className="lp-flex lp-justify-end lp-items-center sm:flex-col">
        <Button
          onClick={onSubmitClick}
          size="medium"
          variant="primary"
          className="no-min-width lp-full"
          disabled={onboardingReviewLoader}
          isLoading={onboardingReviewLoader}
        >
          Submit details
        </Button>
        <div className="mobile-sidebar-footer">
          <Button size="medium" onClick={saveAndFinishLater} variant="ghost" className="lp-w-full">
            <Icon name="Save" />
            Save and finish later
          </Button>
        </div>
      </s.Cta>
    </div>
  );
}

export default BusinessDetailsStep06;
