import * as s from 'assets/styles/Platforms.styles';
import { Fragment, useEffect, useState } from 'react';

import { Button, Heading, Illustration, Modal, Text } from '@limepayments/cosmic';

interface Props {
  isOpen: boolean;
  modalToggler: (isOpen: boolean) => void;
}

function ManualVerificationModal({ isOpen, modalToggler }: Props) {
  const [activeSection, setActiveSection] = useState<number>(0);

  useEffect(() => {
    if (!isOpen) {
      setActiveSection(0);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} className="modal-medium update-pass-modal">
      <s.Wrapper className="">
        <div className="modal-main p-24">
          <div className="modal-header">
            <div className="content-main bb-1">
              {activeSection === 0 && (
                <Heading
                  alignment="center"
                  tagName="h2"
                  variant="xxs"
                  className="mt-12 mb-24 lp-justify-center lp-flex"
                >
                  Manual Verification of Card User
                </Heading>
              )}

              {activeSection === 1 && (
                <Heading
                  alignment="center"
                  tagName="h2"
                  variant="xxs"
                  className="mt-12 mb-24 lp-justify-center lp-flex"
                >
                  Reset status to “unverified”
                </Heading>
              )}

              {activeSection === 2 && (
                <Heading
                  alignment="center"
                  tagName="h2"
                  variant="xxs"
                  className="mt-12 mb-24 lp-justify-center lp-flex"
                >
                  Unable to create a pre-authorised charge
                </Heading>
              )}
            </div>
          </div>

          {/* Begin Step 01 Content */}
          {activeSection === 0 && (
            <Fragment>
              <div className="modal-content sm-p-0 sm-pt-24">
                <div className="lp-w-full mb-16">
                  <Text tagName="p" variant="body-3" className="text-dark-50 m-0 mt-0">
                    Verify amount
                  </Text>
                  <Heading alignment="left" tagName="h1" variant="lg">
                    $0.51
                  </Heading>
                </div>
                <div className="lp-w-full mb-16">
                  <Text alignment="left" tagName="p" variant="body-3" className="text-dark-50 mb-0">
                    <strong>Do not disclose this number to your customer.</strong> Your customer should be able to
                    verify to you the exact amount displayed above as it is displayed on their payment source’s
                    statement.
                  </Text>
                </div>
                <div className="lp-w-full mb-16">
                  <Text tagName="p" variant="caption" className="text-dark-50 m-0 mt-0">
                    Customer details
                  </Text>
                  <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                    Deb Amos
                  </Text>
                  <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                    deb@gmail.com
                  </Text>
                  <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                    0400000000
                  </Text>
                </div>
              </div>
              <div className="modal-footer lp-flex lp-justify-end sm-mt-24">
                <div className="lp-flex sm:flex-col sm:justify-end sm:align-end sm:lp-align-end">
                  <div className="lp-flex lp-align-center sm:mb-12">
                    <Button
                      onClick={() => modalToggler(false)}
                      type="button"
                      size="medium"
                      variant="ghost"
                      className="no-min-width"
                    >
                      Close
                    </Button>
                    <Button type="button" size="medium" variant="destructive" className="no-min-width">
                      Cannot verify
                    </Button>
                  </div>
                  <Button
                    type="button"
                    size="medium"
                    variant="primary"
                    className="no-min-width"
                    onClick={() => setActiveSection(activeSection + 1)}
                  >
                    Verify card user
                  </Button>
                </div>
              </div>
            </Fragment>
          )}
          {/* //Ends Step 01 Content */}

          {activeSection === 1 && (
            <form autoComplete="off">
              <div className="modal-content sm-p-0 sm-mt-24">
                <div className="">
                  <div className="lp-w-full lp-flex mb-24">
                    <div className="lp-w-full lp-flex lp-flex-column lp-align-center lp-justify-center">
                      <div className="illustration-90">
                        <Illustration name="Warning" size="medium" />
                      </div>
                      <div className="w-75">
                        {/* <Text alignment="center" tagName="p" variant="body-3" className="text-dark-50 mb-0">
                        We were unable to create a pre-authorised charge on this card user’s payment source due to [
                        <strong>insufficient funds</strong>]. Please contact the customer to resolve this before trying
                        to verify this card user again.
                      </Text> */}
                        <Text alignment="center" tagName="p" variant="body-3" className="text-dark-50 mb-0">
                          Are you sure you wish to reset the verification status of this card user to "
                          <strong>unverified</strong>"?
                        </Text>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer lp-flex lp-justify-end sm-mt-24">
                <Button
                  onClick={() => modalToggler(false)}
                  type="button"
                  size="medium"
                  variant="ghost"
                  className="no-min-width"
                >
                  Cancel
                </Button>

                <Button
                  type="button"
                  size="medium"
                  variant="destructive"
                  className="no-min-width"
                  onClick={() => setActiveSection(activeSection + 1)}
                >
                  Yes, reset status
                </Button>
              </div>
            </form>
          )}

          {activeSection === 2 && (
            <form autoComplete="off">
              <div className="modal-content sm-p-0 sm-mt-24">
                <div className="">
                  <div className="lp-w-full lp-flex mb-24">
                    <div className="lp-w-full lp-flex lp-flex-column lp-align-center lp-justify-center">
                      <div className="illustration-90">
                        <Illustration name="Warning" size="medium" />
                      </div>
                      <div className="w-75">
                        <Text alignment="center" tagName="p" variant="body-3" className="text-dark-50 mb-0">
                          We were unable to create a pre-authorised charge on this card user’s payment source due to [
                          <strong>insufficient funds</strong>]. Please contact the customer to resolve this before
                          trying to verify this card user again.
                        </Text>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer lp-flex lp-justify-end sm-mt-24">
                <Button
                  type="button"
                  size="medium"
                  variant="primary"
                  className="no-min-width"
                  onClick={() => modalToggler(false)}
                >
                  Ok
                </Button>
              </div>
            </form>
          )}
        </div>
      </s.Wrapper>
    </Modal>
  );
}

export default ManualVerificationModal;
