import { addressToString } from 'api/fragments';
import { MerchantGeneralSettingsResponse, StandardMerchant } from 'api/merchant/dashboard/merchants.types';
import { FormikProps } from 'formik';
import ErrorComponent from 'pages/common/error/ErrorComponent';
import { Fragment, RefObject } from 'react';

import { Button, Heading, Icon, LabelledValue, Spinner, Text } from '@limepayments/cosmic';

import * as s from '../../../../../../assets/styles/Platforms.styles';
import SettingRoutesList from '../../constants';
// Import Static UIs
import SettingsSidebar from '../../partials/SettingsSidebar';
import EditBusinessDetailsModal from './EditBusinessDetailsModal';
import { customerTypeDescriptions } from './constants';
import { BusinessProfileModalForm } from './types';

export interface Props {
  merchantDetails: StandardMerchant;
  generalSettings: MerchantGeneralSettingsResponse;
  onSubmitClick: (values: BusinessProfileModalForm, formObj: { resetForm: () => void }) => void;
  formRef: RefObject<FormikProps<BusinessProfileModalForm>>;
  errorMsg: string;
  editRequestLoading: boolean;
  editModalOpen: boolean;
  setEditModalOpen: (val: boolean) => void;
  isPageLoading: boolean;
  errorMessage: string;
  businessDetailModalShow?: boolean;
  isManualEnterAddress: boolean;
}

function BusinessProfileSettingView({
  merchantDetails,
  generalSettings,
  onSubmitClick,
  formRef,
  errorMsg,
  editModalOpen,
  editRequestLoading,
  setEditModalOpen,
  isPageLoading,
  errorMessage,
  businessDetailModalShow,
  isManualEnterAddress,
}: Props) {
  const businessProfile =
    'StandardMerchant' in merchantDetails ? merchantDetails.StandardMerchant?.businessProfile : null;

  return (
    <Fragment>
      <div className="content-wrap-inner with-gutter">
        <div className="lp-w-full lp-flex">
          <div className="payment-sidebar">
            <SettingsSidebar activeTab={SettingRoutesList.SETTINGS_BUSINESS_PROFILE} />
          </div>

          <div className="payment-content-area">
            {!isPageLoading && errorMessage.length ? <ErrorComponent bodyText={errorMessage} /> : null}

            {isPageLoading && (
              <div className="spinner-wrapper">
                <Spinner variant="simple" isVisible label="Loading..." />
              </div>
            )}

            {!isPageLoading && !errorMessage.length && (
              <Fragment>
                <div className="lp-w-full lp-flex lp-justify-between sm-lp-flex-col mb-32">
                  <Heading alignment="left" tagName="h2" variant="sm">
                    Business profile
                  </Heading>
                </div>

                <s.MerchantsData className="bt-1">
                  <div className="lp-w-full lp-flex lp-align-start lp-justify-between mb-32">
                    <div>
                      <Heading alignment="left" tagName="h2" variant="xs">
                        Business contact details
                      </Heading>
                      <Text variant="body-3" className="mt-4">
                        Contact details for ongoing engagements.
                      </Text>
                    </div>
                    <div className="ml-auto">
                      <Button size="small" variant="reversed" onClick={() => setEditModalOpen(!editModalOpen)}>
                        Edit
                        <Icon name="Edit" />
                      </Button>
                    </div>
                  </div>
                  <div className="lp-w-full mb-32">
                    <LabelledValue
                      label="First name"
                      value={businessProfile?.contactPerson?.givenName ?? ''}
                      variant="vertical"
                    />
                  </div>
                  <div className="lp-w-full mb-32">
                    <LabelledValue
                      label="Last name"
                      value={businessProfile?.contactPerson?.familyName ?? ''}
                      variant="vertical"
                    />
                  </div>
                  <div className="lp-w-full mb-32">
                    <LabelledValue
                      label="Job title"
                      value={businessProfile?.contactPerson?.title ?? ''}
                      variant="vertical"
                    />
                  </div>
                  <div className="lp-w-full mb-32">
                    <LabelledValue
                      label="Contact email address"
                      value={businessProfile?.contactEmailAddress ?? ''}
                      variant="vertical"
                    />
                  </div>
                  <div className="lp-w-full mb-32">
                    <LabelledValue
                      label="Contact phone number"
                      value={businessProfile?.phoneNumber ?? ''}
                      variant="vertical"
                    />
                  </div>
                  <div className="lp-w-full mb-32">
                    <LabelledValue
                      label="Business address"
                      value={addressToString(businessProfile?.address)}
                      variant="vertical"
                    />
                  </div>
                </s.MerchantsData>

                <s.MerchantsData className="bt-1">
                  <div className="lp-w-full mb-32">
                    <Heading alignment="left" tagName="h2" variant="xs">
                      Business registration details
                    </Heading>
                    <Text variant="body-3" className="mt-4">
                      The official details of your business.
                    </Text>
                  </div>
                  <div className="lp-w-full mb-32">
                    <LabelledValue label="Tax ID" value={businessProfile?.taxId ?? ''} variant="vertical" />
                  </div>
                  <div className="lp-w-full mb-32">
                    <LabelledValue
                      label="Legal business name"
                      value={businessProfile?.businessName ?? ''}
                      variant="vertical"
                    />
                  </div>
                  <div className="lp-w-full mb-32">
                    <LabelledValue
                      label="Business type"
                      value={businessProfile?.businessType ?? ''}
                      variant="vertical"
                    />
                  </div>
                  <div className="lp-w-full mb-32">
                    <LabelledValue
                      label="Business industry"
                      value={businessProfile?.industry ?? ''}
                      variant="vertical"
                    />
                  </div>
                  {/*
                  TODO: Requires API changes to fetch "Supported customer types" data
                  */}
                  <div className="lp-w-full mb-0">
                    <div className="lp-flex lp-align-center">
                      <Text variant="caption" className="text-dark-50 m-0">
                        Supported customer types
                      </Text>
                      <span
                        data-balloon="Consumers and businesses have different eligibility checks for payment plans."
                        data-balloon-pos="up"
                        data-balloon-length="large"
                        className="lp-flex lp-align-center lp-justify-center size-11"
                      >
                        <Icon name="Info" />
                      </span>
                    </div>
                    {generalSettings.supportedCustomerTypes.length &&
                      generalSettings.supportedCustomerTypes.map((custType) => (
                        <Text variant="body-3" className="mt-0">
                          {customerTypeDescriptions[custType]}
                        </Text>
                      ))}
                  </div>
                </s.MerchantsData>
              </Fragment>
            )}
          </div>
        </div>
      </div>

      <EditBusinessDetailsModal
        isOpen={businessDetailModalShow ?? editModalOpen}
        modalToggler={setEditModalOpen}
        merchantDetails={merchantDetails}
        onSubmitClick={onSubmitClick}
        formRef={formRef}
        errorMsg={errorMsg}
        editRequestLoading={editRequestLoading}
        isManualEnterAddress={isManualEnterAddress}
      />
    </Fragment>
  );
}

export default BusinessProfileSettingView;
