import ErrorLayout from 'layouts/error/ErrorLayout';

interface Props {
  title?: string;
  bodyTitle?: string;
  bodyText: string;
}

function ErrorPage({ title, bodyTitle, bodyText }: Props) {
  bodyTitle = bodyTitle ?? 'Error';
  return <ErrorLayout title={title ? title : bodyTitle} bodyTitle={bodyTitle} bodyText={bodyText} />;
}

export default ErrorPage;
