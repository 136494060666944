import * as s from 'assets/styles/Onboarding.styles';
import { Formik, FormikProps } from 'formik';
import { RefObject, useEffect, useRef } from 'react';
import scrollToComponent from 'react-scroll-to-component';
import * as Yup from 'yup';

import { Button, Heading, Icon, Message, Spinner, Text, TextField } from '@limepayments/cosmic';

import { CreateMerchantExternalBankAccountPayload } from './types';

const validationSchema = Yup.object().shape({
  accountName: Yup.string().trim().required('Please enter an account name'),
  accountNumber: Yup.string()
    .trim()
    .required('Please enter an account number')
    .matches(/^[0-9]+$/, 'Please enter only numeric characters'),
  routingNumber: Yup.string()
    .trim()
    .when('country', {
      is: 'AU',
      then: Yup.string()
        .required('Please enter a BSB')
        .matches(/^[0-9]+$/, 'Please enter only numeric characters'),
    }),
});

interface Props {
  onSubmitClick: (values: CreateMerchantExternalBankAccountPayload, formObj: { resetForm: () => void }) => void;
  onPreviousClick: () => void;
  initialValues: CreateMerchantExternalBankAccountPayload;
  requestLoader: boolean;
  errorMsg: string;
  formRef: RefObject<FormikProps<CreateMerchantExternalBankAccountPayload>>;
  bankName: string;
  bankDetailFetchLoader: boolean;
  fetchAuBankHandler: (val: string) => void;
  fetchNzBankHandler: (val: string) => void;
  isShowCancelButton: boolean;
  cancelNewAccountHandler: () => void;
  bankNameErrorMsg: string;
  saveAndFinishLater: () => void;
  saveAndReview: boolean;
}

function Step04FormView({
  onSubmitClick,
  onPreviousClick,
  initialValues,
  requestLoader,
  errorMsg,
  formRef,
  bankName,
  bankDetailFetchLoader,
  fetchAuBankHandler,
  cancelNewAccountHandler,
  isShowCancelButton,
  fetchNzBankHandler,
  bankNameErrorMsg,
  saveAndFinishLater,
  saveAndReview,
}: Props) {
  const errorContainerRef = useRef<any>(null);

  useEffect(() => {
    if (errorMsg.length > 0 && errorContainerRef && errorContainerRef.current) {
      scrollToComponent(errorContainerRef.current, { duration: 500 });
    }
  }, [errorMsg]);

  return (
    <>
      <div className="content-wrap-inner">
        <header className="content-header">
          <div className="lp-w-full mb-24">
            <div className="lp-w-full mb-16">
              <Button
                size="small"
                variant="ghost"
                isNeutral
                className="back-button"
                type="button"
                onClick={onPreviousClick}
              >
                <Icon name="ArrowLeft" className="back-arrow" />
                Back
              </Button>
            </div>
            <Heading alignment="left" tagName="h2" variant="md" className="mb-24">
              Verify your business details
            </Heading>
            <Heading alignment="left" tagName="h4" variant="sm">
              Business bank account details
            </Heading>
            {errorMsg.length > 0 && (
              <div className="mt-24 mb-24" ref={errorContainerRef}>
                <Message type="inline" children={<span className="text-wrap">{errorMsg}</span>} variant={'error'} />
              </div>
            )}
            <Text variant="body-3">
              Provide {initialValues.country === 'NZ' ? `a New Zealand` : `an Australian`} bank account where payouts
              will be deposited into.
            </Text>
          </div>
        </header>

        <Formik<CreateMerchantExternalBankAccountPayload>
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={(values, formObj: { resetForm: () => void }) => {
            onSubmitClick(values, formObj);
          }}
          validationSchema={validationSchema}
          innerRef={formRef}
        >
          {({ values, handleSubmit, errors, touched, setFieldValue, handleBlur, handleChange }) => (
            <form autoComplete="off" onSubmit={handleSubmit} className="content-wrap-form">
              <div className="content-main">
                <div className="lp-w-full mb-0">
                  <ul className="two-col lp-flex lp-flex-wrap lp-flex-column">
                    {initialValues.country !== 'NZ' && (
                      <li className="mb-12">
                        <div className="lp-w-full form-floating">
                          <TextField
                            label="BSB"
                            inputMode="text"
                            autoComplete="off"
                            id="routingNumber"
                            name="routingNumber"
                            onBlur={(e) => {
                              handleBlur(e);
                              fetchAuBankHandler(e.target.value);
                            }}
                            value={values.routingNumber}
                            onChange={(e) => {
                              const value = e.target.value;
                              const newValue = value ? value.replace(/[^0-9]+/g, '') : '';
                              e.target.value = newValue;
                              handleChange(e);
                            }}
                            error={errors.routingNumber && touched.routingNumber ? errors.routingNumber : ''}
                          />

                          {(bankName || bankDetailFetchLoader || bankNameErrorMsg) && (
                            <div className="lp-flex lp-items-center custom-message">
                              {bankDetailFetchLoader && <Spinner isVisible variant="simple" label="" />}
                              {!bankDetailFetchLoader && !bankNameErrorMsg && (
                                <Icon name="Tick" className="back-arrow" />
                              )}
                              {bankName && (
                                <Text alignment="left" variant="caption">
                                  {bankName}
                                </Text>
                              )}

                              {bankNameErrorMsg && (
                                <Text alignment="left" variant="caption" className="alert alert-danger Mui-error mt-6">
                                  {bankNameErrorMsg}
                                </Text>
                              )}
                            </div>
                          )}
                        </div>
                      </li>
                    )}
                    <li className="mb-12">
                      <div className="lp-w-full form-floating">
                        <TextField
                          label="Account number"
                          inputMode="text"
                          autoComplete="off"
                          id="accountNumber"
                          name="accountNumber"
                          onBlur={(e) => {
                            handleBlur(e);
                            if (initialValues.country === 'NZ' && e.target.value.length > 5) {
                              fetchNzBankHandler(e.target.value);
                            }
                          }}
                          onChange={(e) => {
                            const value = e.target.value;
                            const newValue = value ? value.replace(/[^0-9]+/g, '') : '';
                            e.target.value = newValue;
                            handleChange(e);
                          }}
                          value={values.accountNumber}
                          error={errors.accountNumber && touched.accountNumber ? errors.accountNumber : ''}
                        />

                        {initialValues.country === 'NZ' && (bankName || bankDetailFetchLoader || bankNameErrorMsg) && (
                          <div className="lp-flex lp-items-center custom-message">
                            {bankDetailFetchLoader && <Spinner isVisible variant="simple" label="" />}
                            {!bankDetailFetchLoader && !bankNameErrorMsg && <Icon name="Tick" className="back-arrow" />}
                            {bankName && (
                              <Text alignment="left" variant="caption">
                                {bankName}
                              </Text>
                            )}

                            {bankNameErrorMsg && (
                              <Text alignment="left" variant="caption" className="alert alert-danger">
                                {bankNameErrorMsg}
                              </Text>
                            )}
                          </div>
                        )}
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="lp-w-full mb-12">
                  <TextField
                    fullWidth
                    label="Account name"
                    inputMode="text"
                    autoComplete="off"
                    id="accountName"
                    name="accountName"
                    onBlur={handleBlur}
                    value={values.accountName}
                    onChange={handleChange}
                    error={errors.accountName && touched.accountName ? errors.accountName : ''}
                  />
                </div>
              </div>

              <s.Cta
                className={`lp-flex lp-justify-${isShowCancelButton ? `between` : `end`} lp-items-center sm:flex-col`}
              >
                {isShowCancelButton && (
                  <Button
                    type="submit"
                    size="medium"
                    variant="primary"
                    className="no-min-width lp-full"
                    onClick={cancelNewAccountHandler}
                  >
                    Cancel
                  </Button>
                )}
                <Button
                  type="submit"
                  size="medium"
                  variant="primary"
                  className="no-min-width lp-full"
                  disabled={requestLoader}
                  isLoading={requestLoader}
                  onClick={() => setFieldValue('isSaveFinishLater', false)}
                >
                  Save and {saveAndReview ? `review` : `continue`}
                  <Icon name="ArrowRight" />
                </Button>
                <div className="mobile-sidebar-footer">
                  <Button size="medium" onClick={saveAndFinishLater} variant="ghost" className="lp-w-full">
                    <Icon name="Save" />
                    Save and finish later
                  </Button>
                </div>
              </s.Cta>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default Step04FormView;
