import styled from '@emotion/styled';

export const Wrapper = styled.div`
  height: 100%;
  min-height: 100%;
  display: flex;
  align-items: stretch;
  -ms-box-orient: vertical;
  box-orient: vertical;
  flex-direction: column;
  background-color: white;

  @media (max-width: 992px) {
    height: inherit !important;
  }

  .lp-flex-column {
    -ms-box-orient: vertical;
    box-orient: vertical;
    flex-direction: column;
  }

  &[data-bg='grey'] {
    z-index: 2;
    height: auto;
    position: relative;
    min-height: inherit;
    align-items: center;
    justify-content: center;
    background-color: #f8f8fb;

    &::before {
      top: 0;
      left: 0;
      content: '';
      z-index: -1;
      width: 100%;
      height: 100%;
      position: fixed;
      background-color: #f8f8fb;
    }

    @media (max-width: 599px) {
      background-color: #fff;
      &::before {
        display: none;
      }
    }
  }

  .w-260 {
    fieldset {
      max-width: 260px;

      @media (max-width: 767px) {
        max-width: inherit;
      }
    }
  }
  .w-180 {
    fieldset {
      max-width: 180px;
      @media (max-width: 767px) {
        max-width: inherit;
      }
    }
  }

  p,
  li,
  .text-greyish {
    color: #555c7c;
  }
  .fw-medium,
  .fw-semibold {
    font-weight: 500;
  }
  a.fw-medium {
    text-decoration: none;
  }

  .lp-ls5,
  .select-error-msg {
    letter-spacing: 0.5px;
  }

  .link-primary {
    text-decoration: underline !important;
    color: rgba(var(--lp-colors-primary), 1);
    -webkit-transition: all 0.1s ease 0.25s;
    -moz-transition: all 0.1s ease 0.25s;
    -ms-transition: all 0.1s ease 0.25s;
    -o-transition: all 0.1s ease 0.25s;
    transition: all 0.1s ease 0.25s;

    &:hover,
    &:focus,
    &:active {
      color: rgba(var(--lp-colors-primary), 0.75);
    }
  }

  div[data-variant='weak'] > div {
    align-items: center;
  }

  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .size-136 {
    width: 136px;
    height: 136px;
  }

  .heading {
    white-space: pre-wrap;
    margin-bottom: var(--lp-space-md);
  }
  .p-0 {
    padding: 0 !important;
  }
  .m-0 {
    margin: 0 !important;
  }
  .mb-0 {
    margin-bottom: 0 !important;
  }
  .mt-0 {
    margin-top: 0 !important;
  }
  .mt-6 {
    margin-top: 6px !important;
  }
  .mt-8 {
    margin-top: var(--lp-space-xxxs) !important;
  }
  .mb-4 {
    margin-bottom: var(--lp-space-tiny) !important;
  }
  .mb-8 {
    margin-bottom: var(--lp-space-xxxs);
  }
  .mb-12 {
    margin-bottom: var(--lp-space-xs);
  }
  .mb-16 {
    margin-bottom: var(--lp-space-xs);
  }
  .mb-24 {
    margin-bottom: var(--lp-space-sm);
  }
  .mb-32 {
    margin-bottom: var(--lp-space-md);
  }
  .mb-40 {
    margin-bottom: 40px !important;
  }
  .mb-48 {
    margin-bottom: var(--lp-space-xl);
  }
  .ml-0 {
    margin-left: 0 !important;
  }

  .mt-24 {
    margin-top: var(--lp-space-sm);
  }

  .mr-8 {
    margin-right: var(--lp-space-xxxs);
  }
  .mr-12 {
    margin-right: var(--lp-space-xxs);
  }
  .mr-16 {
    margin-right: var(--lp-space-xs);
  }

  .size-160 {
    width: 160px;
    max-width: 160px;

    svg,
    img {
      width: 160px;
      max-width: 160px;
    }
  }

  .primary-button {
    color: #191e33 !important;
    background-color: #ebe2fb !important;
  }
  .sub-text {
    color: var(--lp-colors-neutral-600);
  }
  .pl-12 {
    padding-left: var(--lp-space-xxs);
  }
  .pl-16 {
    z-index: 2;
    position: relative;
    padding-left: var(--lp-space-xs);
  }
  .pl-20 {
    padding-left: var(--lp-font-size-heading-xs);
  }
  .zover-2 {
    z-index: 2;
    position: relative;
  }
  .lp-position-relative {
    position: relative;
  }

  .content-header {
    h2 {
      padding-top: var(--lp-space-tiny);
    }
  }

  .back-button-wrap {
    display: flex;
    cursor: pointer;
    align-items: center;
  }

  .back-button {
    height: auto;
    margin-left: 10px;
    min-width: inherit;
    padding: 0 !important;
    outline: none !important;
    color: #555c7c !important;

    svg {
      margin-left: 0 !important;
    }
  }
  .back-arrow {
    margin-top: 1px;
    margin-right: 8px;
    fill: #555c7c !important;
    color: #555c7c !important;
  }

  .styled-lists {
    color: #555c7c;
    margin-bottom: var(--lp-space-lg);

    &-items {
      display: flex;
      position: relative;
      font-size: var(--lp-space-xxs);
      padding-left: var(--lp-space-xxs);
      margin-bottom: var(--lp-space-xxs);

      &::before {
        top: 5px;
        left: 0px;
        content: '';
        width: var(--lp-space-tiny);
        height: var(--lp-space-tiny);
        flex: 0 0 var(--lp-space-tiny);
        display: flex;
        position: absolute;
        border-radius: 50%;
        background-color: #555c7c !important;
      }
    }
  }
  .fs-12 {
    font-size: var(--lp-space-xxs);
    a {
      font-size: var(--lp-space-xxs);
    }
  }

  .lp-autofocus {
    padding: 6px 0;
    border-bottom: 1px solid #e0e1ea;

    &:last-of-type {
      border-bottom: 0 none;
    }

    .MuiInputBase-input {
      font-size: 15px;
      padding-left: 0;
      overflow: hidden;
      white-space: nowrap;
      letter-spacing: 0.5px;
      text-overflow: ellipsis;
      padding-right: var(--lp-space-lg);
    }
  }
  .lp-field-action {
    top: 12px;
    right: 4px;
    z-index: 2;
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: absolute;

    svg {
      fill: rgba(var(--lp-colors-primary), 1);
    }
  }

  .action-dropdown {
    top: auto;
    right: 0px;
    width: 100%;
    max-width: 154px;
    z-index: 3;
    position: absolute;
    background-color: #fff;
    display: flex;
    align-items: stretch;
    -ms-box-orient: vertical;
    box-orient: vertical;
    flex-direction: column;
    padding: var(--lp-space-xxs);
    border-radius: var(--lp-space-xxxs);
    box-shadow: var(--lp-shadows-medium);

    .action-list {
      width: 100%;
      list-style: none;

      &-items {
        width: 100%;
        margin-bottom: var(--lp-space-tiny);

        &:last-of-type {
          margin-bottom: 0;
        }

        button {
          gap: 4px;
          padding: 2px 8px;
          font-size: 14px;
          font-weight: 500;
          border-radius: 5px;
          background-color: #fff;
          justify-content: flex-start !important;
          transition: all 0.1s ease 0.25s;

          span {
            padding-top: 2px;
          }

          &:hover,
          &:focus,
          &:active {
            font-weight: 900;
            background-color: #f8f8fb;
          }

          .size-18 {
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 14px !important;
              height: 14px !important;
              margin-bottom: 0 !important;
            }
          }
        }
      }
    }
  }

  // Spinner
  .spinner {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    &-wrap {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: calc(100vh - 130px);
    }

    &::before {
      content: '';
      width: 40px;
      height: 40px;
      position: absolute;
      border-radius: 50%;
      box-sizing: border-box;
      border-top: 3px solid #555c7c;
      border-right: 3px solid transparent;
      animation: spinner 0.6s linear infinite;
    }
  }
  @keyframes spinner {
    to {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .custom-heading {
    line-height: 28px;
    letter-spacing: 0.25px;
    color: rgb(var(--lp-colors-neutral-600));
  }

  .ordered-lists,
  .check-lists {
    li {
      margin-bottom: var(--lp-space-xxxs);

      p {
        padding-left: var(--lp-space-xxxs);
      }
    }
  }

  .ordered-lists {
    list-style: number;
    padding-left: var(--lp-space-xs);
    font-size: var(--lp-font-size-body-3);
    margin-bottom: var(--lp-space-xs);
  }

  .check-lists {
    list-style: none;
    display: inline-flex;
    -ms-box-orient: vertical;
    box-orient: vertical;
    flex-direction: column;

    &-icon {
      right: 0;
      top: 3px;
      width: 16px;
      height: 16px;
      display: flex;
      position: absolute;
      align-items: center;
      justify-content: center;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    li {
      width: 100%;
      display: inline-flex;
      align-items: flex-start;
      justify-content: space-between;
      position: relative;
      padding-left: var(--lp-space-xs);
      padding-right: var(--lp-space-md);

      &::before {
        top: 2px;
        left: -3px;
        content: '';
        width: 18px;
        height: 18px;
        position: absolute;
        background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 20.7a9.2 9.2 0 1 0 0-18.4 9.2 9.2 0 0 0 0 18.4Zm4.264-10.687a1.15 1.15 0 0 0-1.626-1.626l-3.787 3.787-1.487-1.487a1.15 1.15 0 1 0-1.626 1.626l2.3 2.3c.449.45 1.177.45 1.626 0l4.6-4.6Z' fill='%23E0E1EA'/%3E%3C/svg%3E");
        background-size: 18px auto;
        background-position: center;
        background-repeat: no-repeat;
      }

      .btn-info {
        top: 3px;
        right: 4px;
        width: 18px;
        height: 18px;
        position: absolute;

        .size-16 {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
  .two-col {
    margin-left: -12px;
    margin-right: -12px;
    width: calc(100% + 24px);
    display: flex;

    @media (max-width: 767px) {
      -ms-box-orient: vertical;
      box-orient: vertical;
      flex-direction: column;
    }

    li,
    > div {
      width: 100%;
      flex: 0 0 50%;
      max-width: 50%;
      padding-left: var(--lp-space-xxs);
      padding-right: var(--lp-space-xxs);

      @media (max-width: 767px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    &.lp-flex-column {
      -ms-box-orient: vertical;
      box-orient: vertical;
      flex-direction: column;

      li,
      > div {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;

        .MuiFormControl-root,
        .MuiFormControl-root {
          width: 100%;
          max-width: 260px;

          @media (max-width: 767px) {
            max-width: 100%;
          }
        }
      }
    }
  }
  .lp-equal-width {
    div[role='radiogroup'] {
      width: 100%;
      justify-content: space-between;
    }
  }

  .MuiFormControl-root {
    .MuiInputBase-input,
    .MuiFormHelperText-root,
    .select-error-msg {
      padding-left: var(--lp-space-xs);
    }
    .MuiInputLabel-formControl {
      padding-left: var(--lp-space-xs);
    }
    .MuiSelect-root {
      padding-left: var(--lp-space-xs) !important;
    }
    @media (min-width: 992px) {
      .MuiFormHelperText-root {
        white-space: nowrap;
      }
    }
  }
  .lp-width-180 {
    max-width: 180px;
  }
  .lp-width-300 {
    max-width: 300px;
  }
  .lp-justify-end {
    justify-content: flex-end !important;
  }
  .lp-justify-between {
    justify-content: space-between !important;
  }
  .modal-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 0;
    overflow-y: auto !important;
    margin-bottom: 20px;
    max-height: calc(100% - 40px);
    max-width: 445px;
    margin-left: auto;
    margin-right: auto;
    overflow-x: hidden !important;
  }
  .modal-main {
    height: 100%;
    display: flex;
    max-width: 550px;
    padding: var(--lp-space-sm);
    flex-direction: column;

    @media (max-width: 767px) {
      padding: var(--lp-space-xs);
      max-width: 350px !important;
    }
  }
  .modal-footer {
    @media (max-width: 767px) {
      button {
        min-width: 80px;
      }
      button.lp-width-110 {
        min-width: 110px;
      }
    }
  }

  .icon-input {
    max-width: 260px;
    position: relative;

    &.lp-w-full {
      max-width: 100%;
    }

    .info-icon {
      top: 24px;
      right: 10px;
      width: 18px;
      height: 18px;
      fill: #191e33;
      cursor: pointer;
      position: absolute;
    }
    .MuiInput-input {
      padding-right: 35px;
    }
  }

  .icon-input-error {
    .info-icon {
      right: 25px;
    }
  }

  .custom-message {
    left: 50%;
    top: 18px;
    position: absolute;

    @media (max-width: 767px) {
      top: auto;
      left: auto;
      position: relative;
    }
  }
  .lp-p-0 {
    padding: 0 !important;
  }

  .checkbox-group {
    display: flex;
    flex-direction: column;

    label {
      margin-left: calc(var(--lp-space-xxxs) / 2); /* 4px */

      p {
        padding-top: calc(var(--lp-space-xxxs) / 4); /* 2px */
      }
    }
  }
  .no-min-width {
    min-width: inherit;
  }
  .lp-pass .lp-fill-current.text-primary {
    fill: #191e33 !important;
  }

  .mobile-sidebar-footer {
    display: none !important;
  }
  @media (max-width: 767px) {
    .mobile-sidebar-footer {
      width: 100%;
      background-color: #fff;
      display: flex !important;
      margin-top: var(--lp-space-xxxs);

      svg {
        margin-right: var(--lp-space-xxxs);
        margin-left: 0 !important;
      }
    }
  }

  @media (max-width: 767px) {
    .m-full-width {
      width: 100% !important;
    }
  }
`;

export const OrderBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MultiColumnRespBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p + p {
    color: #030305;
    margin-top: 0px;

    &.is-disabled {
      color: #e0e1ea;
    }
  }

  @media (max-width: 767px) {
    align-items: flex-start;
  }

  @media (max-width: 767px) {
    &:not(.content-header) {
      -ms-box-orient: horizontal;
      box-orient: horizontal;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }

    h4 {
      font-size: 18px;
    }

    p {
      word-break: break-all;
      padding-right: var(--lp-space-xxxs);
      width: 100%;
      max-width: 50%;

      + p {
        width: 100%;
        max-width: 50%;
        margin-top: 0 !important;
        text-align: right !important;
        padding-right: 0 !important;
        padding-left: var(--lp-space-xxxs);
      }
    }
  }
  @media (max-width: 420px) {
    &.content-header {
      justify-content: space-between;
      align-items: center;
    }
    h4 {
      font-size: 16px !important;
    }
  }
`;

export const GradientBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--lp-radii-lg);
  padding: var(--lp-line-height-body-3);
  background-color: #ddcef8;

  > div {
    margin-top: var(--lp-space-xxxs);
    font-size: var(--lp-space-sm);
    font-weight: var(--lp-font-weight-heading-md);
  }
`;

export const SidebarResponsive = styled.div`
  display: none;

  @media (max-width: 992px) {
    display: block;
    width: 100%;

    > div {
      width: 100%;
    }
    div:nth-of-type(2) {
      width: 100%;
    }
  }
`;

export const Sidebar = styled.div`
  top: 0;
  left: 0;
  width: 300px;
  height: 100%;
  position: fixed;
  display: flex;
  -ms-box-orient: vertical;
  box-orient: vertical;
  flex-direction: column;
  align-items: stretch;
  background-color: #f8f8fb;
  border-right: 1px solid #e0e1ea;

  @media (max-width: 992px) {
    position: relative;
    height: inherit;
    width: 100%;
    flex-grow: 1;
    flex-shrink: 0;
  }

  .sidebar-body {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -moz-box-flex: 1;
    -moz-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    -webkit-align-items: stretch;
    -moz-align-items: stretch;
    align-items: stretch;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-box-orient: vertical;
    box-orient: vertical;
    -webkit-transition: opacity 0.1s ease 0.25s;
    -moz-transition: opacity 0.1s ease 0.25s;
    -ms-transition: opacity 0.1s ease 0.25s;
    -o-transition: opacity 0.1s ease 0.25s;
    transition: opacity 0.1s ease 0.25s;
    @media (max-width: 992px) {
      display: none;
    }
  }
  .sidebar-body-content {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -moz-box-flex: 1;
    -moz-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    -webkit-align-items: stretch;
    -moz-align-items: stretch;
    align-items: stretch;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-box-orient: vertical;
    box-orient: vertical;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    background: #f8f8fb none repeat scroll 0 0;
  }
  .sidebar-body-content-scroll {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    overflow-x: auto;
    overflow-x: hidden;
    position: absolute;
    -webkit-overflow-scrolling: touch;

    > div {
      width: 100%;
    }
  }

  .sidebar-footer {
    padding: 10px 24px 24px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    @media (max-width: 992px) {
      display: none;
    }

    button {
      padding-left: 0 !important;
      padding-right: 0 !important;
      justify-content: flex-start !important;

      svg {
        margin-right: var(--lp-space-xxxs);
        margin-left: 0 !important;
      }
    }
  }
`;

export const Header = styled.header`
  padding: 15px 22px;
  min-height: 62px;
  box-shadow: var(--lp-shadows-medium);
`;

export const Main = styled.div`
  margin: 0;
  height: 100%;
  width: calc(100% - 300px);
  margin-left: 300px;
  display: flex;
  -ms-box-orient: vertical;
  box-orient: vertical;
  flex-direction: column;
  align-items: stretch;
  background-color: #fff;

  @media (max-width: 992px) {
    width: 100%;
    margin-left: 0;
    flex-grow: 1;
    flex-shrink: 0;
  }

  &.no-sidebar {
    width: 100%;
    height: auto;
    margin-left: 0;
    align-items: center;
    -ms-box-orient: vertical;
    box-orient: vertical;
    flex-direction: column;
    justify-content: center;
    background-color: transparent;
  }
`;

export const ContentWrap = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: 650px;

  .content-wrap-inner {
    display: flex;
    min-height: 100%;
    -ms-box-orient: vertical;
    box-orient: vertical;
    flex-direction: column;
    align-items: stretch;
    padding: var(--lp-space-xl) var(--lp-space-md) var(--lp-space-md);

    @media (max-width: 767px) {
      padding: var(--lp-space-md) var(--lp-space-xs) var(--lp-space-sm);
    }

    .content-wrap-form,
    .content-main {
      flex-grow: 1;
    }
    .content-wrap-form,
    .content-header,
    .content-main,
    .Cta {
      flex-shrink: 0;
    }

    .content-wrap-form {
      display: flex;
      min-height: 100%;
      -ms-box-orient: vertical;
      box-orient: vertical;
      flex-direction: column;
      align-items: stretch;

      .content-main {
        flex-grow: 1;

        .content-main,
        .Cta {
          flex-shrink: 0;
        }
      }
    }
  }
`;

export const Separator = styled.header`
  margin: var(--lp-space-sm) auto;

  hr {
    margin: 0;
    padding: 0;
    border: 0 none;
    border-bottom: 1px solid #e0e1ea;
  }
`;
export const WeakAnnouncement = styled.div`
  > div {
    color: #7a5526;
    border: 1px solid #f5ab4c;
    background-color: #fefbf6 !important;
    padding: var(--lp-space-xs);
    border-radius: calc(var(--lp-space-xs) / 4);
    box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.08);

    p {
      color: #7a5526;
      font-size: var(--lp-space-xxs);
      line-height: var(--lp-space-xs);
    }
    .lp-fill-current {
      color: #f5ab4c;
    }
  }

  &.align-left {
    > div {
      -webkit-box-pack: flex-start;
      -ms-flex-pack: flex-start;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
    }

    p {
      align-items: center;
      -webkit-box-pack: flex-start;
      -ms-flex-pack: flex-start;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;

      p {
        margin-right: 0;
      }
    }
  }
`;

export const Content = styled.div`
  flex: 1 1;
`;

export const Uploader = styled.div`
  width: 100%;
  max-width: 360px;
  border: 1px solid #f0f2f9;
  padding: var(--lp-space-xs);
  border-radius: calc(var(--lp-space-xxxs) / 2);
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    color: #191e33;
    font-size: var(--lp-space-xxs);
  }

  button {
    width: 100%;
    max-width: 115px;
    min-width: inherit;
    white-space: nowrap;
  }
`;

export const UploaderSmall = styled.div`
  width: 100%;
  max-width: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    color: #191e33;
    font-size: var(--lp-space-xxs);
  }

  button {
    width: 100%;
    max-width: 115px;
    min-width: inherit;
    white-space: nowrap;
  }
`;

export const Cta = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: var(--lp-space-md) 0 0;

  &.justify-start {
    justify-content: flex-start;
  }

  @media (max-width: 767px) {
    button.lp-full {
      width: 100%;
    }
  }

  Button + Button {
    margin-top: var(--lp-space-xs) !important;
  }
`;

export const Alert = styled.div`
  margin-bottom: 16px;
`;

export const DocumentBlock = styled.div`
  width: 100%;
  max-width: 453px;
  gap: var(--lp-space-xs);
  border: 1px solid #f0f2f9;
  padding: var(--lp-space-xs);
  border-radius: var(--lp-space-tiny);
  background-color: #ffffff;
  margin-bottom: var(--lp-space-xs);

  .doc-name {
    flex-shrink: 0;
    max-width: 150px;
    word-wrap: break-word;
  }

  &.noStyle {
    padding: 0;
    border: 0 none;

    .upload-preview {
      max-width: 250px;
      justify-content: space-between;
    }
  }

  .upload-preview {
    display: flex;
    gap: var(--lp-space-xs);
    padding: var(--lp-space-xs);
    border-radius: var(--lp-space-tiny);
    background-color: #f8f8fb;

    svg {
      flex-shrink: 0;
    }
  }
  .upload-delete {
    outline: none;
    border: 0 none;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    flex-shrink: 0;

    svg {
      fill: #3c3c3b;
      opacity: 0.65;
    }
  }
`;

export const AppLogo = styled.div`
  width: 94px;
  height: 26px;
  display: block;
  margin: 32px auto 0;
`;

export const BoxContentWrap = styled.div`
  width: 100%;
  height: auto;
  margin: 0 auto;
  max-width: 428px;
  border-radius: 10px;
  background-color: #fff;
  padding: var(--lp-space-xxxl);
  box-shadow: 0px 8px 24px -4px rgba(0, 0, 0, 0.08);

  @media (max-width: 320px) {
    margin: 0 auto;
    max-width: 100%;
    padding: var(--lp-space-xxs);
    box-shadow: none !important;
  }

  @media screen and (min-width: 321px) and (max-width: 599px) {
    width: 100%;
    margin: 0 auto;
    max-width: 300px;
    padding: var(--lp-space-md) 0;
    box-shadow: none !important;
  }

  .agree-checkbox {
    a {
      font-weight: 500;
    }
  }

  .lp-no-transition {
    svg {
      position: relative;
      margin: 0 !important;
      right: var(--lp-space-xxxs);
      transition: right var(--lp-durations-immediate) ease-in-out;
    }

    &:hover,
    &:focus,
    &:active {
      svg {
        right: var(--lp-space-xxs);
      }
    }
  }

  .avatar {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid #efeff3;
    background-color: #f9f9f9;
    -webkit-transition: all 0.2s ease 0.25s;
    -moz-transition: all 0.2s ease 0.25s;
    -ms-transition: all 0.2s ease 0.25s;
    -o-transition: all 0.2s ease 0.25s;
    transition: all 0.2s ease 0.25s;

    img {
      width: 100px;
      height: 100px;
      overflow: hidden;
      border-radius: 50%;
      object-fit: cover;
      object-position: center;
    }

    &-initial {
      width: 100%;
      height: 100%;
      display: flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;

      h1 {
        color: #191e32;
        letter-spacing: 2px;
      }
    }
    &.hoppy,
    &.laddr,
    &.puma,
    &.fisher,
    &.moco,
    &.anna {
      border: 0 none;

      h1 {
        color: #f8f8fb;
      }
    }

    &.hoppy {
      background-color: #9bd4e3;
    }
    &.laddr {
      background-color: #e375af;
    }
    &.puma {
      background-color: #f3aa55;
    }
    &.fisher {
      background-color: #9b72e8;
    }
    &.moco {
      background-color: #fd6f4b;
    }
    &.anna {
      background-color: #45a279;
    }
  }
`;
