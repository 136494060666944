import { PaginatedResponse, handlePaginatedResponse, handleResponse } from 'api/utils';
import { getAprilApiHost } from 'utils/env';
import getFetchOptions from 'utils/getFetchOptions';

import { PaymentAPIResponseType } from './payments.types';

export const getMerchantPaymentList = async (
  queryString: string,
): Promise<PaginatedResponse<PaymentAPIResponseType[]>> => {
  const options = await getFetchOptions();
  return await fetch(`${await getAprilApiHost()}/transactions?${queryString}`, options).then(handlePaginatedResponse);
};

export const getMerchantPaymentDetail = async (transactionId: string): Promise<PaymentAPIResponseType> => {
  const options = await getFetchOptions();
  return await fetch(`${await getAprilApiHost()}/transactions/${transactionId}`, options).then(handleResponse);
};

export const merchantPaymentRefund = async (
  host: string,
  merchantId: string,
  postData: any,
): Promise<PaymentAPIResponseType> => {
  const options = await getFetchOptions('POST', { payload: JSON.stringify(postData) });
  return await fetch(`${host}/merchants/${merchantId}/refunds`, options).then(handleResponse);
};

export const merchantPaymentCancel = async (
  host: string,
  merchantId: string,
  transactionId: string,
): Promise<PaymentAPIResponseType> => {
  const options = await getFetchOptions('DELETE');
  return await fetch(`${host}/merchants/${merchantId}/transactions/${transactionId}/authorisation`, options).then(
    handleResponse,
  );
};

export const merchantPaymentCapture = async (
  host: string,
  merchantId: string,
  transactionId: string,
  postData: any,
): Promise<PaymentAPIResponseType> => {
  const options = await getFetchOptions('POST', { payload: JSON.stringify(postData) });
  return await fetch(
    `${host}/merchants/${merchantId}/transactions/${transactionId}/authorisation:capture`,
    options,
  ).then(handleResponse);
};
