import * as s from 'assets/styles/Platforms.styles';

import { Button, Checkbox, Heading, Icon, Link, Message, Spinner, Stack, Text } from '@limepayments/cosmic';

interface Props {
  onCloseExportClick: () => void;
}

function ExportComponent({ onCloseExportClick }: Props) {
  return (
    <div className="lp-flex lp-flex-column">
      <div className="filter-header">
        <Heading alignment="left" tagName="h2" variant="xs" className="mb-0">
          Export transactions
        </Heading>
        <Button
          type="button"
          onClick={onCloseExportClick}
          size="small"
          variant="ghost"
          className="button-close m-0 p-0"
        >
          <Icon name="Cross" className="m-0 p-0" />
        </Button>
      </div>
      <div className="filter-body">
        {/* Step 01 */}
        <div className="lp-w-full">
          <div className="export-message mb-24">
            <Message dismissalType="none" type="inline" variant="information">
              <Text alignment="left" tagName="p" variant="body-3" isEmphasised>
                This export has no applied filters
              </Text>
            </Message>
          </div>

          <div className="lp-w-full lp-flex lp-align-center lp-justify-between mb-24">
            <Text alignment="left" tagName="p" variant="caption" className="m-0">
              Available on
            </Text>
            <Text alignment="left" tagName="p" variant="caption" className="m-0 mt-0">
              Is between 07/10/2022 and 07/08/2022
            </Text>
          </div>

          <div className="lp-w-full mb-24">
            <Message dismissalType="none" type="inline" variant="warning">
              <Heading variant="xxs">Large export</Heading>
              <Text alignment="left" tagName="p" variant="caption">
                This export is too large to instantly download. To export an instant report, please adjust the date
                filters 'Ordered on' or 'Available on' to a smaller range.
              </Text>
            </Message>
          </div>

          <div className="lp-w-full lp-w-half checkbox mb-24">
            <Heading alignment="left" tagName="h2" variant="xxs" className="mb-24">
              Columns
            </Heading>
            <Checkbox id="testid" label="Select all" variant="body-2" />
          </div>
          <div className="checkbox-column-list lp-w-full lp-flex">
            <div className="lp-w-half checkbox">
              <Checkbox id="testid1" label="Amount" variant="body-2" />
              <Checkbox id="testid3" label="Currency" variant="body-2" />
              <Checkbox id="testid5" label="Transaction type" variant="body-2" />
              <Checkbox id="testid7" label="Transaction ID" variant="body-2" />
            </div>
            <div className="lp-w-half checkbox">
              <Checkbox id="testid2" label="Payout ID" variant="body-2" />
              <Checkbox id="testid4" label="Transfer ID" variant="body-2" />
              <Checkbox id="testid6" label="Created on date" variant="body-2" />
              <Checkbox id="testid8" label="Available on date" variant="body-2" />
            </div>
          </div>
        </div>

        {/* Step 02 */}
        <s.MerchantLoading>
          <Stack spacing="md">
            <Spinner isVisible variant="simple" label="Preparing export" />
          </Stack>
        </s.MerchantLoading>

        {/* Step 03 */}
        <s.MerchantLoading>
          <Text alignment="center" tagName="p" variant="body-3">
            Exporting 1,298 objects. A few seconds remaining...
          </Text>
          <div className="export-loading mt-16 mb-24">
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                aria-valuenow={25}
                aria-valuemin={0}
                aria-valuemax={100}
                style={{ width: '75%' }}
              ></div>
            </div>
          </div>
          <Text alignment="center" tagName="p" variant="caption" className="max-w320">
            If you need to close this window, we'll send an email to {'{'}username@email.com{'}'} with a link to
            download when your export is ready.
          </Text>
        </s.MerchantLoading>

        {/* Step 04 */}
        <s.MerchantLoading>
          <div className="success mb-8">
            <Icon name="Tick" />
          </div>
          <Text alignment="center" tagName="p" variant="body-3" className="max-w320 mb-24">
            Export complete
          </Text>
          <Text alignment="center" tagName="p" variant="body-3" className="max-w320 mt-0">
            If your download doesn't start automatically,{' '}
            <Link onClick={function noRefCheck() {}} size="small" target="_self" className="fw-medium">
              click here to download.
            </Link>
          </Text>
        </s.MerchantLoading>
      </div>
      <div className="filter-footer">
        <Button className="lp-w-full" onClick={function noRefCheck() {}} size="medium" variant="reversed">
          Clear
        </Button>
        <Button className="lp-w-full" onClick={function noRefCheck() {}} size="medium" variant="primary">
          Save
        </Button>
      </div>
    </div>
  );
}

export default ExportComponent;
