import { GetPlatformConfigResponse } from 'api/platform/platform.types';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IConfig {
  platformName: string;
  isLoggedIn: boolean | null;
  isLoginPending: boolean;
  isMerchantActivated: boolean | null;
  merchantEmail: string;
  tenantId: string;
  apiBaseUri: string;
  authApiKey: string;
  authDomain: string;
  marketplaceId: string;
  platformCountry: string;
}

const initialState: IConfig = {
  platformName: '',
  isLoggedIn: null,
  isLoginPending: false,
  isMerchantActivated: null,
  merchantEmail: '',
  tenantId: '',
  marketplaceId: '',
  authDomain: '',
  apiBaseUri: '',
  authApiKey: '',
  platformCountry: '',
};

export const configSlice = createSlice({
  name: 'platformConfig',
  initialState,
  reducers: {
    setConfig: (state, action: PayloadAction<GetPlatformConfigResponse>) => {
      state.apiBaseUri = action.payload.apiBaseUri;
      state.tenantId = action.payload.tenantId;
      state.authApiKey = action.payload.authApiKey;
      state.authDomain = action.payload.authDomain;
      state.marketplaceId = action.payload.marketplaceId;
    },
    setMerchantActivated: (state, action: PayloadAction<boolean>) => {
      state.isMerchantActivated = action.payload;
    },
    setMerchantEmail: (state, action: PayloadAction<string>) => {
      state.merchantEmail = action.payload;
    },
    setPlatformName: (state, action: PayloadAction<string>) => {
      state.platformName = action.payload;
    },
    setLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
    setLoginPending: (state, action: PayloadAction<boolean>) => {
      state.isLoginPending = action.payload;
    },
    setPlatformCountry: (state, action: PayloadAction<string>) => {
      state.platformCountry = action.payload;
    },
  },
});

export const {
  setConfig,
  setMerchantActivated,
  setMerchantEmail,
  setPlatformName,
  setLoggedIn,
  setLoginPending,
  setPlatformCountry,
} = configSlice.actions;

export default configSlice.reducer;
