import ErrorPage from 'pages/common/error';
import { Route, Routes } from 'react-router-dom';

import ActivateAccountPage from './account-activate';
import AuthRoutesList from './constants';
import ForgotPasswordPage from './forgot-password';
import LoginPage from './login';
import ResetPasswordPage from './reset-password';

const AuthRoutes = () => {
  return (
    <Routes>
      <Route index element={<LoginPage />} />
      <Route path={AuthRoutesList.LOGIN_PAGE} caseSensitive={true} element={<LoginPage />} />
      <Route path={AuthRoutesList.FORGOT_PASSWORD_PAGE} caseSensitive={true} element={<ForgotPasswordPage />} />
      <Route path={AuthRoutesList.RESET_PASSWORD_PAGE} caseSensitive={true} element={<ResetPasswordPage />} />
      <Route path={AuthRoutesList.ACCOUNT_ACTIVATE_PAGE} caseSensitive={true} element={<ActivateAccountPage />} />
      <Route
        path="*"
        element={<ErrorPage bodyTitle="Page Not Found" bodyText="The page you are trying to access doesn't exists" />}
      />
    </Routes>
  );
};

export default AuthRoutes;
