import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  logoUrl: Yup.string().trim().nullable().url('Please enter valid url'),
  faviconUrl: Yup.string().trim().nullable().url('Please enter valid url'),
  logoAltText: Yup.string().trim().nullable(),
  cardStatementName: Yup.string()
    .trim()
    .nullable()
    .min(5, 'Must be at least 5 characters')
    .max(13, 'Must be at most 13 characters')
    .matches(/^[A-Za-z]+$/, 'Alphabetic characters only'),
  smsSenderName: Yup.string()
    .trim()
    .nullable()
    .min(3, 'Must be at least 3 characters')
    .max(11, 'Must be at most 11 characters')
    .matches(/^[a-zA-Z0-9]+$/, 'Alphabetic or numeric characters only'),
  businessWebsite: Yup.string().trim().nullable().url('Please enter valid url'),
  primaryBrandColor: Yup.string()
    .trim()
    .nullable()
    .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, 'Please enter a valid hex code'),
});
