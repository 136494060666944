import { Tag } from '@limepayments/cosmic';
import { TagProps } from '@limepayments/cosmic/build/Tag/Tag';

export interface StatusTagProps {
  label: string;
  variant: TagProps['variant'];
}

function VisibilityTag({ label, variant }: StatusTagProps) {
  return (
    <div className="lp-flex">
      <Tag label={label} size="small" variant={variant} />
    </div>
  );
}

export default VisibilityTag;
