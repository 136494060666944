import { downloadPlatformTransactionDetail } from 'api/platform/transactions';
import { PlatformTransactionDetailObj } from 'api/platform/transactions.types';
import * as s from 'assets/styles/Platforms.styles';
import { DateTime } from 'luxon';
import ErrorComponent from 'pages/common/error/ErrorComponent';
import { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useAppSelector } from 'redux/platform/hooks';
import { toCurrency } from 'utils/currency';
import getErrorMessage from 'utils/getErrorMessage';

import { Button, Heading, Icon, Spinner, Text } from '@limepayments/cosmic';

import { TxnStatusObj } from '../constants';
import StatusTag from '../listing/partials/StatusTagComponent';
import ErrorFetchTxnListComponent from '../transactions/partials/ErrorFetchTxnListComponent';
import DownloadReportComponent from './partials/DownloadReportComponent';
import { IDownloadReport } from './types';

const getInitialState = () => {
  return {
    showDownloadModal: false,
    showLoader: false,
    showSuccessDiv: false,
    downloadDataCSV: '',
    errorMsg: '',
  };
};

export interface Props {
  onBackButtonClick: () => void;
  detailErrorMsg: string;
  showDetailLoader: boolean;
  txnDetailObj: PlatformTransactionDetailObj | null;
}

function TxnDetails({ onBackButtonClick, detailErrorMsg, showDetailLoader, txnDetailObj }: Props) {
  const [downloadObj, setDownloadObj] = useState<IDownloadReport>(getInitialState());

  const { apiBaseUri } = useAppSelector((state) => ({
    apiBaseUri: state.config.apiBaseUri,
  }));

  const exportModalToggle = (downloadButtonClicked?: boolean) => {
    if (downloadButtonClicked) {
      downloadClickHandler();
    } else {
      setDownloadObj((prevState) => ({
        ...prevState,
        showSuccessDiv: false,
        downloadDataCSV: '',
      }));
    }

    setDownloadObj((prevState) => ({
      ...prevState,
      showDownloadModal: !prevState.showDownloadModal,
    }));
  };

  const downloadClickHandler = async () => {
    try {
      if (!txnDetailObj) {
        return;
      }

      setDownloadObj((prevState) => ({
        ...prevState,
        showLoader: true,
        showSuccessDiv: false,
        downloadDataCSV: '',
        errorMsg: '',
      }));

      const response = await downloadPlatformTransactionDetail(
        apiBaseUri,
        txnDetailObj.merchantId,
        txnDetailObj.payoutId,
      );
      console.log(response);
      setDownloadObj((prevState) => ({
        ...prevState,
        downloadDataCSV: response,
        showSuccessDiv: true,
      }));
    } catch (error) {
      setDownloadObj((prevState) => ({
        ...prevState,
        errorMsg: getErrorMessage(error),
      }));
    } finally {
      setDownloadObj((prevState) => ({
        ...prevState,
        showLoader: false,
      }));
    }
  };

  useEffect(() => {
    if (!showDetailLoader && txnDetailObj) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [showDetailLoader, txnDetailObj]);

  return (
    <Fragment>
      <Helmet>
        <title>Transaction Detail</title>
      </Helmet>
      <div className="content-wrap-inner lp-full">
        <div className="header-back mb-28">
          <Button onClick={onBackButtonClick} variant="ghost" size={'small'}>
            <Icon name="ArrowLeft" className="mr-8" />
            Back
          </Button>
        </div>
        {!showDetailLoader && detailErrorMsg.length ? (
          <Fragment>
            <ErrorComponent bodyText={detailErrorMsg} />
            <ErrorFetchTxnListComponent
              title="Unable to load payout details"
              body={`Sorry, we're unable to load the details for this payout at the moment, please try again later.`}
            />
          </Fragment>
        ) : null}

        {showDetailLoader && (
          <div className="spinner-wrapper">
            <Spinner variant="simple" isVisible label="Loading..." />
          </div>
        )}

        {!showDetailLoader && !detailErrorMsg.length && txnDetailObj && (
          <Fragment>
            <Heading alignment="left" tagName="h2" variant="xxs" className="text-muted mb-4">
              Payout
            </Heading>
            <div className="header-details md-pb-enable position-relative">
              <div className="lp-flex lp-flex-column">
                <div className="header-details-inner">
                  <Heading alignment="left" tagName="h2" variant="sm" className="mb-0 mr-16">
                    {toCurrency(txnDetailObj.totalAmount, txnDetailObj.bankAccount.currency)}{' '}
                    {txnDetailObj.bankAccount.currency}
                  </Heading>
                  <StatusTag
                    label={TxnStatusObj[txnDetailObj.status as keyof typeof TxnStatusObj].label}
                    variant={TxnStatusObj[txnDetailObj.status as keyof typeof TxnStatusObj].varient}
                    tooltipDirection="up"
                    tooltipText={TxnStatusObj[txnDetailObj.status as keyof typeof TxnStatusObj].tooltipText}
                  />
                </div>
              </div>
              <div className="lp-flex sm-flex-col sm-w-full">
                <div className="stacked-data">
                  <Text tagName="p" variant="caption" className="text-dark-50 m-0">
                    Payout ID
                  </Text>
                  <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0" isEmphasised>
                    {txnDetailObj.payoutId}
                  </Text>
                </div>
                <div className="stacked-data">
                  <Text tagName="p" variant="caption" className="text-dark-50 m-0">
                    Merchant
                  </Text>
                  <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0" isEmphasised>
                    {txnDetailObj.merchantName}
                  </Text>
                </div>
                <div className="stacked-data">
                  <Text tagName="p" variant="caption" className="text-dark-50 m-0">
                    Date
                  </Text>
                  <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0" isEmphasised>
                    {DateTime.fromISO(txnDetailObj.updatedAt).toFormat('dd/LL/yyyy')}
                  </Text>
                </div>
              </div>
              <div className="ml-auto download-receipt sm-w-full">
                <Button size="small" onClick={() => exportModalToggle(true)} variant="reversed">
                  <Icon name="Receipt" />
                  Download payout report
                </Button>
              </div>
              {downloadObj.showDownloadModal && (
                <DownloadReportComponent onCloseExportClick={exportModalToggle} downloadObj={downloadObj} />
              )}
            </div>

            {txnDetailObj.summary.length && (
              <s.MerchantsData>
                <Heading alignment="left" tagName="h2" variant="xxs" className="mb-16">
                  Summary
                </Heading>
                <div className="lp-w-half sm-w-full">
                  <table className="lp-w-full table-summary">
                    <thead>
                      <tr>
                        <th style={{ width: '50%' }}></th>
                        <th style={{ width: '25%' }}>Count</th>
                        <th style={{ width: '25%' }}>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {txnDetailObj.summary.reverse().map((summary, key) => (
                        <tr>
                          <td>{summary.entryType.split(/(?=[A-Z])/).join(' ')}</td>
                          <td>{summary.totalCount}</td>
                          <td>
                            {summary.totalAmount > 0
                              ? toCurrency(summary.totalAmount, txnDetailObj.bankAccount.currency)
                              : `(${toCurrency(summary.totalAmount, txnDetailObj.bankAccount.currency).replace(
                                  '-',
                                  '',
                                )})`}{' '}
                            {txnDetailObj.bankAccount.currency}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td style={{ width: '50%' }} className="fw-bold">
                          Total
                        </td>
                        <td colSpan={2} style={{ width: '50%' }} className="fw-bold">
                          {toCurrency(txnDetailObj.totalAmount, txnDetailObj.bankAccount.currency)}{' '}
                          {txnDetailObj.bankAccount.currency}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {txnDetailObj.narrative && (
                    <Text tagName="p" variant="caption" className="text-dark2 mt-16">
                      {txnDetailObj.narrative}
                    </Text>
                  )}
                </div>
              </s.MerchantsData>
            )}

            <s.MerchantsData>
              <Heading alignment="left" tagName="h2" variant="xxs" className="mb-16">
                Payout details
              </Heading>
              <div className="lp-half lp-flex two-column-block">
                <ul className="two-column">
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Merchant
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {txnDetailObj.merchantName}
                    </Text>
                  </li>
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Merchant ID
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {txnDetailObj.merchantId}
                    </Text>
                  </li>
                  {/* <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Delivery method
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      Bank transfer (TBC)
                    </Text>
                  </li> */}
                </ul>
              </div>
            </s.MerchantsData>

            <s.MerchantsData>
              <Heading alignment="left" tagName="h2" variant="xxs" className="mb-16">
                Account details
              </Heading>
              <div className="lp-half lp-flex two-column-block">
                <ul className="two-column">
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      BSB
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {txnDetailObj.bankAccount.routingNumber}
                    </Text>
                  </li>
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Account number
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      **** **** {txnDetailObj.bankAccount.accountNumberLast4}
                    </Text>
                  </li>
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Account holder
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {txnDetailObj.bankAccount.accountName}
                    </Text>
                  </li>
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Bank
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {txnDetailObj.bankAccount.bankName ?? '-'}
                    </Text>
                  </li>
                </ul>

                <ul className="two-column">
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Origin
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {txnDetailObj.bankAccount.country}
                    </Text>
                  </li>
                  <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      Currency
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      {txnDetailObj.bankAccount.currency}
                    </Text>
                  </li>
                  {/* <li className="">
                    <Text tagName="p" variant="body-3" className="text-dark-50">
                      External account ID
                    </Text>
                    <Text tagName="p" variant="body-3" className="text-dark m-0 mt-0">
                      ea_sd09sd38kdsls9dD (TBC)
                    </Text>
                  </li> */}
                </ul>
              </div>
            </s.MerchantsData>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}

export default TxnDetails;
