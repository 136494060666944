import { getMerchantCustomerFacingDetail, updateMerchantCustomerFacingDetail } from 'api/merchant/onbording/step-02';
import { FormikProps } from 'formik';
import MerchantLayout from 'layouts/merchant/MerchantLayout';
import ErrorComponent from 'pages/common/error/ErrorComponent';
import AuthRoutesList from 'pages/merchant/auth/constants';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/merchant/hooks';
import { setCurrentActiveStep, setSaveAndFinishLater } from 'redux/merchant/slice/merchantOnboardSlice';
import { getMerchantBaseUrl } from 'utils/getBaseUrl';
import getErrorMessage from 'utils/getErrorMessage';

import { Spinner } from '@limepayments/cosmic';

import OnboardingRoutesList from '../constants';
import Step02View from './Step02View';
import { UpdateMerchantCustomerFacingDetailPayload } from './types';

function OnboardingStep02Page() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const formRef = useRef<FormikProps<UpdateMerchantCustomerFacingDetailPayload>>(null);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [requiredErrorMessage, setRequiredErrorMessage] = useState<string>('');
  const [fetchDetailLoader, setFetchDetailLoader] = useState<boolean>(true);
  const [initialValues, setInitialValues] = useState<UpdateMerchantCustomerFacingDetailPayload>({
    brandName: '',
    cardStatementName: '',
    smsSenderName: '',
    website: '',
    supportSite: '',
    isSaveFinishLater: false,
  });

  const { merchantId, merchantName, saveAndFinishLater, apiBaseUri, hasCompletedSteps } = useAppSelector((state) => ({
    merchantId: state.config.merchantId,
    merchantName: state.config.merchantName,
    apiBaseUri: state.config.apiBaseUri,
    saveAndFinishLater: state.onboarding.saveAndFinishLater,
    hasCompletedSteps: state.onboarding.hasCompletedSteps,
  }));

  const submitHandler = async (
    values: UpdateMerchantCustomerFacingDetailPayload,
    formObj: { resetForm: () => void },
  ) => {
    try {
      setIsRequestLoading(true);
      setErrorMessage('');

      const { isSaveFinishLater, ...postData } = { ...values };

      postData.supportSite = postData.website.toLowerCase();

      postData.website = postData.website.toLowerCase();

      await updateMerchantCustomerFacingDetail(apiBaseUri, merchantId, postData);

      formObj.resetForm();

      let redirectUrl = isSaveFinishLater
        ? `${getMerchantBaseUrl(merchantName)}/${AuthRoutesList.LOGOUT_PAGE}`
        : `${getMerchantBaseUrl(merchantName)}/onboarding/${OnboardingRoutesList.ONBOARDING_STEP03_PAGE}`;

      if (hasCompletedSteps) {
        redirectUrl = `${getMerchantBaseUrl(merchantName)}/onboarding/${OnboardingRoutesList.ONBOARDING_STEP06_PAGE}`;
      }

      navigate(redirectUrl);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setIsRequestLoading(false);
    }
  };

  useEffect(() => {
    if (formRef && formRef.current && saveAndFinishLater) {
      formRef.current.setFieldValue('isSaveFinishLater', true);
      setTimeout(() => {
        if (formRef && formRef.current) {
          formRef.current.submitForm();
        }
      }, 500);
    }
  }, [saveAndFinishLater]);

  useEffect(() => {
    const fetchMerchantCustomerFacingDetail = async () => {
      try {
        setRequiredErrorMessage('');
        setFetchDetailLoader(true);

        const response = await getMerchantCustomerFacingDetail(apiBaseUri, merchantId);
        setInitialValues({ isSaveFinishLater: false, ...response });
      } catch (error) {
        setRequiredErrorMessage(getErrorMessage(error));
      } finally {
        setFetchDetailLoader(false);
      }
    };

    fetchMerchantCustomerFacingDetail();
  }, [apiBaseUri, merchantName, merchantId]);

  useEffect(() => {
    if (!fetchDetailLoader) {
      setIsPageLoading(false);
      dispatch(setCurrentActiveStep(3));
    }
  }, [setIsPageLoading, fetchDetailLoader, dispatch]);

  return (
    <MerchantLayout hideSideBar={false} activeStepNumber={2} title="Onboarding - Branding details">
      {!isPageLoading && requiredErrorMessage.length ? <ErrorComponent bodyText={requiredErrorMessage} /> : null}

      {isPageLoading && (
        <div className="spinner-wrapper">
          <Spinner variant="simple" isVisible label="Loading..." />
        </div>
      )}

      {!isPageLoading && !requiredErrorMessage.length && (
        <Step02View
          onSubmitClick={submitHandler}
          onPreviousClick={() => {
            navigate(`${getMerchantBaseUrl(merchantName)}/onboarding/${OnboardingRoutesList.ONBOARDING_STEP01_PAGE}`);
          }}
          initialValues={initialValues}
          requestLoader={isRequestLoading}
          errorMsg={errorMessage}
          formRef={formRef}
          saveAndFinishLater={() => {
            dispatch(setSaveAndFinishLater(Date.now()));
          }}
          saveAndReview={hasCompletedSteps}
        />
      )}
    </MerchantLayout>
  );
}

export default OnboardingStep02Page;
