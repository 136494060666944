import { exportPlatformMerchantsList } from 'api/platform/merchant';
import { ExportPlatformMerchantsListPayload, PlatformMerchantObj } from 'api/platform/merchant.types';
import { DateTime } from 'luxon';
import { Fragment, useState } from 'react';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { toast } from 'react-toastify';
import { useAppSelector } from 'redux/platform/hooks';
import getErrorMessage from 'utils/getErrorMessage';

import { Button, FlexContainer, Heading, Icon, Text } from '@limepayments/cosmic';

import { ExportColumnsObj, MerchantStatusObj } from '../../constants';
import AddMerchantModal from './AddMerchantModal';
import ExportComponent from './ExportComponent';
import FilterComponent from './FilterComponent';
import MerchantsDataTable, { MerchantsPendingDataTable } from './MerchantsDataTable';

export interface Props {
  merchantList: Array<PlatformMerchantObj>;
  activeStatusTab: string;
  statusTabChangeHandler: (val: string) => void;
  fetchListLoader: boolean;
  totalRows: number;
  handlePerRowsChange: (newPerPage: number, page: number) => void;
  handlePageChange: (page: number) => void;
  limit: number;
  exportOpen: boolean;
  filterPopupOpen: boolean;
  toggleFilter: () => void;
  toggleExport: () => void;
  activePage: number;
  handleSort: (selectedColumn: TableColumn<PlatformMerchantObj>, sortDirection: SortOrder) => void;
}

function MerchantLists({
  merchantList,
  activeStatusTab,
  statusTabChangeHandler,
  fetchListLoader,
  totalRows,
  handlePerRowsChange,
  handlePageChange,
  limit,
  exportOpen,
  filterPopupOpen,
  toggleFilter,
  toggleExport,
  activePage,
  handleSort,
}: Props) {
  const [addMerchantModalOpen, setAddMerchantModalOpen] = useState(false);

  // Export variables
  const [exportWithAppliedFilter, setExportWithAppliedFilter] = useState<boolean>(false);
  const [dateActiveRangeValue, setDateActiveRangeValue] = useState<string>('');
  const [exportDateRangeValue, setExportDateRangeValue] = useState<string | Date | Array<Date>>('');
  const [exportColumnsSelectedList, setExportColumnsSelectedList] = useState<Array<string>>([]);
  const [exportDataLoader, setExportDataLoader] = useState<boolean>(false);
  const [exportDataCSV, setExportDataCSV] = useState<string | null>(null);
  const [exportSuccessfully, setExportSuccessfully] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { marketplaceId, apiBaseUri, merchantFilterObj } = useAppSelector((state) => ({
    marketplaceId: state.config.marketplaceId,
    apiBaseUri: state.config.apiBaseUri,
    merchantFilterObj: state.merchant.merchantFilterObj,
  }));

  const exportSaveClickHandler = async () => {
    try {
      let from = '';
      let to = '';

      let postData: ExportPlatformMerchantsListPayload = {
        query: {},
        columns: [],
      };

      if (exportColumnsSelectedList.length < 1) {
        setErrorMessage('Please select atleast one column to export data');
        return;
      }

      if (exportWithAppliedFilter && merchantFilterObj.merchantName) {
        postData.query.businessName = merchantFilterObj.merchantName;
      }

      if (exportWithAppliedFilter && merchantFilterObj.businessType) {
        postData.query.businessType = merchantFilterObj.businessType;
      }

      if (activeStatusTab) {
        postData.query.status = activeStatusTab;
      }

      if (exportColumnsSelectedList.length > 0) {
        postData.columns = exportColumnsSelectedList;
      }

      if (dateActiveRangeValue === 'today') {
        from = `${DateTime.utc().startOf('day').toISO()}`;
        to = `${DateTime.utc().endOf('day').toISO()}`;
      }

      if (dateActiveRangeValue === 'current_month') {
        from = `${DateTime.utc().startOf('month').startOf('day').toISO()}`;
        to = `${DateTime.utc().endOf('day').toISO()}`;
      }

      if (dateActiveRangeValue === 'one_week') {
        from = `${DateTime.utc().minus({ days: 7 }).startOf('day').toISO()}`;
        to = `${DateTime.utc().endOf('day').toISO()}`;
      }

      if (dateActiveRangeValue === 'one_month') {
        from = `${DateTime.utc().minus({ month: 1 }).startOf('month').startOf('day').toISO()}`;
        to = `${DateTime.utc().minus({ month: 1 }).endOf('month').endOf('day').toISO()}`;
      }

      if (dateActiveRangeValue === 'custom' && Array.isArray(exportDateRangeValue)) {
        const fromDateObj = new Date(exportDateRangeValue[0]);
        const toDateObj = new Date(exportDateRangeValue[1]);

        const fromDateLuxonObj = DateTime.fromISO(fromDateObj.toISOString()).toUTC();
        const toDateLuxonObj = DateTime.fromISO(toDateObj.toISOString()).toUTC();

        const diff = toDateLuxonObj.diff(fromDateLuxonObj, ['years', 'months', 'days', 'hours']);

        if (diff.months > 3 && diff.days > 0) {
          toast.error('Please select date range within 3 months');
          return;
        }

        from = `${DateTime.fromISO(fromDateObj.toISOString()).toUTC().startOf('day').toISO()}`;
        to = `${DateTime.fromISO(toDateObj.toISOString()).toUTC().startOf('day').toISO()}`;
      }

      if (from) {
        postData.query.from = from;
      }

      if (to) {
        postData.query.to = to;
      }

      setExportDataLoader(true);
      setErrorMessage('');

      const response = await exportPlatformMerchantsList(apiBaseUri, marketplaceId, postData);
      setExportDataCSV(response);

      setExportSuccessfully(true);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setExportDataLoader(false);
    }
  };

  const exportColumnsSelectedListHandler = (val: string, isSelectAll?: boolean) => {
    let updatedList = [...exportColumnsSelectedList];

    if (!val && isSelectAll) {
      updatedList =
        exportColumnsSelectedList.length === Object.keys(ExportColumnsObj).length
          ? []
          : Object.keys(ExportColumnsObj).map((obj) => obj);
    }

    if (val && exportColumnsSelectedList.includes(val)) {
      updatedList = exportColumnsSelectedList.filter((obj) => {
        return obj !== val;
      });
    }

    if (val && !exportColumnsSelectedList.includes(val)) {
      updatedList.push(val);
    }

    setExportColumnsSelectedList(updatedList);
  };

  const toggleExportHandler = (val: boolean, clearSection?: boolean) => {
    if (val) {
      resetExportValuesHandler();
    }

    if (clearSection) {
      toggleExport();
    }
  };

  const resetExportValuesHandler = () => {
    setExportColumnsSelectedList([]);
    setExportDateRangeValue('');
    setDateActiveRangeValue('');
    setExportWithAppliedFilter(false);
    setErrorMessage('');
    setExportSuccessfully(false);
  };

  const getFilterApplyCount = () => {
    return merchantFilterObj.totalFiltersApplied;
  };

  return (
    <Fragment>
      <div className="content-wrap-inner">
        <Heading alignment="left" tagName="h2" variant="xl" className="page-title mb-28" testId="merchant-page-heading">
          Merchants
        </Heading>
        <div className="lp-w-full merchant-controls with-border md-lp-spacebetween">
          <ul className="merchant-filters">
            <li className={`${activeStatusTab === MerchantStatusObj.Active ? `is-active` : ``}`}>
              <button onClick={() => statusTabChangeHandler(MerchantStatusObj.Active)}>
                <Text tagName="p" variant="body-2" className="m-0" testId="merchant-page-status-active">
                  Active
                </Text>
              </button>
            </li>
            <li className={`${activeStatusTab === MerchantStatusObj.Pending ? `is-active` : ``}`}>
              <button onClick={() => statusTabChangeHandler(MerchantStatusObj.Pending)}>
                <Text tagName="p" variant="body-2" className="m-0" testId="merchant-page-status-pending">
                  Pending
                </Text>
              </button>
            </li>
            <li className={`${activeStatusTab === MerchantStatusObj.Suspended ? `is-active` : ``}`}>
              <button onClick={() => statusTabChangeHandler(MerchantStatusObj.Suspended)}>
                <Text tagName="p" variant="body-2" className="m-0" testId="merchant-page-status-suspended">
                  Suspended
                </Text>
              </button>
            </li>
          </ul>

          <FlexContainer classNames="lp-flex">
            <div className="lp-flex order-1">
              <Button
                className="lp-w-full"
                onClick={() => setAddMerchantModalOpen(true)}
                size="small"
                variant="reversed"
                testId="add-new-merchant"
              >
                <Icon name="Plus" className="ml-0" />
                Add new merchant
              </Button>
            </div>
            <div className="lp-flex order-2 position-relative ml-16px">
              <Button
                className="lp-w-full"
                onClick={() => toggleFilter()}
                size="small"
                variant="reversed"
                disabled={merchantList.length < 1 && getFilterApplyCount() === 0}
                testId="merchant-filter-button"
              >
                <Icon name="Filter" className="ml-0" />
                Filter ({getFilterApplyCount()})
              </Button>
              <Button
                className="lp-w-full"
                onClick={() => toggleExportHandler(false, true)}
                size="small"
                variant="reversed"
                disabled={!merchantList.length}
                testId="merchant-export-button"
              >
                <Icon name="ExternalLink" className="ml-0" />
                Export
              </Button>

              {/* Filter Dropdown */}
              {filterPopupOpen && <FilterComponent modalToggler={toggleFilter} activeStatusTab={activeStatusTab} />}

              {/* Export Dropdown */}
              {exportOpen && (
                <div className="filter-dropdown w-656">
                  <ExportComponent
                    onCloseExportClick={toggleExportHandler}
                    exportSaveClickHandler={exportSaveClickHandler}
                    exportWithAppliedFilter={exportWithAppliedFilter}
                    dateActiveRangeValue={dateActiveRangeValue}
                    exportDateRangeValue={exportDateRangeValue}
                    exportColumnsSelectedList={exportColumnsSelectedList}
                    setExportWithAppliedFilter={setExportWithAppliedFilter}
                    setDateActiveRangeValue={setDateActiveRangeValue}
                    setExportDateRangeValue={setExportDateRangeValue}
                    setExportColumnsSelectedList={exportColumnsSelectedListHandler}
                    exportDataLoader={exportDataLoader}
                    errorMessage={errorMessage}
                    exportSuccessfully={exportSuccessfully}
                    exportDataCSV={exportDataCSV}
                    activeStatusTab={activeStatusTab}
                    filterCount={getFilterApplyCount()}
                  />
                </div>
              )}
            </div>
          </FlexContainer>
        </div>

        {activeStatusTab === MerchantStatusObj.Pending && (
          <MerchantsPendingDataTable
            merchantList={merchantList}
            fetchListLoader={fetchListLoader}
            activeStatusTab={activeStatusTab}
            totalRows={totalRows}
            handlePerRowsChange={handlePerRowsChange}
            handlePageChange={handlePageChange}
            limit={limit}
            isFilterRequest={getFilterApplyCount() > 0}
            activePage={activePage}
            handleSort={handleSort}
          />
        )}

        {activeStatusTab !== MerchantStatusObj.Pending && (
          <MerchantsDataTable
            merchantList={merchantList}
            fetchListLoader={fetchListLoader}
            activeStatusTab={activeStatusTab}
            totalRows={totalRows}
            handlePerRowsChange={handlePerRowsChange}
            handlePageChange={handlePageChange}
            limit={limit}
            isFilterRequest={getFilterApplyCount() > 0}
            activePage={activePage}
            handleSort={handleSort}
          />
        )}
      </div>

      <AddMerchantModal isOpen={addMerchantModalOpen} modalToggle={setAddMerchantModalOpen} />
    </Fragment>
  );
}

export default MerchantLists;
