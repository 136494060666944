export const PlatformDefaultRoute = {
  LOGGEDIN_ROUTE: 'dashboard/payment/all',
};

export const TableStyleSettings = {
  rows: {
    style: {
      minHeight: '36px', // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: '4px', // override the cell padding for head cells
      paddingRight: '4px',
    },
  },
  cells: {
    style: {
      paddingLeft: '4px', // override the cell padding for data cells
      paddingRight: '4px',
      paddingTop: '4px',
      paddingBottom: '4px',
    },
  },
};

export const TableThemeSettings = {
  text: {
    primary: '#555C7C',
    secondary: '#E0E1EA',
  },
  background: {
    default: '#FFF',
  },
  context: {
    text: '#B3B4BC',
    background: '#CB4B16',
  },
  divider: {
    default: '#F0F2F9',
  },
};

export const MAX_FILTERING_DAYS_MERCHANT = 180;
export const MAX_FILTERING_DAYS = 90;
export const LISTING_RECORDS_PER_PAGE = 20;

export default PlatformDefaultRoute;
