import { handleResponse } from 'api/utils';
import { UpdateMerchantBusinessRegistrationPayload } from 'pages/merchant/onboarding/step-01/types';
import getFetchOptions from 'utils/getFetchOptions';

import {
  GetMerchantBusinessRegistrationResponse,
  GetSupportedIndustryObj,
  UpdateMerchantBusinessRegistrationResponse,
} from './step-01.types';

export const getMerchantBusinessRegistration = async (
  host: string,
  merchantId: string,
): Promise<GetMerchantBusinessRegistrationResponse> => {
  const options = await getFetchOptions();
  return await fetch(`${host}/onboarding/${merchantId}/business/registration`, options).then(handleResponse);
};

export const updateMerchantBusinessRegistration = async (
  host: string,
  merchantId: string,
  payload: UpdateMerchantBusinessRegistrationPayload,
): Promise<UpdateMerchantBusinessRegistrationResponse> => {
  const options = await getFetchOptions('POST', { payload });
  return await fetch(`${host}/onboarding/${merchantId}/business/registration`, options).then(handleResponse);
};

export const getSupportedIndustry = async (
  host: string,
  merchantId: string,
): Promise<Array<GetSupportedIndustryObj>> => {
  const options = await getFetchOptions();
  return await fetch(`${host}/onboarding/${merchantId}/industry/supported`, options).then(handleResponse);
};
