import { Heading, Illustration, Link, Text } from '@limepayments/cosmic';

interface Props {
  onSubmitClick: () => void;
  email: string;
}

function ForgotPasswordConfirmationView({ onSubmitClick, email }: Props) {
  return (
    <>
      <div className="lp-w-full mb-32">
        <div className="size-136 mx-auto mb-32">
          <Illustration name="Email" size="large" />
        </div>
        <Heading alignment="center" tagName="h1" variant="md" className="mb-12">
          Check your email
        </Heading>
        <Text testId="forgot-password-confirm-title" tagName="p" variant="body-3" alignment="center">
          We've sent your password reset instructions to <Link className="fw-medium">{email}</Link>
        </Text>
      </div>
      <Text tagName="p" variant="body-3" alignment="center">
        Didn't receive an email? Check your spam folder or{' '}
        <Link onClick={onSubmitClick} size="small" className="fw-semibold">
          try another email address
        </Link>
        .
      </Text>
    </>
  );
}

export default ForgotPasswordConfirmationView;
