/** @jsxImportSource @emotion/react */

import { searchCustomersQueryDocument, searchOrdersQueryDocument, searchTransactionsQueryDocument } from 'api/graphql';
import { AmountFilterInput, InstantFilterInput, LocalDateFilterInput } from 'api/graphql/generated/graphql';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'redux/merchant/hooks'; // TODO: merchantId prop
import { useQuery } from 'urql';
import { fromCurrency } from 'utils/currency';

import { Icon } from '@limepayments/cosmic';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { debounce } from '@mui/material/utils';

export type AmountFilterValue = {
  option: 'is_equal_to' | 'is_after' | 'is_before';
  value: string;
};

export const toAmountFilterInput = (amountFilter: AmountFilterValue): AmountFilterInput | null => {
  const { option, value } = amountFilter;

  const amount = fromCurrency(value);

  return option === 'is_equal_to'
    ? { eq: amount }
    : option === 'is_after'
    ? { gt: amount }
    : option === 'is_before'
    ? { lt: amount }
    : null;
};

export type AmountFilterProps = {
  value: AmountFilterValue | null;
  onChange(value: AmountFilterValue): void;
};

export function AmountFilter({ onChange, ...props }: AmountFilterProps) {
  const option = props.value?.option ?? 'is_equal_to';
  const value = props.value?.value ?? '';

  return (
    <>
      <div className="lp-w-full mb-16">
        <div className="selectdropdown">
          <Icon name="ChevronDown" className="arrow-down" />
          <select
            className="form-control"
            value={option}
            onChange={({ target: { value: option } }) =>
              onChange({ option: option as AmountFilterValue['option'], value })
            }
          >
            <option value="is_equal_to">is equal to</option>
            {/* TODO: range filters */}
            <option value="is_between" disabled>
              is between
            </option>
            <option value="is_after">is greater than</option>
            <option value="is_before">is less than</option>
          </select>
        </div>
      </div>
      <div className="lp-w-full lp-flex lp-align-center mb-0">
        <div className="w-24">
          <Icon name="Return" className="text-primary" />
        </div>
        <div className="lp-w-full ml-16px">
          <input
            type="text"
            className="form-control radius-0"
            value={value}
            onChange={({ target: { value } }) =>
              onChange({
                option,
                // TODO
                value: value.replace(/[^0-9.]+/g, ''),
              })
            }
          />
        </div>
      </div>
    </>
  );
}

export type DateFilterValue = {
  option: 'is_in_the_last' | 'is_equal_to' | 'is_after' | 'is_before';
  unit: 'days' | 'months';
  value: string;
};

export const toInstantFilterInput = (dateFilter: DateFilterValue): InstantFilterInput | null => {
  const { option, unit, value } = dateFilter;

  if (option === 'is_in_the_last') {
    const now = DateTime.now();
    return {
      gte: now
        .minus({ [unit]: parseInt(value) })
        .startOf('day')
        .toISO(),
    };
  } else if (option === 'is_equal_to') {
    const date = DateTime.fromISO(value);
    return { gte: date.startOf('day').toISO(), lte: date.endOf('day').toISO() };
  } else if (option === 'is_after') {
    return { gt: DateTime.fromISO(value).endOf('day').toISO() };
  } else if (option === 'is_before') {
    return { lt: DateTime.fromISO(value).startOf('day').toISO() };
  }

  return null;
};

export const toLocalDateFilterInput = (dateFilter: DateFilterValue): LocalDateFilterInput | null => {
  const { option, unit, value } = dateFilter;

  if (option === 'is_in_the_last') {
    const now = DateTime.now();
    return {
      gte: now
        .minus({ [unit]: parseInt(value) })
        .startOf('day')
        .toISODate(),
    };
  } else if (option === 'is_equal_to') {
    return { eq: DateTime.fromISO(value).startOf('day').toISODate() };
  } else if (option === 'is_after') {
    return { gt: DateTime.fromISO(value).endOf('day').toISODate() };
  } else if (option === 'is_before') {
    return { lt: DateTime.fromISO(value).startOf('day').toISODate() };
  }

  return null;
};

export type DateFilterProps = {
  value: DateFilterValue | null;
  onChange(value: DateFilterValue): void;
};

export function DateFilter({ onChange, ...props }: DateFilterProps) {
  const option = props.value?.option ?? 'is_in_the_last';
  const unit = props.value?.unit ?? 'days';
  const value = props.value?.value ?? '';

  return (
    <>
      <div className="lp-w-full mb-16">
        <div className="selectdropdown">
          <Icon name="ChevronDown" className="arrow-down" />
          <select
            className="form-control"
            value={option}
            onChange={({ target: { value: option } }) =>
              onChange({ option: option as DateFilterValue['option'], unit, value })
            }
          >
            <option value="is_in_the_last">is in the last</option>
            <option value="is_equal_to">is equal to</option>
            {/* TODO: range filters */}
            <option value="is_between" disabled>
              is between
            </option>
            <option value="is_after">is after</option>
            <option value="is_before">is before</option>
          </select>
        </div>
      </div>
      <div className="lp-w-full lp-flex lp-align-center mb-0">
        <div className="w-24">
          <Icon name="Return" className="text-primary" />
        </div>
        {option === 'is_in_the_last' && (
          <>
            <div className="w-40">
              <input
                type="text"
                className="form-control"
                value={value}
                onChange={({ target: { value } }) => onChange({ option, unit, value })}
              />
            </div>
            <div className="selectdropdown">
              <Icon name="ChevronDown" className="arrow-down" />
              <select
                className="form-control"
                value={unit}
                onChange={({ target: { value: unit } }) =>
                  onChange({ option, unit: unit as DateFilterValue['unit'], value })
                }
              >
                <option value="days">days</option>
                <option value="months">months</option>
              </select>
            </div>
          </>
        )}
        {option !== 'is_in_the_last' && (
          <div className="lp-w-full ml-16px">
            <input
              // TODO: datepicker
              type="date"
              className="form-control"
              value={value}
              onChange={({ target: { value } }) => onChange({ option, unit, value })}
            />
          </div>
        )}
      </div>
    </>
  );
}

export type SearchFilterOption = {
  value: string;
  label: string;
};

export type SearchFilterProps = {
  value: SearchFilterOption | null;
  options: SearchFilterOption[];
  placeholder: string;
  loading?: boolean;
  error?: string;
  onChange(value: SearchFilterOption | null): void;
  onInputChange(value: string): void;
};

export function SearchFilter({
  value,
  options,
  placeholder,
  loading,
  error,
  onChange,
  onInputChange,
}: SearchFilterProps) {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = useMemo(() => debounce(onInputChange, 400), [onInputChange]);

  useEffect(() => {
    handleInputChange(inputValue);
  }, [inputValue, handleInputChange]);

  return (
    <div
      css={{
        '.MuiInput-root': {
          fontFamily: 'var(--lp-fonts-body-3)',
          fontSize: 'var(--lp-font-size-body-3)',
          color: 'rgb(var(--lp-colors-neutral-600))',
          ':before': {
            borderBottomColor: 'rgb(var(--lp-colors-neutral-200)) !important',
          },
          ':after': {
            borderBottomColor: 'rgb(var(--lp-colors-primary))',
          },
        },
        '.MuiPaper-root': {
          fontFamily: 'var(--lp-fonts-body-3)',
          fontSize: 'var(--lp-font-size-body-3)',
          color: 'rgb(var(--lp-colors-neutral-600))',
        },
        '.MuiInput-input::placeholder': {
          color: 'rgb(var(--lp-colors-neutral-600))',
          opacity: 1,
        },
      }}
    >
      <Autocomplete
        value={value}
        inputValue={inputValue}
        options={options}
        loading={loading}
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={(params) => (
          <TextField {...params} variant="standard" placeholder={placeholder} helperText={error} error={!!error} />
        )}
        renderOption={(props, option, { inputValue }) => {
          const matches = match(option.label, inputValue, { insideWords: true });
          const parts = parse(option.label, matches);

          return (
            <li {...props}>
              <div>
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}
              </div>
            </li>
          );
        }}
        disablePortal
      />
    </div>
  );
}

export type CustomerFilterProps = {
  value: SearchFilterOption | null;
  onChange(value: SearchFilterOption | null): void;
};

export function CustomerFilter({ value, onChange, ...props }: CustomerFilterProps) {
  const [query, setQuery] = useState('');

  // TODO: merchantId prop
  const { merchantId } = useAppSelector((state) => ({
    merchantId: state.config.merchantId,
  }));

  const pause = query.length < 3;

  const [{ fetching, data, error }] = useQuery({
    query: searchCustomersQueryDocument,
    variables: { merchantIds: merchantId, query },
    pause,
  });

  const options = useMemo<SearchFilterProps['options']>(() => {
    if (pause || !data?.search.customers) return [];

    return data.search.customers.map(({ customerId, customerEmailAddress }) => ({
      value: customerId,
      label: customerEmailAddress,
    }));
  }, [data?.search.customers, pause]);

  return (
    <SearchFilter
      value={value}
      options={options}
      placeholder="Search customer"
      loading={fetching}
      error={error?.message}
      onChange={onChange}
      onInputChange={setQuery}
    />
  );
}

export type OrderFilterProps = {
  value: SearchFilterOption | null;
  onChange(value: SearchFilterOption | null): void;
};

export function OrderFilter({ value, onChange, ...props }: OrderFilterProps) {
  const [query, setQuery] = useState('');

  // TODO: merchantId prop
  const { merchantId } = useAppSelector((state) => ({
    merchantId: state.config.merchantId,
  }));

  const pause = query.length < 3;

  const [{ fetching, data, error }] = useQuery({
    query: searchOrdersQueryDocument,
    variables: { merchantIds: merchantId, query },
    pause,
  });

  const options = useMemo<SearchFilterProps['options']>(() => {
    if (pause || !data?.search.orders) return [];

    return data.search.orders.map(({ orderId, referenceOrderId }) => ({
      value: orderId,
      label: referenceOrderId,
    }));
  }, [data?.search.orders, pause]);

  return (
    <SearchFilter
      value={value}
      options={options}
      placeholder="Search order ID"
      loading={fetching}
      error={error?.message}
      onChange={onChange}
      onInputChange={setQuery}
    />
  );
}

export type TransactionFilterProps = {
  value: SearchFilterOption | null;
  onChange(value: SearchFilterOption | null): void;
};

export function TransactionFilter({ value, onChange, ...props }: TransactionFilterProps) {
  const [query, setQuery] = useState('');

  // TODO: merchantId prop
  const { merchantId } = useAppSelector((state) => ({
    merchantId: state.config.merchantId,
  }));

  const pause = query.length < 3;

  const [{ fetching, data, error }] = useQuery({
    query: searchTransactionsQueryDocument,
    variables: { merchantIds: merchantId, query },
    pause,
  });

  const options = useMemo<SearchFilterProps['options']>(() => {
    if (pause || !data?.search.transactions) return [];

    return data.search.transactions.map(({ transactionId }) => ({
      value: transactionId,
      label: transactionId,
    }));
  }, [data?.search.transactions, pause]);

  return (
    <SearchFilter
      value={value}
      options={options}
      placeholder="Search transaction ID"
      loading={fetching}
      error={error?.message}
      onChange={onChange}
      onInputChange={setQuery}
    />
  );
}
