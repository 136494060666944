import { getMerchantBusinessContact, updateMerchantBusinessContact } from 'api/merchant/onbording/step-03';
import { FormikProps } from 'formik';
import MerchantLayout from 'layouts/merchant/MerchantLayout';
import ErrorComponent from 'pages/common/error/ErrorComponent';
import AuthRoutesList from 'pages/merchant/auth/constants';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/merchant/hooks';
import { setCurrentActiveStep, setSaveAndFinishLater } from 'redux/merchant/slice/merchantOnboardSlice';
import { getMerchantBaseUrl } from 'utils/getBaseUrl';
import getErrorMessage from 'utils/getErrorMessage';

import { Spinner } from '@limepayments/cosmic';

import OnboardingRoutesList from '../constants';
import Step03View from './Step03View';
import { UpdateMerchantBusinessContactPayload } from './types';

function OnboardingStep03Page() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const formRef = useRef<FormikProps<UpdateMerchantBusinessContactPayload>>(null);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [requiredErrorMessage, setRequiredErrorMessage] = useState<string>('');
  const [fetchDetailLoader, setFetchDetailLoader] = useState<boolean>(true);
  const [initialValues, setInitialValues] = useState<UpdateMerchantBusinessContactPayload>({
    contactPerson: {
      firstName: '',
      lastName: '',
      title: '',
    },
    contactEmail: '',
    supportEmail: '',
    phoneNo: '',
    address: {
      unitNumber: '',
      streetNumber: '',
      streetName: '',
      streetType: '',
      suburb: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
    },
  });

  const { apiBaseUri, merchantId, merchantName, merchantTaxCountry, saveAndFinishLater, hasCompletedSteps } =
    useAppSelector((state) => ({
      merchantId: state.config.merchantId,
      merchantName: state.config.merchantName,
      apiBaseUri: state.config.apiBaseUri,
      merchantTaxCountry: state.config.merchantTaxCountry,
      saveAndFinishLater: state.onboarding.saveAndFinishLater,
      hasCompletedSteps: state.onboarding.hasCompletedSteps,
    }));

  const submitHandler = async (values: UpdateMerchantBusinessContactPayload, formObj: { resetForm: () => void }) => {
    try {
      setIsRequestLoading(true);
      setErrorMessage('');

      const { isSaveFinishLater, isManualEnterAddress, businessAddress, ...postData } = { ...values };

      if (postData.address.country === 'AU') {
        postData.address.city = null;
      }
      if (postData.address.country === 'NZ') {
        postData.address.state = null;
      }

      await updateMerchantBusinessContact(apiBaseUri, merchantId, postData);

      formObj.resetForm();

      let redirectUrl = isSaveFinishLater
        ? `${getMerchantBaseUrl(merchantName)}/${AuthRoutesList.LOGOUT_PAGE}`
        : `${getMerchantBaseUrl(merchantName)}/onboarding/${OnboardingRoutesList.ONBOARDING_STEP04_PAGE}`;

      if (hasCompletedSteps) {
        redirectUrl = `${getMerchantBaseUrl(merchantName)}/onboarding/${OnboardingRoutesList.ONBOARDING_STEP06_PAGE}`;
      }

      navigate(redirectUrl);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setIsRequestLoading(false);
    }
  };

  useEffect(() => {
    if (formRef && formRef.current && saveAndFinishLater) {
      formRef.current.setFieldValue('isSaveFinishLater', true);
      setTimeout(() => {
        if (formRef && formRef.current) {
          formRef.current.submitForm();
        }
      }, 500);
    }
  }, [saveAndFinishLater]);

  useEffect(() => {
    const fetchMerchantBusinessContact = async () => {
      try {
        setRequiredErrorMessage('');
        setFetchDetailLoader(true);

        const response = await getMerchantBusinessContact(apiBaseUri, merchantId);
        setInitialValues({
          ...response,
          isManualEnterAddress: true,
        });
      } catch (error) {
        setRequiredErrorMessage(getErrorMessage(error));
      } finally {
        setFetchDetailLoader(false);
      }
    };

    fetchMerchantBusinessContact();
  }, [apiBaseUri, merchantName, merchantId]);

  useEffect(() => {
    if (!fetchDetailLoader) {
      setIsPageLoading(false);
      dispatch(setCurrentActiveStep(4));
    }
  }, [setIsPageLoading, fetchDetailLoader, dispatch]);

  return (
    <MerchantLayout activeStepNumber={3} title="Onboarding - Business contact details">
      {!isPageLoading && requiredErrorMessage.length ? <ErrorComponent bodyText={requiredErrorMessage} /> : null}

      {isPageLoading && (
        <div className="spinner-wrapper">
          <Spinner variant="simple" isVisible label="Loading..." />
        </div>
      )}

      {!isPageLoading && !requiredErrorMessage.length && (
        <Step03View
          onSubmitClick={submitHandler}
          onPreviousClick={() => {
            navigate(`${getMerchantBaseUrl(merchantName)}/onboarding/${OnboardingRoutesList.ONBOARDING_STEP02_PAGE}`);
          }}
          initialValues={initialValues}
          requestLoader={isRequestLoading}
          errorMsg={errorMessage}
          formRef={formRef}
          merchantTaxCountry={merchantTaxCountry}
          saveAndFinishLater={() => {
            dispatch(setSaveAndFinishLater(Date.now()));
          }}
          saveAndReview={hasCompletedSteps}
        />
      )}
    </MerchantLayout>
  );
}

export default OnboardingStep03Page;
